import React, { Component } from 'react';
import { StyleSheet, Text, View, TextInput, ScrollView, Dimensions } from 'react-native';
import { getDatabase, ref, get, push, set, child } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AirbnbRating } from 'react-native-ratings';

import Modal from "../compenents/Modal";
import Container from '../compenents/Container';
import ActionButton from '../compenents/ActionButton';

export default class WriteReview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accomodationsRating: 3,
            accuracyRating: 3,
            cleanlinessRating: 3,
            overallRating: 3,
            review: null,
            reviewKey: Math.random().toString(36).substring(7),
            name: null,
            nameKey: Math.random().toString(36).substring(7),
            errorModalVisible: false,
            errorModalText: "",
            errorModalKey: Math.random().toString(36).substring(7),
            successModalVisible : false,
            successModalKey: Math.random().toString(36).substring(7),
            successModalText: "",
            reviewData: null,
            oldAccomodationsRating: null,
            oldAccuracyRating: null,
            oldCleanlinessRating: null,
            oldOverallRating: null,
            loading: false,
            postTitle: null,
        }
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.getReviewData(this.props.route.params.postID);
            if (!!this.props.route.params.reviewID) {
                this.getReview(this.props.route.params.postID, this.props.route.params.reviewID);
            }
            this.getListingTitle(this.props.route.params.postID);
        });
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user });
            }
        });
    }

    getListingTitle = async (postID) => {
        const db = getDatabase();
        const titleRef = ref(db, `publicRentals/${postID}/title`);
        get(titleRef).then((snapshot) => {
            if (snapshot.val() != undefined && snapshot.val() != null) {
                const titleData = snapshot.val();
                console.log("titleData: ", titleData)
                this.setState({
                    postTitle: titleData
                });
            }
        });
    }

    getReviewData = (postUID) => {
        const db = getDatabase();
        const reviewDataRef = ref(db, `reviewData/${postUID}`);
        get(reviewDataRef).then((snapshot) => {
            if (snapshot.val() != undefined && snapshot.val() != null) {
                const reviewData = snapshot.val();
                this.setState({
                    reviewData: reviewData
                });
            }
        });
    }

    getReview = (postUID, reviewId) => {
        const db = getDatabase();
        const reviewsRef = ref(db, `reviews/${postUID}/${reviewId}`);
        get(reviewsRef).then((snapshot) => {
            if (snapshot.val() != undefined && snapshot.val() != null) {
                const review = snapshot.val();
                this.setState({
                    accomodationsRating: review.accommodations,
                    accuracyRating: review.accuracy,
                    cleanlinessRating: review.cleanliness,
                    overallRating: review.overall,
                    review: review.review,
                    name: review.reviewerName,
                    oldAccomodationsRating: review.accommodations,
                    oldAccuracyRating: review.accuracy,
                    oldCleanlinessRating: review.cleanliness,
                    oldOverallRating: review.overall,
                });
            }
        });
    }

    submitReview = async () => {
        //make sure there is a review
        if (this.state.review != null && this.state.review != "" && this.state.name != null && this.state.name != "") {
            this.setState({loading: true});
            if (!!this.state.reviewData) {
                if (!!this.props.route.params.reviewID) {
                    let newAccomodationsTotal = 0;
                    let newAccuracyRating = 0;
                    let newCleanlinessRating = 0;
                    let newOverallRating = 0;
                    if (this.state.oldAccomodationsRating != null) {
                        newAccomodationsTotal = this.state.reviewData.accommodations - this.state.oldAccomodationsRating;
                        newAccomodationsTotal = newAccomodationsTotal + this.state.accomodationsRating;
                    }  else {
                        newAccomodationsTotal = this.state.accomodationsRating + this.state.reviewData.accommodations;
                    }
                    if (this.state.oldAccuracyRating != null) {
                        newAccuracyRating = this.state.reviewData.accuracy - this.state.oldAccuracyRating;
                        newAccuracyRating = newAccuracyRating + this.state.accuracyRating;
                    } else {
                        newAccuracyRating = this.state.accuracyRating + this.state.reviewData.accuracy;
                    }
                    if (this.state.oldCleanlinessRating != null) {
                        newCleanlinessRating = this.state.reviewData.cleanliness - this.state.oldCleanlinessRating;
                        newCleanlinessRating = newCleanlinessRating + this.state.cleanlinessRating;
                    } else {
                        newCleanlinessRating = this.state.cleanlinessRating + this.state.reviewData.cleanliness;
                    }
                    if (this.state.oldOverallRating != null) {
                        newOverallRating = this.state.reviewData.overall - this.state.oldOverallRating;
                        newOverallRating = newOverallRating + this.state.overallRating;
                    } else {
                        newOverallRating = this.state.overallRating + this.state.reviewData.overall;
                    }
                    let newReviewData = {
                        accommodations: newAccomodationsTotal,
                        accuracy: newAccuracyRating,
                        cleanliness: newCleanlinessRating,
                        overall: newOverallRating,
                        lastReview: this.state.review,
                        totalReviewCount: this.state.reviewData.totalReviewCount,
                    }
                    //update reviewData
                    const db = getDatabase();
                    const reviewDataRef = ref(db, `reviewData/${this.props.route.params.postID}`);
                    await set(reviewDataRef, newReviewData);
                } else {
                    //calculate new averages
                    let newReviewData = {
                        accommodations: (this.state.accomodationsRating + this.state.reviewData.accommodations),
                        accuracy: (this.state.accuracyRating + this.state.reviewData.accuracy),
                        cleanliness: (this.state.cleanlinessRating + this.state.reviewData.cleanliness),
                        overall: (this.state.overallRating + this.state.reviewData.overall),
                        lastReview: this.state.review,
                        totalReviewCount: (this.state.reviewData.totalReviewCount + 1),
                    }
                    //update reviewData
                    const db = getDatabase();
                    const reviewDataRef = ref(db, `reviewData/${this.props.route.params.postID}`);
                    await set(reviewDataRef, newReviewData);
                }
            } else {
                //post new review data
                let newReviewData = {
                    accommodations: this.state.accomodationsRating,
                    accuracy: this.state.accuracyRating,
                    cleanliness: this.state.cleanlinessRating,
                    overall: this.state.overallRating,
                    lastReview: this.state.review,
                    totalReviewCount: 1,
                }
                const db = getDatabase();
                const reviewDataRef = ref(db, `reviewData/${this.props.route.params.postID}`);
                await set(reviewDataRef, newReviewData);
            }
            if (!!this.props.route.params.reviewID) {
                //update reveiew
                let today = new Date();
                let newReview = {
                    accommodations: this.state.accomodationsRating,
                    accuracy: this.state.accuracyRating,
                    cleanliness: this.state.cleanlinessRating,
                    overall: this.state.overallRating,
                    review: this.state.review,
                    reviewerUID: this.state.user.uid,
                    reviewerName: this.state.name,
                    listingID: this.props.route.params.postID,
                    date: today.getTime(),
                }
                const db = getDatabase();
                const reviewsRef = ref(db, `reviews/${this.props.route.params.postID}/${this.props.route.params.reviewID}`);
                await set(reviewsRef, newReview);
            } else {
                //post new reveiew
                let today = new Date();
                let newReview = {
                    accommodations: this.state.accomodationsRating,
                    accuracy: this.state.accuracyRating,
                    cleanliness: this.state.cleanlinessRating,
                    overall: this.state.overallRating,
                    review: this.state.review,
                    reviewerUID: this.state.user.uid,
                    reviewerName: this.state.name,
                    listingID: this.props.route.params.postID,
                    date: today.getTime(),
                }
                const db = getDatabase();
                const newReviewsRef = push(child(ref(db), 'reviews/' + this.props.route.params.postID));
                await set(newReviewsRef, newReview);
            }

            if (!!this.props.route.params.callback) {
                this.props.route.params.callback();
            }

            //show success
            this.showSuccesModal();
            //clear data
            this.setState({
                accomodationsRating: 3,
                accuracyRating: 3,
                cleanlinessRating: 3,
                overallRating: 3,
                review: null,
                name: null,
                nameKey: Math.random().toString(36).substring(7),
                reviewKey: Math.random().toString(36).substring(7),
                reviewData: null,
                loading: false,
            }, () => {
                this.getReviewData(this.props.route.params.postID)
            })
        } else {
            this.setState({
                errorModalVisible: true,
                errorModalKey: Math.random().toString(36).substring(7),
                errorModalText: "Please write a review and add your name to continue."
            });
        }
    }

    accomodationsRatingCompleted = (rating) => {
        this.setState({
            accomodationsRating: rating
        });
    }

    accuracyRatingCompleted = (rating) => {
        this.setState({
            accuracyRating: rating
        });
    }

    cleanlinessRatingCompleted = (rating) => {
        this.setState({
            cleanlinessRating: rating
        });
    }

    overallRatingCompleted = (rating) => {
        this.setState({
            overallRating: rating
        });
    }

    onModalClose = () => {
        this.setState({modalVisible : false});
    }

    showSuccesModal = () => {
        this.setState({
            successModalVisible : true,
            successModalKey: Math.random().toString(36).substring(7),
            successModalText: "You have successfully left a review."
        });
    }

    render() {
        return (
            <ScrollView
                contentContainerStyle={{width: "100%"}}
            >
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.errorModalVisible}
                    key={this.state.errorModalKey}
                    onModalClose={this.onModalClose}
                    labelText={this.state.errorModalText}
                    isError={true}
                />
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.successModalVisible}
                    key={this.state.successModalKey}
                    onModalClose={this.onModalClose}
                    isSuccess={true}
                    labelText={this.state.successModalText}
                />
                <View style={styles.container}>
                    <Container title={this.state.postTitle?`Write a review for ${this.state.postTitle}`:""}>
                        <View style={styles.flexBoxCenter}>
                            <View style={{marginHorizontal: 15}}>
                                <Text style={styles.subHeader}>Accommodations</Text>
                                <AirbnbRating
                                    defaultRating={this.state.accomodationsRating}
                                    onFinishRating={this.accomodationsRatingCompleted}
                                    size={20}
                                    selectedColor='#5d51ef'
                                    reviewColor='#5d51ef'
                                />
                            </View>
                            <View style={{marginHorizontal: 15}}>
                                <Text style={styles.subHeader}>Accuracy</Text>
                                <AirbnbRating
                                    defaultRating={this.state.accuracyRating}
                                    onFinishRating={this.accuracyRatingCompleted}
                                    size={20}
                                    selectedColor='#5d51ef'
                                    reviewColor='#5d51ef'
                                />
                            </View>
                            <View style={{marginHorizontal: 15}}>
                                <Text style={styles.subHeader}>Cleanliness</Text>
                                <AirbnbRating
                                    defaultRating={this.state.cleanlinessRating}
                                    onFinishRating={this.cleanlinessRatingCompleted}
                                    size={20}
                                    selectedColor='#5d51ef'
                                    reviewColor='#5d51ef'
                                />
                            </View>
                            <View style={{marginHorizontal: 15}}>
                                <Text style={styles.subHeader}>Overall</Text>
                                <AirbnbRating
                                    defaultRating={this.state.overallRating}
                                    onFinishRating={this.overallRatingCompleted}
                                    size={20}
                                    selectedColor='#5d51ef'
                                    reviewColor='#5d51ef'
                                />
                            </View>
                        </View>
                        <Text style={styles.subHeader}>Name</Text>
                        <TextInput
                            style={[styles.nameinputBox, {marginTop: 10}]}
                            onChangeText={name => {
                                this.setState({ name: name});
                            }}
                            placeholder='Your name'
                            placeholderTextColor='black'
                            value={this.state.name}
                            key={this.state.nameKey}
                        />
                         <Text style={styles.subHeader}>Review</Text>
                        <TextInput
                            style={[styles.inputBox, {marginTop: 10}, styles.multilineBox]}
                            onChangeText={review => {
                                this.setState({ review: review});
                            }}
                            placeholder='Write your review here'
                            placeholderTextColor='black'
                            value={this.state.review}
                            numberOfLines={4}
                            multiline={true}
                            key={this.state.reviewKey}
                        />
                        <ActionButton 
                            text={"Submit"}
                            handler={()=>{
                                this.submitReview();
                            }}
                            loading={this.state.loading}
                        />
                    </Container>
                </View>
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%",
    },
    button: {
        marginTop: 30,
        marginBottom: 30,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    headerLabeltext: {
        fontSize: 24,
        marginBottom: 10,
        marginTop: 50,
        fontWeight: "700",
        textAlign: "center",
    },
    subHeader: {
        marginTop: 10,
        textAlign: "center",
        fontWeight: "600",
    },
    inputBox: {
        height: 200,
        width: '85%',
        maxWidth: 1000,
        marginBottom: 10,
        padding: 15,
        fontSize: 16,
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        textAlign: 'center',
        backgroundColor: "#f5f5f5",
    },
    nameinputBox: {
        width: '85%',
        maxWidth: 1000,
        marginBottom: 10,
        padding: 15,
        fontSize: 16,
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        textAlign: 'center',
        backgroundColor: "#f5f5f5",
    },
    divider: {
        backgroundColor: "#d3d3d3",
        width: "90%",
        height: 1,
        marginLeft: "5%",
        marginRight: "5%",
        maxWidth: 1200,
        marginTop: 10,
    },
    multilineBox: {
        minHeight: 80,  
    },
    flexBoxCenter: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap", 
        justifyContent: "center",
        alignItems: "center",
    },
})