import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, initializeAuth, signInAnonymously } from "firebase/auth";
import { getDatabase, ref, set, onChildRemoved, 
        onChildChanged, onChildAdded, query, orderByChild, equalTo } from "firebase/database";
import { getReactNativePersistence } from "firebase/auth/react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Platform, View, Dimensions, StatusBar, Image, StyleSheet, Text } from 'react-native';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import { PaperProvider } from 'react-native-paper';
// import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';

import Home from "./pages/Home";
import MyProperties from "./pages/MyProperties";
import Favorites from "./pages/Favorites";
import About from "./pages/About";
import Support from "./pages/Support";
import SignOut from "./pages/SignOut";
import Settings from "./pages/Settings";
import Legal from "./pages/Legal";
import Requests from './pages/Requests';
import MyTrips from './pages/MyTrips';
import Money from './pages/Money';
import MyProfile from './pages/MyProfile';
import MyMessages from './pages/MyMessages';
import HowTo from './pages/HowTo';
import SignUpLogIn from './pages/SignUpLogIn';

import NavFooter from "./compenents/NavFooter";

import { userIsPhysician,
        listenForPhysicianChange } from './helpers/public-user-helper';

const Drawer = createDrawerNavigator();

const firebaseConfig = {
  apiKey: "AIzaSyCIODNwkcmkpXj8u7c34ZqcBflbJUCdJLE",
  authDomain: "doc2doc-d54a2.firebaseapp.com",
  databaseURL: "https://doc2doc-d54a2.firebaseio.com",
  projectId: "doc2doc-d54a2",
  storageBucket: "doc2doc-d54a2.appspot.com",
  messagingSenderId: "555419520968",
  appId: "1:555419520968:web:cc1ff265b45a60e3ce3afd",
  measurementId: "G-8GD7GMTFK1"
}

let fbApp = initializeApp(firebaseConfig);
initializeAuth(fbApp, {
  persistence: getReactNativePersistence(AsyncStorage)
});

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
			user: null,
      userIsDoc: false,
      messageCount: 0,
      unreadUIDs: {},
      messageKey: Math.random().toString(36).substring(7),
      requestCount: 0,
      requestsUIDs: {},
      requestsCount: 0,
      requestsKey: Math.random().toString(36).substring(7),
    };
  } 

  onChange = ({ window, screen }) => {
    this.setState({ dimensions: { window, screen } });
  };

  async componentDidMount() {
    //this.requestTracking();
    this.dimensionsSubscription = Dimensions.addEventListener("change", this.onChange);
    this.onChange({
      window: Dimensions.get('window'), 
      screen: Dimensions.get('screen'), 
    });
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
			if (user) {
        await this.userIsDoc(user);
        //check again for fist time log ins
        setTimeout(async ()=>{
          await this.userIsDoc(user);
        }, 100);
        this.setState({ user }, () => {
          this.configurePushNotifications()
        });
			} else {
        await this.anonymousSignIn();
        this.setState({ user: null });
      }
    });
  }

  // requestTracking = async () => {
  //   if (Platform.OS == "ios") {
  //       const { status } = await requestTrackingPermissionsAsync();
  //       if (status !== 'granted') {
  //       } else {
  //       }
  //   }
  // }

  configurePushNotifications = async () => {
    let token = await this.registerForPushNotificationsAsync();
    this.setState({pushToken: token});
    if (!!token) {
      this.uploadPushToken(token);
    }
  }

  uploadPushToken = (token) => {
    if (Platform.OS != "web") {
        const db = getDatabase();
        const pushRef = ref(db, `pushNotifications/${this.state.user.uid}`);
        let data = {
          enabled: true,
          pushToken: token,
        }
        set(pushRef, data);
    }
  }

  userIsDoc = async (user) => {
    let userIsDoc = await userIsPhysician(user.uid);
    this.setState({
      userIsDoc: userIsDoc,
    });
    if (!!this.state.user) {
      if (userIsDoc) {
        this.getRequestCount();
      }
      this.getMessageCount();
    }
    listenForPhysicianChange(user.uid, userIsPhysician => {
      this.setState({
        userIsDoc: userIsPhysician,
      });
    });
  }

  componentWillUnmount() {
    this.dimensionsSubscription?.remove();
  }

  anonymousSignIn = async () => {
    const auth = getAuth();
    let user = await signInAnonymously(auth)
      .catch( (error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("sign in failed with error code and message: ", errorCode, " -----", errorMessage);
      }
    );
  }

  registerForPushNotificationsAsync = async () => {
    let token;
    if (Device.isDevice && Platform.OS != "web") {
      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        //alert('Failed to get push token for push notification!');
        const db = getDatabase();
        const pushRef = ref(db, `pushNotifications/${this.state.user.uid}`);
        set(pushRef, { enabled: false });
        return;
      }
      token = (await Notifications.getExpoPushTokenAsync()).data;
    } else {
      //alert('Must use physical device for Push Notifications');
    }
  
    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
    return token;
  }

  getMessageCount = async () => {
    const db = getDatabase();
    const messageRef = query(ref(db, `myMessages/${this.state.user.uid}/`));
    const messageRef2 = query(ref(db, `myMessages/${this.state.user.uid}/`), ...[orderByChild("read"), equalTo(false)]);
    onChildChanged(messageRef, (data => {
      let unreadUIDs = this.state.unreadUIDs;
      let messageData = data.val();
      if (!messageData.read) {
        unreadUIDs[messageData.id] = true;
      } else {
        delete unreadUIDs[messageData.id];
      }
      let count = Object.keys(unreadUIDs).length;
      this.setState({
        unreadUIDs: unreadUIDs,
        messageCount: count,
        messageKey: Math.random().toString(36).substring(7),
      })
    }));
    onChildAdded(messageRef2, (data2 => {
      let unreadUIDs = this.state.unreadUIDs;
      let messageData = data2.val();
      if (!messageData.read) {
        unreadUIDs[messageData.id] = true;
      } else {
        delete unreadUIDs[messageData.id];
      }
      let count = Object.keys(unreadUIDs).length;
      this.setState({
        unreadUIDs: unreadUIDs,
        messageCount: count,
        messageKey: Math.random().toString(36).substring(7),
      })
    }));
  }

  getRequestCount = async () => {
    const db = getDatabase();
    try {
      const requestsRef = ref(db, `requests/${this.state.user.uid}/`);
      onChildAdded(requestsRef, (data => {
        let requestsUIDs = this.state.requestsUIDs;
        let requestData = data.val();
        requestsUIDs[requestData.id] = true;
        let count = Object.keys(requestsUIDs).length;
        this.setState({
          requestsUIDs: requestsUIDs,
          requestCount: count,
          requestsKey: Math.random().toString(36).substring(7),
        });
      }));
      onChildRemoved(requestsRef, (data => {
        let requestsUIDs = this.state.requestsUIDs;
        let requestData = data.val();
        delete requestsUIDs[requestData.id];
        let count = Object.keys(requestsUIDs).length;
        this.setState({
          requestsUIDs: requestsUIDs,
          requestCount: count,
          requestsKey: Math.random().toString(36).substring(7),
        })
      }));
    } catch (error) {
        console.log(error)
    }
  }

  render() {
    let drawerType = "front";
    if (!!this.state.dimensions) {
      if (this.state.dimensions.window.width >= 873) {
        drawerType = "permanent";
      }
    }
    if (!this.state.user) {
      return (
        <PaperProvider>
          <View style={{height: Dimensions.get('window').height}}>
            <StatusBar barStyle="dark-content" />
            <NavigationContainer>
              <Drawer.Navigator 
                initialRouteName="Explore"
                backBehavior="history"
                user={this.state.user}
                drawerContent={props => <NavFooter {...props}/>}
                screenOptions={{
                  drawerType: drawerType,
                  drawerStyle: {
                    width: 250,
                  }
                }}
              >
                <Drawer.Screen 
                  name="Explore" 
                  component={Home} 
                  options={{
                    headerShown: false,
                    title: 'Explore',
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/world.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
                <Drawer.Screen 
                  name="How to" 
                  component={HowTo} 
                  options={{
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/how-to-icon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }
                }/>
                <Drawer.Screen 
                  name="Support" 
                  component={Support}
                  options={{
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/contactIcon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
                <Drawer.Screen 
                  name="About" 
                  component={About}
                  options={{
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/aboutUsIcon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
                <Drawer.Screen 
                  name="Legal" 
                  component={Legal}
                  options={{
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/legalIcon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
                <Drawer.Screen 
                  name="Sign Up" 
                  component={SignUpLogIn}
                  options={{
                    title:"Sign Up",
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/signUpIcon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
                <Drawer.Screen 
                  name="Log In" 
                  component={SignUpLogIn}
                  options={{
                    title:"Log In",
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/logInIcon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
              </Drawer.Navigator>
            </NavigationContainer>
          </View>
        </PaperProvider>
      ); 
    } else if (this.state.user.isAnonymous) {
      return (
        <PaperProvider>
          <View style={{height: Dimensions.get('window').height}}>
            <StatusBar barStyle="dark-content" />
            <NavigationContainer>
              <Drawer.Navigator 
                initialRouteName="Explore"
                backBehavior="history"
                user={this.state.user}
                drawerContent={props => <NavFooter {...props}/>}
                screenOptions={{
                  drawerType: drawerType,
                  drawerStyle: {
                    width: 250,
                  }
                }}
              >
                <Drawer.Screen 
                  name="Explore" 
                  component={Home} 
                  options={{
                    title: 'Explore',
                    headerShown: false,
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/world.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
                <Drawer.Screen 
                  name="How to" 
                  component={HowTo} 
                  options={{
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/how-to-icon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }
                }/>
                <Drawer.Screen 
                  name="Support" 
                  component={Support}
                  options={{
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/contactIcon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
                <Drawer.Screen 
                  name="About" 
                  component={About}
                  options={{
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/aboutUsIcon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
                <Drawer.Screen 
                  name="Legal" 
                  component={Legal}
                  options={{
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/legalIcon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
                <Drawer.Screen 
                  name="Sign Up" 
                  component={SignUpLogIn}
                  options={{
                    title:"Sign Up",
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/signUpIcon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
                <Drawer.Screen 
                  name="Log In" 
                  component={SignUpLogIn}
                  options={{
                    title:"Log In",
                    drawerIcon: ({focused, size}) => (
                      <Image
                        source={require('./assets/logInIcon.png')}
                        style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                      />
                  ),
                  }}
                />
              </Drawer.Navigator>
            </NavigationContainer>
          </View>
        </PaperProvider>
      ); 
    } else {
      if (this.state.userIsDoc) {
        return (
          <PaperProvider>
            <View style={{height: Dimensions.get('window').height}}>
              <StatusBar barStyle="dark-content" />
              <NavigationContainer>
                <Drawer.Navigator 
                  initialRouteName="Home"
                  backBehavior="history"
                  user={this.state.user}
                  drawerContent={props => <NavFooter {...props}/>}
                  drawerType={drawerType}
                  screenOptions={{
                    drawerType: drawerType,
                    drawerStyle: {
                      width: 250,
                    }
                  }}
                >
                  <Drawer.Screen 
                    name="Home" 
                    component={Home} 
                    options={{
                      title: 'Explore',
                      headerShown: false,
                      drawerIcon: ({focused, size}) => (
                        <>
                          <Image
                            source={require('./assets/world.png')}
                            style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                          />
                        </>
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="My Properties" 
                    component={MyProperties}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/map-marker-home.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Requests" 
                    component={Requests}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <>
                          <Image
                            source={require('./assets/code-pull-request.png')}
                            style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                          />
                          {
                            this.state.requestCount > 0 ?
                            <Text
                              key={this.state.messageKey}
                              style={[styles.alertIcon, focused ? {borderColor: "#e3efff"}: {borderColor: "white"}]}
                            >{this.state.requestCount}</Text>:
                            null
                          }

                        </>
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Revenue" 
                    component={Money}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/sack-dollar.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Profile" 
                    component={MyProfile}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/user.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="My Trips" 
                    component={MyTrips}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/road.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Messages" 
                    component={MyMessages}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <>
                          <Image
                            source={require('./assets/envelope.png')}
                            style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                          />
                          {
                            this.state.messageCount > 0 ?
                            <Text
                              source={require('./assets/world.png')}
                              key={this.state.messageKey}
                              style={[styles.alertIcon, focused ? {borderColor: "#e3efff"}: {borderColor: "white"}]}
                            >{this.state.messageCount}</Text>:
                            null
                          }
                        </>
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Favorites" 
                    component={Favorites}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/star.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}  
                  />
                  <Drawer.Screen 
                    name="Settings" 
                    component={Settings}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/settings.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="How to" 
                    component={HowTo} 
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/how-to-icon.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }
                  }/>
                  <Drawer.Screen 
                    name="Legal"
                    component={Legal}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/legalIcon.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Support" 
                    component={Support}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/contactIcon.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="About" 
                    component={About}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/aboutUsIcon.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Sign Out" 
                    component={SignOut}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/exit.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                </Drawer.Navigator>
              </NavigationContainer>
            </View>
          </PaperProvider>
        );
      } else {
        return (
          <PaperProvider>
            <View style={{height: Dimensions.get('window').height}}>
              <StatusBar barStyle="dark-content" />
              <NavigationContainer user={this.state.user}>
                <Drawer.Navigator 
                  initialRouteName="Home"
                  backBehavior="history"
                  user={this.state.user}
                  drawerContent={props => <NavFooter {...props}/>}
                  screenOptions={{
                    drawerType: drawerType,
                    drawerStyle: {
                      width: 250,
                    }
                  }}
                >
                  <Drawer.Screen 
                    name="Home" 
                    component={Home} 
                    options={{
                      title: 'Explore',
                      headerShown: false,
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/world.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Profile" 
                    component={MyProfile}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/user.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="My Trips" 
                    component={MyTrips}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/road.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Messages" 
                    component={MyMessages}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <>
                          <Image
                            source={require('./assets/envelope.png')}
                            style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                          />
                          {
                            this.state.messageCount > 0 ?
                            <Text
                              source={require('./assets/world.png')}
                              key={this.state.messageKey}
                              style={[styles.alertIcon, focused ? {borderColor: "#e3efff"}: {borderColor: "white"}]}
                            >{this.state.messageCount}</Text>:
                            null
                          }
                        </>
                      ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Favorites" 
                    component={Favorites}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/star.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}  
                  />
                  <Drawer.Screen 
                    name="Settings" 
                    component={Settings}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/settings.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                      ),
                    }}
                  />
                  <Drawer.Screen 
                    name="How to" 
                    component={HowTo} 
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/how-to-icon.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }
                  }/>
                  <Drawer.Screen 
                    name="Legal" 
                    component={Legal}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/legalIcon.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Support" 
                    component={Support}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/contactIcon.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="About" 
                    component={About}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/aboutUsIcon.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                  <Drawer.Screen 
                    name="Sign Out" 
                    component={SignOut}
                    options={{
                      drawerIcon: ({focused, size}) => (
                        <Image
                          source={require('./assets/exit.png')}
                          style={[focused ? styles.drawerActive : styles.drawerInActive, { height: 20, width: 20 }]}
                        />
                    ),
                    }}
                  />
                </Drawer.Navigator>
              </NavigationContainer>
            </View>
          </PaperProvider>
        );
      } 
    }
  }
}

const styles = StyleSheet.create({
  drawerActive: {
    tintColor: "#157efb",
  },
  drawerInActive: {
    tintColor: "grey",
  },
  alertIcon: {
    height: 24,
    width: 24,
    backgroundColor: "red", 
    borderRadius: 12, 
    position: "absolute",
    borderWidth: 2,
    color: "white",
    fontWeight: "600",
    textAlign: "center",
    alignContent: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    paddingTop: Platform.OS == "web" ? 2 : 3,
    overflow: "hidden",
    textAlignVertical: 'center',
    left: 18,
  },
});
