import React, { Component } from 'react';
import { StyleSheet, Text, View, ScrollView, Dimensions, ActivityIndicator} from 'react-native';
import { getDatabase, ref, get, orderByKey, set, query, limitToFirst, startAt } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AirbnbRating } from 'react-native-ratings';
import ReadMore from 'react-native-read-more-text';

import Modal from "../compenents/Modal";
import ActionButton from '../compenents/ActionButton';
import Container from '../compenents/Container';

export default class ReadReviews extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reviewData: null,
            lastIndex: 0,
            modalVisible: false,
            modalKey: Math.random().toString(36).substring(7),
            isLoading: true,
        }
    }

    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user });
            }
        });
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.renderPage(); 
        });
        this.renderPage(); 
    }

    renderPage = () => {
        this.setState({
            reviewData: null,
            lastIndex: 0,
        }, () => {
            this.getReviews(); 
        });
    }

    getReviews = () => {
        if (this.state.lastIndex == 0) {
            const db = getDatabase();
            const reviewsRef =  query(ref(db, `reviews/${this.props.route.params.postID}`), orderByKey(), limitToFirst(25));
            get(reviewsRef).then((snapshot) => {
                if (snapshot.val() != undefined && snapshot.val() != null) {
                    const reviewsData = snapshot.val();
                    let newLastIndex = Object.keys(reviewsData).length;
                    this.setState({
                        reviewData: reviewsData,
                        lastIndex: newLastIndex,
                        isLoading: false,
                    });
                }
            });
        } else {
            let keyArray = Object.keys(this.state.reviewData);
            let lastPostKey = keyArray[Object.keys(this.state.reviewData).length - 1];
            const db = getDatabase();
            const reviewsRef =  query(ref(db, `reviews/${this.props.route.params.postID}`), orderByKey(), startAt(lastPostKey), limitToFirst(25));
            get(reviewsRef).then((snapshot) => {
                if (snapshot.val() != undefined && snapshot.val() != null) {
                    const reviewsData = snapshot.val();
                    if (this.state.reviewData == null) {
                        let newLastIndex = Object.keys(reviewsData).length;
                        this.setState({
                            reviewData : reviewsData,
                            lastIndex: newLastIndex,
                        });
                    } else {
                        let newData = this.state.reviewData;
                        for (var key in reviewsData) {
                            let reviewData = reviewsData[key];
                            newData[key] = reviewData;
                        }
                        let newLastIndex = Object.keys(newData).length;
                        this.setState({
                            reviewData : newData,
                            lastIndex: newLastIndex,
                        });
                    }
                } else {
                    console.log("no more reviews found");
                }
            })
        }
    }


    getReviewData = (postUID, callback) => {
        const db = getDatabase();
        const reviewRef = ref(db, `reviewData/${postUID}`);
        get(reviewRef).then((snapshot) => {
            if (snapshot.val() != undefined && snapshot.val() != null) {
                const reviewsData = snapshot.val();
                callback(reviewsData)
            } else {
                callback(null)
            }
        });
    }

    editReview = (reviewID) => {
        this.props.navigation.navigate("Write Review", {
            postID: this.props.route.params.postID,
            reviewID: reviewID,
        });
    }

    promptToDeleteReview = (key) => {
        this.setState({
            modalVisible: true,
            modalKey: Math.random().toString(36).substring(7),
            selectedReview: key
        });
    }

    onModalClose = () => {
        this.setState({modalVisible : false});
    }

    onConfirmClick = () => {
        let reviewData = this.state.reviewData[this.state.selectedReview];
        this.getReviewData(reviewData.listingID, data => {
            if (!!data) {
                //lower the totalReviewCount by 1 for reviewData
                //subtract the values of the ratings for reviewData
                let newReviewData = data;
                newReviewData.totalReviewCount = (newReviewData.totalReviewCount - 1);
                newReviewData.accommodations = (newReviewData.accommodations - reviewData.accommodations);
                newReviewData.accuracy = (newReviewData.accuracy - reviewData.accuracy);
                newReviewData.cleanliness = (newReviewData.cleanliness - reviewData.cleanliness);
                newReviewData.overall = (newReviewData.overall - reviewData.overall);

                const reviewDataRef = ref(db, `reviewData/${this.props.route.params.postID}`);
                set(reviewDataRef, newReviewData);
                const reviewsRef = ref(db, `reviews/${this.props.route.params.postID}/${this.state.selectedReview}`);
                set(reviewsRef, null);
                
                this.renderPage()
            } else {
                console.log("error");
            }
        });
    }

    buildView = () => {
        let parent = [];
        if (!!this.state.reviewData) {
            let accomodationsLabel = ["Accommodations", "Accommodations", "Accommodations", "Accommodations", "Accommodations"];
            let accuracyLabel = ["Accuracy", "Accuracy", "Accuracy", "Accuracy", "Accuracy"];
            let cleanlinessLabel = ["Cleanliness", "Cleanliness", "Cleanliness", "Cleanliness", "Cleanliness"];
            let overallLabel = ["Overall", "Overall", "Overall", "Overall", "Overall"];
            let keyCount = 0;
            for (var key in this.state.reviewData) {
                let child = [];
                keyCount += 1;
                let review = this.state.reviewData[key];
                let accomodationsRating = Math.round(review.accommodations);
                let accuracyRating = Math.round(review.accuracy);
                let cleanlinessRating = Math.round(review.cleanliness);
                let overallRating = Math.round(review.overall);
                let reviewDate = new Date(review.date);
                var dd = reviewDate.getDate();
                var mm = reviewDate.getMonth()+1; 
                var yyyy = reviewDate.getFullYear();
                let reviewDateOutput = mm+'/'+dd+'/'+yyyy;
                review["key"] = key;
                child.push(
                    <View style={styles.nameDateWrapperWrapper}>
                        <View style={styles.nameDateWrapper}>
                            <Text allowFontScaling={false} style={styles.dateLabel}>{reviewDateOutput}</Text>
                        </View>
                    </View>,
                    <View style={styles.flexBoxCenter}>
                        <View style={{marginHorizontal: 15}}>
                            <AirbnbRating
                                defaultRating={accomodationsRating}
                                isDisabled={true}
                                reviewSize={14}
                                reviews={accomodationsLabel}
                                reviewColor={"black"}
                                size={20}
                                selectedColor='#5d51ef'
                            />
                        </View>
                        <View style={{marginHorizontal: 15}}>
                            <AirbnbRating
                                defaultRating={accuracyRating}
                                isDisabled={true}
                                reviewSize={14}
                                reviews={accuracyLabel}
                                reviewColor={"black"}
                                size={20}
                                selectedColor='#5d51ef'
                            />
                        </View>
                        <View style={{marginHorizontal: 15}}>
                            <AirbnbRating
                                defaultRating={cleanlinessRating}
                                isDisabled={true}
                                reviewSize={14}
                                reviews={cleanlinessLabel}
                                reviewColor={"black"}
                                size={20}
                                selectedColor='#5d51ef'
                            />
                        </View>
                        <View style={{marginHorizontal: 15}}>
                            <AirbnbRating
                                defaultRating={overallRating}
                                isDisabled={true}
                                reviewSize={14}
                                reviews={overallLabel}
                                reviewColor={"black"}
                                size={20}
                                selectedColor='#5d51ef'
                            />
                        </View>
                    </View>,
                    <View style={styles.reviewTextWrapper}>
                        <ReadMore numberOfLines={5}>
                            <Text allowFontScaling={false} style={styles.reviewText}>
                                {review.review} - {review.reviewerName}
                            </Text>
                        </ReadMore>
                    </View>,
                );
                if (review.reviewerUID == this.state.user.uid) {
                    child.push(
                        <View style={styles.buttonWrapper}>
                            <ActionButton 
                                text={"Edit"}
                                handler={()=>{
                                    this.props.navigation.navigate("Write Review", {
                                        postID: this.props.route.params.postID,
                                        reviewID: review.key,
                                    });
                                }}
                            />
                            <ActionButton 
                                text={"Delete"}
                                handler={()=>{
                                    this.promptToDeleteReview(review.key)
                                }}
                                filled={true}
                            />
                        </View>
                    )
                }
                parent.push(
                    <Container>
                        {child}
                    </Container>
                )
            }
        } else {
            if (this.state.isLoading) {
                parent.push(<ActivityIndicator size="large" style={styles.activityIndicator}/>);
            } else {
                parent.push(<Text allowFontScaling={false} style={styles.headerLabeltext}>No reviews found</Text>);
            }
        }
        return parent;
    }

    render() {
        return (
            <ScrollView contentContainerStyle={{width: "100%"}}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.modalVisible}
                    key={this.state.modalKey}
                    onModalClose={this.onModalClose}
                    onConfirmClick={() => this.onConfirmClick()}
                    labelText={"Would you like to delete this review?"}
                />
                <View style={styles.container}>
                    {this.buildView()}
                    <ActionButton 
                        text={"View More"}
                        handler={()=>{
                            this.getReviews()
                        }}
                    />
                </View>
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%",
        paddingBottom: 40,
    },
    reviewWrapper: {
        width: "100%",
    },
    buttonWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },  
    button: {
        marginTop: 10,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonBlue: {
        marginBottom: 30,
        paddingVertical: 5,
        marginTop: 20,
        alignItems: 'center',
        backgroundColor: '#3a93f1',
        borderColor: '#2790ff',
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
        margin: "auto",
    },
    buttonRed: {
        marginBottom: 20,
        marginTop: 10,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#f13a3a',
        borderColor: '#ff2323',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    headerLabeltext: {
        marginTop: 50,
        fontSize: 24,
        marginBottom: 10,
        fontWeight: "700",
        textAlign: "center",
    },
    divider: {
        backgroundColor: "#d3d3d3",
        width: Dimensions.get('window').height * 2,
        marginTop: 10,
        left: 0,
        marginBottom: 10,
        borderBottomWidth: 20,
        borderBottomColor: "#9a9a9a",
    },
    nameDateWrapperWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        marginTop: 10,
    },
    nameDateWrapper: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        width: "90%",
        maxWidth: 800,
    },
    dateLabel: {
        fontWeight: "600",
        textAlign: "left",
        color: "grey",
    },
    reviewerNameLabel: {
        fontWeight: "600",
        textAlign: "right",
        flex: 1,
    },
    reviewText: {
        padding: 10,
        margin: 10,
        maxWidth: 800,
        fontSize: 18,
        fontWeight: "200",
    },  
    reviewTextWrapper: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginVertical: 10,
    },
    activityIndicator: {
        zIndex: 1,
    },
    flexBoxCenter: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap", 
        justifyContent: "center",
        alignItems: "center",
    },
})