import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, Image, TouchableHighlight, Alert } from 'react-native';
import ActionButton from '../compenents/ActionButton';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase, ref, get } from "firebase/database";

import Container from "../compenents/Container";
import Modal from "../compenents/Modal";
import Loader from '../compenents/Loader';
import GoldInfo from '../compenents/GoldInfo';
import SilverInfo from '../compenents/SilverInfo';
import BronzeInfo from '../compenents/BronzeInfo';

export default class Subscription extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            bronzeSelected: false,
            silverSelected: false,
            goldSelected: false,
            errorModalVisible: false,
            errorModalKey: Math.random().toString(36).substring(7),
            loading: false,
            loadingKey: Math.random().toString(36).substring(7),
            changeLevelSelected: false,
            verified: false,
            promptModalVisible: false,
            promptModalKey: Math.random().toString(36).substring(7),
            promptModalMessage: "",
            expiration: null,
            onConfirm: null,
        };
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.getUser();
        });
        this.getUser();
    }

    getUser = () => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user }, () => {
                    this.confirgureView();
                });
            } else {
                this.setState({ user: null });
            }
        });
    }

    confirgureView = async () => {
        if (!!this.props.route.params) {
            if (this.props.route.params.rentalData) {
                this.setState({
                    loading: true,
                    loadingKey: Math.random().toString(36).substring(7),
                });
                let verified = true;
                if (!!this.props.route.params.rentalData.subscriptionLevel) {
                    if (this.props.route.params.rentalData.subscriptionLevel != "silver") {
                        verified = await this.verifySubscription(this.props.route.params.rentalData.key);
                    }
                } else {
                    verified = await this.verifySubscription(this.props.route.params.rentalData.key);
                }
                this.setState({
                    loading: false,
                    loadingKey: Math.random().toString(36).substring(7),
                    verified: verified,
                });
                let expiration = await this.getExpiration(this.props.route.params.rentalData.key);
                if (verified) {
                    if (this.props.route.params.rentalData.subscriptionLevel != null) {
                        if (this.props.route.params.rentalData.subscriptionLevel == "silver") {
                            this.setState({ 
                                bronzeSelected: false,
                                silverSelected: true,
                                goldSelected: false,
                                expiration: expiration,
                            });
                        } else if (this.props.route.params.rentalData.subscriptionLevel == "gold") {
                            this.setState({ 
                                bronzeSelected: false,
                                silverSelected: false,
                                goldSelected: true,
                                expiration: expiration,
                            });
                        } else {
                            this.setState({ 
                                bronzeSelected: true,
                                silverSelected: false,
                                goldSelected: false,
                                expiration: expiration,
                            });
                        }
                    } else {
                        this.setState({ 
                            bronzeSelected: true,
                            silverSelected: false,
                            goldSelected: false,
                            expiration: expiration,
                        });
                    }
                } else {
                    this.setState({ 
                        bronzeSelected: false,
                        silverSelected: false,
                        goldSelected: false,
                        expiration: expiration,
                    });
                }
            }
        }
    }

    verifySubscription = async (postID) => {
        const functions = getFunctions();
        const verifyPaypalSubscription = httpsCallable(functions, 'verifyPaypalSubscription');
        let result = await verifyPaypalSubscription({postID: postID})
        const data = result.data;
        if (data.response == "success") {
            if (data.valid) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getExpiration = async (postID) => {
        const db = getDatabase();
        const rentalsRef = ref(db, `rentals/${postID}`);
        let snapshot = await get(rentalsRef)
        const myRentalsData = snapshot.val();
        if (myRentalsData !== null) {
            return myRentalsData.expiration
        }
    }

    bronzeSelect = () => {
        this.setState({ 
            bronzeSelected: true,
            silverSelected: false,
            goldSelected: false,
        });
    }

    silverSelect = () => {
        this.setState({ 
            bronzeSelected: false,
            silverSelected: true,
            goldSelected: false,
        })
    }

    goldSelect = () => {
        this.setState({ 
            bronzeSelected: false,
            silverSelected: false,
            goldSelected: true,
        })
    }

    promptToCancel = (message, onConfirm) => {
        this.setState({
            promptModalVisible: true,
            promptModalKey: Math.random().toString(36).substring(7),
            promptModalMessage: message,
            onConfirm: onConfirm,
        })
    }

    nextButtonPress = () => {
        if (this.state.bronzeSelected ||
            this.state.silverSelected ||
            this.state.goldSelected) {
                if (this.props.route.params.newRentalData != null) {
                    let checkoutData = this.props.route.params.newRentalData;
                    if (!this.state.bronzeSelected) {
                        if (this.state.silverSelected) {
                            checkoutData.subscriptionLevel = "silver";
                        } else {
                            checkoutData.subscriptionLevel = "gold";
                        }
                        this.props.navigation.navigate("Special Options", {
                            newRentalData: checkoutData,
                        });
                    } else {
                        checkoutData.subscriptionLevel = "bronze";
                        this.props.navigation.navigate("Special Options", {
                            newRentalData: checkoutData,
                        });
                    }
                } else if (this.props.route.params.rentalData != null) {
                    let data = {...this.props.route.params.rentalData};
                    data.photoUrls = this.props.route.params.rentalData.photoUrls;
                    if (!!this.props.route.params.rentalData.searchTerms) {
                        data.searchTerms = this.props.route.params.rentalData.searchTerms;
                    }
                    data["email"] = this.state.user.email;
                    if (this.state.bronzeSelected) {
                        if (data.subscriptionLevel == "bronze" && this.state.verified) {
                            this.setState({
                                errorModalVisible: true,
                                errorModalKey: Math.random().toString(36).substring(7),
                                errorMessage: "This listing is already a bronze listing."
                            });
                        } else if (data.subscriptionLevel == undefined && this.state.verified) {
                            this.setState({
                                errorModalVisible: true,
                                errorModalKey: Math.random().toString(36).substring(7),
                                errorMessage: "This listing is already a bronze listing."
                            });
                        } else {
                            data.subscriptionLevel = "bronze";
                            this.props.navigation.navigate("Check Out", {
                                newRentalData: data,
                                checkOutType: "bronze",
                                subscriptionChange: true,
                            });
                        }
                    } else if (this.state.silverSelected) {
                        //we have to check if they are LTR or for sale
                        if (!!data.listingType && (data.listingType == "ltr" || data.listingType == "forSale" )) {
                            this.setState({
                                errorModalVisible: true,
                                errorModalKey: Math.random().toString(36).substring(7),
                                errorMessage: "Silver level subscriptions are for short term rentals only. Update your listing to STR to switch to silver."
                            });
                        } else {
                            if (data.subscriptionLevel == "silver" && this.state.verified) {
                                this.setState({
                                    errorModalVisible: true,
                                    errorModalKey: Math.random().toString(36).substring(7),
                                    errorMessage: "This listing is already a silver listing."
                                });
                            } else {
                                if (this.state.verified) {
                                    this.promptToCancel("In order to continue you must cancel your existing subscription. Would you like to cancel it and continue?", this.cancelAndContinue);
                                } else {
                                    data.subscriptionLevel = "silver";
                                    this.props.navigation.navigate("Special Options", {
                                        newRentalData: data,
                                        subscriptionChange: true,
                                    });
                                }
                            }
                        }
                    } else {
                        if (this.state.goldSelected) {
                            if (data.subscriptionLevel == "gold" && this.state.verified) {
                                this.setState({
                                    errorModalVisible: true,
                                    errorModalKey: Math.random().toString(36).substring(7),
                                    errorMessage: "This listing is already a gold listing."
                                });
                            } else {
                                this.promptToCancel("In order to continue you must cancel your existing subscription. Would you like to cancel it and continue?", this.cancelAndContinue);
                            }
                        }
                    }
                }
        } else {
            this.setState({
                errorModalVisible: true,
                errorModalKey: Math.random().toString(36).substring(7),
                errorMessage: "Please select a subscription level to continue."
            });
        }
    }

    buildButtons = () => {
        let parent = [];
        parent.push(
            <TouchableHighlight onPress={() => this.bronzeSelect()} underlayColor='transparent'>
                <View style={[this.state.bronzeSelected ? styles.subscriptionTypeWrapperSelected : null]}>
                    <BronzeInfo/>
                </View>    
            </TouchableHighlight>
        )
        parent.push(
            <TouchableHighlight onPress={() => this.silverSelect()} underlayColor='transparent'>    
                <View style={[this.state.silverSelected ? styles.subscriptionTypeWrapperSelected : null]}>
                    <SilverInfo/>
                </View>
            </TouchableHighlight>
        );
        parent.push(
            <TouchableHighlight onPress={() => this.goldSelect()} underlayColor='transparent'>    
                <View style={[this.state.goldSelected ? styles.subscriptionTypeWrapperSelected : null]}>
                    <GoldInfo/>
                </View>
            </TouchableHighlight>
        );
        return parent;
    }

    buildLabelView = () => {
        let parent = [];
        if (!!this.props.route.params) {
            if (this.props.route.params.rentalData) {
                let level = "bronze";
                if (!!this.props.route.params.rentalData.subscriptionLevel) {
                    level = this.props.route.params.rentalData.subscriptionLevel;
                }
                let image = [];
                if (level == "gold") {
                    image.push(<GoldInfo/>);
                } else if (level == "silver") {
                    image.push(<SilverInfo/>);
                } else {
                    image.push(<BronzeInfo/>)
                }

                if (!this.state.loading) {
                    let expirationDate = "";
                    if (!!this.state.expiration) {
                        expirationDate = new Date(this.state.expiration).toLocaleDateString();
                    }
                    if (this.state.verified) {
                        parent.push(
                            <Container>
                                <Text style={styles.label}>My Subscription</Text>
                                <Text style={{padding: 5}}>
                                    Your current subscription level for this listing is:
                                </Text>
                                {image}
                                { level != "silver"? 
                                    <Text style={{padding: 10}}>
                                        Next payment date: <Text style={{fontWeight: "600"}}>{expirationDate}</Text>
                                    </Text>:
                                    null
                                }
                                <ActionButton
                                    text="Change Level"
                                    handler={()=>{
                                        this.setState({changeLevelSelected: true});
                                    }}
                                />
                                { level != "silver"? 
                                     <ActionButton
                                        text="Cancel Subscription"
                                        filled={true}
                                        handler={()=>{
                                            //prompt to cancel subscription
                                            this.promptToCancel("Would you like to cancel your subscription?", this.requestCancelSubscription);
                                        }}
                                    />: 
                                    null
                                }
                            </Container>
                        )
                    } else {
                        parent.push(
                            <Container>
                                <Text style={styles.label}>My Subscription</Text>
                                <Text style={{padding: 10}}>
                                    Your subscription for this listng is currently inactive.
                                </Text>
                                <Text style={{padding: 10}}>
                                    Listing will expire: <Text style={{fontWeight: "600"}}>{expirationDate}</Text>
                                </Text>
                                <ActionButton
                                    text="Update Subscription"
                                    handler={()=>{
                                        this.setState({changeLevelSelected: true});
                                    }}
                                />
                            </Container>
                        )
                    }
                }
            }
        }
        return parent;
    }

    buildSelectSubscriptionView = () => {
        let parent = [];
        if (!!this.props.route.params) {
            if (this.props.route.params.newRentalData != null) {
                parent.push(
                    <Container title={"Select a Subscription Level"}>
                        <Text style={{padding: 5}}>Each listing has its own subscription.</Text>
                        <Text style={{paddingBottom: 5}}>Please select the subscription level that best meets your business needs.</Text>
                        <View style={styles.subscriptionWrapper}>
                            { this.buildButtons() }
                        </View>
                        {/* { this.buildActionButtons() } */}
                        <View>
                            <ActionButton 
                                text={"Next"}
                                handler={()=>{
                                    this.nextButtonPress()
                                }}
                            />
                        </View>
                    </Container>
                )
            } else if (this.state.changeLevelSelected) {
                parent.push(
                    <Container title={"Select a Subscription Level"}>
                        <Text style={{padding: 5}}>Each listing has its own subscription.</Text>
                        <Text style={{paddingBottom: 5}}>Please select the subscription level that best meets your business needs.</Text>
                        <View style={styles.subscriptionWrapper}>
                            { this.buildButtons() }
                        </View>
                        {/* { this.buildActionButtons() } */}
                        <View>
                            <ActionButton 
                                text={"Next"}
                                handler={()=>{
                                    this.nextButtonPress()
                                }}
                            />
                        </View>
                    </Container>
                )
            }
        }
        return parent;
    }

    requestCancelSubscription = async () => {
        let url = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/cancelPost?";
        url += "posterUID="+this.state.user.uid;
        url += "&postID="+this.props.route.params.rentalData.key;
        let response = await fetch(url)
        let data = await response.json();
        if (!!data.response) {
            if (data.response == "success") {
                return true;
            } else {
                return false;
            }
        }
        this.confirgureView();
    }

    cancelAndContinue = async () => {
        let success = await this.requestCancelSubscription();
        if (success) {
            let checkoutData = {...this.props.route.params.rentalData};
            checkoutData.photoUrls = this.props.route.params.rentalData.photoUrls;
            if (!!this.props.route.params.rentalData.searchTerms) {
                checkoutData.searchTerms = this.props.route.params.rentalData.searchTerms;
            }
            if (this.state.silverSelected) {
                checkoutData.subscriptionLevel = "silver";
            } else if (this.state.bronzeSelected) {
                checkoutData.subscriptionLevel = "bronze";
            } else {
                checkoutData.subscriptionLevel = "gold";
            }
            checkoutData["email"] = this.state.user.email;
            if (this.state.bronzeSelected) {
                this.props.navigation.navigate("Check Out", {
                    newRentalData: checkoutData,
                    checkOutType: "bronze",
                    subscriptionChange: true,
                });
            } else {
                this.props.navigation.navigate("Special Options", {
                    newRentalData: checkoutData,
                    subscriptionChange: true,
                });
            }
        } else {
            Alert.alert("canceling your subscripton failed")
        }
    }

    confirmClick = () => {
        if (!!this.state.onConfirm) {
            this.state.onConfirm();
        }
    }

    render() {
        return (
            <ScrollView contentContainerStyle={styles.container}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.errorModalVisible}
                    key={this.state.errorModalKey}
                    onModalClose={this.onModalClose}
                    labelText={this.state.errorMessage}
                    isError={true}
                />
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.promptModalVisible}
                    key={this.state.promptModalKey}
                    onModalClose={this.onModalClose}
                    onConfirmClick={() => {
                        this.confirmClick();
                    }}
                    labelText={this.state.promptModalMessage}
                />
                <Loader visible={this.state.loading} key={this.state.loadingKey}/>
                { this.buildLabelView() }
                { this.buildSelectSubscriptionView() }
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',

    },
    mt20: {
        marginTop: 20,
    },
    centeredText: {
        textAlign: "center",
    },
    payment: {
        width: 500,
        height: 600,
    },
    subscriptionWrapper: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "100%",
        padding: 10,
    },
    subscriptionTypeWrapper: {
        width: 200,
        borderWidth: 1,
        borderColor: "lightgrey",
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
    },
    subscriptionTypeWrapperSelected: {
        borderWidth: 5,
        borderColor: "#5d51ef",
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
    },
    subscriptionTypeHeaderText: {
        fontSize: 12,
        fontWeight: "600",
        textAlign: "left",
        width: "100%",
        paddingLeft: 15,
    },
    subscriptionLevelIcon: {
        width: 60,
        height: 60,
    },
    dot: {
        fontSize: 5,
    },
    dotWrapper: {
        width: 20,
        paddingBottom: 5,
        position: "absolute",
        top: 3,
    },
    optionWrapper: {
        width: "100%",
    },  
    subHeaderLabel: {
        fontSize: 14,
        fontWeight: "600",
        textAlign: "center",
    },
    button: {
        marginTop: 20,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    label: {
        margin: 5,
        marginTop: 10,
        fontWeight: "600",
    },
})