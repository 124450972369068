import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, Dimensions, Image, TouchableHighlight, Platform } from 'react-native';
import { getDatabase, ref, get, set, push } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Calendar } from 'react-native-calendars';

import { requestUpdate } from '../helpers/post-interactons';
import { getMonth } from "../helpers/date-formatter";

import Container from "../compenents/Container";
import Stepper from "../compenents/Stepper";
import TopAlert from '../compenents/TopAlert';
import ActionButton from '../compenents/ActionButton';
import Modal from '../compenents/Modal';
import CheckBox from '../compenents/CheckBox';
import ExpandableView from "../compenents/ExpandableView"

export default class Pricing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            publicPrice: null,
            physicianPrice: null,
            successIsShowing: false,
            publicPriceKey: Math.random().toString(36).substring(7),
            physicianPriceKey: Math.random().toString(36).substring(7),
            cleaningFeeKey: Math.random().toString(36).substring(7),
            petFeeKey: Math.random().toString(36).substring(7),
            dailyPhysicianModalKey: Math.random().toString(36).substring(7),
            dailyPhysicianModalVisible: false,
            dailyPublicModalKey: Math.random().toString(36).substring(7),
            dailyPublicModalVisible: false,
            variablePublicPriceKey: Math.random().toString(36).substring(7),
            variablePhysicianPriceKey: Math.random().toString(36).substring(7),
            calendarKey:  Math.random().toString(36).substring(7),
            user: null,
            variablePublicPrice: false,
            variablePhysicianPrice: false,
            publicPriceData: null,
            physicianPriceData: null,
            selectedPublicDate: null,
            selectedPhysicianDate: null,
        };
    } 

    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
			if (user) {
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
        });
        this.configurePage();
        if (Platform.OS == "web") {
            window.addEventListener('resize', this.updateView);
        }
    }

    updateView = () => {
        this.setState({ calendarKey:  Math.random().toString(36).substring(7)});
    };

    configurePage = () => {
        if (!!this.props.route.params) {
            if (this.props.route.params.newRentalData) {
                let publicPrice = null;
                let physicianPrice = null;
                let cleaningFee = null;
                let petFee = null;
                let variablePhysicianPrice = false;
                let variablePublicPrice = false;
                let publicPriceData = null;
                let physicianPriceData = null;
                if (this.props.route.params.newRentalData.publicPrice != null) {
                    publicPrice = this.props.route.params.newRentalData.publicPrice;
                }
                if (this.props.route.params.newRentalData.physicianPrice != null) {
                    physicianPrice = this.props.route.params.newRentalData.physicianPrice;
                }
                if (this.props.route.params.newRentalData.petFee != null) {
                    petFee = this.props.route.params.newRentalData.petFee;
                }
                if (this.props.route.params.newRentalData.cleaningFee != null) {
                    cleaningFee = this.props.route.params.newRentalData.cleaningFee
                }
                if (this.props.route.params.newRentalData.variablePhysicianPrice != null) {
                    variablePhysicianPrice = this.props.route.params.newRentalData.variablePhysicianPrice;
                }
                if (this.props.route.params.newRentalData.variablePublicPrice != null) {
                    variablePublicPrice = this.props.route.params.newRentalData.variablePublicPrice;
                }
                if (this.props.route.params.newRentalData.publicPriceData != null) {
                    publicPriceData = this.props.route.params.newRentalData.publicPriceData;
                }
                if (this.props.route.params.newRentalData.physicianPriceData != null) {
                    physicianPriceData = this.props.route.params.newRentalData.physicianPriceData;
                }
                this.setState({
                    publicPrice: publicPrice,
                    physicianPrice: physicianPrice,
                    cleaningFee: cleaningFee,
                    petFee: petFee,
                    publicPriceKey: Math.random().toString(36).substring(7),
                    physicianPriceKey: Math.random().toString(36).substring(7),
                    cleaningFeeKey: Math.random().toString(36).substring(7),
                    petFeeKey: Math.random().toString(36).substring(7),
                    variablePublicPriceKey: Math.random().toString(36).substring(7),
                    variablePhysicianPriceKey: Math.random().toString(36).substring(7),
                    variablePublicPrice: variablePublicPrice,
                    variablePhysicianPrice: variablePhysicianPrice,
                    physicianPriceData,
                    publicPriceData,
                });
                if (!!this.props.route.params.newRentalData.variablePublicPrice && this.props.route.params.newRentalData.variablePublicPrice) {
                    if (this.props.route.params.newRentalData.public) {
                        this.getPublicPriceData(this.props.route.params.newRentalData);
                        this.getPhysicianPriceData(this.props.route.params.newRentalData);
                    } else {
                        this.getPhysicianPriceData(this.props.route.params.newRentalData);
                    }
                }
            } else if (this.props.route.params.rentalData) {
                this.getPriceData(this.props.route.params.rentalData);
                let cleaningFee = null;
                let petFee = null;
                let variablePhysicianPrice = false;
                let variablePublicPrice = false;
                if (this.props.route.params.rentalData.petFee != null) {
                    petFee = this.props.route.params.rentalData.petFee;
                }
                if (this.props.route.params.rentalData.cleaningFee != null) {
                    cleaningFee = this.props.route.params.rentalData.cleaningFee
                }
                if (this.props.route.params.rentalData.variablePhysicianPrice != null) {
                    variablePhysicianPrice = this.props.route.params.rentalData.variablePhysicianPrice;
                }
                if (this.props.route.params.rentalData.variablePublicPrice != null) {
                    variablePublicPrice = this.props.route.params.rentalData.variablePublicPrice;
                }
                this.setState({
                    cleaningFee: cleaningFee,
                    petFee: petFee,
                    publicPriceKey: Math.random().toString(36).substring(7),
                    physicianPriceKey: Math.random().toString(36).substring(7),
                    cleaningFeeKey: Math.random().toString(36).substring(7),
                    petFeeKey: Math.random().toString(36).substring(7),
                    publicPriceKey: Math.random().toString(36).substring(7),
                    variablePublicPriceKey: Math.random().toString(36).substring(7),
                    variablePhysicianPriceKey: Math.random().toString(36).substring(7),
                    variablePublicPrice: variablePublicPrice,
                    variablePhysicianPrice: variablePhysicianPrice,
                });
                if (!!this.props.route.params.rentalData.variablePublicPrice && this.props.route.params.rentalData.variablePublicPrice) {
                    this.getPublicPriceData(this.props.route.params.rentalData);
                }
                if (!!this.props.route.params.rentalData.variablePhysicianPrice && this.props.route.params.rentalData.variablePhysicianPrice) {
                    this.getPhysicianPriceData(this.props.route.params.rentalData);
                }
            }
        }
    }

    getPriceData = (rentalData) => {
        const db = getDatabase();
        let physicianPriceRef = ref(db, `physicianPrice/${rentalData.key}`);
        if (rentalData.public) {
            let publicPriceRef = ref(db, `publicPrice/${rentalData.key}`);
            get(publicPriceRef).then((snapshot) => {
                let publicPriceData = snapshot.val();
                if (!!publicPriceData) {
                    this.setState({
                        publicPrice: publicPriceData.price,
                        publicPriceKey: Math.random().toString(36).substring(7),
                    });
                }
            });
            get(physicianPriceRef).then((snapshot) => {
                let docPriceData = snapshot.val();
                if (!!docPriceData) {
                    this.setState({
                        physicianPrice: docPriceData.price,
                        physicianPriceKey: Math.random().toString(36).substring(7),
                    });
                }
            });
        } else {
            get(physicianPriceRef).then((snapshot) => {
                let docPriceData = snapshot.val();
                if (!!docPriceData) {
                    this.setState({
                        physicianPrice: docPriceData.price,
                        physicianPriceKey: Math.random().toString(36).substring(7),
                    });
                }
            });
        }
    }

    getPhysicianPriceData = (rentalData) => {
        const db = getDatabase();
        let physicianPriceRef = ref(db, `variablePhysicianPrice/${rentalData.key}`);
        get(physicianPriceRef).then((snapshot) => {
            let physicianPriceData = snapshot.val();
            if (!!physicianPriceData) {
                this.setState({
                    physicianPriceData: physicianPriceData,
                });
            }
        });
    }

    getPublicPriceData = (rentalData) => {
        const db = getDatabase();
        let publicPriceRef = ref(db, `variablePublicPrice/${rentalData.key}`);
        get(publicPriceRef).then((snapshot) => {
            let publicPriceData = snapshot.val();
            if (!!publicPriceData) {
                this.setState({
                    publicPriceData: publicPriceData,
                });
            }
        });
    }

    buildPetFee = () => {
        let parent = [];
        if (this.props.route.params.newRentalData != null || this.props.route.params.rentalData != null) {
            let data = this.props.route.params.newRentalData;
            if (data == null) {
                data = this.props.route.params.rentalData ;
            }
            if (data.petFriendly) {
                parent.push(
                    <Container title={"Pet Fee (optional)"}>
                        <Text style={styles.sublabel}>Enter your pet fee</Text>
                        <View style={styles.priceWrapper}>
                            <Stepper
                                navigation={this.props.navigation}
                                count={this.state.petFee}
                                key={this.state.petFeeKey}
                                money={true}
                                handler={petFee => {
                                    this.setState({petFee: petFee})
                                }}
                            />
                        </View>
                    </Container>
                )
            }
        }
        return parent;
    }

    saveDraft = (checkoutData) => {
        let save = false;
        if (this.props.route.params.subscriptionChange == null) {
            save = true;
        } else if (!this.props.route.params.subscriptionChange) {
            save = true;
        }
        if (save) {
            const db = getDatabase();
            const draftsRef = ref(db, `drafts/${this.state.user.uid}/${checkoutData.key}`);
            set(draftsRef, checkoutData);
        }
    }

    saveButtonPress = async () => {
        if (this.state.physicianPrice != null) {
            if (this.props.route.params.rentalData != null) {
                let checkoutData = this.props.route.params.rentalData
                checkoutData.physicianPrice = this.state.physicianPrice;
                if (!!this.state.publicPrice) {
                    checkoutData.publicPrice = this.state.publicPrice;
                }
                if (this.state.variablePhysicianPrice) {
                    checkoutData.variablePhysicianPrice = true;
                    if (!!this.state.physicianPriceData) {
                        checkoutData.physicianPriceData = this.state.physicianPriceData;
                    }
                } else {
                    checkoutData.variablePhysicianPrice = false;
                }
                if (this.state.variablePublicPrice) {
                    checkoutData.variablePublicPrice = true;
                    if (!!this.state.publicPriceData) {
                        checkoutData.publicPriceData = this.state.publicPriceData;
                    }
                } else {
                    checkoutData.variablePublicPrice = false;
                }
                if (!!!checkoutData.physicianPrice) {
                    this.setState({
                        errorMessage: "Please select a price",
                        errorModalKey: Math.random().toString(36).substring(7),
                        errorModalVisible: true,
                    })
                } else if (checkoutData.public && !!!checkoutData.publicPrice) {
                    this.setState({
                        errorMessage: "Please select a public price",
                        errorModalKey: Math.random().toString(36).substring(7),
                        errorModalVisible: true,
                    })
                } else {
                    if (this.state.petFee != null) {
                        checkoutData.petFee = this.state.petFee;
                    }
                    if (this.state.cleaningFee != null) {
                        checkoutData.cleaningFee = this.state.cleaningFee;
                    }
                    let success = await requestUpdate(this.state.user.uid, checkoutData);
                    if (success) {
                        this.showSuccess();
                    } else {
                        this.setState({
                            errorMessage: "There was an issue updating your lising.",
                            errorModalKey: Math.random().toString(36).substring(7),
                            errorModalVisible: true,
                        })
                    }   
                } 
            }
        } else {
            this.setState({
                errorMessage: "Please select a price.",
                errorModalKey: Math.random().toString(36).substring(7),
                errorModalVisible: true,
            })
        }
    }

    nextButtonPress = () => {
        if (this.state.physicianPrice != null && (this.props.route.params.newRentalData.public && !!this.state.publicPrice)) {
            if (this.props.route.params.newRentalData != null) {
                let checkoutData = this.props.route.params.newRentalData
                checkoutData.physicianPrice = this.state.physicianPrice;
                if (this.state.variablePhysicianPrice) {
                    checkoutData.variablePhysicianPrice = true;
                    if (!!this.state.physicianPriceData) {
                        checkoutData.physicianPriceData = this.state.physicianPriceData;
                    }
                } else {
                    checkoutData.variablePhysicianPrice = false;
                }
                if (!!this.state.publicPrice) {
                    checkoutData.publicPrice = this.state.publicPrice;
                }
                if (this.state.variablePublicPrice) {
                    checkoutData.variablePublicPrice = true;
                    if (!!this.state.publicPriceData) {
                        checkoutData.publicPriceData = this.state.publicPriceData;
                    }
                } else {
                    checkoutData.variablePublicPrice = false;
                }
                if (this.state.petFee != null) {
                    checkoutData.petFee = this.state.petFee;
                }
                if (this.state.cleaningFee != null) {
                    checkoutData.cleaningFee = this.state.cleaningFee;
                }

                let subscriptionChange = true;
                if (this.props.route.params.subscriptionChange == null) {
                    subscriptionChange = false;
                } else if (!this.props.route.params.subscriptionChange) {
                    subscriptionChange = false;
                }

                this.props.navigation.navigate("Tax Collection", {
                    newRentalData: checkoutData,
                    subscriptionChange
                });
                this.saveDraft(checkoutData);
            }
        } else if (this.state.physicianPrice != null) {
            if (this.props.route.params.newRentalData != null) {
                let checkoutData = this.props.route.params.newRentalData
                checkoutData.physicianPrice = this.state.physicianPrice;
                if (this.state.variablePhysicianPrice) {
                    checkoutData.variablePhysicianPrice = true;
                    if (!!this.state.physicianPriceData) {
                        checkoutData.physicianPriceData = this.state.physicianPriceData;
                    }
                }
                if (this.state.petFee != null) {
                    checkoutData.petFee = this.state.petFee;
                }
                if (this.state.cleaningFee != null) {
                    checkoutData.cleaningFee = this.state.cleaningFee;
                }

                let subscriptionChange = true;
                if (this.props.route.params.subscriptionChange == null) {
                    subscriptionChange = false;
                } else if (!this.props.route.params.subscriptionChange) {
                    subscriptionChange = false;
                }

                this.props.navigation.navigate("Tax Collection", {
                    newRentalData: checkoutData,
                    subscriptionChange
                });
                this.saveDraft(checkoutData);
            } else {
                this.setState({
                    errorMessage: "Something went wrong 0111.",
                    errorModalKey: Math.random().toString(36).substring(7),
                    errorModalVisible: true,
                })
            }
        } else {
            this.setState({
                errorMessage: "Please enter a price",
                errorModalKey: Math.random().toString(36).substring(7),
                errorModalVisible: true,
            })
        }
    }

    showSuccess = () => {
        this.setState({
            successIsShowing: true,
        });
        setTimeout(() => {
            this.setState({
                successIsShowing: false,
            });
        }, 1500);
    }

    onPublicDayPress = (day) => {
        this.setState({
            selectedPublicDate: day,
            dailyPublicModalVisible: true,
            dailyPublicModalKey: Math.random().toString(36).substring(7),
        })
    }

    onPhysicianDayPress = (day) => {
        this.setState({
            selectedPhysicianDate: day,
            dailyPhysicianModalVisible: true,
            dailyPhysicianModalKey: Math.random().toString(36).substring(7),
        })
    }

    buildPricing = () => {
        let parent = [];
        if (this.props.route.params.newRentalData != null || this.props.route.params.rentalData != null) {
            let data = this.props.route.params.newRentalData;
            if (data == null) {
                data = this.props.route.params.rentalData ;
            }
            if (data.public) {
                parent.push(
                    this.buildPhysicianPrice(),
                    this.buildPublicPrice()
                )
            } else {
                parent.push(this.buildPhysicianPrice());
            }
        } else {
            parent.push(this.buildPhysicianPrice());
        }
        return parent;
    }

    buildPublicPrice = () => {
        let calendarWidth = Dimensions.get("window").width - 30;
        const todayDate = new Date();
        let month = todayDate.getUTCMonth()+1;
        let day = todayDate.getUTCDate();
        month >= 10 ? month = todayDate.getMonth()+1: month = `0${todayDate.getMonth()+1}`;
        day >= 10 ? day = todayDate.getUTCDate(): day = `0${todayDate.getUTCDate()}`;
        const todayDateString = todayDate.getUTCFullYear()+"-"+month +"-"+day
        let parent = []
        parent.push(
            <Container title={"Public Price"}>
                <Text style={styles.sublabel}>Enter the price per night for your rental when renting to a non-physician</Text>
                <View style={styles.priceWrapper}>
                    <Stepper
                        navigation={this.props.navigation}
                        count={this.state.publicPrice}
                        key={this.state.publicPriceKey}
                        fast={true}
                        money={true}
                        handler={publicPrice => {
                            this.setState({publicPrice: publicPrice});
                        }}
                    />
                    <Text style={styles.centeredText}>per night</Text>
                </View>
                <View style={{height: 40, width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <CheckBox 
                        label={"Price varies by day"}
                        checked={this.state.variablePublicPrice}
                        key={this.state.variablePublicPriceKey}
                        handler={checked => {
                            this.setState({
                                variablePublicPrice: checked
                            });
                            let rentalData = this.props.route.params.rentalData;
                            if (rentalData == undefined || rentalData == null) {
                                rentalData = this.props.route.params.newRentalData;
                            }
                            if (checked) {
                                this.getPublicPriceData(rentalData);
                            }
                        }}
                    />
                </View>
                <ExpandableView
                    isOpen={this.state.variablePublicPrice}
                    showTrigger={false}
                >
                    <Calendar
                        horizontal={true}
                        style={[styles.calendar, {width: calendarWidth, zIndex: 30, maxWidth: 800}]}
                        markedDates={this.state.markedDates}
                        key={this.state.calendarKey}
                        onDayPress={(day) => this.onDayPress(day)}
                        minDate={todayDateString}
                        enableSwipeMonths={true}
                        markingType={"period"}
                        disableAllTouchEventsForDisabledDays={false}
                        renderArrow={(direction) => direction === 'left' ? 
                            <Image
                                source={require('../assets/backArrowIcon.png')}
                                style={styles.leftIcon}
                            ></Image> : 
                            <Image
                                source={require('../assets/forwardArrowIcon.png')}
                                style={styles.rightIcon}
                            ></Image>
                        }
                        onMonthChange={(month) => {
                            this.setState({selectedMonth: month.dateString});
                        }}
                        current={this.state.selectedMonth}
                        dayComponent={({date, state}) => {
                            let price = this.state.publicPrice;
                            if (!!this.state.publicPriceData) {
                                let dateString = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`
                                if (!!this.state.publicPriceData[dateString]) {
                                    price = this.state.publicPriceData[dateString];
                                }
                            }
                            if (price == null) {
                                price = ""
                            }
                            return (
                                <View>
                                    <TouchableHighlight
                                        onPress={()=>{
                                            this.onPublicDayPress(date)
                                        }}
                                        style={{minWidth: 35}}
                                    >
                                        <View>
                                            <Text style={{textAlign: 'center', color: state === 'disabled' ? 'gray' : 'black'}}>{date.day}</Text>
                                            <Text style={{textAlign: 'center', color: state === 'disabled' ? 'gray' : 'black'}}>{state === 'disabled' ? '' : `$${price}`}</Text>
                                        </View>
                                    </TouchableHighlight>
                                </View>
                            );
                        }}
                    />
                </ExpandableView>
            </Container>,
        );
        return parent;
    }

    buildPhysicianPrice = () => {
        let calendarWidth = Dimensions.get("window").width - 30;
        const todayDate = new Date();
        let month = todayDate.getUTCMonth()+1;
        let day = todayDate.getUTCDate();
        month >= 10 ? month = todayDate.getMonth()+1: month = `0${todayDate.getMonth()+1}`;
        day >= 10 ? day = todayDate.getUTCDate(): day = `0${todayDate.getUTCDate()}`;
        const todayDateString = todayDate.getUTCFullYear()+"-"+month +"-"+day
        let parent = []
        parent.push(
            <Container title={"Price for Physicians"}>
                <Text style={styles.sublabel}>Enter the nightly price for physicians</Text>
                <View style={styles.priceWrapper}>
                    <Stepper
                        navigation={this.props.navigation}
                        count={this.state.physicianPrice}
                        key={this.state.physicianPriceKey}
                        fast={true}
                        money={true}
                        handler={price => {
                            this.setState({physicianPrice: price});
                        }}
                    />
                    <Text style={styles.centeredText}>per night</Text>
                </View>
                <View style={{height: 40, width: "100%", display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <CheckBox 
                        label={"Price varies by day"}
                        checked={this.state.variablePhysicianPrice}
                        key={this.state.variablePhysicianPriceKey}
                        handler={checked => {
                            this.setState({
                                variablePhysicianPrice: checked
                            })
                            if (checked) {
                                let rentalData = this.props.route.params.rentalData;
                                if (rentalData == undefined || rentalData == null) {
                                    rentalData = this.props.route.params.newRentalData;
                                }
                                this.getPhysicianPriceData(rentalData);
                            }
                        }}
                    />
                </View>
                <ExpandableView
                    isOpen={this.state.variablePhysicianPrice}
                    showTrigger={false}
                >
                    <Calendar
                        horizontal={true}
                        style={[styles.calendar, {width: calendarWidth, zIndex: 30, maxWidth: 800}]}
                        markedDates={this.state.markedDates}
                        key={this.state.calendarKey}
                        onDayPress={(day) => this.onDayPress(day)}
                        minDate={todayDateString}
                        enableSwipeMonths={true}
                        markingType={"period"}
                        disableAllTouchEventsForDisabledDays={false}
                        renderArrow={(direction) => direction === 'left' ? 
                            <Image
                                source={require('../assets/backArrowIcon.png')}
                                style={styles.leftIcon}
                            ></Image> : 
                            <Image
                                source={require('../assets/forwardArrowIcon.png')}
                                style={styles.rightIcon}
                            ></Image>
                        }
                        onMonthChange={(month) => {
                            this.setState({selectedMonth: month.dateString});
                        }}
                        current={this.state.selectedMonth}
                        dayComponent={({date, state}) => {
                            let price = this.state.physicianPrice;
                            if (!!this.state.physicianPriceData) {
                                let dateString = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`
                                if (!!this.state.physicianPriceData[dateString]) {
                                    price = this.state.physicianPriceData[dateString];
                                }
                            }
                            if (price == null) {
                                price = ""
                            }
                            return (
                                <View>
                                    <TouchableHighlight
                                        onPress={()=>{
                                            this.onPhysicianDayPress(date);
                                        }}
                                        style={{minWidth: 35}}
                                    >
                                        <View>
                                            <Text style={{textAlign: 'center', color: state === 'disabled' ? 'gray' : 'black'}}>{date.day}</Text>
                                            <Text style={{textAlign: 'center', color: state === 'disabled' ? 'gray' : 'black'}}>{state === 'disabled' ? '' : `$${price}`}</Text>
                                        </View>
                                    </TouchableHighlight>
                                </View>
                            );
                        }}
                    />
                </ExpandableView>
            </Container>
        );
        return parent;
    }

    buildActionButton = () => {
        let parent = [];
        if (this.props.route.params.rentalData != null) {
            parent.push(
                <ActionButton
                    text={"Save"}
                    handler={()=>{
                        this.saveButtonPress()
                    }}
                />
            ); 
        } else {
            parent.push(
                <ActionButton
                    text={"Next"}
                    handler={()=>{
                        this.nextButtonPress()
                    }}
                />
            ); 
        }
        return parent;
    }

    getStepperCount = (type) => {
        if (type === "public") {
            if (!!this.state.publicPriceData && !!this.state.selectedPublicDate) {
                let dateString = `${this.state.selectedPublicDate.year}-${this.state.selectedPublicDate.month.toString().padStart(2, '0')}-${this.state.selectedPublicDate.day.toString().padStart(2, '0')}`
                if (!!this.state.publicPriceData[dateString]) {
                    return this.state.publicPriceData[dateString];
                } else {
                    return this.state.publicPrice;
                }
            } else {
                return this.state.publicPrice;
            }
        } else {
            if (!!this.state.physicianPriceData && !!this.state.selectedPhysicianDate) {
                let dateString = `${this.state.selectedPhysicianDate.year}-${this.state.selectedPhysicianDate.month.toString().padStart(2, '0')}-${this.state.selectedPhysicianDate.day.toString().padStart(2, '0')}`
                if (!!this.state.physicianPriceData[dateString]) {
                    return this.state.physicianPriceData[dateString];
                } else {
                    return this.state.physicianPrice;
                }
            } else {
                return this.state.physicianPrice;
            }
        }
    }

    render() {
        let selectedPublicString = "";
        let selectedPhysicianString = "";
        if (!!this.state.selectedPublicDate) {
            let month = getMonth(this.state.selectedPublicDate.month);
            selectedPublicString = `${month} ${this.state.selectedPublicDate.day}, ${this.state.selectedPublicDate.year}`;
        }
        if (!!this.state.selectedPhysicianDate) {
            let month = getMonth(this.state.selectedPhysicianDate.month);
            selectedPhysicianString = `${month} ${this.state.selectedPhysicianDate.day}, ${this.state.selectedPhysicianDate.year}`;
        }
        return (
            <View style={{height: "100%"}}>
                <TopAlert text={"Pricing updated"} isShowing={this.state.successIsShowing}/>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.errorModalVisible}
                    key={this.state.errorModalKey}
                    onModalClose={this.onModalClose}
                    labelText={this.state.errorMessage}
                    isError={true}
                />
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.dailyPublicModalVisible}
                    key={this.state.dailyPublicModalKey}
                    onModalClose={this.onModalClose}
                    useChildren={true}
                >
                    <Text style={styles.titleText}>{
                        !!this.state.selectedPublicDate?
                        `Select a nightly price for non-physician renters on ${selectedPublicString}`: null
                    }</Text>
                    <View style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "center"}}>
                        <Stepper
                            navigation={this.props.navigation}
                            count={this.getStepperCount("public")}
                            key={this.state.publicPriceKey}
                            money={true}
                            fast={true}
                            handler={price => {
                                let publicPriceData = this.state.publicPriceData;
                                if (publicPriceData == undefined || publicPriceData== null ) {
                                    publicPriceData = {};
                                }
                                let dateString = `${this.state.selectedPublicDate.year}-${this.state.selectedPublicDate.month.toString().padStart(2, '0')}-${this.state.selectedPublicDate.day.toString().padStart(2, '0')}`
                                publicPriceData[dateString] = price;
                                this.setState({publicPriceData: publicPriceData});
                            }}
                        />
                        <Text style={{marginTop: 20, marginLeft: 10}}>Per night</Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "center"}}>
                        <ActionButton 
                            text="Done"
                            handler={()=>{
                                this.setState({
                                    dailyPublicModalVisible: false,
                                    dailyPublicModalKey: Math.random().toString(36).substring(7),
                                })
                            }}
                        />
                    </View>
                </Modal>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.dailyPhysicianModalVisible}
                    key={this.state.dailyPhysicianModalKey}
                    onModalClose={this.onModalClose}
                    useChildren={true}
                >
                    <Text style={styles.titleText}>{
                        !!this.state.selectedPhysicianDate?
                        `Select a nightly price for physician renters on ${selectedPhysicianString}`: null
                    }</Text>
                     <View style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "center"}}>
                        <Stepper
                            navigation={this.props.navigation}
                            count={this.getStepperCount("physician")}
                            key={this.state.publicPriceKey}
                            money={true}
                            fast={true}
                            handler={price => {
                                let physicianPriceData = this.state.physicianPriceData;
                                if (physicianPriceData == undefined || physicianPriceData== null ) {
                                    physicianPriceData = {};
                                }
                                let dateString = `${this.state.selectedPhysicianDate.year}-${this.state.selectedPhysicianDate.month.toString().padStart(2, '0')}-${this.state.selectedPhysicianDate.day.toString().padStart(2, '0')}`
                                physicianPriceData[dateString] = price;
                                this.setState({physicianPriceData: physicianPriceData});
                            }}
                        />
                        <Text style={{marginTop: 20, marginLeft: 10}}>Per night</Text>
                     </View>
                     <View style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "center"}}>
                        <ActionButton 
                            text="Done"
                            handler={()=>{
                                this.setState({
                                    dailyPhysicianModalVisible: false,
                                    dailyPhysicianModalKey: Math.random().toString(36).substring(7),
                                })
                            }}
                        />
                    </View>
                </Modal>
                <ScrollView contentContainerStyle={styles.container}>
                    { this.buildPricing() }
                    { this.buildPetFee() }
                    <Container title={"Cleaning Fee (Optional)"}>
                        <Text style={styles.sublabel}>Enter the cleaning fee if applicable</Text>
                        <Stepper
                            navigation={this.props.navigation}
                            count={this.state.cleaningFee}
                            key={this.state.cleaningFeeKey}
                            money={true}
                            handler={cleaningFee => {
                                this.setState({cleaningFee: cleaningFee});
                            }}
                        />
                        <View>
                            { this.buildActionButton() }
                        </View>
                    </Container>
                
                </ScrollView>
            </View>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        fontSize: 24,
        marginBottom: 10,
        marginTop: 50,
        fontWeight: "700",
        textAlign: "center",
    },
    mt20: {
        marginTop: 20,
    },
    centeredText: {
        textAlign: "center",
        marginHorizontal: 10,
        fontSize: 16,
    },
    payment: {
        width: 500,
        height: 600,
    },
    itemStyle: {
        fontSize: 12,
        height: 75,
        color: 'black',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    priceWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    label: {
        margin: 5,
        marginTop: 10,
        fontSize: 12,
        fontWeight: "600",
    },
    sublabel: {
        paddingBottom: 10,
    },
    button: {
        marginTop: 20,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    leftIcon: {
        width:40,
        height:40,
    },
    rightIcon: {
        width:40,
        height:40,
    },  
    titleText: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: 20,
    },
})