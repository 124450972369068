import React, { Component } from 'react';
import { StyleSheet, Text, View, TextInput } from 'react-native';
import { getAuth, updatePassword, signOut } from "firebase/auth";
import 'react-native-gesture-handler';
import { createStackNavigator } from '@react-navigation/stack';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { getFunctions, httpsCallable } from "firebase/functions";

// import CheckoutScreen from "./StripeCheckout";
import BlockList from "./BlockedUsers";

import Modal from "../compenents/Modal";
import Container from "../compenents/Container";
import ActionButton from '../compenents/ActionButton';

const validPasswordRegex = RegExp(
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{8,})$/
);

const Stack = createStackNavigator();

export default class Settings extends Component {
    render() {
        return (
            <Stack.Navigator>
                <Stack.Screen name="My Settings" component={SettingsView} options={{headerShown: false}}/>
                {/* <Stack.Screen name="Payment Method" component={CheckoutScreen} options={{headerShown: true}}/> */}
                <Stack.Screen name="Block List" component={BlockList} options={{headerShown: true}}/>
            </Stack.Navigator>
        )
    }
}

class SettingsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPassword: null,
            newPassword: null,
            modalVisible: false,
            modalKey: Math.random().toString(36).substring(7),
            errorModalVisible: false,
            errorModalKey: Math.random().toString(36).substring(7),
            errorMessage: "",
            successModalVisible: false,
            successModalKey : Math.random().toString(36).substring(7),
        }
    }

    componentDidMount() {
        //this refreshes the map every time the home view comes into focus
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.props.navigation.getParent().setOptions({headerShown: true});
        });
    }

    onSavePress = () => {
        if (this.state.currentPassword != null && this.state.currentPassword.trim() != "") {
            if (validPasswordRegex.test(this.state.currentPassword) && validPasswordRegex.test(this.state.newPassword)) {
                this.setState({
                    modalVisible: true,
                    modalKey:  Math.random().toString(36).substring(7),
                })
            } else {
                this.setState({
                    errorModalVisible: true,
                    errorModalKey: Math.random().toString(36).substring(7),
                    errorMessage: "Passwords must contain at least 8 letters, one number, and one upper case letter, and no special characters",
                });
            }
        } else {
            this.setState({
                errorModalVisible: true,
                errorModalKey: Math.random().toString(36).substring(7),
                errorMessage: "Passwords must contain at least 8 letters, one number, and one upper case letter, and no special characters",
            });
        }
    }

    updatePassword = () => {
        const _this = this;
        const auth = getAuth();
        const user = auth.currentUser;
        const newPassword = this.state.newPassword;
        updatePassword(user, newPassword).then(() => {
            _this.setState({
                successModalVisible: true,
                successModalKey: Math.random().toString(36).substring(7),
            })
        }).catch((error) => {
            _this.setState({
                errorModalVisible: true,
                errorModalKey: Math.random().toString(36).substring(7),
                errorMessage: error,
            })
        });
    }

    onModalClose = () => {
        this.setState({
            modalVisible: false,
            errorModalVisible: false,
            successModalVisible: false,
        })
    }

    onConfirmClick = () => {
        this.updatePassword();
    }

    updatePaymentMethod = () => {
        this.props.navigation.getParent().setOptions({headerShown: false});
        this.props.navigation.navigate("Payment Method")
    }

    navigateToBlockList = () => {
        this.props.navigation.getParent().setOptions({headerShown: false});
        this.props.navigation.navigate("Block List")
    }

    propmptToDeleteAccount = () => {
        this.setState({
            deleteModalKey: Math.random().toString(36).substring(7),
            deleteModalVisible: true,
        });
    }

    deleteAccount = async () => {
        //request delete 
        const functions = getFunctions();
        const deleteUser = httpsCallable(functions, 'deleteUser');
        let result = await deleteUser();
        const data = result.data;
        if (data.response == "success") {
            //log user out and cry
            const auth = getAuth();
            signOut(auth).then(() => {
                this.props.navigation.navigate('Home');
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    render() {
        return (
            <KeyboardAwareScrollView>
                <View style={styles.container}>
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.modalVisible}
                        key={this.state.modalKey}
                        onModalClose={this.onModalClose}
                        onConfirmClick={this.onConfirmClick}
                        labelText="Are you sure you'd like to update your password?"
                    />
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.successModalVisible}
                        key={this.state.successModalKey}
                        onModalClose={this.onModalClose}
                        isSuccess={true}
                        labelText="Your password has been updated."
                    />
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.errorModalVisible}
                        key={this.state.errorModalKey}
                        onModalClose={this.onModalClose}
                        isError={true}
                        labelText={this.state.errorMessage}
                    />
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.deleteModalVisible}
                        key={this.state.deleteModalKey}
                        onModalClose={this.onModalClose}
                        onConfirmClick={this.deleteAccount}
                        labelText="Are you sure you'd like to delete your account? All your data will be removed. This cannot be reversed. You will be logged out."
                    />
                    <Container title={"Update your password"}>
                        <Text allowFontScaling={false} style={styles.label}>
                            Passwords must contain at least 8 letters, one number, and one upper case letter, and no special characters
                        </Text> 
                        <TextInput
                            style={styles.inputBox}
                            onChangeText={currentPassword => this.setState({ currentPassword })}
                            placeholder='Current Password'
                            placeholderTextColor='black' 
                            secureTextEntry={true}
                        ></TextInput>
                        <TextInput
                            style={styles.inputBox}
                            placeholderTextColor='black' 
                            onChangeText={newPassword => this.setState({ newPassword })}
                            placeholder='New Password'
                            secureTextEntry={true}
                        ></TextInput>
                        <ActionButton 
                            text={"Save"}
                            handler={()=>{
                                this.onSavePress();
                            }}
                        />
                    </Container>
                    <Container title={"Payment Method"}>
                        <Text allowFontScaling={false} style={[styles.label, {paddingBottom: 10}]}>
                            Add, update, or remove your methods of payment
                        </Text> 
                        <ActionButton 
                            text={"Update Payment"}
                            handler={()=>{
                                this.updatePaymentMethod();
                            }}
                        />
                    </Container>
                    <Container title={"Blocked users"}>
                        <Text allowFontScaling={false} style={[styles.label, {paddingBottom: 10}]}>
                            View and edit the users you have blocked.
                        </Text> 
                        <ActionButton 
                            text={"Blocked users"}
                            handler={()=>{
                                this.navigateToBlockList();
                            }}
                        />
                    </Container>
                    <Container title={"Delete Account"}>
                        <Text allowFontScaling={false} style={[styles.label, {paddingBottom: 10}]}>
                            Delete your account and all data associated with you. 
                        </Text> 
                        <Text allowFontScaling={false} style={[styles.label, {paddingBottom: 10, fontWeight: "600"}]}>
                            Pending reservations will not be canceled.
                        </Text> 
                        <ActionButton 
                            text={"Delete Account"}
                            handler={()=>{
                                this.propmptToDeleteAccount();
                            }}
                            filled={true}
                        />
                    </Container>
                </View>
            </KeyboardAwareScrollView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputBox: {
        width: '85%',
        margin: 10,
        padding: 15,
        fontSize: 16,
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        textAlign: 'center',
        backgroundColor: "#f5f5f5",
        color: "black",
    },
    button: {
        marginTop: 10,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    buttonSignup: {
        fontSize: 12
    },
    headerLabeltext: {
        fontSize: 24,
        marginBottom: 10,
        fontWeight: "700",
    },
    subheader: {
        margin: 5,
        marginTop: 10,
        fontSize: 12,
        fontWeight: "600",
    },
    label: {
        textAlign: "center",
        maxWidth: 800,
        marginLeft: 20,
        marginRight: 20,
    }
})