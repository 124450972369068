import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, Image, TextInput, TouchableHighlight } from 'react-native';
import { getDatabase, ref, get, update, set, push, child } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage, uploadBytesResumable, getDownloadURL, ref as sRef } from "firebase/storage";
import * as ImagePicker from 'expo-image-picker';
import * as ImageManipulator from 'expo-image-manipulator';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import Container from "../compenents/Container";
import ActionButton from '../compenents/ActionButton.native';
import Modal from "../compenents/Modal";
import TopAlert from '../compenents/TopAlert';
import Loader from "../compenents/Loader";

export default class MyProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            firstName: null,
            lastName: null,
            npi: null,
            photoUrl: null,
            bio: null,
            modalVisible: false,
            modalKey: Math.random().toString(36).substring(7),
            errorMessage: null,
            successIsShowing: false,
            imageKey: Math.random().toString(36).substring(7),
            loaderIsShowing: false,
            loaderKey: Math.random().toString(36).substring(7),
        }
    }

    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user }, ()=>{
                    this.getProfile(user.uid);
                });
            } else {
                this.setState({ user: null });
            }
        });
    }

    getProfile = async (uid) => {
        const db = getDatabase();
        const profileRef = ref(db, `profiles/${uid}`);
        let snapshot = await get(profileRef);
        const profileData = snapshot.val();
        if (profileData !== null) {
            this.setState({
                firstName: profileData.firstName,
                lastName: profileData.lastName,
                npi: profileData.npi,
                photoUrl: profileData.photoUrl,
                bio: profileData.bio,
            });
        }
    }

    saveProfile = () => {
        let allValid = true;
        let message = "";
        if (this.state.firstName == null || this.state.firstName == "") {
            allValid = false;
            message = "Please enter a first name";
        } 
        if (this.state.lastName == null || this.state.lastName == "") {
            allValid = false;
            message = "Please enter a last name";
        } 
        if (allValid) {
            //save profile
            let profileData = {};
            if (!!this.state.npi) {
                profileData["npi"] = this.state.npi;
            }
            if (!!this.state.firstName) {
                profileData["firstName"] = this.state.firstName;
            }
            if (!!this.state.lastName) {
                profileData["lastName"] = this.state.lastName;
            }
            if (!!this.state.photoUrl) {
                profileData["photoUrl"] = this.state.photoUrl;
            }
            if (!!this.state.bio) {
                profileData["bio"] = this.state.bio;
            }
            const db = getDatabase();
            const profileRef = ref(db, `profiles/${this.state.user.uid}`);
            set(profileRef, profileData);
            this.showSuccess();
        } else {
            this.setState({
                modalVisible: true,
                modalKey: Math.random().toString(36).substring(7),
                errorMessage: message,
            })
        }
    }

    saveAndContiue = () => {
        let allValid = true;
        let message = "";
        if (this.state.firstName == null || this.state.firstName == "") {
            allValid = false;
            message = "Please enter a first name";
        } 
        if (this.state.lastName == null || this.state.lastName == "") {
            allValid = false;
            message = "Please enter a last name";
        } 
        if (allValid) {
            //save profile
            let profileData = {
                firstName: this.state.firstName,
                lastName:  this.state.lastName,
                npi: this.state.npi,
                photoUrl: this.state.photoUrl,
                bio: this.state.bio,
            }
            const db = getDatabase();
            const profileRef = ref(db, `profiles/${this.state.user.uid}`);
            set(profileRef, profileData);
           //navigate to create reservation
           this.props.navigation.navigate("Create Reservation", {
                rentalData:this.props.route.params.rentalData,
                availabilityData: this.props.route.params.availabilityData,
                profileData: profileData,
                userIsDoc: this.props.route.params.userIsDoc,
            });
        } else {
            this.setState({
                modalVisible: true,
                modalKey: Math.random().toString(36).substring(7),
                errorMessage: message,
            })
        }
    }

    showSuccess = () => {
        this.setState({
            successIsShowing: true,
        });
        setTimeout(() => {
            this.setState({
                successIsShowing: false,
            });
        }, 1500);
    }


    getNewProfilePic = async () => {
        if (this.props.imageUrls) {
            if (this.props.imageUrls.length <= 20) {
                let result = await ImagePicker.launchImageLibraryAsync({
                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                    allowsEditing: false,
                    aspect: [4, 3],
                    quality: 1,
                  });
                  if (!result.canceled) {
                      this.resizeImage(result);
                  }
            } else {
                //show error modal
                this.setState({
                    errorModalVisible: true,
                    errorModalKey: Math.random().toString(36).substring(7),
                })
            }
        } else {
            let result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: false,
                aspect: [4, 3],
                quality: 1,
              });
              if (!result.canceled) {
                  this.resizeImage(result);
              }
        }
    }

    resizeImage = async (result) => {
        this.setState({
            loaderIsShowing: true,
            loaderKey: Math.random().toString(36).substring(7),
        });
        const manipResult = await ImageManipulator.manipulateAsync(
            result.uri,
            [{ resize: {width: 900}}],
            { compress: 1, format: ImageManipulator.SaveFormat.JPEG }
        );
        this.setImage(manipResult);
    };

    setImage = (file) => {
        const db = getDatabase();
        const newImageRef = push(child(ref(db), 'posts'));
        const storage = getStorage();
        const storageRef = sRef(storage, `profileImages/${newImageRef.key}`);
        fetch(file.uri)
        .then(res => res.blob())
        .then(blob => {
            const uploadTask = uploadBytesResumable(storageRef, blob);
            uploadTask.on('state_changed', (snapshot) => {
                switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
                }
            }, 
            (error) => {
                // Handle unsuccessful uploads
            }, 
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    this.setState({ 
                        loaderIsShowing: false,
                        loaderKey: Math.random().toString(36).substring(7),
                        photoUrl: downloadURL,
                        imageKey: Math.random().toString(36).substring(7),
                    });
                    let profileData = {
                        photoUrl: downloadURL,
                    }
                    const db = getDatabase();
                    const profileRef = ref(db, `profiles/${this.state.user.uid}`);
                    update(profileRef, profileData);
                });
            });
        });
    }

    buildView = () => {
        let parent = [];
        if (this.state.photoUrl != null) {
            parent.push(
                <Container>
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>Profile Picture (optional)</Text>
                    <Text allowFontScaling={false}>Tap the image to upload a new one</Text>
                    <TouchableHighlight
                        onPress={()=>{
                            this.getNewProfilePic();
                        }}
                    >
                        <Image 
                            style={{width: 120, height: 120, borderRadius: 60, margin: 10}}
                            source={{
                                uri: this.state.photoUrl,
                            }}
                            // resizeMode={"contain"}
                        />
                    </TouchableHighlight>
                </Container>
            )
        } else {
            parent.push(
                <Container>
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>Profile Picture (optional)</Text>
                    <Text allowFontScaling={false}>Tap the image to upload a new one</Text>
                    <TouchableHighlight
                        onPress={()=>{
                            this.getNewProfilePic();
                        }}
                    >
                        <Image
                            source={require('../assets/profileIcon.png')}
                            style={{width: 120, height: 120, borderRadius: 60, margin: 10}}
                            key={this.state.imageKey}
                        />
                    </TouchableHighlight>
                </Container>
            )
        }
        parent.push(
            <Container>
                <Text allowFontScaling={false} style={styles.headerLabeltext}>First and Last Name</Text>
                <TextInput
                    style={styles.inputBox}
                    onChangeText={name => {
                        this.setState({ firstName: name});
                    }}
                    placeholder='First name'
                    placeholderTextColor='black'
                    value={this.state.firstName}
                />
                <TextInput
                    style={styles.inputBox}
                    onChangeText={name => {
                        this.setState({ lastName: name});
                    }}
                    placeholder='Last name'
                    placeholderTextColor='black'
                    value={this.state.lastName}
                />
            </Container>,
            <Container>
                <Text allowFontScaling={false} style={styles.headerLabeltext}>NPI Number (optional)</Text>
                <TextInput
                    style={styles.inputBox}
                    onChangeText={npi => {
                        this.setState({ npi: npi});
                    }}
                    placeholder='NPI Number'
                    placeholderTextColor='black'
                    keyboardType='numeric'
                    value={this.state.npi}
                />
            </Container>,
            <Container>
                <Text allowFontScaling={false} style={styles.headerLabeltext}>About Me (optional)</Text>
                <TextInput
                    style={styles.inputBox}
                    onChangeText={bio => {
                        this.setState({ bio: bio});
                    }}
                    placeholder='Write a quick bio about yourself'
                    placeholderTextColor='black'
                    value={this.state.bio}
                />
            </Container>
        )
        if (!!this.props.route.params) {
            if (!!this.props.route.params.rentalData) {
                parent.push(
                    <Container>
                        <ActionButton
                            text="Next"
                            handler={()=>{
                                //this.saveProfile();
                                this.saveAndContiue();
                            }}
                        />
                    </Container>
                )
            } else {
                parent.push(
                    <Container>
                        <ActionButton
                            text="Save"
                            handler={()=>{
                                this.saveProfile();
                            }}
                        />
                    </Container>
                )
            }
        }else {
            parent.push(
                <Container>
                    <ActionButton
                        text="Save"
                        handler={()=>{
                            this.saveProfile();
                        }}
                    />
                </Container>
            )
        }

        return parent;
    }

    render() {
        return (
            <View style={{height: "100%"}}>
                <TopAlert text={"Profile Updated"} isShowing={this.state.successIsShowing}/>
                <KeyboardAwareScrollView contentContainerStyle={styles.container}>
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.modalVisible}
                        key={this.state.modalKey}
                        onModalClose={this.onModalClose}
                        isError={true}
                        labelText={this.state.errorMessage}
                    />
                    <Loader 
                        visible={this.state.loaderIsShowing}
                        key={this.state.loaderKey}  
                    />
                   {this.buildView()}
                </KeyboardAwareScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        marginTop: 10,
        fontWeight: "600",
        textAlign: "center",
    },
    inputBox: {
        width: '85%',
        maxWidth: 1000,
        marginBottom: 10,
        padding: 15,
        fontSize: 14,
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        textAlign: 'center',
        backgroundColor: "#f5f5f5",
    },
})