import { getDatabase, ref, get, onValue } from "firebase/database";

export const userIsPhysician = async (uid) => {
    const db = getDatabase();
    try {
        const publicUserRef = ref(db, `publicUsers/${uid}`);
        let snapshot = await get(publicUserRef);
        let publicUserData = snapshot.val();
        if (publicUserData != null) {
            return false;
        } else {
            return true;
        }
    } catch (error) {
        console.log(error)
    }
}

export const listenForPhysicianChange = (uid, callback) => {
    const db = getDatabase();
    try {
        const publicUserRef = ref(db, `publicUsers/${uid}`);
        onValue(publicUserRef, snapshot => {
            let publicUserData = snapshot.val();
            if (publicUserData != null) {
                callback(false);
            } else {
                callback(true);
            }
        });
    } catch (error) {
        console.log(error);
    }
}

export const checkUserAgreement = async (uid) => {
    const db = getDatabase();
    try {
        const userAgreementsRef = ref(db, `userAgreements/${uid}`);
        let snapshot = await get(userAgreementsRef);
        let userAgreementData = snapshot.val();
        if (userAgreementData != null) {
            //probably check the date
            return false;
        } else {
            return true;
        }
    } catch (error) {
        console.log(error)
    }
}