import { useState, useEffect } from 'react'
import { View, StyleSheet, Text } from 'react-native';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { Portal } from 'react-native-paper';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

// if setting up the CSS is tricky, you can add this to your page somewhere:
// <link rel="stylesheet" href="https://unpkg.com/react-spring-bottom-sheet/dist/style.css" crossorigin="anonymous">
// import 'react-spring-bottom-sheet/dist/style.css'
import './BottomPageWebStyles.css';

export default function BottomPage(props) {
  const [open, setOpen] = useState(true)

  useEffect(() => {
    setOpen(props.open);
  });

  return (
    <Portal>
        <BottomSheet
            open={open}
            enablePanDownToClose={true}
            onDismiss={()=>{
                setOpen(false);
                if (!!props.onClose) {
                    props.onClose();
                }
            }}
        >
            <KeyboardAwareScrollView>
                <View style={styles.contentContainer}>
                    {props.children}
                </View>
            </KeyboardAwareScrollView>
        </BottomSheet>
    </Portal>
  )
}

const styles = StyleSheet.create({
    container: {
      position: "absolute",
      height: "100%",
      width: "100%",
      flex: 1,
      padding: 24,
      backgroundColor: "green",
      zIndex: 10,
    },
    contentContainer: {
      flex: 1,
      alignItems: 'center',
      width: "100%",
      padding: 10,
    },
  });