import React, {useEffect, useState} from 'react';
import {
    ScrollView,
    View,
    StyleSheet,
    Image,
    Text,
    ActivityIndicator,
    Alert,
  } from 'react-native';
import { getDatabase, ref, get, set } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {loadStripe} from '@stripe/stripe-js';
import {
    PaymentElement,
    CardElement,
    Elements,
    useStripe,
    useElements,
  } from '@stripe/react-stripe-js';

import Container from '../compenents/Container';
import ActionButton from '../compenents/ActionButton';
import Modal from '../compenents/Modal';
import Logo from "../compenents/Logo";

//test
//const stripePromise = loadStripe('pk_test_51L44gZGuXQhnf0tYCXJRYw8vlMHmaKmv4acyZh7lyvJfed5Iu7MI0fvPOX7gtr1R73AxvTuMPFUVKpQHexwDnvA9006qpuJpPR');
//prod
const stripePromise = loadStripe('pk_live_51L44gZGuXQhnf0tYYvmagRmvHvrinoaFzmZcfuOWsUO5BfdEnoyy7FiT3xv2RapZhFWKr3v08dEHWljXmNl1LaJz00MqB4JzTj');

export default function CheckoutScreenWrapper(props) {
    const [customerID, setCustomerID] = useState(null);
    const [clientSecret , setSecret] = useState(null);
    const [cardsData, setCardsData] = useState(null);
    const [user, setUser] = useState(null);
    const [checkoutKey, setCheckoutKey] = useState(Math.random().toString(36).substring(7));

    useEffect(() => {
        //check for props.route.params.mobileCheckoutUID 
        //if it exists, allow user to upload payment method with that UID and redirect back to the app
        //also dont allow them to navigate back within the app.
        let isMobileCheckout = false;
        if (!!props.route.params) {
            if (!!props.route.params.mobileCheckoutUID) {
                isMobileCheckout = true;
                props.navigation.setOptions({headerShown: false});
            }
        }
        if (isMobileCheckout) {
            let user = {
                uid: props.route.params.mobileCheckoutUID
            }
            setUser(user);
            getPaymentDetails(user)
        } else {
            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setUser(user);
                    getPaymentDetails(user)
                } else {
                    setUser(null);
                }
            });
        }
    }, []);

    const fetchPaymentSheetParams = async () => {
        const response = await fetch(`https://us-central1-doc2doc-d54a2.cloudfunctions.net/requestStripeAccount`);
        const paymentData  = await response.json();
        if (!!paymentData) {
            return paymentData;
        }
    };

    const initializePaymentSheet = async () => {
        const paymentData =  await fetchPaymentSheetParams();
        setCustomerID(paymentData.customer);
        setSecret(paymentData.setupIntent);
    };

    const getPaymentDetails = async (user) => {
        const db = getDatabase();
        const stripeCustomerIDRef = ref(db, `stripeCustomerID/${user.uid}`);
        let snapshot = await get(stripeCustomerIDRef);
        let customerData = snapshot.val();
        if (!!customerData) {
            if (!!customerData.stripeID) {
                let url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/getPaymentDetails?customerID=${customerData.stripeID}&requesterUID=${user.uid}`;
                const response = await fetch(url);
                const paymentData  = await response.json();
                if (!!paymentData) {
                    if (paymentData.response == "success") {
                        if (paymentData.data.length > 0) {
                            setCardsData([paymentData.data[0]])
                            setCheckoutKey(Math.random().toString(36).substring(7));
                        } else {
                            initializePaymentSheet();
                        }
                    } else {
                        initializePaymentSheet();
                    }
                } else {
                    initializePaymentSheet();
                }
            } else {
                initializePaymentSheet();
            }
        } else {
            initializePaymentSheet();
        }
    }

    const buildView = () => {
        let parent = [];
        if (!!clientSecret) {
            parent.push(
                <Elements 
                    stripe={stripePromise} 
                    options={{
                        clientSecret: clientSecret,
                      }}
                >
                    <CheckoutScreen 
                        {...props} 
                        customerID={customerID} 
                        user={user}
                        cardsData={cardsData}
                        clientSecret={clientSecret} 
                        key={checkoutKey}
                        getPaymentDetails={getPaymentDetails}
                        deleteCallback={()=>{
                            setCardsData(null);
                            setCheckoutKey(Math.random().toString(36).substring(7));
                            initializePaymentSheet();
                        }}
                    />
                </Elements>
            )
        } else if (!!cardsData) {
            parent.push(
                <Elements 
                    stripe={stripePromise} 
                >
                    <CheckoutScreen 
                        {...props} 
                        customerID={customerID} 
                        user={user}
                        cardsData={cardsData}
                        clientSecret={clientSecret} 
                        key={checkoutKey}
                        deleteCallback={()=>{
                            setCardsData(null);
                            setCheckoutKey(Math.random().toString(36).substring(7));
                            initializePaymentSheet();
                        }}
                    />
                </Elements>
            )
        } else {
            parent.push(
                <ActivityIndicator size="large" style={{marginTop: 50,}}/>
            )
        }
        return parent;
    }

    return (
        buildView()
    );
}

function CheckoutScreen(props) {
    const stripe = useStripe();
    const elements = useElements();
  
    const [modalVisible, setModalVisible] = useState(false);
    const [modalKey, setModalKey] = useState(Math.random().toString(36).substring(7))
    const [loading, setLoading] = useState(false);

    const uploadPaymentMethod = async (paymentID) => {
        let data = {
            userID: props.user.uid,
            stripeID: props.customerID,
            paymentSource: paymentID,
        }
        if (!!props.route.params) {
            if (!!props.route.params.mobileCheckoutUID) {
                data.userID = props.route.params.mobileCheckoutUID;
            }
        }
        if (!!data.stripeID && !!data.paymentSource) {
            const db = getDatabase();
            const stripeCustomerIDRef = ref(db, `stripeCustomerID/${data.userID}`);
            let success = await set(stripeCustomerIDRef, data);
            return true;
        } else {
            return false;
        }
    }

    const promptToDelete = () => {
        setModalVisible(true);
        setModalKey(Math.random().toString(36).substring(7));
    }

    const onModalClose = () => {
        setModalVisible(false);
        setModalKey(Math.random().toString(36).substring(7));
    }

    const requestDelete = async () => {
        let paymentMethodID = props.cardsData[0].id;
        let url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/removePaymentMethod?paymentMethodID=${paymentMethodID}&requesterUID=${props.user.uid}`;
        const response = await fetch(url);
        const data  = await response.json();
        if (data.response == "success") {
            props.deleteCallback();
        } else {
            //show error
        }
    }

    const buildCardData = () => {
        let parent = [];
        let image = [];
        if (props.cardsData) {
            for (let index in props.cardsData) {
                let cardData = props.cardsData[index];
                let expiration = `${cardData.card.exp_month}/${String(cardData.card.exp_year).slice(-2)}`
                switch(cardData.card.brand) {
                    case "amex":
                        image.push(
                            <Image 
                                source={require("../assets/amex-logo.png")} 
                                style={{width: 60, maxHeight: 35, height:40, position: "absolute", bottom: 0, right: 10}}
                                resizeMode={"contain"}
                            />
                        );
                        break;
                    case "diners":
                        image.push(
                            <Image 
                                source={require("../assets/diners-logo.png")} 
                                style={{width: 60, maxHeight: 35, height:40, position: "absolute", bottom: 0, right: 10}}
                                resizeMode={"contain"}
                            />
                        );
                        break;
                    case "discover":
                        image.push(
                            <Image 
                                source={require("../assets/discover-logo.png")} 
                                style={{width: 60, maxHeight: 35, height:40, position: "absolute", bottom: 0, right: 10}}
                                resizeMode={"contain"}
                            />
                        );
                    break;
                        case "jcb":
                        image.push(
                            <Image 
                                source={require("../assets/jcb-logo.png")} 
                                style={{width: 60, maxHeight: 35, height:40, position: "absolute", bottom: 0, right: 10}}
                                resizeMode={"contain"}
                            />
                        );
                        break;
                    case "mastercard":
                        image.push(
                            <Image 
                                source={require("../assets/mastercard-logo.png")} 
                                style={{width: 60, maxHeight: 35, height:40, position: "absolute", bottom: 0, right: 10}}
                                resizeMode={"contain"}
                            />
                        );
                        break;
                    case "unionpay":
                        image.push(
                            <Image 
                                source={require("../assets/UnionPayLogo.png")} 
                                style={{width: 60, maxHeight: 35, height:40, position: "absolute", bottom: 0, right: 10}}
                                resizeMode={"contain"}
                            />
                        );
                        break;
                    case "visa":
                        image.push(
                            <Image 
                                source={require("../assets/visa-logo.png")} 
                                style={{width: 60, maxHeight: 35, height:40, position: "absolute", bottom: 0, right: 10}}
                                resizeMode={"contain"}
                            />
                        );
                        break;
                    default:
                        image.push(
                            <Image 
                                source={require("../assets/amex-logo.png")} 
                                style={{width: 60, maxHeight: 35, height:40, position: "absolute", bottom: 0, right: 10}}
                                resizeMode={"contain"}
                            />
                        );
                        break;
                }
                let child = [
                    <View style={styles.card}>
                        {image}
                        <View style={{marginTop: 15, height:20, width: "100%", backgroundColor: "black"}}></View>
                        <View style={{display: "flex", flexDirection: "row", marginTop: 0}}>
                            <Text style={{padding: 10, marginTop: 0,}}>•••• •••• •••• {cardData.card.last4}</Text>
                        </View>
                        <Text style={{position: "absolute", bottom: 10, left: 30}}>{expiration}</Text>
                    </View>
                ];
                if (loading) {
                    child.push(
                        <ActionButton 
                            filled={true}
                            text={"Delete"}
                            loading={true}
                            handler={()=>{
                                promptToDelete();
                            }}
                        />
                    )
                } else {
                    child.push(
                        <ActionButton 
                            filled={true}
                            text={"Delete"}
                            handler={()=>{
                                promptToDelete();
                            }}
                        />
                    )
                }
                parent.push(
                    <View style={{display: "flex", alignItems: "center"}}>
                        {child}
                    </View>
                )
            }
        }
        return parent;
    }

    const buildCheckOut = () => {
        let parent = [];
        if (props.cardsData == null) {
            parent.push(
                <Text>You currently dont have a payment method set up</Text>,
                <Text>Enter your card info below and tap the button that says "Add Payment Method" to save it</Text>,
                <Container>
                    <form style={{width: "100%", padding: 10}}>
                        <CardElement/>
                    </form>
                </Container>
            );   
            if (loading) {
                parent.push(
                    <ActionButton
                        text={"Add Payment Method"}
                        loading={true}
                        handler={()=>{
                            handleSubmit()
                        }}
                    />,
                );
            } else {
                parent.push(
                    <ActionButton
                        text={"Add Payment Method"}
                        handler={()=>{
                            handleSubmit()
                        }}
                    />,
                );
            }
        }
        return parent;
    }

    const buildContinue = () => {
        let parent = [];
        if (!!props.route.params) {
            if (!!props.cardsData && props.route.params.mobileCheckoutUID == null) {
                parent.push(
                    <Container>
                        <ActionButton
                            text={"Continue"}
                            handler={()=>{
                                props.navigation.navigate("Review Reservation", {
                                    reservationData:  props.route.params.reservationData,
                                    rentalData: props.route.params.rentalData,
                                    customerID: props.customerID,
                                    paymentSource: props.cardsData[0].id,
                                })
                            }}
                        />
                    </Container>
                );
            }
        }
        return parent;
    }

    const handleSubmit = async () => {
        if (elements == null) {
          return;
        }
        //this.setState({loading: true})
        setLoading(true);
        const results = await stripe.confirmCardSetup(props.clientSecret, {
            payment_method: {
              card:  elements.getElement(CardElement),
            },
        }).catch(error => {
            alert("We were unable to process your card: "+error)
        })
        setLoading(false);
        if (!!results.setupIntent) {
            if (!!results.setupIntent.payment_method) {
                let success = await uploadPaymentMethod(results.setupIntent.payment_method);
                if (success) {
                    if (props.route.params != null) {
                        if (!!props.route.params.mobileCheckoutUID) {
                            window.location.replace('doc2doc://');
                        } else {
                            props.getPaymentDetails(props.user);
                        }
                    } else {
                        props.getPaymentDetails(props.user);
                    }
                } else {
                    alert("something went wrong. Please try again. 1101")
                }
            } else {
                alert("something went wrong. Please try again.")
            }
        } else {
            alert("We were unable to process your card: "+results.error?.message)
        }
    };

    const buildHeader = () => {
        let parent = [];
        if (!!props.route.params) {
            if (!!props.route.params.mobileCheckoutUID) {
                parent.push(<Logo/>);
            }
        }
        return parent;
    }
  
    return (
        <View style={{width: "100%"}}>
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                key={modalKey}
                onModalClose={onModalClose}
                onConfirmClick={() => requestDelete()}
                labelText={"Would you like to delete this payment method?"}
            />
             <ScrollView contentContainerStyle={styles.container}>
                {buildHeader()}
                <Container>
                    <Text style={styles.headerLabel}>My Payment Method</Text>
                    {buildCardData()}
                    {buildCheckOut()}
                </Container>
                {buildContinue()}
             </ScrollView>
        </View>
    );
}

const styles = StyleSheet.create({
    card: {
        width: 200,
        height: 110,
        borderWidth: 1,
        borderColor: "lightgrey",
        display: "flex",
        alignItems: "center",
        borderRadius: 10,
        marginBottom: 10,
    },
    headerLabel: {
        fontWeight: "600",
        marginBottom: 10,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
});