import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View } from 'react-native';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Container from "../compenents/Container";
import { getDateString } from '../helpers/date-formatter';

export default class CostBreakdown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            costData: null,
        }
    }

    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
        });
    }

    buildView = () => {
        let parent = [];
        if (!!this.props.route.params) {
            if (!!this.props.route.params.reservationData) {
                parent.push(
                    <View style={{display: "flex", flexDirection: "row", width: 230}}>
                        <Text style={{width: 80}}>Name:</Text>
                        <View style={styles.divider}></View>
                        <Text>{this.props.route.params.reservationData.renterName}</Text>
                    </View>
                );
                let checkInDate = new Date(this.props.route.params.reservationData.checkInDate);
                let checkOutDate = new Date(this.props.route.params.reservationData.checkOutDate);
                let datesAray = this.getDaysArray(checkInDate, checkOutDate);
                let datesCount = datesAray.length - 1;
                let checkInString = getDateString(this.props.route.params.reservationData.checkInDate);
                let checkOutString = getDateString(this.props.route.params.reservationData.checkOutDate);
                parent.push(
                    <View style={{display: "flex", flexDirection: "row", width: 230}}>
                        <Text style={{width: 80}}>Check In:</Text>
                        <View style={styles.divider}></View>
                        <Text>{checkInString}</Text>
                    </View>
                );
                parent.push(
                    <View style={{display: "flex", flexDirection: "row",  width: 230}}>
                        <Text style={{width: 80}}>Check Out:</Text>
                        <View style={styles.divider}></View>
                        <Text>{checkOutString}</Text>
                    </View>
                );
                parent.push(
                    <View style={{display: "flex", flexDirection: "row", width: 230}}>
                        <Text style={{width: 80}}>Night(s):</Text>
                        <View style={styles.divider}></View>
                        <Text>x {datesCount}</Text>
                    </View>
                );
                if (this.props.route.params.reservationData.bringingPet) {
                    parent.push(
                        <View style={{display: "flex", flexDirection: "row", width: 230}}>
                            <Text style={{width: 80}}>Pet:</Text>
                            <View style={styles.divider}></View>
                            <Text>Yes</Text>
                        </View>
                    );
                }
                parent.push(
                    <View style={{display: "flex", flexDirection: "row",  width: 230}}>
                        <Text style={{width: 80}}>People:</Text>
                        <View style={styles.divider}></View>
                        <Text>x {this.props.route.params.reservationData.people}</Text>
                    </View>
                );
            }
        }
        return parent;
    }

    buildPriceBreakDown = () => {
        let parent = [];
        let checkInDate = new Date(this.props.route.params.reservationData.checkInDate);
        let checkOutDate = new Date(this.props.route.params.reservationData.checkOutDate);
        let datesAray = this.getDaysArray(checkInDate, checkOutDate);
        let datesCount = datesAray.length - 1;
        // let rentalData = this.props.route.params.rentalData;

        if (this.props.route.params.reservationData.petFee) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 230}}>
                    <Text style={{width: 130}}>Pet Fee:</Text>
                    <View style={styles.divider}></View>
                    <Text style={{width: 70}}>${this.props.route.params.reservationData.petFee.toFixed(2)}</Text>
                </View>
            )
        }
        
        if (this.props.route.params.reservationData.cleaningFee) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 230}}>
                    <Text style={{width: 130}}>Cleaning Fee:</Text>
                    <View style={styles.divider}></View>
                    <Text style={{width: 70}}>${this.props.route.params.reservationData.cleaningFee.toFixed(2)}</Text>
                </View>
            )
        }
        if (this.props.route.params.reservationData.nightlyFee != null) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 230}}>
                    <Text style={{width: 130}}>Nightly Fee x{datesCount} :</Text>
                    <View style={styles.divider}></View>
                    <Text style={{width: 70}}>${this.props.route.params.reservationData.nightlyFee.toFixed(2)}</Text>
                </View>
            )
        }
        parent.push(
            <View style={{display: "flex", flexDirection: "row", width: 230}}>
                <Text style={{width: 130}}>Guest Fee:</Text>
                <View style={styles.divider}></View>
                <Text style={{width: 70}}>${parseFloat(this.props.route.params.reservationData.guestFee).toFixed(2)}</Text>
            </View>
        )
        if (this.props.route.params.reservationData.taxCollected) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 230}}>
                    <Text style={{width: 130}}>Tax Collected:</Text>
                    <View style={styles.divider}></View>
                    <Text style={{width: 70}}>${parseFloat(this.props.route.params.reservationData.taxCollected).toFixed(2)}</Text>
                </View>
            )
        }
        if (this.props.route.params.reservationData.occupancyTax) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 230}}>
                    <Text style={{width: 130}}>Occupancy Tax:</Text>
                    <View style={styles.divider}></View>
                    <Text style={{width: 70}}>{this.props.route.params.reservationData.occupancyTax}%</Text>
                    <View style={styles.divider}></View>
                    <Text>x 1</Text>
                </View>
            )
        }
        parent.push(
            <View style={{display: "flex", flexDirection: "row", marginTop: 10,}}>
                <Text style={{fontWeight: "600", fontSize: 18}}>Total: ${parseFloat(this.props.route.params.reservationData.totalCollected).toFixed(2)}</Text>
            </View>
        )
        return parent;
    }

    getDaysArray = (s,e) => {
        for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;
    };
    
    render() {
        return (
            <View style={{height: "100%"}}>
                <ScrollView contentContainerStyle={styles.container}>
                    <Container>
                        <Text allowFontScaling={false} style={styles.headerLabeltext}>Details</Text>
                        {this.buildView()}
                    </Container>
                    <Container>
                        <Text allowFontScaling={false} style={styles.headerLabeltext}>Price Breakdown</Text>
                        {this.buildPriceBreakDown()}
                    </Container>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        fontWeight: "600",
        textAlign: "center",
        marginBottom: 10,
    },
    divider: {
        backgroundColor: "#d3d3d3",
        width: 1,
        height: 20,
        marginHorizontal: 10,
      },
})