import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View } from 'react-native';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Container from "../compenents/Container";
import ActionButton from "../compenents/ActionButton";

export default class CancelReservation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            refundAmount: 0,
        }
    }

    componentDidMount() {
        this.getUser();
    }

    getUser = () => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                this.setState({
                    user: user,
                }, () => {
                    this.getRefundAmount();
                });

            } else {
                this.setState({
                    user: null,
                })
            }
        });
    }

    getRefundAmount = () => {
        if (!!this.props.route.params.tripData) {
            if (this.props.route.params.tripData.hostID == this.state.user.uid) {
                this.setState({
                    refundAmount: this.props.route.params.tripData.totalCollected,
                });
            } else {
                let refund = this.props.route.params.tripData.totalCollected - this.props.route.params.tripData.guestFee;
                this.setState({
                    refundAmount: refund,
                });
            }
        } else if (!!this.props.route.params.reservationData) {
            this.setState({
                refundAmount: this.props.route.params.reservationData.totalCollected,
            });
        }
    }

    requestCancel = async () => {
        let paymentID = "";
        const requesterUID = this.state.user.uid; 
        let guestID = ""
        let hostID = "";
        let postID = "";
        let url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/cancelBooking?`;
        if (!!this.props.route.params.requestData) {
            paymentID = this.props.route.params.requestData.id;
            hostID = this.props.route.params.requestData.hostID;
            postID = this.props.route.params.requestData.postID;
            url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/cancelRequest?`;
            url += `requestID=${paymentID}&requesterUID=${requesterUID}`;
        } else {
            paymentID = this.props.route.params.tripData.id;
            hostID = this.props.route.params.tripData.hostID;
            postID = this.props.route.params.tripData.postID;
            url += `paymentID=${paymentID}&requesterUID=${requesterUID}`;
        }
        if (hostID == this.state.user.uid) {
            guestID = this.props.route.params.requestData.requesterUID;
        } else {
            guestID = this.state.user.uid;
        }
        url += `&guestID=${guestID}&hostID=${hostID}&postID=${postID}`;
        try {
            const response = await fetch(url);
            const bookingResponse = await response.json();
            if (bookingResponse.response == "success") {
                if (!!this.props.route.params.requestData) {
                    this.props.navigation.navigate("Success", {
                        canceledRequestID: postID,
                    })
                } else {
                    this.props.navigation.navigate("Success", {
                        canceledReservationID: postID,
                    })
                }
            } else {
                console.log("something failed")
                this.setState({
                    modalVisible: true,
                    modalKey: Math.random().toString(36).substring(7),
                })
            }   
        } catch (error) {
            console.log(error)
            this.setState({
                modalVisible: true,
                modalKey: Math.random().toString(36).substring(7),
            })
        }
    }

    hostCancelReservation = async reservationData => {
        const requesterUID = this.state.user.uid; 
        const guestID = reservationData.requesterUID;
        const hostID = this.state.user.uid; 
        const postID = reservationData.postID;
        const paymentID = reservationData.id;
        let url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/cancelBooking?`;
        url += `paymentID=${paymentID}&requesterUID=${requesterUID}`;
        url += `&guestID=${guestID}&hostID=${hostID}&postID=${postID}`;
        try {
            const response = await fetch(url);
            const bookingResponse = await response.json();
            if (bookingResponse.response == "success") {
                if (!!this.props.route.params.requestData) {
                    this.props.navigation.navigate("Success", {
                        canceledRequestID: postID,
                    })
                } else {
                    this.props.navigation.navigate("Success", {
                        hostCanceledReservationID: postID,
                    })
                }
            } else {
                console.log("something failed")
                this.setState({
                    modalVisible: true,
                    modalKey: Math.random().toString(36).substring(7),
                })
            }   
        } catch (error) {
            console.log(error)
            this.setState({
                modalVisible: true,
                modalKey: Math.random().toString(36).substring(7),
            })
        }
    }

    buildView = () => {
        let parent = [];
        if (!!this.props.route.params.tripData) {
            if (this.state.refundAmount != null) {
                parent.push(
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>Would you like to cancel your reservation?</Text>,
                    <Text allowFontScaling={false} style={{margin: 10, marginBottom: 3}}>
                        Canceling your reservation will refund the following amount to you: 
                    </Text>,
                    <Text allowFontScaling={false} style={[styles.headerLabeltext, {marginBottom: 10}]}>${parseFloat(this.state.refundAmount).toFixed(2)}</Text>,
                    <ActionButton
                        text="Cancel Reservation"
                        handler={()=>{
                            this.requestCancel();
                        }}
                    />
                );
            }
        } else if (!!this.props.route.params.requestData) {
            parent.push(
                <Text allowFontScaling={false} style={styles.headerLabeltext}>Would you like to cancel your reservation request?</Text>,
                <Text allowFontScaling={false} style={{margin: 10}}>
                    Canceling your reservation request will remove this request permanently 
                </Text>,
                <ActionButton
                    text="Cancel Request"
                    handler={()=>{
                        this.requestCancel();
                    }}
                />
            );
        } else if (!!this.props.route.params.reservationData && !!this.state.refundAmount) {
            parent.push(
                <Text allowFontScaling={false} style={styles.headerLabeltext}>Confirm you like to cancel this reservation</Text>,
                <Text allowFontScaling={false} style={{margin: 10, marginBottom: 3}}>
                    Canceling your reservation will refund the following amount to the guest: 
                </Text>,
                <Text allowFontScaling={false} style={[styles.headerLabeltext, {marginBottom: 10}]}>${parseFloat(this.state.refundAmount).toFixed(2)}</Text>,
                <ActionButton
                    text="Cancel Reservation"
                    handler={()=>{
                        this.hostCancelReservation(this.props.route.params.reservationData);
                    }}
                />
            );
        }
        return parent;
    }

    render() {
        return (
            <View style={{height: "100%"}}>
                <ScrollView contentContainerStyle={styles.container}>
                    <Container>
                        {this.buildView()}
                    </Container>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        marginTop: 10,
        fontWeight: "600",
        textAlign: "center",
    },
})