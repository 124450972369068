import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, Image, Platform } from 'react-native';
import { getDatabase, ref, get, set } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Container from "../compenents/Container";
import ActionButton from "../compenents/ActionButton";
import Modal from '../compenents/Modal';
import TopAlert from '../compenents/TopAlert';
import Feed from "../compenents/Feed";

export default class PublicProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            firstName: null,
            lastName: null,
            npi: null,
            photoUrl: null,
            bio: null,
            reviewsData: null,
            confirmModalVisible: false,
            confirmModalKey: Math.random().toString(36).substring(7),
            confirmModalMessage: "",
            confirmAction: null,
            successIsShowing: false,
            topAlertMessage: "",
            errorModalVisible: false,
            errorMessage: "",
            errorModalKey: Math.random().toString(36).substring(7),
            myRentalsData: null,
            rentalData: null,
            shareModalVisible: false,
            shareModalKey: Math.random().toString(36).substring(7),
        }
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.configureView();
        });
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user }, ()=>{
                    this.configureView();
                });
            } else {
                this.setState({ user: null });
            }
        });
    }

    configureView = async () => {
        if (Platform.OS == "web") {
            window.history.replaceState(null, null, "?profileID="+this.props.route.params.profileUID);
        }
        if (!!this.props.route.params) {
            if (!!this.props.route.params.profileUID) {
                let userIsBlocked = await this.checkMyBlockList(this.props.route.params.profileUID);
                let userBlocksMe = await this.checkUsersBlockList(this.props.route.params.profileUID);
                this.setState({
                    userIsBlocked: userIsBlocked,
                    userBlocksMe: userBlocksMe,
                });
                if (!userIsBlocked && !userBlocksMe) { 
                    this.getProfileData(this.props.route.params.profileUID);
                    this.getMyRentals(this.props.route.params.profileUID);
                } 
            }
        }
    }

    checkMyBlockList = async uid => {
        if (!!this.state.user) {
            const db = getDatabase();
            const blockRef = ref(db, `blockList/${this.state.user.uid}/${uid}`);
            let snapshot = await get(blockRef);
            let blockData = snapshot.val();
            if (!!blockData) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    checkUsersBlockList = async uid => {
        if (!!this.state.user) {
            const db = getDatabase();
            const blockRef = ref(db, `blockList/${uid}/${this.state.user.uid}`);
            let snapshot = await get(blockRef);
            let blockData = snapshot.val();
            if (!!blockData) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getProfileData = async (profileUID) => {
        const db = getDatabase();
        const profileRef = ref(db, `profiles/${profileUID}`);
        let snapshot = await get(profileRef);
        let profileData = snapshot.val();
        if (!!profileData) {
            this.setState({
                firstName: profileData.firstName,
                lastName: profileData.lastName,
                npi: profileData.npi,
                photoUrl: profileData.photoUrl,
                bio: profileData.bio,
            })
        };
    }

    getMyRentals = async (profileUID) => {
        const db = getDatabase();
        const myRentalsRef = ref(db, `myRentals/${profileUID}`);
        let snapshot = await get(myRentalsRef);
        let myRentalsData = snapshot.val();
        if (!!myRentalsData) {
            this.setState({
                myRentalsData: myRentalsData,
            });
            let rentalData = {};
            for (let index in myRentalsData) {
                const publicRentalRef = ref(db, `publicRentals/${index}`);
                let snapshot = await get(publicRentalRef);
                let publicRentalData = snapshot.val();
                if (!!publicRentalData) {
                    rentalData[index] = publicRentalData;
                }
            }
            this.setState({
                rentalData: rentalData,
            });
        };
    }

    messageUser = () => {
        this.props.navigation.navigate("Message", {
            recipientUID: this.props.route.params.profileUID,
            user: this.state.user,
            fromProfile: true,
        })
    }

    reportUserButtonTap = () => {
        this.setState({
            confirmModalVisible: true,
            confirmModalKey: Math.random().toString(36).substring(7),
            confirmModalMessage: "Would you like to report this user?",
            confirmAction: this.reportUser,
        }); 
    }

    reportUser = async () => {
        this.setState({
            confirmModalVisible: false,
            confirmModalKey: Math.random().toString(36).substring(7),
            confirmModalMessage: "",
            confirmAction: null,
        }); 
        //send report via cloud function
        let url = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/reportUser?";
        const reportedUserID = this.props.route.params.profileUID;
        const senderUID = this.state.user.uid;
        url += `reportedUserID=${reportedUserID}&senderUID=${senderUID}`;
        try {
            const response = await fetch(url);
            const reportResponse = await response.json();
            if (reportResponse.response == "success") {
                this.showSuccess("User has been reported");
            } else  {
                this.setState({
                    errorMessage: "Something went wrong. Please try again.",
                    errorModalKey: Math.random().toString(36).substring(7),
                    errorModalVisible: true,
                });
            }
        } catch (error) {
            this.setState({
                errorMessage: error,
                errorModalKey: Math.random().toString(36).substring(7),
                errorModalVisible: true,
            });
        }
    }

    showSuccess = message => {
        this.setState({
            successIsShowing: true,
            topAlertMessage: message,
        });
        setTimeout(() => {
            this.setState({
                successIsShowing: false,
            });
        }, 1500);
    }

    blockUserButtonTap = () => {
        let message = `Would you like to block this user? 
            This will NOT cancel any pending reservations for them.
            You must cancel reservations manually in "My Properties".
        `
        this.setState({
            confirmModalVisible: true,
            confirmModalKey: Math.random().toString(36).substring(7),
            confirmModalMessage: message,
            confirmAction: this.blockUser,
        }); 
    }

    blockUser = async () => {
        this.setState({
            confirmModalVisible: false,
            confirmModalKey: Math.random().toString(36).substring(7),
            confirmModalMessage: "",
            confirmAction: null
        }); 
        //add user to blocked list
        const db = getDatabase();
        const blockedUserId = this.props.route.params.profileUID;
        const blockListRef = ref(db, `blockList/${this.state.user.uid}/${blockedUserId}`);
        let snapshot = await set(blockListRef, {blocked: true, id: blockedUserId});
        this.showSuccess("You have successfully blocked this user");
        this.setState({
            userIsBlocked: true,
            userBlocksMe: false,
            firstName: null,
            lastName: null,
            npi: null,
            photoUrl: null,
            bio: null,
        });
    }

    unblockUserButtonTap = () => {
        this.setState({
            confirmModalVisible: true,
            confirmModalKey: Math.random().toString(36).substring(7),
            confirmModalMessage: "Would you like to unblock this user?",
            confirmAction: this.unblockUser,
        }); 
    }

    unblockUser = async () => {
        //remove user from blockList
        this.setState({
            confirmModalVisible: false,
            confirmModalKey: Math.random().toString(36).substring(7),
            confirmModalMessage: "",
            confirmAction: null
        }); 
        //add user to blocked list
        const db = getDatabase();
        const blockedUserId = this.props.route.params.profileUID
        const blockListRef = ref(db, `blockList/${this.state.user.uid}/${blockedUserId}`);
        let snapshot = await set(blockListRef, null);
        this.showSuccess("You have successfully unblocked this user");
        this.setState({
            userIsBlocked: false,
            userBlocksMe: false,
        });
        this.getProfileData(blockedUserId);
    }

    buildMyRentals = () => {

    }

    buildView = () => {
        let parent = [];
        let final = [];
        if (this.state.userIsBlocked) {
            parent.push(
                <Image 
                    style={styles.userImage}
                    source={require('../assets/profileIcon.png')}
            
                />
            );
            final.push(
                <Container>
                    {parent}
                </Container>,
                <Container>
                <ActionButton
                    text="Report"
                    handler={()=>{
                        this.reportUserButtonTap();
                    }}
                    filled={true}
                />
                <ActionButton
                    text="Unblock User"
                    handler={()=>{
                        this.unblockUserButtonTap();
                    }}
                    filled={true}
                />
            </Container>
            );
        } else if (this.state.userBlocksMe) {
            parent.push(
                <Image 
                    style={styles.userImage}
                    source={require('../assets/profileIcon.png')}
            
                />
            );
            final.push(
                <Container>
                    {parent}
                </Container>
            );
        } else { 
            if (this.state.firstName != null && this.state.lastName != null) {
                parent.push(
                    <Text style={styles.hostName}>Dr. {this.state.firstName} {this.state.lastName}</Text>
                );
            } 
            if (this.state.photoUrl != null) {
                parent.push(
                    <Image 
                        style={styles.userImage}
                        source={{
                            uri: this.state.photoUrl,
                        }}
                    />
                )
            } else {
                parent.push(
                    <Image 
                        style={styles.userImage}
                        source={require('../assets/profileIcon.png')}
                
                    />
                );
            }
            
            final.push(
                <Container title={"Host Profile"} maxWidth={300}>
                    <View style={{width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}>
                        <View style={{ height: 220}}>
                            {parent}
                        </View>
                        <Text style={[styles.label, {minWidth: 200,}]}>{!!this.state.bio? this.state.bio: "Host has not added and about me"}</Text>
                    </View>
                </Container>
            );
            if (!!this.state.user) {
                final.push(
                    <View style={{maxWidth: 1300,}}>
                        <Feed 
                            listingData={this.state.rentalData} 
                            navigation={this.props.navigation}
                            showMoreIsShowing={false}
                            colums={2}
                        />
                    </View>
                )
            }
            if (this.state.npi != null) {
                // final.push(
                //     <Container>
                //         <Text style={styles.headerLabeltext}>NPI Number</Text>
                //         <Text style={styles.label}>{this.state.npi}</Text>
                //     </Container>
                // );
            }
            // if (this.state.reviewsData != null) {
    
            // } else {
            //     final.push(
            //         <Container>
            //             <Text style={styles.headerLabeltext}>Reviews</Text>
            //             <Text style={styles.label}>No reviews yet</Text>
            //             <ActionButton
            //                 text="Write a review"
            //                 handler={()=>{
    
            //                 }}
            //             />
            //         </Container>
            //     );
            // }
            let messageButton = [];
            if (!!this.state.user) {
                if (!this.state.user.isAnonymous) {
                    messageButton.push(
                        <ActionButton
                            text="Message"
                            handler={()=>{
                                this.messageUser();
                            }}
                        />,
                        <ActionButton
                            text="Block"
                            handler={()=>{
                                this.blockUserButtonTap();
                            }}
                            filled={true}
                        />
                    );
                } else {
                    messageButton.push(
                        <ActionButton
                            text="Log in to Message"
                            handler={()=>{
                                
                            }}
                        />
                    );
                }
            } else {
                messageButton.push(
                    <ActionButton
                        text="Log in to Message"
                        handler={()=>{
                            
                        }}
                    />
                );
            }
            final.push(
                <Container>
                     <ActionButton
                        text="Share"
                        handler={()=>{
                            this.setState({
                                shareModalVisible: true,
                                shareModalKey: Math.random().toString(36).substring(7),
                            })
                        }}
                    />
                    {messageButton}
                    <ActionButton
                        text="Report"
                        handler={()=>{
                            this.reportUserButtonTap();
                        }}
                        filled={true}
                    />
                </Container>
            );
        }
        return final;
    }

    render() {
        let profileUrl = `https://doc2docrental.com/?profileID=${this.props.route.params.profileUID}`
        return (
            <View style={{height: "100%"}}>
                <TopAlert text={this.state.topAlertMessage} isShowing={this.state.successIsShowing}/>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.errorModalVisible}
                    key={this.state.errorModalKey}
                    onModalClose={this.onModalClose}
                    labelText={this.state.errorMessage}
                    isError={true}
                />
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.confirmModalVisible}
                    key={this.state.confirmModalKey}
                    onModalClose={this.onModalClose}
                    onConfirmClick={() => this.state.confirmAction()}
                    labelText={this.state.confirmModalMessage}
                />
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.shareModalVisible}
                    key={this.state.shareModalKey}
                    onModalClose={this.onModalClose}
                    website={profileUrl}
                />
                <ScrollView contentContainerStyle={styles.container}>
                    {this.buildView()}
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    userImage: {
        width: 160,
        height: 160,
        borderRadius: 80,
        backgroundColor: "white",
        margin: 10,
        marginTop: 0,
    },
    hostName: {
        fontSize: 24,
        textAlign: "center",
        fontWeight: "600",
        marginBottom: 10,
    },
    label: {
        marginBottom: 10,
    }
})