import React, { Component } from 'react';
import { StyleSheet, Image, View, TouchableHighlight, Share, Text, Platform, Dimensions } from 'react-native';
import { getDatabase, ref, get, set } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDateString } from '../helpers/date-formatter';
import { getUserImage } from '../helpers/user-image-retreiver';
import uuid from 'react-native-uuid';
import { getFirestore, setDoc, doc } from "firebase/firestore";

import ActionButton from './ActionButton';
import Container from './Container';
import Modal from "./Modal";


export default class Feed extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: Dimensions.get('window').width,
            userImages: null,
            listingTitles: null,
            feedKey: Math.random().toString(36).substring(7),
            errorModalText: null,
            errorModalKey: Math.random().toString(36).substring(7),
            errorModalVisible: false,
        }
    }

    componentDidMount() {
        if (Platform.OS == "web") {
            window.addEventListener('resize', this.updateDimensions);
        }
        this.updateDimensions();
        if (!!this.props.requestsData) {
            this.getUserImages();
            this.getListingTitles()
        }
    }
    
    componentWillUnmount() {
        if (Platform.OS == "web") {
            window.removeEventListener('resize', this.updateDimensions);
        }
    }
    
    updateDimensions = () => {
        this.setState({ width:  Dimensions.get('window').width});
    };

    getUserImages = async () => {
        let userImages = {};
        for (let index in this.props.requestsData) {
            let requestData = this.props.requestsData[index];
            let imageUrl = await getUserImage(requestData.requesterUID);
            userImages[index] = imageUrl;
        }
        this.setState({
            userImages: userImages,
            feedKey: Math.random().toString(36).substring(7),
        })
    }

    getListingTitles = async () => {
        let listingTitles = {};
        const db = getDatabase();
        for (let index in this.props.requestsData) {
            let requestData = this.props.requestsData[index];
            const titleRef = ref(db, `publicRentals/${requestData.postID}/title`);
            let snapshot = await get(titleRef);
            let title = snapshot.val();
            listingTitles[requestData.postID] = title;
        }
        this.setState({
            listingTitles: listingTitles,
            feedKey: Math.random().toString(36).substring(7),
        });
    }

    navigateToUserProfile = uid => {
        this.props.navigation.getParent().setOptions({headerShown: false});
        this.props.navigation.navigate("Profile View", {
            profileUID: uid,
        })
    }

    createTable = () => {
        let parent = [];
        if (!!this.props.listingData) {
            let colums = 1;
            if (!!this.props.colums) {
                colums = this.props.colums;
            }

            if (colums == 1) {
                //let dataCount = Object.keys(this.state.listingData).length;
                parent.push(<View style={{width: "100%", height: 10,}}></View>)
                for (var listingDataKey in this.props.listingData) {
                    let listingData = this.props.listingData[listingDataKey];
                    parent.push(this.constructViewFromData(listingData, colums));
                }
            } else if (colums == 2) {
                let child = [];
                for (var listingDataKey in this.props.listingData) {
                    let listingData = this.props.listingData[listingDataKey];
                    child.push(this.constructViewFromData(listingData, colums));
                }
                parent.push(
                    <View style={styles.flexContainer}>
                        {child}
                    </View>
                )
            }
            if (this.props.showMoreIsShowing) {
                parent.push(
                    <View style={{width: "100%", display: "flex", alignContent: "center", alignItems: "center", justifyContent: "center",}}>
                        <ActionButton
                            text={"Show More"}
                            handler={() => {
                                this.props.showMoreCallback()
                            }}
                        />
                    </View>
                );
            }
        } else if (!!this.props.requestsData) {
            let colums = 1;
            let today = new Date();
            today.setHours(today.getHours() - 24)
            if (!!this.props.colums) {
                colums = this.props.colums;
            }
            if (colums == 1) {
                parent.push(<View style={{width: "100%", height: 10,}}></View>)
                for (var requestsDataKey in this.props.requestsData) {
                    let requestData = this.props.requestsData[requestsDataKey];
                    let checkIn = new Date(requestData.checkInDate);
                    if (today.getTime() <= checkIn.getTime()) {
                        parent.push(this.constructRequestFromData(requestData, colums));
                    }
                }
            } else if (colums == 2) {
                let child = [];
                for (var requestsDataKey in this.props.requestsData) {
                    let requestData = this.props.requestsData[requestsDataKey];
                    let checkIn = new Date(requestData.checkInDate);
                    if (today.getTime() <= checkIn.getTime()) {
                        child.push(this.constructRequestFromData(requestData, colums));
                    }
                }
                if (child.length > 0) {
                    parent.push(
                        <View style={styles.flexContainer}>
                            {child}
                        </View>
                    )
                } else {
                    parent.push(<Text style={{marginTop: 10, fontWeight: "600", width: "100%", textAlign: "center"}}>You have no requests yet</Text>);
                }
            }
        } else if (!this.props.listingData && !!this.props.requestsData) {
            parent.push(<Text style={{marginTop: 10, fontWeight: "600", width: "100%", textAlign: "center"}}>You have no requests yet</Text>);
        }
        return parent;
    }

    constructViewFromData(listingData, colums) {
        let parent = [];
        let locationLabel = "";
        if (!!listingData) {
            if (listingData.city) {
                locationLabel += listingData.city;
            }
            if (listingData.state) {
                locationLabel += " " + listingData.state;
            }
            if (listingData.country) {
                locationLabel += ", " + listingData.country;
            }
        }
        let title = [];

        title.push(
            <View style={{flexDirection:'row'}}> 
                <Text allowFontScaling={false} style={styles.usernameText}>
                    {listingData.title}
                </Text>
            </View>
        )
        
        parent.push(
            <View style={[styles.viewWrapper]}>
                <View style={styles.profileHeader}>
                    <View style={{width: "90%"}}>
                        {title}
                        <Text allowFontScaling={false} style={styles.locationLabel}>{locationLabel}</Text>
                    </View>
                </View>
                <Image 
                    style={styles.previewImage}
                    source={{
                        uri: listingData.photoUrls[0],
                      }}
                    resizeMode={"contain"}
                />
                <View style={styles.viewMoreButtonWrapper}>
                    <ActionButton
                        text={"View"}
                        handler={() => {
                            this.props.navigation.getParent().setOptions({headerShown: false});
                            this.props.navigation.navigate('Public Property', {
                                rentalData: listingData,
                            })
                        }}
                    />
                </View>
            </View>
        )

        if (colums == 2 && ((this.state.width >= 800 && this.state.width < 873) || this.state.width > 1120)) {
            let smallWrapper = [
                <View style={{width: "100%", maxWidth: 380}}>
                    {parent}
                </View>
            ];
            return smallWrapper;
        } else {
            let smallWrapper = [
                <View style={{width: "100%"}}>
                    {parent}
                </View>
            ];
            return smallWrapper;
        }
        //return parent;
    }

    constructRequestFromData = (requestData,  colums) => {
        let parent = [];
        // let userImage = await getUserImage(requestData.requesterUID)
        if (!!this.state.userImages) {
            let userImage = this.state.userImages[requestData.id];
            if (userImage != undefined) {
                parent.push(
                    <TouchableHighlight
                        onPress={()=>{
                            this.navigateToUserProfile(requestData.requesterUID)
                        }}
                    >
                        <View style={{display: "flex", flexDirection: "row", width: 280}}>
                            <Image 
                                style={styles.userImage}
                                source={{
                                    uri: userImage,
                                }}
                                // resizeMode={"contain"}
                            />
                            <Text style={styles.renterName}>{requestData.renterName}</Text>
                        </View>
                    </TouchableHighlight>
                );
            } else {
                parent.push(
                    <TouchableHighlight
                        onPress={()=>{
                            this.navigateToUserProfile(requestData.requesterUID)
                        }}
                    >
                        <View style={{display: "flex", flexDirection: "row", width: 280}}>
                            <Image 
                                style={styles.userImage}
                                source={require('../assets/profileIcon.png')}
                                // resizeMode={"contain"}
                            />
                            <Text style={styles.renterName}>{requestData.renterName}</Text>
                        </View>
                    </TouchableHighlight>
                );
            }
        } else {
            parent.push(
                <TouchableHighlight
                    onPress={()=>{
                        this.navigateToUserProfile(requestData.requesterUID)
                    }}
                >
                    <View style={{display: "flex", flexDirection: "row", width: 280}}>
                        <Image 
                            style={styles.userImage}
                            source={require('../assets/profileIcon.png')}
                            // resizeMode={"contain"}
                        />
                        <Text style={styles.renterName}>{requestData.renterName}</Text>
                    </View>
                </TouchableHighlight>
            );
        }
        if (this.state.listingTitles != null) {
            let propertyName = this.state.listingTitles[requestData.postID];
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 280}}>
                    <Text style={{width: 110, fontWeight: "600"}}>Requesting:</Text>
                    <View style={styles.divider}></View>
                    <Text>{propertyName}</Text>
                </View>
            );
        }
      
        let checkInDate = new Date(requestData.checkInDate);
        let checkOutDate = new Date(requestData.checkOutDate);
        let datesAray = this.getDaysArray(checkInDate, checkOutDate);
        let datesCount = datesAray.length - 1;
        let checkInString = getDateString(requestData.checkInDate);
        let checkOutString = getDateString(requestData.checkOutDate);
        parent.push(
            <View style={{display: "flex", flexDirection: "row", width: 280}}>
                <Text style={{width: 110, fontWeight: "600"}}>Check In:</Text>
                <View style={styles.divider}></View>
                <Text>{checkInString}</Text>
            </View>
        );
        parent.push(
            <View style={{display: "flex", flexDirection: "row",  width: 280}}>
                <Text style={{width: 110, fontWeight: "600"}}>Check Out:</Text>
                <View style={styles.divider}></View>
                <Text>{checkOutString}</Text>
            </View>
        );
        parent.push(
            <View style={{display: "flex", flexDirection: "row", width: 280}}>
                <Text style={{width: 110, fontWeight: "600"}}>Night(s):</Text>
                <View style={styles.divider}></View>
                <Text>x {datesCount}</Text>
            </View>
        );
        if (requestData.bringingPet) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 280}}>
                    <Text style={{width: 110, fontWeight: "600"}}>Pet:</Text>
                    <View style={styles.divider}></View>
                    <Text>Yes</Text>
                </View>
            );
        }
        parent.push(
            <View style={{display: "flex", flexDirection: "row",  width: 280}}>
                <Text style={{width: 110, fontWeight: "600"}}>People:</Text>
                <View style={styles.divider}></View>
                <Text>x {requestData.people}</Text>
            </View>
        );
        if (requestData.npi != "undefined") {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 280}}>
                    <Text style={{width: 110, fontWeight: "600"}}>NPI:</Text>
                    <View style={styles.divider}></View>
                    <Text>{requestData.npi}</Text>
                </View>
            );
        }
        parent.push(
            <View style={{display: "flex", flexDirection: "column", width: "100%"}}>
                <Text style={{width: "100%", fontWeight: "600"}}>Message:</Text>
                <Text 
                style={{
                    width: "100%",
                    backgroundColor: "#d3d3d3",
                    padding: 10
                }}>
                    {requestData.message}
                </Text>
            </View>
        );
        parent.push(
            <View style={{display: "flex", flexDirection: "row", marginTop: 10,}}>
                <Text style={{fontWeight: "600", fontSize: 18}}>Total: ${parseFloat(requestData.totalCollected).toFixed(2)}</Text>
            </View>
        )

        parent.push(
            <View style={{display: "flex", flexDirection: "column", marginTop: 10, width: "100%", alignItems: "center"}}>
                <ActionButton
                    text="Accept"
                    handler={()=>{
                        this.acceptRequest(requestData);
                    }}
                />
                <ActionButton
                    text="Decline"
                    handler={()=>{
                        this.declineRequest(requestData);
                    }}
                    filled={true}
                />
            </View>
        )

        let showRequest = true;
        if (!!this.props.blockList) {
            if (this.props.blockList[requestData.requesterUID] != null) {
                showRequest = false;
            }
        } 
        if (showRequest) {
            if (colums == 2 && ((this.state.width >= 800 && this.state.width < 873) || this.state.width > 1120)) {
                let smallWrapper = [
                    <Container maxWidth={380}>
                        <View style={{width: "100%", maxWidth: 380}}>
                            {parent}
                        </View>
                    </Container>
                ];
                return smallWrapper;
            } else {
                let smallWrapper = [
                    <Container>
                        <View style={{width: "100%"}}>
                            {parent}
                        </View>
                    </Container>
                ];
                return smallWrapper;
            }
        } 
        return [];
    }

    acceptRequest = async (requestData) => {
        if (!!this.props.onLoading) {
            this.props.onLoading(true);
        }
        let nameArray = requestData.renterName.split(" ");
        const approverUID = this.props.user.uid;
        const total = requestData.totalCollected.toString();
        const nightlyFee =  requestData.nightlyFee.toString();
        const guestFee =  requestData.guestFee.toString();
        const petFee = requestData.petFee.toString();
        const cleaningFee = requestData.cleaningFee.toString();
        const salesTax = requestData.taxCollected.toString();
        const occupancyTax = "0";
        const postID = requestData.postID;
        const checkIn = requestData.checkInDate;
        const checkOut = requestData.checkOutDate;
        const bringingPet =  requestData.bringingPet;
        const message = requestData.message;
        const people = requestData.people;
        const requesterUID = requestData.requesterUID; 
        const renterFirstName = nameArray[0];
        const renterLastName = nameArray[1];
        const customerID = requestData.customerID;
        const paymentSource = requestData.paymentSource;
        const npi = requestData.npi
        const requestID = requestData.id;
        const renterEmail = requestData.renterEmail;
        let url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/approveBooking?`;
        url += `total=${total}&nightlyFee=${nightlyFee}&guestFee=${guestFee}&petFee=${petFee}&`
        url += `cleaningFee=${cleaningFee}&salesTax=${salesTax}&occupancyTax=${occupancyTax}&`
        url += `postID=${postID}&checkIn=${checkIn}&checkOut=${checkOut}&bringingPet=${bringingPet}&`
        url += `message=${message}&people=${people}&requesterUID=${requesterUID}&`
        url += `renterFirstName=${renterFirstName}&renterLastName=${renterLastName}&`
        url += `customerID=${customerID}&paymentSource=${paymentSource}&renterEmail=${renterEmail}&}`;
        url += `npi=${npi}&requestID=${requestID}&approverUID=${approverUID}`;
        try {
            const response = await fetch(url);
            const bookingResponse = await response.json();
            if (bookingResponse.response == "success") {
                // this.props.navigation.navigate("Success", {
                //     reservationData: postID,
                // })
                //show success
                if (!!this.props.onSuccess) {
                    this.props.onSuccess();
                }
                if (!!this.props.onLoading) {
                    this.props.onLoading(false);
                }
                //create message thread
                this.createNewMessageThread(message, requesterUID, renterEmail);
            } else {
                console.log("something failed: ", bookingResponse.message)
                this.setState({
                    errorModalVisible: true,
                    errorModalKey: Math.random().toString(36).substring(7),
                    errorModalText: bookingResponse.message,
                });
                if (!!this.props.onLoading) {
                    this.props.onLoading(false);
                }
            }   
        } catch (error) {
            console.log(error)
            this.setState({
                errorModalVisible: true,
                errorModalKey: Math.random().toString(36).substring(7),
                errorModalText: bookingResponse.message,
            });
            if (!!this.props.onLoading) {
                this.props.onLoading(false);
            }
        }
    }

    createNewMessageThread = async (text, senderID, senderEmail) => {
        let createDate = new Date();
        const fsdb = getFirestore();
        let newID = uuid.v4();
        let messageID = `${createDate.getTime()}-${newID}`;
        let sender = {
            _id: senderID,
            email: senderEmail,
        }
        let data = {
            _id: messageID,
            createdAt: createDate.getTime(),
            text: text,
            sender: sender,
            recipient: {
                _id: this.props.user.uid,
                email: this.props.user.email,
            },
        };
        let newConvoID = uuid.v4();
        await setDoc(doc(fsdb, "messages", newConvoID, "conversation", messageID), data);
        await this.updateMyMessages(text, sender, createDate.getTime(), newConvoID, senderID);
    }

    updateMyMessages = async (text, sender, createdAt, convoID, senderID) => {
        const db = getDatabase();
        const myMessagesRef = ref(db, `myMessages/${this.props.user.uid}/${senderID}`);
        const userMyMessagesRef = ref(db, `myMessages/${senderID}/${this.props.user.uid}`);
        let data = {
          lastMessage: text,
          sender: sender,
          recipient: {_id: this.props.user.uid},
          createdAt: createdAt,
          id: convoID,
          read: true,
        };
        await set(userMyMessagesRef, data);
        await set(myMessagesRef, data);
      }

    declineRequest = async (requestData) => {
        const requestID = requestData.id;
        const postID = requestData.postID;
        const requesterUID = requestData.requesterUID;
        if (!!this.props.onLoading) {
            this.props.onLoading(true);
        }
        const functions = getFunctions();
        const denyBooking = httpsCallable(functions, 'denyBooking');
        let result = await denyBooking({
            requestID: requestID,
            requesterUID: requesterUID,
            postID: postID,
        });
        const data = result.data;
        if (data.response == "success") {
            if (!!this.props.onSuccess) {
                this.props.onSuccess();
            }
            if (!!this.props.onLoading) {
                this.props.onLoading(false);
            }
        } else {
            if (!!this.props.onLoading) {
                this.props.onLoading(false);
            }
        }
    }

    getDaysArray = (s,e) => {
        for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;
    };

    onShare = async (postID) => {
        try {
        let postURL = "https://doc2doc-d54a2.web.app/?postID=" + postID
          const result = await Share.share({
            url: postURL
          });
          if (result.action === Share.sharedAction) {
            if (result.activityType) {
              // shared with activity type of result.activityType
            } else {
              // shared
            }
          } else if (result.action === Share.dismissedAction) {
            // dismissed
          }
        } catch (error) {
          alert(error.message);
        }
    }

    render() {
        return (
            <View style={styles.searchTableWrapper} feedKey={this.state.feedKey}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.errorModalVisible}
                    key={this.state.errorModalKey}
                    onModalClose={this.onModalClose}
                    labelText={this.state.errorModalText}
                    isError={true}
                />
                { this.createTable() }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    viewWrapper: {
        backgroundColor: "white",
        width: "95%",
        borderWidth: 1,
        borderColor: "lightgrey",
        borderRadius: 6,
        margin: 5,
        padding: 10,
        // backgroundColor: "#171717",
        shadowOffset: {
            width: 0,
            height: 2,
          },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 3,
    },
    detailText: {
        color: "black",
    },
    viewMoreButtonWrapper: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      marginTop: 5,
    },
    elipsisWrapper: {
        flexGrow: 1,
    },
    elipsisButton: {
      width: 50,
      alignSelf: "flex-end",

    },
    profileHeader: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    //   height: 55,
      alignItems: "stretch",
    },
    detailsWrapper: {
        paddingLeft: 20,
        paddingRight: 20,
        width: "100%",
    },
    searchTableWrapper: {
        width: "100%",
        paddingTop: 20,
    },
    usernameText: {
        fontSize: 16,
        marginBottom: 0,
        marginLeft: 20,
        fontWeight: "600",
        marginTop: 10,
        // backgroundColor: "#171717",
        zIndex: 15,
        color: "black",
        flex: 1,
        flexWrap: 'wrap'
    },
    locationLabel: {
        color: "grey",
        padding: 5,
        fontSize: 12,
        marginLeft: 15,
    }, 
    button: {
        marginTop: 20,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
    },
    buttonText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    subHeaderTitle: {
        color: "black",
        fontSize: 14,
        fontWeight: "700",
    },
    noListingsLabel: {
        fontSize: 18,
        marginBottom: 250,
        marginTop: 100,
        fontWeight: "700",
        textAlign: "center",
        color: "black"
    },
    previewImage: {
        width: "100%",
        height: 300,
        margin: "auto",
        backgroundColor: "#ebecf0",
    },
    flexContainer: {
        padding: 10,
        paddingHorizontal: 20,
        paddingTop: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    proText: {
        fontWeight: "700",
        color: "#c79f59",
    },
    adWrapper: {
        width: "100%",
        height: 300,
    },
    divider: {
        backgroundColor: "#d3d3d3",
        width: 1,
        height: 20,
        marginHorizontal: 10,
    },
    userImage: {
        width: 60,
        height: 60,
        borderRadius: 35,
        backgroundColor: "white",
        margin: 10,
        marginTop: 0,
    },
    renterName: {
        fontWeight: "600",
        marginTop: 10,
    }
});
