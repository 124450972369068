import React, { Component }  from 'react';
import { StyleSheet, Image, View, Dimensions, TouchableHighlight, Text} from 'react-native';
import { ActivityIndicator } from 'react-native';

export default class SlideShow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
            height: Dimensions.get('window').height,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ height: window.innerHeight - 120});
    };

    constructSlideShow = () => {
        return this.constructWebSlideShow();
    }

    constructWebSlideShow =() => {
        let parent = [];
        if (!!this.props.imageUrls) {
            if (this.props.imageUrls.length >= 1) {
                parent.push(
                    <TouchableHighlight 
                        onPress={() => this.selectBack()}
                        style ={[styles.navigationArrowWrapper, styles.imageBackButton]}>
                        <Image
                            source={require('../assets/backArrowIcon.png')}
                            style={styles.arrowButton}
                        ></Image>
                    </TouchableHighlight>,
                    <TouchableHighlight 
                        onPress={() => this.selectForward()}
                        style ={[styles.navigationArrowWrapper, styles.imageForwardbutton]}>
                        <Image
                            source={require('../assets/forwardArrowIcon.png')}
                            style={[styles.arrowButton, styles.rightArrowButton]}
                        ></Image>
                    </TouchableHighlight>
                );
            } 
            parent.push(
                <View style={{ 
                    flex: 1, 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    // height:  600,
                    width: "100%",
                    position: "absolute", 
                    backgroundColor: "#ebecf0'",
                }}>
                    <ActivityIndicator size="large" style={styles.activityIndicator}/>
                </View>,
                <Image source={this.props.imageUrls[this.state.currentIndex]} style={styles.slideShowImage}/>,
                <View>
                    <Text style={styles.imageCounter}>{this.state.currentIndex + 1} / {this.props.imageUrls.length}</Text>
                </View>
            );
            
        }
        return parent;
    }

    selectBack = () => {
        if (this.props.imageUrls.length > 1) {
            if (this.state.currentIndex === 0) {
                this.setState({ currentIndex: (this.props.imageUrls.length - 1)});
            } else {
                this.setState({ currentIndex: (this.state.currentIndex - 1)});
            }   
        }
    }

    selectForward = () => {
        if (this.props.imageUrls.length > 1) {
            if (this.state.currentIndex === (this.props.imageUrls.length - 1 )) {
                this.setState({ currentIndex: 0});
            } else {
                this.setState({ currentIndex: (this.state.currentIndex + 1)});
            }
        }
    }

    render() {
        return (
            <View 
                style={{
                    width: "100%",
                    zIndex: 1,
                    backgroundColor: "#ebecf0",
                }}
            >
                <View 
                    style={{
                        backgroundColor: '#ebecf0',
                        width: "100%",
                        paddingBottom: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    {this.constructSlideShow()}
                </View>
            </View>
        );
    }
}


const styles = StyleSheet.create({
    imageBackButton: {
        left: "2%",
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        width: 40,
    }, 
    imageForwardbutton: {
        right: "2%",
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        width: 40,
    },
    slideShowImage: {
        width: "100%",
        height:  550,
        maxWidth: 900,
        resizeMode: "contain",
    },
    navigationArrowWrapper: {
        height: 75,
        width:75,
        paddingTop: 10,
        position: "absolute",
        zIndex: 12,
      },
    arrowButton: {
        height: 55,
        width:40,
        zIndex: 12,
    },
    rightArrowButton: {
        width: 40,
    },
    removeIcon: {
        height: 60,
        width:60,
        position: "absolute",
        right: "8%",
        alignItems: "center",
        justifyContent: "center",
        top: 30,
        zIndex: 12,
    },
    removeIconImage: {
        height: 30,
        width:30,
        zIndex: 12,
    },
    buttonBlue: {
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#3a93f1',
        borderColor: '#2790ff',
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
        height: 50,
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    uploadIcon: {
        height: 18,
        width: 10,
        marginLeft: 10,
        paddingLeft: 10,
    },
    textWrapper: {
        width: 200,
        height: 80,
        flexDirection:"row",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
    },
    imageCounter: {
        marginTop: 10,
        color: "#545a69",
    },
    viewPager: {
        flex: 1,
      },
});