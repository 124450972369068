import React, { Component } from 'react';
import { StyleSheet, Image, View, TouchableHighlight } from 'react-native';

export default class BackButton extends Component {

  backButtonOnPress = () => {
    if (!!this.props.handler) {
      this.props.handler();
    }
    this.props.navigation.goBack();
  }

  render() {
    return (        
      <TouchableHighlight 
          onPress={this.backButtonOnPress}
          style ={styles.backButtonWrapper}>
          <View style={[styles.backWrapper]}>
              <Image
                source={require('../assets/backArrow.png')}
                style={[styles.backButton]}
              ></Image>
          </View>
      </TouchableHighlight>
    )
  }
}

const styles = StyleSheet.create({
  backButtonWrapper: {
    height: 46,
    width:46,
    position: "absolute",
    left: "8%",
    top: 35,
    zIndex: 10,
  },
  backWrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 2,
    zIndex: 10,
    width: 46,
    height: 46,
  },
  backButton: {
    height: 46,
    width:46,
    zIndex: 10,
  },
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
        width: 6,
        height: 6,
    },
    shadowOpacity: 0.34,
    shadowRadius: 10.27,
    elevation: 10,
    borderRadius: 23,
  },
  highlight: {
      shadowColor: "#fff",
      shadowOffset: {
          width: -6,
          height: -6,
      },
      shadowOpacity: 0.9,
      shadowRadius: 8.27,
      elevation: 10,
      borderRadius: 23,
  },
});
