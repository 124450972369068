import React, { Component } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './Percentage.css';

export default class Percentage extends Component {
  render() {
    let percent = parseInt(this.props.percentage * 100);
    return (        
        <CircularProgressbar 
            value={this.props.percentage}  
            maxValue={1} 
            text={`${percent}%`}
            style={{font: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'}}
            styles={buildStyles({
              pathColor: `#5d51ef`,
              textColor: '#5d51ef',
              trailColor: 'light grey',
              pathTransitionDuration: 0.3,
            })}
        />
    )
  }
}
