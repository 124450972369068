import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, Image, Platform, Linking, Alert } from 'react-native';
import { getDatabase, ref, get } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import ReadMore from 'react-native-read-more-text';
import Container from "../compenents/Container";
import Map from "../compenents/Map";
import ActionButton from "../compenents/ActionButton";


export default class MyCheckInInstructions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            checkInData: null,
        }
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
           this.getUser();
        });
    }

    getUser = async () => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                this.setState({
                    user: user,
                });
                let success = await this.getCheckInInstructions(user);
            } else {
                this.setState({
                    user: null,
                })
            }
        });
    }

    getCheckInInstructions = async (user) => {
        if (!!this.props.route.params.reservationData) {
            const db = getDatabase();
            const checkInRef = ref(db, `myCheckInInstructions/${user.uid}/${this.props.route.params.reservationData.id}`);
            let snapshot = await get(checkInRef);
            let checkInData = snapshot.val();
            if (!!checkInData) {
                this.setState({
                    checkInData: checkInData,
                });
                return true;
            } else {
                return false;
            }
        }
    }

    buildView = () => {
        let parent = [];
        if (this.state.checkInData === null) {
            parent.push(
                <Container>
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>Check-in Instructions Not Available Yet</Text>
                    <Image
                        source={require('../assets/lock.png')}
                        style={{width: 40, height: 40}}
                    />
                    <Text allowFontScaling={false} >
                        Your check in instructions will become available 48 hours before your check in date and will be hidden after your check out date.
                    </Text>
                </Container>
            )
        } else {
            let mapData = {
                latitude: this.state.checkInData.lat,
                longitude: this.state.checkInData.lng,
                title: this.state.checkInData.address,
            }
            parent.push(
                <Container>
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>Check-in instructions</Text>
                    <ReadMore
                        numberOfLines={2}>
                        <Text>
                            {this.state.checkInData.checkInInstructions}
                        </Text>
                    </ReadMore>
                </Container>,
                <Container>
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>Exact address/ Directions</Text>
                    <Text allowFontScaling={false} style={{marginBottom: 5}}>{this.state.checkInData.address}</Text>
                    <Map 
                        isHome={false}
                        rentalsData={mapData}
                    />
                    <ActionButton
                        text="Open in Maps"
                        handler={()=>{
                            if (Platform.OS != "web") {
                                const url = Platform.select({
                                    ios: `maps:0,0?q=${this.state.checkInData.address}`,
                                    android: `geo:0,0?q=${this.state.checkInData.address}`,
                                })
                                Linking.openURL(url)
                            } else {
                                window.open("https://maps.google.com?q="+this.state.checkInData.lat+","+this.state.checkInData.lng );
                            }
                        }}
                    />
                </Container>
            )
        }
        return parent;
    }

    render() {
        return (
            <View style={{height: "100%"}}>
                <ScrollView contentContainerStyle={styles.container}>
                    {this.buildView()}
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        marginTop: 10,
        marginBottom: 10,
        fontWeight: "600",
        textAlign: "center",
    },
})