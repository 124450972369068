
import React, { Component } from 'react';
import { StyleSheet, Text, TouchableHighlight, View, Image, TextInput } from 'react-native';

export default class Stepper extends Component {

    state = { 
        count: null,
        longPressCount: 0,
        delayCount: 0,
        delayedHandlerInterval: null,
    }

    componentDidMount() {
        if (this.props.count != null) {
            if (this.props.percentage) {
                this.setState({ count: `${this.props.count.toFixed(1)}%` })
            } else {
                this.setState({ count: this.props.count })
            }
        }
    }

    handlePlusTap = () => {
        if (this.state.count != null) {
            let newCount = parseFloat(`${this.state.count}`.replace(/[^\d.-]/g, ''))+ 1;
            if (this.props.percentage) {
                newCount = parseFloat(`${this.state.count}`.replace(/[^\d.-]/g, '')) + 0.1;
            } 
            if (this.props.max != null) {
                if (newCount < this.props.max) {
                    if (this.props.percentage) {
                        if (!!this.props.handler) {
                            this.props.handler(newCount.toFixed(1));
                        }
                        this.setState({ count: `${newCount.toFixed(1)}%` })
                    } else {
                        if (!!this.props.handler) {
                            this.props.handler(newCount);
                        }
                        this.setState({ count: newCount })
                    }
                }
            } else {
                if (this.props.percentage) {
                    if (!!this.props.handler) {
                        this.props.handler(newCount.toFixed(1));
                    }
                    this.setState({ count: `${newCount.toFixed(1)}%` })
                } else {
                    if (!!this.props.handler) {
                        this.props.handler(newCount);
                    }
                    this.setState({ count: newCount })
                }
            }
        } else {
            if (this.props.percentage) {
                if (!!this.props.handler) {
                    this.props.handler(newCount);
                }   
                this.setState({ count: `0.1%` })
            } else {
                if (!!this.props.handler) {
                    this.props.handler(1);
                }
                this.setState({ count: 1 })
            }
        }
        if (!!this.props.delayedHandler) {
            this.delayedHandler()
        }
    }

    handleMinusTap = () => {
        if (this.state.count != null) {
            let newCount = parseFloat(`${this.state.count}`.replace(/[^\d.-]/g, '')) - 1;
            if (this.props.percentage) {
                newCount = parseFloat(`${this.state.count}`.replace(/[^\d.-]/g, '')) - 0.1;
            } 
            if (newCount >= 0) {
                if (this.props.percentage) {
                    this.setState({ count: `${newCount.toFixed(1)}%` })
                    if (!!this.props.handler) {
                        this.props.handler(newCount.toFixed(1));
                    }
                } else {
                    if (!!this.props.handler) {
                        this.props.handler(newCount);
                    }
                    this.setState({ count: newCount })
                }
            }
        } else {
            if (this.props.percentage) {
                if (!!this.props.handler) {
                    this.props.handler(newCount.toFixed(1));
                }
                this.setState({ count: newCount.toFixed(1) })
            } else {
                if (!!this.props.handler) {
                    this.props.handler(0);
                }
                this.setState({ count: 0 })
            }
        }
        if (!!this.props.delayedHandler) {
            this.delayedHandler();
        }
    }

    delayedHandler = () => {
        if (!!this.props.delayedHandler) {
            //check if delay counter is running
            if (this.state.delayedHandlerInterval == null) {
                //start counting
                let interval = setInterval(this.delayedCallBack, 1000);
                this.setState({delayedHandlerInterval: interval});
            } else {
                //restart the counter
                clearInterval(this.state.delayedHandlerInterval);
                let interval = setInterval(this.delayedCallBack, 1000);
                this.setState({delayedHandlerInterval: interval});
            }
        }
    }

    delayedCallBack = () => {
        clearInterval(this.state.delayedHandlerInterval);
        this.props.delayedHandler(this.state.count);
    }

    handleLongMinusTap = () => {
        var intervalId = setInterval(this.timerDown, 500);
        this.setState({intervalId: intervalId});
    }

    handleLongPlusTap = () => {
        var intervalId = setInterval(this.timerUp, 500);
        this.setState({intervalId: intervalId});
    }

    timerDown = () => {
        if (this.props.fast) {
            if (this.state.count >= 50) {
                if (this.state.longPressCount <= 4 && this.state.count >= 100) {
                    this.setState({ count:  this.state.count - 100, longPressCount: this.state.longPressCount + 1})
                    this.props.handler(this.state.count);
                }else if (this.state.longPressCount >= 8 && this.state.count >= 500) {
                    this.setState({ count:  this.state.count - 500, longPressCount: this.state.longPressCount + 1})
                    this.props.handler(this.state.count);
                } else {
                    this.setState({ count:  this.state.count - 50, longPressCount: this.state.longPressCount + 1})
                    this.props.handler(this.state.count);
                }
            } else if (this.state.count >= 2) {
                this.setState({ count:  this.state.count - 1  })
                this.props.handler(this.state.count);
            }
        } else {
            if (this.state.count >= 5) {
                this.setState({ count:  this.state.count - 5  })
                this.props.handler(this.state.count);
            } else if (this.state.count >= 2) {
                this.setState({ count:  this.state.count - 1  })
                this.props.handler(this.state.count);
            }
        }
     }

     timerUp = () => {
        if (this.props.fast) {
            if (this.state.longPressCount <= 4) {
                this.setState({ count:  this.state.count + 50, longPressCount: this.state.longPressCount + 1});
                this.props.handler(this.state.count);
            } else if (this.state.longPressCount >= 8) {
                this.setState({ count:  this.state.count + 500, longPressCount: this.state.longPressCount + 1});
                this.props.handler(this.state.count);
            } else {
                this.setState({ count:  this.state.count + 100, longPressCount: this.state.longPressCount + 1});
                this.props.handler(this.state.count);
            }
        } else {
            if (this.props.max != null) {
                let newCount = this.state.count + 5;
                if (newCount < this.props.max) {
                    this.setState({ count:  this.state.count + 5  });
                    this.props.handler(this.state.count);
                }
            } else {
                this.setState({ count:  this.state.count + 5  });
                this.props.handler(this.state.count);
            }
        }
     }

    handlePressOut = () => {
        clearInterval(this.state.intervalId);
        this.setState({
            longPressCount: 0,
        })
    }

    buildView = () => {
        let parent = [];
        if (!!this.props.label) {
            parent.push(
                <Text 
                    style={{
                        fontWeight: "600",
                        color: "black",
                        textAlign: "center",
                        marginTop: 10,
                        marginBottom: 5,
                    }}
                    allowFontScaling={false}
                >
                    {this.props.label}
                </Text>,
            );
        }
        parent.push(
            <View style={styles.stepperWrapper}>
                <TouchableHighlight 
                    style={styles.minusWrapper}
                    onPress={() => this.handleMinusTap()}
                    onLongPress={() => this.handleLongMinusTap()}
                    onPressOut={() => this.handlePressOut()}
                >
                    <Image
                        source={require('../assets/minusIcon.png')}
                        style={styles.stepIcon}
                    ></Image>
                </TouchableHighlight>

                {this.props.money?
                    <Text
                        style={{
                            textAlign: "right",
                            fontSize: 22,
                            color: "black",

                        }}
                    >$</Text>:
                    null
                }

                {this.props.money?
                    <Text 
                        style={{
                            textAlign: "left",
                            fontSize: 22,
                            color: "black",
                        }}
                    >
                        {this.state.count}
                    </Text>:
                    null
                }
                {
                    this.props.percentage ?
                    <TextInput
                        style={styles.inputBox}
                        onChangeText={count => {
                            if (parseFloat(count.replace(/[^\d.-]/g, '')) < 10) {
                                this.setState({ count: `${parseFloat(count.replace(/[^\d.-]/g, '')).toFixed(1)}%` });
                                this.props.handler(parseFloat(count.replace(/[^\d.-]/g, '')).toFixed(1));
                            }
                          
                        }}
                        placeholder='0%'
                        placeholderTextColor='black' 
                        value={`${this.state.count}`}
                    />
                    : null
                }
                {
                    this.props.money == null && this.props.percentage == null ?
                    <Text 
                        style={{
                            textAlign: "center",
                            fontSize: 22,
                            color: "black"
                        }}
                    >
                        {this.state.count}
                    </Text>:
                    null
                }
                <TouchableHighlight 
                    style={styles.plusWrapper}
                    onPress={() => this.handlePlusTap()}
                    onLongPress={() => this.handleLongPlusTap()}
                    onPressOut={() => this.handlePressOut()}
                >
                    <Image
                        source={require('../assets/plusIcon.png')}
                        style={styles.stepIcon}
                    ></Image>
                </TouchableHighlight>
            </View>     
        )
        return parent;
    }

    render() {
        return (
            <View style={styles.container}>
                {this.buildView()}
            </View>  
        ) 
    }
}


const styles = StyleSheet.create({
    container: {
        height: 60,
    },  
    stepperWrapper: {
        flexDirection:"row",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        height: 40,
    },
    checkBoxText: {
        fontSize: 10,
        marginLeft: 10,
    },
    minusWrapper: {
        width: 45,
        height: 45,
        marginRight: 25,
    },
    plusWrapper: {
        width: 45,
        height: 45,
        marginLeft: 25,
    },
    stepIcon: {
        width: 45,
        height: 45,
    },
    inputBox: {
        textAlign: "center",
        fontSize: 18,
        color: "black",
        backgroundColor: "white",
        width: 50
    }
});