import React, { Component } from 'react';
import {Image, Text, StyleSheet, View } from 'react-native';

import * as Icons  from '../assets';

import ExpandableView from "./ExpandableView"

let labelData = {
  "baby" :"Safe for babies",
  "toddler" :"Safe for toddlers",
  "children" :"Safe for children",
  "crib" :"Crib",
  "highChair" :"High chair",
  "handiCap" :"Wheelchair accessible",
  "pool" :"Pool",
  "hotTub" :"Hot Tub",
  "wifi" :"Free Wifi",
  "kitchen" :"Kitchen",
  "fridge" :"Refridgerator",
  "freezer" :"freezer",
  "stoveTop" :"Stove top",
  "microwave" :"Microwave",
  "utencils" :"Utencils",
  "dishes" :"Dishes",
  "coffeeMaker" :"Coffee Maker",
  "dishWasher" :"Dish Washer",
  "tv" :"TV",
  "hotWater" :"Hot Water",
  "barbeque" :"Barbeque Grill",
  "bathTub" :"Bath Tub",
  "shower" :"Shower",
  "towels" :"Towels",
  "ac" :"Air Conditioning",
  "carEV" :"Car EV Hookup",
  "firepit" :"Fire pit",
  "patio" :"Patio",
};


export default class Accommodations extends Component {

  buildIcons = () => {
    let parent = [];
    let extras = this.props.rentalData.extraAmmenities
    Object.keys(this.props.rentalData.extraAmmenities).forEach(function(key) {
      let showAmmenity = extras[key];
      if (showAmmenity) {
        parent.push(
          <View style={styles.flexBox}>
            <Image
              source={Icons[`${key}`]}
              style={[styles.icon]}
            />
            <Text allowFontScaling={false}  style={styles.boldText}>{labelData[key]}</Text>
          </View>
        );
      }
    });
    return parent;
  }

  buildView = () => {
    let parent = [];
    if (!!this.props.rentalData) {
      parent.push(
        <View style={styles.scrollViewWrapper}>
          <AccommodationView accomodationType={"bedrooms"} value={this.props.rentalData.bedrooms}/>
          <View style={styles.divider}/>
          <AccommodationView accomodationType={"bathrooms"} value={this.props.rentalData.bathrooms}/>
          <View style={styles.divider}/>
          <AccommodationView accomodationType={"accommodates"} value={this.props.rentalData.accommodates}/>
          <View style={styles.divider}/>
          <AccommodationView accomodationType={"electric"} value={this.props.rentalData.electric}/>
          <View style={styles.divider}/>
          <AccommodationView accomodationType={"petFriendly"} value={this.props.rentalData.petFriendly}/>
          <View style={styles.divider}/>
          <AccommodationView accomodationType={"washerDryer"} value={this.props.rentalData.washerDryer}/>
        </View>
      );
      if (!!this.props.rentalData.extraAmmenities) {
        parent.push(
          <View>
            <ExpandableView>
              <View style={styles.flexBoxWrapper}>
                {this.buildIcons()}
              </View>
            </ExpandableView>
          </View>
        );
      }
    }
    return parent;
  }

  render() {
    if (!!this.props.rentalData) {
      return (this.buildView())
    }
    return (null);
  }
}

class AccommodationView extends Component {

  render() {
    switch(this.props.accomodationType) {
      case "accommodates":
        return (
          <View style={styles.amentitesContainer}>
            <Text allowFontScaling={false}>Accommodates</Text>
            <Image 
              source={require('../assets/peopleIcon.png')}
              style={styles.amentitiesIcon}
            />
            <Text 
              allowFontScaling={false} 
              style={styles.boldText}
            >
              {this.props.value}
            </Text>
          </View>
        );
      case "bathrooms":
        return (
          <View style={styles.amentitesContainer}>
            <Text allowFontScaling={false}>Bathrooms</Text>
            <Image 
                source={require('../assets/bathIcon.png')}
                style={styles.amentitiesIcon}
              />
            <Text 
              allowFontScaling={false} 
              style={styles.boldText}
            >{this.props.value}</Text>
          </View>
        );
      case "petFriendly":
        return (
          <View style={styles.amentitesContainer}>
            <Text allowFontScaling={false}>Pet Friendly</Text>
            <Image 
              source={require('../assets/petIcon.png')}
              style={styles.amentitiesIcon}
            />
            <Text 
              allowFontScaling={false} 
              style={styles.boldText}
            >
              {this.props.value ? 
                  "Yes" : "No"
              }    
            </Text>
          </View>
        );
      case "electric":
        return (
          <View style={styles.amentitesContainer}>
            <Text allowFontScaling={false}>Electric</Text>
            <Image 
              source={require('../assets/electricIcon.png')}
              style={styles.amentitiesIcon}
            />
            <Text 
              allowFontScaling={false} 
              style={styles.boldText}
            >
              {this.props.value ? 
                  "Yes" : "No"
              }    
            </Text>
          </View>
        );
      case "washerDryer":
        return (
          <View style={styles.amentitesContainer}>
            <Text allowFontScaling={false}>Washer/Dryer</Text>
            <Image 
              source={require('../assets/washerIcon.png')}
              style={styles.amentitiesIcon}
            />
            <Text 
              allowFontScaling={false} 
              style={styles.boldText}
            >
              {this.props.value ? 
                  "Yes" : "No"
              }    
            </Text>
          </View>
        );
      default:
        return (
          <View style={styles.amentitesContainer}>
            <Text allowFontScaling={false}>Bedrooms</Text>
            <Image 
              source={require('../assets/bedIcon.png')}
              style={styles.amentitiesIcon}
            />
            <Text 
              allowFontScaling={false} 
              style={styles.boldText}
            >{this.props.value}</Text>
          </View>
        );
    }
  }
}

 
const styles = StyleSheet.create({
  container: { 
    height: 80,
    width:80,
    marginTop: 0,
    marginBottom: 0,
  },
  scrollContainer: {
    width:"100%",
    maxWidth: 680,
  },
  flexBox: {
    flexDirection:"row",
    alignItems: "center",
    justifyContent: "center",
    padding:10,
  },
  flexBoxWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
},
  scrollViewWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    maxWidth: 1100,
  },
  amentitesContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    margin:10,
  },
  amentitiesIcon: {
    height: 35,
    width:35,
    margin: 5,
  }, 
  divider: {
    backgroundColor: "#d3d3d3",
    width: 1,
    height: "30%",
    marginTop: 50,
  },
  icon: {
    width: 30,
    height: 30,
    elevation: 0, 
    shadowOffset: { height: 0, width: 0 }, 
    shadowOpacity: 0, 
    shadowRadius: 0,
    marginRight: 10,
  },
  boldText: {
    fontSize: 14,
    fontWeight: "600",
  }
});