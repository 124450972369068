import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, Platform, Alert } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import Constants from 'expo-constants';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import * as Linking from 'expo-linking';

import ActionButton from '../compenents/ActionButton';
import Container from "../compenents/Container";
import { getDateString } from '../helpers/date-formatter';
import { getHostRevenueData } from "../helpers/price-calculator";

export default class Money extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: false,
            onboardingComplete: false,
            payoutData: null,
            pendingTotal: 0,
            pendingPayouts: 0,
            goldListings: null,
            postTitleData: null,
            reservationData: null,
        }
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.getUser();
        });
        this.getUser();
    }

    getUser = () => {
        this.setState({loading: true})
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user }, () => {
                    this.setState({loading: false})
                    if (!!this.props.route.params) {
                        if (!!this.props.route.params.reauth) {
                            this.requestOnBoading();
                        }
                    } else {
                        this.checkIfOnboardingComplete();
                    }
                });
            } else {
                this.setState({ user: null });
            }
        });
    }

    checkIfOnboardingComplete = async () => {
        this.setState({
            loading: true,
        })
        let url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/verifySeller?requesterUID=${this.state.user.uid}`;
        try {
            const response = await fetch(url);
            const onboardingResponse = await response.json();
            if (onboardingResponse.response == "success") {
                this.setState({
                    onboardingComplete: true,
                })
            }
            this.setState({
                loading: false,
            })
            this.requestPayouts();
            this.getReservationData();
        } catch(e) {
            Alert.alert("There was an issue verifying your stripe account")
        }
    }

    requestPayouts = async () => {
        const db = getDatabase();
        const payoutsRef = ref(db, `payouts/${this.state.user.uid}`);
        let snapshot = await get(payoutsRef);
        const payoutsData = snapshot.val();
        if (payoutsData !== null) {
            this.calculatePendingTotal(payoutsData);
            this.setState({
                payoutData: payoutsData,
            });
        }
    }

    getReservationData = async () => {
        const fsdb = getFirestore();
        if (this.state.payoutData != null) {
            let reservationData = null;
            for (let index in this.state.payoutData) {
                let payout = this.state.payoutData[index];
                //get reservation data for each payout key
                const docRef = doc(fsdb, "reservations", payout.id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                  if (reservationData == null) {
                    reservationData = {};
                  }
                  reservationData[payout.id] = docSnap.data();
                } 
            }
            this.setState({
                reservationData: reservationData
            })
        }
    }

    checkGoldListing = async (postID) => {
        const db = getDatabase();
        const rentalsRef = ref(db, `publicRentals/${postID}`);
        let snapshot = await get(rentalsRef);
        const rentalData = snapshot.val();
        let isGold = false;
        if (rentalData !== null) {
            if (rentalData.subscriptionLevel != null) {
                if (rentalData.subscriptionLevel == "gold") {
                    isGold = true;
                }
            }
            this.getPostTitle(rentalData);
        }
        if (isGold) {
            let newGoldListings = {};
            if (this.state.goldListings != null) {
                newGoldListings = this.state.goldListings;
            }
            newGoldListings[postID] = true;
            this.setState({
                goldListings: newGoldListings,
            });
        }
        return isGold;
    }

    getPostTitle = (rentalData) => {
        let newTitleData = {};
        if (this.state.postTitleData != null) {
            newTitleData = this.state.postTitleData;
        }
        newTitleData[rentalData.key] = rentalData.title;
        this.setState({
            postTitleData: newTitleData,
        });
    }

    requestOnBoading = async () => {
        let returnUrl = "https://doc2doc-d54a2.web.app";
        if (Platform.OS == "web") {
            returnUrl = window.location.origin;
        }
        let url = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/requestSellerOnboardingAccount?";
        url += `requesterUID=${this.state.user.uid}&returnUrl=${returnUrl}&device=${Platform.OS}`;
        this.setState({
            loading: true,
        })
        try {
            const response = await fetch(url);
            const onboardingResponse = await response.json();
            if (!!onboardingResponse) {
                if (onboardingResponse.response == "success") {
                    if (Platform.OS == "web") {
                        window.location = onboardingResponse.url;
                    } else {
                        let browserParams = {};
                        browserParams["dismissButtonStyle"] = "done";
                        WebBrowser.openBrowserAsync(onboardingResponse.url).then(closeData => {
                            let url = closeData.url;
                            this.checkIfOnboardingComplete();
                        }).catch(error => {
                            console.log(error)
                        });
                    }
                }
            } else {
                Alert.alert("There was and issue onboarding your as a host ");
            }
            this.setState({
                loading: false,
            })
        } catch (error) {
            Alert.alert("There was and issue onboarding your as a host: ", error);
        }
    }

    getDaysArray = (s,e) => {
        for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;
    };

    buildPayouts = () => {
        let parent = [];
        if (this.state.payoutData != null && this.state.reservationData != null) {
            for (let index in this.state.payoutData) {
                let final = [];
                let payout = this.state.payoutData[index];
                let reservation =  this.state.reservationData[index];
                if (reservation.status == "awaiting check in" || reservation.status == "checked in") {
                    let postTitle = ""
                    if (this.state.postTitleData != null) {
                        postTitle = this.state.postTitleData[payout.postID];
                    }
                    let createdDate = new Date(payout.created)
                    let payOutDate = new Date(payout.checkOutDate);
                    payOutDate.setHours(payOutDate.getHours() + 32);
                    let payoutDateString = getDateString(payOutDate.toLocaleDateString('en-CA'));
                    let checkInString = getDateString(payout.checkInDate);
                    let checkOutString = getDateString(payout.checkOutDate);

                    let paymentData = getHostRevenueData(payout);

                    final.push(
                        <View style={styles.flexWrapper}>
                            <Text style={styles.labelBold}>Renting:</Text>
                            <Text style={{
                                maxWidth: 200, 
                                borderLeftColor: "#d3d3d3", 
                                borderLeftWidth: 1, 
                                marginLeft: 10,
                                paddingLeft: 10,
                            }}>{postTitle}
                            </Text>
                        </View>,
                        <View style={styles.flexWrapper}>
                            <Text style={styles.labelBold}>Check In:</Text>
                            <Text  style={{
                                maxWidth: 200, 
                                borderLeftColor: "#d3d3d3", 
                                borderLeftWidth: 1, 
                                marginLeft: 10,
                                paddingLeft: 10,
                            }}>{checkInString}</Text>
                        </View>
                    );
                    final.push(
                        <View style={styles.flexWrapper}>
                            <Text style={styles.labelBold}>Check Out:</Text>
                            <Text  style={{
                                maxWidth: 200, 
                                borderLeftColor: "#d3d3d3", 
                                borderLeftWidth: 1, 
                                marginLeft: 10,
                                paddingLeft: 10,
                            }}>{checkOutString}</Text>
                        </View>
                    );
                    final.push(
                        <View style={styles.flexWrapper}>
                            <Text style={styles.labelBold}>Funds Available:</Text>
                            <Text style={{
                                maxWidth: 200, 
                                borderLeftColor: "#d3d3d3", 
                                borderLeftWidth: 1, 
                                marginLeft: 10,
                                paddingLeft: 10,
                            }}>{payoutDateString}</Text>
                        </View>
                    );
                    if (payout.nightlyFee) {
                        final.push(
                            <View style={styles.flexWrapper}>
                                <Text style={styles.labelBold}>Rental Fee:</Text>
                                <Text style={{
                                    maxWidth: 200, 
                                    borderLeftColor: "#d3d3d3", 
                                    borderLeftWidth: 1, 
                                    marginLeft: 10,
                                    paddingLeft: 10,
                                }}>${payout.nightlyFee.toFixed(2)}</Text>
                            </View>
                        );
                    }
                    if (payout.petFee) {
                        final.push(
                            <View style={styles.flexWrapper}>
                                <Text style={styles.labelBold}>Pet Fee:</Text>
                                <Text style={{
                                maxWidth: 200, 
                                borderLeftColor: "#d3d3d3", 
                                borderLeftWidth: 1, 
                                marginLeft: 10,
                                paddingLeft: 10,
                            }}>${payout.petFee.toFixed(2)}</Text>
                            </View>
                        );
                    }
                    if (payout.cleaningFee) {
                        final.push(
                            <View style={styles.flexWrapper}>
                                <Text style={styles.labelBold}>Cleaning Fee:</Text>
                                <Text style={{
                                maxWidth: 200, 
                                borderLeftColor: "#d3d3d3", 
                                borderLeftWidth: 1, 
                                marginLeft: 10,
                                paddingLeft: 10,
                            }}>${payout.cleaningFee.toFixed(2)}</Text>
                            </View>
                        );
                    }
                    if (payout.taxCollected) {
                        final.push(
                            <View style={styles.flexWrapper}>
                                <Text style={styles.labelBold}>Tax:</Text>
                                <Text style={{
                                maxWidth: 200, 
                                borderLeftColor: "#d3d3d3", 
                                borderLeftWidth: 1, 
                                marginLeft: 10,
                                paddingLeft: 10,
                            }}>${payout.taxCollected.toFixed(2)}</Text>
                            </View>
                        );
                    }
                    if (paymentData.hostFee != 0) {
                        let fees = parseFloat(paymentData.hostFee)
                        final.push(
                            <View style={styles.flexWrapper}>
                                <Text style={styles.labelBold}>Platform Fee:</Text>
                                <Text style={{
                                maxWidth: 200, 
                                borderLeftColor: "#d3d3d3", 
                                borderLeftWidth: 1, 
                                marginLeft: 10,
                                paddingLeft: 10,
                            }}>-${fees.toFixed(2)}</Text>
                            </View>
                        );
                    }
                    parent.push(
                        <Container>
                            <View style={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "flex-end"}}>
                                <Text style={{width: 80, fontSize: 10, color: "grey"}}>{createdDate.toLocaleDateString()}</Text>
                            </View>
                            <View style={{display: "flex", flexDirection: "row", marginTop: 10, width: "100%", justifyContent: "space-around", flexWrap: "wrap", marginBottom: 10}}>
                                <View style={{borderRightColor: "#d3d3d3", borderRightWidth: 1, paddingRight: 10}}>
                                    {final}
                                </View>
                                <Text style={{fontWeight: "600", fontSize: 18, marginTop: 10}}>Total: ${parseFloat(paymentData.hostPayout).toFixed(2)}</Text>
                            </View>
                        </Container>
                    );
                }
            }
        } else {
            parent.push(
                <Container>
                    <Text>You currently have no payouts</Text>
                </Container>
            )
        }

        return parent;
    }

    calculatePendingTotal = async (payoutsData) => {
        let total = 0;
        for (let index in payoutsData) {
            let payout = payoutsData[index];
            let isGold = await this.checkGoldListing(payout.postID);
            payout["isGold"] = isGold
            let payoutTotal = getHostRevenueData(payout);
            total += payoutTotal.hostPayout;
        }
        this.setState({
            pendingTotal: total,
        })
    }

    requestStripeAccount = async () => {
        this.setState({
            loading: true,
        });
        let returnUrl = "https://doc2doc-d54a2.web.app"
        if (Platform.OS == "web") {
            returnUrl = window.location.origin;
        }
        const functions = getFunctions();
        const requestStripeExpressLink = httpsCallable(functions, 'requestStripeExpressLink');
        let result = await requestStripeExpressLink({returnUrl: returnUrl})
        const data = result.data;
        if (data.response == "success") {
            this.setState({
                loading: false,
            });
            if (Platform.OS == "web") {
                window.open(data.url, "_blank")
            } else {
                try {
                    WebBrowser.openBrowserAsync(data.url).catch(error => {
                        console.log(error)
                    });
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }

    buildView = () => {
        let parent = [];
        let payoutLength = 0;
            if (this.state.payoutData != null) {
                payoutLength = Object.keys(this.state.payoutData).length
            }
        if (this.state.onboardingComplete) {
            parent.push(
                <Container>
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>Revenue</Text>
                    <Text allowFontScaling={false} style={{marginTop: 10}}>Doc to Doc Rentals collects all fees from the renters and pays out hosts 24 hours after check out.</Text>
                    <Text allowFontScaling={false} style={{marginBottom: 10}}>All payouts are completed through Stripe.</Text>
                    {
                        this.state.loading ?
                        <ActionButton 
                            text="View Stripe Account"
                            loading={true}
                        />:
                        <ActionButton 
                            text="View Stripe Account"
                            handler={()=>{
                                this.requestStripeAccount();
                            }}
                        />
                    }
                </Container>,
                <Container>
                    <View style={{display: "flex", flexDirection: "row", width: "90%", justifyContent: "space-evenly", marginBottom: 10}}>
                        <View>
                            <Text allowFontScaling={false} style={styles.headerLabeltext}>Pending Balance</Text>
                            <Text style={{fontSize: 18, fontWeight: "600", textAlign: "center", padding: 5}}>${this.state.pendingTotal.toFixed(2)}</Text>
                        </View>
                        <View>
                            <Text allowFontScaling={false} style={styles.headerLabeltext}>Pending Reservations</Text>
                            <Text style={{fontSize: 18, fontWeight: "600", textAlign: "center", padding: 5}}>{payoutLength}</Text>
                        </View>
                    </View>
                </Container>,
                this.buildPayouts()
            );
        } else {
            if (this.state.loading) {
                parent.push(
                    <Container>
                        <Text allowFontScaling={false} style={styles.headerLabeltext}>Revenue</Text>
                        <Text allowFontScaling={false} style={{marginTop: 10}}>Doc to Doc Rentals collects all fees from the renters and pays out hosts 24 hours after check out.</Text>
                        <Text allowFontScaling={false} style={{marginBottom: 10}}>All payouts are completed through Stripe. Complete seller onboarding to begin collecting your money.</Text>
                        <ActionButton 
                            text="Onboard as Host"
                            loading={true}
                        />
                    </Container>,
                    <Container>
                        <View style={{display: "flex", flexDirection: "row", width: "90%", justifyContent: "space-evenly", marginBottom: 10}}>
                            <View>
                                <Text allowFontScaling={false} style={styles.headerLabeltext}>Pending Balance</Text>
                                <Text style={{fontSize: 18, fontWeight: "600", textAlign: "center", padding: 5}}>${this.state.pendingTotal.toFixed(2)}</Text>
                            </View>
                            <View>
                                <Text allowFontScaling={false} style={styles.headerLabeltext}>Pending Reservations</Text>
                                <Text style={{fontSize: 18, fontWeight: "600", textAlign: "center", padding: 5}}>{payoutLength}</Text>
                            </View>
                        </View>
                    </Container>,
                    this.buildPayouts()
                );
            } else {
                parent.push(
                    <Container>
                        <Text allowFontScaling={false} style={styles.headerLabeltext}>Revenue</Text>
                        <Text allowFontScaling={false} style={{marginTop: 10}}>Doc to Doc Rentals collects all fees from the renters and pays out hosts 24 hours after check out.</Text>
                        <Text allowFontScaling={false} style={{marginBottom: 10}}>All payouts are completed through Stripe. Complete seller onboarding to begin collecting your money.</Text>
                        <ActionButton 
                            text="Onboard as Host"
                            handler={()=>{
                                this.requestOnBoading();
                            }}
                        />
                    </Container>,
                    <Container>
                         <View style={{display: "flex", flexDirection: "row", width: "90%", justifyContent: "space-evenly", marginBottom: 10}}>
                            <View>
                                <Text allowFontScaling={false} style={styles.headerLabeltext}>Pending Balance</Text>
                                <Text style={{fontSize: 18, fontWeight: "600", textAlign: "center", padding: 5}}>${this.state.pendingTotal.toFixed(2)}</Text>
                            </View>
                            <View>
                                <Text allowFontScaling={false} style={styles.headerLabeltext}>Pending Reservations</Text>
                                <Text style={{fontSize: 18, fontWeight: "600", textAlign: "center", padding: 5}}>{payoutLength}</Text>
                            </View>
                        </View>
                    </Container>,
                    this.buildPayouts()
                );
            }
        }
        return parent;
    }

    render() {
        return (
            <View style={{height: "100%"}}>
                <ScrollView contentContainerStyle={styles.container}>
                    {this.buildView()}
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        marginTop: 10,
        fontWeight: "600",
        textAlign: "center",
    },
    divider: {
        backgroundColor: "#d3d3d3",
        width: 1,
        height: 20,
        marginHorizontal: 10,
    },
    flexWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginVertical: 1,
    },
    labelBold: {
        width: 120,
        fontWeight: "600",
    },
})