import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import Collapsible from 'react-collapsible';
import './ExpandableView.css';

class ExpandableView extends Component {
    state = {
      expanded: false,
    }

    constructor(props) {
      super(props);
    }

    componentDidMount() {
      if (!!this.props.isOpen) {
        this.setState({expanded: this.props.isOpen});
      }
    }

    buildView = () => {
      let parent = [];
      if (this.props.showTrigger != null) {
        if (this.props.showTrigger) {
          parent.push(
            <div class="expandable">
              <Collapsible 
                  trigger={this.state.expanded ? "Show less": "Show more"}
                  onOpen={()=>{
                      this.setState({
                          expanded: true,
                      });
                  }}
                  onClose={()=>{
                      this.setState({
                          expanded: false,
                      });
                  }}
              >
                  <View style={styles.button}>
                      {this.props.children}
                  </View>
              </Collapsible>
            </div>
          )
        } else {
          parent.push(
            <div class="noTrigger">
              <Collapsible 
                  open={this.props.isOpen}
                  onOpen={()=>{
                      this.setState({
                          expanded: true,
                      });
                  }}
                  onClose={()=>{
                      this.setState({
                          expanded: false,
                      });
                  }}
              >
                <View style={styles.button}>
                    {this.props.children}
                </View>
              </Collapsible>
            </div>
          )
        }
      } else {
        parent.push(
          <div class="expandable">
            <Collapsible 
                trigger={this.state.expanded ? "Show less": "Show more"}
                onOpen={()=>{
                    this.setState({
                        expanded: true,
                    });
                }}
                onClose={()=>{
                    this.setState({
                        expanded: false,
                    });
                }}
            >
                <View style={styles.button}>
                    {this.props.children}
                </View>
            </Collapsible>
          </div>
        )
      }
      return parent;
    }
  
    render() {
      return (
        <>{this.buildView()}</>
      );
    }
  }

  const styles = StyleSheet.create({

    button: {
      textAlign: "center",
    },
  });
  
  export default ExpandableView;