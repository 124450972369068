import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, Platform, TouchableHighlight, Image, View, Dimensions } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import { getDatabase, ref, get, update, set } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import ReadMore from 'react-native-read-more-text';
import { AirbnbRating } from 'react-native-ratings';
import { Calendar } from 'react-native-calendars';

import * as Icons  from '../assets';

import Modal from "../compenents/Modal";
import Container from "../compenents/Container";
import SlideShow from "../compenents/SlideShow";
import Footer from "../compenents/Footer";
import Accommodations from '../compenents/Accommodations';
import Map from "../compenents/Map";
import { getRentalPrice } from "../helpers/price-calculator";
import ActionButton from '../compenents/ActionButton';
import Placeholder from '../compenents/Placeholder';
import { userIsPhysician } from '../helpers/public-user-helper';
import {filterOutput, formatTime} from "../helpers/output-formatter";
import ListingRetreiver from "../helpers/listing-retreiver";


export default class PublicProperty extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            rentalData: null,
            availabilityData: null,
            userEmail: null,
            userIsDoc: false,
            modalVisible: false,
            physicianPrice: null,
            publicPrice: null,
            physicianPriceData: null,
            publicPriceData: null,
            modalKey: Math.random().toString(36).substring(7),
            successModalVisible: false,
            successModalKey: Math.random().toString(36).substring(7),
            successModalText: "",
            errorModalVisible: false,
            errorModalText: "",
            errorModalKey: Math.random().toString(36).substring(7),
            currentUserPost: false,
            calendarKey: Math.random().toString(36).substring(7),
            shareModalVisible: false,
            shareModalKey: Math.random().toString(36).substring(7),
            userLikesThisPost: false,
            reviewData: null,
            userIsBlocked: false,
            userBlocksMe: false,
            hostID: null,
            funThings: null,
            variablePhysicianPriceData: null,
            variablePublicPriceData: null,
        };
    } 

    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user }, () => {
                    this.checkIfCurrentUserPost();
                    this.checkIfUserLikesPost();
                    if (!user.isAnonymous) {
                        this.checkIfUserIsDoc(user);
                    } else {
                        this.setState({
                            userIsDoc: false,
                        });
                    }
                    this.loadView(this.props.route.params.rentalData);
                });
            } else {
                this.setState({ user: null, userIsDoc: false, });
            }
        });
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.loadView(this.props.route.params.rentalData)
            this.checkIfCurrentUserPost();
            this.getFunThings(this.props.route.params.rentalData)
        });
        if (Platform.OS == "web") {
            window.addEventListener('resize', this.updateView);
        }
    }

    updateView = () => {
        this.setState({ calendarKey:  Math.random().toString(36).substring(7)});
    };

    checkMyBlockList = async uid => {
        if (!!this.state.user) {
            const db = getDatabase();
            const blockRef = ref(db, `blockList/${this.state.user.uid}/${uid}`);
            let snapshot = await get(blockRef);
            let blockData = snapshot.val();
            if (!!blockData) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    checkUsersBlockList = async uid => {
        if (!!this.state.user) {
            const db = getDatabase();
            const blockRef = ref(db, `blockList/${uid}/${this.state.user.uid}`);
            let snapshot = await get(blockRef);
            let blockData = snapshot.val();
            if (!!blockData) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    checkIfUserIsDoc = async (user) => {
        let userIsDoc = await userIsPhysician(user.uid);
        this.setState({
          userIsDoc: userIsDoc,
        });
        if (userIsDoc) {
            this.getPhysicianPriceData();
        } else {
            this.getPublicPriceData();
        }
    }

    onBackButtonClick = () => {
        if (Platform.OS === "web") {
            window.history.replaceState({}, document.title, "/" + "");
        }
    }

    getHostID = async postID => {
        let url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/getHostID?postID=${postID}`;
        try {
            const response = await fetch(url);
            const hostIDResponse = await response.json();
            if (hostIDResponse.response == "success") {
               return hostIDResponse.hostID;
            } else  {
                this.setState({
                    errorMessage: "Something went wrong. Please try again.",
                    errorModalKey: Math.random().toString(36).substring(7),
                    errorModalVisible: true,
                });
            }
        } catch (error) {
            this.setState({
                errorMessage: error,
                errorModalKey: Math.random().toString(36).substring(7),
                errorModalVisible: true,
            });
        }
    }

    loadView = async (rentalData) => {
        //get public data
        let listingRetreiver = new ListingRetreiver();
        let data = await listingRetreiver.getPublicListingData(rentalData.key);
        let hostID = await this.getHostID(data.key);
        let userIsBlocked = await this.checkMyBlockList(hostID);
        let userBlocksMe = await this.checkUsersBlockList(hostID);
        this.setState({ 
            rentalData: data,
            userIsBlocked: userIsBlocked,
            userBlocksMe: userBlocksMe,
            hostID: hostID,
        });
        this.getAvailabilityData(rentalData);
        if (Platform.OS === "web") {
            window.history.replaceState(null, null, "?postID="+rentalData.key);
        }
        this.increaseViewCount(rentalData.key);
        this.getReviewData(rentalData.key);
        this.getHostProfile(hostID);
        this.setHeader();
    }

    setHeader = () => {
        if (!!this.state.rentalData) {
            if (!!this.state.rentalData.listingType) {
                if (this.state.rentalData.listingType == "ltr") {
                    this.props.navigation.setOptions({
                        title: `Long Term Rental`,
                    });
                } else if (this.state.rentalData.listingType == "forSale") {
                    this.props.navigation.setOptions({
                        title: `For Sale`,
                    });
                } else {
                    this.props.navigation.setOptions({
                        title: `Short Term Rental`,
                    });
                }
            } else {
                this.props.navigation.setOptions({
                    title: `Short Term Rental`,
                });
            }
        } else {
            this.props.navigation.setOptions({
                title: `Short Term Rental`,
            });
        }
    }

    getHostProfile = async (hostID) => {
        let hostProfile = await this.getUserProfile(hostID);
        if (hostProfile != undefined) {
            this.setState({
                hostData: hostProfile
            })
        }
    }

    increaseViewCount = (postUID) => {
        //get current view count and up it by one
        const _this = this;
        const db = getDatabase();
        const postAnalyticsRef = ref(db, `postAnalytics/${postUID}`);
        get(postAnalyticsRef).then((snapshot) => {
            const analyticsData = snapshot.val();
            if (analyticsData !== null) {
                _this.setState({
                    analyticsData: analyticsData,
                });
                var newPostCount = (analyticsData.viewCount + 1);
                const updates = {};
                updates['postAnalytics/' + postUID] = {
                    "viewCount": newPostCount,
                    "likes": analyticsData.likes || 0,
                    "contacted": analyticsData.contacted || 0,
                };
                update(ref(db), updates);
            } else {
                const newAnalyticsData = {
                    viewCount: 0,
                    likes: 0,
                    contacted: 0,
                }
                _this.setState({
                    analyticsData: newAnalyticsData,
                });
                const postAnalyticsRef = ref(db, `postAnalytics/${this.state.rentalData.key}`);
                set(postAnalyticsRef, newAnalyticsData);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    increaseLikeCount = (postUID) => {
        const _this = this;
        const db = getDatabase();
        const postAnalyticsRef = ref(db, `postAnalytics/${postUID}`);
        get(postAnalyticsRef).then((snapshot) => {
            const analyticsData = snapshot.val();
            if (analyticsData !== null) {
                let newAnalyticsData = analyticsData;
                newAnalyticsData.likes = (newAnalyticsData.likes + 1);
                _this.setState({
                    analyticsData: newAnalyticsData,
                });
                const updates = {};
                updates['postAnalytics/' + this.state.rentalData.key] = newAnalyticsData;
                update(ref(db), updates);
            } else {
                const newAnalyticsData = {
                    likes: 1,
                }
                _this.setState({
                    analyticsData: newAnalyticsData,
                });
                const postAnalyticsRef = ref(db, `postAnalytics/${postUID}`);
                set(postAnalyticsRef, newAnalyticsData);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    decreaseLikeCount = (postUID) => {
        const _this = this;
        const db = getDatabase();
        const postAnalyticsRef = ref(db, `postAnalytics/${postUID}`);
        get(postAnalyticsRef).then((snapshot) => {
            const analyticsData = snapshot.val();
            if (analyticsData !== null) {
                let newAnalyticsData = analyticsData;
                if (newAnalyticsData.likes > 0) {
                    newAnalyticsData.likes = (newAnalyticsData.likes - 1);
                }
                _this.setState({
                    analyticsData: newAnalyticsData,
                });
                const updates = {};
                updates['postAnalytics/' + postUID] = newAnalyticsData;
                update(ref(db), updates);
            } else {
                const newAnalyticsData = {
                    likes: 0,
                }
                _this.setState({
                    analyticsData: newAnalyticsData,
                });
                const postAnalyticsRef = ref(db, `postAnalytics/${postUID}`);
                set(postAnalyticsRef, newAnalyticsData);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    increaseContactCount = (postUID) => {
        //do this later
    }

    increaseShareCount = (postUID) => {
        //do this later
    }

    checkIfUserLikesPost = () => {
        const _this = this;
        const db = getDatabase();
        const myFavoritesRef = ref(db, `myFavorites/${this.state.user.uid}/${this.props.route.params.rentalData.key}`);
        get(myFavoritesRef).then((snapshot) => {
            const favoritesData = snapshot.val();
            if (favoritesData !== null) {
                _this.setState({
                    userLikesThisPost: true,
                });
            } 
        }).catch(error => {
            console.log(error);
        });
    }

    getPhysicianPriceData = () => {
        const db = getDatabase();
        const priceRef = ref(db, `physicianPrice/${this.props.route.params.rentalData.key}`);
        get(priceRef).then((snapshot) => {
            const physicianPriceData = snapshot.val();
            if (physicianPriceData !== null) {
                this.setState({physicianPrice: physicianPriceData.price});
            } else {
                this.setState({physicianPrice: null});
            }
        }).catch(error => {
            this.setState({physicianPrice: null});
        });
        if (!!this.props.route.params.rentalData.variablePhysicianPrice) {
            if (this.props.route.params.rentalData.variablePhysicianPrice) {
                const variablePriceRef = ref(db, `variablePhysicianPrice/${this.props.route.params.rentalData.key}`);
                get(variablePriceRef).then((snapshot) => {
                    const variablePhysicianPriceData = snapshot.val();
                    if (variablePhysicianPriceData !== null) {
                        this.setState({variablePhysicianPriceData: variablePhysicianPriceData});
                    } else {
                        this.setState({variablePhysicianPriceData: null});
                    }
                }).catch(error => {
                    this.setState({variablePhysicianPriceData: null});
                });
            }
        }
    }

    getPublicPriceData = () => {
        const db = getDatabase();
        const priceRef = ref(db, `publicPrice/${this.props.route.params.rentalData.key}`);
        get(priceRef).then((snapshot) => {
            const publicPriceData = snapshot.val();
            if (publicPriceData !== null) {
                this.setState({publicPrice: publicPriceData.price});
            } else {
                this.setState({publicPrice: null});
                this.getPhysicianPriceData();
            }
        }).catch(error => {
            this.setState({publicPrice: null});
        });
        if (!!this.props.route.params.rentalData.variablePublicPrice) {
            if (this.props.route.params.rentalData.variablePublicPrice) {
                const variablePriceRef = ref(db, `variablePublicPrice/${this.props.route.params.rentalData.key}`);
                get(variablePriceRef).then((snapshot) => {
                    const variablePublicPriceData = snapshot.val();
                    if (variablePublicPriceData !== null) {
                        this.setState({variablePublicPriceData: variablePublicPriceData});
                    } else {
                        this.setState({variablePublicPriceData: null});
                    }
                }).catch(error => {
                    this.setState({variablePublicPriceData: null});
                });
            }
        }
    }

    getFunThings = async (rentalData) => {
        let address = "";
        if (rentalData.city) {
            address += rentalData.city;
        }
        if (rentalData.state) {
            address += " " + rentalData.state;
        }
        if (rentalData.country) {
            address += ", " + rentalData.country;
        }
        if (address != "") {
            const functions = getFunctions();
            const askOpenAI = httpsCallable(functions, 'askOpenAI');
            let prompt = `what are some fun things to do in ${address}`;
            let result = await askOpenAI({prompt: prompt})
            const data = result.data;
            if (data.response == "success") {
                this.setState({
                    funThings: data.data.replace(/^\s+|\s+$/g, '')
                });
            } 
        }
    }

    showContactInfo = () => {
        //request contact data from server
        if (!this.state.user.isAnonymous) {
            //get user email
            const _this = this;
            this.requestUserEmail(function(email){
                if (email) {
                    _this.setState({
                        userEmail: email,
                        modalVisible: true,
                        modalKey: Math.random().toString(36).substring(7)
                    });
                }
            })
        }
    }

    navigateToBooking = async () => {
        let sendRentalData = this.state.rentalData;
        sendRentalData.publicPrice = this.state.publicPrice;
        sendRentalData.physicianPrice = this.state.physicianPrice;
        let myProfile = await this.getUserProfile(this.state.user.uid);
        if (myProfile != undefined) {
            if (this.props.navigation.getParent().getState().routes[0].params !== undefined) {
                if (!!this.props.navigation.getParent().getState().routes[0].params.filterData) {
                    let filterData = this.props.navigation.getParent().getState().routes[0].params.filterData;
                    if (filterData.checkIn != null && filterData.checkOut != null) {
                        let startDate = new Date(filterData.checkIn);
                        let endDate = new Date(filterData.checkOut);
                        this.props.navigation.navigate("Create Reservation", {
                            rentalData: this.state.rentalData,
                            startDate: startDate,
                            endDate: endDate,
                            availabilityData: this.state.availabilityData,
                            profileData: myProfile,
                            userIsDoc: this.state.userIsDoc
                        });
                    } else {
                        this.props.navigation.navigate("Create Reservation", {
                            rentalData: this.state.rentalData,
                            availabilityData: this.state.availabilityData,
                            profileData: myProfile,
                            userIsDoc: this.state.userIsDoc
                        });
                    }
                } else {
                    this.props.navigation.navigate("Create Reservation", {
                        rentalData: this.state.rentalData,
                        availabilityData: this.state.availabilityData,
                        profileData: myProfile,
                        userIsDoc: this.state.userIsDoc
                    });
                }
            } else {
                this.props.navigation.navigate("Create Reservation", {
                    rentalData: this.state.rentalData,
                    availabilityData: this.state.availabilityData,
                    profileData: myProfile,
                    userIsDoc: this.state.userIsDoc
                });
            }
        } else {
            //navigate to profile
            this.props.navigation.navigate("My Profile", {
                rentalData: this.state.rentalData,
                availabilityData: this.state.availabilityData,
                userIsDoc: this.state.userIsDoc
            });
        }
    }

    getUserProfile = async (uid) => {
        const db = getDatabase();
        const profilesRef = ref(db, `profiles/${uid}`);
        const snapshot = await get(profilesRef);
        const profileData = snapshot.val();
        if (profileData !== null) {
            return profileData;
        } else {
            return null;
        }
    }

    navigateToReservation = () => {
        this.props.navigation.navigate("Create Reservation", {
            rentalData: this.state.rentalData,
            autoBook: false,
        });
    }

    requestUserEmail = (callback) => {
        let url = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/requestUserEmail?";
        url += "postUID="+this.state.rentalData.key;
        url += "&requesterUID="+this.state.user.uid;
        fetch(url)
        .then(response => response.json())
        .then(data => {
            if (!!data.response) {
                if (data.response == "success") {
                    callback(data.email);
                } else {
                    callback(false);
                }
            } else {
                callback(false);
            }
        });
    }

    checkForContactButton = () => {
        let parent = [];
        if (!this.state.userIsBlocked && !this.state.userBlocksMe) {
            if (!!this.state.user) {
                if (!this.state.user.isAnonymous) {
                    if (this.state.currentUserPost) {
                        parent.push(
                            <ActionButton
                                text={"Edit Post"}
                                handler={()=>{
                                    this.editPost();
                                }}
                            />
                        );
                    } else {
                        if (!!this.state.rentalData) {
                            if (this.state.rentalData.subscriptionLevel != "silver") {
                                parent.push(
                                    <ActionButton
                                        text={"Contact"}
                                        handler={()=>{
                                            this.showContactInfo();
                                        }}
                                    />
                                );
                            }
                        }
                    }
                } else {

                    if (!!this.state.rentalData) {
                        if (this.state.rentalData.subscriptionLevel != "silver") {
                            parent.push(
                                <ActionButton
                                    text={"Log in to Contact"}
                                    handler={()=>{
                                        this.navigateToLogIn();
                                    }}
                                />
                            );
                        }
                    }
                    if (!!this.state.rentalData) {
                        if (this.state.rentalData.availableToPublic ) {
                            if (this.state.rentalData.autoBook) {
                                parent.push(
                                    <ActionButton
                                        text={"Log in to Reserve"}
                                        handler={()=>{
                                            this.navigateToLogIn();
                                        }}
                                    />
                                );
                            } else {
                                parent.push(
                                    <ActionButton
                                        text={"Log in to Request"}
                                        handler={()=>{
                                            this.navigateToLogIn();
                                        }}
                                    />
                                );
                            }
                        } 
                    }
                }
            }
            parent.push(
                <ActionButton
                    text={"Share"}
                    handler={()=>{
                        this.openShareModal();
                    }}
                />,
                <ActionButton
                    text={"Report this post"}
                    handler={()=>{
                        this.onReportPostPress();
                    }}
                    filled={true}
                />
            )
            let final = [
                <Container title={"More"}>
                    {parent}
                </Container>
            ];
            return final;
        }
        return parent;
    }

    checkForBookingButton = () => {
        let parent = [];
        if (this.state.rentalData.availableToPublic ) {
            if (this.state.rentalData.autoBook) {
                if (this.state.user.isAnonymous) {
                    parent.push(
                        <ActionButton
                            text={"Log in to book"}
                            handler={()=>{
                                this.navigateToLogIn();
                            }}
                        />
                    )
                } else {
                    parent.push(
                        <ActionButton
                            text={"Book Now"}
                            handler={()=>{
                                this.navigateToBooking();
                            }}
                        />
                    )
                }

            } else {
                if (this.state.user.isAnonymous) {
                    parent.push(
                        <ActionButton
                            text={"Log in to request"}
                            handler={()=>{
                                this.navigateToLogIn();
                            }}
                        />
                    )
                } else {
                    parent.push(
                        <ActionButton
                            text={"Request Booking"}
                            handler={()=>{
                                this.navigateToBooking();
                            }}
                        />
                    )
                }
            }
        }
        return parent;
    }

    navigateToLogIn = () => {
        this.props.navigation.navigate("Log In")
    }

    checkIfCurrentUserPost = async () => {
        const db = getDatabase();
        if (!!this.state.user) {
            const rentalsRef = ref(db, `myRentals/${this.state.user.uid}/${this.props.route.params.rentalData.key}`);
            const snapshot = await get(rentalsRef);
            const rentalData = snapshot.val();
            if (rentalData !== null) {
                this.setState({ currentUserPost: true});
                return true;
            } else {
                this.setState({ currentUserPost: false});
                return false;
            }
        } else {
            return false;
        }
    }

    editPost = () => {
        this.props.navigation.navigate('Post Property', {
            publicRentalData: this.state.rentalData,
            callBack: this.handleCallback
        });
    }

    updateAvailability = () => {
        this.props.navigation.navigate('Calendar View', {
            rentalData: this.state.rentalData,
            availabilityData: this.state.availabilityData,
            callback: this.handleAvailabilityCallback,
            dataCallback: this.handleDataCallback,
        });
    }

    handleCallback = (newRentalData) => {
        this.loadView(newRentalData);
    }

    handleAvailabilityCallback = (newAvailabilityData) => {
        this.setState({ 
            availabilityData: newAvailabilityData,
            calendarKey: Math.random().toString(36).substring(7),
        });
    }

    handleDataCallback = (rentalData) => {
        this.setState({ 
            rentalData: rentalData,
        });
    }

    getAvailabilityData = (data) => {
        if (data != null) {
            const _this = this;
            const db = getDatabase();
            const availabilityRef = ref(db, `availability/${data.key}`);
            get(availabilityRef).then((snapshot) => {
                const availabilityData = snapshot.val();
                if (availabilityData !== null) {
                    _this.setState({ 
                        availabilityData: availabilityData,
                        calendarKey: Math.random().toString(36).substring(7),
                    });
                } else {
                    _this.setState({ availabilityData: null});
                }
            }).catch(error => {
                console.log(error);
            });
        }
    }

    onModalClose = () => {
        this.setState({modalVisible : false});
    }

    onCopyPress = () => {
        this.setState({
            modalVisible: false,
            successModalVisible : true,
            successModalKey: Math.random().toString(36).substring(7),
            successModalText: "Copied to your clipboard."
        });
    }

    onReportPostPress = () => {
        this.props.navigation.navigate("Report", {
            postID: this.state.rentalData.key,
        });
    }

    onSendEmailError = () => {
        this.setState({
            modalVisible: false,
            errorModalVisible : true,
            errorModalText: "There was an issue when trying to open a mail app. This happens if you do not have a mail app installed or if the email is malformed.",
            errorModalKey: Math.random().toString(36).substring(7),
        });
    }


    buildHostProfile = () => {
        let final = [];
        let parent = [];
        let data = [];
        if (!this.state.userBlocksMe && !this.state.userIsBlocked) {
            if (!!this.state.hostData) {
                if (!!this.state.hostData.photoUrl) {
                    data.push(
                        <TouchableHighlight
                            onPress={()=>{
                                this.props.navigation.navigate("Public Profile", {
                                    profileUID: this.state.hostID,
                                });
                            }}
                        >
                            <View style={{
                                display: "flex",
                                flexDirection: "row", 
                                width: 280, 
                                justifyContent: "center",
                                paddingRight: 5,
                            }}>
                                <Image 
                                    style={styles.userImage}
                                    source={{
                                        uri: this.state.hostData.photoUrl,
                                    }}
                                />
                                <Text style={{fontWeight: "600", paddingTop: 10}}>
                                    {"Dr. "}{this.state.hostData.firstName} {this.state.hostData.lastName}
                                </Text>
                            </View>
                        </TouchableHighlight>
                    );
                } else {
                    data.push(
                        <TouchableHighlight
                            onPress={()=>{
                                this.props.navigation.navigate("Public Profile", {
                                    profileUID: this.state.hostID,
                                });
                            }}
                        >
                            <View style={{
                                display: "flex",
                                flexDirection: "row", 
                                width: 280, 
                                justifyContent: "center",
                                paddingRight: 5,
                            }}>
                                <Image 
                                    style={styles.userImage}
                                    source={require('../assets/profileIcon.png')}
                                />
                                <Text style={{fontWeight: "600", paddingTop: 10}}>
                                    Dr. {this.state.hostData.firstName} {this.state.hostData.lastName}
                                </Text>
                            </View>
                        </TouchableHighlight>
                    );
                }
                parent.push(
                    <Container maxWidth={300} title="Hosted by">
                        {data}
                    </Container>
                );
            } 
            parent.push(this.buildAccomodationType(this.state.rentalData.typeOfaccommodation));

            if (!!!this.state.rentalData.subscriptionLevel) {
                if (this.state.user.isAnonymous) {
                    parent.push(
                        <Container maxWidth={300} title="Contact Host Directly">
                            <ActionButton
                                text={"Log in to contact"}
                                handler={()=>{
                                    this.navigateToLogIn();
                                }}
                            />
                        </Container>
                    );
                } else {
                    parent.push(
                        <Container maxWidth={300} title="Contact Host Directly">
                            <ActionButton
                                text={"Email"}
                                handler={()=>{
                                    this.showContactInfo();
                                }}
                            />
                            <ActionButton
                                text={"Message"}
                                handler={()=>{
                                    this.props.navigation.navigate("Message", {
                                        recipientUID: this.state.hostID,
                                        user: this.state.user,
                                    })
                                }}
                            />
                        </Container>
                    );
                }
            } else if (this.state.rentalData.subscriptionLevel == "gold") {
                if (this.state.user.isAnonymous) {
                    parent.push(
                        <Container maxWidth={300} title="Contact Host Directly">
                            <ActionButton
                                text={"Log in to contact"}
                                handler={()=>{
                                    this.navigateToLogIn();
                                }}
                            />
                        </Container>
                    );
                } else {
                    parent.push(
                        <Container maxWidth={300} title="Contact Host Directly">
                            <ActionButton
                                text={"Email"}
                                handler={()=>{
                                    this.showContactInfo();
                                }}
                            />
                            <ActionButton
                                text={"Message"}
                                handler={()=>{
                                    this.props.navigation.navigate("Message", {
                                        recipientUID: this.state.hostID,
                                        user: this.state.user,
                                    })
                                }}
                            />
                        </Container>
                    );
                }
            } else if (this.state.rentalData.subscriptionLevel == "bronze") {
                if (this.state.user.isAnonymous) {
                    parent.push(
                        <Container maxWidth={300} title="Contact Host Directly">
                            <ActionButton
                                text={"Log in to contact"}
                                handler={()=>{
                                    this.navigateToLogIn();
                                }}
                            />
                        </Container>
                    );
                } else {
                    parent.push(
                        <Container maxWidth={300} title="Contact Host Directly">
                            <ActionButton
                                text={"Email"}
                                handler={()=>{
                                    this.showContactInfo();
                                }}
                            />
                            <ActionButton
                                text={"Message"}
                                handler={()=>{
                                    this.props.navigation.navigate("Message", {
                                        recipientUID: this.state.hostID,
                                        user: this.state.user,
                                    })
                                }}
                            />
                        </Container>
                    );
                }
            }

            final = [
                <View style={styles.flexBoxCenter}>
                    {parent}
                </View>
            ];
        }
        return final;
    }

    buildAccomodationType = (type) => {
        let parent = [];
        let image = [];
        switch (type) {
            case "Entire Home":
                image.push(
                    <Image
                        source={Icons[`house`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "Home":
                image.push(
                    <Image
                        source={Icons[`house`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "Bedroom":
                image.push(
                    <Image
                        source={Icons[`bedroom`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "Tiny House":
                image.push(
                    <Image
                        source={Icons[`tinyHouse`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "Yurt":
                image.push(
                    <Image
                        source={Icons[`yurt`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "Cabin":
                image.push(
                    <Image
                        source={Icons[`cabin`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "Tree House":
                image.push(
                    <Image
                        source={Icons[`treeHouse`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "Camp Ground":
                image.push(
                    <Image
                        source={Icons[`campground`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "RV":
                image.push(
                    <Image
                        source={Icons[`rv`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "Hotel":
                image.push(
                    <Image
                        source={Icons[`hotel`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "Hostel":
                image.push(
                    <Image
                        source={Icons[`hostel`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "Bed and Breakfast":
                image.push(
                    <Image
                        source={Icons[`bedAndBreakfast`]}
                        style={[styles.icon]}
                    />
                );
                break;
            case "Other":
                image.push(
                    <Image
                        source={Icons[`other`]}
                        style={[styles.icon]}
                    />
                );
                break;
            default:
                image.push(
                    <Image
                        source={Icons[`house`]}
                        style={[styles.icon]}
                    />
                );
                break;
        }
        let accommodationText = this.state.rentalData.typeOfaccommodation;
        if (this.state.rentalData.listingType == undefined) {
            accommodationText += " for rent short term";
        } else if (this.state.rentalData.listingType == "str") {
            accommodationText += " for rent short term";
        } else if (this.state.rentalData.listingType == "ltr") {
            accommodationText += " for rent long term";
        } else if (this.state.rentalData.listingType == "forSale") {
            accommodationText += " for sale";
        }

        parent.push(
            <Container maxWidth={230} title="Accommodation type">
                <View style={styles.flexBoxCenter}>
                    <Text allowFontScaling={false} style={styles.boldText}>
                        {accommodationText}
                    </Text>
                  {image}
                </View>
            </Container>
        );
        return parent;
    }

    buildCostBreakDown = () => {
        let parent = [];
        if (!!this.state.variablePhysicianPriceData) {
            let max = this.state.physicianPrice;
            let min = this.state.physicianPrice;
            Object.keys(this.state.variablePhysicianPriceData).forEach(key => {
                let price = this.state.variablePhysicianPriceData[key];
                if (price > max) {
                    max = price;
                }
                if (price < min) {
                    min = price;
                }
            })
            parent.push(
                <Text allowFontScaling={false} style={[styles.boldText, {fontSize: 18}]}>
                    ${min} - ${max}
                    <Text allowFontScaling={false} style={styles.smallText}>
                        {" per night"}
                    </Text>
                </Text>
            );
        } else if (!!this.state.variablePublicPriceData) {
            let max = this.state.publicPrice;
            let min = this.state.publicPrice;
            Object.keys(this.state.variablePublicPriceData).forEach(key => {
                let price = this.state.variablePublicPriceData[key];
                if (price > max) {
                    max = price;
                }
                if (price < min) {
                    min = price;
                }
            })
            parent.push(
                <Text allowFontScaling={false} style={[styles.boldText, {fontSize: 18}]}>
                    ${min} - ${max}
                    <Text allowFontScaling={false} style={styles.smallText}>
                        {" per night"}
                    </Text>
                </Text>
            );
        } else if (this.state.physicianPrice != null) {
            parent.push(
                <Text allowFontScaling={false} style={[styles.boldText, {fontSize: 18}]}>
                    ${this.state.physicianPrice}
                    <Text allowFontScaling={false} style={styles.smallText}>
                        {" per night"}
                    </Text>
                </Text>
            );
        } else if (this.state.publicPrice != null) {
            parent.push(
                <Text allowFontScaling={false} style={[styles.boldText, {fontSize: 18}]}>
                    ${this.state.publicPrice}
                    <Text allowFontScaling={false} style={styles.smallText}>
                        {" per night"}
                    </Text>
                </Text>
            );
        } 
        if (this.state.rentalData.cleaningFee) {
            parent.push(
                <Text allowFontScaling={false} style={[styles.boldText, {fontSize: 18}]}>
                    ${this.state.rentalData.cleaningFee}
                    <Text allowFontScaling={false} style={styles.smallText}>
                        {" cleaning fee"}
                    </Text>
                </Text>
            );
        }
        if (this.state.rentalData.petFee) {
            parent.push(
                <Text allowFontScaling={false} style={[styles.boldText, {fontSize: 18}]}>
                     ${this.state.rentalData.petFee}
                    <Text allowFontScaling={false} style={styles.smallText}>
                        {" pet fee"}
                    </Text>
                </Text>
            );
        }
        if (this.state.rentalData.occupancyTax) {
            parent.push(
                <Text allowFontScaling={false} style={[styles.boldText, {fontSize: 18}]}>
                    {this.state.rentalData.occupancyTax}%
                    <Text allowFontScaling={false} style={styles.smallText}>
                        {" occupancy tax"}
                    </Text>
                </Text>
            );
        }
        if (this.state.rentalData.salesTax) {
            parent.push(
                <Text allowFontScaling={false} style={[styles.boldText, {fontSize: 18}]}>
                    {this.state.rentalData.salesTax}%
                    <Text allowFontScaling={false} style={styles.smallText}>
                        {" sales tax"}
                    </Text>
                </Text>
            );
        }
       
        if (parent.length > 0) {
            return [
                <Container title={"Cost Breakdown"} maxWidth={300}>
                    {parent}
                </Container>
            ];
        } 
        return [];
    }

    buildSlideShow = () => {
        let parent = [];
        if (!this.state.userIsBlocked && !this.state.userBlocksMe) {
            parent.push(this.buildLikeIcon());
            if (this.state.rentalData != null) {
                parent.push(
                    <Text allowFontScaling={false} style={[styles.titleText]}>
                        {this.buildTitle()}
                    </Text>,
                );
                if (this.state.analyticsData) {
                    parent.push(
                        <Text allowFontScaling={false} style={styles.analyticsText}>
                            views: {this.state.analyticsData.viewCount} / favorited: {this.state.analyticsData.likes}
                        </Text>,
                    );
                }
            
                parent.push(
                    <SlideShow
                        imageUrls={this.state.rentalData.photoUrls}
                    />,
                )
            }
            let final = [
                <Container>
                    {parent}
                </Container>
            ];
            return final
        }
        return parent;
    }

    buildTitle = () => {
        return !!this.state.rentalData.title? filterOutput(this.state.rentalData.title): "";
    }

    buildDescription = () => {
        return !!this.state.rentalData.description? filterOutput(this.state.rentalData.description): "";
    }

    buildPriceDescription = () => {
        return !!this.state.rentalData.price? filterOutput(this.state.rentalData.price): "";
    }

    buildView = () => {
        let parent = [];
        let calendarWidth = Dimensions.get("window").width - 30;
        const todayDate = new Date();
        let month = todayDate.getUTCMonth()+1;
        let day = todayDate.getUTCDate();
        month >= 10 ? month = todayDate.getMonth()+1: month = `0${todayDate.getMonth()+1}`;
        day >= 10 ? day = todayDate.getUTCDate(): day = `0${todayDate.getUTCDate()}`;
        const todayDateString = todayDate.getUTCFullYear()+"-"+month +"-"+day
        if (this.state.userIsBlocked) {
            parent.push(
            <Container>
                <Text allowFontScaling={false} style={[styles.headerLabeltext]}>You have blocked the host of this listing</Text>
                <Text allowFontScaling={false} style={{marginBottom: 10}}>Would you like to unblock them?</Text>
                <ActionButton
                    text="Unblock Host"
                    handler={async ()=>{
                        const db = getDatabase();
                        const blockedUserId = this.state.hostID;
                        const blockListRef = ref(db, `blockList/${this.state.user.uid}/${blockedUserId}`);
                        let snapshot = await set(blockListRef, null);
                        this.setState({
                            userIsBlocked: false,
                            userBlocksMe: false,
                        });
                    }}
                />
            </Container>
            );
        } else if (this.state.userBlocksMe) {
            parent.push(<Text allowFontScaling={false} style={[styles.headerLabeltext]}>This listing is not available</Text>);
        } else {
            if (this.state.rentalData != null) {
                parent.push(
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.errorModalVisible}
                        key={this.state.errorModalKey}
                        onModalClose={this.onModalClose}
                        isError={true}
                        labelText={this.state.errorModalText}
                    />,
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.successModalVisible}
                        key={this.state.successModalKey}
                        onModalClose={this.onModalClose}
                        isSuccess={true}
                        labelText={this.state.successModalText}
                    />,
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.modalVisible}
                        key={this.state.modalKey}
                        onModalClose={this.onModalClose}
                        onCopyPress={this.onCopyPress}
                        email={this.state.userEmail}
                        onError={this.onSendEmailError}
                        labelText=""
                    />,
                    <Container title={"Description"}>
                        <View style={{paddingHorizontal: 20, paddingBottom: 20}}>
                            <ReadMore
                                numberOfLines={4}>
                                <Text style={styles.descriptionText}>
                                    {this.buildDescription()}
                                </Text>
                            </ReadMore>
                        </View>
                    </Container>,
                    <Container title={"Amenities"}>
                        <Accommodations rentalData={this.state.rentalData}/>
                    </Container>
                );
                let locationLabel = "";
                if (this.state.rentalData.city) {
                    locationLabel += this.state.rentalData.city;
                }
                if (this.state.rentalData.state) {
                    locationLabel += " " + this.state.rentalData.state;
                }
                if (this.state.rentalData.country) {
                    locationLabel += ", " + this.state.rentalData.country;
                }
                parent.push(
                    <Container title={"Approximate Location"}>
                        <Text allowFontScaling={false} style={[styles.labelText, {marginBottom: 10}]}>{locationLabel}</Text>
                        <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center"}}>
                            <Map 
                                isHome={false}
                                rentalsData={this.state.rentalData}
                            />
                            <View style={{marginBottom: 5, maxWidth: 300}}>
                                {
                                    !!this.state.funThings ?
                                    <View style={{padding: 10}}>
                                        <Text style={{fontSize: 16}}>{`Fun things to do in ${locationLabel}: `}</Text>
                                        <ReadMore numberOfLines={10}>
                                            <Text 
                                                allowFontScaling={false}
                                                style={[styles.locationDescription, {maxWidth: 100, marginBottom: 10}]}
                                            >
                                                {this.state.funThings}
                                                {
                                                    Platform.OS == "web" ?
                                                    <Text style={{fontSize: 10}}>
                                                        {"  Powered by"}
                                                        <Image 
                                                            style={styles.openAILogo}
                                                            source={require('../assets/OpenAI_Logo.png')}
                                                            resizeMode={"contain"}
                                                        />
                                                    </Text>: null
                                                }
                                            </Text>
                                        </ReadMore>
                                    </View>: null
                                }
                            </View>
                        </View>
                    </Container>,
                );
                if (!!this.state.rentalData.subscriptionLevel) {
                    if (this.state.rentalData.subscriptionLevel == "bronze") {
                        parent.push(
                            <Container title={"Price Description"}>
                                <Text 
                                    allowFontScaling={false} 
                                    style={[styles.labelText, {marginBottom: 10}]}
                                >
                                    {this.buildPriceDescription()}
                                </Text>
                            </Container>
                        );
                    } else if (this.state.rentalData.subscriptionLevel == "gold" && this.state.rentalData.listingType == "forSale") {
                        parent.push(
                            <Container title={"Price Description"}>
                                <Text 
                                    allowFontScaling={false} 
                                    style={[styles.labelText, {marginBottom: 10}]}
                                >
                                    {this.buildPriceDescription()}
                                </Text>
                            </Container>
                        );
                    }
                } else {
                    parent.push(
                        <Container title={"Price Description"}>
                            <Text 
                                allowFontScaling={false} 
                                style={[styles.labelText, {marginBottom: 10}]}
                            >
                                 {this.buildPriceDescription()}
                            </Text>
                        </Container>
                    );
                }
                if (!!this.state.rentalData.availability) {
                    if (this.state.rentalData.availability == "calendar") {
                        parent.push(
                            <Container title={"Availability"}>
                                <Calendar
                                    horizontal={true}
                                    style={[styles.calendar, {width: calendarWidth, maxWidth: 800, }]}
                                    markedDates={this.state.availabilityData}
                                    key={this.state.calendarKey}
                            
                                    minDate={todayDateString}
                                    enableSwipeMonths={true}
                                    markingType={"period"}
                                    disableAllTouchEventsForDisabledDays={true}
                                    renderArrow={(direction) => direction === 'left' ? 
                                        <Image
                                            source={require('../assets/backArrowIcon.png')}
                                            style={styles.leftIcon}
                                        ></Image> : 
                                        <Image
                                            source={require('../assets/forwardArrowIcon.png')}
                                            style={styles.rightIcon}
                                        ></Image>
                                    }
                                    onMonthChange={(month) => {
                                        this.setState({selectedMonth: month.dateString});
                                    }}
                                    current={this.state.selectedMonth}
                                    dayComponent={({date, state}) => {
                                        let price = ""
                                        if (!!this.state.rentalData) {
                                            if (this.state.rentalData.listingType == undefined || this.state.rentalData.listingType == "str") {
                                                if (this.state.userIsDoc) {
                                                    price = this.state.physicianPrice;
                                                } else {
                                                    if (this.state.publicPrice == null) {
                                                        price = this.state.physicianPrice;
                                                    } else {
                                                        price = this.state.publicPrice;
                                                    }
                                                }
                                            }
                                        }
                                        let dateString = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`
                                        if (!!this.state.rentalData) {
                                            if (this.state.rentalData.listingType == undefined || this.state.rentalData.listingType == "str") {
                                                if (this.state.userIsDoc) {
                                                    if (!!this.state.variablePhysicianPriceData) {
                                                        if (!!this.state.variablePhysicianPriceData[dateString]) {
                                                            price = this.state.variablePhysicianPriceData[dateString];
                                                        }
                                                    }
                                                } else {
                                                    if (!!this.state.variablePublicPriceData) {
                                                        if (!!this.state.variablePublicPriceData[dateString]) {
                                                            price = this.state.variablePublicPriceData[dateString];
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        if (price == null) {
                                            price = ""
                                        }
                                        let color = "black";
                                        if (state == "disabled") {
                                            color = "grey";
                                        } else if (!!this.state.availabilityData) {
                                            if (!!this.state.availabilityData[dateString]) {
                                                if (this.state.availabilityData[dateString].disabled) {
                                                    color = "grey";
                                                }
                                            }
                                        }
                                        return (
                                            <View>
                                                <Text style={{textAlign: 'center', color: color}}>{date.day}</Text>
                                                <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text>
                                            </View>
                                        );
                                    }}
                                />
                            </Container>
                        );
                    } else if (this.state.rentalData.availability !== "hide") {
                        parent.push(
                            <Container title={"Availability"}>
                                <View style={styles.linkWrapper}>
                                    <Image
                                        source={require('../assets/link.png')}
                                        style={styles.linkIcon}
                                    />
                                    <Text
                                        allowFontScaling={false} 
                                        style={styles.linkText}
                                        onPress={this.handleAvailabilityPress}
                                    >{this.state.rentalData.availability}</Text>
                                </View>
                            </Container>
                        );
                    } 
                } else {
                    parent.push(
                        <Container title={"Availability"}>
                            <Calendar
                                horizontal={true}
                                style={[styles.calendar, {width: calendarWidth, zIndex: 30, maxWidth: 800,}]}
                                markedDates={this.state.availabilityData}
                                key={this.state.calendarKey}
                                onDayPress={(day) => this.onDayPress(day)}
                                minDate={todayDateString}
                                enableSwipeMonths={true}
                                markingType={"period"}
                                disableAllTouchEventsForDisabledDays={false}
                                renderArrow={(direction) => direction === 'left' ? 
                                    <Image
                                        source={require('../assets/backArrowIcon.png')}
                                        style={styles.leftIcon}
                                    ></Image> : 
                                    <Image
                                        source={require('../assets/forwardArrowIcon.png')}
                                        style={styles.rightIcon}
                                    ></Image>
                                }
                                onMonthChange={(month) => {
                                    this.setState({selectedMonth: month.dateString});
                                }}
                                current={this.state.selectedMonth}
                                dayComponent={({date, state}) => {
                                    let price = this.state.publicPrice;
                                    if (this.state.userIsDoc) {
                                        price = this.state.physicianPrice;
                                    }
                                    let dateString = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`
                                    if (this.state.userIsDoc) {
                                        if (!!this.state.variablePhysicianPriceData) {
                                            if (!!this.state.variablePhysicianPriceData[dateString]) {
                                                price = this.state.variablePhysicianPriceData[dateString];
                                            }
                                        }
                                    } else {
                                        if (!!this.state.variablePublicPriceData) {
                                            if (!!this.state.variablePublicPriceData[dateString]) {
                                                price = this.state.variablePublicPriceData[dateString];
                                            }
                                        }
                                    }
                                    if (price == null) {
                                        price = ""
                                    }
                                    let color = "black";
                                    if (state == "disabled") {
                                        color = "grey";
                                    } else if (!!this.state.availabilityData) {
                                        if (!!this.state.availabilityData[dateString]) {
                                            if (this.state.availabilityData[dateString].disabled) {
                                                color = "grey";
                                            }
                                        }
                                    }
                                    return (
                                        <View>
                                            <Text style={{textAlign: 'center', color: color}}>{date.day}</Text>
                                            <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text>
                                        </View>
                                    );
                                }}
                            />
                        </Container>
                    );
        
                }
                if (this.state.rentalData.website != null && this.state.rentalData.website != ' ') {
                    if (this.state.rentalData.subscriptionLevel != "silver") {
                        parent.push(
                            <Container title={"Website"}>
                                <View style={styles.linkWrapper}>
                                    <Image
                                        source={require('../assets/link.png')}
                                        style={styles.linkIcon}
                                    />
                                    <Text
                                        allowFontScaling={false} 
                                        style={styles.linkText}
                                        onPress={this.handlePress}
                                    >{this.state.rentalData.website}</Text>
                                </View>
                            </Container>
                        );
                    }
                }
            }
        }
        return parent;
    }

    buildReviews = () => {
        let parent = [];
        if (!this.state.userIsBlocked && !this.state.userBlocksMe) {
            if (!!this.state.reviewData) {
                let accomodationsLabel = ["Accommodations", "Accommodations", "Accommodations", "Accommodations", "Accommodations"];
                let accuracyLabel = ["Accuracy", "Accuracy", "Accuracy", "Accuracy", "Accuracy"];
                let cleanlinessLabel = ["Cleanliness", "Cleanliness", "Cleanliness", "Cleanliness", "Cleanliness"];
                let overallLabel = ["Overall", "Overall", "Overall", "Overall", "Overall"];
                let accomodationsRating = Math.round((this.state.reviewData.accommodations / this.state.reviewData.totalReviewCount));
                let accuracyRating = Math.round((this.state.reviewData.accuracy / this.state.reviewData.totalReviewCount));
                let cleanlinessRating = Math.round((this.state.reviewData.cleanliness / this.state.reviewData.totalReviewCount));
                let overallRating = Math.round((this.state.reviewData.overall / this.state.reviewData.totalReviewCount));
                parent.push(
                    <Text style={styles.reviewText}>{this.state.reviewData.totalReviewCount} total review(s)</Text>,
                    <View style={[styles.flexBoxCenter, {marginBottom: 10}]}>
                        <View  style={{marginHorizontal: 10}}>
                            <AirbnbRating
                                defaultRating={accomodationsRating}
                                isDisabled={true}
                                reviewSize={14}
                                reviews={accomodationsLabel}
                                reviewColor={"black"}
                                size={20}
                                selectedColor='#5d51ef'
                            />
                        </View>
                        <View  style={{marginHorizontal: 10}}>
                            <AirbnbRating
                                defaultRating={accuracyRating}
                                isDisabled={true}
                                reviewSize={14}
                                reviews={accuracyLabel}
                                reviewColor={"black"}
                                size={20}
                                selectedColor='#5d51ef'
                            />
                        </View>
                        <View  style={{marginHorizontal: 10}}>
                            <AirbnbRating
                                defaultRating={cleanlinessRating}
                                isDisabled={true}
                                reviewSize={14}
                                reviews={cleanlinessLabel}
                                reviewColor={"black"}
                                size={20}
                                selectedColor='#5d51ef'
                            />
                        </View>
                        <View  style={{marginHorizontal: 10}}>
                            <AirbnbRating
                                defaultRating={overallRating}
                                isDisabled={true}
                                reviewSize={14}
                                reviews={overallLabel}
                                reviewColor={"black"}
                                size={20}
                                selectedColor='#5d51ef'
                            />
                        </View>
                    </View>,
                     <ActionButton
                        text={"Read reviews"}
                        handler={()=>{
                            this.navigateToReadReviews();
                        }}
                    />
                );
            } else {
                parent.push(
                   <Text style={{fontSize: 14, paddingBottom: 10}}>No reviews yet</Text>
                );
            }
            if (!!this.state.user) {
                if (!this.state.user.isAnonymous) {
                    parent.push(
                        <ActionButton
                            text={"Write a review"}
                            handler={()=>{
                                this.navigateToWriteReviews();
                            }}
                        />
                    );
                }
            }
            let final = [
                <Container title={"Reviews"}>
                    {parent}
                </Container>
            ];
            return final;
        }
        return parent;
    }

    getReviewData = (postUID) => {
        const db = getDatabase();
        const reviewDataRef = ref(db, `reviewData/${postUID}`);
        get(reviewDataRef).then((snapshot) => {
            if (snapshot.val() != undefined && snapshot.val() != null) {
                const reviewsData = snapshot.val();
                this.setState({
                    reviewData: reviewsData
                });
            }
        });
    }

    buildTags = () => {
        let parent = [];
        if (!this.state.userIsBlocked && !this.state.userBlocksMe) {
            if (this.state.rentalData != null) {
                if (this.state.rentalData.searchTerms != null) {
                    parent.push(
                        <Container title={"Tags"}>
                            <View style={{width: "100%", maxWidth: 1000}}>
                                <View style={styles.searchTagsWrapper}>
                                    { this.buildSearchTerms() } 
                                </View>
                            </View>
                        </Container>
                    )
                }
            }
        }
        return parent;
    }

    buildCancellation = () => {
        let parent = [];
        if (!this.state.userIsBlocked && !this.state.userBlocksMe) {
            if (this.state.rentalData != null) {
                if (!!this.state.rentalData.subscriptionLevel) {
                    if (this.state.rentalData.subscriptionLevel == "bronze") {
                        if (!!this.state.rentalData.listingType && this.state.rentalData.listingType == "str") {
                            parent.push(
                                <Container title={"Cancelation Policy"}>
                                    <ReadMore numberOfLines={3}>
                                        <Text 
                                            allowFontScaling={false} 
                                            style={[styles.labelText, {marginBottom: 10,}]}
                                        >
                                            Each host will manage their own booking and cancellations. Be sure to check with the host for their cancellation policy.
                                        </Text>
                                    </ReadMore>
                                </Container>
                            );
                        } else if (this.state.rentalData.listingType == undefined && this.state.rentalData.listingType == "str") {
                            parent.push(
                                <Container title={"Cancelation Policy"}>
                                    <ReadMore numberOfLines={3}>
                                        <Text 
                                            allowFontScaling={false} 
                                            style={[styles.labelText, {marginBottom: 10, }]}
                                        >
                                            Each host will manage their own booking and cancellations. Be sure to check with the host for their cancellation policy.
                                        </Text>
                                    </ReadMore>
                                </Container>
                            );
                        }
                    } else if (this.state.rentalData.subscriptionLevel == "silver" && this.state.rentalData.listingType == "str") {
                        parent.push(
                            <Container title={"Cancelation Policy"}>
                                <ReadMore numberOfLines={3}>
                                    <Text 
                                        allowFontScaling={false} 
                                        style={[styles.labelText, {marginBottom: 10, }]}
                                    >
                                        Both host and guest can cancel up to 24 hours prior to check-in for a full refund minus the Stripe fee (3% of transaction). Cancelations with less the 24 hours till check in must be approved by both parties as well as an admin and may result in fees.
                                    </Text>
                                </ReadMore>
                            </Container>
                        );
                    } else if (this.state.rentalData.subscriptionLevel == "gold" && this.state.rentalData.listingType == "str") {
                        parent.push(
                            <Container title={"Cancelation Policy"}>
                                <ReadMore numberOfLines={3}>
                                    <Text 
                                        allowFontScaling={false} 
                                        style={[styles.labelText, {marginBottom: 10,}]}
                                    >
                                        <Text
                                            allowFontScaling={false} 
                                            style={[styles.labelText, {marginBottom: 10, fontWeight: "600"}]}
                                        >Booked within the Doc to Doc app: </Text>
                                        Both host and guest can cancel up to 24 hours prior to check-in for a full refund minus the Stripe fee (3% of transaction). Cancelations with less the 24 hours till check in must be approved by both parties as well as an admin and may result in fees.
                                    </Text>
                                    {/* <View style={{width: 400, height: 10}}/> */}
                                    <Text 
                                        allowFontScaling={false} 
                                        style={[styles.labelText, {marginBottom: 10, }]}
                                    >
                                        <Text
                                            allowFontScaling={false} 
                                            style={[styles.labelText, {marginBottom: 10, fontWeight: "600"}]}
                                        >
                                            {"\n"}{"\n"}Booked by contacting host directly: </Text>
                                            Each host will manage their own booking and cancellations. Be sure to check with the host for their cancellation policy.
                                    </Text>
                                </ReadMore>
                            </Container>
                        );
                    } 
                } else if (!!this.state.rentalData.listingType && this.state.rentalData.listingType == "str") {
                    parent.push(
                        <Container title={"Cancelation Policy"}>
                            <ReadMore numberOfLines={3}>
                                <Text 
                                    allowFontScaling={false} 
                                    style={[styles.labelText, {marginBottom: 10, }]}
                                >
                                    Each host will manage their own booking and cancellations. Be sure to check with the host for their cancellation policy.
                                </Text>
                            </ReadMore>
                        </Container>
                    );
                } else if (this.state.rentalData.listingType === undefined && this.state.rentalData.listingType == "str") {
                     parent.push(
                        <Container title={"Cancelation Policy"}>
                            <ReadMore numberOfLines={3}>
                                <Text 
                                    allowFontScaling={false} 
                                    style={[styles.labelText, {marginBottom: 10,}]}
                                >
                                    Each host will manage their own booking and cancellations. Be sure to check with the host for their cancellation policy.
                                </Text>
                            </ReadMore>
                        </Container>
                    );
                }
            } else if (this.state.rentalData.listingType == undefined && this.state.rentalData.listingType == "str") {
                parent.push(
                    <Container title={"Cancelation Policy"}>
                        <ReadMore numberOfLines={3}>
                            <Text 
                                allowFontScaling={false} 
                                style={[styles.labelText, {marginBottom: 10}]}
                            >
                                Each host will manage their own booking and cancellations. Be sure to check with the host for their cancellation policy.
                            </Text>
                        </ReadMore>
                    </Container>
                );
            }
        }
        return parent;
    }

    buildSearchTerms = () => {
        let parent = [];
        if (!this.state.userIsBlocked && !this.state.userBlocksMe) {
            if (this.state.rentalData != null) {
                if (this.state.rentalData.searchTerms != null) {
                    for (let key in this.state.rentalData.searchTerms) {
                        parent.push(
                            <TouchableHighlight 
                                style={styles.searchTermButton}
                                onPress={() => this.navigateToSearch(key)}
                            >
                                <View style={styles.searchTermWrapper} >
                                    <Text style={styles.searchTermButtonText}>{key}</Text>
                                </View>
                            </TouchableHighlight>
                            
                        );
                    }
                }
            }
        }
        return parent;
    }

    navigateToSearch = (key) => {
        this.props.navigation.navigate("Search List", {
            filterData: {
                keyword: key
            }
        });
    }

    navigateToReadReviews = () => {
        this.props.navigation.navigate("Read Reviews", {
            postID: this.state.rentalData.key,
        });
    }

    navigateToWriteReviews = () => {
        this.props.navigation.navigate("Write Review", {
            postID: this.state.rentalData.key
        });
    }

    handlePress = () => {
        let url = this.state.rentalData.website;
        if (!this.state.rentalData.website.includes("https://") && !this.state.rentalData.website.includes("http://")) {
            url = "https://" + this.state.rentalData.website;
        }

        if (Platform.OS !== 'web') {
            try {
                const results = WebBrowser.openBrowserAsync(url).catch(error => {
                    //show error modal
                    console.log(error)
                    this.setState({
                        errorModalVisible : true,
                        errorModalText: "There was an issue opening the following URL: "+ url,
                        errorModalKey: Math.random().toString(36).substring(7),
                    });
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            window.open(url, "_blank")
        }
    };

    handleAvailabilityPress = () => {
        let url = this.state.rentalData.availability;
        if (!this.state.rentalData.availability.includes("https://") && !this.state.rentalData.availability.includes("http://")) {
            url = "https://" + this.state.rentalData.availability;
        }
        if (Platform.OS !== 'web') {
            try {
                const results = WebBrowser.openBrowserAsync(url).catch(error => {
                    //show error modal
                    console.log(error)
                    this.setState({
                        errorModalVisible : true,
                        errorModalText: "There was an issue opening the following URL: "+ url,
                        errorModalKey: Math.random().toString(36).substring(7),
                    });
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            window.open(url, "_blank")
        }
    }

    openShareModal = () => {
        this.setState({
            shareModalVisible: true,
            shareModalKey: Math.random().toString(36).substring(7),
        });
    }

    likeButtonPress = () => {
        if (this.state.userLikesThisPost) {
            const db = getDatabase();
            const myFavoritesRef = ref(db, `myFavorites/${this.state.user.uid}/${this.state.rentalData.key}`);
            set(myFavoritesRef, null);
            this.setState({
                successModalVisible : true,
                successModalKey: Math.random().toString(36).substring(7),
                successModalText: "You have removed this lisiting to your favorites",
                userLikesThisPost: false,
            });
            this.decreaseLikeCount(this.state.rentalData.key);
        } else {
            let likeData = {};
            likeData["key"] = this.state.rentalData.key;
            const db = getDatabase();
            const myFavoritesRef = ref(db, `myFavorites/${this.state.user.uid}/${this.state.rentalData.key}`);
            set(myFavoritesRef, likeData);
            this.setState({
                successModalVisible : true,
                successModalKey: Math.random().toString(36).substring(7),
                successModalText: "You have added this lisiting to your favorites",
                userLikesThisPost: true,
            });
            this.increaseLikeCount(this.state.rentalData.key);
        }
    }

    buildFooter = () => {
        let parent = [];
        if (!this.state.userIsBlocked && !this.state.userBlocksMe) {
            let buttonText = "Request to Book";
            if (!!this.state.rentalData) {
                if (this.state.rentalData.autoBook) {
                    buttonText = "Book Now";
                }
            }
            if (!this.state.currentUserPost) {
                if (!!this.state.rentalData) {
                    if (this.state.rentalData.listingType == undefined || this.state.rentalData.listingType == "str") {
                        if (this.state.rentalData.public != null && (this.state.rentalData.subscriptionLevel != undefined && this.state.rentalData.subscriptionLevel != "bronze")) {
                            if (this.state.user != null && !this.state.user.isAnonymous) {
                                if (this.state.rentalData.public) {
                                    if (this.state.userIsDoc) {
                                        if (this.state.physicianPrice != null) {
                                            if (this.props.navigation.getParent().getState().routes[0].params != undefined) {
                                                if (!!this.props.navigation.getParent().getState().routes[0].params.filterData) {
                                                    let filterData = this.props.navigation.getParent().getState().routes[0].params.filterData;
                                                    if (filterData.checkIn != null && filterData.checkOut != null) {
                                                        let startDate = new Date(filterData.checkIn);
                                                        let endDate = new Date(filterData.checkOut);
                                                        let datesAray = this.getDaysArray(startDate, endDate);
                                                        let price = getRentalPrice(this.state.userIsDoc,
                                                                                    null,
                                                                                    this.state.physicianPrice,
                                                                                    this.state.rentalData.petFee,
                                                                                    this.state.rentalData.cleaningFee,
                                                                                    datesAray.length - 1);
                                                        parent.push(
                                                            <View style={styles.fixedFooter}>
                                                                <View style={styles.flexBox}>
                                                                    <Text>x{datesAray.length - 1} night(s): <Text style={{fontWeight: "600"}}>${price}</Text></Text>
                                                                    <ActionButton 
                                                                        text={buttonText}
                                                                        handler={()=>{
                                                                            this.navigateToBooking();
                                                                        }}
                                                                        size={"medium"}
                                                                    />
                                                                </View>
                                                            </View>
                                                        );
                                                    } else {
                                                        parent.push(
                                                            <View style={styles.fixedFooter}>
                                                                <ActionButton 
                                                                    text={buttonText}
                                                                    handler={()=>{
                                                                        this.navigateToBooking();
                                                                    }}
                                                                />
                                                            </View>
                                                        );
                                                    }
                                                } else {
                                                    parent.push(
                                                        <View style={styles.fixedFooter}>
                                                            <ActionButton 
                                                                text={buttonText}
                                                                handler={()=>{
                                                                    this.navigateToBooking();
                                                                }}
                                                            />
                                                        </View>
                                                    );
                                                }
                                            } else {
                                                parent.push(
                                                    <View style={styles.fixedFooter}>
                                                        <ActionButton 
                                                            text={buttonText}
                                                            handler={()=>{
                                                                this.navigateToBooking();
                                                            }}
                                                        />
                                                    </View>
                                                );
                                            }
                                        }
                                    } else if (!!this.state.user) {
                                        if (!this.state.user.isAnonymous) {
                                            if (this.state.publicPrice != null) {
                                                if (this.props.navigation.getParent().getState().routes[0].params != undefined) {
                                                    if (!!this.props.navigation.getParent().getState().routes[0].params.filterData) {
                                                        let filterData = this.props.navigation.getParent().getState().routes[0].params.filterData;
                                                        if (filterData.checkIn != null && filterData.checkOut != null) {
                                                            let startDate = new Date(filterData.checkIn);
                                                            let endDate = new Date(filterData.checkOut);
                                                            let datesAray = this.getDaysArray(startDate, endDate);
                                                            let price = getRentalPrice(this.state.userIsDoc,
                                                                                        this.state.publicPrice,
                                                                                        null,
                                                                                        this.state.rentalData.petFee,
                                                                                        this.state.rentalData.cleaningFee,
                                                                                        datesAray.length - 1);
                                                            parent.push(
                                                                <View style={styles.fixedFooter}>
                                                                    <View style={styles.flexBox}>
                                                                        <Text>x{datesAray.length - 1} night(s): <Text style={{fontWeight: "600"}}>${price}</Text></Text>
                                                                        <ActionButton 
                                                                            text={buttonText}
                                                                            handler={()=>{
                                                                                this.navigateToBooking();
                                                                            }}
                                                                            size={"medium"}
                                                                        />
                                                                    </View>
                                                                </View>
                                                            );
                                                        } else {
                                                            parent.push(
                                                                <View style={styles.fixedFooter}>
                                                                    <ActionButton 
                                                                        text={buttonText}
                                                                        handler={()=>{
                                                                            this.navigateToBooking();
                                                                        }}
                                                                    />
                                                                </View>
                                                            );
                                                        }
                                                    } else {
                                                        parent.push(
                                                            <View style={styles.fixedFooter}>
                                                                <ActionButton 
                                                                    text={buttonText}
                                                                    handler={()=>{
                                                                        this.navigateToBooking();
                                                                    }}
                                                                />
                                                            </View>
                                                        );
                                                    }
                                                } else {
                                                    parent.push(
                                                        <View style={styles.fixedFooter}>
                                                            <ActionButton 
                                                                text={buttonText}
                                                                handler={()=>{
                                                                    this.navigateToBooking();
                                                                }}
                                                            />
                                                        </View>
                                                    );
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    if (this.state.userIsDoc) {
                                        if (this.state.physicianPrice != null) {
                                            if (this.props.navigation.getParent().getState().routes[0].params != undefined) {
                                                if (!!this.props.navigation.getParent().getState().routes[0].params.filterData) {
                                                    let filterData = this.props.navigation.getParent().getState().routes[0].params.filterData;
                                                    if (filterData.checkIn != null && filterData.checkOut != null) {
                                                        let startDate = new Date(filterData.checkIn);
                                                        let endDate = new Date(filterData.checkOut);
                                                        let datesAray = this.getDaysArray(startDate, endDate);
                                                        let price = getRentalPrice(this.state.userIsDoc,
                                                                                    null,
                                                                                    this.state.physicianPrice,
                                                                                    this.state.rentalData.petFee,
                                                                                    this.state.rentalData.cleaningFee,
                                                                                    datesAray.length - 1);
                                                        parent.push(
                                                            <View style={styles.fixedFooter}>
                                                                <View style={styles.flexBox}>
                                                                    <Text>x{datesAray.length - 1} night(s): <Text style={{fontWeight: "600"}}>${price}</Text></Text>
                                                                    <ActionButton 
                                                                        text={buttonText}
                                                                        handler={()=>{
                                                                            this.navigateToBooking();
                                                                        }}
                                                                        size={"medium"}
                                                                    />
                                                                </View>
                                                            </View>
                                                        );
                                                    }
                                                } else {
                                                    parent.push(
                                                        <View style={styles.fixedFooter}>
                                                            <ActionButton 
                                                                text={buttonText}
                                                                handler={()=>{
                                                                    this.navigateToBooking();
                                                                }}
                                                            />
                                                        </View>
                                                    );
                                                }
                                            } else {
                                                parent.push(
                                                    <View style={styles.fixedFooter}>
                                                        <ActionButton 
                                                            text={buttonText}
                                                            handler={()=>{
                                                                this.navigateToBooking();
                                                            }}
                                                        />
                                                    </View>
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        } 
                    }
                }
            }
        }
        return parent;
    }

    buildLikeIcon = () => {
        let parent = [];
        let showLikeIcon = false;
        let showUnlikeIcon = false;
        if (this.state.user) {
            showLikeIcon = (this.state.userLikesThisPost && !this.state.user.isAnonymous);
            showUnlikeIcon = (!this.state.userLikesThisPost && !this.state.user.isAnonymous);
        }
        if (showLikeIcon) {
            parent.push(
                <TouchableHighlight 
                    style={styles.likeIconWrapper}
                    onPress={() => this.likeButtonPress()}>
                    <Image
                        style={styles.likeIcon}
                        source={require('../assets/likeIcon.png')}
                    />
                </TouchableHighlight>
            );
        } else if (showUnlikeIcon) {
            parent.push(
                <TouchableHighlight 
                    style={styles.likeIconWrapper}
                    onPress={() => this.likeButtonPress()}>
                    <Image
                        style={styles.likeIcon}
                        source={require('../assets/unlikeIcon.png')}
                    />
                </TouchableHighlight>
            );
        }
        return parent;
    }

    buildBookingDetails = () => {
        let parent = [];
        let final = [];
        if (!this.state.userBlocksMe && !this.state.userIsBlocked) {
            if (this.state.rentalData.listingType == undefined || this.state.rentalData.listingType == "str") {
                if (!!this.state.rentalData){
                    if (this.state.rentalData.autoBook && this.state.hostID != this.state.user.uid) {
                        parent.push(
                            <Container maxWidth={300} title="Auto Book Enabled">
                                <Text allowFontScaling={false} style={{ marginTop: 5, marginBottom: 15}}>
                                    Reserve immediatley without a request
                                </Text>
                                <ActionButton
                                    text="Book Now"
                                    handler={()=>{
                                        this.navigateToBooking();
                                    }}
                                />
                            </Container>
                        )
                    } else if (!this.state.rentalData.autoBook && this.state.hostID != this.state.user.uid) {
                        if (this.state.rentalData.autoBook != null) {
                            if (!this.state.user.isAnonymous) {
                                parent.push(
                                    <Container maxWidth={300} title="Reservations Enabled">
                                        <Text allowFontScaling={false} style={{ marginTop: 5, marginBottom: 15}}>
                                            Reserve by sending the host a request
                                        </Text>
                                        <ActionButton
                                            text="Request Booking"
                                            handler={()=>{
                                                this.navigateToBooking();
                                            }}
                                        />
                                    </Container>
                                )
                            } else {
                                parent.push(
                                    <Container maxWidth={300} title="Reservations Enabled">
                                        <Text allowFontScaling={false} style={{ marginTop: 5, marginBottom: 15}}>
                                            Reserve by sending the host a request
                                        </Text>
                                        <ActionButton
                                            text="Log in to request"
                                            handler={()=>{
                                                this.navigateToLogIn();
                                            }}
                                        />
                                    </Container>
                                )
                            }
                        }
                    }
                    if (this.state.rentalData.minStay) {
                        parent.push(
                            <Container maxWidth={175} title={"Minimum Stay"}>
                                <Text allowFontScaling={false} style={{fontWeight: "600", fontSize: 18}}>
                                    {this.state.rentalData.minDays}
                                    <Text style={styles.smallText}>
                                    {" night(s)"}
                                    </Text>
                                </Text>
                            </Container>
                        )
                    }
                    if (!!this.state.rentalData.checkInTime && !!this.state.rentalData.checkOutTime) {
                        let checkInDate = new Date(`2015-03-25T${this.state.rentalData.checkInTime}:00`);
                        let checkOutDate = new Date(`2015-03-25T${this.state.rentalData.checkOutTime}:00`);
                        let checkInTimeString = formatTime(checkInDate);
                        let checkOutTimeString = formatTime(checkOutDate);
                        parent.push(
                            <Container maxWidth={175} title="Check In/ Out">
                                <Text allowFontScaling={false} style={{fontWeight: "600", fontSize: 18}}>
                                    <Text style={styles.smallText}>
                                        {"In: "}
                                    </Text>
                                    {checkInTimeString}
                                </Text>
                                <Text allowFontScaling={false} style={{fontWeight: "600", fontSize: 18}}>
                                    <Text style={styles.smallText}>
                                        {"Out: "}
                                    </Text>
                                    {checkOutTimeString}
                                </Text>
                            </Container>
                        )
                    }
                    parent.push(this.buildCostBreakDown());
                    final = [
                        <View style={{
                            display: "flex", 
                            flexDirection: "row", 
                            flexWrap: "wrap", 
                            justifyContent: "center", 
                            width: "100%"
                        }}>
                            {parent}
                        </View>
                    ];
                    if (!!this.state.rentalData.houseRules) {
                        final.push(
                            <Container title={"House Rules"}>
                                <View style={{ marginTop: 5, marginBottom: 10}}>
                                    <ReadMore numberOfLines={2}>
                                        <Text allowFontScaling={false} style={styles.labelText}>
                                            {this.state.rentalData.houseRules}
                                        </Text>
                                    </ReadMore>
                                </View>
                            </Container>
                        )
                    }
                }
            }
        }
        return final;
    }

    getDaysArray = (s,e) => {
        for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;
    };

    buildWholeView = () => {
        let parent = [];
        if (!!this.state.rentalData) {
            if (this.state.rentalData.public != null) {
                if (this.state.rentalData.public) {
                    parent.push(
                        this.buildSlideShow(),
                        this.buildHostProfile(),
                        this.buildBookingDetails(),
                        this.buildView(),
                        this.buildReviews(),
                        this.buildTags(),
                        this.buildCancellation(),
                        this.checkForContactButton(),
                    );
                } else if (!this.state.rentalData.public) {
                    if (this.state.userIsDoc) {
                        parent.push(
                            this.buildSlideShow(),
                            this.buildHostProfile(),
                            this.buildBookingDetails(),
                            this.buildView(),
                            this.buildReviews(),
                            this.buildTags(),
                            this.buildCancellation(),
                            this.checkForContactButton(),
                        );
                    } else {
                        parent.push(
                            <Container>
                                 <Text allowFontScaling={false} style={[styles.headerLabeltext]}>This post is not available to the public</Text>
                                 <Text allowFontScaling={false} style={[styles.headerLabeltext]}>Please log in as a physician to view it.</Text>
                                 <ActionButton
                                    text="Log In"
                                    handler={()=>{
                                        this.navigateToLogIn()
                                    }}
                                 />
                            </Container>
                        )
                    }
                }
            } else {
                parent.push(
                    this.buildSlideShow(),
                    this.buildHostProfile(),
                    this.buildBookingDetails(),
                    this.buildView(),
                    this.buildReviews(),
                    this.buildTags(),
                    this.buildCancellation(),
                    this.checkForContactButton(),
                );
            }
        } else {
            parent.push(
                <Placeholder/>
            );
        }
        return parent;
    }

    render() {
        let postURL = "https://doc2doc-d54a2.web.app/?postID="
        if (this.state.rentalData) {
            postURL += this.state.rentalData.key;
        }
        return (
            <View style={{flex: 1}}>
                <ScrollView contentContainerStyle={styles.container}>
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.shareModalVisible}
                        key={this.state.shareModalKey}
                        onModalClose={this.onModalClose}
                        onCopyPress={this.onCopyPress}
                        website={postURL}
                        labelText=""
                    />
                    { this.buildWholeView() }
                    <Footer hideText={true}/>
                </ScrollView>
                { this.buildFooter() }
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%",
        paddingBottom: 60,
    },
    button: {
        marginTop: 10,
        marginBottom: 10,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonBlue: {
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#3a93f1',
        borderColor: '#2790ff',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonRed: {
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#f13a3a',
        borderColor: '#ff2323',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    reportButton: {
        marginTop: 400,
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    buttonSignup: {
        fontSize: 12
    },
    headerLabeltext: {
        fontSize: 16,
        margin: 5,
        fontWeight: "600",
        textAlign: "center",
        color: "black",
    },
    boldText: {
        fontWeight: "600",
    },
    smallText: {
        fontSize: 14,
        fontWeight: "300",
    },
    analyticsText: {
        width: "100%",
        fontSize: 12,
        fontWeight: "600",
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 6,
        color: "#3d3d3d",
        marginBottom: 10,
    },
    mt20: {
        marginTop: 20,
    },
    mt40: {
        marginTop: 40,
    },
    mt70: {
        marginTop: 70,
    },
    mb20: {
        marginBottom: 20,
    },
    descriptionText: {
        fontWeight: "300",
        fontSize: Platform.OS == "web" ? 14: 16,
        // maxWidth: 1000,
        // padding: 20,
        // paddingTop: 10,

    },
    labelText: {
        color: "black",
        fontWeight: "300",
        // textAlign: "center",
    },
    swipeLabelText: {
        color: "black",
        fontSize: 16,
        maxWidth: 1000,
        textAlign: "center",
        padding: 10,
        paddingBottom: 0,
    },
    linkWrapper: {
        width: "100%",
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 5,
        paddingHorizontal: 20,
    },
    linkIcon: {
        marginTop: 0,
        width: 20,
        height: 20,
        marginRight: 10,
    },
    likeIconWrapper: {
        position: "absolute",
        width: 40,
        height: 40,
        top: 10,
        right: 20,
        zIndex: 20,
    },
    likeIcon: {
        width: 40,
        height: 40,
        zIndex: 20,
    },
    searchTermButton: {
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: 'white',
        borderColor: '#a3a3a3',
        borderWidth: 1,
        borderRadius: 5,
        width: 160,
        margin: 2,
    },
    searchTermButtonText: {
        fontSize: 14,
        fontWeight: "600",
        color: 'black'
    },
    searchTagsWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: 1100,
        marginBottom: 5,
    },
    reviewText: {
        width: "80%",
        maxWidth: 500,
        textAlign: "center",
        fontSize: 18,
        fontWeight: "300"
    },
    mt60: {
        marginTop: 90,
    },
    fixedFooter: {
        position: "absolute",
        backgroundColor: "white",
        width: "100%",
        height: 70,
        paddingTop: 5,
        bottom: 0,
        shadowOffset: {
            width: 0,
            height: 2,
          },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 3,
        alignItems: "center",
        zIndex: 20,
        borderTopWidth: 1,
        borderTopColor: "lightgrey"
    },
    flexBox: {
        display: "flex",
        flexDirection: "row",
        alignContent: "space-around",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%"
    },
    flexBoxCenter: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap", 
        justifyContent: "center",
        alignItems: "center",
    },
    userImage: {
        width: 40,
        height: 40,
        borderRadius: 20,
        margin: 10,
        marginTop: 0,
    },
    linkText: {
        textDecorationLine: "underline",
    },
    locationDescription: {
        fontWeight: "300",
        fontSize: Platform.OS == "web" ? 14: 16,
        color: "black",
    },
    titleText: {
        fontSize: 24,
        fontWeight: "600",
        marginBottom: 5,
        width: "100%",
        paddingRight: 60,
        marginLeft: 0,
        textAlign: "left",
        zIndex: 1,
    },
    openAILogo: {
        marginTop:5,
        width: 50,
        height:10,
    },
    icon: {
        marginLeft: 10,
        width: 30,
        height: 30,
    },
    leftIcon: {
        width:40,
        height:40,
    },
    rightIcon: {
        width:40,
        height:40,
    },  
})