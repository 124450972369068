import React from 'react';
import { TouchableHighlight } from 'react-native';
import './Marker.css';

const Marker = (props) => {
    const { color, name, id } = props;

    const createMarker = () => {
        if (props.pro) {
            return (
                <TouchableHighlight onPress={() => {
                    if (!!props.name && !!props.onClick) {
                        props.onClick(props.name);
                    }
                }}>
                    <div>
                        <div
                            className="pinPro bounce pinProTransform"
                            style={{ backgroundColor: "#5749e7", cursor: 'pointer' }}
                            title={name}
                            draggable={true}
                        />
                        <div className="pulsePro" />
                    </div>
                </TouchableHighlight>
              );
        } else if (props.selected) {
            return (
                <TouchableHighlight onPress={() => {
                    if (!!props.name && !!props.onClick) {
                        props.onClick(props.name);
                    }
                }}>
                    <div>
                        <div
                            className="pinPro bounce pinProTransform selected"
                            style={{ backgroundColor: "#e2d50a", cursor: 'pointer' }}
                            title={name}
                            draggable={true}
                        />
                        <div className="pulsePro" />
                    </div>
                </TouchableHighlight>
              );
        } else {
            return (
                <TouchableHighlight onPress={() => {
                    if (!!props.name && !!props.onClick) {
                        props.onClick(props.name);
                    }
                }}>
                    <div>
                        <div
                            className="pin bounce"
                            style={{ backgroundColor: "#5749e7", cursor: 'pointer' }}
                            title={name}
                            draggable={true}
                        />
                        <div className="pulse" />
                    </div>
                </TouchableHighlight>
              );
        }
    }

    return (
        createMarker()
    );
  };

  export default Marker;