import React,  { useState, useEffect, } from 'react';
import * as AppleAuthentication from 'expo-apple-authentication';
import { OAuthProvider } from 'firebase/auth';
import * as Crypto from 'expo-crypto';
import { StyleSheet,View,  Dimensions } from 'react-native';

export default function AppleSignUpButton(props) {

    // const [loading, setLoading] = useState(false);

    const appleSignup = async () => {
        const nonce = Math.random().toString(36).substring(2, 10);
        const hashedNonce = await Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA256, nonce);
        try {
            const credential = await AppleAuthentication.signInAsync({
              requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME
              ],
              nonce: hashedNonce,
            });
            // setLoading(true);
            const provider = new OAuthProvider('apple.com');
            provider.addScope('email');
            provider.addScope('fullName');
            provider.addScope('profile');
            const appleCredential = provider.credential({
                idToken: credential.identityToken,
                rawNonce: nonce
            });
            if (props.onComplete) {
                let name = null;
                if (credential.fullName.givenName && credential.fullName.familyName) {
                    name = credential.fullName.givenName + " " + credential.fullName.familyName;
                }
                props.onComplete(appleCredential, credential.email, name);
            }
        } catch (e) {
            if (e.code === 'ERR_CANCELED') {
                console.log(e)
            } else {
                console.log(e)
            }
        }
    }

    return (
        <View style={styles.flexBox}>
            <AppleAuthentication.AppleAuthenticationButton
                buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
                buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.BLACK}
                cornerRadius={8}
                style={styles.appleButton}
                onPress={async () => {
                    appleSignup();
                }}
            />
        </View>
    );
}

const styles = StyleSheet.create({
    appleButton: {
        width: "100%",
        padding: 7,
        maxWidth: 300,
        height: 50,
    },
    flexBox: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
    },
});