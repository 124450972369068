import React, { Component } from 'react';
import { StyleSheet,
        Text,
        View, 
        ScrollView,  
        Platform,  
        TextInput } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { createStackNavigator } from '@react-navigation/stack';
import {Picker} from '@react-native-picker/picker';

import ReservationsDateView from "./ReservationDatesView";

import Container from '../compenents/Container';
import Checkbox from "../compenents/CheckBox";
import SearchInput from "../compenents/SearchInput";
import Stepper from "../compenents/Stepper";
import ActionButton from '../compenents/ActionButton';
import { getDateString } from '../helpers/date-formatter';

const Stack = createStackNavigator();

export default function FilterViewWrapper() {
    return (
      <Stack.Navigator>
        <Stack.Screen
          name="Filters"
          component={FilterView}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Reservation Dates"
          component={ReservationsDateView}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    );
}

class FilterView extends Component {

    constructor(props) {
        super(props);
        this.state = {
          checkIn: null,
          checkOut: null,
          petFriendly: false,
          washerDryer: false,
          people: null,
          bedrooms: null,
          bathrooms: null,
          petKey: Math.random().toString(36).substring(7),
          washerDryerKey: Math.random().toString(36).substring(7),
          bedroomKey: Math.random().toString(36).substring(7),
          bathroomsKey: Math.random().toString(36).substring(7),
          peopleKey: Math.random().toString(36).substring(7),
          searchKey: Math.random().toString(36).substring(7),
          keywordKey: Math.random().toString(36).substring(7),
        };
        this.petCheckHandler = this.petCheckHandler.bind(this);
        this.washerDryerCheckHandler = this.washerDryerCheckHandler.bind(this);
        this.bedroomHandler = this.bedroomHandler.bind(this);
        this.bathroomHandler = this.bathroomHandler.bind(this);
        this.peopleHandler = this.peopleHandler.bind(this);
    } 

    componentDidMount() {
        if (this.props.route.params != null) {
            if (this.props.route.params.filterData != null) {
                this.setState({
                    checkIn: this.props.route.params.filterData.checkIn,
                    checkOut: this.props.route.params.filterData.checkOut,
                    petFriendly: this.props.route.params.filterData.petFriendly,
                    washerDryer: this.props.route.params.filterData.washerDryer,
                    people: this.props.route.params.filterData.people,
                    bedrooms: this.props.route.params.filterData.bedrooms,
                    bathrooms: this.props.route.params.filterData.bathrooms,
                    petKey: Math.random().toString(36).substring(7),
                    washerDryerKey: Math.random().toString(36).substring(7),
                    bedroomKey: Math.random().toString(36).substring(7),
                    bathroomsKey: Math.random().toString(36).substring(7),
                    peopleKey: Math.random().toString(36).substring(7),
                    searchKey: Math.random().toString(36).substring(7),
                }); 
                if (!!this.props.route.params.filterData.location) {
                    this.setState({
                        lat: this.props.route.params.filterData.location.lat,
                        lng: this.props.route.params.filterData.location.lng,
                        locationName: this.props.route.params.filterData.location.locationName,
                    }); 
                }
                if (!!this.props.route.params.filterData.keyword) {
                    this.setState({
                        keyword: this.props.route.params.filterData.keyword,
                    }); 
                }
            }
        }
    }

    petCheckHandler(checked) {
        this.setState({ 
            petFriendly: checked,
        });
    }

    washerDryerCheckHandler(checked) {
        this.setState({ 
            washerDryer: checked,
        });
    }

    bedroomHandler(bedrooms) {
        this.setState({ 
            bedrooms: bedrooms,
        });
    }

    bathroomHandler(bathrooms) {
        this.setState({ 
            bathrooms: bathrooms,
        });
    }

    peopleHandler = (people) => {
        this.setState({ 
            people: people,
        });
    }

    onDatesSelect = (checkIn, checkOut) => {
        this.setState({
            checkIn: checkIn,
            checkOut: checkOut,
        })
    }

    resetFilters = () => {
        this.setState({
            checkIn: null,
            checkOut: null,
            petFriendly: false,
            washerDryer: false,
            people: null,
            bedrooms: null,
            bathrooms: null,
            petKey: Math.random().toString(36).substring(7),
            washerDryerKey: Math.random().toString(36).substring(7),
            bedroomKey: Math.random().toString(36).substring(7),
            bathroomsKey: Math.random().toString(36).substring(7),
            peopleKey: Math.random().toString(36).substring(7),
            searchKey: Math.random().toString(36).substring(7),
            lat: null,
            lng: null,
            locationName: null,
            keyword: null,
            keywordKey: Math.random().toString(36).substring(7),
          });
    }

    navigateToCalendarView = () => {
        let filtertData = {
            checkIn: this.state.checkIn,
            checkOut: this.state.checkOut,
            petFriendly: this.state.petFriendly,
            washerDryer: this.state.washerDryer,
            people: this.state.people,
            bedrooms: this.state.bedrooms,
            bathrooms: this.state.bathrooms,
        }
        this.props.navigation.navigate('Reservation Dates', {
            filterData: filtertData,
            callback: this.onDatesSelect,
        });
    }

    filterComplete = () => {
        let filtertData = {
            checkIn: this.state.checkIn,
            checkOut: this.state.checkOut,
            petFriendly: this.state.petFriendly,
            washerDryer: this.state.washerDryer,
            people: this.state.people,
            bedrooms: this.state.bedrooms,
            bathrooms: this.state.bathrooms,
        }
        if (!!this.state.lat && !!this.state.lng && !!this.state.locationName) {
            let locationData = {
                lat: this.state.lat,
                lng: this.state.lng,
                locationName: this.state.locationName,
            }
            filtertData["location"] = locationData;
        }
        if (this.state.keyword != null) {
            filtertData["keyword"] = this.state.keyword;
        }
        if (this.props.route.params.homeCallback) {
            if (!!filtertData.bathrooms ||
                !!filtertData.bedrooms ||
                !!filtertData.checkIn ||
                !!filtertData.checkOut ||
                !!filtertData.people ||
                filtertData.petFriendly ||
                filtertData.washerDryer ||
                !!filtertData.keyword ||
                !!filtertData.location
            ) {
                this.props.route.params.homeCallback(filtertData);
            } else {
                this.props.route.params.homeCallback(null);
            }
        }
        if (this.props.route.params.searchCallBack) {
            if (!!filtertData.bathrooms ||
                !!filtertData.bedrooms ||
                !!filtertData.checkIn ||
                !!filtertData.checkOut ||
                !!filtertData.people ||
                filtertData.petFriendly ||
                filtertData.washerDryer ||
                !!filtertData.keyword ||
                !!filtertData.location
            ) {
                this.props.route.params.searchCallBack(filtertData);   
            } else {
                this.props.route.params.searchCallBack(null);   
            }
        }
        this.props.navigation.goBack();
    }

    onSearchSelect = (lat, lng, name) => {
        this.setState({
            lat: lat,
            lng: lng,
            locationName: name,
        });
    }

    buildView = () => {
        let parent = [];
        let datesString = "";
        if (this.state.checkIn != null) {
            let checkInString = getDateString(this.state.checkIn);
            datesString += checkInString;
        } 
        if (this.state.checkOut != null) {
            if (datesString == "") {
                let checkOutString = getDateString(this.state.checkOut);
                datesString += checkOutString;
            } else {
                let checkOutString = getDateString(this.state.checkOut);
                datesString += " - " + checkOutString;
            }
        } 
        if (datesString == "") {
            parent.push(
                <ActionButton 
                    text={"Add search dates"}
                    handler={() => {
                        this.navigateToCalendarView()
                    }}
                    icon={require('../assets/calendarIcon.png')}
                    filled={true}
                />
            );
        } else {
            parent.push(
                <ActionButton 
                    text={datesString}
                    handler={() => {
                        this.navigateToCalendarView()
                    }}
                    icon={require('../assets/calendarIcon.png')}
                    filled={true}
                />
            );
        }

        parent.push(
            <Checkbox 
                label={"Pet Friendly"}
                checked={this.state.petFriendly}
                key={this.state.petKey}
                handler={this.petCheckHandler}
            />,
            <View style={[styles.mt10, {width: "100%"}]}>
                <Checkbox 
                    label={"Washer and Dryer"}
                    checked={this.state.washerDryer}
                    key={this.state.washerDryerKey}
                    handler={this.washerDryerCheckHandler}
                />
            </View>,
            <View style={styles.mt10}>
                <Stepper
                    count={this.state.bedrooms}
                    key={this.state.bedroomKey}
                    handler={this.bedroomHandler}
                    label="Number of bedrooms"
                />
            </View>,
            <Stepper
                key={this.state.bathroomsKey}
                count={this.state.bathrooms}
                handler={this.bathroomHandler}
                label="Number of bathrooms"
            />,
            <Stepper
                key={this.state.peopleKey}
                count={this.state.people}
                handler={this.peopleHandler}
                label="Number of people"
            />,
        );
        if (!!this.state.keyword) {
            parent.push(
                <Text allowFontScaling={false} style={styles.label}>Tags</Text>,
                <TextInput
                    style={styles.inputBox}
                    //placeholder="Search by keyword/ tag"
                    numberOfLines={1}
                    onChangeText={keyword => {
                        this.setState({ keyword: keyword});
                    }}
                    autoCapitalize={false}
                    value={this.state.keyword}
                    key={this.state.keywordKey}
                    placeholderTextColor='black' 
                />
            );
        } else {
            parent.push(
                <Text allowFontScaling={false} style={styles.label}>Tags</Text>,
                <TextInput
                    style={styles.inputBox}
                    placeholder="Search by keyword/ tag"
                    placeholderTextColor='black'
                    numberOfLines={1}
                    onChangeText={keyword => {
                        this.setState({ keyword: keyword});
                    }}
                    autoCapitalize={false}
                    // value={this.state.publicRentalData.description}
                    key={this.state.keywordKey}
                />
            );
        }
        parent.push(
            <View style={styles.buttonContainer}>
                <ActionButton
                    text={"Done"}
                    handler={()=>{
                        this.filterComplete()
                    }}
                />
                <ActionButton
                    text={"Clear filters"}
                    handler={()=>{
                        this.resetFilters()
                    }}
                    filled={true}
                />
            </View>
        );
        return parent;
    }

    render() {
        let headerLabeltextStyles = [styles.headerLabeltext];
        if (Platform.OS != "web") {
            headerLabeltextStyles.push(styles.mt70)
        }
        let headWrapperStyles = [styles.headWrapper];
        if (this.state.searchIsFocused) {
            headWrapperStyles.push(styles.height100)
        }
        return (
            <View style={[styles.scrollContainer]}>
                    <View style={headWrapperStyles}>
                        <View 
                            style={{
                                width: "100%",
                                position: "absolute", 
                                display: "flex", 
                                alignItems: "center", 
                                justifyContent: "center",
                                zIndex: 0,
                            }}>
                            <Container>
                                <View style={{maxWidth: 300, width: 300}}>
                                    <Text allowFontScaling={false} style={styles.label}>Location</Text>
                                    {
                                        Platform.OS == "android"?
                                        <SearchInput
                                            onChange={(lat, lng, name) => {
                                                if (!!name) {
                                                    this.onSearchSelect(lat, lng, name)
                                                } else {
                                                    if (this.props.route.params != null) {
                                                        if (this.props.route.params.filterData != null) {
                                                            if (this.props.route.params.filterData.location.locationName) {
                                                                this.onSearchSelect(this.props.route.params.filterData.location.lat,
                                                                                    this.props.route.params.filterData.location.lng,
                                                                                    this.props.route.params.filterData.location.locationName)
                                                            } else {
                                                                this.onSearchSelect(null, null, null)
                                                            }
                                                        } else {
                                                            this.onSearchSelect(null, null, null)
                                                        }
                                                    } else {
                                                        this.onSearchSelect(null, null, null)
                                                    }
                                                }
                                            }}
                                            isFocused={focused => {
                                                this.setState({
                                                    searchIsFocused: focused
                                                })
                                            }}
                                            key={this.state.searchKey}
                                            value={this.state.locationName}
                                        />:
                                        <SearchInput
                                            onChange={(lat, lng, name) => {
                                                if (!!name) {
                                                    this.onSearchSelect(lat, lng, name)
                                                } else {
                                                    if (this.props.route.params != null) {
                                                        if (this.props.route.params.filterData != null) {
                                                            if (this.props.route.params.filterData.location.locationName) {
                                                                this.onSearchSelect(this.props.route.params.filterData.location.lat,
                                                                                    this.props.route.params.filterData.location.lng,
                                                                                    this.props.route.params.filterData.location.locationName)
                                                            } else {
                                                                this.onSearchSelect(null, null, null)
                                                            }
                                                        } else {
                                                            this.onSearchSelect(null, null, null)
                                                        }
                                                    } else {
                                                        this.onSearchSelect(null, null, null)
                                                    }
                                                }
                                            }}
                                            key={this.state.searchKey}
                                            value={this.state.locationName}
                                        />
                                    }
                                </View>
                            </Container>
                        </View>
                    </View>
     
                <KeyboardAwareScrollView contentContainerStyle={styles.container}>
                    <Container>
                        { this.buildView() }
                    </Container>
                </KeyboardAwareScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        width: "100%",
        alignItems: "center",
    },
    inputBox: {
        width: '85%',
        margin: 10,
        marginTop: 0,
        padding: 15,
        paddingLeft: 5,
        fontSize: 14,
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        backgroundColor: "#f5f5f5",
        maxWidth: 300,
    },
    headWrapper:{
        width: "100%",
        zIndex: 20, 
        display: "flex", 
        height: 110,
    },
    height100: {
        height: "100%",
    },
    scrollContainer: {
        width: "100%",
        height: "100%",
    },
    button: {
        marginTop: 30,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    clickablDate: {
    },
    labelText: {
        color: "black",
        fontSize: 16,
        maxWidth: 1000,
        padding: 10,
        paddingBottom: 10,
    },
    label: {
        margin: 10,
        fontWeight: "600",
        textAlign: "center"
    },
    mt10: {
        marginTop: 10,
    },
    mt20: {
        marginTop: 20,
    },
    buttonContainer: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    calendarIcon: {
        marginTop: 7,
        height: 30,
        width: 30,
    },
    headerLabeltext: {
        marginTop: 20,
        fontSize: 24,
        fontWeight: "700",
        textAlign: "center",
    },
    mt70: {
        marginTop: 70,
    },
    buttonWrapper: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: 45,
    },  
    itemStyle: {
        fontSize: 15,
        height: 75,
        color: 'black',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    backButtonWrapper: {
        height: 75,
        width:75,
        padding: 10,
        position: "absolute",
        left: "8%",
        top: 40,
        zIndex: 20,
    },
    backWrapper: {
        display: "flex",
        flexDirection: "row",
        flex: 2,
        zIndex: 10,
    },
    backButton: {
        height: 60,
        width:60,
        zIndex: 10,
        transform: [{ rotate: '270deg'}]
    },
});