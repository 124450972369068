import React, { Component } from 'react';
import { StyleSheet, Image, View, TouchableHighlight } from 'react-native';

export default class ToolTip extends Component {
  render() {
    return (        
      <TouchableHighlight 
          onPress={() => {
            this.props.onPress(this.props.title)
          }}
          style ={styles.wrapper}>
          <View style ={styles.menuWrapper}>
              <Image
                style={styles.menuIcon}
                source={require('../assets/tooltipIcon.png')}
              ></Image>
          </View>
      </TouchableHighlight>
    )
  }
}

const styles = StyleSheet.create({
  wrapper: {
    height: 20,
    width:20,
    marginBottom: 10,
    marginLeft: 3,
  },
  menuWrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 2,
    zIndex: 10,
  },
  menuIcon: {
    width: 20,
    height: 20,
  },
});
