import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, Image, Dimensions, Platform, TouchableHighlight } from 'react-native';
import { getDatabase, ref, get } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { getDateString } from '../helpers/date-formatter';
import { getUserImage } from '../helpers/user-image-retreiver';
import Container from "../compenents/Container";
import ActionButton from '../compenents/ActionButton';

export default class Reservations extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            reservationsData: null,
            userImages: null,
            width: Dimensions.get('window').width,
            selectedFilter: "upcoming",
        }
    }

    componentDidMount = () => {
        if (Platform.OS == "web") {
            window.addEventListener('resize', this.updateDimensions);
        }
        this.updateDimensions();
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user }, ()=>{
                    this.configureView();
                });
            } else {
                this.setState({ user: null });
            }
        });
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.configureView();
        });
    }

    updateDimensions = () => {
        this.setState({ width:  Dimensions.get('window').width});
    };

    configureView = () => {
        if (!!this.props.route.params) {
            if (!!this.props.route.params.rentalData) {
                this.getReservationsForPostID(this.props.route.params.rentalData.key);
            } else {
                this.getAllReservations();
            }
        } else {
            this.getAllReservations();
        }
    }

    getReservationsForPostID = async (postID) => {
        if (this.state.user != null) {
            const db = getDatabase();
            const reservationsRef = ref(db, `reservations/${this.state.user.uid}/${postID}`);
            let snapshot = await get(reservationsRef);
            let reservationsData = snapshot.val();
            this.setState({
                reservationsData: reservationsData,
            }, ()=>{
                this.getUserImages()
            });
        }
    }

    getAllReservations = async () => {
        if (this.state.user != null) {
            const db = getDatabase();
            const reservationsRef = ref(db, `reservations/${this.state.user.uid}`);
            let snapshot = await get(reservationsRef);
            let reservationsData = snapshot.val();
            this.setState({
                reservationsData: reservationsData,
            }, ()=>{
                this.getUserImages()
            });
        }
    }

    getUserImages = async () => {
        let userImages = {};
        if (this.state.reservationsData != null) {
            for (let index in this.state.reservationsData) {
                let reservation = this.state.reservationsData[index];
                let imageUrl = await getUserImage(reservation.requesterUID);
                userImages[reservation.requesterUID] = imageUrl;
            }
            this.setState({
                userImages: userImages,
            })            
        }
    }

    allSelected = () => {
        this.setState({selectedFilter: "all"});
    }

    // pendingSelected = () => {
    //     this.setState({selectedFilter: "pending"});
    // }

    upcomingSelected = () => {
        this.setState({selectedFilter: "upcoming"});
    }

    passedSelected = () => {
        this.setState({selectedFilter: "passed"});
    }

    userSelect = (requesterUID) => {
        //navigate to profile page
        this.props.navigation.navigate("Public Profile", {
            profileUID: requesterUID,
        })
    }

    buildHeader = () => {
        let parent = [];
        switch (this.state.selectedFilter) {
            case "all":
                parent.push(
                    <View style={styles.header}>
                        <TouchableHighlight 
                            style={styles.selectedHeaderButton}
                            onPress={()=>{
                                this.allSelected();
                            }}
                        >
                            <Text style={styles.selectedHeaderButtonText}>All</Text>
                        </TouchableHighlight>
                        {/* <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.pendingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Pending</Text>
                        </TouchableHighlight> */}
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.upcomingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Upcoming</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.passedSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Past</Text>
                        </TouchableHighlight>
                    </View>
                )
                break;
            case "pending":
                parent.push(
                    <View style={styles.header}>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.allSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>All</Text>
                        </TouchableHighlight>
                        {/* <TouchableHighlight 
                            style={styles.selectedHeaderButton}
                            onPress={()=>{
                                this.pendingSelected();
                            }}
                        >
                            <Text style={styles.selectedHeaderButtonText}>Pending</Text>
                        </TouchableHighlight> */}
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.upcomingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Upcoming</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.passedSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Past</Text>
                        </TouchableHighlight>
                    </View>
                )
                break;
            case "upcoming":
                parent.push(
                    <View style={styles.header}>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.allSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>All</Text>
                        </TouchableHighlight>
                        {/* <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.pendingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Pending</Text>
                        </TouchableHighlight> */}
                        <TouchableHighlight 
                            style={styles.selectedHeaderButton}
                            onPress={()=>{
                                this.upcomingSelected();
                            }}
                        >
                            <Text style={styles.selectedHeaderButtonText}>Upcoming</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.passedSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Past</Text>
                        </TouchableHighlight>
                    </View>
                )
                break;
            case "passed":
                parent.push(
                    <View style={styles.header}>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.allSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>All</Text>
                        </TouchableHighlight>
                        {/* <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.pendingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Pending</Text>
                        </TouchableHighlight> */}
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.upcomingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Upcoming</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.selectedHeaderButton}
                            onPress={()=>{
                                this.passedSelected();
                            }}
                        >
                            <Text style={styles.selectedHeaderButtonText}>Past</Text>
                        </TouchableHighlight>
                    </View>
                )
                break;
            default:
                break;
        }
       
        return parent;
    }

    constructReservationFromData = (reservationData,  colums) => {
        let parent = [];
        // let userImage = await getUserImage(requestData.requesterUID)
        if (!!this.state.userImages) {
            let userImage = this.state.userImages[reservationData.requesterUID];
            if (userImage != undefined) {
                parent.push(
                    <TouchableHighlight
                        onPress={()=>{
                            this.userSelect(reservationData.requesterUID);
                        }}
                    >
                        <View style={{display: "flex", flexDirection: "row", width: 280}}>
                            <Image 
                                style={styles.userImage}
                                source={{
                                    uri: userImage,
                                }}
                            
                            />
                            <Text style={styles.renterName}>{reservationData.renterName}</Text>
                        </View>
                    </TouchableHighlight>
                );
            } else {
                parent.push(
                    <TouchableHighlight
                        onPress={()=>{
                            this.userSelect(reservationData.requesterUID);
                        }}
                    >
                            <View style={{display: "flex", flexDirection: "row", width: 280}}>
                            <Image 
                                style={styles.userImage}
                                source={require('../assets/profileIcon.png')}
                        
                            />
                            <Text style={styles.renterName}>{reservationData.renterName}</Text>
                        </View>
                    </TouchableHighlight>
                );
            }
        } else {
            parent.push(
                <TouchableHighlight
                    onPress={()=>{
                        this.userSelect(reservationData.requesterUID);
                    }}
                >
                    <View style={{display: "flex", flexDirection: "row", width: 280}}>
                        <Image 
                            style={styles.userImage}
                            source={require('../assets/profileIcon.png')}
                            
                        />
                        <Text style={styles.renterName}>{reservationData.renterName}</Text>
                    </View>
                </TouchableHighlight>
            );
        }
        if (this.props.route.params.rentalData != null) {
            let propertyName = this.props.route.params.rentalData.title;
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 280}}>
                    <Text style={{width: 80, fontWeight: "600"}}>Renting:</Text>
                    <View style={styles.divider}></View>
                    <Text>{propertyName}</Text>
                </View>
            );
        }
      
        let today = new Date();
        let checkInDate = new Date(reservationData.checkInDate);
        let checkOutDate = new Date(reservationData.checkOutDate);
        let datesAray = this.getDaysArray(checkInDate, checkOutDate);
        let datesCount = datesAray.length - 1;
        let checkInString = getDateString(reservationData.checkInDate);
        let checkOutString = getDateString(reservationData.checkOutDate);
        parent.push(
            <View style={{display: "flex", flexDirection: "row", width: 280}}>
                <Text style={{width: 80, fontWeight: "600"}}>Check In:</Text>
                <View style={styles.divider}></View>
                <Text>{checkInString}</Text>
            </View>
        );
        parent.push(
            <View style={{display: "flex", flexDirection: "row",  width: 280}}>
                <Text style={{width: 80, fontWeight: "600"}}>Check Out:</Text>
                <View style={styles.divider}></View>
                <Text>{checkOutString}</Text>
            </View>
        );
        parent.push(
            <View style={{display: "flex", flexDirection: "row", width: 280}}>
                <Text style={{width: 80, fontWeight: "600"}}>Night(s):</Text>
                <View style={styles.divider}></View>
                <Text>x {datesCount}</Text>
            </View>
        );
        if (reservationData.bringingPet) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 280}}>
                    <Text style={{width: 80, fontWeight: "600"}}>Pet:</Text>
                    <View style={styles.divider}></View>
                    <Text>Yes</Text>
                </View>
            );
        }
        parent.push(
            <View style={{display: "flex", flexDirection: "row",  width: 280}}>
                <Text style={{width: 80, fontWeight: "600"}}>People:</Text>
                <View style={styles.divider}></View>
                <Text>x {reservationData.people}</Text>
            </View>
        );
        if (!!reservationData.npi) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 280}}>
                    <Text style={{width: 80, fontWeight: "600"}}>NPI:</Text>
                    <View style={styles.divider}></View>
                    <Text>{reservationData.npi}</Text>
                </View>
            );
        }
        parent.push(
            <View style={{display: "flex", flexDirection: "column", width: "100%",}}>
                <Text style={{width: "100%", fontWeight: "600"}}>Message:</Text>
                {/* <View style={styles.divider}></View> */}
                <Text style={{width: "100%", backgroundColor: "#d3d3d3", padding: 10}}>{reservationData.message}</Text>
            </View>
        );
        parent.push(
            <View style={{display: "flex", flexDirection: "row", marginTop: 10,}}>
                <Text style={{fontWeight: "600", fontSize: 18}}>Total: ${parseFloat(reservationData.totalCollected).toFixed(2)}</Text>
            </View>
        )
        // let tomorrow = new Date();
        // tomorrow.setDate(tomorrow.getDate()+1);
        if (checkInDate.getTime() > today.getTime()) {
            parent.push(
                <View style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <ActionButton
                        text="Cancel"
                        handler={()=>{
                            this.props.navigation.navigate(
                                "Cancel Reservation",
                                { reservationData: reservationData }
                            );   
                        }}
                        filled={true}
                    />
                </View>
            )
        }

        if (colums == 2 && ((this.state.width >= 800 && this.state.width < 873) || this.state.width > 1120)) {
            let smallWrapper = [
                <Container maxWidth={360}>
                    <View style={{width: "100%", maxWidth: 360}}>
                        {parent}
                    </View>
                </Container>
            ];
            return smallWrapper;
        } else {
            let smallWrapper = [
                <Container maxWidth={360}>
                    <View style={{width: "100%"}}>
                        {parent}
                    </View>
                </Container>
            ];

            return smallWrapper;
        }
    }

    getDaysArray = (s,e) => {
        for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;
    };


    buildView = () => {
        let parent = [];
        if (this.state.reservationsData != null) {
            for (let index in this.state.reservationsData) {
                let reservation = this.state.reservationsData[index];
                if (reservation.status != "canceled by host" && reservation.status != "cancled by guest" ) {
                    if (this.state.selectedFilter == "all") {
                        parent.push(this.constructReservationFromData(reservation, 2));
                    } else if (this.state.selectedFilter == "upcoming") {
                        let checkIn = new Date(reservation.checkInDate);
                        let today = new Date();
                        if (checkIn.getTime() > today.getTime()) {
                            parent.push(this.constructReservationFromData(reservation, 2));
                        }
                    } else if (this.state.selectedFilter == "passed") {
                        let checkOut = new Date(reservation.checkOutDate);
                        let today = new Date();
                        if (checkOut.getTime() < today.getTime()) {
                            parent.push(this.constructReservationFromData(reservation, 2));
                        }
                    }
                }
            }
        }
        let final = [];
        final.push(
            <View style={styles.flexContainer}>
                {parent}
            </View>
        )
        return final;
    }

    render() {
        return (
            <View style={{height: "100%"}}>
                { this.buildHeader() }
                <ScrollView contentContainerStyle={styles.container}>
                    { this.buildView() }
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 60,
        paddingBottom: 80,
    },
    headerLabeltext: {
        marginTop: 10,
        fontWeight: "600",
        textAlign: "center",
    },
    userImage: {
        width: 30,
        height: 30,
        borderRadius: 15,
        backgroundColor: "white",
        margin: 10,
        marginTop: 0,
    },
    renterName: {
        fontWeight: "600",
        marginTop: 8,
    },
    divider: {
        backgroundColor: "#d3d3d3",
        width: 1,
        height: 20,
        marginHorizontal: 10,
    },
    flexContainer: {
        padding: 10,
        paddingHorizontal: 20,
        paddingTop: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    header: {
        position: "absolute",
        top: 0,
        height: 60,
        width: "100%",
        zIndex: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        borderBottomColor: "lightgrey",
        borderBottomWidth: 1,
        backgroundColor: "white",
    },
    headerButton: {
        width: "20%",
        height: 50,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        marginHorizontal: 3,
    },
    headerButtonText: {
        fontSize: 16,
        fontWeight: "600",
        // color: "#5d51ef"
    },
    selectedHeaderButton: {
        width: "20%",
        height: 50,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        borderBottomColor: "#5d51ef",
        borderBottomWidth: 5,
        marginHorizontal: 3,
    },
    selectedHeaderButtonText: {
        fontSize: 16,
        fontWeight: "600",
        color: "#5d51ef"
    },
})