import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, Image, TouchableHighlight } from 'react-native';
import { getDatabase, ref, get } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { createStackNavigator } from '@react-navigation/stack';
import { getDateString } from '../helpers/date-formatter';

import PublicProperty from './PubicProperty';
import MyCheckInInstructions from './MyCheckInInstructions';
import CancelReservation from './CancelReservation';
import CostBreakdown from './CostBreakDown';
import MessageView from './Message';
import Success from './Success';
import PublicProfile from './PublicProfile';

import Container from "../compenents/Container";
import ActionButton from '../compenents/ActionButton';

const Stack = createStackNavigator();

export default function MyTrips() {
    return (
      <Stack.Navigator>
        <Stack.Screen
          name="My Trips View"
          component={Trips}
          options={{ headerShown: false }}
        />
        <Stack.Screen 
          name="Public Property" 
          component={PublicProperty}
        />
        <Stack.Screen 
          name="Public Profile" 
          component={PublicProfile}
        />
        <Stack.Screen 
          name="My Check In Instructions" 
          component={MyCheckInInstructions}
        />
        <Stack.Screen 
          name="Reservation Details" 
          component={CostBreakdown}
        />
        <Stack.Screen 
          name="Cancel Reservation" 
          component={CancelReservation}
        />
        <Stack.Screen 
          name="Message" 
          component={MessageView}
        />
        <Stack.Screen 
          name="Success" 
          component={Success}
        />
      </Stack.Navigator>
    );
}

class Trips extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            tripsData: null,
            pendingTripsData: null,
            rentalData: null,
            reservationData: null,
            selectedFilter: "all",
        }
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.props.navigation.getParent().setOptions({headerShown: true});
            if (!!this.state.user) {
                this.getMyTrips(this.state.user);
                this.getMyPendingTrips(this.state.user);
                this.getRentalData();
                this.getReservationData();
            } else {
                this.getUser();
            }
        });
        this.getUser();
    }

    getUser = () => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                this.setState({
                    user: user,
                });
                let success = await this.getMyTrips(user);
                let pending = await this.getMyPendingTrips(user);
                this.getRentalData();
                this.getReservationData();
            } else {
                this.setState({
                    user: null,
                })
            }
        });
    }

    getMyTrips = async (user) => {
        const db = getDatabase();
        const myTripsRef = ref(db, `myTrips/${user.uid}`);
        let snapshot = await get(myTripsRef);
        let tripsData = snapshot.val();
        if (!!tripsData) {
            this.setState({
                tripsData: tripsData,
            });
            return true;
        }
    }

    getMyPendingTrips = async (user) => {
        const db = getDatabase();
        const myTripsRef = ref(db, `myPendingTrips/${user.uid}`);
        let snapshot = await get(myTripsRef);
        let tripsData = snapshot.val();
        if (!!tripsData) {
            this.setState({
                pendingTripsData: tripsData,
            });
            return true;
        }
    }

    getRentalData = async () => {
        const db = getDatabase();
        if (this.state.tripsData != null) {
            for (let index in this.state.tripsData) {
                let tripData = this.state.tripsData[index];
                const rentalRef = ref(db, `publicRentals/${tripData.postID}`);
                let snapshot = await get(rentalRef);
                let rentalData = snapshot.val();
                if (!!rentalRef) {
                    if (this.state.rentalData != null) {
                        let newRentalData = this.state.rentalData;
                        newRentalData[tripData.postID] = rentalData;
                        this.setState({
                            rentalData: newRentalData,
                        });
                    } else {
                        let newRentalData = [];
                        newRentalData[tripData.postID] = rentalData;
                        this.setState({
                            rentalData: newRentalData,
                        });
                    }
                }
            }
        }
        if (this.state.pendingTripsData != null) {
            for (let index in this.state.pendingTripsData) {
                let tripData = this.state.pendingTripsData[index];
                const rentalRef = ref(db, `publicRentals/${tripData.postID}`);
                let snapshot = await get(rentalRef);
                let rentalData = snapshot.val();
                if (!!rentalRef) {
                    if (this.state.rentalData != null) {
                        let newRentalData = this.state.rentalData;
                        newRentalData[tripData.postID] = rentalData;
                        this.setState({
                            rentalData: newRentalData,
                        });
                    } else {
                        let newRentalData = [];
                        newRentalData[tripData.postID] = rentalData;
                        this.setState({
                            rentalData: newRentalData,
                        });
                    }
                }
            }
        }
    }

    getReservationData = async () => {
        const fsdb = getFirestore();
        if (this.state.tripsData != null) {
            let reservationData = null;
            for (let index in this.state.tripsData) {
                let tripData = this.state.tripsData[index];
                //get reservation data for each tripData key
                const docRef = doc(fsdb, "reservations", index);
                const docSnap = await getDoc(docRef).catch(error => {console.log(error)});
                if (docSnap.exists()) {
                  if (reservationData == null) {
                    reservationData = {};
                  } 
                  reservationData[tripData.id] = docSnap.data();
                } else {
                    console.log("no reservaton data exists")
                }
            }
            this.setState({
                reservationData: reservationData
            })
        }
    }

    buildView = () => {
        let parent = [];
        let today = new Date();
        if (this.state.tripsData != null && this.state.rentalData != null && this.state.reservationData != null) {
            for (let index in this.state.tripsData) {
                let trip = [];
                let tripData = this.state.tripsData[index];
                let rentalData = this.state.rentalData[tripData.postID];
                let reservation = this.state.reservationData[index];
                if (rentalData != null) {
                    if ((reservation.status == "awaiting check in" || reservation.status == "checked in") && (this.state.selectedFilter == "all" || this.state.selectedFilter == "upcoming")) {
                        let checkInDate = new Date(tripData.checkInDate);
                        let checkInString = getDateString(tripData.checkInDate)
                        let checkOutString = getDateString(tripData.checkOutDate)
                        trip.push(
                            <Text style={{fontWeight: "600", margin: 5, marginBottom: 10, fontSize: 16}}>{rentalData.title}</Text>,
                            <View style={styles.checkInOutWrapper}>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Arrive
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkInString}
                                    </Text>
                                </View>
                                <View style={styles.divider}/>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Depart
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkOutString}
                                    </Text>
                                </View>
                            </View>,
                            <Image 
                                style={styles.previewImage}
                                source={{
                                    uri: rentalData.photoUrls[0],
                                }}
                                resizeMode={"contain"}
                            />,
                            <ActionButton
                                text="Check In Instructions"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("My Check In Instructions",
                                        {reservationData: tripData}
                                    );
                                }}
                            />,
                            <ActionButton
                                text="View Listing"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Public Property",
                                        {rentalData: rentalData}
                                    );
                                }}
                            />,
                            <ActionButton
                                text="Reservation Details"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Reservation Details",
                                        {reservationData: tripData}
                                    );
                                }}
                            />,
                            <ActionButton
                                text="Message Host"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Message",
                                        {
                                            recipientUID: tripData.hostID,
                                            user: this.state.user,
                                        }
                                    );
                                }}
                            />
                        )
                        if (checkInDate.getTime() > today.getTime()) {
                            if (reservation.status == "awaiting check in") {
                                trip.push(
                                    <ActionButton
                                        text="Cancel Reservation"
                                        filled={true}
                                        handler={() => {
                                            this.props.navigation.getParent().setOptions({headerShown: false});
                                            this.props.navigation.navigate(
                                                "Cancel Reservation",
                                                {
                                                    rentalData: rentalData,
                                                    tripData: tripData
                                                }
                                            );
                                        }}
                                    />
                                );
                            }
                        }
                        parent.unshift(
                            <Container maxWidth={360}>
                                {trip}
                            </Container>
                        )
                    } else if ((reservation.status == "cancled by guest" || reservation.status == "canceled by host") && this.state.selectedFilter == "all" ){
                        let checkInString = getDateString(tripData.checkInDate)
                        let checkOutString = getDateString(tripData.checkOutDate)
                        trip.push(
                            <Text style={{fontWeight: "600", margin: 5, marginBottom: 0, fontSize: 16, color: "red"}}>Canceled</Text>,
                            <Text style={{fontWeight: "600", margin: 5, marginBottom: 10, fontSize: 16}}>{rentalData.title}</Text>,
                            <View style={styles.checkInOutWrapper}>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Arrive
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkInString}
                                    </Text>
                                </View>
                                <View style={styles.divider}/>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Depart
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkOutString}
                                    </Text>
                                </View>
                            </View>,
                            <Image 
                                style={styles.previewImage}
                                source={{
                                    uri: rentalData.photoUrls[0],
                                }}
                                resizeMode={"contain"}
                            />,
                            <ActionButton
                                text="View Listing"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Public Property",
                                        {rentalData: rentalData}
                                    );
                                }}
                            />,
                            <ActionButton
                                text="Reservation Details"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Reservation Details",
                                        {reservationData: tripData}
                                    );
                                }}
                            />,
                        )
                        parent.unshift(
                            <Container maxWidth={360}>
                                {trip}
                            </Container>
                        )
                    } else if ((this.state.selectedFilter == "all" || this.state.selectedFilter == "past") && reservation.status == "checked out") {
                        //past trips
                        let checkInString = getDateString(tripData.checkInDate)
                        let checkOutString = getDateString(tripData.checkOutDate)
                        trip.push(
                            // <Text style={{fontWeight: "600", margin: 5, marginBottom: 0, fontSize: 16, color: "red"}}>Canceled</Text>,
                            <Text style={{fontWeight: "600", margin: 5, marginBottom: 10, fontSize: 16}}>{rentalData.title}</Text>,
                            <View style={styles.checkInOutWrapper}>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Arrive
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkInString}
                                    </Text>
                                </View>
                                <View style={styles.divider}/>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Depart
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkOutString}
                                    </Text>
                                </View>
                            </View>,
                            <Image 
                                style={styles.previewImage}
                                source={{
                                    uri: rentalData.photoUrls[0],
                                }}
                                resizeMode={"contain"}
                            />,
                            <ActionButton
                                text="View Listing"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Public Property",
                                        {rentalData: rentalData}
                                    );
                                }}
                            />,
                            <ActionButton
                                text="Reservation Details"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Reservation Details",
                                        {reservationData: tripData}
                                    );
                                }}
                            />,
                        )
                        parent.push(
                            <Container maxWidth={360}>
                                {trip}
                            </Container>
                        )
                    } else if (this.state.selectedFilter == "all" && reservation.status == "checked in") {
                        //trips currently happening
                        let checkInString = getDateString(tripData.checkInDate)
                        let checkOutString = getDateString(tripData.checkOutDate)
                        trip.push(
                            // <Text style={{fontWeight: "600", margin: 5, marginBottom: 0, fontSize: 16, color: "red"}}>Canceled</Text>,
                            <Text style={{fontWeight: "600", margin: 5, marginBottom: 10, fontSize: 16}}>{rentalData.title}</Text>,
                            <View style={styles.checkInOutWrapper}>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Arrive
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkInString}
                                    </Text>
                                </View>
                                <View style={styles.divider}/>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Depart
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkOutString}
                                    </Text>
                                </View>
                            </View>,
                            <Image 
                                style={styles.previewImage}
                                source={{
                                    uri: rentalData.photoUrls[0],
                                }}
                                resizeMode={"contain"}
                            />,
                            <ActionButton
                            text="Check In Instructions"
                            handler={() => {
                                this.props.navigation.getParent().setOptions({headerShown: false});
                                this.props.navigation.navigate("My Check In Instructions",
                                    {reservationData: tripData}
                                );
                            }}
                        />,
                        <ActionButton
                            text="View Listing"
                            handler={() => {
                                this.props.navigation.getParent().setOptions({headerShown: false});
                                this.props.navigation.navigate("Public Property",
                                    {rentalData: rentalData}
                                );
                            }}
                        />,
                        <ActionButton
                            text="Reservation Details"
                            handler={() => {
                                this.props.navigation.getParent().setOptions({headerShown: false});
                                this.props.navigation.navigate("Reservation Details",
                                    {reservationData: tripData}
                                );
                            }}
                        />,
                        <ActionButton
                            text="Message Host"
                            handler={() => {
                                this.props.navigation.getParent().setOptions({headerShown: false});
                                this.props.navigation.navigate("Message",
                                    {
                                        recipientUID: tripData.hostID,
                                        user: this.state.user,
                                    }
                                );
                            }}
                        />
                        )
                        parent.push(
                            <Container maxWidth={360}>
                                {trip}
                            </Container>
                        )
                    } else if (reservation.status == "pending approval" && (this.state.selectedFilter == "all" || this.state.selectedFilter == "pending")) {
                        let checkInString = getDateString(tripData.checkInDate)
                        let checkOutString = getDateString(tripData.checkOutDate)
                        trip.push(
                            <Text style={{fontWeight: "600", margin: 5, marginBottom: 0, fontSize: 16, color: "blue"}}>Pending Approval</Text>,
                            <Text style={{fontWeight: "600", margin: 5, marginBottom: 10, fontSize: 16}}>{rentalData.title}</Text>,
                            <View style={styles.checkInOutWrapper}>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Arrive
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkInString}
                                    </Text>
                                </View>
                                <View style={styles.divider}/>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Depart
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkOutString}
                                    </Text>
                                </View>
                            </View>,
                            <Image 
                                style={styles.previewImage}
                                source={{
                                    uri: rentalData.photoUrls[0],
                                }}
                                resizeMode={"contain"}
                            />,
                            <ActionButton
                                text="View Listing"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Public Property",
                                        {rentalData: rentalData}
                                    );
                                }}
                            />,
                            <ActionButton
                                text="Reservation Details"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Reservation Details",
                                        {reservationData: tripData}
                                    );
                                }}
                            />,
                            <ActionButton
                            text="Message Host"
                            handler={() => {
                                this.props.navigation.getParent().setOptions({headerShown: false});
                                this.props.navigation.navigate("Message",
                                    {
                                        recipientUID: tripData.hostID,
                                        user: this.state.user,
                                    }
                                );
                            }}
                        />
                        )
                        let checkInDate = new Date(tripData.checkInDate);
                        if (checkInDate.getTime() > today.getTime()) {
                            trip.push(
                                <ActionButton
                                    text="Cancel Request"
                                    filled={true}
                                    handler={() => {
                                        this.props.navigation.getParent().setOptions({headerShown: false});
                                        this.props.navigation.navigate(
                                            "Cancel Reservation",
                                            {
                                                rentalData: rentalData,
                                                requestData: tripData
                                            }
                                        );
                                    }}
                                />
                            );
                        }
                        parent.unshift(
                            <Container maxWidth={360}>
                                {trip}
                            </Container>
                        )
                    }
                }
            }
        }

        if (this.state.pendingTripsData != null && this.state.rentalData != null) {
            for (let index in this.state.pendingTripsData) {
                let trip = [];
                let tripData = this.state.pendingTripsData[index];
                let rentalData = this.state.rentalData[tripData.postID];
                if (rentalData != null) {
                    if (this.state.selectedFilter == "all" || this.state.selectedFilter == "pending") {
                        let checkInString = getDateString(tripData.checkInDate)
                        let checkOutString = getDateString(tripData.checkOutDate)
                        let checkInDate = new Date(tripData.checkInDate)
                        //let today = new Date();
                        let tomorrow = new Date();
                        tomorrow.setHours(0,0,0,0);
                        tomorrow.setDate(tomorrow.getDate()+1);
                        if (tripData.status != "declined") {
                            if (checkInDate.getTime() < tomorrow.getTime()) {
                                trip.push(
                                    <Text style={{fontWeight: "600", margin: 5, marginBottom: 0, fontSize: 16, color: "red"}}>Expired Request</Text>,
                                );
                            } else {
                                trip.push(
                                    <Text style={{fontWeight: "600", margin: 5, marginBottom: 0, fontSize: 16, color: "blue"}}>Pending Approval</Text>,
                                );
                            } 
                        } else {
                            trip.push(
                                <Text style={{fontWeight: "600", margin: 5, marginBottom: 0, fontSize: 16, color: "red"}}>Host Declined</Text>,
                            );
                        }
                        trip.push(
                            <Text style={{fontWeight: "600", margin: 5, marginBottom: 10, fontSize: 16}}>{rentalData.title}</Text>,
                            <View style={styles.checkInOutWrapper}>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Arrive
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkInString}
                                    </Text>
                                </View>
                                <View style={styles.divider}/>
                                <View style={{width: "100%", textAlign: "center"}}>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        Depart
                                    </Text>
                                    <Text allowFontScaling={false} style={styles.label}>
                                        {checkOutString}
                                    </Text>
                                </View>
                            </View>,
                            <Image 
                                style={styles.previewImage}
                                source={{
                                    uri: rentalData.photoUrls[0],
                                }}
                                resizeMode={"contain"}
                            />,
                            <ActionButton
                                text="View Listing"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Public Property",
                                        {rentalData: rentalData}
                                    );
                                }}
                            />,
                            <ActionButton
                                text="Reservation Details"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Reservation Details",
                                        {reservationData: tripData}
                                    );
                                }}
                            />,
                            <ActionButton
                                text="Message Host"
                                handler={() => {
                                    this.props.navigation.getParent().setOptions({headerShown: false});
                                    this.props.navigation.navigate("Message",
                                        {
                                            recipientUID: tripData.hostID,
                                            user: this.state.user,
                                        }
                                    );
                                }}
                            />
                        );
                        if (checkInDate.getTime() > tomorrow.getTime() && tripData.status != "declined") {
                            trip.push(
                                <ActionButton
                                    text="Cancel Request"
                                    filled={true}
                                    handler={() => {
                                        this.props.navigation.getParent().setOptions({headerShown: false});
                                        this.props.navigation.navigate(
                                            "Cancel Reservation",
                                            {
                                                rentalData: rentalData,
                                                requestData: tripData
                                            }
                                        );
                                    }}
                                />
                            );
                        }
                        parent.unshift(
                            <Container maxWidth={360}>
                                {trip}
                            </Container>
                        )
                    }
                }
            }
        }


        let final = [
            <View style={styles.flexContainer}>
                {parent}
            </View>
        ];
        return final;
    }

    allSelected = () => {
        this.setState({selectedFilter: "all"});
    }

    pendingSelected = () => {
        this.setState({selectedFilter: "pending"});
    }

    upcomingSelected = () => {
        this.setState({selectedFilter: "upcoming"});
    }

    pastSelected = () => {
        this.setState({selectedFilter: "past"});
    }

    buildHeader = () => {
        let parent = [];
        switch (this.state.selectedFilter) {
            case "all":
                parent.push(
                    <View style={styles.header}>
                        <TouchableHighlight 
                            style={styles.selectedHeaderButton}
                            onPress={()=>{
                                this.allSelected();
                            }}
                        >
                            <Text style={styles.selectedHeaderButtonText}>All</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.pendingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Pending</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.upcomingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Upcoming</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.pastSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Past</Text>
                        </TouchableHighlight>
                    </View>
                )
                break;
            case "pending":
                parent.push(
                    <View style={styles.header}>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.allSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>All</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.selectedHeaderButton}
                            onPress={()=>{
                                this.pendingSelected();
                            }}
                        >
                            <Text style={styles.selectedHeaderButtonText}>Pending</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.upcomingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Upcoming</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.pastSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Past</Text>
                        </TouchableHighlight>
                    </View>
                )
                break;
            case "upcoming":
                parent.push(
                    <View style={styles.header}>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.allSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>All</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.pendingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Pending</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.selectedHeaderButton}
                            onPress={()=>{
                                this.upcomingSelected();
                            }}
                        >
                            <Text style={styles.selectedHeaderButtonText}>Upcoming</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.pastSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Past</Text>
                        </TouchableHighlight>
                    </View>
                )
                break;
            case "past":
                parent.push(
                    <View style={styles.header}>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.allSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>All</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.pendingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Pending</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.headerButton}
                            onPress={()=>{
                                this.upcomingSelected();
                            }}
                        >
                            <Text style={styles.headerButtonText}>Upcoming</Text>
                        </TouchableHighlight>
                        <TouchableHighlight 
                            style={styles.selectedHeaderButton}
                            onPress={()=>{
                                this.pastSelected();
                            }}
                        >
                            <Text style={styles.selectedHeaderButtonText}>Past</Text>
                        </TouchableHighlight>
                    </View>
                )
                break;
            default:
                break;
        }
       
        return parent;
    }

    render() {
        return (
            <View style={{height: "100%"}}>
                { this.buildHeader() }
                <ScrollView contentContainerStyle={styles.container}>
                    { this.buildView() }
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 60,
    },
    headerLabeltext: {
        marginTop: 10,
        fontWeight: "600",
        textAlign: "center",
    },
    label: {
        width: "100%",
        textAlign: "center",
    },
    divider: {
        backgroundColor: "#d3d3d3",
        width: 1,
        height: 40,
    },
    previewImage: {
        width: "100%",
        height: 300,
        margin: "auto",
        backgroundColor: "#ebecf0",
    },
    checkInOutWrapper: {
        width: "100%",
        maxWidth: 200,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        paddingBottom: 5,
    },
    flexContainer: {
        padding: 10,
        paddingHorizontal: 20,
        paddingTop: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        flexWrap: "wrap",
    },
    header: {
        position: "absolute",
        top: 0,
        height: 60,
        width: "100%",
        zIndex: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        borderBottomColor: "lightgrey",
        borderBottomWidth: 1,
        backgroundColor: "white",
    },
    headerButton: {
        width: "20%",
        height: 50,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        marginHorizontal: 3,
    },
    headerButtonText: {
        fontSize: 16,
        fontWeight: "600",
        // color: "#5d51ef"
    },
    selectedHeaderButton: {
        width: "20%",
        height: 50,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        borderBottomColor: "#5d51ef",
        borderBottomWidth: 5,
        marginHorizontal: 3,
    },
    selectedHeaderButtonText: {
        fontSize: 16,
        fontWeight: "600",
        color: "#5d51ef"
    },

})