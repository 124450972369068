import React, { useEffect, useState } from 'react';
import {TextInput, StyleSheet, View, TouchableHighlight, Text, Image, Platform} from 'react-native';

export default function StyledTextInput ({ 
    value, placeholder, onChange, title, secure, onBlur, defaultValue 
}) {
    const [passVisible, setPassVisible] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);
    const [inputKey, setInputKey] = useState(Math.random().toString(36).substring(7));

    useEffect(() => {
        if (!!value) {
            setCurrentValue(value);
        }
    });

    const togglePassInput = () => {
        setPassVisible(!passVisible);
    }

    const buildTopBar = () => {
        let parent = [];
        if (secure) {
            if (passVisible) {
                parent.push(
                    <View style={styles.topBarWrapper}>
                        <Text style={styles.placeholder}>{placeholder}</Text>
                        <TouchableHighlight 
                            style={styles.visibleIconButton}
                            onPress={() => togglePassInput()}>
                            <View style={{display: "flex", flexDirection: "row", justifyContent: "flex-end",}}>
                            <Text style={{color: "#5d51ef", fontWeight: "600"}}>Hide password</Text>
                                <Image
                                    style={styles.visibleIcon}
                                    source={require('../assets/closedEye.png')}
                                />
                            </View>
                        </TouchableHighlight>
                    </View>
                );
            } else {
                parent.push(
                    <View style={styles.topBarWrapper}>
                        <Text style={styles.placeholder}>{placeholder}</Text>
                        <TouchableHighlight 
                            style={styles.visibleIconButton}
                            onPress={() => togglePassInput()}>
                            <View style={{display: "flex", flexDirection: "row", justifyContent: "flex-end",}}>
                                <Text style={{color: "#5d51ef", fontWeight: "600"}}>Show password</Text>
                                <Image
                                    style={styles.visibleIcon}
                                    source={require('../assets/eye.png')}
                                />
                            </View>
                        </TouchableHighlight>
                    </View>
                );
            }
        } else {
            parent.push(
                <View style={styles.topBarWrapper}>
                    <Text style={styles.placeholder}>{placeholder}</Text>
                </View>
            );
        }
        return parent;
    }

    const buildView = () => {
        let parent = [];
        let xIconWrapper = {position: "absolute", zIndex: 200, marginTop: 10, right: 5}
        if (Platform.OS != "ios") {
            parent.push(
                <View style={{width: "100%"}}>
                    <TouchableHighlight
                        onPress={()=>{
                            setCurrentValue(null);
                            setInputKey(Math.random().toString(36).substring(7))
                            console.log("setting value to null")
                            if (!!onChange) {
                                onChange(null);
                            }
                        }}
                        style={xIconWrapper}
                    >
                        <Image
                            source={require('../assets/xIcon.png')}
                            style={styles.removeIconImage}
                        />
                    </TouchableHighlight>
                    <TextInput
                        style={styles.inputBox}
                        onChangeText={text => {
                            if (!!onChange) {
                                onChange(text);
                            }
                            setCurrentValue(text);
                        }}
                        placeholder={placeholder? placeholder: null}
                        placeholderTextColor='grey'
                        value={currentValue}
                        clearButtonMode="always"
                        secureTextEntry={secure && !passVisible? secure: false}
                        onBlur={()=>{
                            if (!!onBlur) {
                                onBlur(currentValue);
                            }
                        }}
                        defaultValue={defaultValue}
                        key={inputKey}
                    />
                </View>
            )
        } else {
            parent.push(
                <TextInput
                    style={styles.inputBox}
                    onChangeText={text => {
                        if (!!onChange) {
                            onChange(text);
                        }
                        setCurrentValue(text);
                    }}
                    placeholder={placeholder? placeholder: null}
                    placeholderTextColor='grey'
                    value={value? value: null}
                    clearButtonMode="always"
                    secureTextEntry={secure && !passVisible? secure: false}
                    onBlur={()=>{
                        if (!!onBlur) {
                            onBlur(currentValue);
                        }
                    }}
                    defaultValue={defaultValue}
                />
            );
        }

        return parent;
    }


    return (
      <View style={{width: "100%", maxWidth: 400, justifyContent: "center", alignItems: "center"}}>
       {buildTopBar()}
       {buildView()}
      </View>
    );
  }
const styles = StyleSheet.create({
    inputBox: {
        width: '100%',
        padding: 5,
        paddingHorizontal: 20,
        fontSize: 18,
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        backgroundColor: "#f5f5f5",
        height: 50,
    },
    visibleIcon: {
        height: 20,
        width: 20,
        marginHorizontal: 10,
        tintColor: "#5d51ef",
    },
    placeholder: {
        color: "grey",
        fontSize: 12,
    },
    topBarWrapper: {
        display: "flex",
        flexDirection: "row", 
        justifyContent: "space-between", 
        width: "100%", 
        alignItems: "flex-end"
    },
    removeIconImage: {
        height: 30,
        width:30,
        zIndex: 12,
      },
});