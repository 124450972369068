import React, { Component } from 'react';
import {Image, Text, View, Dimensions } from 'react-native';


export default class Footer extends Component {
  render() {
    if (this.props.isHome) {
      return (
        <View
          style={{ 
            width: "100%",
            alignItems: 'center',
            height: 80,
            justifyContent: 'center',
            position:"absolute",
            top: Dimensions.get('window').height - 160,
            zIndex: 11,
        }}/>
      );
    }
    return (
      <View
        style={{ 
          width: "100%",
          alignItems: 'center',
          height: 80,
          justifyContent: 'center',
      }}>
        {!!this.props.hideText ? 
            null : 
            <Text 
              allowFontScaling={false}
              style={{fontSize: 10}}>
                  A K Kay Moody company.
            </Text>
        }
      </View>
    );
  }
}