import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, TextInput, Image, Platform, View, Dimensions } from 'react-native';
import { Calendar } from 'react-native-calendars';
import { getDatabase, ref, get } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import Modal from "../compenents/Modal";
import Container from "../compenents/Container";
import CheckBox from '../compenents/CheckBox';
import Stepper from "../compenents/Stepper";
import ActionButton from '../compenents/ActionButton';
import { getDateString } from '../helpers/date-formatter';
import { TouchableHighlight } from 'react-native-gesture-handler';

export default class CreateReservation extends Component {

    constructor(props) {
        super(props);
        const todayDate = new Date();
        let month = todayDate.getUTCMonth()+1;
        let day = todayDate.getUTCDate();
        month >= 10 ? month = todayDate.getMonth()+1: month = `0${todayDate.getMonth()+1}`;
        day >= 10 ? day = todayDate.getUTCDate(): day = `0${todayDate.getUTCDate()}`;
        const todayDateString = todayDate.getUTCFullYear()+"-"+month +"-"+day
        this.state = {
            firstName: null,
            lastName: null,
            checkIn: null,
            checkOut: null,
            bringingPet: null,
            numberOfPeople: null,
            modalVisible: false,
            markedDates: null,
            selectedMonth: todayDateString,
            message: null,
            errorMessage: null,
            modalKey: Math.random().toString(36).substring(7),
            calendarKey: Math.random().toString(36).substring(7),
            bringingPetKey: Math.random().toString(36).substring(7),
            notBringingPetKey: Math.random().toString(36).substring(7),
            numberOfPeopleKey: Math.random().toString(36).substring(7),
            width: Dimensions.get("screen").width,
            physicianPrice: null,
            variablePhysicianPriceData: null,
            publicPrice: null,
            variablePublicPriceData: null,
        }
        this.bringingPetHandler = this.bringingPetHandler.bind(this);
        this.notBringingPetHandler = this.notBringingPetHandler.bind(this);
        //check if they have a renter profile
    }

    componentDidMount() {
        if (Platform.OS == "web") {
            window.addEventListener('resize', this.updateDimensions);
            this.setState({
                width: window.innerWidth,
            });
        }
        this.populateProfileData();
        this.populateCalendar();
        if (this.props.route.params.userIsDoc) {
            this.getPhysicianPriceData();
        }
        this.getPublicPriceData();
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.setState({
                    user: user,
                })
            } else {
                this.setState({
                    user: null,
                })
            }
        });
    }

    updateDimensions = () => {
        if (!!this.props.navigation) {
          if (this.props.navigation.isFocused()) {
            this.setState({
                width: window.innerWidth,
                calendarKey: Math.random().toString(36).substring(7),
              });
          }
        }
    };

    getPhysicianPriceData = () => {
        const db = getDatabase();
        const priceRef = ref(db, `physicianPrice/${this.props.route.params.rentalData.key}`);
        get(priceRef).then((snapshot) => {
            const physicianPriceData = snapshot.val();
            if (physicianPriceData !== null) {
                this.setState({physicianPrice: physicianPriceData.price});
            } else {
                this.setState({physicianPrice: null});
            }
        }).catch(error => {
            this.setState({physicianPrice: null});
        });
        if (!!this.props.route.params.rentalData.variablePhysicianPrice) {
            if (this.props.route.params.rentalData.variablePhysicianPrice) {
                const variablePriceRef = ref(db, `variablePhysicianPrice/${this.props.route.params.rentalData.key}`);
                get(variablePriceRef).then((snapshot) => {
                    const variablePhysicianPriceData = snapshot.val();
                    if (variablePhysicianPriceData !== null) {
                        this.setState({variablePhysicianPriceData: variablePhysicianPriceData});
                    } else {
                        this.setState({variablePhysicianPriceData: null});
                    }
                }).catch(error => {
                    this.setState({variablePhysicianPriceData: null});
                });
            }
        }
    }

    getPublicPriceData = () => {
        const db = getDatabase();
        const priceRef = ref(db, `publicPrice/${this.props.route.params.rentalData.key}`);
        get(priceRef).then((snapshot) => {
            const publicPriceData = snapshot.val();
            if (publicPriceData !== null) {
                this.setState({publicPrice: publicPriceData.price});
            } else {
                this.setState({publicPrice: null});
                this.getPhysicianPriceData();
            }
        }).catch(error => {
            this.setState({publicPrice: null});
        });
        if (!!this.props.route.params.rentalData.variablePublicPrice) {
            if (this.props.route.params.rentalData.variablePublicPrice) {
                const variablePriceRef = ref(db, `variablePublicPrice/${this.props.route.params.rentalData.key}`);
                get(variablePriceRef).then((snapshot) => {
                    const variablePublicPriceData = snapshot.val();
                    if (variablePublicPriceData !== null) {
                        this.setState({variablePublicPriceData: variablePublicPriceData});
                    } else {
                        this.setState({variablePublicPriceData: null});
                    }
                }).catch(error => {
                    this.setState({variablePublicPriceData: null});
                });
            }
        }
    }

    populateProfileData = () => {
        if (!!this.props.route.params.profileData) {
            this.setState({
                firstName: this.props.route.params.profileData.firstName,
                lastName: this.props.route.params.profileData.lastName,
                npiNumber: this.props.route.params.profileData.npi,
            })
        }
    }

    populateCalendar = () => {
        let blackedOutDates = {};       
        if (!!this.props.route.params.availabilityData) {
            for (let index in this.props.route.params.availabilityData) {
                let item = this.props.route.params.availabilityData[index];
                blackedOutDates[index] = item;
                blackedOutDates.disableTouchEvent = true;
            } 
        }             
        if (this.props.route.params.startDate != null && this.props.route.params.endDate == null) {
            let markedDates = {};
            markedDates[this.props.route.params.startDate] = {startingDay: true, endingDay: true, color: '#3a93f1', textColor: 'white'};
            let startDate = new Date(this.props.route.params.startDate);
            let selectedMonth = startDate.getUTCFullYear() +"/"+
                                (startDate.getUTCMonth()+1) +"/"+
                                startDate.getUTCDate()  
            this.setState({
                selectedMonth: selectedMonth,
                markedDates: {...markedDates, ...blackedOutDates},
                checkIn: this.props.route.params.startDate,
                checkOut: null,
                calendarKey: Math.random().toString(36).substring(7),
                blackedOutDates: blackedOutDates,
            });
        } else if (this.props.route.params.startDate != null && this.props.route.params.endDate != null) {
            let newMarkedDates = {};
            let startDate = new Date(this.props.route.params.startDate);
            let endDate = new Date(this.props.route.params.endDate);
            let datesAray = this.getDaysArray(startDate, endDate);
            let datesCount = datesAray.length;
            for (var key in datesAray) {
                let date = datesAray[key].toISOString().slice(0,10);
                if (key == 0) {
                    this.setState({
                        checkIn: date,
                    });
                    newMarkedDates[date] = {startingDay: true, color: '#3a93f1', textColor: 'white'};
                } else if (datesCount == (parseInt(key) + 1)) {
                    this.setState({
                        checkOut: date,
                    });
                    newMarkedDates[date] = {endingDay: true, color: '#3a93f1', textColor: 'white'};
                } else {
                    newMarkedDates[date] = {color: '#3a93f1', textColor: 'white'};
                }
            }
            let selectedMonth = startDate.getUTCFullYear() +"/"+
                                (startDate.getUTCMonth()+1) +"/"+
                                startDate.getUTCDate()
            this.setState({
                selectedMonth: selectedMonth,
                markedDates: {...newMarkedDates, ...blackedOutDates},
                calendarKey: Math.random().toString(36).substring(7),
                blackedOutDates: blackedOutDates,
            });
            
        } else if (this.props.route.params.startDate == null && this.props.route.params.endDate != null) {
            let markedDates = {};
            markedDates[this.props.route.params.endDate] = {startingDay: true, endingDay: true, color: '#3a93f1', textColor: 'white'};
            let endDate = new Date(this.props.route.params.endDate);
            let selectedMonth = endDate.getUTCFullYear() +"/"+
                                (endDate.getUTCMonth()+1) +"/"+
                                endDate.getUTCDate()
            this.setState({
                selectedMonth: selectedMonth,
                markedDates: {...newMarkedDates, ...blackedOutDates},
                checkIn: null,
                checkOut: this.props.route.params.endDate,
                calendarKey: Math.random().toString(36).substring(7),
                blackedOutDates: blackedOutDates,
            });
        } else {
            this.setState({
                markedDates: blackedOutDates,
                calendarKey: Math.random().toString(36).substring(7),
                blackedOutDates: blackedOutDates,
            });
        }
    }

    onModalClose = () => {
        this.setState({modalVisible: false});
    }

    bringingPetHandler(checked) {
        this.setState({ 
            bringingPet: checked,
            bringingPetKey: Math.random().toString(36).substring(7),
            notBringingPetKey: Math.random().toString(36).substring(7),
        });
    }

    notBringingPetHandler(checked) {
        this.setState({ 
            bringingPet: !checked,
            bringingPetKey: Math.random().toString(36).substring(7),
            notBringingPetKey: Math.random().toString(36).substring(7),
        });
    }

    onDayPress = (day) => {
        if (this.state.blackedOutDates[day.dateString] == null) {
            if (this.state.checkIn == null) {
                let markedDates = {};
                markedDates[day.dateString] = {startingDay: true, endingDay: true, color: '#3a93f1', textColor: 'white'};
                this.setState({
                    markedDates: {...markedDates, ...this.state.blackedOutDates},
                    checkIn: day.dateString,
                    checkOut: null,
                })
            } else {
                if (this.state.checkIn != null && this.state.checkOut != null) {
                    let markedDates = {};
                    markedDates[day.dateString] = {startingDay: true, endingDay: true, color: '#3a93f1', textColor: 'white'};
                    this.setState({
                        markedDates: {...markedDates, ...this.state.blackedOutDates},
                        checkIn: day.dateString,
                        checkOut: null,
                    });
                } else {
                    let newMarkedDates = {};
                    let startDate = new Date(this.state.checkIn);
                    let endDate = new Date(day.dateString);
                    let datesAray = [];
                    if (startDate.getTime() > endDate.getTime()) {
                        datesAray = this.getDaysArray(endDate, startDate);
                    } else {
                        datesAray = this.getDaysArray(startDate, endDate);
                    }
                    let datesCount = datesAray.length;
                    let selectionContainsReservedDay = false;
                    for (var key in datesAray) {
                        let date = datesAray[key].toISOString().slice(0,10);
                        if (this.state.blackedOutDates[date] != null) {
                            selectionContainsReservedDay = true;
                        } 
                    }
                    if (selectionContainsReservedDay) {
                        let markedDates = {};
                        markedDates[day.dateString] = {startingDay: true, endingDay: true, color: '#3a93f1', textColor: 'white'};
                        this.setState({
                            markedDates: {...markedDates, ...this.state.blackedOutDates},
                            checkIn: day.dateString,
                            checkOut: null,
                        });
                    } else {
                        for (let index in datesAray) {
                            let date = datesAray[index].toISOString().slice(0,10);
                            if (index == 0) {
                                this.setState({
                                    checkIn: date,
                                });
                                newMarkedDates[date] = {startingDay: true, color: '#3a93f1', textColor: 'white'};
                            } else if (datesCount == (parseInt(index) + 1)) {
                                this.setState({
                                    checkOut: date,
                                });
                                newMarkedDates[date] = {endingDay: true, color: '#3a93f1', textColor: 'white'};
                            } else {
                                newMarkedDates[date] = {color: '#3a93f1', textColor: 'white'};
                            }
                        }
                        this.setState({
                            markedDates: {...newMarkedDates, ...this.state.blackedOutDates,},
                        });
                    }
                }
            }
        }
    }

    getDaysArray = (s,e) => {
        for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;
    };

    createMinNightLabel = () => {
        let parent = [];
        if (this.props.route.params.rentalData.minStay != undefined &&
            this.props.route.params.rentalData.minStay) {
            if (this.props.route.params.rentalData.minDays != undefined) {
                parent.push(
                    <Text 
                        allowFontScaling={false} 
                        style={styles.headerLabeltext}
                    >
                        Minimum Nights: {this.props.route.params.rentalData.minDays}
                    </Text>
                )
            }
        }
        return parent;
    }

    createCheckInOutLabel = () => {
        let parent = [];
        if (this.state.checkIn != null) {
            //let checkInDate = new Date(this.state.checkIn);
           let checkInDateString = getDateString(this.state.checkIn);
            parent.push(
                <View style={{width: "100%", textAlign: "center"}}>
                    <Text allowFontScaling={false} style={styles.label}>
                        Arrive
                    </Text> 
                    <Text style={styles.label}>
                        {checkInDateString}
                    </Text>
                </View>,
                <View style={styles.divider}/>
            );
        } else {
            parent.push(
                <View style={{width: "100%", textAlign: "center"}}>
                    <Text allowFontScaling={false} style={styles.label}>
                        Arrive
                    </Text>
                    <Text allowFontScaling={false} style={styles.label}>
                        -/-
                    </Text>
                </View>,
                 <View style={styles.divider}/>
            );
        }
        if (this.state.checkOut != null) {
            let checkOutDateString = getDateString(this.state.checkOut);
            parent.push(
                <View style={{width: "100%", textAlign: "center"}}>
                    <Text allowFontScaling={false} style={styles.label}>
                        Depart
                    </Text>
                    <Text allowFontScaling={false} style={styles.label}>
                        {checkOutDateString}
                    </Text>
                </View>
            );
        } else {
            parent.push(
                <View style={{width: "100%", textAlign: "center"}}>
                    <Text allowFontScaling={false} style={styles.label}>
                        Depart
                    </Text>
                    <Text allowFontScaling={false} style={styles.label}>
                        -/-
                    </Text>
                </View>
            );
        }
        let final = [
            <View style={{width: "100%", maxWidth: 200, display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
                {parent}
            </View>
        ]
        // if (this.state.checkIn != null && this.state.checkOut != null) {
        //     let checkInDate = new Date(this.state.checkIn);
        //     let checkOutDate = new Date(this.state.checkOut);
        //     let numberOfNights = this.getDaysArray(checkInDate, checkOutDate).length;
        //     final.push(
        //         <Text style={{fontSize:16,  overflow: "visible", zIndex: 10}}>x{numberOfNights} night(s)</Text>
        //     )
        // }   
        return final;
    }

    continuePress = async () => {
        let reservationData = {}
        let allValid = true;
        let message = "";
        if (this.state.firstName != null && this.state.firstName != "") {
            reservationData.firstName = this.state.firstName;
        } else {
            allValid = false;
            message = "Please enter a first name";
        }
        if (this.state.lastName != null && this.state.lastName != "") {
            reservationData.lastName = this.state.lastName;
        } else {
            allValid = false;
            message = "Please enter a last name";
        }
        if (this.state.numberOfPeople != null && this.state.numberOfPeople != 0) {
            reservationData.numberOfPeople = this.state.numberOfPeople;
        } else {
            allValid = false;  
            message = "Please enter the number of people that will be staying";      
        }
        if (this.state.checkIn != null && this.state.checkOut != null) {
            if (this.props.route.params.rentalData.minStay) {
                if (this.props.route.params.rentalData.minDays != undefined) {
                    let startDate = new Date(this.state.checkIn);
                    let endDate = new Date(this.state.checkOut);
                    let datesAray = this.getDaysArray(startDate, endDate);
                    let datesCount = datesAray.length - 1;
                    if (datesCount >= this.props.route.params.rentalData.minDays) {
                        reservationData.checkIn = this.state.checkIn;
                        reservationData.checkOut = this.state.checkOut;
                    } else {
                        allValid = false;
                        message = `You have selected ${datesCount} night(s) but the minimum is ${this.props.route.params.rentalData.minDays}`;
                    }
                } else {
                    reservationData.checkIn = this.state.checkIn;
                    reservationData.checkOut = this.state.checkOut;
                }
            } else {
                reservationData.checkIn = this.state.checkIn;
                reservationData.checkOut = this.state.checkOut;
            }
        } else {
            allValid = false;
            message = "Please select a check in and out date";
        }
        if (this.state.npiNumber != null && this.state.npiNumber != "") {
            reservationData.npiNumber = this.state.npiNumber;
        } else {
            if (this.props.route.params.rentalData.requireNPI) {
                allValid = false;
                message = "Please enter your NPI number";
            }
        }
        if (this.state.bringingPet != null ) {
            reservationData.bringingPet = this.state.bringingPet;
        } else {
            if (this.props.route.params.rentalData.petFriendly) {
                allValid = false;
                message = "Please select if you are bringing a pet";
            } 
        }
        if (this.state.message != null && this.state.message != "") {
            reservationData.message = this.state.message;
        } else {
            allValid = false;
            message = "Please enter a message to the host";
        }

        if (allValid) {
            const db = getDatabase();
            const stripeCustomerIDRef = ref(db, `stripeCustomerID/${this.state.user.uid}`);
            let snapshot = await get(stripeCustomerIDRef);
            let customerData = snapshot.val();
            if (!!customerData) {
               this.props.navigation.navigate("Review Reservation", {
                   reservationData: reservationData,
                   rentalData: this.props.route.params.rentalData,
                   customerID: customerData.stripeID,
                   paymentSource: customerData.paymentSource,
               })
            } else {
               this.props.navigation.navigate("Payment Method", {
                   reservationData: reservationData,
                   rentalData: this.props.route.params.rentalData,
               })
            }
        } else {
            this.setState({
                modalVisible: true,
                modalKey: Math.random().toString(36).substring(7),
                errorMessage: message,
            })
        }
    }

    buildPetCheckBoxes = () => {
        let parent = [];
        if (this.props.route.params.rentalData.petFriendly) {
            let noChecked = false;
            if (this.state.bringingPet != null) {
                noChecked = !this.state.bringingPet;
            }
            parent.push(
                <Container>
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>Bringing a pet</Text>
                    <Text allowFontScaling={false} >Will you be bringing a pet?</Text>
                    <CheckBox 
                        label={"Yes"}
                        checked={this.state.bringingPet}
                        key={this.state.bringingPetKey}
                        handler={this.bringingPetHandler}
                    />
                    <CheckBox 
                        label={"No"}
                        checked={noChecked}
                        key={this.state.notBringingPetKey}
                        handler={this.notBringingPetHandler}
                    />
                </Container>
            )
        }
        return parent;
    }

    render() {
        let calendarWidth = this.state.width - 30;
        const todayDate = new Date();
        let month = todayDate.getUTCMonth()+1;
        let day = todayDate.getUTCDate();
        month >= 10 ? month = todayDate.getMonth()+1: month = `0${todayDate.getMonth()+1}`;
        day >= 10 ? day = todayDate.getUTCDate(): day = `0${todayDate.getUTCDate()}`;
        const todayDateString = todayDate.getUTCFullYear()+"-"+month +"-"+day
        return (
            <KeyboardAwareScrollView contentContainerStyle={styles.container}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.modalVisible}
                    key={this.state.modalKey}
                    onModalClose={this.onModalClose}
                    isError={true}
                    labelText={this.state.errorMessage}
                />
                <Container>
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>First and Last Name</Text>
                    <TextInput
                        style={styles.inputBox}
                        onChangeText={name => {
                            this.setState({ firstName: name});
                        }}
                        placeholder='First name'
                        placeholderTextColor='black'
                        value={this.state.firstName}
                    />
                     <TextInput
                        style={styles.inputBox}
                        onChangeText={name => {
                            this.setState({ lastName: name});
                        }}
                        placeholder='Last name'
                        placeholderTextColor='black'
                        value={this.state.lastName}
                    />
                </Container>
                <Container>
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>Check In/ Out</Text>
                    { this.createMinNightLabel() }
                    { this.createCheckInOutLabel() }
                    <Calendar
                        horizontal={true}
                        style={[styles.calendar, {width: calendarWidth}]}
                        markedDates={this.state.markedDates}
                        key={this.state.calendarKey}
                        // onDayPress={(day) => this.onDayPress(day)}
                        minDate={todayDateString}
                        enableSwipeMonths={true}
                        markingType={"period"}
                        disableAllTouchEventsForDisabledDays={true}
                        renderArrow={(direction) => direction === 'left' ? 
                            <Image
                                source={require('../assets/backArrowIcon.png')}
                                style={styles.leftIcon}
                            ></Image> : 
                            <Image
                                source={require('../assets/forwardArrowIcon.png')}
                                style={styles.rightIcon}
                            ></Image>
                        }
                        onMonthChange={(month) => {
                            this.setState({selectedMonth: month.dateString});
                        }}
                        current={this.state.selectedMonth}
                        dayComponent={({date, state, marking}) => {
                            let color = "black";
                            let parent = [];
                            let price = "";
                            if (!!this.state.variablePhysicianPriceData) {
                                if (!!this.state.variablePhysicianPriceData[date.dateString]) {
                                    price = this.state.variablePhysicianPriceData[date.dateString];
                                }
                            } else if (!!this.state.variablePublicPriceData)  {
                                if (!!this.state.variablePublicPriceData[date.dateString]) {
                                    price = this.state.variablePublicPriceData[date.dateString];
                                }
                            }
                            if (price == "") {
                                if (!!this.state.physicianPrice) {
                                    price = this.state.physicianPrice;
                                } else if (!!this.state.publicPrice) {
                                    price = this.state.publicPrice;
                                }
                            }
                            if (state != "disabled") {
                                if (!!this.state.blackedOutDates && this.state.blackedOutDates[date.dateString] == null) {
                                    if (!!marking && !!marking.color) {
                                        if (marking.startingDay && marking.endingDay) {
                                            parent.push(
                                                <View style={{backgroundColor: "#ab5cf7", width: "100%",  height: 40, borderRadius: 20, alignContent: "center"}}>
                                                    <TouchableHighlight
                                                        onPress={()=>{
                                                            this.onDayPress(date)
                                                        }}
                                                    >
                                                        <View style={{width: "100%", height: 40, borderRadius: 20, paddingTop: 6}}>
                                                            <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>{date.day}</Text>
                                                            <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>${price}</Text>
                                                            {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                                        </View>
                                                    </TouchableHighlight>
                                                </View>
                                            )
                                        } else if (marking.startingDay) {
                                            parent.push(
                                                <View style={{backgroundColor: "#ab5cf7", width: "100%",  height: 40, borderTopLeftRadius: 20, borderBottomLeftRadius: 20, alignContent: "center"}}>
                                                    <TouchableHighlight
                                                        onPress={()=>{
                                                            this.onDayPress(date)
                                                        }}
                                                    >
                                                        <View style={{width: "100%", height: 40, paddingTop: 6}}>
                                                            <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>{date.day}</Text>
                                                            <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>${price}</Text>
                                                            {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                                        </View>
                                                    </TouchableHighlight>
                                                </View>
                                            )
                                        } else if (marking.endingDay) {
                                            parent.push(
                                                <View style={{backgroundColor: "#53a4e8", width: "100%",  height: 40, borderTopRightRadius: 20, borderBottomRightRadius: 20}}>
                                                    <TouchableHighlight
                                                        onPress={()=>{
                                                            this.onDayPress(date)
                                                        }}
                                                    >
                                                        <View style={{ width: "100%", height: 40, paddingTop: 6}}>
                                                            <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>{date.day}</Text>
                                                            <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>${price}</Text>
                                                            {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                                        </View>
                                                    </TouchableHighlight>
                                                </View>
                                            )
                                        } else {
                                            parent.push(
                                                <View style={{backgroundColor: "#5d51ef", width: "100%", alignContent: "center"}}>
                                                    <TouchableHighlight
                                                        onPress={()=>{
                                                            this.onDayPress(date)
                                                        }}
                                                    >
                                                        <View style={{width: "100%", height: 40, paddingTop: 6}}>
                                                            <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>{date.day}</Text>
                                                            <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>${price}</Text>
                                                            {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                                        </View>
                                                    </TouchableHighlight>
                                                </View>
                                            )
                                        }
                                    } else {
                                        parent.push(
                                            <View>
                                                <TouchableHighlight
                                                    onPress={()=>{
                                                        this.onDayPress(date)
                                                    }}
                                                >
                                                     <View style={{width: "100%", height: 40}}>
                                                        <Text style={{textAlign: 'center', color: color, fontWeight: "600"}}>{date.day}</Text>
                                                        <Text style={{textAlign: 'center', color: color, fontWeight: "600"}}>${price}</Text>
                                                        {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                                    </View>
                                                </TouchableHighlight>
                                            </View>
                                        )
                                    }
                                } else {
                                    parent.push(
                                        <View>
                                            <View style={{width: 40, height: 40}}>
                                                <Text style={{textAlign: 'center', color: "grey", textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{date.day}</Text>
                                                {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                            </View>
                                        </View>
                                    )
                                }
                            } else {
                                let today = new Date();
                                let dateMonth = new Date(date.dateString);
                                if (today.getTime() < dateMonth.getTime()) {
                                    if (!!this.state.blackedOutDates && this.state.blackedOutDates[date.dateString] == null) {
                                        if (!!marking && !!marking.color) {
                                            if (marking.startingDay && marking.endingDay) {
                                                parent.push(
                                                    <View style={{backgroundColor: "#ab5cf7", width: "100%",  height: 40, borderRadius: 20, alignContent: "center"}}>
                                                        <TouchableHighlight
                                                            onPress={()=>{
                                                                this.onDayPress(date)
                                                            }}
                                                        >
                                                            <View style={{width: "100%", height: 40, borderRadius: 20, paddingTop: 6}}>
                                                                <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>{date.day}</Text>
                                                                <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>${price}</Text>
                                                                {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                                            </View>
                                                        </TouchableHighlight>
                                                    </View>
                                                )
                                            } else if (marking.startingDay) {
                                                parent.push(
                                                    <View style={{backgroundColor: "#ab5cf7", width: "100%",  height: 40, borderTopLeftRadius: 20, borderBottomLeftRadius: 20, alignContent: "center"}}>
                                                        <TouchableHighlight
                                                            onPress={()=>{
                                                                this.onDayPress(date)
                                                            }}
                                                        >
                                                            <View style={{width: "100%", height: 40, paddingTop: 6}}>
                                                                <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>{date.day}</Text>
                                                                <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>${price}</Text>
                                                                {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                                            </View>
                                                        </TouchableHighlight>
                                                    </View>
                                                )
                                            } else if (marking.endingDay) {
                                                parent.push(
                                                    <View style={{backgroundColor: "#53a4e8", width: "100%",  height: 40, borderTopRightRadius: 20, borderBottomRightRadius: 20}}>
                                                        <TouchableHighlight
                                                            onPress={()=>{
                                                                this.onDayPress(date)
                                                            }}
                                                        >
                                                            <View style={{ width: "100%",  paddingTop: 6}}>
                                                                <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>{date.day}</Text>
                                                                <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>${price}</Text>
                                                                {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                                            </View>
                                                        </TouchableHighlight>
                                                    </View>
                                                )
                                            } else {
                                                parent.push(
                                                    <View style={{backgroundColor: "#5d51ef", width: "100%", alignContent: "center"}}>
                                                        <TouchableHighlight
                                                            onPress={()=>{
                                                                this.onDayPress(date)
                                                            }}
                                                        >
                                                            <View style={{width: "100%",  height: 40, paddingTop: 6}}>
                                                                <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>{date.day}</Text>
                                                                <Text style={{textAlign: 'center', color: marking.textColor, fontWeight: "600"}}>${price}</Text>
                                                                {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                                            </View>
                                                        </TouchableHighlight>
                                                    </View>
                                                )
                                            }
                                        } else {
                                            parent.push(
                                                <View>
                                                    <TouchableHighlight
                                                        onPress={()=>{
                                                            this.onDayPress(date)
                                                        }}
                                                    >
                                                         <View style={{width: 40, height: 40}}>
                                                            <Text style={{textAlign: 'center', color: color, fontWeight: "600"}}>{date.day}</Text>
                                                            <Text style={{textAlign: 'center', color: color, fontWeight: "600"}}>${price}</Text>
                                                            {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                                        </View>
                                                    </TouchableHighlight>
                                                </View>
                                            )
                                        }
                                    } else {
                                        parent.push(
                                            <View>
                                                <View style={{width: 40, height: 40}}>
                                                    <Text style={{textAlign: 'center', color: "grey", textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{date.day}</Text>
                                                    {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                                </View>
                                            </View>
                                        )
                                    }
                                } else {
                                    parent.push(
                                        <View>
                                            <View style={{width: 40, height: 40}}>
                                                <Text style={{textAlign: 'center', color: "grey", textDecorationLine: 'line-through', textDecorationStyle: 'solid'}}>{date.day}</Text>
                                                {/* <Text style={{textAlign: 'center', color: color}}>{color === 'grey' || price == "" ? '' : `$${price}`}</Text> */}
                                            </View>
                                        </View>
                                    )
                                }
                            }
                            return (parent);
                        }}
                    />
                </Container>
                { this.buildPetCheckBoxes() }
                {
                    this.props.route.params.rentalData.requestNPI ?
                    <Container>
                        <Text allowFontScaling={false} style={styles.headerLabeltext}>NPI Number (Optional)</Text>
                        <Text allowFontScaling={false}>The host requests all renters provide their NPI</Text>
                        <TextInput
                            style={styles.inputBox}
                            onChangeText={message => {
                                this.setState({ npiNumber: message});
                            }}
                            placeholder='NPI Number'
                            placeholderTextColor='black'
                            keyboardType='numeric'
                            value={this.state.npiNumber}
                        />
                    </Container>:null
                }
                {
                    this.props.route.params.rentalData.requireNPI ?
                    <Container>
                        <Text allowFontScaling={false} style={styles.headerLabeltext}>NPI Number</Text>
                        <Text allowFontScaling={false}>The host requires all renters provide their NPI</Text>
                        <TextInput
                            style={styles.inputBox}
                            onChangeText={message => {
                                this.setState({ npiNumber: message});
                            }}
                            placeholder='NPI Number'
                            placeholderTextColor='black'
                            keyboardType='numeric'
                            value={this.state.npiNumber}
                        />
                    </Container>:null
                }
                <Container>
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>Number of People</Text>
                    <Text allowFontScaling={false} >How many people will be staying the night?</Text>
                    <Stepper
                        count={this.state.numberOfPeople}
                        key={this.state.numberOfPeopleKey}
                        handler={people => {
                            this.setState({ 
                                numberOfPeople: people,
                                numberOfPeopleKey: Math.random().toString(36).substring(7),
                            });
                        }}
                    />
                </Container>
                <Container>
                    <Text allowFontScaling={false} style={styles.headerLabeltext}>Message to Host</Text>
                    <Text allowFontScaling={false} >Tell the host a little bit about yourself and your trip</Text>
                    <TextInput
                        style={[styles.inputBox, styles.multilineBox]}
                        onChangeText={message => {
                            this.setState({ message: message});
                        }}
                        placeholder='Message to host'
                        placeholderTextColor='black'
                        multiline = {true}
                        numberOfLines={4}
                        value={this.state.message}
                    />
                    <ActionButton 
                        text={"Continue"}
                        handler={()=>{
                            this.continuePress()
                        }}
                    />
                </Container>
            </KeyboardAwareScrollView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerLabeltext: {
        marginBottom: 10,
        fontWeight: "600",
        textAlign: "center",
    },
    mt50: {
        marginTop: 50,
    },
    centeredText: {
        textAlign: "center",
        marginBottom: 30,
    },
    button: {
        marginTop: 10,
        marginBottom: 10,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
        height: 36,
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    inputBox: {
        width: '85%',
        maxWidth: 1000,
        marginBottom: 10,
        padding: 15,
        fontSize: 14,
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        textAlign: 'center',
        backgroundColor: "#f5f5f5",
    },
    calendar: {

        marginTop: 10,
        maxWidth: 600,
        width: "100%",
    },
    leftIcon: {
        width:40,
        height:40,
    },
    rightIcon: {
        width:40,
        height:40,
    },
    label: {
        width: "100%",
        textAlign: "center",
    },
    divider: {
        backgroundColor: "#d3d3d3",
        width: 1,
        height: 40,
    },
    multilineBox: {
        minHeight: 80,  
    },
})