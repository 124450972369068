import React, { Component } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { getAuth, signOut } from "firebase/auth";

import Container from "../compenents/Container";
import ActionButton from '../compenents/ActionButton';

export default class SignOut extends Component {

    handleLogOut = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            this.props.navigation.navigate('Home');
        }).catch((error) => {
            console.log(error)
        });
    }

    handleCancel = () => {
        this.props.navigation.navigate('Home');
    }

    render() {
        return (
            <View style={styles.container}>
                <Container>
                <Text  allowFontScaling={false} style={styles.headerLabeltext}>Sign out</Text>
                <Text allowFontScaling={false}>Would you like to sign out?</Text>
                    <ActionButton 
                        text={"Yes"}
                        handler={()=>{
                            this.handleLogOut();
                        }}
                    />
                    <ActionButton 
                        text={"Cancel"}
                        handler={()=>{
                            this.handleCancel();
                        }}
                        filled={true}
                    />
                </Container>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    button: {
        marginTop: 20,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    headerLabeltext: {
        fontSize: 24,
        fontWeight: "700",
        textAlign: "center",
        marginBottom: 20,
    },
})