import React,  { useState, useEffect, } from 'react';
import * as WebBrowser from 'expo-web-browser';
import * as Facebook from 'expo-auth-session/providers/facebook';
import { ResponseType } from 'expo-auth-session';
import { getAuth, FacebookAuthProvider, signInWithCredential,
    linkWithCredential, onAuthStateChanged, 
    signInWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';
import { StyleSheet, TouchableHighlight, Image, Text, 
    View, Dimensions, ActivityIndicator, Platform } from 'react-native';
    import { getFunctions, httpsCallable } from "firebase/functions";
// import { ShareApi } from 'react-native-fbsdk-next';

import { userIsPhysician } from '../helpers/public-user-helper';

// import BottomPage from './BottomPage';
import StyledTextInput from './StyledTextInput';
import ActionButton from './ActionButton.native';
import CheckBox from './CheckBox';

//this might need to go to app.js
WebBrowser.maybeCompleteAuthSession();

export default function FbSignUpButton(props) {

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [userPassword, setUserPassword] = useState(null);
    const [facebookEmail, setFacebookEmail] = useState(null);
    const [request, response, promptAsync] = Facebook.useAuthRequest({
        responseType: ResponseType.Token,
        clientId: "786377083058447",
        expoClientId: "786377083058447",
        iosClientId: "786377083058447",
        androidClientId: "786377083058447",
        webClientId: "786377083058447",
        scopes: [
            'public_profile',
            'email',
            // 'publish_to_groups',
            // 'groups_access_member_info',
        ],
    });
    const [bottomSheetIndex, setBottomSheetIndex] = useState(-1);
    const [bottomSheetType, setBottomSheetType] = useState(null);
    const [fbCredentials, setCrendental] = useState(null);
    const [yesChecked, setYesChecked] = useState(false);
    const [noChecked, setNoChecked] = useState(true);
    const [fullName, setFullname] = useState(null);
    const [npi, setNPI] = useState(null);
    const [medSchool, setMedSchool] = useState(null);
    const [currentPractice, setCurrentPractice] = useState(null);
    const [passwordErrorShowing, passwordErrorIsShowing] = useState(false);
    const [fullNameErrorVisible, setFullNameErrorVisible] = useState(false);
    const [npiErrorVisible, setNpiErrorVisible] = useState(false);
    const [medSchoolErrorVisible, setMedSchoolErrorVisible] = useState(false);
    const [currentPractiveErrorVisible, setCurrentPractiveErrorVisible] = useState(false);
    const [bottomSheetLoading, setBottomSheetLoading] = useState(false);

    useEffect(() => {
        getUser();
        if (response?.type === 'success') {
            const { access_token } = response.params;
            const auth = getAuth();
            const credential = FacebookAuthProvider.credential(access_token);
            setCrendental(credential);
            setLoading(true);
            if (!!user) {
                if (!user.isAnonymous) {
                    (async () => {
                        let fbEmail = await getUserEmailFromFB(access_token);
                        setFacebookEmail(fbEmail);
                        let inGroup = await userIsInGroup(access_token);
                        if (inGroup) {
                            let userCred = await linkWithCredential(user, credential);
                            setUser(userCred.user);
                            setLoading(false);
                        } else {
                            let isPhysician = await userIsPhysician(user.uid);
                            if (isPhysician) {
                                let userCred = await linkWithCredential(user, credential);
                                setUser(userCred.user);
                                setLoading(false);
                            } else {
                                setBottomSheetType("not in group");
                                setBottomSheetIndex(2);
                            }
                        }
                        setLoading(false);
                    })();
                } else {
                    (async () => {
                        let fbEmail = await getUserEmailFromFB(access_token);
                        setFacebookEmail(fbEmail);
                        let inGroup = await userIsInGroup(access_token);
                        if (inGroup) {
                            try {
                                let usercred = await signInWithCredential(auth, credential)
                                const user = usercred.user;
                                setUser(user);
                                setLoading(false);
                            } catch (error) {
                                if (error.message.indexOf("account-exists-with-different-credential") > -1) {
                                    setBottomSheetType("password");
                                    setBottomSheetIndex(2);
                                }
                            }
                        } else {
                            let signInMethods = await fetchSignInMethodsForEmail(auth, fbEmail);
                            if (signInMethods.length > 0) {
                                try {
                                    let usercred = await signInWithCredential(auth, credential)
                                    const user = usercred.user;
                                    setUser(user);
                                    setLoading(false);
                                } catch (error) {
                                    if (error.message.indexOf("account-exists-with-different-credential") > -1) {
                                        setBottomSheetType("password");
                                        setBottomSheetIndex(2);
                                    }
                                }
                            } else {
                                setBottomSheetType("not in group");
                                setBottomSheetIndex(2);
                            }
                        }
                    })();
                }
            } else {
                (async () => {
                    let inGroup = await userIsInGroup(access_token);
                    if (inGroup) {
                        let userCred = await signInWithCredential(auth, credential);
                        setUser(userCred.user);
                        setLoading(false);
                    } else {
                        setBottomSheetType("not in group");
                        setBottomSheetIndex(2);
                    }
                })();
            }
        }
    }, [response]);

    const getUser = () => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUser(user);
            }
        });
    }

    const getUserEmailFromFB = async (token) => {
        let url = `https://graph.facebook.com/me?fields=email&access_token=${token}`;
        try {
            const response = await fetch(url);
            const graphResponse = await response.json();
            return graphResponse.email;
        } catch(error) {
            console.log(error);
        }
    }

    const userIsInGroup = async (token) => {
        let url = `https://graph.facebook.com/me/groups?access_token=${token}`;
        try {
            const response = await fetch(url);
            const graphResponse = await response.json();
            let group = graphResponse.data.map(element => {
                return element.name == "Doc to Doc Rentals and Vacation Homes";
            });
            return group.length > 0;
        } catch(error) {
            console.log(error);
        }
    }

    const submitPassword = async () => {
        setBottomSheetLoading(true);
        const auth = getAuth();
        try {
            let userCredentials = await signInWithEmailAndPassword(auth, facebookEmail, userPassword)
            let userCred = await linkWithCredential(userCredentials.user, fbCredentials);
            setUser(userCred.user);
            setBottomSheetLoading(false);
        } catch (error) {
            console.log(error);
            passwordErrorIsShowing(true);
            setBottomSheetLoading(false);
        }
    }

    const validateAndSubmit = async () => {
        if (yesChecked) {
            let valid = true;
            if (fullName == null || fullName.trim() == "") {
                valid = false;
                setFullNameErrorVisible(true);
            }
            if (npi == null || npi.trim() == "") {
                valid = false;
                setNpiErrorVisible(true);
            }
            if (medSchool == null || medSchool.trim() == "") {
                valid = false;
                setMedSchoolErrorVisible(true);
            }
            if (currentPractice == null || currentPractice.trim() == "") {
                valid = false;
                setCurrentPractiveErrorVisible(true);
            }
            if (valid) {
                //sign in as public user and navigate home with first open flag as true
                try {
                    setBottomSheetLoading(true);
                    const auth = getAuth();
                    let usercred = await signInWithCredential(auth, fbCredentials)
                    const user = usercred.user;
                    setUser(user);
                    setLoading(false);
                    sendDoctorRequest(user);
                    requestNewSignUpEmail(facebookEmail, false, user.uid)
                    setBottomSheetLoading(false);
                } catch (error) {
                    console.log(error);
                    setBottomSheetLoading(false);
                }
            }
        } else if (noChecked) {
            //sign in as public user and navigate home with first open flag as true
            try {
                setBottomSheetLoading(true);
                const auth = getAuth();
                let usercred = await signInWithCredential(auth, fbCredentials)
                const user = usercred.user;
                setUser(user);
                setLoading(false);
                requestNewSignUpEmail(facebookEmail, false, user.uid)
                setBottomSheetLoading(false);
            } catch (error) {
                console.log(error);
            }
        }
    }

    const sendDoctorRequest = async (user) => {
        const functions = getFunctions();
        const requestPhysicianStatus = httpsCallable(functions, 'requestPhysicianStatus');
        let requestData = {
            user: user.uid,
            fullName,
            npi,
            medSchool,
            currentPractice
        }
        let result = await requestPhysicianStatus(requestData);
        const data = result.data;
        if (data.response == "success") {
            return true;
        }
        return false;
    }

    const requestNewSignUpEmail = (email, userIsPhysician, uid) => {    
        let url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/sendWelcomeEmail?dest=${email}&userIsPhysician=${userIsPhysician}&userUID=${uid}`
        fetch(url)
        .then(response => response.json())
        .then(data => console.log(data));
    }

    const buildBottomSheet = () => {
        let parent = [];
        if (bottomSheetType == "password") {
            parent.push(
                <Text style={{fontSize: 18, fontWeight: "600", marginBottom: 10}}>Sign in to continue</Text>,
                <Text style={{marginBottom: 10}}>
                    It appears you already have an account under this email address. Please enter your password to continue.
                </Text>,
                <StyledTextInput 
                    secure={true}
                    onChange={(text)=>{
                        setUserPassword(text);
                    }}
                    placeholder={"Your Password"}
                />,
            );
            if (passwordErrorShowing) {
                parent.push(
                    <Text style={styles.errorText}>Incorrect Password</Text>
                )
            }
            parent.push(
                <ActionButton
                    text={"Continue"}
                    handler={()=>{
                        submitPassword();
                    }}
                    loading={bottomSheetLoading}
                />
            );
        } else if (bottomSheetType == "not in group") {
            parent.push(
                <Text style={{fontSize: 18, fontWeight: "600", marginBottom: 10}}>Confirm</Text>,
                <Text style={{marginBottom: 10}}>
                    It appears you are not in the Facebook group. Please answer the question(s) below
                </Text>,
                <Text style={{marginBottom: 10}}>
                    Are you a physican?
                </Text>,
                <View style={styles.flexBox}>
                    <CheckBox 
                        label={"No"}
                        checked={noChecked}
                        key={Math.random().toString(36).substring(7)}
                        handler={checked => {
                            setNoChecked(checked)
                            setYesChecked(!checked)
                        }}
                    />
                    <CheckBox 
                        label={"Yes"}
                        checked={yesChecked}
                        key={Math.random().toString(36).substring(7)}
                        handler={checked => {
                            setYesChecked(checked)
                            setNoChecked(!checked)
                        }}
                    />
                </View>
            );
            if (yesChecked) {
                parent.push(
                    <Text style={{marginBottom: 10, fontWeight: "600"}}>
                        You may sign in but will not be able to list a property until aproved by an admin.
                    </Text>,
                    <StyledTextInput 
                        onChange={(text)=>{
                            setFullname(text);
                        }}
                        placeholder={"Full Name"}
                    />
                );
                if (fullNameErrorVisible) {
                    parent.push(<Text style={styles.errorText}>Please enter a first and last name</Text>)
                }
                parent.push(
                    <StyledTextInput 
                        onChange={(text)=>{
                            setNPI(text);
                        }}
                        placeholder={"NPI number"}
                    />
                );
                if (npiErrorVisible) {
                    parent.push(<Text style={styles.errorText}>Please enter an NPI number</Text>)
                }
                parent.push(
                    <StyledTextInput 
                        onChange={(text)=>{
                            setMedSchool(text);
                        }}
                        placeholder={"Medical School Name"}
                    />
                );
                if (medSchoolErrorVisible) {
                    parent.push(<Text style={styles.errorText}>Please enter your medical school name</Text>)
                }
                parent.push(
                    <StyledTextInput 
                        onChange={(text)=>{
                            setCurrentPractice(text);
                        }}
                        placeholder={"Name of Practice/ Facility"}
                    />,
                );
                if (currentPractiveErrorVisible) {
                    parent.push(<Text style={styles.errorText}>Please enter the name of our current practice/ employer</Text>)
                }
            }
            parent.push(
                <ActionButton
                    text={"Continue"}
                    handler={()=>{
                        validateAndSubmit();
                    }}
                    loading={bottomSheetLoading}
                />
            );
        } 
        let final = [];
        if (bottomSheetIndex > -1) {
            final = [
                // <BottomPage
                //     onClose={()=>{
                //         setBottomSheetIndex(-1);
                //         setLoading(false);
                //     }}
                // >
                //     {parent}
                // </BottomPage>
            ]; 
            if (Platform.OS == "web") {
                final = [
                    // <BottomPage 
                    //     open={true}
                    //     onClose={()=>{
                    //         setBottomSheetIndex(-1);
                    //         setLoading(false);
                    //     }}
                    // >
                    //     {parent}
                    // </BottomPage>
                ]; 
            }
        }
        return final;
    }

    return (
        <View>
            <TouchableHighlight 
                style={loading? styles.fbButtonDisabled: styles.fbButton}
                disabled={loading}
                onPress={() => promptAsync()}>
                <View style={styles.horizontalFlex}>
                    <Image
                        style={styles.fbIcon}
                        source={require('../assets/facebook.png')}
                    />
                    <Text style={styles.fbButtonText}>Login with Facebook</Text>
                    {loading? <ActivityIndicator size={"small"} color={"white"}/>: null}
                </View>
            </TouchableHighlight>
            {buildBottomSheet()}
        </View>
    );
}

const styles = StyleSheet.create({
    fbIcon: {
        width: 35,
        height: 35,
        marginHorizontal: 10,
        tintColor: "white",
    },
    fbButton: {
        width: "100%",
        backgroundColor: "#1877F2",
        padding: 7,
        borderRadius: 8,
        maxWidth: 300,
    },
    fbButtonDisabled: {
        width: "100%",
        backgroundColor: "#6795d0",
        padding: 7,
        borderRadius: 8,
        maxWidth: 300,
    },
    fbButtonText: {
        color: "white",
        fontWeight: "600",
        fontSize: 20,
        marginRight: 10, 
    },
    horizontalFlex: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
    },
    contentContainer: {
        flex: 1,
        alignItems: 'center',
    },
    bottomSheetContainer: {
        position: "absolute",
        width: Dimensions.get("screen").width,
        height: 100,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "green"
    },
    flexBox: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
    },
    errorText: {
        fontWeight: "600",
        color: "red",
        marginTop: 0,
        marginBottom: 10,
    }
});