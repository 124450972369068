import React, { Component } from 'react';
import { StyleSheet, Text, TextInput, TouchableHighlight, View, Platform, Image, Animated, Dimensions } from 'react-native';
import * as Linking from 'expo-linking';
import { getDatabase, ref, child, set, push } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { requestUpdate } from '../helpers/post-interactons';
import { uploadImages } from '../helpers/image-uploader';

import Modal from "../compenents/Modal";
import Container from "../compenents/Container";
import ToolTip from "../compenents/Toolltip";
import Checkbox from "../compenents/CheckBox";
import Map from "../compenents/Map";
import CountrySelect from "../compenents/CountrySelect";
import Footer from "../compenents/Footer";
import Stepper from "../compenents/Stepper";
import ActionButton from '../compenents/ActionButton';
import ImageUploader from "../compenents/ImageUploader";
import ExpandableView from '../compenents/ExpandableView';
import Ammenities from '../compenents/Ammenities';
import TopAlert from '../compenents/TopAlert';
import StyledTextInput from '../compenents/StyledTextInput';

const validWebsiteURLRegex = RegExp(
    /[a-z0-9-\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?$/
);
const validEmailRegex = RegExp(
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export default class PostProperty extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectURI: null,
            user: null,
            newRentalKey: null,
            publicRentalData: {
                accommodates: 1,
                bathrooms: 1,
                bedrooms: 1,
                electric: false,
                petFriendly: false,
                washerDryer: false,
                typeOfaccommodation: "Home",
                extraAmmenities: {
                    baby: false,
                    toddler: false,
                    children: false,
                    crib: false,
                    highChair: false,
                    handiCap: false,
                    pool: false,
                    hotTub: false,
                    wifi: false,
                    kitchen: false,
                    fridge: false,
                    freezer: false,
                    oven: false,
                    stoveTop: false,
                    microwave: false,
                    utencils: false,
                    dishes: false,
                    coffeeMaker: false,
                    dishWasher: false,
                    tv: false,
                    hotWater: false,
                    barbeque: false,
                    bathTub: false,
                    shower: false,
                    towels: false,
                    ac: false,
                    carEV: false,
                    firepit: false,
                    patio: false,
                },
                listingType: null,
            },
            strSelected: false,
            ltrSelected: false,
            saleSelected: false,
            strKey: Math.random().toString(36).substring(7),
            ltrKey: Math.random().toString(36).substring(7),
            saleKey: Math.random().toString(36).substring(7),
            inputSearchTerm: null,
            searchTerms: null,
            modalVisible: false,
            errorModalVisible: false,
            loadingVisible: false,
            errorMessage: "",
            titleError: false,
            photosError: false,
            descriptionError: false,
            priceError: false,
            mapError: false,
            websiteError: false,
            listingTypeError: false,
            searchInputKey: Math.random().toString(36).substring(7),
            petKey: Math.random().toString(36).substring(7),
            electricKey: Math.random().toString(36).substring(7),
            washerKey: Math.random().toString(36).substring(7),
            modalKey: Math.random().toString(36).substring(7),
            errorModalKey: Math.random().toString(36).substring(7),
            loadingModalKey: Math.random().toString(36).substring(7),
            saveDraftsKey: Math.random().toString(36).substring(7),
            tooltipIsVisible: false,
            tooltipMessage: "",
            tooltipTitle: "",
            modalText: "",
            tooltipModalKey: Math.random().toString(36).substring(7),
            slideShowKey: Math.random().toString(36).substring(7),
            bedroomKey: Math.random().toString(36).substring(7),
            bathroomKey:Math.random().toString(36).substring(7),
            accomodatesKey: Math.random().toString(36).substring(7),
            height: Dimensions.get("window").height,
            scrollEnabled: true,
            loadingPercentage: 0,
            loading: false,
            successIsShowing: false,
            homeKey: Math.random().toString(36).substring(7),
            bedroomCheckKey: Math.random().toString(36).substring(7),
            tinyHouseKey: Math.random().toString(36).substring(7),
            yurtKey: Math.random().toString(36).substring(7),
            cabinKey: Math.random().toString(36).substring(7),
            treeHouseKey: Math.random().toString(36).substring(7),
            campGroundKey: Math.random().toString(36).substring(7),
            rvKey: Math.random().toString(36).substring(7),
            hotelKey: Math.random().toString(36).substring(7),
            hostelKey: Math.random().toString(36).substring(7),
            bedKey: Math.random().toString(36).substring(7),
            otherKey: Math.random().toString(36).substring(7),
        };
        this.mapUpdateHandler = this.mapUpdateHandler.bind(this);
        this.electricCheckHandler = this.electricCheckHandler.bind(this);
        this.petCheckHandler = this.petCheckHandler.bind(this);
        this.washerCheckHandler = this.washerCheckHandler.bind(this);
        this.newImageDataHandler = this.newImageDataHandler.bind(this);
        this.countrySelectHandler = this.countrySelectHandler.bind(this);
        this.accomodationTypeCheckHandler = this.accomodationTypeCheckHandler.bind(this);
    } 

    componentDidMount() {
        this.configureView();
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.resetView();
            this.configureView();
        });
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
			if (user) {
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
        });
        if (Platform.OS == "web") {
            window.addEventListener('resize', this.updateDimensions);
        }
    }

    updateDimensions = () => {
        this.setState({height: Dimensions.get("window").height})
    }
 
    resetView = () => {
        this.setState({
            publicRentalData: {
                title: null,
                accommodates: 1,
                bathrooms: 1,
                bedrooms: 1,
                electric: false,
                petFriendly: false,
                washerDryer: false,
                typeOfaccommodation: "Home",
                photoUrls: null,
                website: null,
                description: null,
                price: null,
                latitude: null,
                longitude: null,
                city: null,
                state: null,
                country: null,
                key: null,
                contactEmail: null,
                extraAmmenities: {
                    baby: false,
                    toddler: false,
                    children: false,
                    crib: false,
                    highChair: false,
                    handiCap: false,
                    pool: false,
                    hotTub: false,
                    wifi: false,
                    kitchen: false,
                    fridge: false,
                    freezer: false,
                    oven: false,
                    stoveTop: false,
                    microwave: false,
                    utencils: false,
                    dishes: false,
                    coffeeMaker: false,
                    dishWasher: false,
                    tv: false,
                    hotWater: false,
                    barbeque: false,
                    bathTub: false,
                    shower: false,
                    towels: false,
                    ac: false,
                    carEV: false,
                    firepit: false,
                    patio: false,
                },
                listingType: null,
            },
            searchTerms: null,
            mapKey: Math.random().toString(36).substring(7),
            searchInputKey: Math.random().toString(36).substring(7),
            petKey: Math.random().toString(36).substring(7),
            electricKey: Math.random().toString(36).substring(7),
            washerKey: Math.random().toString(36).substring(7),
            modalKey: Math.random().toString(36).substring(7),
            errorModalKey: Math.random().toString(36).substring(7),
            loadingModalKey: Math.random().toString(36).substring(7),
            saveDraftsKey: Math.random().toString(36).substring(7),
            slideShowKey: Math.random().toString(36).substring(7),
            bedroomKey: Math.random().toString(36).substring(7),
            bathroomKey:Math.random().toString(36).substring(7),
            accomodatesKey: Math.random().toString(36).substring(7),
            tooltipModalKey: Math.random().toString(36).substring(7),
            tempData: null,
            loading: false,
            homeKey: Math.random().toString(36).substring(7),
            bedroomCheckKey: Math.random().toString(36).substring(7),
            tinyHouseKey: Math.random().toString(36).substring(7),
            yurtKey: Math.random().toString(36).substring(7),
            cabinKey: Math.random().toString(36).substring(7),
            treeHouseKey: Math.random().toString(36).substring(7),
            campGroundKey: Math.random().toString(36).substring(7),
            rvKey: Math.random().toString(36).substring(7),
            hotelKey: Math.random().toString(36).substring(7),
            hostelKey: Math.random().toString(36).substring(7),
            bedKey: Math.random().toString(36).substring(7),
            otherKey: Math.random().toString(36).substring(7),
            listingTypeError: false,
        });
    }

    configureView = () => {
        if (!!this.props.route.params) {
            if (this.props.route.params.publicRentalData != null && 
                this.props.route.params.draftData != null ) {
                let draftClick = this.props.route.params.draftData.clickTime;
                let publicRentalClick = this.props.route.params.publicRentalData.clickTime;
                if (draftClick > publicRentalClick) {
                    delete this.props.route.params.draftData.clickTime;
                    let rentalData = this.props.route.params.draftData;
                    let extraAmmenitiesData = this.mapExtraAmmenities(rentalData);
                    rentalData.extraAmmenities = extraAmmenitiesData;
                    this.setListingType(rentalData);
                    this.setState({ 
                        publicRentalData: rentalData,
                        privateRentalData: null,
                        searchTerms: rentalData.searchTerms,
                        draftVisible: true,
                        publicRentalVisible: false,
                        mapKey: Math.random().toString(36).substring(7),
                        petKey: Math.random().toString(36).substring(7),
                        electricKey: Math.random().toString(36).substring(7),
                        washerKey: Math.random().toString(36).substring(7),
                        bedroomKey: Math.random().toString(36).substring(7),
                        bathroomKey:Math.random().toString(36).substring(7),
                        accomodatesKey: Math.random().toString(36).substring(7),
                        homeKey: Math.random().toString(36).substring(7),
                        bedroomCheckKey: Math.random().toString(36).substring(7),
                        tinyHouseKey: Math.random().toString(36).substring(7),
                        yurtKey: Math.random().toString(36).substring(7),
                        cabinKey: Math.random().toString(36).substring(7),
                        treeHouseKey: Math.random().toString(36).substring(7),
                        campGroundKey: Math.random().toString(36).substring(7),
                        rvKey: Math.random().toString(36).substring(7),
                        hotelKey: Math.random().toString(36).substring(7),
                        hostelKey: Math.random().toString(36).substring(7),
                        bedKey: Math.random().toString(36).substring(7),
                        otherKey: Math.random().toString(36).substring(7),
                    });
                } else {
                    let rentalData = this.props.route.params.publicRentalData;
                    if (this.props.route.params.privateRentalData != null) {
                        rentalData["contactEmail"] = this.props.route.params.privateRentalData.contactEmail;
                    }
                    let extraAmmenitiesData = this.mapExtraAmmenities(rentalData);
                    rentalData.extraAmmenities = extraAmmenitiesData;
                    this.setListingType(rentalData);
                    this.setState({ 
                        publicRentalData: rentalData,
                        searchTerms: rentalData.searchTerms,
                        draftVisible: false,
                        publicRentalVisible: true,
                        petKey: Math.random().toString(36).substring(7),
                        electricKey: Math.random().toString(36).substring(7),
                        washerKey: Math.random().toString(36).substring(7),
                        bedroomKey: Math.random().toString(36).substring(7),
                        bathroomKey:Math.random().toString(36).substring(7),
                        accomodatesKey: Math.random().toString(36).substring(7),
                        homeKey: Math.random().toString(36).substring(7),
                        bedroomCheckKey: Math.random().toString(36).substring(7),
                        tinyHouseKey: Math.random().toString(36).substring(7),
                        yurtKey: Math.random().toString(36).substring(7),
                        cabinKey: Math.random().toString(36).substring(7),
                        treeHouseKey: Math.random().toString(36).substring(7),
                        campGroundKey: Math.random().toString(36).substring(7),
                        rvKey: Math.random().toString(36).substring(7),
                        hotelKey: Math.random().toString(36).substring(7),
                        hostelKey: Math.random().toString(36).substring(7),
                        bedKey: Math.random().toString(36).substring(7),
                        otherKey: Math.random().toString(36).substring(7),
                    });
                }
            } else if (this.props.route.params.publicRentalData != null) {
                let rentalData = this.props.route.params.publicRentalData;
                if (this.props.route.params.privateRentalData != null) {
                    rentalData["contactEmail"] = this.props.route.params.privateRentalData.contactEmail;
                }
                let extraAmmenitiesData = this.mapExtraAmmenities(rentalData);
                rentalData.extraAmmenities = extraAmmenitiesData;
                this.setListingType(rentalData);
                this.setState({ 
                    publicRentalData: rentalData,
                    searchTerms: rentalData.searchTerms,
                    draftVisible: false,
                    publicRentalVisible: true,
                    petKey: Math.random().toString(36).substring(7),
                    electricKey: Math.random().toString(36).substring(7),
                    washerKey: Math.random().toString(36).substring(7),
                    bedroomKey: Math.random().toString(36).substring(7),
                    bathroomKey:Math.random().toString(36).substring(7),
                    accomodatesKey: Math.random().toString(36).substring(7),
                    homeKey: Math.random().toString(36).substring(7),
                    bedroomCheckKey: Math.random().toString(36).substring(7),
                    tinyHouseKey: Math.random().toString(36).substring(7),
                    yurtKey: Math.random().toString(36).substring(7),
                    cabinKey: Math.random().toString(36).substring(7),
                    treeHouseKey: Math.random().toString(36).substring(7),
                    campGroundKey: Math.random().toString(36).substring(7),
                    rvKey: Math.random().toString(36).substring(7),
                    hotelKey: Math.random().toString(36).substring(7),
                    hostelKey: Math.random().toString(36).substring(7),
                    bedKey: Math.random().toString(36).substring(7),
                    otherKey: Math.random().toString(36).substring(7),
                });
            } else if (this.props.route.params.draftData != null) {
                let rentalData = this.props.route.params.draftData;
                let extraAmmenitiesData = this.mapExtraAmmenities(rentalData);
                rentalData.extraAmmenities = extraAmmenitiesData;
                this.setListingType(rentalData);
                this.setState({ 
                    publicRentalData: rentalData,
                    searchTerms: rentalData.searchTerms,
                    draftVisible: true,
                    publicRentalVisible: false,
                    mapKey: Math.random().toString(36).substring(7),
                    petKey: Math.random().toString(36).substring(7),
                    electricKey: Math.random().toString(36).substring(7),
                    washerKey: Math.random().toString(36).substring(7),
                    bedroomKey: Math.random().toString(36).substring(7),
                    bathroomKey:Math.random().toString(36).substring(7),
                    accomodatesKey: Math.random().toString(36).substring(7),
                    homeKey: Math.random().toString(36).substring(7),
                    bedroomCheckKey: Math.random().toString(36).substring(7),
                    tinyHouseKey: Math.random().toString(36).substring(7),
                    yurtKey: Math.random().toString(36).substring(7),
                    cabinKey: Math.random().toString(36).substring(7),
                    treeHouseKey: Math.random().toString(36).substring(7),
                    campGroundKey: Math.random().toString(36).substring(7),
                    rvKey: Math.random().toString(36).substring(7),
                    hotelKey: Math.random().toString(36).substring(7),
                    hostelKey: Math.random().toString(36).substring(7),
                    bedKey: Math.random().toString(36).substring(7),
                    otherKey: Math.random().toString(36).substring(7),
                });
            } else {
                if (!!this.state.tempData) {
                    this.setListingType(this.state.tempData);
                    this.setState({ 
                        publicRentalData: this.state.tempData,
                        privateRentalData: null,
                        searchTerms: this.state.tempData.searchTerms,
                        draftVisible: true,
                        publicRentalVisible: false,
                        mapKey: Math.random().toString(36).substring(7),
                        petKey: Math.random().toString(36).substring(7),
                        electricKey: Math.random().toString(36).substring(7),
                        washerKey: Math.random().toString(36).substring(7),
                        bedroomKey: Math.random().toString(36).substring(7),
                        bathroomKey:Math.random().toString(36).substring(7),
                        accomodatesKey: Math.random().toString(36).substring(7),
                        tempData: null,
                        loading: false,
                        homeKey: Math.random().toString(36).substring(7),
                        bedroomCheckKey: Math.random().toString(36).substring(7),
                        tinyHouseKey: Math.random().toString(36).substring(7),
                        yurtKey: Math.random().toString(36).substring(7),
                        cabinKey: Math.random().toString(36).substring(7),
                        treeHouseKey: Math.random().toString(36).substring(7),
                        campGroundKey: Math.random().toString(36).substring(7),
                        rvKey: Math.random().toString(36).substring(7),
                        hotelKey: Math.random().toString(36).substring(7),
                        hostelKey: Math.random().toString(36).substring(7),
                        bedKey: Math.random().toString(36).substring(7),
                        otherKey: Math.random().toString(36).substring(7),
                    });
                } else {
                    this.setState({ 
                        draftVisible: false,
                        publicRentalVisible: false,
                    });
                }
            }
        } 
        this.getThisURI();
    }

    mapExtraAmmenities = (rentalData) => {
        if (!!rentalData.extraAmmenities) {
            return {
                baby: !!rentalData.extraAmmenities.baby ? true: false,
                toddler: !!rentalData.extraAmmenities.toddler ? true: false,
                children: !!rentalData.extraAmmenities.children ? true: false,
                crib: !!rentalData.extraAmmenities.crib ? true: false,
                highChair: !!rentalData.extraAmmenities.highChair ? true: false,
                handiCap: !!rentalData.extraAmmenities.handiCap ? true: false,
                pool: !!rentalData.extraAmmenities.pool ? true: false,
                hotTub: !!rentalData.extraAmmenities.hotTub ? true: false,
                wifi: !!rentalData.extraAmmenities.wifi ? true: false,
                kitchen: !!rentalData.extraAmmenities.kitchen ? true: false,
                fridge: !!rentalData.extraAmmenities.fridge ? true: false,
                freezer: !!rentalData.extraAmmenities.freezer ? true: false,
                oven: !!rentalData.extraAmmenities.oven ? true: false,
                stoveTop: !!rentalData.extraAmmenities.stoveTop ? true: false,
                microwave: !!rentalData.extraAmmenities.microwave ? true: false,
                utencils: !!rentalData.extraAmmenities.utencils ? true: false,
                dishes: !!rentalData.extraAmmenities.dishes ? true: false,
                coffeeMaker: !!rentalData.extraAmmenities.coffeeMaker ? true: false,
                dishWasher: !!rentalData.extraAmmenities.dishWasher ? true: false,
                tv: !!rentalData.extraAmmenities.tv ? true: false,
                hotWater: !!rentalData.extraAmmenities.hotWater ? true: false,
                barbeque: !!rentalData.extraAmmenities.barbeque ? true: false,
                bathTub: !!rentalData.extraAmmenities.bathTub ? true: false,
                shower: !!rentalData.extraAmmenities.shower ? true: false,
                towels: !!rentalData.extraAmmenities.towels ? true: false,
                ac: !!rentalData.extraAmmenities.ac ? true: false,
                carEV: !!rentalData.extraAmmenities.carEV ? true: false,
                firepit: !!rentalData.extraAmmenities.firepit ? true: false,
                patio: !!rentalData.extraAmmenities.patio ? true: false,
            };
        } 
        return;
    }

    setListingType = (rentalData) => {
        if (!!rentalData.listingType) {
            if (rentalData.listingType == "str") {
                this.setState({
                    strSelected: true,
                    ltrSelected: false,
                    saleSelected: false,
                    strKey: Math.random().toString(36).substring(7),
                    ltrKey: Math.random().toString(36).substring(7),
                    saleKey: Math.random().toString(36).substring(7),
                })
            } else if (rentalData.listingType == "ltr") {
                this.setState({
                    strSelected: false,
                    ltrSelected: true,
                    saleSelected: false,
                    strKey: Math.random().toString(36).substring(7),
                    ltrKey: Math.random().toString(36).substring(7),
                    saleKey: Math.random().toString(36).substring(7),
                })
            } else if (rentalData.listingType == "forSale") {
                this.setState({
                    strSelected: false,
                    ltrSelected: false,
                    saleSelected: true,
                    strKey: Math.random().toString(36).substring(7),
                    ltrKey: Math.random().toString(36).substring(7),
                    saleKey: Math.random().toString(36).substring(7),
                })
            }
        } else {
            this.setState({
                strSelected: true,
                ltrSelected: false,
                saleSelected: false,
                strKey: Math.random().toString(36).substring(7),
                ltrKey: Math.random().toString(36).substring(7),
                saleKey: Math.random().toString(36).substring(7),
            })
        }
    }

    getThisURI = () => {
        const redirect = Linking.makeUrl("/");
        this.setState({redirectURI: redirect});
    }

    getGeocodeData = (lat, long, callback) => {
        const _this = this;
        fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + lat + ',' + long + '&key=AIzaSyCgAEOikmxZ9Zb9JB20pSHGZLKACyt9YZk')
        .then((response) => response.json())
        .then((responseJson) => {
            let finalAddress = {
                region: "",
                city: "",
                country: ""
            }
            responseJson.results.forEach(item => {
                const addressObject = _this.getAddressObject(item.address_components);
                if (addressObject.city && addressObject.city != "" &&
                    finalAddress.city === "") {
                    finalAddress.city = addressObject.city;
                }
                if (addressObject.region && addressObject.region != "" &&
                    finalAddress.region === "") {
                    finalAddress.region = addressObject.region;
                }
                if (addressObject.country && addressObject.country != "" &&
                    finalAddress.country === "") {
                    finalAddress.country = addressObject.country;
                }
            });
            callback(finalAddress);
        })
    }

    getAddressObject(address_components) {
        var ShouldBeComponent = {
          home: ["street_number"],
          postal_code: ["postal_code"],
          street: ["street_address", "route"],
          region: [
            "administrative_area_level_1",
            "administrative_area_level_2",
            "administrative_area_level_3",
            "administrative_area_level_4",
            "administrative_area_level_5"
          ],
          city: [
            "locality",
            "sublocality",
            "sublocality_level_1",
            "sublocality_level_2",
            "sublocality_level_3",
            "sublocality_level_4"
          ],
          country: ["country"]
        };
  
        var address = {
          region: "",
          city: "",
          country: ""
        };
        address_components.forEach(component => {
          for (var shouldBe in ShouldBeComponent) {
            if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                address[shouldBe] = component.long_name;
            }
          }
        });
        return address;
    }

    mapUpdateHandler(rentalData) {
        let newRentalData = this.state.publicRentalData;
        if (Platform.OS == "web") {
            newRentalData.latitude = rentalData.lat;
            newRentalData.longitude = rentalData.lng;
        } else {
            newRentalData.latitude = rentalData.latitude;
            newRentalData.longitude = rentalData.longitude;
        }
        this.getGeocodeData(newRentalData.latitude, newRentalData.longitude, (data) => {
            let newRentalData = this.state.publicRentalData;
            newRentalData.city = data.city;
            newRentalData.state = data.region;
            newRentalData.country = data.country;
            this.setState({ 
                publicRentalData: newRentalData,
            });
        });
        this.setState({ 
            publicRentalData: newRentalData,
        });
    }

    electricCheckHandler(checked) {
        let rentalData = this.state.publicRentalData;
        rentalData.electric = checked;
        this.setState({ 
            publicRentalData: rentalData,
        });
    }

    petCheckHandler(checked) {
        let rentalData = this.state.publicRentalData;
        rentalData.petFriendly = checked;
        this.setState({ 
            publicRentalData: rentalData,
        });
    }

    washerCheckHandler(checked) {
        let rentalData = this.state.publicRentalData;
        rentalData.washerDryer = checked;
        this.setState({ 
            publicRentalData: rentalData,
        });
    }

    newImageDataHandler(imageData) {
        let rentalData = this.state.publicRentalData;
        rentalData.photoUrls = imageData;
        this.setState({ 
            publicRentalData: rentalData,
            slideShowKey: Math.random().toString(36).substring(7),
        });
    }   

    removeImageHandler = (imageData) => {
        let rentalData = this.state.publicRentalData;
        rentalData.photoUrls = imageData;
        this.setState({ 
            publicRentalData: rentalData,
        });
    }

    countrySelectHandler(country) {
        let rentalData = this.state.publicRentalData;
        rentalData.country = country;
        this.setState({ 
            publicRentalData: rentalData,
        });
    }

    accomodationTypeCheckHandler(checked, type) {
        let rentalData = this.state.publicRentalData;
        rentalData.typeOfaccommodation = type;
        this.setState({ 
            publicRentalData: rentalData,
            homeKey: Math.random().toString(36).substring(7),
            bedroomCheckKey: Math.random().toString(36).substring(7),
            tinyHouseKey: Math.random().toString(36).substring(7),
            yurtKey: Math.random().toString(36).substring(7),
            cabinKey: Math.random().toString(36).substring(7),
            treeHouseKey: Math.random().toString(36).substring(7),
            campGroundKey: Math.random().toString(36).substring(7),
            rvKey: Math.random().toString(36).substring(7),
            hotelKey: Math.random().toString(36).substring(7),
            hostelKey: Math.random().toString(36).substring(7),
            bedKey: Math.random().toString(36).substring(7),
            otherKey: Math.random().toString(36).substring(7),
        });
    }

    validateNewPropertyInput(type) {
        let errorMessage = "Please fill in all required fields to continue.";
        let allValid = true;
        if (this.state.publicRentalData.title == null ) {
            allValid = false;
            this.setState({titleError: true})
        }
        if (this.state.publicRentalData.photoUrls == null ) {
            allValid = false;
            this.setState({photosError: true})
        }
        if (this.state.publicRentalData.description == null ) {
            allValid = false;
            this.setState({descriptionError: true})
        }
        if (this.state.publicRentalData.price == null ) {
            allValid = false; 
            this.setState({priceError: true})
        } 
        if (this.state.publicRentalData.longitude == null ||
            this.state.publicRentalData.latitude == null) {
            allValid = false; 
            this.setState({mapError: true})
        }
        if (this.state.publicRentalData.website != null && this.state.publicRentalData.website != "") {
            if (!validWebsiteURLRegex.test(this.state.publicRentalData.website)) {
                allValid = false; 
                this.setState({websiteError: true})
            } else {
                this.setState({websiteError: false})
            }
        } else {
            this.setState({websiteError: false})
        }

        if (this.state.publicRentalData.contactEmail != null ) {
            //verify email is valid
            if (validEmailRegex.test(this.state.publicRentalData.contactEmail) || this.state.publicRentalData.contactEmail == "" ) {
                this.setState({ contactEmailError: false });
            } else {
                allValid = false;
                this.setState({ contactEmailError: true });
            }
        } else {
            this.setState({ contactEmailError: false });
        }

        if (!!this.state.publicRentalData.listingType) {
            this.setState({
                listingTypeError: false,
            });
        } else {
            if (this.state.strSelected || this.state.ltrSelected || this.state.saleSelected) {
                let newListingData = this.state.publicRentalData;
                if (this.state.strSelected) {
                    newListingData.listingType = "str";
                } else if (this.state.ltrSelected) {
                    newListingData.listingType = "ltr";
                } else if (this.state.saleSelected) {
                    newListingData.listingType = "forSale";
                }
                this.setState({
                    publicRentalData: newListingData,
                })
            } else {
                allValid = false;
                this.setState({
                    listingTypeError: true,
                })
            }
        }

        //only save for sale or LTR if bronze or gold
        //only save silver if STR
        if (!!this.state.publicRentalData.listingType) {
            if (!!this.state.publicRentalData.subscriptionLevel) {
                if ((this.state.publicRentalData.listingType == "ltr" || this.state.publicRentalData.listingType == "forSale") && this.state.publicRentalData.subscriptionLevel == "silver") {
                    allValid = false;
                    errorMessage = "You must change your subscription level to Bronze or Gold to change your listing to long term rental or for sale.";
                }
            } 
        }

        if (allValid) {
            if (type == "save") {
                this.promptToSave();
            } else {
                this.checkout();
            }
        } else {
            this.setState({
                errorModalVisible: true,
                errorModalKey: Math.random().toString(36).substring(7),
                errorMessage: errorMessage,
            });
        }
    }

    checkout = async () => {
        const db = getDatabase();
        let newProperyRef = ""
        let purchaseData = this.state.publicRentalData;
        if (this.state.searchTerms != null) {
            purchaseData["searchTerms"] = this.state.searchTerms
        }
        purchaseData.posterUID = this.state.user.uid;
        purchaseData.email = this.state.user.email;

        this.setState({
            loadingVisible: true,
            loadingModalKey: Math.random().toString(36).substring(7),
        })
        let imageUrls = await uploadImages(purchaseData.photoUrls, purchaseData.key, true, this.state.user.uid, percentage => {
            this.setState({
                loadingPercentage: percentage,
            })
        });
        purchaseData.photoUrls = imageUrls;

        //filter out all false from extraAmmenities
        let finalExtras = {};
        if (!!purchaseData.extraAmmenities) {
            Object.keys(purchaseData.extraAmmenities).forEach(function(key) {
                if (purchaseData.extraAmmenities[key]) {
                    finalExtras[key] = true;
                }
            });
            if (Object.keys(finalExtras).length > 0) {
                purchaseData.extraAmmenities = finalExtras;
            } else {
                delete purchaseData.extraAmmenities;
            }
        } else {
            delete purchaseData.extraAmmenities;
        }
        if (this.state.draftVisible) {
            newProperyRef = ref(db, `publicRentals/${purchaseData.key}}`);
            let draftsRef = ref(db, `drafts/${this.state.user.uid}/${purchaseData.key}`);
            set(draftsRef, purchaseData);
        } else {
            if (this.props.route.params.draftData == null) {
                newProperyRef = push(child(ref(db), `publicRentals/${this.state.user.uid}`));
                purchaseData.key = newProperyRef.key;
                let draftsRef = ref(db, `drafts/${this.state.user.uid}/${purchaseData.key}`);
                set(draftsRef, purchaseData);
            } else {
                newProperyRef = ref(db, `publicRentals/${this.props.route.params.draftData.key}}`);
                purchaseData.key = newProperyRef.key;
                let draftsRef = ref(db, `drafts/${this.state.user.uid}/${purchaseData.key}`);
                set(draftsRef, purchaseData);
            }
        }
        this.setState({
            tempData: purchaseData,
            loadingVisible: false,
            loadingModalKey: Math.random().toString(36).substring(7),
        })

        if (this.state.publicRentalData.listingType != "str") {
            purchaseData.subscriptionLevel = "bronze";
            this.props.navigation.navigate("Special Options", {
                newRentalData: purchaseData,
            });
        } else {
            this.props.navigation.navigate("Subscription", {
                newRentalData: purchaseData
            });
        }
    }

    getParameterByName = (name, url) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    promptToSave() {
        this.setState({
            modalVisible : true,
            modalKey: Math.random().toString(36).substring(7),
            modalText: "Are you sure you'd like to save these changes?"
        });
    }

    onModalClose = () => {
        this.setState({modalVisible : false});
    }

    onConfirmClick = (confirmMessage) => {
        this.updatePost();
    }

    tooltipOnPress = (key) => {
        let title = key;
        let message = "";
        switch(key) {
            case "Listing Type":
                message = `The type of listing you would like to create. 
Either a short term rental, long term rental, or for sale'`;
                break;
            case "Title":
                message = `The title of your property should be something
to catch user's eye without being too wordy.
A good example might be 'Bahama Get-away Cottage'
or 'Charming 3 bedroom on Lake Michigan'`;
                break;
            case "Images":
                message = `The first image will be the image most users see so make sure it's a good one!
You can upload up to 20 images. Users must upload at least one image.
Tap the "Upload Image" button to select an image.`;
                break;
            case "Description":
                message = `The description should highlight interesting features about the property.`;
                break;
            case "Price":
                message = `Some good examples of a price might be '$250 per night' 
or '$2,500 per month' or '$250,000' if you are selling.
If you wish have a variable price or want to keep the price private, 
you may list the price as 'Contact for price'.`;
                break;
            case "Approximate location":
                message = `Tap the map to drop a pin on the approximate location. 
Tap and hold an existing pin to move it to a new location.
We don't recommend putting a pin exactly where the property is for safety reasons. 
This pin will be visible to all users, including those not logged in.`;
                break;
            case "Amenities":
                message = `Check the box if your rental has the listed amenities.`;
                break;
            case "Number of bedrooms":
                message = "The number of rooms available.";
                break;
            case "Number of bathrooms":
                message = "The number of bathrooms available.";
                break;
            case "Number of people rental accommodates":
                message = "The number of people that can comfortably stay at the property at one time.";
                break;
            case "Type of accommodation":
                message = "The type of living quarters this property is.";
                break;
            case "Public Website (optional)":
                message = `A good example of a website link might be an Airbnb listing, or a Zilllow post.
This link will be visible to all users, including those not logged in. 
Your contact is made available to logged in users so if you wish to only rent to doctors on this platform, leave this empty. `;
                break;
            case "Search Tags (optional)":
                message = `Terms that can be used to find your property when searching.
Good examples might be hot tub, beach, NYC`;
                break;
            case "Contact Email (optional)":
                message = `This email will be visible for logged in users to contact you about your listing.
If no contact email is listed is listed, the email used to log in will be used.`;
                break;
            case "City (optional)":
                message = `The city/ town the property is located in.
This is to help optimize the search and not required.`;
                break;
            case "State (optional)":
                message = `The state/ region the property is located in.
This is to help optimize the search and not required.`;
                break;
            case "Country (optional)":
                message = `The country the property is located in.
This is to help optimize the search and not required.`;
                break;
            default:
                message = "Someting went wrong.";
                break;
        }
        this.setState({
            tooltipIsVisible: true,
            tooltipMessage: message,
            tooltipTitle: title,
            tooltipModalKey: Math.random().toString(36).substring(7),
        });
    }

    updatePost = async () => {
        let updateRentalData = this.state.publicRentalData;
        updateRentalData.posterUID = this.state.user.uid;
        if (this.state.searchTerms != null) {
            let numberOfTerms = Object.keys(this.state.searchTerms).length;
            if (numberOfTerms > 0) {
                updateRentalData["searchTerms"] = this.state.searchTerms;
            }
        }
        if (updateRentalData.contactEmail == undefined) {
            delete updateRentalData.contactEmail
        }
        if (updateRentalData.analyticsData == undefined) {
            delete updateRentalData.analyticsData
        }
        this.setState({
            loadingVisible: true,
            loadingModalKey: Math.random().toString(36).substring(7),
            modalVisible: false,
            modalKey: Math.random().toString(36).substring(7),
        })
        let imageUrls = await uploadImages(updateRentalData.photoUrls, updateRentalData.key, false, this.state.user.uid, percentage => {
            this.setState({
                loadingPercentage: percentage,
            })
        });
        updateRentalData.photoUrls = imageUrls;

        if (updateRentalData.extraAmmenities != undefined) {
            let finalExtras = {};
            Object.keys(updateRentalData.extraAmmenities).forEach(function(key) {
                if (updateRentalData.extraAmmenities[key]) {
                    finalExtras[key] = true;
                }
            });
            if (Object.keys(finalExtras).length > 0) {
                updateRentalData.extraAmmenities = finalExtras;
            }
        } else {
            delete updateRentalData.extraAmmenities;
        }

        let success = await requestUpdate(this.state.user.uid, updateRentalData);
        this.setState({
            loadingVisible: false,
            loadingModalKey: Math.random().toString(36).substring(7),
            modalVisible: false,
            modalKey: Math.random().toString(36).substring(7),
            

        })
        if (success) {
            this.showSuccess();
            if (!!this.props.route.params) {
                if (!!this.props.route.params.callBack) {
                    this.props.route.params.callBack(this.state.publicRentalData);
                }
            }
        } else {
            console.log("update failed");
        }
    }

    showSuccess = () => {
        this.setState({
            successIsShowing: true,
        });
        setTimeout(() => {
            this.setState({
                successIsShowing: false,
            });
        }, 1500);
    }

    addSearchTerm = () => {
        //grab seach term and put it in the search 
        if (this.state.inputSearchTerm != null && this.state.inputSearchTerm != "" ) {
            let numberOfTerms = 0;
            if (this.state.searchTerms != null) {
                numberOfTerms = Object.keys(this.state.searchTerms).length;
            }
            if (numberOfTerms < 5) {
                let newTerm = this.state.inputSearchTerm;
                newTerm = newTerm.toLowerCase();
                let newTerms = {};
                newTerms[newTerm] = newTerm;
                if (this.state.searchTerms == null) {
                    this.setState({
                        searchTerms: newTerms,
                        inputSearchTerm: null,
                        searchInputKey: Math.random().toString(36).substring(7),
                    });
                } else {
                    let newTerms = this.state.searchTerms;
                    newTerms[newTerm] = newTerm;
                    this.setState({
                        searchTerms: newTerms,
                        inputSearchTerm: null,
                        searchInputKey: Math.random().toString(36).substring(7),
                    });
                }
            } else {
                this.setState({
                    errorModalVisible: true,
                    errorModalKey: Math.random().toString(36).substring(7),
                    errorMessage: "You can only have 5 tags. Please remove one to contiue.",
                });
            }
        } else {
            this.setState({
                errorModalVisible: true,
                errorModalKey: Math.random().toString(36).substring(7),
                errorMessage: "Cannot add empty search tag.",
            });
        }
    }

    buildSearchTermButtons = () => {
        let parent = [];
        if (this.state.searchTerms != null ) {
            for (let key in this.state.searchTerms) {
                parent.push(
                    <TouchableHighlight 
                        style={styles.searchTermButton}
                        onPress={() => this.removeSearchTerm(key)}
                    >
                        <View style={styles.searchTermWrapper} >
                            <Image
                                source={require('../assets/xIcon.png')}
                                style={styles.xIcon}
                            />
                            <Text style={styles.searchTermButtonText}>{key}</Text>
                        </View>
                    </TouchableHighlight>
                );
            }
        }
        return parent;
    }

    removeSearchTerm = (key) => {
        let newSearchTerms = this.state.searchTerms;
        delete newSearchTerms[key];
        this.setState({searchTerms: newSearchTerms});
    }

    constuctActionButton = () => {
        let parent = [];
        if (!!this.props.route.params) {
            if (this.state.publicRentalVisible) {
                parent.push(
                    <ActionButton
                        text={"Save"}
                        handler={()=>{
                            this.validateNewPropertyInput("save")
                        }}
                        loading={this.state.loadingVisible || this.state.loading}
                    />
                )
            } else {
                parent.push(
                    <ActionButton
                        text={"Post"}
                        handler={()=>{
                            this.validateNewPropertyInput("post")
                        }}
                        loading={this.state.loadingVisible || this.state.loading}
                    />
                )
            }
        } else {
            parent.push(
                <ActionButton
                    text={"Post"}
                    handler={()=>{
                        this.validateNewPropertyInput("post")
                    }}
                    loading={this.state.loadingVisible || this.state.loading}
                />
            )
        }

        return parent;
    }

    constructMap = () => {
        let parent = [];
        if (!!this.props.route.params) {
            if (this.state.publicRentalVisible) {
                if (!!this.props.route.params.publicRentalData) {
                    parent.push(
                        <Text style={{padding: 5, fontSize: 12}}>Tap the map to drop a pin</Text>,
                        <Map 
                            isHome={false}
                            rentalsData={this.props.route.params.publicRentalData}
                            handler={this.mapUpdateHandler}
                            draggable={true}
                            key={this.state.mapKey}
                        />
                    );
                } else {
                    parent.push(
                        <Text style={{padding: 5, fontSize: 12}}>Tap the map to drop a pin</Text>,
                        <Map 
                            isHome={false}
                            handler={this.mapUpdateHandler}
                            rentalsData={this.state.publicRentalData}
                            draggable={true}
                            key={this.state.mapKey}
                        />
                    );
                }
            } else if (this.state.draftVisible) {
                if (!!this.props.route.params.draftData) {
                    parent.push(
                        <Text style={{padding: 5, fontSize: 12}}>Tap the map to drop a pin</Text>,
                        <Map 
                            isHome={false}
                            rentalsData={this.props.route.params.draftData}
                            handler={this.mapUpdateHandler}
                            draggable={true}
                            key={this.state.mapKey}
                        />
                    );
                } else {
                    parent.push(
                        <Text style={{padding: 5, fontSize: 12}}>Tap the map to drop a pin</Text>,
                        <Map 
                            isHome={false}
                            handler={this.mapUpdateHandler}
                            rentalsData={this.state.publicRentalData}
                            draggable={true}
                            key={this.state.mapKey}
                        />
                    );
                }
            } else {
                parent.push(
                    <Text style={{padding: 5, fontSize: 12}}>Tap the map to drop a pin</Text>,
                    <Map 
                        isHome={false}
                        handler={this.mapUpdateHandler}
                        rentalsData={this.state.publicRentalData}
                        draggable={true}
                        key={this.state.mapKey}
                    />
                );
            }
        } else {
            parent.push(
                <Text style={{padding: 5, fontSize: 12}}>Tap the map to drop a pin</Text>,
                <Map 
                    isHome={false}
                    draggable={true}
                    handler={this.mapUpdateHandler}
                    rentalsData={this.state.publicRentalData}
                    key={this.state.mapKey}
                />
            );
        }
        return parent;
    }

    render() {
        let position = "absolute";
        return (
            <View style={{height: this.state.height - 50}} >
                <TopAlert text={"Success!"} isShowing={this.state.successIsShowing}/>
                <KeyboardAwareScrollView contentContainerStyle={styles.container} scrollEnabled={this.state.scrollEnabled}>
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.modalVisible}
                        key={this.state.modalKey}
                        onModalClose={this.onModalClose}
                        onConfirmClick={() => this.onConfirmClick()}
                        labelText={this.state.modalText}
                    />
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.errorModalVisible}
                        key={this.state.errorModalKey}
                        onModalClose={this.onModalClose}
                        labelText={this.state.errorMessage}
                        isError={true}
                    />
                    <Modal
                        transparent={true}
                        visible={this.state.loadingVisible}
                        key={this.state.loadingModalKey}
                        onModalClose={this.onModalClose}
                        isLoading={false}
                        percentage={this.state.loadingPercentage}
                    />
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.tooltipIsVisible}
                        key={this.state.tooltipModalKey}
                        onModalClose={this.onModalClose}
                        labelText={this.state.tooltipMessage}
                        title={this.state.tooltipTitle}
                    />
                    <Container>
                        <View style={[styles.tooltipWrapper]}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Type of listing</Text>
                            <ToolTip
                                title="listing Type"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <View style={styles.flexBoxWrapper}>
                            <Checkbox 
                                label={"Short Term Rental"}
                                checked={this.state.strSelected}
                                key={this.state.strKey}
                                handler={checked => {
                                    let newRentalData = this.state.publicRentalData;
                                    newRentalData.listingType = checked? "str": null; 
                                    this.setState({
                                        strSelected: checked,
                                        ltrSelected: false,
                                        saleSelected: false,
                                        strKey: Math.random().toString(36).substring(7),
                                        ltrKey: Math.random().toString(36).substring(7),
                                        saleKey: Math.random().toString(36).substring(7),
                                        publicRentalData: newRentalData,
                                    })
                                }}
                            />
                            <View style={{marginTop: 10}}></View>
                            <Checkbox 
                                label={"Long Term Rental"}
                                checked={this.state.ltrSelected}
                                key={this.state.ltrKey}
                                handler={checked => {
                                    let newRentalData = this.state.publicRentalData;
                                    newRentalData.listingType = checked? "ltr": null; 
                                    this.setState({
                                        strSelected: false,
                                        ltrSelected: checked,
                                        saleSelected: false,
                                        strKey: Math.random().toString(36).substring(7),
                                        ltrKey: Math.random().toString(36).substring(7),
                                        saleKey: Math.random().toString(36).substring(7),
                                        publicRentalData: newRentalData,
                                    })
                                }}
                            />
                            <View style={{marginTop: 10}}></View>
                            <Checkbox 
                                label={"For Sale"}
                                checked={this.state.saleSelected}
                                key={this.state.saleKey}
                                handler={checked => {
                                    let newRentalData = this.state.publicRentalData;
                                    newRentalData.listingType = checked? "forSale": null; 
                                    this.setState({
                                        strSelected: false,
                                        ltrSelected: false,
                                        saleSelected: checked,
                                        strKey: Math.random().toString(36).substring(7),
                                        ltrKey: Math.random().toString(36).substring(7),
                                        saleKey: Math.random().toString(36).substring(7),
                                        publicRentalData: newRentalData,
                                    })
                                }}
                            />
                        </View>
                        {!!this.state.listingTypeError ? 
                            <Text allowFontScaling={false} style={styles.errorStyle}>Please select a type of listing</Text> : null
                        }
                    </Container>
                    <Container>
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Title</Text>
                            <ToolTip
                                title="Title"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <StyledTextInput 
                            onChange={(text)=>{
                                let newRentalData = this.state.publicRentalData;
                                newRentalData.title = text;
                                this.setState({ publicRentalData: newRentalData});
                            }}
                            placeholder={"Propery Title"}
                            value={this.state.publicRentalData.title}
                        />
                        {!!this.state.titleError ? 
                            <Text allowFontScaling={false} style={styles.errorStyle}>Please enter a title</Text> : null
                        }
                    </Container>
                    <Container>
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Images</Text>
                            <ToolTip
                                title="Images"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        {this.state.photosError ? 
                            <Text allowFontScaling={false} style={styles.errorStyle}>Please add at least one image</Text> : null
                        }
                        <Text allowFontScaling={false}>Tap and hold images to rearrange their order.</Text>
                        <ImageUploader
                            imageData={this.state.publicRentalData.photoUrls}
                            newImageHandler={this.newImageDataHandler}
                            removeImageHandler={this.removeImageHandler}
                            key={this.state.slideShowKey}
                            onDrag={()=>{
                                this.setState({scrollEnabled: false})
                            }}
                            onDragRelease={(data)=>{
                                let newRentalData = this.state.publicRentalData;
                                let photoUrls = [];
                                data.forEach(element => {
                                    photoUrls.push(element.uri);
                                });
                                newRentalData.photoUrls = photoUrls;
                                this.setState({
                                    publicRentalData: newRentalData,
                                    scrollEnabled: true,
                                })
                            }}
                            onLoading={loading => {
                                this.setState({
                                    loading: loading,
                                })
                            }}
                        />
                    </Container>

                    <Container>
                        <View style={[styles.tooltipWrapper]}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Description</Text>
                            <ToolTip
                                title="Description"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <TextInput
                            style={[styles.inputBox, styles.multilineBox]}
                            onChangeText={description => {
                                let newRentalData = this.state.publicRentalData;
                                newRentalData.description = description;
                                this.setState({ publicRentalData: newRentalData});
                            }}
                            placeholder='Description'
                            placeholderTextColor='grey'
                            autoCapitalize='none'
                            multiline = {true}
                            numberOfLines={4}
                            value={this.state.publicRentalData.description}
                        />
                        {!!this.state.descriptionError ? 
                            <Text allowFontScaling={false} style={styles.errorStyle}>Please enter a description</Text> : null
                        }
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Price Description</Text>
                            <ToolTip
                                title="Price"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                         <StyledTextInput 
                            onChange={(text)=>{
                                let newRentalData = this.state.publicRentalData;
                                newRentalData.price = text;
                                this.setState({ publicRentalData: newRentalData});
                            }}
                            placeholder={"Price Description"}
                            value={this.state.publicRentalData.price}
                        />
                        {!!this.state.priceError ? 
                            <Text allowFontScaling={false} style={styles.errorStyle}>Please enter a price</Text> : null
                        }
                    </Container>

                    <Container>
                        <View style={[styles.tooltipWrapper]}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>House Rules (optional)</Text>
                            <ToolTip
                                title="House Rules"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <TextInput
                            style={[styles.inputBox, styles.multilineBox]}
                            onChangeText={houseRules => {
                                let newRentalData = this.state.publicRentalData;
                                newRentalData.houseRules = houseRules;
                                this.setState({ publicRentalData: newRentalData});
                            }}
                            placeholder='House Rules'
                            placeholderTextColor='grey'
                            multiline={true}
                            numberOfLines={4}
                            value={this.state.publicRentalData.houseRules}
                        />
                        {!!this.state.houseRulesError ? 
                            <Text allowFontScaling={false} style={styles.errorStyle}>Please enter house rules</Text> : null
                        }
                    </Container>
                    <Container>
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Approximate location</Text>
                            <ToolTip
                                title="Approximate location"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        {this.state.mapError ? 
                            <Text allowFontScaling={false} style={styles.errorStyle}>Please select an approximate location</Text> : null
                        }
                        {this.constructMap()}
                    </Container>

                    <Container>
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>City (optional)</Text>
                            <ToolTip
                                title="City (optional)"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                         <StyledTextInput 
                            onChange={(text)=>{
                                let newRentalData = this.state.publicRentalData;
                                newRentalData.city = text;
                                this.setState({ publicRentalData: newRentalData});
                            }}
                            placeholder={"City (optional)"}
                            value={this.state.publicRentalData.city}
                        />
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>State (optional)</Text>
                            <ToolTip
                                title="State (optional)"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                         <StyledTextInput 
                            onChange={(text)=>{
                                let newRentalData = this.state.publicRentalData;
                                newRentalData.state = text;
                                this.setState({ publicRentalData: newRentalData});
                            }}
                            placeholder={"State or Providence (optional)"}
                            value={this.state.publicRentalData.state}
                        />
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Country (optional)</Text>
                            <ToolTip
                                title="Country (optional)"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <CountrySelect 
                            selectedCountry={this.state.publicRentalData.country}
                            handler={this.countrySelectHandler}
                        />
                    </Container>

                    <Container>
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Amenities</Text>
                            <ToolTip
                                title="Amenities"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <View style={{marginTop: 10}}></View>
                        <View style={styles.flexBoxWrapper}>
                            <Checkbox 
                                label={"Electric"}
                                checked={this.state.publicRentalData.electric}
                                key={this.state.electricKey}
                                handler={this.electricCheckHandler}
                                icon={"electric"}
                            />
                            <View style={{marginTop: 10}}></View>
                            <Checkbox 
                                label={"Pet Friendly"}
                                checked={this.state.publicRentalData.petFriendly}
                                key={this.state.petKey}
                                handler={this.petCheckHandler}
                                icon={"pet"}
                            />
                            <View style={{marginTop: 10}}></View>
                            <Checkbox 
                                label={"Washer/ Dryer"}
                                checked={this.state.publicRentalData.washerDryer}
                                key={this.state.washerKey}
                                handler={this.washerCheckHandler}
                                icon={"washer"}
                            />
                        </View>
                        <ExpandableView>
                            <View style={[styles.flexBoxWrapper]}>
                                <Ammenities 
                                    rentalData={this.state.publicRentalData} 
                                    onCheck={(extraAmmenityData)=>{
                                        let rentalData = this.state.publicRentalData;
                                        rentalData.extraAmmenities = extraAmmenityData
                                        this.setState({
                                            publicRentalData: rentalData,
                                        })
                                    }}
                                />
                            </View>
                        </ExpandableView>
                    </Container>
            
                    <Container>
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Number of bedrooms</Text>
                            <ToolTip
                                title="Number of bedrooms"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <Stepper
                            count={this.state.publicRentalData.bedrooms}
                            key={this.state.bedroomKey}
                            navigation={this.props.navigation}
                            handler={bedrooms => {
                                let newRentalData = this.state.publicRentalData;
                                newRentalData.bedrooms = bedrooms;
                                this.setState({ publicRentalData: newRentalData});
                            }}
                        />
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Number of bathrooms</Text>
                            <ToolTip
                                title="Number of bathrooms"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <Stepper
                            count={this.state.publicRentalData.bathrooms}
                            key={this.state.bathroomKey}
                            navigation={this.props.navigation}
                            handler={bathrooms => {
                                let newRentalData = this.state.publicRentalData;
                                newRentalData.bathrooms = bathrooms;
                                this.setState({ publicRentalData: newRentalData});
                            }}
                        />
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Number of people rental accommodates</Text>
                            <ToolTip
                                title="Number of people rental accommodates"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <Stepper
                            count={this.state.publicRentalData.accommodates}
                            key={this.state.accomodatesKey}
                            navigation={this.props.navigation}
                            handler={accommodates => {
                                let newRentalData = this.state.publicRentalData;
                                newRentalData.accommodates = accommodates;
                                this.setState({ publicRentalData: newRentalData});
                            }}
                        />
                    </Container>

                    <Container>
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Type of accommodation</Text>
                            <ToolTip
                                title="Type of accommodation"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <View style={styles.flexBoxWrapper}>
                            <Checkbox 
                                label={"Home"}
                                checked={
                                    this.state.publicRentalData.typeOfaccommodation == "Home" ||
                                    this.state.publicRentalData.typeOfaccommodation == "Entire Home" }
                                key={this.state.homeKey}
                                handler={checked => this.accomodationTypeCheckHandler(checked, "Entire Home" )}
                                icon={"house"}
                            />
                            <View style={{marginTop: 10}}></View>
                            <Checkbox 
                                label={"Bedroom"}
                                checked={this.state.publicRentalData.typeOfaccommodation == "Bedroom"}
                                key={this.state.bedroomCheckKey}
                                handler={checked => this.accomodationTypeCheckHandler(checked, "Bedroom" )}
                                icon={"bedroom"}
                            />
                            <View style={{marginTop: 10}}></View>
                            <Checkbox 
                                label={"Tiny House"}
                                checked={this.state.publicRentalData.typeOfaccommodation == "Tiny House"}
                                key={this.state.tinyHouseKey}
                                handler={checked => this.accomodationTypeCheckHandler(checked, "Tiny House" )}
                                icon={"tinyHouse"}
                            />
                        </View>
                        <ExpandableView>
                            <View style={styles.flexBoxWrapper}>
                                <Checkbox 
                                    label={"Yurt"}
                                    checked={this.state.publicRentalData.typeOfaccommodation == "Yurt"}
                                    key={this.state.yurtKey}
                                    handler={checked => this.accomodationTypeCheckHandler(checked, "Yurt" )}
                                    icon={"yurt"}
                                />
                                <View style={{marginTop: 10}}></View>
                                <Checkbox 
                                    label={"Cabin"}
                                    checked={this.state.publicRentalData.typeOfaccommodation == "Cabin"}
                                    key={this.state.cabinKey}
                                    handler={checked => this.accomodationTypeCheckHandler(checked, "Cabin")}
                                    icon={"cabin"}
                                />
                                <View style={{marginTop: 10}}/>
                                <Checkbox 
                                    label={"Tree house"}
                                    checked={this.state.publicRentalData.typeOfaccommodation == "Tree house"}
                                    key={this.state.treeHouseKey}
                                    handler={checked => this.accomodationTypeCheckHandler(checked, "Tree house" )}
                                    icon={"treeHouse"}
                                />
                                <View style={{marginTop: 10}}/>
                                <Checkbox 
                                    label={"Campground"}
                                    checked={this.state.publicRentalData.typeOfaccommodation == "Campground"}
                                    key={this.state.campGroundKey}
                                    handler={checked => this.accomodationTypeCheckHandler(checked, "Campground" )}
                                    icon={"campground"}
                                />
                                <View style={{marginTop: 10}}/>
                                <Checkbox 
                                    label={"RV"}
                                    checked={this.state.publicRentalData.typeOfaccommodation == "RV"}
                                    key={this.state.rvKey}
                                   handler={checked => this.accomodationTypeCheckHandler(checked, "RV" )}
                                    icon={"rv"}
                                />
                                <View style={{marginTop: 10}}/>
                                <Checkbox 
                                    label={"Hotel"}
                                    checked={this.state.publicRentalData.typeOfaccommodation == "Hotel"}
                                    key={this.state.hotelKey}
                                    handler={checked => this.accomodationTypeCheckHandler(checked, "Hotel" )}
                                    icon={"hotel"}
                                />
                                <View style={{marginTop: 10}}/>
                                <Checkbox 
                                    label={"Hostel"}
                                    checked={this.state.publicRentalData.typeOfaccommodation == "Hostel"}
                                    key={this.state.hostelKey}
                                    handler={checked => this.accomodationTypeCheckHandler(checked, "Hostel" )}
                                    icon={"hostel"}
                                />
                                <View style={{marginTop: 10}}/>
                                <Checkbox 
                                    label={"Bed and breakfast"}
                                    checked={this.state.publicRentalData.typeOfaccommodation == "Bed and breakfast"}
                                    key={this.state.bedKey}
                                    handler={checked => this.accomodationTypeCheckHandler(checked, "Bed and breakfast" )}
                                    icon={"bedAndBreakfast"}
                                />
                                <View style={{marginTop: 10}}/>
                                <Checkbox 
                                    label={"Other"}
                                    checked={this.state.publicRentalData.typeOfaccommodation == "Other"}
                                    key={this.state.otherKey}
                                    handler={checked => this.accomodationTypeCheckHandler(checked, "Other" )}
                                    icon={"other"}
                                />
                                <View style={{marginTop: 10}}/>
                            </View>
                        </ExpandableView>
                    </Container>

                    <Container>
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Public Website (optional)</Text>
                            <ToolTip
                                title="Public Website (optional)"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <StyledTextInput 
                            onChange={(text)=>{
                                let newRentalData = this.state.publicRentalData;
                                newRentalData.website = text;
                                this.setState({ publicRentalData: newRentalData});
                            }}
                            placeholder={"Public website (optional)"}
                            value={this.state.publicRentalData.website}
                        />
                        {this.state.websiteError ? 
                            <Text allowFontScaling={false} style={styles.errorStyle}>Please enter a valid website URL</Text> : null
                        }

                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Contact Email (optional)</Text>
                            <ToolTip
                                title="Contact Email (optional)"
                                onPress={this.tooltipOnPress}
                            />
                        </View>
                        <StyledTextInput 
                            onChange={(text)=>{
                                let newRentalData = this.state.publicRentalData;
                                newRentalData.contactEmail = text;
                                this.setState({ publicRentalData: newRentalData});
                            }}
                            placeholder={"Contact Email (optional)"}
                            value={this.state.publicRentalData.contactEmail}
                        />
                        {this.state.contactEmailError ? 
                            <Text allowFontScaling={false} style={styles.errorStyle}>Please enter a valid Email</Text> : null
                        }
                    </Container>

                    <Container>
                        <View style={styles.tooltipWrapper}>
                            <Text allowFontScaling={false} style={styles.headerLabel}>Search Tags (optional)</Text>
                            <ToolTip
                                title="Search Tags (optional)"
                                onPress={this.tooltipOnPress}
                            />
                        </View >
                        <Text style={styles.sublabel}>Tap the plus button to add a tag. Limit 5</Text>
                        <View style={styles.searchTagsInputWrapper}>
                            <StyledTextInput 
                                onChange={(text)=>{
                                    let trimmedTag = text.replace(".", '')
                                                        .replace("/", '')
                                                        .replace("#", "")
                                                        .replace("$", "")
                                                        .replace("[", "")
                                                        .replace("]", "")
                                                        .replace('"', "")
                                                        .replace('”', "")
                                                        .replace("'", "")
                                                        .replace("’", "");
                                    this.setState({ inputSearchTerm: trimmedTag});
                                }}
                                placeholder={"Search Tag (optional)"}
                                value={this.state.inputSearchTerm}
                                key={this.state.searchInputKey}
                            />
                            <TouchableHighlight 
                                style={styles.addButton}
                                onPress={() => {
                                    this.addSearchTerm();
                                    this.setState({ 
                                        inputSearchTerm: null,
                                        searchInputKey: Math.random().toString(36).substring(7),
                                    });
                                }}
                            >
                                <Image
                                    source={require('../assets/addIcon.png')}
                                    style={styles.addButtonImage}
                                />
                            </TouchableHighlight>
                        </View>
                        <View style={styles.searchTagsWrapper}>
                            {this.buildSearchTermButtons()}
                        </View >
                        { this.constuctActionButton() }
                    </Container>
                    <Footer hideText={true}/>
                </KeyboardAwareScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    inputBox: {
        width: '85%',
        maxWidth: 600,
        marginBottom: 10,
        padding: 15,
        fontSize: 16,
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        backgroundColor: "#f5f5f5",
    },
    button: {
        marginTop: 30,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    whiteButtonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'white'
    },
    headerLabel: {
        fontSize: 18,
        fontWeight: "600",
      },
    label: {
        margin: 0,
        marginTop: 10,
        fontSize: 12,
        fontWeight: "600",
    },
    itemStyle: {
        fontSize: 12,
        height: 75,
        color: 'black',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    errorStyle: {
        color: "red",
        fontWeight:"bold",
    },
    mt40: {
        marginTop: 40,
    },
    mt50: {
        marginTop: 50,
    },
    mb50: {
        marginBottom: 50,
    },
    tooltipWrapper: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    searchTagsInputWrapper: {
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        padding: 30,
        paddingRight: 10,
        paddingTop: 0,
        paddingBottom: 10,
        width: "100%",
        maxWidth: 1000,
    }, 
    flexBoxWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    },
    searchTagsWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: 1100,
    },
    addButton: {
        width: 60,
        height: 60,
        padding: 10,
        paddingRight: 0,
    },
    addButtonImage: {
        width: 40,
        height: 40,
    },
    searchTermButton: {
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: 'white',
        borderColor: '#a3a3a3',
        borderWidth: 1,
        borderRadius: 5,
        width: 160,
        margin: 2,
    },
    searchTermButtonText: {
        fontSize: 14,
        fontWeight: "600",
        color: 'black'
    },
    xIcon: {
        width: 30,
        height: 30,
        position: "absolute",
        right: 5,
        zIndex: 5,
    },
    searchTermWrapper: {
        width: 160,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        padding: 8,
        paddingRight: 20,
    },
    sublabel: {
        fontSize: 12,
        paddingBottom: 10,
    },
    blueButton: {
        alignItems: 'center',
        backgroundColor: '#3a93f1',
        borderColor: '#2790ff',
        borderWidth: 1,
        borderRadius: 25,
        paddingTop: 13,
        width: 150,
        height: 50,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    multilineBox: {
        minHeight: 80,  
    },
})