import React, { Component } from 'react';
import { StyleSheet, Text, View, ScrollView, TouchableHighlight } from 'react-native';
import * as Linking from 'expo-linking';
import Accordion from 'react-native-collapsible/Accordion';

import Modal from "../compenents/Modal";
import Container from "../compenents/Container";
import ActionButton from '../compenents/ActionButton';
import GoldInfo from '../compenents/GoldInfo';
import SilverInfo from '../compenents/SilverInfo';
import BronzeInfo from '../compenents/BronzeInfo';

const SECTIONS = [
    {title: 'Who can become a host?'},
    {title: 'Can non-physicians use the app?'},
    {title: 'Once I am a host, how do I get paid?'},
    {title: 'What are the different listing levels?'},
    {title: 'What is the cancelation policy?'},
    {title: 'Does Doc to Doc offer damage insurance?'},
    {title: 'Can I give physicians a discount?'},
    {title: 'Can I rent to Physicians only?'},
    {title: 'Where can I share my listing?'},
];

class BecomeAHost extends Component {
    render() {
        return(
            <View>
                 <Text style={{marginBottom: 10}}>
                    {[`                     `,
                        " Hosts must either be a currently practicing physician or retired. In order to become a host on Doc to Doc Rentals you must sign up as a physician. Signing up as a physician means"+
                        " you will need to either be a member of the Facebook group, or enter a few questions about your credentials.",
                    ]}
                </Text>
            </View>
        )
    }
}

class NonPhysicians extends Component {
    render() {
        return(
            <View>
                 <Text style={{marginBottom: 10}}>
                    {[`                     `,
                        "Yes! You can reccommend that app to anyone you would like! We only limit the hosting ability to Physicians. "
                    ]}
                 </Text>
            </View>
        )
    }
}

class GetPaid extends Component {

    navigateToStripe = () => {
        Linking.openURL('https://stripe.com').catch(error => {
            //show error modal
            this.setState({
                errorModalVisible: true,
                errorModalKey: Math.random().toString(36).substring(7),
            });
        });
    }

    render() {
        return(
            <View>
                 <Text style={{marginBottom: 10}}>
                    {[`                     `,
                        "Doc to Doc Rentals uses "
                    ]}
                    <TouchableHighlight
                        onPress={()=>{
                            this.navigateToStripe();
                        }}
                    >
                        <Text style={{textDecorationLine: "underline"}}>Stripe</Text>
                    </TouchableHighlight>
                    {[
                        " to handle rentals transaction and host payouts. Host walk through an onboarding process where they enter their business info. ",
                        " Doc to Doc collects rental fees at the time of booking, and pays hosts out on the day of check out. "+

                        "Hosts can onboard with Stripe as well as find their Stripe dashboard within the Doc to Doc app on the ",
                        <Text style={{fontWeight: "600"}}>"Revenue</Text>,
                        " page. "
                    ]}
                </Text>
            </View>
        )
    }
}

class ListingLevels extends Component {
    render() {
        return(
            <View>
                 <Text style={{marginBottom: 10}}>
                    {[`                     `,
                        "We offer 3 different listing levels to accomodate for different needs. ",
                        "Below are the 3 levels and the features they come with:"
                    ]}
                </Text>
                <View style={{display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap",marginBottom: 10}}>
                    <BronzeInfo/>
                    <SilverInfo/>
                    <GoldInfo/>
                </View>
            </View>
        );
    }
}

class CancelationPolicy extends Component {
    render() {
        return(
            <View>
                 <Text 
                    allowFontScaling={false} 
                    style={[styles.labelText, ]}
                >
                    <Text
                        allowFontScaling={false} 
                        style={[styles.labelText, {marginBottom: 10, fontWeight: "600"}]}
                    >Booked within the Doc to Doc app: </Text>
                    Both host and guest can cancel up to 24 hours prior to check-in for a full refund minus the Stripe fee (3% of transaction). Cancelations with less the 24 hours till check in must be approved by both parties as well as an admin and may result in fees.
                </Text>
                {/* <View style={{width: 400, height: 10}}/> */}
                <Text 
                    allowFontScaling={false} 
                    style={[styles.labelText, {marginBottom: 10, }]}
                >
                    <Text
                        allowFontScaling={false} 
                        style={[styles.labelText, {marginBottom: 10, fontWeight: "600"}]}
                    >
                        {"\n"}Booked by contacting host directly: </Text>
                        Each host will manage their own booking and cancellations. Be sure to check with the host for their cancellation policy.
                </Text>
            </View>
        );
    }
}

class DamageProtection extends Component {
    render() {
        return(
            <View>
                 <Text style={{marginBottom: 10}}>
                    {[`                     `,
                        "No, Doc to Doc Rentals does not offer damage protection insurance. We reccomend all hosts have homeowners insurance which covers damage.",
                    ]}
                </Text>
            </View>
        );
    }
}

class PhysicianDiscount extends Component {

    navigateToSupport = () => {
        this.props.navigation.navigate("Support");
    }

    render() {
        return(
            <View>
                 <Text style={{marginBottom: 10}}>
                    {[`                     `,
                        "Yes, hosts can set a different nightly price for Physicians. ",
                    ]}
                </Text>
            </View>
        );
    }
}

class PhysicianOnly extends Component {
    render() {
        return(
            <View>
                 <Text style={{marginBottom: 10}}>
                    {[`                     `,
                        " Yes, hosts can elect to only show their rental to users who have signed in as a physician. Hosts can also request or require NPI numbers as well as set different prices for Physicians."
                    ]}
                </Text>
            </View>
        )
    }
}

class WhereToShare extends Component {
    render() {
        return(
            <View>
                 <Text style={{marginBottom: 10}}>
                    {[`                     `,
                        "Share your listing on any social media or link it in you personal site. We definiltey reccommend sharing your listing in the Doc to Doc Rentals Facebook group as well! "
                    ]}
                </Text>
            </View>
        )
    }
}

export default class Support extends Component {

    state = {
        activeSections: [],  
    };

    renderHeader = (section, index) => {
        return (
          <View style={styles.header}>
            <ActionButton
                text={section.title}
                handler={()=>{
                    if (this.state.activeSections[0] == index) {
                        this.setState({ activeSections:  []});
                    } else {
                        this.setState({ activeSections:  [index]});
                    }
                }}
                size={"extralarge"}
            />
          </View>
        );
    };

    renderContent = (section) => {
        let description = <BecomeAHost navigation={this.props.navigation}/>;
        switch(section.title) {
            case "Can non-physicians use the app?":
                description = <NonPhysicians navigation={this.props.navigation}/>;
                break;
            case "Once I am a host, how do I get paid?":
                description = <GetPaid navigation={this.props.navigation}/>;
                break;
            case "What are the different listing levels?":
                description = <ListingLevels navigation={this.props.navigation}/>;;
                break;
            case "What is the cancelation policy?":
                description = <CancelationPolicy navigation={this.props.navigation}/>;;
                break;
            case "Does Doc to Doc offer damage insurance?":
                description = <DamageProtection navigation={this.props.navigation}/>;;
                break;
            case "Can I give physicians a discount?":
                description = <PhysicianDiscount navigation={this.props.navigation}/>;;
                break;
            case "Can I rent to Physicians only?":
                description = <PhysicianOnly navigation={this.props.navigation}/>;;
                break;
            case "Where can I share my listing?":
                description = <WhereToShare navigation={this.props.navigation}/>;;
                break;
            default:
                break;
        }
        return (description)
    }

    updateSections = (activeSections) => {
        this.setState({ activeSections });
    };

    openMailApp = () => {
        Linking.openURL('mailto:doc2docrentals@gmail.com').catch(error => {
            //show error modal
            this.setState({
                errorModalVisible: true,
                errorModalKey: Math.random().toString(36).substring(7),
            });
        });
    }

    onModalClose = () => {
        this.setState({
            errorModalVisible: false,
        });
    }

    render() {
        return (
            <ScrollView contentContainerStyle={styles.container}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.errorModalVisible}
                    key={this.state.errorModalKey}
                    onModalClose={this.onModalClose}
                    isError={true}
                    labelText="Could not open any mail apps."
                />
                <Container title={"Support/ FAQ"}>
                    <Text style={{marginBottom: 10}}>Having trouble? Check our frequently asked questions.</Text>
                      <Accordion
                        containerStyle={styles.accordion}
                        sections={SECTIONS}
                        activeSections={this.state.activeSections}
                        renderHeader={this.renderHeader}
                        renderContent={this.renderContent}
                        onChange={this.updateSections}
                    />
                </Container>
                <Container title={"Delete Account"}>
                        <Text allowFontScaling={false} style={styles.centeredText}>
                            Users can delete their account at anytime by navigating to the settings page. 
                            From there they can tap or click "Delete Account" and follow the promopts.
                            Or users can reach out to us at doc2docrentals@gmail.com to have an admin delete their account.
                      </Text>
                    <ActionButton 
                        text={"Email Us"}
                        handler={()=>{
                            this.openMailApp();
                        }}
                    />
                </Container>
                <Container title={"Contact"}>
                        <Text allowFontScaling={false} style={styles.centeredText}>
                          Still having trouble or simply have questions? Reach out to us at doc2docrentals@gmail.com
                      </Text>
                    <ActionButton 
                        text={"Email Us"}
                        handler={()=>{
                            this.openMailApp();
                        }}
                    />
                </Container>
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        fontSize: 24,
        marginBottom: 10,
        fontWeight: "700",
        textAlign: "center",
    },
    centeredText: {
        textAlign: "center",
        maxWidth: 800,
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 10
    },
    button: {
        marginTop: 30,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    buttonSignup: {
        fontSize: 12
    }
})