import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  Image,
  TouchableHighlight,
  Dimensions,
  Platform,
  ActivityIndicator,
} from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { DraggableGrid } from 'react-native-draggable-grid';
import * as ImageManipulator from 'expo-image-manipulator';

import Modal from './Modal';
import ActionButton from './ActionButton';

export default class ImageUploader extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            data:[],
            modalVisible: false,
            modalKey: Math.random().toString(36).substring(7),
            errorModalVisible: false,
            errorModalKey: Math.random().toString(36).substring(7),
            columns: 3,
            draggableKey: Math.random().toString(36).substring(7),
            loading: false,
        };
    }

    componentDidMount() {
        if (!!this.props.imageData) {
            let data = [];
            this.props.imageData.forEach(element => {
                data.push({
                    name: `${data.length+1}`,
                    key: Math.random().toString(36).substring(7),
                    uri: element
                });
            });
            this.setState({
                data: data,
            }) 
        } else {
            this.setState({
                data:[{name:'Add Image',key:'one'}],
            }) 
        }
        this.checkWidth();
        if (Platform.OS == "web") {
            window.addEventListener('resize', this.checkWidth);
        }
        (async () => {
            if (Platform.OS !== 'web') {
                const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
                if (status !== 'granted') {
                    alert('Sorry, we need camera roll permissions to make this work!');
                }
            }
        })();
    }

    checkWidth = () => {
        let columns = 4;
        if (Platform.OS == "web") {
            if (Dimensions.get("window").width <= 1000) {
                if (Dimensions.get("window").width <= 873 && Dimensions.get("window").width > 570) {
                    columns = 4;
                } else if (Dimensions.get("window").width <= 435) {
                    columns = 2;
                } else {
                    columns = 3;
                }
            } else if (Dimensions.get("window").width > 1100) {
                columns = 5;
            } 
        } else {
            if (Dimensions.get("window").width <= 1000) {
                columns = 3;
                if (Dimensions.get("window").width <= 550) {
                    columns = 2;
                }
            } 
        }
        this.setState({
            columns: columns,
            draggableKey: Math.random().toString(36).substring(7),
        });
    }


    pickImage = async () => {
        this.setState({loading: true});
        if (!!this.props.onLoading) {
            this.props.onLoading(true);
        }
        try {
            if (this.props.imageData) {
                if (this.props.imageData.length < 20) {
                    let result = await ImagePicker.launchImageLibraryAsync({
                        mediaTypes: ImagePicker.MediaTypeOptions.Images,
                        allowsEditing: false,
                        allowsMultipleSelection: true,
                        orderedSelection: true,
                        aspect: [4, 3],
                        quality: 0.9,
                    });
                    if (!result.canceled) {
                        let tempData = []
                        this.props.imageData.forEach(element => {
                            tempData.push({
                                name: `${tempData.length+1}`,
                                key: Math.random().toString(36).substring(7),
                                uri: element
                            });
                        });
                        if (result.assets == null) {
                            this.setState({loading: false});
                            if (!!this.props.onLoading) {
                                this.props.onLoading(false);
                            }
                        } else {
                            result.assets.forEach(element => {
                                tempData.push({
                                    name: "Loading",
                                    key: Math.random().toString(36).substring(7),
                                });
                            });
                            this.setState({
                                data: tempData,
                                draggableKey: Math.random().toString(36).substring(7),
                            })
                            this.setImages(result);
                        }
                    } else {
                        this.setState({loading: false});
                        if (!!this.props.onLoading) {
                            this.props.onLoading(false);
                        }
                    }
                } else {
                    this.setState({
                        loading: false,
                        errorModalVisible: true,
                        errorModalKey: Math.random().toString(36).substring(7),
                    });
                    if (!!this.props.onLoading) {
                        this.props.onLoading(false);
                    }
                }
            } else {
                let result = await ImagePicker.launchImageLibraryAsync({
                    mediaTypes: ImagePicker.MediaTypeOptions.Images,
                    allowsEditing: false,
                    allowsMultipleSelection: true,
                    orderedSelection: true,
                    aspect: [4, 3],
                    quality: 0.9,
                });
                if (!result.canceled) {
                    let tempData = [];
                    result.assets.forEach(element => {
                        tempData.push({
                            name: "Loading",
                            key: Math.random().toString(36).substring(7),
                        });
                    });
                    this.setState({
                        data: tempData,
                        draggableKey: Math.random().toString(36).substring(7),
                    })
                    this.setImages(result);
                } else {
                    this.setState({loading: false});
                    if (!!this.props.onLoading) {
                        this.props.onLoading(false);
                    }
                }
            }
        } catch (error) {
            console.log("failed to pick images: ",error);
        }
    }

    setImages = async (result) => {
        let newImageData = [];
        if (!!this.props.imageData) {
            if (!!this.props.imageData[0]) {
                if (this.props.imageData[0].key != "One") {
                    newImageData = this.props.imageData;
                }
            }
        }
        for (let index in result.assets) {
            if (newImageData.length < 20) {
                let image = result.assets[index];
                let dimensions = await new Promise((resolve, reject) => {
                    Image.getSize(image.uri, async (width, height) => {
                        resolve({width: width, height: height});
                    });
                });
                let compression = 0.9;
                if (Platform.OS != "web") {
                    compression = 0.2;
                }
                if (dimensions.width > dimensions.height) {
                    let data = await ImageManipulator.manipulateAsync(
                        image.uri,
                        [{resize: {width: 1800}}],
                        { compress: compression, format: ImageManipulator.SaveFormat.JPEG, }
                    )
                    newImageData.push(data.uri);
                } else {
                    let data = await ImageManipulator.manipulateAsync(
                        image.uri,
                        [{resize: {width: 900}}],
                        { compress: compression, format: ImageManipulator.SaveFormat.JPEG, }
                    )
                    newImageData.push(data.uri);
                }
            }
        }
        this.props.newImageHandler(newImageData);
        this.setState({loading: false});
        if (!!this.props.onLoading) {
            this.props.onLoading(false);
        }
    }

    onModalClose = () => {
        this.setState({modalVisible : false});
    }

    removeSelectedImage = (item) => {
        let newData = this.state.data.filter(element => {
            return element.key != item.key;
        });
        for (let index in newData) {
            newData[index].name = `${parseInt(index) + 1}`;
        }
        this.setState({
            data: newData,
        });
        let handlerData = [];
        newData.forEach(element => {
            handlerData.push(element.uri);
        });
        this.props.removeImageHandler(handlerData);
    }

  renderItem(item, self) {
    return (
      <View
        style={styles.item}
        key={item.key}
      >
        <TouchableHighlight 
            onPress={() => {self.removeSelectedImage(item)}}
            style ={styles.removeIcon}>
                <Image
                    source={require('../assets/xIcon.png')}
                    style={styles.removeIconImage}
                ></Image>
        </TouchableHighlight>
        {
            item.name == "Loading" ? 
            <ActivityIndicator size="small" />:
            <Text style={styles.itemText}>{item.name}</Text>
        }
        {
            !!item.uri ?
            <Image 
                resizeMode="cover"
                style={styles.thumbnail}
                source={{
                    uri: item.uri,
                }}
            />: null
        }
      </View>
    );
  }

  render() {
    return (
        <View style={styles.mainWrapper}>
            <View style={styles.wrapper}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.errorModalVisible}
                    key={this.state.errorModalKey}
                    onModalClose={this.onModalClose}
                    labelText="You can only upload 20 images."
                    isError={true}
                />
                <DraggableGrid
                    key={this.state.draggableKey}
                    numColumns={this.state.columns}
                    renderItem={item => this.renderItem(item, this)}
                    data={this.state.data}
                    onDragStart={()=>{
                    if (!!this.props.onDrag){
                        this.props.onDrag();
                    }}}
                    onDragRelease={(data) => {
                        for (let index in data) {
                            data[index].name = `${parseInt(index) + 1}`;
                        }
                        this.setState({data});
                        if (!!this.props.onDragRelease){
                            this.props.onDragRelease(data);
                        }
                    }}
                    style={Platform.OS == "web" ? {flex: "none"}: {flex: 0}}
                />
            </View>
            <View 
                style={{
                    backgroundColor: 'white',
                    width: "100%",
                    height: 70,
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <ActionButton
                    text={" Upload Images"}
                    handler={() => {
                        this.pickImage()
                    }}
                    icon={require('../assets/uploadIcon.png')}
                    loading={this.state.loading}
                />
            </View>
        </View>
    );
  }
}

const styles = StyleSheet.create({
    mainWrapper: {
        width:'100%',
        justifyContent:'center',
    },
    wrapper:{
        width:'100%',
        justifyContent:'center',
        backgroundColor: "#f5f5f5",
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        minHeight: 150,
    },
    item:{
        width:130,
        height:130,
        borderRadius:8,
        backgroundColor:'#ebecf0',
        justifyContent:'center',
        alignItems:'center',
        overflow: "hidden",
        borderColor: "white",
        borderWidth: 3,
    },
    itemText:{
        fontSize:40,
        color:'#FFFFFF',
        position: "absolute",
        zIndex: 10,
        opacity: 0.5,
    },
    removeIcon: {
        height: 30,
        width:30,
        borderRadius: 15,
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
        top: 5,
        right: 5,
        zIndex: 12,
    },
    removeIconImage: {
        height: 30,
        width:30,
        zIndex: 12,
    },
    thumbnail: {
        width: 130,
        height: 130,
        borderRadius: 8,
    }
});