import React, { Component } from 'react';
import {Image, Text, View, Dimensions } from 'react-native';


export default class Footer extends Component {
  render() {
    return (
      <View
        style={{ 
          width: "100%",
          alignItems: 'center',
          height: 30,
          justifyContent: 'center',
      }}>
        {!!this.props.hideText ? 
            null : 
            <View
                style={{ 
                  width: "100%",
                  alignItems: 'center',
                  justifyContent: 'center',
              }}
            >
              <Text style={{fontSize: 10, fontWeight: "600"}}>
                    A K Kay Moody company  © 2021-2024 Doc 2 Doc Rentals LLC
              </Text>
            </View>
        }
      </View>
    );
  }
}