import { 
    getDatabase, ref, query, orderByKey, get,
    limitToFirst, startAt, orderByChild, equalTo 
} from "firebase/database";
import { 
    getFirestore, collection, where, getDocs,
    getDoc, orderBy, doc, limit, startAt as fbStartAt,
} from "firebase/firestore";
import { query as firestoreQuery } from "firebase/firestore";
import geohash from "ngeohash";

export default class ListingRetreiver {

    getListings = async (filterData, lastPostID) => {
        let finalData = {};
        if (!!filterData) {
            if (!!filterData.location || !!filterData.keyword) {
                if (!!filterData.location) {
                    let locationData = await this.searchLocation(filterData.location.lat, filterData.location.lng);
                    let filteredData = this.filterResults(locationData, null, filterData);
                    finalData = filteredData;
                } 
                if (!!filterData.keyword) {
                    let keywordData = await this.searchKeyword(filterData.keyword);
                    let filteredData = this.filterResults(keywordData, null, filterData);
                    finalData = {...finalData, ...filteredData};
                }
            } else if (!!filterData.listingType && filterData.listingType != "str") {
                //get all listings of type
                let filter = [{key : "listingType", value: filterData.listingType, equaltiy: "=="}]
                let listingData = await this.getListingsWithCriteria(filter);
                let filteredlistingData = this.filterResults(listingData, null, filterData);
                finalData = {...finalData, ...filteredlistingData}
            } else {
                let listingData = null;
                //check if they have any large filters
                if ((!!filterData.bedrooms && filterData.bedrooms > 5)||
                    (!!filterData.bathrooms && filterData.bathrooms > 5)) {
                    if (!!filterData.bedrooms) {
                        if (filterData.bedrooms > 5) {
                            //get all bathroom data
                            let filter = [{key : "bedrooms", value: filterData.bedrooms, equaltiy: ">="}]
                            listingData = await this.getListingsWithCriteria(filter);
                            let filteredlistingData = this.filterResults(listingData, null, filterData);
                            finalData = {...finalData, ...filteredlistingData}
                        } else {
                            //get some bathroom data
                            listingData = await this.getAllListings(lastPostID);
                            let filteredlistingData = this.filterResults(listingData, null, filterData);
                            finalData = {...finalData, ...filteredlistingData}
                        }
                    }
                    if (!!filterData.bathrooms) {
                        if (filterData.bathrooms > 5) {
                            //get all bathroom data
                            let filter = [{key : "bathrooms", value: filterData.bathrooms, equaltiy: ">="}]
                            listingData = await this.getListingsWithCriteria(filter);
                            let filteredlistingData = this.filterResults(listingData, null, filterData);
                            finalData = {...finalData, ...filteredlistingData}
                        } else {
                            //get some bathroom data
                            // listingData = await this.getAllListings(lastPostID);
                            // let filteredlistingData = this.filterResults(listingData, null, filterData);
                            // finalData = {...finalData, ...filteredlistingData}
                        }
                    }
                } else {
                    //call this till we get at least 10
                    listingData = await this.getAllListings(lastPostID);
                    let originalData = listingData;
                    let filteredlistingData = this.filterResults(listingData, null, filterData);
                    let throttle = 0;
                    let lastIndex = null;
                    while (Object.keys(filteredlistingData).length < 10 && throttle < 4) {
                        if (lastIndex == null && Object.keys(originalData).length > 0) {
                            lastIndex = originalData[Object.keys(originalData)[Object.keys(originalData).length - 1]].key;
                        }
                        let moreListingData = await this.getAllListings(lastIndex);
                        lastIndex =  moreListingData[Object.keys(moreListingData)[Object.keys(moreListingData).length - 1]].key;
                        let moreFilteredListing = this.filterResults(moreListingData, null, filterData);
                        filteredlistingData = {...filteredlistingData, ...moreFilteredListing};
                        throttle += 1;
                    }
                    finalData = {...finalData, ...filteredlistingData}
                }
            }
            if (!!filterData.checkIn || !!filterData.checkOut) {
                let availabilityData = await this.getAvailabilityData(finalData);
                return this.filterResults(finalData, availabilityData, filterData);
            } else {
                return finalData;
            }
        } else {
            let data = await this.getAllListings(lastPostID);
            return data;
        }
    }

    getListingsWithCriteria = async (filters) => {
        let finalData = null;
        const db = getFirestore();
        const rentalsRef = collection(db, "rentals");
        let filterObj = [];
        for (let index in filters) {
            let filter = filters[index];
            filterObj.push(where(filter.key, filter.equaltiy, filter.value))
        }
        let rentalQuery = firestoreQuery(
            rentalsRef, 
            ...filterObj);
        const responseData = await getDocs(rentalQuery);
        for (let key in responseData.docs) {
            let data = responseData.docs[key].data();
            if (finalData == null) {
                finalData = {};
            } 
            finalData[data.key] = data;
        }
        return finalData;
    }

    getAllListings = async (lastPostID) => {
        const db = getDatabase();
        if (lastPostID == null || lastPostID == undefined) {
            try {
                const rentalsRef = query(ref(db, `publicRentals`), ...[orderByKey(), limitToFirst(10)]);
                const snapshot = await get(rentalsRef);
                const rentalsData = await snapshot.val();
                if (rentalsData !== null) {
                    return rentalsData;
                } else {
                    return null;
                }
            } catch (error) {
                console.log(error);
            }
        } else if (lastPostID == "home") {
            let finalData = null;
            try {
                const db = getFirestore();
                const rentalsRef = collection(db, "rentals");
                let rentalQuery = firestoreQuery(
                    rentalsRef,
                    orderBy("key"),);
                const responseData = await getDocs(rentalQuery);
                for (let key in responseData.docs) {
                    let data = responseData.docs[key].data();
                    if (finalData == null) {
                        finalData = {};
                    } 
                    finalData[data.key] = data;
                }
                return finalData;
            } catch (error) {
                console.log(error);
            }
        } else {
            let finalData = null;
            try {
                const db = getFirestore();
                const rentalsRef = collection(db, "rentals");
                let rentalQuery = firestoreQuery(
                    rentalsRef,
                    orderBy("key"),
                    limit(10),
                    fbStartAt(lastPostID),);
                const responseData = await getDocs(rentalQuery);
                for (let key in responseData.docs) {
                    let data = responseData.docs[key].data();
                    if (finalData == null) {
                        finalData = {};
                    } 
                    finalData[data.key] = data;
                }
                return finalData;
            } catch (error) {
                console.log(error);
            }
        }
    }

    getReccomendedListings = async (filterData, userIsPhysician, lastPostID, lastPostSubscriptionLevel) => {
        let finalData = {}
        let promises = [];
        if (!!filterData) {
            let filteredListings = await this.getListings(filterData, lastPostID);
            for (let index in filteredListings) {
                let data = filteredListings[index];
                if (data.public != null || data.public != undefined) {
                    if (!data.public && userIsPhysician) {
                        promises.push(
                            this.getPriceForListing(index, userIsPhysician)
                        )
                        finalData[index] = data;
                    } else if (data.public ) {
                        promises.push(
                            this.getPriceForListing(index, userIsPhysician)
                        )
                        finalData[index] = data;
                    }
                } else {
                    promises.push(
                        this.getPriceForListing(index, userIsPhysician)
                    )
                    finalData[index] = data;
                }
            }
            let priceData =  await Promise.all(promises);
            priceData.forEach(data => {
                finalData[data.id]["rentablePrice"] = data.price
            });
        } else {
            let searchGold = false;
            if (lastPostSubscriptionLevel == null ) {
                searchGold = true;
            } else if (!!lastPostSubscriptionLevel && lastPostSubscriptionLevel == "gold") {
                searchGold = true;
            }
            if (searchGold) {
                //get gold listings
                let gold = await this.getListingsForSubscriptionLevel("gold", lastPostID, lastPostSubscriptionLevel);
                if (!!gold) {
                    Object.keys(gold).forEach(index => {
                        let data = gold[index];
                        if (data.public != null || data.public != undefined) {
                            if (!data.public && userIsPhysician) {
                                promises.push(
                                    this.getPriceForListing(index, userIsPhysician)
                                )
                                finalData[index] = data;
                            } else if (data.public) {
                                promises.push(
                                    this.getPriceForListing(index, userIsPhysician)
                                )
                                finalData[index] = data;
                            }
                        } else {
                            promises.push(
                                this.getPriceForListing(index, userIsPhysician)
                            )
                            finalData[index] = data;
                        }
                    });
                }
            }
            let searchSilver = false;
            if (lastPostSubscriptionLevel == null || (lastPostSubscriptionLevel == "gold" || lastPostSubscriptionLevel == "silver")) {
                searchSilver = true;
            } 
            //if nothing then get silver
            if (Object.keys(finalData).length <= 5 && searchSilver) {
                let lastPostKey = null
                if (lastPostSubscriptionLevel == "silver") {
                    lastPostKey = lastPostID;
                }
                let silver = await this.getListingsForSubscriptionLevel("silver", lastPostKey, lastPostSubscriptionLevel);
                if (!!silver) {
                    for (let silverIndex in silver) {
                        let data = silver[silverIndex];
                        if (data.public != null || data.public != undefined) {
                            if (!data.public && userIsPhysician) {
                                promises.push(
                                    this.getPriceForListing(silverIndex, userIsPhysician)
                                )
                                finalData[silverIndex] = data;
                            } else if (data.public ) {
                                promises.push(
                                    this.getPriceForListing(silverIndex, userIsPhysician)
                                )
                                finalData[silverIndex] = data;
                            }
                        } else {
                            promises.push(
                                this.getPriceForListing(silverIndex, userIsPhysician)
                            )
                            finalData[silverIndex] = data;
                        }
                    }
                }
            }

            let searchBronze = false;
            if (!!lastPostSubscriptionLevel && (lastPostSubscriptionLevel == "silver" || lastPostSubscriptionLevel == "bronze") ) {
                searchBronze = true;
            }
            //if nothing then get bronze
            if (Object.keys(finalData).length < 5 && searchBronze) {
                let lastPostKey = null
                if (lastPostSubscriptionLevel == "bronze") {
                    lastPostKey = lastPostID;
                }
                let bronze = await this.getListingsForSubscriptionLevel("bronze", lastPostKey, lastPostSubscriptionLevel);
                if (!!bronze) {
                    for (let bronzeIndex in bronze) {
                        let data = bronze[bronzeIndex];
                        if (data.public != null || data.public != undefined) {
                            if (!data.public && userIsPhysician) {
                                promises.push(
                                    this.getPriceForListing(bronzeIndex, userIsPhysician)
                                )
                                finalData[bronzeIndex] = data;
                            } else if (data.public ) {
                                promises.push(
                                    this.getPriceForListing(bronzeIndex, userIsPhysician)
                                )
                                finalData[bronzeIndex] = data;
                            }
                        } else {
                            promises.push(
                                this.getPriceForListing(bronzeIndex, userIsPhysician)
                            )
                            finalData[bronzeIndex] = data;
                        }
                    }
                }
            }
            let priceData =  await Promise.all(promises);
            priceData.forEach(data => {
                finalData[data.id]["rentablePrice"] = data.price
            });
        }
        return finalData
    }

    getPriceForListing = async (id, userIsPhysician) => {
        const db = getDatabase();
        let type = "physicianPrice";
        if (!userIsPhysician) {
            type = "publicPrice";
        }
        const priceRef = ref(db, `${type}/${id}`);
        let snapshot = await get(priceRef)
        const priceData = snapshot.val();
        let returnData = {
            id: id,
            price: priceData?.price,
        }
        return returnData;
    }

    getListingsForSubscriptionLevel = async (level, lastPostID) => {
        let finalData = null;
        try {
            const db = getFirestore();
            const rentalsRef = collection(db, "rentals");
            let limitNum = 99;
            if (level != "gold") {
                limitNum = 5;
            }
            let rentalQuery = firestoreQuery(
                rentalsRef, 
                where("subscriptionLevel",  "==", level),
                orderBy("key"), 
                limit(limitNum));
            if (lastPostID != null) {
                rentalQuery = firestoreQuery(
                    rentalsRef, 
                    where("subscriptionLevel",  "==", level),
                    orderBy("key"), 
                    fbStartAt(lastPostID),
                    limit(5));
            }
            const responseData = await getDocs(rentalQuery);
            //id like to randomize if gold but worried about key no longer working
            if (level == "gold") {
                for (let key in responseData.docs) {
                    let data = responseData.docs[key].data();
                    if (finalData == null) {
                        finalData = {};
                    } 
                    finalData[(Math.random() + 1).toString(36).substring(7)] = data;
                }
                if (finalData) {
                    let keys = Object.keys(finalData);
                    let newKeys = this.shuffle(keys);
                    // finalData = {};
                    for (let key in newKeys) {
                        let newKey =  newKeys[key];
                        let data = finalData[newKey];
                        finalData[key] = data;
                        delete finalData[newKey];
                    }
                }
            } else {
                for (let key in responseData.docs) {
                    let data = responseData.docs[key].data();
                    if (finalData == null) {
                        finalData = {};
                    } 
                    finalData[data.key] = data;
                }
            }
            return finalData;
        } catch (error) {
            console.log(error);
        }
    }

    shuffle = (array) => {
        let currentIndex = array.length,  randomIndex;
        while (currentIndex != 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        return array;
      }

    getPublicListingData = async (id) => {
        try {
            const db = getDatabase();
            const rentalsRef = ref(db, `publicRentals/${id.trim()}`)
            const snapshot = await get(rentalsRef);
            const rentalsData = await snapshot.val();
            if (rentalsData !== null) {
                return rentalsData;
            } else {
                return null;
            }   
        } catch (error) {
            console.log(error)
        }
    }

    // searchTitle = async (term) => {
    //     var db = firebase.firestore();
    //     const postRef = db.collection('posts');
    //     // reverse term
    //     const termR = term.split("").reverse().join("");
    //     // define queries
    //     const titles = postRef.orderBy('title').startAt(term).endAt(term + '~').get();
    //     const titlesR = postRef.orderBy('titleRev').startAt(termR).endAt(termR + '~').get();
    //     // get queries
    //     const [titleSnap, titlesRSnap] = await Promise.all([
    //       titles,
    //       titlesR
    //     ]);
    //     return (titleSnap.docs).concat(titlesRSnap.docs);
    // }

    searchKeyword = async (term) => {
        let finalData = {};
        let promises = [];
        try {
            const db = getFirestore();
            const keywordsRef = collection(db, "keywords");
            const keyWordsQuery = firestoreQuery(keywordsRef, where("keywords", "array-contains", term.toLowerCase()));
            const responseData = await getDocs(keyWordsQuery);
            for (let key in responseData.docs) {
                let postID = responseData.docs[key].id;
                promises.push(
                    this.getPublicListingData(postID)
                )
            }
            let allPublicData = await Promise.all(promises);
            allPublicData.forEach(data => {
                if (!!data) {
                    finalData[data.key] = data;
                }
            });
            return finalData;
        } catch (error) {
            console.log(error);
        }
    }

    searchLocation = async (latitude, longitude) => {
        let finalData = {};
        let promises = [];
        try {
            const db = getFirestore();
            const range = this.getGeohashRange(latitude, longitude, 50);
            const locationsRef = collection(db, "locations");
            const locationQuery = firestoreQuery(locationsRef, where("geohash",  ">=", range.lower), where("geohash",  "<=", range.upper));
            const responseData = await getDocs(locationQuery);
            for (let key in responseData.docs) {
                let postID = responseData.docs[key].data().key;
                promises.push(
                    this.getPublicListingData(postID.trim())
                )
            }
            let allPublicData = await Promise.all(promises);
            allPublicData.forEach(data => {
                if (!!data) {
                    finalData[data.key] = data;
                }
            });
            return finalData;
        } catch (error) {
            console.log(error);
        }
    }

    getGeohashRange = (latitude, longitude, distance) => {
        const lat = 0.0144927536231884; // degrees latitude per mile
        const lon = 0.0181818181818182; // degrees longitude per mile
        const lowerLat = latitude - lat * distance;
        const lowerLon = longitude - lon * distance;
        const upperLat = latitude + lat * distance;
        const upperLon = longitude + lon * distance;
        const lower = geohash.encode(lowerLat, lowerLon);
        const upper = geohash.encode(upperLat, upperLon);
        return {
          lower,
          upper
        };
    }

    getAvailabilityData = async (allRentalData) => {
        let allAvailabilityData = {};
        let promises = [];
        const db = getDatabase();
        for (var key in allRentalData) {
            const availabilityRef = ref(db, `availability/${key}`)
            promises.push(
                get(availabilityRef)
            )
        }
        let availabilityData = await Promise.all(promises);
        for (let index in allRentalData) {
            allAvailabilityData[index] = availabilityData
        }
        return allAvailabilityData;
    }

    filterResults = (rentalsData, allAvailabilityData, filterData) => {
        let newRentalData = rentalsData;
        if (filterData != undefined) {
            if (
                filterData.bathrooms != null ||
                filterData.bedrooms != null ||
                filterData.checkIn != null ||
                filterData.checkOut != null ||
                filterData.people != null ||
                filterData.petFriendly == true ||
                filterData.washerDryer == true ||
                !!filterData.listingType
                ) {
                for (var rentalKey in rentalsData) {
                    let rentalData = rentalsData[rentalKey];
                    let availabilityData = {}
                    if (!!allAvailabilityData) {
                        availabilityData = allAvailabilityData[rentalData.key];
                    } else {
                        availabilityData = null;
                    }
                    if (filterData.checkIn != null && filterData.checkOut != null) {
                        let startDate = new Date(filterData.checkIn );
                        let endDate = new Date(filterData.checkOut);
                        let datesAray = this.getDaysArray(startDate, endDate);
                        let available = true;
                        if (availabilityData != null) {
                            for (var key in datesAray) {
                                let date = datesAray[key].toISOString().slice(0,10);
                                if (availabilityData[date] != undefined ) {
                                    available = false;
                                }
                            }
                        }
                        if (!available) {
                            delete newRentalData[rentalKey];
                        }
                    } else if (filterData.checkIn != null && filterData.checkOut == null) {
                        if (availabilityData != undefined) {
                            if (availabilityData[filterData.checkIn] != undefined ) {
                                delete newRentalData[rentalKey];
                            }
                        }
                    } else if (filterData.checkIn == null && filterData.checkOut != null) {
                        if (availabilityData != undefined) {
                            if (availabilityData[filterData.checkOut] != undefined ) {
                                delete newRentalData[rentalKey];
                            }
                        }
                    }
                    if (rentalData.availability != null) {
                        if (rentalData.availability != "calendar" && (filterData.checkIn != null || filterData.checkOut != null)) {
                            //color = "yellow";
                        }
                    }
                    if (filterData.bathrooms != null) {
                        if (filterData.bathrooms > rentalData.bathrooms) {
                            delete newRentalData[rentalKey];
                        }
                    }
                    if (filterData.bedrooms != null) {
                        if (filterData.bedrooms > rentalData.bedrooms) {
                            delete newRentalData[rentalKey];
                        }
                    }
                    if (filterData.people != null) {
                        if (filterData.people > rentalData.accommodates) {
                            delete newRentalData[rentalKey];
                        }
                    }
                    if (filterData.petFriendly) {
                        if (!rentalData.petFriendly) {
                            delete newRentalData[rentalKey];
                        }
                    }
                    if (filterData.washerDryer) {
                        if (!rentalData.washerDryer) {
                            delete newRentalData[rentalKey];
                        }
                    }
                    if (filterData.listingType != null) {
                        if (filterData.listingType != "str" && rentalData.listingType == undefined) {
                            delete newRentalData[rentalKey];
                        } else if (filterData.listingType != rentalData.listingType) {
                            if (!!rentalData.listingType) {
                                delete newRentalData[rentalKey];
                            }
                        } 
                    }
                }
            }
        }
        return newRentalData;
    }

    getDaysArray = (s,e) => {
        for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;
    };

    uuidv4 = () => {
        return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
}
