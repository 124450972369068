import React from 'react';
import ContentLoader, { Rect, Circle } from 'react-content-loader/native'
import { Dimensions, View } from "react-native";

import Container from './Container';

export default function Placeholder (props) {

    return (
        <>
            <Container>
                <View>
                    <ContentLoader 
                        speed={1}
                        width={Dimensions.get("screen").width - 40}
                        height={460}
                        viewBox="0 0 590 460"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                        {...props}
                    >
                        <Rect x="3" y="11" rx="2" ry="2" width="335" height="17" /> 
                        <Rect x="4" y="34" rx="2" ry="2" width="197" height="8" /> 
                        <Rect x="0" y="54" rx="2" ry="2" width="100%" height="420" />
                        <Circle cx="551" cy="23" r="22" />
                    </ContentLoader>
                </View>
            </Container>
            <View style={{display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap"}}>
                <Container maxWidth={300}>
                    <ContentLoader 
                        speed={1}
                        width={300}
                        height={64}
                        viewBox="0 0 300 64"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <Rect x="66" y="12" rx="2" ry="2" width="213" height="26" /> 
                        <Circle cx="29" cy="24" r="22" />
                    </ContentLoader>
                </Container>
                <Container maxWidth={300}>
                    <ContentLoader 
                        speed={1}
                        width={250}
                        height={64}
                        viewBox="0 0 250 64"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <Rect x="46" y="13" rx="2" ry="2" width="132" height="23" /> 
                        <Circle cx="218" cy="27" r="22" />
                    </ContentLoader>
                </Container>
                <Container maxWidth={300}>
                    <ContentLoader 
                        speed={1}
                        width={200}
                        height={64}
                        viewBox="0 0 200 64"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <Rect x="6" y="31" rx="2" ry="2" width="197" height="29" /> 
                        <Rect x="6" y="11" rx="0" ry="0" width="202" height="12" />
                    </ContentLoader>
                </Container>
                <Container maxWidth={250}>
                    <ContentLoader 
                        speed={1}
                        width={150}
                        height={64}
                        viewBox="0 0 150 64"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        <Rect x="6" y="31" rx="2" ry="2" width="150" height="29" /> 
                        <Rect x="6" y="11" rx="0" ry="0" width="150" height="12" />
                    </ContentLoader>
                </Container>
            </View>
            <Container>
                <ContentLoader
                    width={"100%"}
                    height={160}
                    viewBox="0 0 800 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <Rect x="20" y="7" rx="2" ry="2" width="100%" height="231" />
                </ContentLoader>
            </Container>
            <Container>
                <ContentLoader 
                    speed={1}
                    width={"100%"}
                    height={124}
                    viewBox="0 0 476 124"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <Circle cx="46" cy="38" r="38" />
                    <Rect x="34" y="83" rx="5" ry="5" width="25" height="10" />
                    <Rect x="547" y="222" rx="5" ry="5" width="220" height="10" />
                    <Rect x="82" y="150" rx="5" ry="5" width="220" height="10" />
                    <Circle cx="137" cy="38" r="38" />
                    <Rect x="124" y="83" rx="5" ry="5" width="25" height="10" />
                    <Circle cx="228" cy="38" r="38" />
                    <Rect x="215" y="83" rx="5" ry="5" width="25" height="10" />
                    <Circle cx="320" cy="38" r="38" />
                    <Rect x="307" y="83" rx="5" ry="5" width="25" height="10" />
                    <Circle cx="410" cy="38" r="38" />
                    <Rect x="398" y="83" rx="5" ry="5" width="25" height="10" />
                </ContentLoader>
            </Container>
        </>        
    )
}
