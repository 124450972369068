import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, Image, Platform, Alert } from 'react-native';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import * as WebBrowser from 'expo-web-browser';
import Constants from 'expo-constants';
import PayPalButtonWeb from '../compenents/PayPalButton';
import { getDatabase, ref, set } from "firebase/database";

import ActionButton from '../compenents/ActionButton';
import Container from "../compenents/Container";
import Footer from "../compenents/Footer";

export default class CheckOut extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
        };
    } 

    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
        });
    }

    buildView = () => {
        let parent = [];
        if (this.props.route.params.checkOutType == "bronze") {
            if (Platform.OS == "web") {
                parent.push(
                    <Text style={styles.label}>Bronze Membership</Text>,
                    <Text style={{padding: 10}}>To complete your listing, walk through the paypal process to set up automatic payments.</Text>,
                    <Text style={{padding: 10}}>Bronze listings are $10 per month</Text>,
                    <PayPalButtonWeb level="bronze" success={(data)=>{
                        this.completeOrder(this.props.route.params.newRentalData, data, false);
                    }}/>
                );
            } else {
                parent.push(
                    <Text style={styles.label}>Bronze membership</Text>,
                    <Text style={{padding: 10}}>To complete your listing, walk through the paypal process to set up automatic payments.</Text>,
                    <Text style={{padding: 10}}>Bronze listings are $10 per month</Text>,
                    <ActionButton
                        text={"Check Out"}
                        handler={() => {
                            this.mobileCheckOut();
                        }}
                    />

                );
            }
        } else if (this.props.route.params.checkOutType == "silver") {
            //merchant check out
                parent.push(
                    <Text style={styles.label}>Merchant Onboading</Text>,
                );
        } else if (this.props.route.params.checkOutType == "gold") {
            if (Platform.OS == "web") {
                parent.push(
                    <Text style={styles.label}>New Gold Listing</Text>,
                    <Text style={{padding: 10}}>To complete your listing, walk through the paypal process to set up automatic payments.</Text>,
                    <Text style={{padding: 10}}>Gold listings are $25 per month</Text>,
                    <PayPalButtonWeb level="gold" success={(data)=>{
                        this.completeOrder(this.props.route.params.newRentalData, data, true);
                    }}/>
                );
            } else {
                parent.push(
                    <Text style={styles.label}>Gold membership</Text>,
                    <Text style={{padding: 10}}>To complete your listing, walk through the paypal process to set up automatic payments.</Text>,
                    <Text style={{padding: 10}}>Gold listings are $25 per month</Text>,
                    <ActionButton
                        text="Check Out"
                        handler={()=>{
                            this.mobileCheckOut();
                        }}
                    />
                );
            }
        } 
        return parent;
    }

    completeOrder = async (purchaseData, data, isGold) => {
         //if changing bronze subscription level we need to save a draft here
        if (this.props.route.params.subscriptionChange != null) {
            if (this.props.route.params.subscriptionChange != null) {
                let complete = await this.saveDraft(purchaseData);
            }
        }

        let url = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/createNewPost?";
        if (isGold) {
            if (this.props.route.params.isExpiredPost) {
                url = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/updatePayment?";
            } else {
                url = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/createNewGoldListing?";
            }
        }
        url += "postID="+purchaseData.key;
        url += "&posterUID="+this.state.user.uid;
        url += "&orderID="+data.orderID;
        url += "&subscriptionID="+data.subscriptionID;
        if (isGold) {
            url += "&subscriptionLevel=gold";
        } else {
            url += "&subscriptionLevel=bronze";
        }
        const response = await fetch(url, { 
            method: 'POST',
        });
        const createResponse = await response.json();
        if (!!createResponse.response) {
            if (createResponse.response == "success") {
                this.props.navigation.navigate("Success", {
                    rentalData: purchaseData,
                });
            } else {
                alert("There was a failire checking out. please reach out to doc2docrentals@gmail.com #88512")
            }
        }
    }

    saveDraft = async (checkoutData) => {
        const db = getDatabase();
        const draftsRef = ref(db, `drafts/${this.state.user.uid}/${checkoutData.key}`);
        let success = await set(draftsRef, checkoutData);
        return true;
    }

    deleteDraft = async (key) => {
        const db = getDatabase();
        const draftsRef = ref(db, `drafts/${this.state.user.uid}/${key}`);
        let success = await set(draftsRef, null);
        return true;
    }

    getParameterByName = (name, url) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    mobileCheckOut = async () => {
        if (Platform.OS !== 'web') {
            let sendData = {
                postID: this.props.route.params.newRentalData.key,
                posterUID: this.state.user.uid,
                email: this.state.user.email,
            }
            let url = 'https://doc2doc-d54a2.web.app/mobile-payments/payments-v3.html?';
            if (this.props.route.params.checkOutType == "gold") {
                url = 'https://doc2doc-d54a2.web.app/mobile-payments/payments-gold.html?';
            }
            const paymentURL = url+ encodeURIComponent(JSON.stringify(sendData));
            let browserParams = {};
            browserParams["dismissButtonStyle"] = "done";
            //if changing bronze subscription level we need to save a draft here
            if (this.props.route.params.subscriptionChange != null) {
                if (this.props.route.params.subscriptionChange != null) {
                    let complete = await this.saveDraft(this.props.route.params.newRentalData);
                }
            }
            try {
                WebBrowser.openAuthSessionAsync(paymentURL, Constants.linkingUrl, browserParams).then(closeData => {
                    let url = closeData.url;
                    let success = this.getParameterByName("success", url);
                    if (!!success) {
                        this.props.navigation.navigate("Success", {
                            rentalData: this.props.route.params.newRentalData,
                        });
                    } else {
                        //if changing bronze subscription level we need to delete the draft here
                        if (this.props.route.params.subscriptionChange != null) {
                            if (this.props.route.params.subscriptionChange != null) {
                                this.deleteDraft(this.props.route.params.newRentalData.key);
                            }
                        }
                    }
                });
            } catch (error) {
                console.log(error);
            }
        } 
    }

    render() {
        return (
            <ScrollView contentContainerStyle={styles.container}>
                <Container>
                    {this.buildView()}
                </Container>
                <Footer hideText={true}/>
            </ScrollView>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',

    },
    headerLabeltext: {
        fontSize: 24,
        marginBottom: 20,
        fontWeight: "700",
        textAlign: "center",
    },
    mt20: {
        marginTop: 20,
    },
    centeredText: {
        textAlign: "center",
    },
    payment: {
        width: 500,
        height: 600,
    },
    label: {
        margin: 5,
        marginTop: 10,
        marginBottom: 10,
        fontSize: 14,
        fontWeight: "700",
    },
    sublabel: {
        fontSize: 12,
        paddingBottom: 10,
    },
})