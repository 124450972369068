import React, { Component } from 'react';
import { StyleSheet, ScrollView, } from 'react-native';
import 'react-native-gesture-handler';
import { getDatabase, ref, get } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { createStackNavigator } from '@react-navigation/stack';

import PublicProperty from './PubicProperty';

import Feed from "../compenents/Feed";
import Loader from '../compenents/Loader';
import Footer from "../compenents/Footer";

const Stack = createStackNavigator();

export default class FavoritesWrapper extends Component {
    render() {
        return (
            <Stack.Navigator>
                <Stack.Screen name="Favorites" component={Favorites} options={{ headerShown: false }}/>
                <Stack.Screen name="Public Property" component={PublicProperty} />
            </Stack.Navigator>
        );
    }
}

class Favorites extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            favoritesData: null,
            loaderIsShowing: null,
            loaderKey: Math.random().toString(36).substring(7),
        }
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.props.navigation.getParent().setOptions({headerShown: true});
            this.setState({
                favoritesData: null,
                loaderIsShowing: true,
                loaderKey: Math.random().toString(36).substring(7),
            });
            const auth = getAuth();
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    this.setState({ user });
                    this.getMyFavorites();
                } else {
                    this.setState({ user: null });
                }
            });
        });
    }

    getMyFavorites = () => {
        const _this = this;
        let finalRentalData = {};
        let callbacksRequired = 0;
        let callbacksCompleted = 0;
        const db = getDatabase();
        const myFavoritesRef = ref(db, `myFavorites/${this.state.user.uid}`);
        get(myFavoritesRef).then((snapshot) => {
            const favoritesDataBlock = snapshot.val();
            if (favoritesDataBlock !== null) {
                callbacksRequired = Object.keys(favoritesDataBlock).length;
                for (var key in favoritesDataBlock) {
                    const publicRentals = ref(db, `publicRentals/${key}`);
                    get(publicRentals).then((snapshot) => {
                        const publicRentalData = snapshot.val();
                        if (publicRentalData) {
                            finalRentalData[publicRentalData.key] = publicRentalData
                        }
                        callbacksCompleted += 1;
                        if (callbacksCompleted == callbacksRequired) {
                            _this.setState({
                                favoritesData: finalRentalData,
                            });
                        }
                    });
                }
            }
            _this.setState({ 
                loaderIsShowing: false,
                loaderKey: Math.random().toString(36).substring(7),
            }); 
        }).catch(error => {
            console.log(error);
            _this.setState({ 
                loaderIsShowing: false,
                loaderKey: Math.random().toString(36).substring(7),
            }); 
        });
    }

    buildTable = () => {
        let parent = [];
        if (this.state.favoritesData) {
            parent.push(
                <Feed 
                    listingData={this.state.favoritesData} 
                    navigation={this.props.navigation}
                    showMoreIsShowing={false}
                    colums={2}
                />
            );
        }
        return parent;
    }

    render() {
        return (
            <ScrollView contentContainerStyle={styles.container}>
                <Loader 
                    visible={this.state.loaderIsShowing}
                    key={this.state.loaderKey}  
                />
                { this.buildTable() }
                <Footer hideText={true}/>
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        fontSize: 24,
        marginTop: 50,
        marginBottom: 10,
        fontWeight: "700",
        textAlign: "center",
    },
    mt20: {
        marginTop: 20,
    },
    centeredText: {
        textAlign: "center",
        maxWidth: 800,
        paddingLeft: 20,
        paddingRight: 20,
    },
    propertyTitleText: {
        fontSize: 16,
        marginBottom: 10,
        fontWeight: "500",
        textAlign: "center",
        marginTop: 30,
    },
    amentitesContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        margin:10,
      },
      amentitiesIcon: {
        height: 35,
        width:35,
        margin: 5,
      },
    bedBathWrapper: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'stretch',
    },
    listingFooter: {
        height: 1,
        width: "80%",
        backgroundColor: "grey",
        marginTop: 30,
        marginBottom: 20
    },
    mt20: {
        marginTop: 20,
    },
    button: {
        marginTop: 20,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    thumbnail: {
        width: "100%",
        height: 500,
    },
    viewWrapper: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
})