import React, { Component } from "react";
import { Text, View, Modal, StyleSheet, Clipboard, Image, ActivityIndicator, Platform } from "react-native";
import * as Linking from 'expo-linking';

import Percentage from "./Percentage";
 
import ActionButton from "./ActionButton";
 
export default class ModalWrapper extends Component {

    state = {
        visibleModal: false
    };

    componentDidMount() {
        if (this.props.visible) {
            this.setState({visibleModal: true});
        }
    }

    modalClose = () => {
        this.setState({visibleModal: false},
            () => {
                if (this.props.onModalClose) {
                    this.props.onModalClose();
                }
            });
      }

    hideModal = () => {
        this.setState({visibleModal: false},
            () => {
                if (this.props.onModalClose) {
                    this.props.onModalClose();
                }
            });
    }

    confirmButtonClick = () => {
        this.props.onConfirmClick();
        this.modalClose();
    }

    copyEmailClick = () => {
        Clipboard.setString(this.props.email);
        if (this.props.onCopyPress) {
            this.props.onCopyPress();
        }
        this.modalClose();
    }

    copyWebsiteClick = () => {
        Clipboard.setString(this.props.website);
        if (this.props.onCopyPress) {
            this.props.onCopyPress();
        }
        this.modalClose();
    }

    sendEmailClick = () => {
        Linking.openURL('mailto:'+this.props.email).catch(error => {
            this.modalClose();
            this.props.onError();
        });
    }

    render() {
        let anitmationType = "";
        if (this.props.isLoading) {
            anitmationType = "fade"
        } else {
            anitmationType = "slide"
        }
        return (
            <View>
                <Modal
                    animationType={anitmationType}
                    visible={this.state.visibleModal}
                    transparent={true}
                    onBackdropPress={() => this.modalClose()}
                >
                    <View style={styles.centeredView}>
                        <View style={styles.modalView}>
                            {!!this.props.onConfirmClick ?
                                <Text 
                                    allowFontScaling={false}
                                    style={styles.titleText}
                                >
                                    Confirm
                                </Text>: null
                            }
                            {!!this.props.isSuccess ?
                                <Text allowFontScaling={false} style={styles.successText}>Success</Text>: null
                            }
                            {!!this.props.isError ?
                                <Text allowFontScaling={false} style={styles.errorText}>Error</Text>: null
                            }
                            {!!this.props.title ?
                                <Text allowFontScaling={false} style={styles.titleText}>{this.props.title}</Text>: null
                            }
                            {!!this.props.labelText ?
                                <Text allowFontScaling={false} style={styles.modalText}>{this.props.labelText}</Text>: null
                            }
                            {!!this.props.email ?
                                <View style={styles.copyWrapper}>
                                    <Text allowFontScaling={false} style={styles.titleText}>Copy</Text>
                                    <Text allowFontScaling={false} style={styles.email}>{this.props.email}</Text>
                                    <ActionButton
                                        text={"Copy Email"}
                                        handler={() => {
                                            this.copyEmailClick()
                                        }}
                                    />
                                    <ActionButton
                                        text={"Send Email"}
                                        handler={() => {
                                            this.sendEmailClick()
                                        }}
                                    />
                                </View>
                                : null
                            }
                            {!!this.props.website ?
                                <View style={styles.copyWrapperSmall}>
                                    <Text allowFontScaling={false} style={styles.titleText}>Copy</Text>
                                    <Text allowFontScaling={false} style={styles.email}>{this.props.website}</Text>
                                    <ActionButton
                                        text={"Copy URL"}
                                        handler={() => {
                                            this.copyWebsiteClick()
                                        }}
                                    />
                                </View>
                                : null
                            }
                            {!!this.props.onConfirmClick ?
                                <ActionButton
                                    text={"Yes"}
                                    handler={() => {
                                        this.confirmButtonClick()
                                    }}
                                />: null
                            }
                            {!!this.props.isSuccess ?
                                <ActionButton
                                    text={"Done"}
                                    handler={() => {
                                        this.hideModal()
                                    }}
                                />: null
                            }

                            {this.props.useChildren != undefined?
                                <View>{this.props.children}</View>:null
                            }

                            {!this.props.isSuccess && !this.props.isLoading && this.props.percentage == undefined  ?
                                <ActionButton
                                    text={"Close"}
                                    handler={() => {
                                        this.hideModal()
                                    }}
                                    filled={true}
                                />: null
                            }

                            {!!this.props.isLoading ?
                                <View style={styles.loadingImageWrapper}>
                                    <Text allowFontScaling={false} style={styles.buttonText}>Loading</Text>
                                    <View style={{height: 10}}/>
                                    <ActivityIndicator size="large" />
                                    <View style={{height: 10}}/>
                                    <ActionButton
                                        text={"Close"}
                                        handler={() => {
                                            this.hideModal()
                                        }}
                                        filled={true}
                                    />
                                </View>
                                : null
                            } 
                            {this.props.percentage != undefined && this.props.percentage != null ?
                                <View style={styles.loadingImageWrapper}>
                                    <Text allowFontScaling={false} style={styles.buttonText}>Saving</Text>
                                    <View style={{height: 10}}/>
                                    <Percentage percentage={this.props.percentage}/>
                                    <View style={{height: 10}}/>
                                </View>
                                : null
                            } 
                        </View>
                    </View>
                </Modal>
            </View>
        );
    }
}


const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 20,
        color: 'black'
    },
    modalText: {
        marginBottom: 15,
        textAlign: 'center',
    },
    button: {
        marginTop: 30,
        marginBottom: 10,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
        height: 36,
    },
    buttonNoMargin: {
        marginTop: 0,
        marginBottom: 10,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
        height: 36,
    },
    buttonRed: {
        marginBottom: 20,
        marginTop: 10,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#f13a3a',
        borderColor: '#ff2323',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    checkMark: {
        width: 40,
        height: 40
    },
    errorText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: "red",
        margin: 20,
    },
    titleText: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: 20,
    },
    successText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: "green",
        margin: 20,
    },
    copyWrapper: {
        width: "100%",
        height: 200,
        textAlign: "center",
        justifyContent:"center",
        alignItems: "center",
    },
    copyWrapperSmall: {
        width: "100%",
        height: 170,
        textAlign: "center",
        justifyContent:"center",
        alignItems: "center",
    },
    email: {
        textAlign: "center",
        fontSize: 18,
    },
    loadingImageWrapper: {
        width: 200,
        height: 250,
        textAlign: "center",
        justifyContent:"center",
        alignItems: "center",
      },
      loadingImage: {
        width: 200,
        height: 180,
      }
});