import React, {Component, useState, useEffect} from 'react';
import { StyleSheet,
        View, 
        Dimensions, 
        TouchableHighlight, 
        Image, 
        Text, 
        ScrollView,
        LayoutAnimation } from 'react-native';
import 'react-native-gesture-handler';
import { createStackNavigator } from '@react-navigation/stack';
import { getDatabase, ref, get, set } from "firebase/database";
import { getAuth, onAuthStateChanged, } from "firebase/auth";
import ContentLoader, { Rect } from "react-content-loader/native"

import Menu from "../compenents/Menu";
import Modal from "../compenents/Modal";
import Map from "../compenents/Map";
import Footer from '../compenents/Footer';
import { userIsPhysician, checkUserAgreement } from '../helpers/public-user-helper';
import BottomPage from '../compenents/BottomPage';
import ListingRetreiver from "../helpers/listing-retreiver";

import PublicProperty from "./PubicProperty";
import PublicProfile from './PublicProfile';
import PostProperties from "./PostProperty";
import CalendarView from "./CalendarView";
import SeachView from "./Search";
import Legal from "./Legal";
import FilterView from "./FilterView";
import WriteReview from './WriteReview';
import ReadReviews from './Reviews';
import CreateReservation from './CreateReservation';
import ReviewReservation from './ReviewReservation';
import StripeCheckout from './StripeCheckout';
import Success from './Success';
import MyProfile from './MyProfile';
import MessageView from './Message';
import Report from "./ReportPost";
import UserAgreementComponent from '../compenents/UserAgreementComponent';
import CheckBox from '../compenents/CheckBox';
import ActionButton from '../compenents/ActionButton';
import Container from '../compenents/Container';
import FilterBar from '../compenents/FilterBar';
import ExpandableView from '../compenents/ExpandableView';

const Stack = createStackNavigator();

export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filterData: null,
      shareModalVisible: false,
      shareModalKey: Math.random().toString(36).substring(7),
    };
  } 

  componentDidMount() {
    window.addEventListener('resize', this.updateView);
    this.checkForDeepLinks();
    this.props.navigation.setParams({
      homeCallback: this.onFilterComplete,
    });
  }

  checkForDeepLinks = () => {
    // console.log("window.location.href: ", window.location.href)
    if (window.location.href.indexOf("onboarded") > -1) {
        setTimeout(()=>{
          this.props.navigation.navigate("Revenue");
        },2000);
      
    } else if (window.location.href.indexOf("reauth") > -1) {
      //navigate to revenue with reauth prop
      //this.props.navigation.getParent().navigation.navigate("Revenue", {reauth: true});
      if (this.props.navigation.getState().routes.length > 11) {
        setTimeout(()=>{
          this.props.navigation.navigate("Revenue", {reauth: true});
        },10);
      }
    } else if (window.location.href.indexOf("postID=") > -1) {
      let postUIDData = window.location.href.split("postID=")[1];
      let postUID = postUIDData.split("&")[0];
      const db = getDatabase();
      const publicRentalsRef = ref(db, `publicRentals/${postUID}`);
      get(publicRentalsRef).then((snapshot) => {
        const postData = snapshot.val();
        if (postData !== null) {
          //this is and ugly line of code but it works
          this.props.navigation.getState().routes[0].params = {
              selectedKey: postData.key,
          };
          setTimeout(()=>{
            this.props.navigation.navigate("Public Property", {
              rentalData: postData
            });
          },10);
        } 
      });
    } else if (window.location.href.indexOf("privacy=") > -1) {
        this.props.navigation.navigate("Legal");
    } else if (window.location.href.indexOf("about=") > -1) {
      this.props.navigation.navigate("About");
    } else if (window.location.href.indexOf("mobileCheckoutUID=") > -1) {
      let mobileCheckOutData = window.location.href.split("mobileCheckoutUID=")[1];
      let requesterUID = mobileCheckOutData.split("&")[0];
      this.props.navigation.navigate("Payment Method", {
        mobileCheckoutUID: requesterUID,
      });
    } else if (window.location.href.indexOf("howto=") > -1) {
      this.props.navigation.navigate("How to");
    } else if (window.location.href.indexOf("about=") > -1) {
      this.props.navigation.navigate("About");
    } else if (window.location.href.indexOf("profileID=") > -1) {
      let postUIDData = window.location.href.split("profileID=")[1];
      let profileID = postUIDData.split("&")[0];
      this.props.navigation.navigate("Public Profile", {
        profileUID: profileID,
      });
    } else if (window.location.href.indexOf("support=") > -1) {
      this.props.navigation.navigate("Support");
    } else if (window.location.href.indexOf("review=") > -1) {
      let postUIDData = window.location.href.split("review=")[1];
      let postID = postUIDData.split("&")[0];
      setTimeout(()=>{
        this.props.navigation.navigate("Write Review", {
          postID: postID,
        });
      },2000);
    }
  }

  updateView = () => {
    this.setState({ height: window.innerHeight + 260});
  };

  onSearchClick = () => {
    this.props.navigation.navigate("Search List", {
      filterData: this.state.filterData,
      homeCallback: this.onFilterComplete
    });
  }


  onFilterComplete = (filterData) => {
    this.setState({
      filterData: filterData,
    });
    this.props.navigation.setParams({
      filterData: filterData,
      onFilterComplete: this.onFilterComplete
    })
  }

  onShare = () => {
    this.setState({
      shareModalVisible: true,
      shareModalKey: Math.random().toString(36).substring(7),
    });
  }

  buildView = () => {
    let postURL = "https://doc2doc-d54a2.web.app/?postID=";
    let homeRoute = this.props.navigation.getState().routes[0];
    if (!!homeRoute) {
      if (!!homeRoute.params) {
        if (!!homeRoute.params.selectedKey) {
          postURL += homeRoute.params.selectedKey
        }
      }
    }
    let parent = [];
    if (window.innerWidth >=  873) {
      parent.push(
        <View style={{width: "100%", height: "100%"}}>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.shareModalVisible}
            key={this.state.shareModalKey}
            onModalClose={this.onModalClose}
            onCopyPress={this.onCopyPress}
            website={postURL}
            labelText=""
          />
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.successModalVisible}
            key={this.state.successModalKey}
            onModalClose={this.onModalClose}
            isSuccess={true}
            labelText={"Successfully copied the shareable URL"}
          />
        <Stack.Navigator>
          <Stack.Screen name="Home" 
            component={HomeView}
            options={{
              headerShown: false,
              title: 'Doc to Doc Rentals'
            }}
          />
          <Stack.Screen 
            name="Public Property" 
            component={PublicProperty} 
            options={{ 
              headerShown: true,
              headerRight: () => (
                <View 
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 10,
                  }}>
                  <TouchableHighlight
                    onPress={() => {
                      this.onShare();
                    }}
                    style ={styles.searchButton}
                  >
                    <Image
                      style={styles.searchIcon}
                      source={require('../assets/shareIcon.png')}
                    />
                  </TouchableHighlight>
                </View>
              )
            }}
          />
          <Stack.Screen name="Post Property" component={PostProperties}></Stack.Screen>
          <Stack.Screen name="Search List" component={SeachView}></Stack.Screen>
          <Stack.Screen name="Calendar View" component={CalendarView}></Stack.Screen>
          <Stack.Screen name="Filters" component={FilterView}></Stack.Screen>
          <Stack.Screen name="My Profile" component={MyProfile}></Stack.Screen>
          <Stack.Screen name="Message" component={MessageView}/>
          <Stack.Screen name="Write Review" component={WriteReview}/>
          <Stack.Screen name="Read Reviews" component={ReadReviews}/>
          <Stack.Screen name="Create Reservation" component={CreateReservation}/>
          <Stack.Screen name="Review Reservation" component={ReviewReservation}/>
          <Stack.Screen name="Payment Method" component={StripeCheckout}/>
          <Stack.Screen name="Success" component={Success}/>
          <Stack.Screen name="Public Profile" component={PublicProfile}/>
          <Stack.Screen name="Report" component={Report}/>
        </Stack.Navigator>
        </View>
      );
    } else {
      let logo = [
        <Image
          style={{ 
            width: 250,
            height: 45,
            margin: 0,
            padding: 0,
          }}
          resizeMode="contain"
          source={require('../assets/horizontal-logo.png')}
        /> 
      ];
      if (window.innerWidth < 600) {
        logo = [
          <Image
            style={{ 
              width: 50,
              height: 45,
              margin: 0,
              padding: 0,
            }}
            resizeMode="contain"
            source={require('../assets/doc2docRentalsLogo-small.png')}
          /> 
        ];
      }
      parent.push(
        <View style={{width: "100%", height: "100%"}}>
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.shareModalVisible}
            key={this.state.shareModalKey}
            onModalClose={this.onModalClose}
            onCopyPress={this.onCopyPress}
            website={postURL}
            labelText=""
          />
          <Modal
            animationType="slide"
            transparent={true}
            visible={this.state.successModalVisible}
            key={this.state.successModalKey}
            onModalClose={this.onModalClose}
            isSuccess={true}
            labelText={"Successfully copied the shareable URL"}
          />
        <Stack.Navigator >
          <Stack.Screen name="Home" 
            component={HomeView}
            options={{
              title: 'Doc to Doc Rentals',
              headerTitle: () =>
              <View>
                {logo}
              </View>, 
              headerLeft: () => (
                <Menu navigation={this.props.navigation}/>
              ),
              headerRight: () => (
                <View style={{display: "flex", flexDirection: "row"}}>
                  <TouchableHighlight
                    onPress={() => {
                      this.onSearchClick();
                    }}
                    style ={styles.searchButton}
                  >
                    <Image
                      style={styles.searchIcon}
                      source={require('../assets/stackIcon.png')}
                    />
                  </TouchableHighlight>
                </View>
              ),
            }}
          />
          <Stack.Screen 
            name="Public Property"
            component={PublicProperty} 
            options={{ 
              headerShown: true,
              headerRight: () => (
                <View style={{display: "flex", flexDirection: "row"}}>
                  <TouchableHighlight
                    onPress={() => {
                      this.onShare();
                    }}
                    style ={styles.searchButton}
                  >
                    <Image
                      style={styles.searchIcon}
                      source={require('../assets/shareIcon.png')}
                    />
                  </TouchableHighlight>
                </View>
              )
            }}
          />
          <Stack.Screen name="Post Property" component={PostProperties} options={{ headerShown: true }}></Stack.Screen>
          <Stack.Screen name="Search List" component={SeachView} options={{ headerShown: true }}></Stack.Screen>
          <Stack.Screen name="Calendar View" component={CalendarView} options={{ headerShown: true }}></Stack.Screen>
          <Stack.Screen name="Legal" component={Legal} options={{ headerShown: false }}></Stack.Screen>
          <Stack.Screen name="Filters" component={FilterView}></Stack.Screen>
          <Stack.Screen name="Write Review" component={WriteReview}/>
          <Stack.Screen name="Read Reviews" component={ReadReviews}/>
          <Stack.Screen name="Create Reservation" component={CreateReservation}/>
          <Stack.Screen name="Review Reservation" component={ReviewReservation}/>
          <Stack.Screen name="Payment Method" component={StripeCheckout}/>
          <Stack.Screen name="Success" component={Success}/>
          <Stack.Screen name="My Profile" component={MyProfile}></Stack.Screen>
          <Stack.Screen name="Public Profile" component={PublicProfile}/>
          <Stack.Screen name="Message" component={MessageView}/>
          <Stack.Screen name="Report" component={Report}/>
        </Stack.Navigator>
        </View>
      );
    }
    return parent;
  }

  render() {
    return (
      this.buildView()
    );
  }
}

class HomeView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      height: Dimensions.get('window').height,
      width: Dimensions.get('window').width,
      mapKey: Math.random().toString(36).substring(7),
      selectedProperty: null,
      selectedPropData: null,
      searchText: null,
      errorModalVisible: false,
      errorModalKey: Math.random().toString(36).substring(7),
      filterData: null,
      filterViewKey: Math.random().toString(36).substring(7),
      user: null,
      userIsDoc: false,
      bottomSheetIndex: -1,
      userAgreed: false,
      userAgreementError: null,
      sideNavData: null,
      filterBarKey: Math.random().toString(36).substring(7),
    };
  } 

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.focusListener = this.props.navigation.addListener("focus", () => {
      this.updateDimensions();
      window.history.replaceState(null, null, "?");
      if (!!this.props.navigation.getParent().getState().routes[0].params) {
        if (this.props.navigation.getParent().getState().routes[0].params.filterData != undefined) {
          let parentState = this.props.navigation.getParent().getState();
          let filterData = parentState.routes[0].params.filterData;
          this.onFilterComplete(filterData);
          this.setState({
            filterBarKey: Math.random().toString(36).substring(7),
          })
        }
      }
    });
    this.props.navigation.setParams({
      onFilterComplete: this.onFilterComplete
    })
    this.getUser(); 
  }

  getUser = () => {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
			if (user) {
        if (!user.isAnonymous) {
          await this.userIsDoc(user);
          this.setState({ user });
          //check if user has aggreed to terms
          let showAgreement = await checkUserAgreement(user.uid);
          if (showAgreement) {
            this.setState({bottomSheetIndex: 2});
          } else {
            this.setState({userAgreed: true,})
          }
        } else {
          this.setState({ 
            user: null, 
            userIsDoc: false, 
            mapKey: Math.random().toString(36).substring(7), 
          });
          this.getSideNavData(false);
        }
			} else {
        this.setState({ 
          user: null, 
          userIsDoc: false, 
          mapKey: Math.random().toString(36).substring(7), 
        });
        this.getSideNavData(false);
      };
    });
  }

  userIsDoc = async (user) => {
    let userIsDoc = await userIsPhysician(user.uid);
    this.setState({
      userIsDoc: userIsDoc,
    });
    this.getSideNavData(userIsDoc);
  }
  
  updateDimensions = () => {
    if (this.props.navigation.isFocused()) {
      this.setState({ 
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
  };

  getSideNavData = async (userIsDoc) => {
    let lastPostIndex = null;
    let lastSubscriptionLevel = null;
    if (!!this.state.sideNavData) {
      let sideData = this.state.sideNavData;
      lastPostIndex = sideData[Object.keys(sideData)[Object.keys(sideData).length - 1]].key;
      lastSubscriptionLevel = sideData[Object.keys(sideData)[Object.keys(sideData).length - 1]].subscriptionLevel;
      if (lastSubscriptionLevel == null) {
        lastSubscriptionLevel = "bronze";
      }
    }
    let listingRetreiver = new ListingRetreiver();
    let data = await listingRetreiver.getReccomendedListings(
      this.state.filterData, userIsDoc, lastPostIndex, lastSubscriptionLevel
    );
    if (!!data) {
      if (!!this.state.sideNavData) {
        if (Object.keys(this.state.sideNavData).length > 0) {
          let newData = {...this.state.sideNavData, ...data};
          this.setState({
            sideNavData: newData,
          })
        } else {
          this.setState({
            sideNavData: data,
          })
        }
      } else {
        this.setState({
          sideNavData: data,
        })
      }
    } 
  }

  onMapUpdate = async (selectedProp, zoom) => {
    let propData = await this.getPropData(selectedProp);
    this.setState({
      selectedProperty: selectedProp,
      currentZoon: zoom,
      selectedPropData: propData,
    });
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }

  getPropData = async (key) => {
    let listingRetreiver = new ListingRetreiver();
    let data = await listingRetreiver.getPublicListingData(key);
    if (!!data.subscriptionLevel) {
      if (data.subscriptionLevel == "silver" || data.subscriptionLevel == "gold") {
        let price = await listingRetreiver.getPriceForListing(key, this.state.userIsDoc);
        data.rentablePrice = price.price;
      }
    }
    return data;
  }

  onSearchClick = () => {
    this.props.navigation.navigate("Search List", {
      filterData: this.state.filterData,
      homeCallback: this.onFilterComplete,
    });
  }

  onFilterComplete = (filterData) => {
    this.setState({
      filterData: filterData,
      mapKey: Math.random().toString(36).substring(7),
      filterViewKey: Math.random().toString(36).substring(7),
      sideNavData: null,
      selectedProperty: null,
      selectedPropData: null,
    }, ()=>{
      this.getSideNavData(this.state.userIsDoc);
    });
    this.props.navigation.setParams({
      filterData: filterData,
      onFilterComplete: this.onFilterComplete
    })
    if (this.props.navigation.getParent().getState().routes[0].params != undefined) {
      if (this.props.navigation.getParent().getState().routes[0].params.homeCallback != undefined) {
        this.props.navigation.getParent()
          .getState().routes[0].params.homeCallback(filterData);
      }
    }
  }

  createFilterView = (type) => {
    let parent = [];
    parent.push(
      <FilterBar
        onFilterChange={(filterData)=>{
          this.onFilterComplete(filterData)
        }}
        data={this.state.filterData}
        key={this.state.filterBarKey}
      />
    )
    return parent;
  }

  onModalClose = () => {
    this.setState({errorModalVisible: false})
  }

  buildSelectedProp = () => {
    let parent = [];
    if (!!this.state.selectedPropData) {
      parent.push(
        <View 
          style={{
            margin: 5,
            borderColor: "#f2e423",
            borderWidth: 3,
            borderRadius: 8,
            paddingRight: 10,
          }}>
            <ListingPreview 
              navigation={this.props.navigation}
              selectedProperty={this.state.selectedProperty}
              selectedPropData={this.state.selectedPropData}
            />
        </View>
      )
    }
    return parent;
  }

  buildPreviewLabel = (data) => {
    let parent = [];
    if (data.subscriptionLevel == undefined) {
      parent.push(
        <Text style={{fontWeight: "300"}}>Host direct contact available</Text>
      );
    } else if (data.subscriptionLevel == "bronze" || data.subscriptionLevel == "gold") {
      parent.push(
        <Text style={{fontWeight: "300"}}>Host direct contact available</Text>
      );
    }
    return parent;
  }

  buildSideNav = () => {
    let parent = [];
    if (this.state.sideNavData != null) { 
      let keys = Object.keys(this.state.sideNavData);
      keys.forEach(index => {
        let data = this.state.sideNavData[index];
        let locationLabel = "";
        if (data.city) {
            locationLabel += data.city;
        }
        if (data.state) {
            locationLabel += " " + data.state;
        }
        if (data.country) {
            locationLabel += ", " + data.country;
        }
        parent.push(
            <Container maxWidth={260}>
              <TouchableHighlight
                onPress={()=>{
                  this.setState({
                    selectedPropData: data,
                    selectedProperty: data.key,
                  })
                }}
                style={{width: "100%"}}
                underlayColor="#fff"
              >
                <>
                  <Text style={{width: "100%", fontWeight: "600"}}>{data.title}</Text>
                  <Text style={{color: "grey", fontSize: 10}}>{locationLabel}</Text>
                  {
                    !!data.photoUrls?
                    <View>
                    <View style={{position: "absolute", top: 0, left: 0}}>
                      <ContentLoader 
                        speed={1}
                        width={220}
                        height={200}
                        viewBox="0 0 220 200"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                      >
                        <Rect x="0" y="0" rx="0" ry="0" width="220" height="220" />
                      </ContentLoader>
                    </View>
                      <Image
                        style={styles.thumbnail}
                        source={{
                            uri: data.photoUrls[0],
                        }}
                      />
                    </View>: null
                  }
                  <Text style={{fontWeight: "300"}}>
                    <Text style={{fontWeight: "600"}}>
                      {data.bedrooms}</Text> bed(s) • <Text style={{fontWeight: "600"}}>{data.bathrooms}</Text> bathroom(s)
                    </Text>
                  {data.rentablePrice != undefined ?
                    <Text style={{fontWeight: "600"}}>${data.rentablePrice}<Text style={{fontWeight: "300"}}> per night</Text></Text>: null  
                  }
                  {this.buildPreviewLabel(data)}
                </>
              </TouchableHighlight>
            </Container>
        )
      });
    }
    return [
      <View style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
        {parent}
        <View style={{width: "100%", display: "flex", alignItems: "center"}}>
          <ActionButton
            text={"Show More"}
            handler={()=>{
              this.getSideNavData(this.state.userIsDoc);
            }}
          />
        </View>
      </View>
    ];
  }

  isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
    const paddingToBottom = 20;
    return layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom;
  };

  buildWebView = () => {
    let parent = [];
    let sideNavTopMargin = 60;
    if (window.innerWidth <= 1095 && window.innerWidth >= 872) {
      sideNavTopMargin = 120;
    }
    if (window.innerWidth >= 1300) {
      parent.push(
        <View 
          style={{
            height: window.innerHeight,
            width: window.innerWidth,
          }}
        >
          <View style={[styles.fullSearchWrapper, {width: this.state.width - 250}]}>
              {this.createFilterView("full")}
              <TouchableHighlight
                onPress={() => {
                  this.onSearchClick();
                }}
                style ={styles.searchButton}
              >
                <Image
                  style={styles.searchIcon}
                  source={require('../assets/stackIcon.png')}
                />
              </TouchableHighlight>
          </View>
          <View style={[styles.desktopContentWrapper, { width: this.state.width - 520, height: this.state.height - 60}]}>
            <Map 
              navigation={this.props.navigation}
              isHome={true}
              isFullScreen={true}
              key={this.state.mapKey}
              handler={this.onMapUpdate}
              filterData={this.state.filterData}
              zoom={this.state.currentZoon}
              userIsPhysician={this.state.userIsDoc}
              selectedKey={this.state.selectedProperty}
              selectedPropData={this.state.selectedPropData}
            />
          </View>
        </View>,
        <View 
          style={[styles.sideNavWrapper, {height: this.state.height - sideNavTopMargin}]}
        >
          {this.buildSelectedProp()}
          <ScrollView 
            onScroll={({nativeEvent}) => {
              if (this.isCloseToBottom(nativeEvent)) {
                this.getSideNavData(this.state.userIsDoc);
              }
            }}
            scrollEventThrottle={400}
          >
            {this.buildSideNav()}
          </ScrollView>
        </View>
      );
    } else if (window.innerWidth >= 873) {
      parent.push(
        <View 
          style={{
            height: window.innerHeight,
            width: window.innerWidth,
          }}
        >
          <View style={[styles.fullSearchWrapper, {width: this.state.width - 250}]}>
              {this.createFilterView()}
              <TouchableHighlight
                onPress={() => {
                  this.onSearchClick();
                }}
                style ={styles.searchButton}
              >
                <Image
                  style={styles.searchIcon}
                  source={require('../assets/stackIcon.png')}
                />
              </TouchableHighlight>
          </View>
          <View style={[styles.desktopContentWrapper, { width: this.state.width - 520, height: this.state.height - 60}]}>
            <Map 
              navigation={this.props.navigation}
              isHome={true}
              isFullScreen={true}
              key={this.state.mapKey}
              handler={this.onMapUpdate}
              filterData={this.state.filterData}
              zoom={this.state.currentZoon}
              userIsPhysician={this.state.userIsDoc}
              selectedKey={this.state.selectedProperty}
              selectedPropData={this.state.selectedPropData}
            />
          </View>
        </View>,
        <View 
          style={[styles.sideNavWrapperSmall, {top: sideNavTopMargin, height: this.state.height - sideNavTopMargin}]}
        >
          {this.buildSelectedProp()}
          <ScrollView
            onScroll={({nativeEvent}) => {
              if (this.isCloseToBottom(nativeEvent)) {
                this.getSideNavData(this.state.userIsDoc);
              }
            }}
            scrollEventThrottle={400}
          >
            {this.buildSideNav()}
          </ScrollView>
        </View>
      );
    } else {
      parent.push(
        <ScrollView>
          <View 
            style={{
              padding: 20,
              backgroundColor: "white",
              height: Dimensions.get('window').height - 65,
            }}
          >
            <Modal
              animationType="slide"
              transparent={true}
              visible={this.state.errorModalVisible}
              key={this.state.errorModalKey}
              onModalClose={this.onModalClose}
              labelText={"Please enter a location to search"}
              isError={true}
            />
            <Map 
              navigation={this.props.navigation}
              isHome={true}
              key={this.state.mapKey}
              handler={this.onMapUpdate}
              filterData={this.state.filterData}
              zoom={this.state.currentZoon}
              userIsPhysician={this.state.userIsDoc}
              selectedKey={this.state.selectedProperty}
              selectedPropData={this.state.selectedPropData}
            />
            <View style={styles.searchWrapper}>
              {this.createFilterView()}
            </View>
            <Footer isHome={true}/>
          </View>
        </ScrollView>
      );
    }
    return parent;
  }

  buildBottomSheet = () => {
    let parent = [];
    parent.push(
      <UserAgreementComponent/>,
      <View style={{height: 10}}/>,
      <CheckBox 
        label={"I agree"}
        checked={this.state.userAgreed}
        key={Math.random().toString(36).substring(7)}
        handler={checked => {
          this.setState({
            userAgreed: checked,
          })
        }}
      />
    );
    if (this.state.userAgreementError != null) {
      parent.push(
        <Text style={{fontWeight: "600", color: "red"}}>Please indicate you accept</Text>
      );
    }
    parent.push(
      <View style={{height: 10}}/>,
      <ActionButton
        text="Continue"
        handler={()=>{
          if (this.state.userAgreed) {
            //set user aceptance data
            this.setState({bottomSheetIndex: -1})
            let today = new Date();
            const db = getDatabase();
            const agreementRef = ref(db, `userAgreements/${this.state.user.uid}`);
            let data = {
              accepted: true,
              date: today.getTime(),
            }
            set(agreementRef, data);
          } else {
            this.setState({
              userAgreementError: true,
            })
          }
        }}
      />,
    )
    return parent;
  }

  render() {
      return (
        <>
            {this.buildWebView()}
            <BottomPage 
                open={this.state.bottomSheetIndex == -1? false:  true}
                onClose={()=>{
                    // setBottomSheetIndex(-1);
                    // setLoading(false);
                }}
            >
              {this.buildBottomSheet()}
            </BottomPage>
        </>
      );
  }
}

const ListingPreview = ( props ) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(true);
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  });

  const getLocationLabel = (selectedPropData) => {
    let locationLabel = "";
    if (selectedPropData.city) {
        locationLabel += selectedPropData.city;
    }
    if (selectedPropData.state) {
        locationLabel += " " + selectedPropData.state;
    }
    if (selectedPropData.country) {
        locationLabel += ", " + selectedPropData.country;
    }
    let subscriptionLevel = selectedPropData.subscriptionLevel;
    if (subscriptionLevel == undefined || subscriptionLevel == null) {
      subscriptionLevel = "bronze";
    }
    return locationLabel
  }

  const buildPreviewLabel = (data) => {
    let parent = [];
    if (data.subscriptionLevel == undefined) {
      parent.push(
        <Text style={{fontWeight: "300"}}>Host direct contact available</Text>
      );
    } else if (data.subscriptionLevel == "bronze" || data.subscriptionLevel == "gold") {
      parent.push(
        <Text style={{fontWeight: "300"}}>Host direct contact available</Text>
      );
    }
    return parent;
  }

  return(
    <ExpandableView
      isOpen={isOpen}
      showTrigger={false}
    >
      <Container>
        <TouchableHighlight
          onPress={()=>{
            props.navigation.getParent()
            .getState().routes[0].params = {
              selectedKey: props.selectedPropData.key,
          };
            props.navigation.navigate("Public Property", {
              rentalData: props.selectedPropData,
            });
          }}
          style={{width: "100%"}}
          underlayColor="#fff"
        >
          <>
            <Text style={{width: "100%", fontSize: 18, fontWeight: "600"}}>{props.selectedPropData.title}</Text>
            <Text style={{color: "grey", fontSize: 10}}>{getLocationLabel(props.selectedPropData)}</Text>
            <View>
              <View style={{position: "absolute", zIndex: 1, width: "100%", height: "100%"}}>
                <ContentLoader 
                    speed={1}
                    width={"100%"}
                    height={"100%"}
                    viewBox="0 -20 590 100%"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                  <Rect x="0" y="0" rx="2" ry="2" width="100%" height="100%" />
                </ContentLoader>
              </View>
              <Image
                style={styles.thumbnail}
                source={{
                    uri: props.selectedPropData.photoUrls[0],
                }}
              />
            </View>
            <Text style={{fontWeight: "300"}}>
                <Text style={{fontWeight: "600"}}>
                  {props.selectedPropData.bedrooms}</Text> bed(s) • <Text style={{fontWeight: "600"}}>{props.selectedPropData.bathrooms}</Text> bathroom(s)
                </Text>
              {props.selectedPropData.rentablePrice != undefined ?
                <Text style={{fontWeight: "600"}}>${props.selectedPropData.rentablePrice}<Text style={{fontWeight: "300"}}> per night</Text></Text>: null  
              }
              {buildPreviewLabel(props.selectedPropData)}
          </>
        </TouchableHighlight>
      </Container>
    </ExpandableView>
  )
}

const styles = StyleSheet.create({
  fullSearchWrapper: {
    zIndex: 11,
    flexDirection: 'row',
    backgroundColor: "white",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    paddingTop: 5,
    shadowOffset: {
      width: 0,
      height: 2,
    },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
  },
  searchWrapper: {
    width: "100%",
    height: 40,
    zIndex: 110,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    top: 10,
    position:"absolute",
    left: 0,
  },
  searchButton: {
    width:40,
    height:40,
    justifyContent: 'center',
    marginLeft:10,
  },
  searchIcon: {
    width:35,
    height:35,
  },
  inputBoxFullWidth: {
    width: '60%',
    height: 45,
    padding: 10,
    fontSize: 14,
    borderColor: '#d3d3d3',
    borderBottomWidth: 1,
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    maxWidth: 800,
  },
  inputBox: {
    width: '85%',
    height: 50,
    padding: 10,
    fontSize: 14,
    borderColor: '#d3d3d3',
    borderBottomWidth: 1,
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    maxWidth: 800,
  },
  desktopContentWrapper: {
    backgroundColor: "white",
    position: "absolute",
    top: 60,
    marginLeft: 0,
    marginTop: 0,
    paddingTop: 0,
    overflow: "hidden",
    zIndex: 10,
  },
  thumbnail: {
    width: "100%",
    height: 200,
    zIndex: 10,
  },
  hidden: {
    height: 0,
  },
  list: {
    overflow: 'hidden'
  },
  sideNavWrapper: {
    backgroundColor: "lightgrey", 
    width: 550, 
    position: "absolute", 
    right: 0, 
    top: 60, 
    zIndex: 9,
    borderLeftColor: "lightgrey",
    borderLeftWidth: 1,
    borderTopColor: "lightgrey",
    borderTopWidth: 1,
  },
  sideNavWrapperSmall: {
    width: 270, 
    position: "absolute", 
    right: 0, 
    zIndex: 20,
  },
});
