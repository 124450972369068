import React, { Component } from 'react';
import { StyleSheet, Text, View, Platform, ScrollView } from 'react-native';
import HTML from "react-native-render-html";

const htmlContent = `
<p class="p1"><strong>Privacy Policy <br></strong></p>
<p class="p2">Effective Date: December <span class="s1">22</span>, 2020</p>
<p class="p2">At Doc to Doc Rentals, LLC (hereinafter, &ldquo;<strong>Doc to Doc Rentals</strong>,&rdquo; &ldquo;<strong>we</strong>,&rdquo; &ldquo;<strong>our</strong>,&rdquo; or &ldquo;<strong>us</strong>&rdquo;), we take your privacy seriously and want you to understand how we are using information and the ways in which you can protect your privacy.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Our Privacy Policy explains the following:</p>
<ul class="ul1">
    <li class="li2">What information we collect.</li>
    <li class="li2">How we use and share the information we collect.</li>
    <li class="li2">What choices are available to you regarding the use of your information.</li>
    <li class="li2">The security procedures in place to protect your information.</li>
</ul>
<p class="p3">Capitalized terms used but not defined here have the meanings given to them in our <span class="s3">Terms of Service</span>.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p4"><br></p>
<p class="p3"><strong>1. &nbsp; &nbsp;Information We Collect</strong>&nbsp;</p>
<p class="p4"><br></p>
<p class="p2">We collect information to operate our company effectively and to provide better Services to our Users.<span class="Apple-converted-space">&nbsp;&nbsp;</span>We collect information in the following ways:</p>
<ul class="ul1">
    <li class="li2"><strong>Information you provide us.</strong> We collect and have access to information that you voluntarily provide us via account registration or other communication from you.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Such information may include personal information such as your name, email, and physician license number.</li>
    <li class="li5"><strong>Information we get from your use of our Services.</strong><span class="Apple-converted-space">&nbsp;&nbsp;</span>Automatically as you navigate through our website or our mobile applications, and primarily by the service providers we use to help provide the Services to our Users (e.g., Google Firebase and PayPal).<span class="Apple-converted-space">&nbsp;&nbsp;</span>Information collected automatically may include usage details, IP addresses, and information collected through automatic tracking technologies. Google Firebase&rsquo;s Privacy and Security information is currently available at <span class="s4">https://firebase.google.com/support/privacy#firebase_is_certified_under_major_privacy_and_security_standards</span> and PayPal&rsquo;s Privacy Statement is available at <span class="s4">https://www.paypal.com/us/webapps/mpp/ua/privacy-full</span>.</li>
</ul>
<p class="p3"><strong>2. &nbsp; &nbsp;How We Use and Share the Information We Collect</strong></p>
<p class="p4"><br></p>
<p class="p2">We use the information we collect to provide, maintain, protect, and improve the Services, to develop and provide new products and services, and to protect Doc to Doc Rentals and our Users.<span class="Apple-converted-space">&nbsp;&nbsp;</span>We also use the information to communicate with you, for example, responding to your questions or requests and informing you about your account, any upcoming changes or improvements of the Services, and any new products and services or other information that you might be interested in.</p>
<p class="p2">We will not share your personal information (i.e., information that identifies you) with any third party outside of our company unless one of the following circumstances applies:</p>
<ul class="ul1">
    <li class="li2"><strong>With your consent.</strong><span class="Apple-converted-space">&nbsp;&nbsp;</span>We will share your personal information with parties outside of Doc to Doc Rentals when we have your consent to do so.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Except for the cases listed below, we require opt-in consent for the sharing of any sensitive personal information.</li>
    <li class="li2"><strong>With service providers.</strong><span class="Apple-converted-space">&nbsp;&nbsp;</span>We may engage third-party service providers to perform certain functions and assist us in providing you with and improving the Services.<span class="Apple-converted-space">&nbsp;&nbsp;</span>They may have access to your personal information needed to perform their functions.</li>
    <li class="li2"><strong>For legal reasons.</strong><span class="Apple-converted-space">&nbsp;&nbsp;</span>We may share personal information with third parties if we have a good-faith belief that it is reasonably necessary to:<ul class="ul1">
            <li class="li2">Meet any applicable law, regulation, legal process or enforceable governmental request.</li>
            <li class="li2">Enforce our <span class="s3">Terms of Service</span>, including investigation of potential violations.</li>
            <li class="li2">Detect, prevent, investigate, mitigate, or otherwise address fraud, security, or technical issues.</li>
            <li class="li2">Protect against harm to the rights, property, or safety of Doc to Doc Rentals, our Users, or the public as required or permitted by law.</li>
        </ul>
    </li>
    <li class="li2"><strong>Business transfers</strong>.<span class="Apple-converted-space">&nbsp;&nbsp;</span>If we buy, or are sold to, another entity, customer information generally is included in the transferred business assets.<span class="Apple-converted-space">&nbsp;</span></li>
</ul>
<p class="p3">We may use and disclose de-identified, non-personal information in any manner, including to share it with third parties to analyze and understand general trends and usage statistics of our Services.</p>
<p class="p4"><br></p>
<p class="p3"><strong>3. &nbsp; &nbsp;Your Access to and Control Over Information</strong></p>
<p class="p2">At Doc to Doc Rentals, we strive to provide our Users with access to and control over their information.<span class="Apple-converted-space">&nbsp;&nbsp;</span>You may do the following on your own by going to your account and/or by contacting us at: doc2docrentals@gmail.com.</p>
<ul class="ul1">
    <li class="li2">See what information we have about you, if any.</li>
    <li class="li2">Change and/or correct any information we have about you.</li>
    <li class="li2">Have us delete any information we have about you (subject to our rights to keep it to the extent necessary to comply with applicable law, to enforce our agreements with you, or protect ourselves or others).</li>
    <li class="li2">Opt out of future communications from us (but note that opting out of transactional communications from us may mean we cannot provide the Services to you).</li>
    <li class="li2">Express any concern you have about our use of your information.</li>
</ul>
<p class="p4"><br></p>
<p class="p3"><strong>4. &nbsp; &nbsp;Security</strong></p>
<p class="p3">&nbsp;<br>We strive to protect your information by using reputable third parties, such as Go Daddy (<a href="https://www.godaddy.com/"><span class="s4">https://www.godaddy.com/</span></a>) to host our website, using SSL certificate to encrypt traffic, and storing our database on Google Firebase which is ISO and SOC compliant (see <a href="https://firebase.google.com/support/privacy#firebase_is_certified_under_major_privacy_and_security_standards"><span class="s4">https://firebase.google.com/support/privacy#firebase_is_certified_under_major_privacy_and_security_standards</span></a>).<span class="Apple-converted-space">&nbsp;&nbsp;</span>Please understand that while we take reasonable measures to safeguard your information, no Internet or email transmission is 100% secure or error free.<span class="Apple-converted-space">&nbsp;&nbsp;</span>It is important for you to protect against unauthorized access to your password and to your computer.</p>
<p class="p3"><br><strong>5. &nbsp; &nbsp;Do Not Track Notice<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p4"><br></p>
<p class="p3">Some browsers may give you &quot;Do Not Track&quot; settings to request that a website operator not track your browser information across its website or other websites.<span class="Apple-converted-space">&nbsp;&nbsp;</span>There is no consensus among industry participants as to what &ldquo;Do Not Track&rdquo; means in this context.<strong>&nbsp;</strong>While we do not currently honor the &ldquo;Do Not Track&rdquo; requests, we will use your information only in accordance with this Privacy Policy. <span class="s7"><span class="Apple-converted-space">&nbsp;</span></span></p>
<p class="p4"><br></p>
<p class="p3"><strong>6. &nbsp; &nbsp;Age Requirement and No Information from Children<span class="Apple-converted-space">&nbsp;</span></strong></p>
<p class="p3">&nbsp;<br>Our Services are not intended for Users under eighteen (18) years of age, and we do not knowingly collect information from them.<span class="Apple-converted-space">&nbsp;&nbsp;</span>If you are under 18, please do not use or access our Services at any time or in any manner.<span class="Apple-converted-space">&nbsp; &nbsp;</span></p>
<p class="p4"><br></p>
<p class="p3"><strong>7. &nbsp; &nbsp;Applicability of our Privacy Policy</strong></p>
<p class="p4"><br></p>
<p class="p3">This Privacy Policy applies only to our website, mobile applications, and any other Services of ours that link to it.<span class="Apple-converted-space">&nbsp;&nbsp;</span>If you go to a third-party website or third-party websites from our Services, please note that such third-party websites may collect your information and have their own privacy policies.<span class="Apple-converted-space">&nbsp;&nbsp;</span>Doc to Doc Rentals does not control those websites, and this Privacy Policy does not apply to those external websites and/or third parties.<span class="Apple-converted-space">&nbsp;</span></p>
<p class="p4"><br></p>
<p class="p3"><strong>8. &nbsp; &nbsp;Changes&nbsp;</strong>&nbsp;</p>
<p class="p3"><br>Our Privacy Policy may change from time to time.<span class="Apple-converted-space">&nbsp;&nbsp;</span>We will post any privacy policy changes on this page and, if the changes are significant, we will take reasonable steps to notify you either by prominently posting a notice of such changes or by directly sending you a notification.</p>
<p class="p4"><br></p>
<p class="p3"><strong>If you have any questions about this Privacy Policy, please feel free to contact us at doc2docrentals@gmail.com.</strong></p>
`;


export default class TermsOfService extends Component {

    constructor(props) {
        super(props);
    } 

    render() {
        return (
            <View style={styles.wholeThingWrapper}>
                <Text style={styles.headerLabeltext}>Privacy Policy</Text>
                <ScrollView style={styles.scrollContainer} nestedScrollEnabled={true}>
                    <HTML source={{ html: htmlContent }}/>
                </ScrollView>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    headerLabeltext: {
        fontSize: 24,
        marginBottom: 20,
        fontWeight: "700",
        textAlign: "center",
        color: "black"
    },
    scrollContainer: {
        width: "100%",
        height: "80%",
        borderColor: "grey",
        borderWidth: 1,
        backgroundColor: "#dedede",
        padding: 10,
    },
    wholeThingWrapper: {
        height: 300,
        maxWidth: 1100,
        width: "100%",
        marginHorizontal: 20,
        paddingLeft: 20,
        paddingRight: 20,
    },
    red: {
        color: "red",
    },
    "p_p1":{"margin":"0.0px 0.0px 6.0px 0.0px","font":"11.0px Calibri","color":"#000000","WebkitTextStroke":"#000000"},
    "p_p2":{"margin":"0.0px 0.0px 6.0px 0.0px","font":"11.0px Calibri","color":"#000000","WebkitTextStroke":"#000000","backgroundColor":"#ffffff","minHeight":"13.0px"},
    "p_p3":{"margin":"0.0px 0.0px 6.0px 0.0px","font":"11.0px Calibri","color":"#000000","WebkitTextStroke":"#000000","backgroundColor":"#ffffff"},
    "p_p4":{"margin":"0.0px 0.0px 6.0px 0.0px","font":"11.0px Calibri","color":"#000000","WebkitTextStroke":"#000000","minHeight":"13.0px"},
    "p_p5":{"margin":"0.0px 0.0px 6.0px 0.0px","font":"11.0px Calibri","color":"#fb0007","WebkitTextStroke":"#000000"},
    "p_p6":{"margin":"0.0px 0.0px 12.0px 0.0px","font":"11.0px Calibri","color":"#000000","WebkitTextStroke":"#000000"},
    "p_p7":{"margin":"0.0px 0.0px 12.0px 0.0px","font":"11.0px Calibri","color":"#000000","WebkitTextStroke":"#000000","minHeight":"13.0px"},
    "li_li1":{"margin":"0.0px 0.0px 6.0px 0.0px","font":"11.0px Calibri","color":"#000000","WebkitTextStroke":"#000000"},
    "li_li6":{"margin":"0.0px 0.0px 12.0px 0.0px","font":"11.0px Calibri","color":"#000000","WebkitTextStroke":"#000000"},
    "span_s1":{"backgroundColor":"#ffff0b"},"span_s2":{"font":"11.0px Symbol"},"span_s3":{"textDecoration":"underline"},
    "span_s4":{"font":"12.0px 'Times New Roman'"},"span_s5":{"color":"#0c61ad","backgroundColor":"#ffffff"},
    "span_s6":{"backgroundColor":"#ffffff"},"span_s7":{"textDecoration":"underline","color":"#fb0007"},
    "span_s8":{"color":"#fb0007"},"ul_ul1":{"listStyleType":"disc"}});
