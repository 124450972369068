import { getDatabase, ref, set } from "firebase/database";

export const requestUpdate = async (userID, updateRentalData) => {
    //upload updatedRetalData to the updateRental table and send key
    const db = getDatabase();
    const updateRetalRef = ref(db, `updateRental/${userID}/${updateRentalData.key}`);
    let success = await set(updateRetalRef, updateRentalData);
    let url = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/editPost?";
    url += "postID="+updateRentalData.key;
    url += "&requesterUID="+userID
    let response  = await fetch(url);
    let data = await response.json();
    if (!!data.response) {
        if (data.response == "success") {
            return true;
        } else {
            return false;
        }
    }
}

