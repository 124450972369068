import React, { Component } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import GoogleMapReact from 'google-map-react';
import { getDatabase, ref, onChildChanged, onChildRemoved, onChildAdded } from "firebase/database";
import { doc, onSnapshot, getFirestore, collection } from "firebase/firestore";

import WebMarker from "./Marker";
import ListingRetreiver from "../helpers/listing-retreiver";
import Modal from './Modal';

export default class MapComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rentalsData: null,
      width: Dimensions.get('window').width - 90,
      height: Dimensions.get('window').height - 0,
      currentZoom: 9,
      draggable: true,
      loading: false,
      loadingModalKey: Math.random().toString(36).substring(7),
      unsubscribe: null,
    };
    this.mapClicked = this.mapClicked.bind(this);
    this.onChildMouseMove = this.onChildMouseMove.bind(this)
    this.onChildMouseUp = this.onChildMouseUp.bind(this)
    this.onChildMouseDown = this.onChildMouseDown.bind(this)
  } 

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    if (this.props.isHome) {
      this.getRentalData();
      if (!!this.props.selectedKey) {
        this.setState({ selectedKey: this.props.selectedKey});
      }
    } else {
      this.setState({ rentalsData: this.props.rentalsData});
    }
  }

  componentWillUnmount() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }
  }

  updateDimensions = () => {
    if (!!this.props.navigation) {
      if (this.props.navigation.isFocused()) {
        this.setState({ 
          width:  Dimensions.get('window').width - 90,
          height: window.innerHeight - 0
        });
      }
    }
  };

  getRentalData = async () => {
    if (this.props.filterData != null) {
      if (
        this.props.filterData.bathrooms != null ||
        this.props.filterData.bedrooms != null ||
        this.props.filterData.checkIn != null ||
        this.props.filterData.checkOut != null ||
        this.props.filterData.people != null ||
        this.props.filterData.petFriendly == true ||
        this.props.filterData.washerDryer == true ||
        this.props.filterData.location != null ||
        this.props.filterData.keyword != null
      ) {
        this.setState({
          loading: true,
          loadingModalKey: Math.random().toString(36).substring(7),
        })
        let listingRetreiver = new ListingRetreiver();
        let data = await listingRetreiver.getListings(this.props.filterData, "home");
        this.setState({ 
          rentalsData: data,
          loading: false,
          loadingModalKey: Math.random().toString(36).substring(7),
        });
      } else {
        this.getAllRentals();
      }
    } else {
      this.getAllRentals();
    }
  }

  getAllRentals = () => {
    const db = getFirestore();
    const unsubscribe = onSnapshot(collection(db, "rentals"), (snapshot) => {
      let rentalsData = {};
      snapshot.docs.forEach(doc => {
        let listingData = doc.data();
        rentalsData[listingData.key] = listingData;
      });
      this.setState({rentalsData});
    });
    this.setState({unsubscribe});
  }

  onMarkerClick = (props, marker, e) => {
    if (this.props.isHome) {
      if (!this.props.isFullScreen) {
        this.props.navigation.navigate('Public Property', {
          rentalData: this.state.rentalsData[props],
        });
      }
      this.props.handler(
        this.state.rentalsData[props].key, 
        this.state.currentZoom
      );
      this.setState({
        selectedKey: this.state.rentalsData[props].key,
      })

      //this is and ugly line of code but it works
      this.props.navigation.getParent()
        .getState().routes[0].params = {
        selectedKey: this.state.rentalsData[props].key,
      }
    } 
  }

  mapClicked(lat, lng,) {
    let listingData = {
      lat: lat,
      lng: lng,
    }
    if (!!this.props.handler) {
      this.setState({ listingsData: listingData, });
      this.props.handler(listingData)
    }
  }

  onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    let newRentalData = this.state.rentalsData;
    newRentalData.latitude = latLng.lat();
    newRentalData.longitude = latLng.lng();
    let rentalData = {
      latitude: newRentalData.latitude ,
      longitude: newRentalData.longitude,
    }
    this.props.handler(rentalData)
    this.setState({ rentalsData: newRentalData});
  }


  onChildMouseDown(){
    // set map no draggable
    this.setState({
        draggable: false,
    });
  }

  onChildMouseUp(hoverKey, childProps, mouse){
    let newListingData = this.state.rentalsData;
    newListingData.lat = mouse.lat;
    newListingData.lng = mouse.lng;
    if (!!this.props.onBlur) {
      this.props.onBlur(newListingData)
    }
    //set map draggable again
    this.setState({
        draggable: true,
    });
  }

  onChildMouseMove(hoverKey, childProps, mouse){
  }

  createMarkers = () => {
    let parent = [];
    if (!!this.state.rentalsData) {
      if (this.props.isHome) {
        for (var key in this.state.rentalsData) {
          const rentalData = this.state.rentalsData[key]
          if (!!rentalData) {
            if (rentalData.key == this.props.selectedKey ) {
              if (rentalData.public != null) {
                if (rentalData.public) {
                  parent.push(
                    <WebMarker 
                      onClick={(data) => this.onMarkerClick(data)}
                      key={key}
                      name={key}
                      lat={rentalData.latitude}
                      lng={rentalData.longitude}
                      selected={true}
                      draggable={false}
                    />
                  )
                } else if (!rentalData.public) {
                  if (this.props.userIsPhysician) {
                    parent.push(
                      <WebMarker 
                        onClick={(data) => this.onMarkerClick(data)}
                        key={key}
                        name={key}
                        lat={rentalData.latitude}
                        lng={rentalData.longitude}
                        selected={true}
                        draggable={false}
                      />
                    )
                  }
                }
              } else {
                parent.push(
                  <WebMarker 
                    onClick={(data) => this.onMarkerClick(data)}
                    key={key}
                    name={key}
                    lat={rentalData.latitude}
                    lng={rentalData.longitude}
                    selected={true}
                    draggable={false}
                  />
                )
              }
            } else if (!!rentalData.latitude && !!rentalData.longitude) {
              if (rentalData.public != null) {
                if (rentalData.public) {
                  let lat = parseFloat(rentalData.latitude);
                  let lng = parseFloat(rentalData.longitude)
                  parent.push(
                    <WebMarker 
                      onClick={(data) => this.onMarkerClick(data)}
                      key={key}
                      name={key}
                      text={rentalData.title}
                      lat={lat}
                      lng={lng}
                      pro={true}
                      draggable={false}
                    />
                  )
                } else if (!rentalData.public) {
                  if (this.props.userIsPhysician) {
                    let lat = parseFloat(rentalData.latitude);
                    let lng = parseFloat(rentalData.longitude)
                    parent.push(
                      <WebMarker 
                        onClick={(data) => this.onMarkerClick(data)}
                        key={key}
                        name={key}
                        text={rentalData.title}
                        lat={lat}
                        lng={lng}
                        pro={true}
                        draggable={false}
                      />
                    )
                  }
                }
              } else {
                let lat = parseFloat(rentalData.latitude);
                let lng = parseFloat(rentalData.longitude)
                parent.push(
                  <WebMarker 
                    onClick={(data) => this.onMarkerClick(data)}
                    key={key}
                    name={key}
                    text={rentalData.title}
                    lat={lat}
                    lng={lng}
                    pro={true}
                    draggable={false}
                  />
                );
              }
            }
          } 
        }
      } else {
        if (this.props.draggable) {
          parent.push(
            <WebMarker 
              key={key}
              name={key}
              lat={this.state.rentalsData.latitude}
              lng={this.state.rentalsData.longitude}
              pro={true}
              draggable={true}
              // onMarkerDragEnd={(t, map, coord) => this.onMarkerDragEnd(coord)}
              onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
            />
          )
        } else {
          parent.push(
            <WebMarker 
              key={key}
              name={key}
              lat={this.state.rentalsData.latitude}
              lng={this.state.rentalsData.longitude}
              pro={true}
              draggable={false}
            />
          )
        }
      }
      return parent;
    } 
  }

  render() {
    if (this.props.isHome) {
      let zoom = 4;
      let lat = 39.8283;
      let lng = -98.5795;
      if (!!this.props.selectedKey && !!this.props.selectedPropData) {
        let selectedPropData = this.props.selectedPropData;
        zoom = 6;
        lat = selectedPropData.latitude;
        lng = selectedPropData.longitude;
      } else if (this.props.filterData) {
        if (!!this.props.filterData.location) {
          zoom = 10;
          if (!!this.props.filterData.location.lat) {
            lat = this.props.filterData.location.lat;
            lng = this.props.filterData.location.lng;
          }
        } 
      }
      if (this.props.isFullScreen) {
        let width = this.state.width - 430;
        if (this.state.width >= 1210) {
          width = this.state.width - 710;
        }
        return (
          <View style={{
            width: width,
            height: "100%",
            padding: 0,
            margin: 0,
            backgroundColor: "green",
            zIndex: 10,
          }}>
            <Modal
              transparent={true}
              visible={this.state.loading}
              key={this.state.loadingModalKey}
              onModalClose={this.onModalClose}
              isLoading={true}
            />
            <GoogleMapReact
              bootstrapURLKeys={{ 
                key: "AIzaSyCgAEOikmxZ9Zb9JB20pSHGZLKACyt9YZk",
                libraries:['places']
              }}
              center={{
                lat: lat,
                lng: lng
              }}
              draggable={true}
              zoom={zoom}
              containerStyle={{ 
                padding: 0,
                marginLeft: 20,
                marginRight: 20,
              }}
              options={{
                zoomControlOptions: { position: 5 },
              }}
            >
              {this.createMarkers()}
            </GoogleMapReact>
          </View>
        );
      } else {
        return (
          <View style={{
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            width: this.state.width + 50,
            height: Dimensions.get('window').height  - 170,
            marginTop: 50,
            backgroundColor: "blue"
          }}>
            <Modal
              transparent={true}
              visible={this.state.loading}
              key={this.state.loadingModalKey}
              onModalClose={this.onModalClose}
              isLoading={true}
            />
            <GoogleMapReact
              bootstrapURLKeys={{ 
                key: "AIzaSyCgAEOikmxZ9Zb9JB20pSHGZLKACyt9YZk",
                libraries:['places']
              }}
              center={{
                lat: lat,
                lng: lng
              }}
              draggable={true}
              zoom={zoom}
              containerStyle={{ 
                width: this.state.width + 50,
                height: Dimensions.get('window').height  - 170,
                zIndex: 0,
                padding: 0,
                backgroundColor: null,
                maxWidth: 900,
              }}
            >
              {this.createMarkers()}
            </GoogleMapReact>
          </View>
        );
      }
    } else {
      if (!!this.props.rentalsData) {
        if (this.props.rentalsData.latitude != null) {
          return (
            <View style={{
              backgroundColor: 'white',
              alignItems: 'center',
              justifyContent: 'center',
              width: 300,
              height: this.state.height,
              maxHeight: 300,
              marginTop: 0,
            }}>
              <GoogleMapReact
                bootstrapURLKeys={{ 
                  key: "AIzaSyCgAEOikmxZ9Zb9JB20pSHGZLKACyt9YZk",
                  libraries:['places']
                }}
                center={{
                  lat: this.props.rentalsData.latitude,
                  lng: this.props.rentalsData.longitude
                }}
                draggable={this.state.draggable}
                zoom={this.state.currentZoom}
                containerStyle={{ 
                  width: 300,
                  height: this.state.height,
                  maxHeight: 300,
                  zIndex: 0,
                  padding: 0,
                  backgroundColor: null,
                }}
                onClick={map => {
                  this.mapClicked(map.lat, map.lng)
                }} 
                onChildMouseDown={() => this.onChildMouseDown()}
                onChildMouseUp={(key, childProps, mouse) => this.onChildMouseUp(key, childProps, mouse)}
                onChildMouseMove={(key, childProps, mouse) => this.onChildMouseMove(key, childProps, mouse)}
              >
                {this.createMarkers()}
              </GoogleMapReact>
            </View>
          );
        } else {
          return (
            <View style={{
              backgroundColor: 'white',
              alignItems: 'center',
              justifyContent: 'center',
              width: 300,
              height: this.state.height,
              maxHeight: 300,
            }}>
              <GoogleMapReact
                bootstrapURLKeys={{ 
                  key: "AIzaSyCgAEOikmxZ9Zb9JB20pSHGZLKACyt9YZk",
                  libraries:['places']
                }}
                center={{
                  lat: 39.8283,
                  lng: -98.5795
                }}
                draggable={this.state.draggable}
                zoom={4}
                containerStyle={{ 
                  width: 300,
                  height: this.state.height,
                  maxHeight: 300,
                  zIndex: 0,
                  padding: 0,
                }}
                onClick={map => {
                  this.mapClicked(map.lat, map.lng)
                }} 
                onChildMouseDown={() => this.onChildMouseDown()}
                onChildMouseUp={(key, childProps, mouse) => this.onChildMouseUp(key, childProps, mouse)}
                onChildMouseMove={(key, childProps, mouse) => this.onChildMouseMove(key, childProps, mouse)}
              />
            </View>
          );
        }
      } else {
        return (
          <View style={{
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            width: 300,
            height: this.state.height,
            maxHeight: 300,
          }}>
            <GoogleMapReact
              bootstrapURLKeys={{ 
                key: "AIzaSyCgAEOikmxZ9Zb9JB20pSHGZLKACyt9YZk",
                libraries:['places']
              }}
              center={{
                lat: 39.8283,
                lng: -98.5795
              }}
              draggable={true}
              onClick={map => {
                this.mapClicked(map.lat, map.lng)
              }} 
              zoom={4}
              containerStyle={{ 
                width: 300,
                height: this.state.height,
                maxHeight: 300,
                zIndex: 0,
                padding: 0,
                marginLeft: 20,
                marginRight: 20,
              }}
              onChildMouseDown={() => this.onChildMouseDown()}
              onChildMouseUp={(key, childProps, mouse) => this.onChildMouseUp(key, childProps, mouse)}
              onChildMouseMove={(key, childProps, mouse) => this.onChildMouseMove(key, childProps, mouse)}
            />
          </View>
        )
      }
    }
  }
}

const styles = StyleSheet.create({
  filterLabel: {
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
  }
});

