import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, Image, Platform } from 'react-native';

import Container from '../compenents/Container';
import Modal from "../compenents/Modal";
import Footer from "../compenents/Footer";
import ActionButton from '../compenents/ActionButton';

export default class Success extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            modalKey: Math.random().toString(36).substring(7)
        }
        this.onDonePress = this.onDonePress.bind(this);
        this.props.navigation.setOptions({headerShown: false});
    }

    onDonePress = () => {
        this.props.navigate("My Rentals");
    }

    onSharePress = () => {
        this.setState({
            modalVisible: true,
            modalKey: Math.random().toString(36).substring(7)
        });
    }

    onModalClose = () => {
        this.setState({modalVisible: false});
    }

    onCopyPress = () => {
        this.setState({
            modalVisible: false,
            successModalVisible : true,
            successModalKey: Math.random().toString(36).substring(7),
        });
    }

    buildView = () => {
        let parent = [];
        if (!!this.props.route.params.rentalData) {
            const postURL = "https://doc2doc-d54a2.web.app/?postID=" + this.props.route.params.rentalData.key;
            parent.push(
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.modalVisible}
                    key={this.state.modalKey}
                    onModalClose={this.onModalClose}
                    onCopyPress={this.onCopyPress}
                    website={postURL}
                    labelText=""
                />,
                <Text allowFontScaling={false} style={[styles.centeredText, styles.bold]}>
                    You have successfully posted your listing!
                </Text>,
                <Text allowFontScaling={false} style={[styles.centeredText, {fontWeight: "600"}]}>
                    Please allow up at 5 minutes for your listing to go live.
                </Text>,
                <Text allowFontScaling={false} style={styles.centeredText}>
                   You will receive an email confirming your listing as well as explaining how to cancel or delete your post at anytime.
                </Text>,
                <Image 
                    source={require('../assets/checkmark.png')}
                    style={styles.checkMark}
                />,
                <ActionButton 
                    text={"Share"}
                    handler={()=>{
                        this.onSharePress();
                    }}
                />,
                <ActionButton 
                    text={"Done"}
                    handler={()=>{
                        this.props.navigation.navigate('My Properties');
                    }}
                />
            ); 
        } else if (!!this.props.route.params.reservationData) {
            parent.push(
                <Text allowFontScaling={false} style={[styles.centeredText, styles.bold]}>
                    You have successfully completed your reservation
                </Text>,
                <Image 
                    source={require('../assets/checkmark.png')}
                    style={styles.checkMark}
                />,
                <Text allowFontScaling={false} style={styles.centeredText}>
                    Check in instructions will be sent 48 hours before check in time.
                </Text>,
                <Text allowFontScaling={false} style={styles.centeredText}>
                    If you wish to cancel you can do so in the "My Trips" page on the main menu.
                </Text>,
                <ActionButton 
                    text={"Done"}
                    handler={()=>{
                        this.props.navigation.navigate('Home View');
                    }}
                />
            ); 
        } else if (!!this.props.route.params.reservationRequestData) {
            parent.push(
                <Text allowFontScaling={false} style={[styles.centeredText, styles.bold]}>
                    Your request has been sent
                </Text>,
                <Text allowFontScaling={false} style={styles.centeredText}>
                    You will receive a notification when the host has responded.
                </Text>,
                <Image 
                    source={require('../assets/checkmark.png')}
                    style={styles.checkMark}
                />,
                <ActionButton 
                    text={"Done"}
                    handler={()=>{
                        if (Platform.OS == "web") {
                            this.props.navigation.navigate('Home');
                        } else {
                            this.props.navigation.navigate('Home View');
                        }
                    }}
                />
            ); 
        } else if (!!this.props.route.params.canceledReservationID) {
            parent.push(
                <Text allowFontScaling={false} style={[styles.centeredText, styles.bold]}>
                    Your have successfully canceled a reservation
                </Text>,
                <Text allowFontScaling={false} style={styles.centeredText}>
                    A refund has been initiated and the reservation has been canceled.
                </Text>,
                <Image 
                    source={require('../assets/checkmark.png')}
                    style={styles.checkMark}
                />,
                <ActionButton 
                    text={"Done"}
                    handler={()=>{
                        this.props.navigation.navigate('My Trips View');
                    }}
                />
            ); 
        } else if (!!this.props.route.params.hostCanceledReservationID) {
            parent.push(
                <Text allowFontScaling={false} style={[styles.centeredText, styles.bold]}>
                    Your have successfully canceled a reservation
                </Text>,
                <Text allowFontScaling={false} style={styles.centeredText}>
                    A refund has been initiated to the guest and the reservation has been canceled.
                </Text>,
                <Image 
                    source={require('../assets/checkmark.png')}
                    style={styles.checkMark}
                />,
                <ActionButton 
                    text={"Done"}
                    handler={()=>{
                        this.props.navigation.navigate('My Properties');
                    }}
                />
            ); 
        } else if (!!this.props.route.params.canceledRequestID) {
            parent.push(
                <Text allowFontScaling={false} style={[styles.centeredText, styles.bold]}>
                    Your have successfully canceled a reservation request
                </Text>,
                <Text allowFontScaling={false} style={styles.centeredText}>
                    Your request has been canceled 
                </Text>,
                <Image 
                    source={require('../assets/checkmark.png')}
                    style={styles.checkMark}
                />,
                <ActionButton 
                    text={"Done"}
                    handler={()=>{
                        this.props.navigation.navigate('My Trips View');
                    }}
                />
            ); 
        }
        return parent;
    }

    render() {
        return (
            <ScrollView contentContainerStyle={styles.container}>
                <Container>
                    { this.buildView() }
                </Container>
                <Footer hideText={true}/>
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 60,
    },
    headerLabeltext: {
        fontSize: 24,
        marginBottom: 10,
        marginTop: 50,
        fontWeight: "700",
        textAlign: "center",
    },
    mt20: {
        marginTop: 20,
    },
    centeredText: {
        textAlign: "center",
        marginBottom: 5,
    },
    bold: {
        fontWeight: "600",
    },
    button: {
        marginTop: 10,
        marginBottom: 10,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
        height: 36,
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    checkMark: {
        width: 250,
        height: 250,
    }
})