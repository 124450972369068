import React, { Component } from 'react';
import { StyleSheet, Text, View, ScrollView, Platform, Image, Dimensions } from 'react-native';
import {Calendar} from 'react-native-calendars';

import Container from '../compenents/Container';
import BackButton from "../compenents/BackButton";
import ActionButton from '../compenents/ActionButton';

import { getDateString } from '../helpers/date-formatter';

export default class ReservationsDateView extends Component {

    constructor(props) {
        super(props);
        const todayDate = new Date();
        const todayDateString = todayDate.getUTCFullYear() +"/"+
                                (todayDate.getUTCMonth()+1) +"/"+
                                todayDate.getUTCDate()
        this.state = {
            checkIn: null,
            checkOut: null,
            markedDates: null,
            calendarKey: Math.random().toString(36).substring(7),
            selectedMonth: todayDateString,
            width: Dimensions.get("screen").width,
        };
    } 

    componentDidMount() {
        if (Platform.OS == "web") {
            window.addEventListener('resize', this.updateDimensions);
            this.setState({
                width: window.innerWidth,
            });
        }
        if (this.props.route.params.filterData != undefined) {
            if (this.props.route.params.filterData.checkIn != null) {
                const checkInDate = new Date(this.props.route.params.filterData.checkIn);
                const checkInDateString = checkInDate.getUTCFullYear() +"/"+
                                        (checkInDate.getUTCMonth()+1) +"/"+
                                        checkInDate.getUTCDate();
                this.setState({
                    calendarKey: Math.random().toString(36).substring(7),
                    selectedMonth: checkInDateString,
                });
            }

            if (this.props.route.params.filterData.checkIn != null && this.props.route.params.filterData.checkOut == null) {
                let markedDates = {};
                markedDates[this.props.route.params.filterData.checkIn] = {startingDay: true, endingDay: true, color: '#3a93f1', textColor: 'white'};
                this.setState({
                    markedDates: markedDates,
                    checkIn: this.props.route.params.filterData.checkIn,
                    checkOut: null,
                    calendarKey: Math.random().toString(36).substring(7),
                });
            } else if (this.props.route.params.filterData.checkIn != null && this.props.route.params.filterData.checkOut != null) {
                let newMarkedDates = {};
                let startDate = new Date(this.props.route.params.filterData.checkIn);
                let endDate = new Date(this.props.route.params.filterData.checkOut);
                let datesAray = this.getDaysArray(startDate, endDate);
                let datesCount = datesAray.length;
                for (var key in datesAray) {
                    let date = datesAray[key].toISOString().slice(0,10);
                    if (key == 0) {
                        this.setState({
                            checkIn: date,
                        });
                        newMarkedDates[date] = {startingDay: true, color: '#3a93f1', textColor: 'white'};
                    } else if (datesCount == (parseInt(key) + 1)) {
                        this.setState({
                            checkOut: date,
                        });
                        newMarkedDates[date] = {endingDay: true, color: '#3a93f1', textColor: 'white'};
                    } else {
                        newMarkedDates[date] = {color: '#3a93f1', textColor: 'white'};
                    }
                }
                this.setState({
                    markedDates: newMarkedDates,
                    calendarKey: Math.random().toString(36).substring(7),
                });
            } else if (this.props.route.params.filterData.checkIn == null && this.props.route.params.filterData.checkOut != null) {
                let markedDates = {};
                markedDates[this.props.route.params.filterData.checkOut] = {startingDay: true, endingDay: true, color: '#3a93f1', textColor: 'white'};
                this.setState({
                    markedDates: markedDates,
                    checkIn: null,
                    checkOut: this.props.route.params.filterData.checkOut,
                    calendarKey: Math.random().toString(36).substring(7),
                });
            }
        }
    }

    updateDimensions = () => {
        if (!!this.props.navigation) {
          if (this.props.navigation.isFocused()) {
            this.setState({
                width: window.innerWidth,
                calendarKey: Math.random().toString(36).substring(7),
              });
          }
        }
      };
    

    onDayPress = (day) => {
        if (this.state.markedDates == null) {
            let markedDates = {};
            markedDates[day.dateString] = {startingDay: true, endingDay: true, color: '#3a93f1', textColor: 'white'};
            this.setState({
                markedDates: markedDates,
                checkIn: day.dateString,
                checkOut: null,
            })
        } else {
            let keyCount = Object.keys(this.state.markedDates).length;
            if (keyCount == 1) {
                let newMarkedDates = {};
                let startDate = new Date(this.state.checkIn);
                let endDate = new Date(day.dateString);
                let datesAray = [];
                if (startDate.getTime() > endDate.getTime()) {
                    datesAray = this.getDaysArray(endDate, startDate);
                } else {
                    datesAray = this.getDaysArray(startDate, endDate);
                }
                let datesCount = datesAray.length;
                for (var key in datesAray) {
                    let date = datesAray[key].toISOString().slice(0,10);
                    if (key == 0) {
                        this.setState({
                            checkIn: date,
                        });
                        newMarkedDates[date] = {startingDay: true, color: '#3a93f1', textColor: 'white'};
                    } else if (datesCount == (parseInt(key) + 1)) {
                        this.setState({
                            checkOut: date,
                        });
                        newMarkedDates[date] = {endingDay: true, color: '#3a93f1', textColor: 'white'};
                    } else {
                        newMarkedDates[date] = {color: '#3a93f1', textColor: 'white'};
                    }
                }
                this.setState({
                    markedDates: newMarkedDates,
                });
            } else {
                let markedDates = {};
                markedDates[day.dateString] = {startingDay: true, endingDay: true, color: '#3a93f1', textColor: 'white'};
                this.setState({
                    markedDates: markedDates,
                    checkIn: day.dateString,
                    checkOut: null,
                });
            }
        }
    }

    getDaysArray = (s,e) => {
        for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;
    };

    resetDates = () => {
        this.setState({
            checkIn: null,
            checkOut: null,
            markedDates: null,
            calendarKey: Math.random().toString(36).substring(7),
        })
    }

    selectDatesTaped = () => {
        this.props.route.params.callback(this.state.checkIn, this.state.checkOut);
        this.props.navigation.goBack();
    }

    buildView = () => {
        let parent = [];
        let dateOutputString = "";
        if (this.state.checkIn != null) {
            let checkInOutput = getDateString(this.state.checkIn);
            dateOutputString += checkInOutput;
        }
        if (this.state.checkOut != null) {
            if (this.state.checkIn != null) {
                let checkOutOutput = getDateString(this.state.checkOut);
                dateOutputString += " - " + checkOutOutput;
            } else {
                dateOutputString += this.state.checkOut;
            }
        } 
        parent.push(
            <Text allowFontScaling={false} style={styles.label}>{dateOutputString}</Text>,
            <Text style={styles.headerLabeltext}>Search Dates</Text>
        );

        const todayDate = new Date();
        const todayDateString = todayDate.getUTCFullYear() +"/"+
                                (todayDate.getUTCMonth()+1) +"/"+
                                todayDate.getUTCDate()
        let calendarWidth = this.state.width - 30;
        parent.push(
            <Calendar
                horizontal={true}
                style={[styles.calendar, {width: calendarWidth}]}
                markedDates={this.state.markedDates}
                key={this.state.calendarKey}
                onDayPress={(day) => this.onDayPress(day)}
                minDate={todayDateString}
                enableSwipeMonths={true}
                markingType={"period"}
                renderArrow={(direction) => direction === 'left' ? 
                    <Image
                        source={require('../assets/backArrowIcon.png')}
                        style={styles.leftIcon}
                    ></Image> : 
                    <Image
                        source={require('../assets/forwardArrowIcon.png')}
                        style={styles.rightIcon}
                    ></Image>
                }
                onMonthChange={(month) => {
                    this.setState({selectedMonth: month.dateString});
                }}
                current={this.state.selectedMonth}
          />
        )

        parent.push(
            <View style={styles.buttonWrapper}>
                <ActionButton 
                    text={"Select Dates"}
                    handler={()=>{
                        this.selectDatesTaped();
                    }}
                />
                <ActionButton 
                    text={"Reset dates"}
                    handler={()=>{
                        this.resetDates();
                    }}
                    filled={true}
                />
            </View>
        );
        return parent;
    }

    render() {
        return (
            <View style={styles.viewContainer}>
                <BackButton
                    navigation={this.props.navigation}
                ></BackButton>
                <ScrollView style={[styles.container]}>
                    <View style={{alignItems: "center", width: "100%"}}>
                        <Container>
                            { this.buildView() }
                        </Container>
                    </View>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    viewContainer: {
        alignItems: "center",
        width: "100%",
    },
    container: {
        width: "100%",
    },
    headerLabeltext: {
        marginTop: 10,
        fontSize: 24,
        fontWeight: "700",
        textAlign: "center",
    },
    button: {
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    calendar: {
        height: 400,
        marginTop: 10,
        maxWidth: 600,
        width: "100%",
    },
    leftIcon: {
        width:40,
        height:40,
    },
    rightIcon: {
        width:40,
        height:40,
    },
    buttonWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    labelText: {
        color: "black",
        fontSize: 16,
        maxWidth: 1000,
        padding: 10,
        paddingBottom: 10,
    },
    label: {
        fontWeight: "600",
        marginTop: 10,
        marginLeft: 70,
        fontWeight: "500",
        textAlign: "center",
        height: 40,
        marginRight: 10,
        paddingTop: 10,
        borderColor: 'grey',
        borderWidth: 0.5,
        borderRadius: 8,
        backgroundColor: "#f5f5f5",
        minWidth: 250,
    },
});