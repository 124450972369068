import React, { Component } from 'react';
import { StyleSheet, Text, View, TextInput, ScrollView } from 'react-native';
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Modal from "../compenents/Modal";
import Checkbox from "../compenents/CheckBox";
import Footer from "../compenents/Footer";
import ActionButton from '../compenents/ActionButton';

export default class ReportPost extends Component {

    constructor(props) {
        super(props);
        this.state = {
            falseMisleading: false,
            falseMisleadingKey: Math.random().toString(36).substring(7),
            notADoctor: false,
            notADoctorKey: Math.random().toString(36).substring(7),
            unsafe: false,
            unsafeKey: Math.random().toString(36).substring(7),
            other: false,
            otherKey: Math.random().toString(36).substring(7),
            message: "",
            modalVisible: false,
            modalKey: Math.random().toString(36).substring(7),
            errorModalVisible: false,
            errorModalKey: Math.random().toString(36).substring(7),
            successModalVisible: false,
            successModalKey : Math.random().toString(36).substring(7),
        }
    }

    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
        });
    }

    sendReport = () => {
        if (this.state.falseMisleading || this.state.notADoctor || this.state.unsafe || this.state.other) {
            const _this = this;
            let url = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/reportPost?";
            url += "reportedPostID="+this.props.route.params.postID;
            url += "senderUID="+this.state.user.uid;
            url += "&falseMisleading="+this.state.falseMisleading;
            url += "&notADoctor="+this.state.notADoctor;
            url += "&unsafe="+this.state.unsafe;
            url += "&other="+this.state.other;
            url += "&message="+this.state.message;
            fetch(url)
            .then(response => response.json())
            .then(data => {
                if (!!data.response) {
                    if (data.response == "success") {
                        _this.setState({
                            successModalVisible: true,
                            successModalKey: Math.random().toString(36).substring(7),
                            falseMisleading: false,
                            falseMisleadingKey: Math.random().toString(36).substring(7),
                            notADoctor: false,
                            notADoctorKey: Math.random().toString(36).substring(7),
                            unsafe: false,
                            unsafeKey: Math.random().toString(36).substring(7),
                            other: false,
                            otherKey: Math.random().toString(36).substring(7),
                            message: "",
                        });
                    } else {
                        _this.setState({
                            errorModalText: "Group password is incorrect", 
                            errorModalKey: Math.random().toString(36).substring(7),
                            errorModalVisible: true,
                        })
                    }
                }
            });
        } else {
            this.setState({
                errorModalVisible: true,
                errorModalKey: Math.random().toString(36).substring(7),
            });
        }
    }

    onReportButtonPress = () => {
        this.setState({
            modalVisible: true,
            modalKey: Math.random().toString(36).substring(7),
        });
    }

    onConfirmClick = () => {
        this.sendReport();
    }

    onModalClose = () => {
        this.setState({
            modalVisible: false,
            errorModalVisible : false,
            successModalVisible: false,
        });
    }

    render() {
        return (
            <ScrollView style={styles.scrollWrapper}>
                <View style={styles.container}>
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.modalVisible}
                        key={this.state.modalKey}
                        onModalClose={this.onModalClose}
                        onConfirmClick={this.onConfirmClick}
                        labelText="Are you sure you'd like to report this post?"
                    />
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.successModalVisible}
                        key={this.state.successModalKey}
                        onModalClose={this.onModalClose}
                        isSuccess={true}
                        labelText="Thanks for the feedback. We will look into this post asap."
                    />
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.errorModalVisible}
                        key={this.state.errorModalKey}
                        onModalClose={this.onModalClose}
                        isError={true}
                        labelText="Please select at least one of the listed reasons why you wish to report this post."
                    />
                <Text allowFontScaling={false} style={styles.headerLabeltext}>Report this post</Text>
                <Text allowFontScaling={false} style={styles.labeltext}>Select the reason or reasons why you wish to report this post:</Text>
                <Checkbox 
                        label={"This post has false or misleading information"}
                        checked={this.state.falseMisleading}
                        key={this.state.falseMisleadingKey}
                        handler={checked => {
                            this.setState({
                                falseMisleading: checked,
                                falseMisleadingKey: Math.random().toString(36).substring(7),
                            }); 
                        }}
                    />
                <Checkbox 
                        label={"The person posting this listing is not a doctor"}
                        checked={this.state.notADoctor}
                        key={this.state.notADoctorKey}
                        handler={checked => {
                            this.setState({
                                notADoctor: checked,
                                notADoctorKey: Math.random().toString(36).substring(7),
                            }); 
                        }}
                    />
                    <Checkbox 
                        label={"This post is unsafe"}
                        checked={this.state.unsafe}
                        key={this.state.unsafeKey}
                        handler={checked => {
                            this.setState({
                                unsafe: checked,
                                unsafeKey: Math.random().toString(36).substring(7),
                            }); 
                        }}
                    />
                    <Checkbox 
                        label={"Other"}
                        checked={this.state.other}
                        key={this.state.otherKey}
                        handler={checked => {
                            this.setState({
                                other: checked,
                                otherKey: Math.random().toString(36).substring(7),
                            }); 
                        }}
                    />
                    <TextInput
                        style={styles.inputBox}
                        onChangeText={otherReportMessage => {
                            this.setState({ message: otherReportMessage});
                        }}
                        placeholder='Optional message'
                        placeholderTextColor='black'
                        autoCapitalize='none'
                        value={this.state.message}
                    />
                    <ActionButton 
                        text={"Send Report"}
                        handler={()=>{
                            this.onReportButtonPress();
                        }}
                    />
                    
                </View>
                <Footer hideText="true"/>
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    scrollWrapper: {
        backgroundColor: 'white',
        height: '100%',
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerLabeltext: {
        fontSize: 24,
        marginBottom: 30,
        fontWeight: "700",
        textAlign: "center",
    },
    labeltext: {
        fontSize: 16,
        marginBottom: 30,
        fontWeight: "500",
        textAlign: "center",
    },
    button: {
        marginTop: 30,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    buttonSignup: {
        fontSize: 12
    },
    inputBox: {
        width: '85%',
        maxWidth: 1000,
        marginTop: 10,
        padding: 15,
        marginBottom: 20,
        fontSize: 16,
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        textAlign: 'center',
        backgroundColor: "#f5f5f5",
    },
})