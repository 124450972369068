import React, { Component } from 'react';
import { Platform, ScrollView, StyleSheet, Text, View, TouchableHighlight, Image } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

import Container from "../compenents/Container";

export default class HowTo extends Component {

    openYoutube = () => {
        const url = "https://www.youtube.com/channel/UC0E6ZIbL0H2izcufhDLbvRg";
        if (Platform.OS !== 'web') {
            try {
                const results = WebBrowser.openBrowserAsync(url).catch(error => {
                    //show error modal
                    console.log(error)
                    this.setState({
                        errorModalVisible : true,
                        errorModalText: "There was an issue opening the following URL: "+ url,
                        errorModalKey: Math.random().toString(36).substring(7),
                    });
                });
            } catch (error) {
                console.log(error);
            }
        } else {
            window.open(url, "_blank")
        }
    }

    render() {
        return (
            <ScrollView contentContainerStyle={styles.container}>
                <Container title="How to use Doc to Doc Rentals">
                    <Text style={{marginBottom: 20}}>Check out our Youtube channel to learn how to use Doc to Doc Rentals</Text>
                     <TouchableHighlight 
                        onPress={()=>{
                            this.openYoutube();
                        }}
                        style={{marginBottom: 10}}>
                        <Image
                            source={require('../assets/youtubeLogo.png')}
                            style={{width: 280, height: 60}}
                        ></Image>
                    </TouchableHighlight>
                </Container>
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    video: {
        height: 300,
        padding: 10,
    },
    videoInner: {
        width: 10,
        flex: 1,
    },
    content: {
        flex: 1,
    },
    header: {
        width: "100%",
        justifyContent: "center",
        alignItems: "center"
    },
    headerText: {
        textAlign: "center",
        fontWeight: "600",
    },
    accordion: {
        width: "100%",
    },
    underline: {
        textDecorationLine: "underline"
    }
})