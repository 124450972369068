export const electric = require('./electricIcon.png'); 
export const pet = require('./petIcon.png'); 
export const washer = require('./washerIcon.png'); 
export const baby = require('./baby.png'); 
export const toddler = require('./baby-boy.png'); 
export const children = require('./children.png'); 
export const crib = require('./baby-crib.png'); 
export const highChair = require('./high-chair.png'); 
export const handiCap = require('./wheel-chair.png'); 
export const pool = require('./swimming-pool.png'); 
export const hotTub = require('./hot-tub.png'); 
export const wifi = require('./wifi.png'); 
export const kitchen = require('./kitchen.png'); 
export const fridge = require('./fridge.png'); 
export const freezer = require('./freezer.png'); 
export const oven = require('./oven.png'); 
export const stoveTop = require('./electric-stove.png'); 
export const microwave = require('./microwave.png'); 
export const utencils = require('./spoon-and-fork.png'); 
export const dishes = require('./dishes.png'); 
export const coffeeMaker = require('./coffee-machine.png'); 
export const dishWasher = require('./dish-washer.png'); 
export const tv = require('./television.png'); 
export const hotWater = require('./water-temperature.png'); 
export const barbeque = require('./grill.png'); 
export const bathTub = require('./bathIcon-small.png'); 
export const shower = require('./shower.png'); 
export const towels = require('./towel.png'); 
export const ac = require('./air-conditioner.png'); 
export const carEV = require('./charging-station.png'); 
export const firepit = require('./fire.png'); 
export const patio = require('./table.png'); 

export const house = require('./house.png'); 
export const bedroom = require('./bedroom.png'); 
export const tinyHouse = require('./tinyhouse.png'); 
export const yurt = require('./yurt.png'); 
export const cabin = require('./cabin.png'); 
export const treeHouse = require('./treehouse.png'); 
export const campground = require('./campground.png'); 
export const rv = require('./rv.png'); 
export const hotel = require('./hotel.png'); 
export const hostel = require('./hostel.png'); 
export const bedAndBreakfast = require('./bedAndBreakfast.png'); 
export const other = require('./otherIcon.png'); 