import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, Platform, FlatList } from 'react-native';
import { Video, AVPlaybackStatus } from 'expo-av';

import Logo from "../compenents/Logo";
import Container from "../compenents/Container";
import Footer from "../compenents/Footer";
import GoldInfo from '../compenents/GoldInfo';
import SilverInfo from '../compenents/SilverInfo';
import BronzeInfo from '../compenents/BronzeInfo';

export default class About extends Component {

    render() {
        return (
            <ScrollView contentContainerStyle={styles.container}>
                <Logo/>
                <Container title="Misson Statement">
                    <Text allowFontScaling={false}  style={styles.centeredText}>
                        To improve medicine by empowering Physicians
                    </Text>
                    <View style={{height: 10}}/>
                </Container>
                <Container title={"How it work"}>
                    <Text allowFontScaling={false}  style={styles.centeredText}>
                        Using our mobile or Web app physicians can share their properties with one another with intentions of either
                        renting or selling to each other. Users can either find listings on a map, or search by city, state or country.
                        Once users have found a listing, and have signed in, can either access the posters contact information and request to book, 
                        or book directly through the app. Hosts can choose to rent to the public or to Physicians only.
                    </Text>
                    <View style={{height: 10}}/>
                </Container>
                <Container title="What makes Doc to Doc unique?">
                    <Text allowFontScaling={false}  style={styles.centeredText}>
                        Doc to Doc Rentals is built to cater to physician hosts. Some of our unique features include:
                    </Text>
                    <FlatList
                        style={{margin: 10}}
                        data={[
                        {text: '• 3 levels of listings to cater to different business models.'},
                        {text: '• Ability to be contacted directly.'},
                        {text: '• Listings can also be elected to be available to public or to doctors only. '},
                        {text: '• Hosts select to charge a nightly fee for the public and a different amount for physicians.'},
                        {text: '• Hosts can also elect to collect taxes at varying rates which will be paid out separetly. '},
                        ]}
                        renderItem={({item}) => <Text allowFontScaling={false}  style={styles.centeredText}>{item.text}</Text>}
                    />
                    <Text allowFontScaling={false}  style={styles.centeredText}>
                        In the following video we will cover the features that make Doc to Doc Rentals unique and how we cater to Physician hosts.
                    </Text>
                    <Video
                        style={styles.video}
                        source={{uri: "https://doc2doc-d54a2.web.app/media/What-makes-Doc-to-Doc-Rental-unique_.mp4"}}
                        useNativeControls={true}
                        resizeMode="contain"
                        onReadyForDisplay={videoData => {
                            if (Platform.OS == "web") {
                                videoData.srcElement.style.position = "initial"
                            }
                        }}
                    />
                </Container>
                <Container title={"Pricing"}>
                    <Text allowFontScaling={false}  style={[styles.subheaderLabeltext]}>To rent a property:</Text>
                    <Text allowFontScaling={false}  style={styles.centeredText}>
                        Depending on how the host has chosen to list, you can either rent directly through the app
                        for a 10% fee or contact hosts directly for free.
                    </Text>
                    <View style={{height: 20}}/>
                    <Text allowFontScaling={false}  style={[styles.subheaderLabeltext]}>To list a property:</Text>
                    <Text>Properties can be listed under the following three levels. Each level has the following features/ prices/ fees:</Text>
                    <View style={{display: "flex", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", marginTop: 10, marginBottom: 10}}>
                        <BronzeInfo/>
                        <SilverInfo/>
                        <GoldInfo/>
                    </View>
                    <Text allowFontScaling={false}  style={styles.centeredText}>
                        All listings have basic info such as photos and a description. 
                        They are able to be found on a map or by searching by city/ keyword. 
                        Listings can also be elected to be available to public or to doctors only. 
                        Hosts select to charge a nightly fee for the public and a different amount for physicians.
                        Hosts can also elect to collect taxes at varying rates which will be paid out separetly. 
                    </Text>
                    <View style={{height: 10}}/>
                </Container>
                <Container title={"About Us"}>
                    <Text allowFontScaling={false} style={styles.centeredText}>
                        Doc to Doc Rentals was started by an Emergency Medicine Physician, Dr K Kay Moody, in 2012 as a Facebook
                        group with the mission to support physicians to support one another by renting to and from colleagues.
                        The ability to list homes for sale was subsequently added.
                    </Text>
                    <Text allowFontScaling={false} style={[styles.centeredText, styles.mt10]}>
                        The group of thousands of physicians has outgrown Facebook with a need for a more organized approach. Dustin Spengler,
                        Dr Moody’s son, developed a support website/ mobile apps to organize properties for easy search. 
                    </Text>
                    <Text allowFontScaling={false} style={[styles.centeredText, styles.mt10]}>
                        The website and apps have search function for properties by location and “save favorites” capability and physicians may
                        list properties with up to 20 photos, include availability, amenities, and a “link share” function to other rental platforms. 
                    </Text>
                    <View style={{height: 10}}/>
                </Container>
                <Footer hideText={true}/>
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    subheaderLabeltext: {
        fontSize: 16,
        marginBottom: 10,
        fontWeight: "500",
    },
    mt10: {
        marginTop: 10,
    },
    mt20: {
        marginTop: 20,
    },
    centeredText: {
        maxWidth: 800,
        paddingLeft: 20,
        paddingRight: 20,
    },
    leftText: {
        textAlign: "left",
    },
    leftWrapper: {
        width: "100%",
    },
    dot: {
        fontSize: 5,
    },
    dotWrapper: {
        textAlign: "center",
        width: 20,
        alignContent: "center",
        alignItems: "center",
        paddingBottom: 3,
    },
    video: {
        width: "100%",
        margin: 10,
        minHeight: 300,
    },
})