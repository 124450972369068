import React, { Component } from 'react';
import { StyleSheet, Image, View, TouchableHighlight } from 'react-native';

export default class Menu extends Component {
  render() {
    return (        
      <TouchableHighlight 
          onPress={() => this.props.navigation.toggleDrawer()}
          style ={styles.findARentalButtonWrapper}>
          <View style ={styles.menuWrapper}>
              <Image
                style={styles.menuIcon}
                source={require('../assets/MenuIcon.png')}
              ></Image>
              <Image
                style={styles.profileIcon}
                source={require('../assets/profileIcon.png')}
              ></Image>
          </View>
      </TouchableHighlight>
    )
  }
}

const styles = StyleSheet.create({
  findARentalButtonWrapper: {
    height: 42,
    width:75,

    backgroundColor : "white",
    padding: 10,
    marginLeft: 10,

  },
  menuWrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 2,
    zIndex: 10,
  },
  menuIcon: {
    width: 22,
    height: 22,
    zIndex: 10,
  },
  profileIcon: {
    width: 22,
    height: 22,
    marginLeft: 3,
  },
});
