import React, { Component } from 'react';

import Checkbox from "./CheckBox";

let data = [
    {key: "baby", label: "Safe for babies"},
    {key: "toddler", label: "Safe for toddlers"},
    {key: "children", label: "Safe for children"},
    {key: "crib", label: "Crib"},
    {key: "highChair", label: "High chair"},
    {key: "handiCap", label: "Wheelchair accessible"},
    {key: "pool", label: "Pool"},
    {key: "hotTub", label: "Hot Tub"},
    {key: "wifi", label: "Free Wifi"},
    {key: "kitchen", label: "Kitchen"},
    {key: "fridge", label: "Refridgerator"},
    {key: "freezer", label: "freezer"},
    {key: "stoveTop", label: "Stove top"},
    {key: "microwave", label: "Microwave"},
    {key: "utencils", label: "Utencils"},
    {key: "dishes", label: "Dishes"},
    {key: "coffeeMaker", label: "Coffee Maker"},
    {key: "dishWasher", label: "Dish Washer"},
    {key: "tv", label: "TV"},
    {key: "hotWater", label: "Hot Water"},
    {key: "barbeque", label: "Barbeque Grill"},
    {key: "bathTub", label: "Bath Tub"},
    {key: "shower", label: "Shower"},
    {key: "towels", label: "Towels"},
    {key: "ac", label: "Air Conditioning"},
    {key: "carEV", label: "Car EV Hookup"},
    {key: "firepit", label: "Fire pit"},
    {key: "patio", label: "Patio"},
];

let defaultData = {
    baby: false,
    toddler: false,
    children: false,
    crib: false,
    highChair: false,
    handiCap: false,
    pool: false,
    hotTub: false,
    wifi: false,
    kitchen: false,
    fridge: false,
    freezer: false,
    oven: false,
    stoveTop: false,
    microwave: false,
    utencils: false,
    dishes: false,
    coffeeMaker: false,
    dishWasher: false,
    tv: false,
    hotWater: false,
    barbeque: false,
    bathTub: false,
    shower: false,
    towels: false,
    ac: false,
    carEV: false,
    firepit: false,
    patio: false,
}

export default class Ammenities extends Component {
    
    constructor(props) {
        super(props);
        this.onCheck = this.onCheck.bind(this);
    }

    onCheck(checked, key) {
        if (!!this.props.rentalData) {
            let rentalData = this.props.rentalData
            if (!!!rentalData.extraAmmenities) {
                rentalData.extraAmmenities = {};
            }
            rentalData.extraAmmenities[key] = checked;
            this.props.onCheck(rentalData.extraAmmenities);
        } else {
            let rentalData = defaultData
            rentalData[key] = checked;
            this.props.onCheck(rentalData);
        }
    }

    buildView = () => {
        let parent = [];
        if (!!this.props.rentalData.extraAmmenities) {
            data.forEach(element => {
                parent.push(
                    <Checkbox 
                        label={element.label}
                        checked={this.props.rentalData.extraAmmenities[element.key] ? true : false}
                        key={Math.random().toString(36).substring(7)}
                        handler={checked => this.onCheck(checked, element.key)}
                        icon={element.key}
                    />
                )
            });
        } else {
            data.forEach(element => {
                parent.push(
                    <Checkbox 
                        label={element.label}
                        checked={false}
                        key={Math.random().toString(36).substring(7)}
                        handler={checked => this.onCheck(checked, element.key)}
                        icon={element.key}
                    />
                )
            });
        }
        return parent;
    }

    render() {
        return (<>{this.buildView()}</>);
    }
}