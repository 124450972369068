import React, { Component } from 'react';
import { View, Text, StyleSheet, ScrollView, TextInput, Animated, Platform} from 'react-native';
import {Picker} from '@react-native-picker/picker';
import { getDatabase, ref, get, push, child, set } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Container from '../compenents/Container';
import Calendar from "../compenents/Calendar";
import Modal from "../compenents/Modal";
import Footer from "../compenents/Footer";
import ActionButton from '../compenents/ActionButton';

const validWebsiteURLRegex = RegExp(
  /[a-z0-9-\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?$/
);

export default class CalendarView extends Component {

  constructor(props) {
    super(props);
    this.state = {
       rentalData: null,
        availabilityData: null,
        currentMonth: "",
        modalVisible: false,
        successWrapperTop: new Animated.Value(-165),
        scrollOffset: new Animated.Value(0),
        calendarKey: Math.random().toString(36).substring(7),
        modalKey: Math.random().toString(36).substring(7),
        showError: false,
        errorKey: Math.random().toString(36).substring(7),
    };
  } 

  componentDidMount() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (user) => {
			if (user) {
          this.setState({ user });
      } else {
          this.setState({ user: null });
      }
    });
    this.loadView(this.props.route.params.rentalData);
    if (Platform.OS == "web") {
      window.onscroll = () => { 
          Animated.spring(this.state.scrollOffset, {
            toValue: window.pageYOffset,
            useNativeDriver: true
          },).start();
      } 
    }
  }

  loadView = (rentalData) => {
    if (rentalData.subscriptionLevel == "silver" || rentalData.subscriptionLevel == "gold") {
      rentalData.availability = "calendar";
      this.setState({rentalData: rentalData});
    } else {
      this.setState({ rentalData : rentalData });
    }
    
    if (this.props.route.params.availabilityData) {
      this.setState({
        availabilityData: this.props.route.params.availabilityData,
        calendarKey: Math.random().toString(36).substring(7),
      })
    } else if (this.props.route.params.availabilityLink) {
      this.setState({
        availabilityLink: this.props.route.params.availabilityLink,
      })
    } else {
      this.getAvailabilityData();
    }
  }

  getAvailabilityData = () => {
    if (this.props.route.params.rentalData != null) {
      const db = getDatabase();
      const availabilityRef = ref(db, `availability/${this.props.route.params.rentalData.key}`);
      get(availabilityRef).then((snapshot) => {
          const availabilityData = snapshot.val();
          if (availabilityData !== null) {
              this.setState({ 
                availabilityData: availabilityData,
                calendarKey: Math.random().toString(36).substring(7),
              });
          } else {
              this.setState({ availabilityData: null});
          }
      });
    }
  }

  onDayClick = (newAvailabilityData) => {
    this.setState({ availabilityData: newAvailabilityData});
  } 

  onSavePress = () => {
    this.setState({
      modalKey: Math.random().toString(36).substring(7),
      modalVisible: true,
    })
  }

  onConfirmClick = () => {
    if (this.state.rentalData.availability == "calendar") {
      this.requestUpdateAvailabilityData("calendar");
    } else if (this.state.rentalData.availability == "hide") {
      this.requestUpdateAvailabilityData("hide");
    } else {
      if (validWebsiteURLRegex.test(this.state.rentalData.availability)) {
        this.requestUpdateAvailabilityData(this.state.rentalData.availability);
      } else {
        this.setState({
          showError: true,
          errorKey: Math.random().toString(36).substring(7),
        })
      }
    }
  }

  requestUpdateAvailabilityData = (type) => {
    let fetchUrl = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/updateAvailability?";
    fetchUrl += "&postUID="+this.state.rentalData.key;
    fetchUrl += "&posterUID="+this.state.user.uid;
    fetchUrl += "&updateAvailabilityData="+JSON.stringify(this.state.availabilityData);
    fetchUrl += "&type="+type;
    fetch(fetchUrl)
    .then(response => response.json())
    .then(data => {
      if (data.response != null) {
        if (data.response == "success") {
            this.showSuccess();
            if (this.props.route.params) {
              if (this.props.route.params.callback) {
                this.props.route.params.callback(this.state.availabilityData);
              }
            }
        } else {
            alert("There was a failire checking out. please reach out to doc2docrentals@gmail.com #88512")
        }
      }
    });
  }

  showSuccess = () => {
      Animated.spring(this.state.successWrapperTop, {
          toValue: 0 + this.state.scrollOffset._value,
          useNativeDriver: true,
          duration: 200
      },).start();
      setTimeout(() => {
          Animated.spring(this.state.successWrapperTop, {
              toValue: -165,
              useNativeDriver: true,
              duration: 200
          },).start();
      }, 1400);
  }

  onModalClose = () => {
    this.setState({successModalVisible : false});
  }

  buildBronzeView = () => {
    let parent = [];
    parent.push(
      <Text allowFontScaling={false} style={styles.titleLabel}>
      Manage your availability using either a calendar, by sharing a link, or hide it all together. {"\n"}
      Select the method you would like to use to manage your availability:
    </Text>
    )
    if (this.state.rentalData.availability === "") {
      parent.push(
        <Picker
          style={{ height: 65,  width: 250, backgroundColor: "#f5f5f5", }}
          itemStyle={styles.itemStyle}
          selectedValue="link"
          onValueChange={(itemValue, itemIndex) => {
            let rentalData = this.state.rentalData
            if (itemValue == "calendar") {
              rentalData.availability = "calendar";
            } else if (itemValue == "hide") {
              rentalData.availability = "hide"
            } else {
              rentalData.availability = "";
            }
            this.setState({rentalData: rentalData});
          }}
        >
          <Picker.Item label="Calendar" value="calendar" />
          <Picker.Item label="Link" value="link" />
          <Picker.Item label="Hide Availability" value="hide" />
        </Picker>,
        <Text allowFontScaling={false} style={styles.titleLabel}>Add the URL to the availability for your listing</Text>,
        <TextInput
            style={styles.inputBox}
            onChangeText={url => {
              let rentalData = this.state.rentalData;
              rentalData.availability = url;
              this.setState({ rentalData: rentalData});
            }}
            placeholder='Website Link'
            placeholderTextColor='black'
            autoCapitalize='none'
            value={this.state.rentalData.availability}
        />
      );
    } else if (this.state.rentalData.availability !== "" && this.state.rentalData.availability !== "hide" && this.state.rentalData.availability !== "calendar") {
      parent.push(
        <Picker
          style={{ height: 65, width: 250, backgroundColor: "#f5f5f5", }}
          itemStyle={styles.itemStyle}
          selectedValue="link"
          onValueChange={(itemValue, itemIndex) => {
            let rentalData = this.state.rentalData
            if (itemValue == "calendar") {
              rentalData.availability = "calendar";
            } else if (itemValue == "hide") {
              rentalData.availability = "hide"
            } else {
              rentalData.availability = "";
            }
            this.setState({rentalData: rentalData});
          }}
        >
          <Picker.Item label="Calendar" value="calendar" />
          <Picker.Item label="Link" value="link" />
          <Picker.Item label="Hide Availability" value="hide" />
        </Picker>,
        <Text allowFontScaling={false} style={styles.titleLabel}>Add the URL to the availability for your listing</Text>,
        <TextInput
            style={styles.inputBox}
            onChangeText={url => {
              let rentalData = this.state.rentalData;
              rentalData.availability = url;
              this.setState({ rentalData: rentalData});
            }}
            placeholder='Website Link'
            placeholderTextColor='black' 
            autoCapitalize='none'
            value={this.state.rentalData.availability}
        />
      );
    } else if (this.state.rentalData.availability == "calendar") {
      parent.push(
        <Picker
          style={{ height: 65, width: 250, backgroundColor: "#f5f5f5", }}
          itemStyle={styles.itemStyle}
          selectedValue={this.state.rentalData.availability}
          onValueChange={(itemValue, itemIndex) => {
            let rentalData = this.state.rentalData
            if (itemValue == "calendar") {
              rentalData.availability = "calendar";
            } else if (itemValue == "hide") {
              rentalData.availability = "hide"
            } else {
              rentalData.availability = "";
            }
            this.setState({rentalData: rentalData});
          }}
        >
          <Picker.Item label="Calendar" value="calendar" />
          <Picker.Item label="Link" value="link" />
          <Picker.Item label="Hide Availability" value="hide" />
        </Picker>,
        <Text allowFontScaling={false} style={styles.titleLabel}>Tap the days you wish to mark as unavailable</Text>,
        <Calendar 
          editable={true}
          blackOut={this.state.availabilityData}
          callback={this.onDayClick}
          key={this.state.calendarKey}
        />
      );
    } else {
      parent.push(
        <Picker
          style={{ height: 65, width: 250, backgroundColor: "#f5f5f5", }}
          itemStyle={styles.itemStyle}
          selectedValue={this.state.rentalData.availability}
          onValueChange={(itemValue, itemIndex) => {
            let rentalData = this.state.rentalData
            if (itemValue == "calendar") {
              rentalData.availability = "calendar";
            } else if (itemValue == "hide") {
              rentalData.availability = "hide"
            } else {
              rentalData.availability = "";
            }
            this.setState({rentalData: rentalData});
          }}
        >
          <Picker.Item label="Calendar" value="calendar" />
          <Picker.Item label="Link" value="link" />
          <Picker.Item label="Hide Availability" value="hide" />
        </Picker>,
      );
    }
    return parent;
  }

  buildSilverGoldView = () => {
    // let rentalData = this.state.rentalData
    // rentalData.availability = "calendar";
    // this.setState({rentalData: rentalData});
    let parent = [];
    parent.push(      
      <Text allowFontScaling={false} style={styles.titleLabel}>
        Manage your availability using the calenda below. {"\n"}
        Tap the days you wish to mark as unavailable:
      </Text>,
      <Calendar 
        editable={true}
        blackOut={this.state.availabilityData}
        callback={this.onDayClick}
        key={this.state.calendarKey}
      />
    )
    return parent;
  }

  buildView = () => {
    if (!!this.state.rentalData) {
      if (!!this.state.rentalData.subscriptionLevel) {
        if (this.state.rentalData.subscriptionLevel == "bronze") {
          return this.buildBronzeView();
        } else {
          return this.buildSilverGoldView();
        }
      } else {
        return this.buildBronzeView();
      }
    }
    //return parent;
  }

  render() {
    return (
      <View style={{height: "100%"}}>
        <Animated.View 
          style={[{
              height: 65,
              width: "100%",
              zIndex: 10,
              backgroundColor: "green",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              position: "absolute",
              transform: [
                  { translateY: this.state.successWrapperTop},
              ],
          }]}
        >
          <Text style={styles.whiteButtonText}>Availability Succesfully Updated</Text>
        </Animated.View>

        <ScrollView
          style={{ 
            width: "100%",
            height: "100%",
        }}>
          <View
            style={{ 
              width: "100%",
              alignItems: 'center',
              justifyContent: 'center',
              height: "100%",
          }}
          >
            <Modal
              animationType="slide"
              transparent={true}
              visible={this.state.modalVisible}
              key={this.state.modalKey}
              onModalClose={this.onModalClose}
              onConfirmClick={() => this.onConfirmClick()}
              labelText="Would you like to save these changes to your availability?"
            />
            <Modal
              animationType="slide"
              transparent={true}
              visible={this.state.showError}
              key={this.state.errorKey}
              onModalClose={this.onModalClose}
              labelText="Website URL is invalid"
              isError={true}
            />
            <Container>
              <Text 
                allowFontScaling={false} 
                style={[styles.headerLabeltext]}
              >
                Availability for {this.props.route.params.rentalData.title}
              </Text>
              {this.buildView()}  
              <ActionButton
                text={"Save"}
                handler={()=>{
                  this.onSavePress();
                }}
              />
            </Container>
            <Footer hideText={true}/>
          </View>
        </ScrollView>
      </View>
    );
  }
}


const styles = StyleSheet.create({
    headerLabeltext: {
      // fontSize: 24,
      marginTop: 10,
      fontWeight: "600",
      textAlign: "center",
  },
  titleLabel: {
    marginTop: 20,
    marginBottom: 20,
    textAlign: "center",
  },
  button: {
    marginTop: 30,
    marginBottom: 20,
    paddingVertical: 5,
    alignItems: 'center',
    backgroundColor: '#FFA611',
    borderColor: '#FFA611',
    borderWidth: 1,
    borderRadius: 5,
    width: 200
  },
  buttonText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'black'
  },
  itemStyle: {
    fontSize: 15,
    height: 75,
    color: 'black',
    fontWeight: 'bold'
  },
  inputBox: {
    width: '85%',
    maxWidth: 1000,
    marginBottom: 10,
    padding: 15,
    fontSize: 16,
    borderColor: '#d3d3d3',
    borderBottomWidth: 1,
    textAlign: 'center',
    backgroundColor: "#f5f5f5",
  },
  whiteButtonText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: 'white'
  },
  mt40: {
    marginTop: 40,
  }
});