import React, { Component } from 'react';
import { StyleSheet, Image, View, } from 'react-native';

import Modal from "./Modal";

export default class Loader extends Component {

    render() {
        return (        
            <Modal
                animationType="slide"
                transparent={true}
                visible={this.props.visible}
                onModalClose={this.onModalClose}
                labelText=""
                isLoading={true}
            />
        )
      }
}