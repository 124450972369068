import React, { Component } from 'react';
import { StyleSheet, View } from 'react-native';
import Collapsible from 'react-collapsible';
import './DropDown.css';

import ActionButton from './ActionButton';

class DropDown extends Component {
    state = {
      expanded: false,
      value: null,
    }

    constructor(props) {
      super(props);
      this.setValue = this.setValue.bind(this);
    }
  
    handlePress = () =>
      this.setState({
        expanded: !this.state.expanded
    });

    setValue(callback) {
      this.setState(state => ({
        value: callback(state.value)
      }), () => { 
        if (!!this.props.callback) {
          this.props.callback(this.state.value);
        }
      });
    }

    buttonSelected = (buttonValue) => {
      if (this.props.callback) {
        this.props.callback(buttonValue)
      }
    }

    buildView = () => {
      let parent = [];
      for (var index in this.props.listItems) {
        let listItem = this.props.listItems[index];
        if (listItem.filled) {
          parent.push(
            <ActionButton
              handler={() => this.buttonSelected(listItem.value)}
              text={listItem.label}
              filled={true}
            />
          );
        } else {
          parent.push(
            <ActionButton
              handler={() => this.buttonSelected(listItem.value)}
              text={listItem.label}
            />
          );
        }
      }
      return parent;
    }
  
    render() {
      return (
        <Collapsible trigger="Select an option">
          <View style ={styles.button}>
              {this.buildView()}
          </View>
        </Collapsible>
      );
    }
  }

  const styles = StyleSheet.create({


    buttonWrapper: {
      display: "flex",
      alignItems: "center"
    },

    button: {
      textAlign: "center",
      color: "white",
    },
    gradient: {
      borderRadius: 10,
      width: 230,
      textAlign: "center",
      height: 40,
      paddingTop: 8,
    },
    buttonText: {
        fontSize: 16,
        fontWeight: "700",
      color: "white",
      textAlign: "center",
    },
  });
  
  export default DropDown;