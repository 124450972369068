
import React, { Component } from 'react';
import Checkbox from 'expo-checkbox';
import { StyleSheet, Text, Platform, View, Image, TouchableWithoutFeedback } from 'react-native';

import * as Icons  from '../assets';

export default class CheckBoxWrapper extends Component {

    state = { checked: false }

    componentDidMount() {
      if (this.props.checked) {
        this.setState({ checked: true })
      } else {
        this.setState({checked: false})
      }
    }

    handleCheckboxChange = event => {
      if (this.state.checked) {
        this.props.handler(false);
        this.setState({ checked: false })
      } else {
        this.props.handler(true);
        this.setState({ checked: true })
      }
    }

    createIcons = ( ) => {
      let parent = [];
      if (!!this.props.icon) {
        parent.push(
          <Image
            source={Icons[`${this.props.icon}`]}
            style={[styles.icon, {
              tintColor: this.state.checked ? "#5d51ef":"gray",
            }]}
          />
        )
      } 
      return parent;
    }

    render() {
      if (Platform.OS == "web") {
        return (
          <View style={styles.webCheckBoxWrapper}>
            <Checkbox 
              value={this.state.checked}
              color="#5d51ef"
              onValueChange={this.handleCheckboxChange}/>
            
            <TouchableWithoutFeedback
              onPress={()=>{
                this.handleCheckboxChange()
              }}
            >
              <View style={styles.flexBox}>
                <Text 
                  style={{
                    fontSize: 14,
                    marginLeft: 20,
                    color:this.state.checked ? "#5d51ef":"gray",
                    fontWeight:this.state.checked ? "bold" :"normal",
                  }}
                  allowFontScaling={false}
                  numberOfLines={1}
                >
                {this.props.label}
              </Text>
              {this.createIcons()}
              </View>
            </TouchableWithoutFeedback>
          </View>    
        ) 
      } else {
        return (
          <View style={styles.checkBoxWrapper}>
            <Checkbox 
              value={this.state.checked}
              color="#5d51ef"
              onValueChange={this.handleCheckboxChange}/>
            <TouchableWithoutFeedback
              onPress={()=>{
                this.handleCheckboxChange()
              }}
            >
              <View style={styles.flexBox}>
                <Text 
                  style={{
                    fontSize: 14,
                    marginLeft: 20,
                    color:this.state.checked ? "#5d51ef":"gray",
                    fontWeight:this.state.checked ? "bold" :"normal",
                  }}
                  allowFontScaling={false}
                  numberOfLines={1}
                >
                  {this.props.label}
                </Text>
                {this.createIcons()}
              </View>
            </TouchableWithoutFeedback>  
          </View>    
        ) 
      }
    }
  }


const styles = StyleSheet.create({
  checkBoxWrapper: {
      flexDirection:"row",
      alignItems: "center",
      justifyContent: "center",
      height: 40,
      flex: 1,
      minWidth: 200,
  },
  webCheckBoxWrapper: {
    flexDirection:"row",
    alignItems: "center",
    justifyContent: "center",
    height: 35,
    flex: 1,
    minWidth: 200,
    margin: 3,
  },
  flexBox: {
    flexDirection:"row",
    alignItems: "center",
    justifyContent: "center",
  },
  checkBoxText: {
    fontSize: 10,
    marginLeft: 10,
  },
  icon: {
    marginLeft: 10,
    width: 30,
    height: 30,
    elevation: 0, 
    shadowOffset: { height: 0, width: 0 }, 
    shadowOpacity: 0, 
    shadowRadius: 0
  }
});