import React, { useState, useCallback, useEffect } from 'react'
import { Text } from "react-native";
import { getDatabase, ref, get, set, update } from "firebase/database";
import { getFirestore, collection, query, setDoc, doc, onSnapshot } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import uuid from 'react-native-uuid';
import { GiftedChat } from 'react-native-gifted-chat'

import {filterOutput} from "../helpers/output-formatter";

export default function MessageView(props) {
  const [messages, setMessages] = useState([]);
  const [blockList, setBlockList] = useState(null);
  const [userIsBlocked, setUserBlocked] = useState(null);
  const [convoID, setConvoID] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [myUserProfile, setMyProfile] = useState(null);
  const [errorModalVisible, setModalVisible] = useState(null);
  const [unsubscribeRef, setUnsubscribe] = useState(null);

  useEffect(() => {
    let unsubscribe;
    const configureView = async () => {
      if (myUserProfile == null || userProfile == null) {
        let myBlockList = await getBlockListForID(props.route.params.user.uid);
        setBlockList(myBlockList);
        let userBlockList = await getBlockListForID(props.route.params.recipientUID);
        let userIsBlocked = false;
        if (!!myBlockList) {
          if (myBlockList[props.route.params.recipientUID] != null) {
            userIsBlocked = true;
          } 
        } 
        if (!!userBlockList) {
          if (userBlockList[props.route.params.user.uid] != null) {
            userIsBlocked = true;
          } 
        } 
        setUserBlocked(userIsBlocked);
        if (!userIsBlocked) {
          if (myUserProfile == null) {
            let myProfile = await getMyProfile();
            setMyProfile(myProfile);
          }
          if (userProfile == null) {
            let profile = await getProfileData();
            setUserProfile(profile);
          }
          const db = getDatabase();
          const myMessagesRef = ref(db, `myMessages/${props.route.params.user.uid}/${props.route.params.recipientUID}`);
          let snapshot = await get(myMessagesRef);
          let myMessagesData = snapshot.val();
          if (!!myMessagesData) {
            setConvoToViewed();
            setConvoID(myMessagesData.id);
            const fsdb = getFirestore();
            const q = query(collection(fsdb, "messages", myMessagesData.id, "conversation"));
            unsubscribe = onSnapshot(q, (querySnapshot) => {
              let messages = [];
              querySnapshot.forEach((doc) => {
                let data = doc.data();
                data.user = data.sender;
                if (!!userProfile) {
                  if (!!userProfile.photoUrl) {
                    data.user["avatar"] = userProfile.photoUrl;
                  } else {
                    data.user["avatar"] = "https://doc2doc-d54a2.web.app/media/userAvatar.png";
                  }
                } else {
                  data.user["avatar"] = "https://doc2doc-d54a2.web.app/media/userAvatar.png";
                }
                data.text = filterOutput(data.text);
                messages.unshift(data);
              });
              setMessages(messages)
            });
          }
        }
      }
    }
    configureView();
    return () => {
      if (typeof unsubscribe === 'function') {
        unsubscribe();
      }
    }
  }, [userProfile, myUserProfile]);

  const setConvoToViewed = () => {
    if (props.navigation.isFocused()) {
      const db = getDatabase();
      const myMessagesRef = ref(db, `myMessages/${props.route.params.user.uid}/${props.route.params.recipientUID}`);
      update(myMessagesRef, {read: true});
    }
  }

  const getBlockListForID = async (uid) => {
    const db = getDatabase();
    const reservationsRef = ref(db, `blockList/${uid}`);
    let snapshot = await get(reservationsRef);
    let blockList = snapshot.val();
    return blockList;
  }

  const getProfileData = async () => {
    const db = getDatabase();
    const profileRef = ref(db, `profiles/${props.route.params.recipientUID}`);
    let snapshot = await get(profileRef);
    let profileData = snapshot.val();
    return profileData;
  }

  const getMyProfile = async () => {
    const db = getDatabase();
    const profileRef = ref(db, `profiles/${props.route.params.user.uid}`);
    let snapshot = await get(profileRef);
    let profileData = snapshot.val();
    return profileData;
  }

  const onSend = useCallback(async (newMessage = []) => {
    if (!userIsBlocked) {
      let oldMessages = [...messages];
      if (oldMessages.length == 0) {
        sendNewConvoEmail();
      }
      const {_id, createdAt, text, user} = newMessage[0];
      let createDate = new Date(createdAt);
      const fsdb = getFirestore();
      let messageID = `${createDate.getTime()}-${_id}`;
      let data = {
        _id: messageID,
        createdAt: createDate.getTime(),
        text: text,
        sender: user,
        recipient: {_id: props.route.params.recipientUID},
      };
      if (!!convoID) {
        await setDoc(doc(fsdb, "messages", convoID,"conversation", messageID), data);
        await updateMyMessages(text, user, createDate.getTime(), convoID);
      } else {
        let newConvoID = uuid.v4();
        setConvoID(newConvoID);
        await setDoc(doc(fsdb, "messages", newConvoID, "conversation", messageID), data);
        await updateMyMessages(text, user, createDate.getTime(), newConvoID);
      }
      requestPush(text);
    } else {
      setModalVisible(true);
    }
  }, [convoID, userIsBlocked, messages])

  const updateMyMessages = async (text, userObj, createdAt, convoID) => {
    const db = getDatabase();
    const myMessagesRef = ref(db, `myMessages/${userObj._id}/${props.route.params.recipientUID}`);
    const userMyMessagesRef = ref(db, `myMessages/${props.route.params.recipientUID}/${userObj._id}`);
    let data = {
      lastMessage: text,
      sender: userObj,
      recipient: {_id: props.route.params.recipientUID},
      createdAt: createdAt,
      id: convoID,
      read: false,
    };
    await set(userMyMessagesRef, data);
    data["read"] = true;
    await set(myMessagesRef, data);
  }

  const requestPush = async message => {
    let senderName = `${myUserProfile?.firstName}`; 
    if (myUserProfile == null) {
      senderName = "null";
    }
    let recipientUID = props.route.params.recipientUID;
    let url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/sendMessagePush?`;
    url += `senderName=${senderName}&recipientUID=${recipientUID}&message=${message}`
    try {
      const response = await fetch(url);
      const pushResponse = await response.json();
      if (pushResponse.response != "success") {
        console.log("push notification not sent");
      } 
    } catch (error) {
        console.log(error)
    }
  }

  const sendNewConvoEmail = async () => {
    const functions = getFunctions();
    const requestNewConvoEmail = httpsCallable(functions, 'requestNewConvoEmail');
    let requestData = {
      recipientUID: props.route.params.recipientUID
    }
    let result = await requestNewConvoEmail(requestData);
    const data = result.data;
    if (data.response == "success") {
        return true;
    }
    return false;
  }


  const navigateToProfile = () => {
    if (!!props.route.params.fromProfile) {
      props.navigation.goBack();
    } else {
      props.navigation.navigate("Public Profile", {
        profileUID: props.route.params.recipientUID,
      })
    }
  }

  const onModalClose = () => {
    setModalVisible(false);
  }

  const buildView = () => {
    let parent = [];
    if (!userIsBlocked) {
      parent.push(
        <GiftedChat
          messages={messages}
          onSend={messages => onSend(messages)}
          showAvatarForEveryMessage={true}
          onPressAvatar={()=>{
            navigateToProfile();
          }}
          user={{
              _id: props.route.params.user.uid,
              email: props.route.params.user.email,
          }}
        />
      );
    } else {
      parent.push(
        <Text style={{
          marginTop: 10,
          fontWeight: "600",
          width: "100%",
          textAlign: "center"
        }}>
          User is blocked
        </Text>
      );
    }
    return parent;
  }

  return (
    buildView()
  )
}