import React, { Component } from 'react';
import { StyleSheet, Animated, Text, Platform, View } from 'react-native';

export default class TopAlert extends Component {

    constructor(props) {
        super(props);
        this.state = {
            successWrapperTop: new Animated.Value(-365),
            scrollOffset: new Animated.Value(0),
        }
    }

    componentDidMount() {
        if (Platform.OS == "web") {
          window.onscroll = () => { 
              Animated.spring(this.state.scrollOffset, {
                toValue: window.pageYOffset,
                useNativeDriver: true
              },).start();
          } 
        }
    }

    showSuccess = () => {
        Animated.spring(this.state.successWrapperTop, {
            toValue: 0 + this.state.scrollOffset._value,
            useNativeDriver: true,
            duration: 200
        },).start();
        setTimeout(() => {
            Animated.spring(this.state.successWrapperTop, {
                toValue: -365,
                useNativeDriver: true,
                duration: 200
            },).start();
        }, 1400);
    }

    checkShowSuccess = () => {
        setTimeout(() => {
            if (this.props.isShowing) {
                this.showSuccess();
            }
        }, 10)
    }

    render() {
        let text = "Success"
        if (!!this.props.text) {
            text = this.props.text;
        }
        return (   
            <Animated.View 
                style={[{
                    height: 65,
                    width: "100%",
                    zIndex: 30,
                    backgroundColor: "green",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                    position: "absolute",
                    transform: [
                        { translateY: this.state.successWrapperTop},
                    ],
                }]}
            >
                <Text style={styles.whiteButtonText}>{text}</Text>
                { this.checkShowSuccess() }  
            </Animated.View> 
        )
    }
}

const styles = StyleSheet.create({
    whiteButtonText: {
        fontSize: 18,
        fontWeight: '600',
        color: 'white',
    }
});
