import React, { Component } from 'react';
import TimePicker from 'react-time-picker'

// import 'react-time-picker/dist/TimePicker.css';
import './TimePicker.css';

export default class DateTimePicker extends Component {

    state = { date: null, pickerKey: Math.random().toString(36).substring(7) }

    componentDidMount() {
        if (!!this.props.time) {
            var date = new Date(`01/01/1970 ${this.props.time}`);
            this.setState({
                date: date,
            })
        }
    }

    render() {
        return(
            <TimePicker 
                onChange={data => {
                    if (!!this.props.onUpdate) {
                        this.props.onUpdate(data);
                    }
                }} 
                value={this.state.date} 
                disableClock={true}
                key={this.state.pickerKey}
            />
        ); 
    }
}

// const styles = StyleSheet.create({
      

// });

