import React, { Component } from 'react';
import { Image, View } from 'react-native';

export default class Logo extends Component {
    render() {
        return (
            <View
                style={{ 
                    width: 200,
                    margin:10,
                    padding: 10,
                    marginBottom: 0,
                    paddingBottom: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 10,
                }}
            >
                <Image
                    style={{ 
                        width: 150,
                        height: 150,
                        alignItems: 'center',
                        zIndex: 10,
                        resizeMode: "center",
                    }}
                    source={require('../assets/doc2docRentalsLogo-small.png')}
                />
            </View>
        )}
}