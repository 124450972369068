import { useEffect } from "react";
import {
	PayPalScriptProvider,
	PayPalButtons,
	usePayPalScriptReducer
} from "@paypal/react-paypal-js";

const ButtonWrapper = ({ type, level, success }) => {
	const [{ options }, dispatch] = usePayPalScriptReducer();

	useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [type]);

    const buildButton = () => {
        let planID = "P-9XK91722VU325010SL7NHEAA";
        if (level == "gold") {
            planID = "P-6AX63287U9757091XMKAXNJQ";
        } 
        return [
            <PayPalButtons
                createSubscription={(data, actions) => {
                    return actions.subscription
                        .create({ plan_id: planID })
                        .then((orderId) => {
                            return orderId;
                        });
                }}
                style={{
                    label: "subscribe",
                }}
                onApprove={(data, actions) => {
                    success(data);
                }}
            />
        ];
    }

	return (buildButton());
}

export default function PayPalButtonWeb(props) {
	return (
		<PayPalScriptProvider
			options={{
				"client-id": "Ad1cALs8Uw8sFNtdLxMOWaitdWFBUKKS-XDzCRwYHxLoUZKwZwr2nqTvLCAMsjYAG_0ZEbqIuodLaZqD",
				components: "buttons",
				intent: "subscription",
				vault: true,
			}}
		>
			<ButtonWrapper type="subscription" {...props}/>
		</PayPalScriptProvider>
	);
}