import React, { Component } from 'react';
import { StyleSheet, View, Text, Image, Dimensions, Linking, ActivityIndicator, Platform } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import Constants from 'expo-constants';
import { getDatabase, ref, get, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

import Modal from "./Modal";
import Container from "../compenents/Container";
import DropDown from "./DropDown";
import Loader from "../compenents/Loader";

export default class MyRentalsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            rentalsData: null,
            publicRentalsData: null,
            expiredRentalData: null,
            draftRentalData: null,
            analyticsData: null,
            tableKey: Math.random().toString(36).substring(7),
            shareModalVisible: false,
            shareModalKey: Math.random().toString(36).substring(7),
            successModalVisible : false,
            successModalKey: Math.random().toString(36).substring(7),
            errorModalVisible: false,
            errorModalKey: Math.random().toString(36).substring(7),
            errorMessage: "",
            publicRentalsloadingComplete: false,
            expiredRentalsloadingComplete: false,
            draftRentalloadingComplete: false,
            width: Dimensions.get('window').width,
            loading: false,
            addListener: null,
            editListener: null,
            deleteListener: null,
        };
    } 

    componentDidMount() {
        //this refreshes the map every time the home view comes into focus
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.setState({
                rentalsData: null,
                publicRentalsData: null,
                expiredRentalData: null,
            });
            this.getMyRentalsData();
            this.getMyExpiredRentals();
            this.getMyDrafts();
            this.props.navigation.getParent().setOptions({headerShown: true});
        });
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user }, () => {
                    this.getMyRentalsData();
                    this.getMyExpiredRentals();
                    this.getMyDrafts();
                });
            } else {
                this.setState({ user: null });
            }
        });
        if (Platform.OS == "web") {
            window.addEventListener('resize', this.updateDimensions);
        }
        this.updateDimensions();
    }

    componentWillUnmount() {
        if (this.state.addListener) {
            this.state.addListener()
        }
        if (this.state.updateListener) {
            this.state.updateListener()
        }
        if (this.state.deleteListener) {
            this.state.deleteListener()
        }
    }

    updateDimensions = () => {
        this.setState({ width:  Dimensions.get('window').width});
    };

    getMyRentalsData = () => {
        let rentalsData = {};
        let publicRentalsData = {};
        let analyticsData = {};
        const db = getDatabase();
        const rentalsRef = ref(db, `myRentals/${this.props.user.uid}`);
        this.setState({loading: true});
        get(rentalsRef).then(async (snapshot) => {
            const myRentalsData = snapshot.val();
            if (myRentalsData !== null) {
                for (var key in myRentalsData) {
                    //iterate through my rentals data and grab the following for each listing
                        //  - rentalData
                        //  - publicRentalData
                        //  - analyticsData
                        //  - verify subscription if bronze or silver
                    let publicRentalData = await this.getPublicRentalData(key);
                    if (!!publicRentalData) {
                        let rentalData = await this.getRentalData(key);
                        let analyticData = await this.getAnalyticsData(key);
                        rentalsData[key] = rentalData;
                        publicRentalsData[key] = publicRentalData;
                        analyticsData[key] = analyticData;
                        if (!!publicRentalData.subscriptionLevel) {
                            if (publicRentalData.subscriptionLevel == "bronze") {
                                let hasReservations = await this.checkReservations(key);
                                rentalsData[key]["hasReservations"] = hasReservations;
                            }
                        } else {
                            let hasReservations = await this.checkReservations(key);
                            rentalsData[key]["hasReservations"] = hasReservations;
                        }
                    } else {
                        let rentalData = await this.getRentalData(key);
                        rentalsData[key] = rentalData;
                    }
                }
                this.setState({
                    rentalsData: rentalsData,
                    publicRentalsData: publicRentalsData,
                    analyticsData: analyticsData,
                    tableKey: Math.random().toString(36).substring(7),
                    loading: false,
                });
            } else {
                this.setState({ 
                    rentalsData: null,
                    loading: false,
                });
            }
	    }).catch(error => {
            console.log(error);
        });
    }

    getRentalData = async (postID) => {
        const db = getDatabase();
        const rentalsRef = ref(db, `rentals/${postID}`);
        let snapshot = await get(rentalsRef)
        let rentalData = snapshot.val();
        return rentalData;
    }

    getPublicRentalData = async (postID) => {
        const db = getDatabase();
        const rentalsRef = ref(db, `publicRentals/${postID}`);
        let snapshot = await get(rentalsRef)
        let rentalData = snapshot.val();
        return rentalData;
    }

    verifySubscription = async (postID) => {
        const functions = getFunctions();
        const verifyPaypalSubscription = httpsCallable(functions, 'verifyPaypalSubscription');
        let result = await verifyPaypalSubscription({postID: postID})
        const data = result.data;
        if (data.response == "success") {
            if (data.valid) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getMyExpiredRentals = () => {
        const db = getDatabase();
        const myExpiredRentalsRef = ref(db, `myExpiredRentals/${this.state.user.uid}`);
        get(myExpiredRentalsRef).then((snapshot) => {
            const data = snapshot.val();
            if (data !== null) {
                this.setState({ 
                    expiredRentalsloadingComplete: true,
                    expiredRentalData: data,
                    tableKey: Math.random().toString(36).substring(7),
                });
            } else {
                this.setState({ 
                    expiredRentalData: null,
                    expiredRentalsloadingComplete: true,
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    getMyDrafts = () => {
        const db = getDatabase();
        const draftsRef = ref(db, `drafts/${this.state.user.uid}`);
        let draftRentalData = {};
        const addListener = onChildAdded(draftsRef, (data) => {
            let draftData = data.val();
            draftRentalData[draftData.key] = draftData;
        });
        const updateListener = onChildChanged(draftsRef, (data) => {
            let draftData = data.val();
            draftRentalData[draftData.key] = draftData;
        });
        const deleteListener = onChildRemoved(draftsRef, (data) => {
            let draftData = data.val();
            delete draftRentalData[draftData.key];
            this.getMyRentalsData();
        });
        this.setState({ 
            addListener: addListener,
            updateListener: updateListener,
            deleteListener: deleteListener,
            draftRentalloadingComplete: true,
            draftRentalData: draftRentalData,
            tableKey: Math.random().toString(36).substring(7),
        });
    }

    checkReservations = async (key) => {
        const db = getDatabase();
        const reservationsRef = ref(db, `reservations/${this.state.user.uid}/${key}`);
        let snapshot = await get(reservationsRef);
        const data = snapshot.val();
        if (data !== null) {
            return true;
        } else {
            return false;
        }
   
    }

    onSharePress = (key) => {
        this.setState({
            shareModalVisible: true,
            shareModalKey: Math.random().toString(36).substring(7),
            selectedPropertyUrl: "https://doc2doc-d54a2.web.app/?postID="+key,
        });
    }

    getThisURI = async () => {
        const redirect = await Linking.getInitialURL('/');
        this.setState({redirectURI: redirect});
    }

    doMobileCheckout = (purchaseDataObj) => {
        let data = {
            postID: purchaseDataObj.key,
            posterUID: this.state.user.uid,
            email: this.state.user.email,
        }

        //open link to checkout page
        const _this = this;
        const paymentURL = 'https://doc2doc-d54a2.web.app/mobile-payments/payments-v3.html?'+ encodeURIComponent(JSON.stringify(data));
        let browserParams = {};
        browserParams["dismissButtonStyle"] = "done";
        try {
            WebBrowser.openAuthSessionAsync(paymentURL, Constants.linkingUrl, browserParams).then(closeData => {
                //check url for deep link to completion
                let url = closeData.url;
                let orderID = this.getParameterByName("orderID", url);
                let subscriptionID = this.getParameterByName("subscriptionID", url);
                let posterUID = this.getParameterByName("posterUID", url);
                let postID = this.getParameterByName("postID", url);
    
                if (!!orderID && !!subscriptionID && !!postID && !!posterUID) {
                    let fetchUrl = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/updatePayment?";
                    fetchUrl += "&postID="+postID;
                    fetchUrl += "&posterUID="+posterUID;
                    fetchUrl += "&orderID="+orderID;
                    fetchUrl += "&subscriptionID="+subscriptionID;
                    
                    fetch(fetchUrl)
                    .then(response => response.json())
                    .then(data => {
                        if (!!data.response) {
                            if (data.response == "success") {
                                //navigate to success page and send params
                                _this.props.navigation.navigate("Success", {
                                    rentalData: purchaseDataObj,
                                });
                            } else {
                                _this.setState({
                                    errorModalVisible: true,
                                    errorModalKey: Math.random().toString(36).substring(7),
                                    errorMessage: "There was an issue posting your listing. An admin should reach out to you shortly",
                                    loading: false,
                                    loadingModalKey: Math.random().toString(36).substring(7),
                                });
                            }
                        }
                    });
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    getParameterByName = (name, url) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    getAnalyticsData = async (postUID) => {
        const db = getDatabase();
        const postAnalyticsRef = ref(db, `postAnalytics/${postUID}`);
        let snapshot = await get(postAnalyticsRef)
        const analyticsData = snapshot.val();
        return analyticsData;
    }

    checkAnalytics = (publicRentalData) => {
        let parent = [];
        let analyticsData = this.state.analyticsData[publicRentalData.key];
        if (!!analyticsData) {
            parent.push(
                <Text allowFontScaling={false} style={styles.analyticsText}>
                    views: {analyticsData.viewCount} / favorited: {analyticsData.likes}
                </Text>
            );
        }
        return parent;
    }

    buttonSelected = (selectedButton) => {
        let idArray = selectedButton.split(":::");
        let postID = idArray[1];
        let action =  idArray[0];
        let publicRentalData = null;
        if (this.state.publicRentalsData != null) {
            publicRentalData = this.state.publicRentalsData[postID];
        }
        let privateRentalData = null;
        if (this.state.publicRentalsData != null) {
            privateRentalData = this.state.rentalsData[postID];
        }
        let expiredRentalData = null;
        if (this.state.expiredRentalData != null) {
            expiredRentalData = this.state.expiredRentalData[postID];
        }
        let draftData = null;
        if (this.state.draftRentalData != null) {
            draftData = this.state.draftRentalData[postID];
        }
        switch(action) {
            case "Edit":
                this.props.navigation.getParent().setOptions({headerShown: false});
                publicRentalData.clickTime = new Date().getTime();
                this.props.navigation.navigate('Post Property', {
                    publicRentalData: publicRentalData,
                    privateRentalData: privateRentalData,
                })
            break;
            case "Tax":
                this.props.navigation.getParent().setOptions({headerShown: false});
                this.props.navigation.navigate('Tax Collection', {
                    rentalData: publicRentalData,
                })
            break;
            case "Availability":
                this.props.navigation.getParent().setOptions({headerShown: false});
                this.props.navigation.navigate('Calendar View', {
                    rentalData: publicRentalData,
                });
                break;
            case "Booking Options":
                this.props.navigation.getParent().setOptions({headerShown: false});
                this.props.navigation.navigate("Special Options", {
                    rentalData: publicRentalData
                });
                break;
            case "Pricing Breakdown":
                this.props.navigation.getParent().setOptions({headerShown: false});
                this.props.navigation.navigate("Pricing", {
                    rentalData: publicRentalData
                });
                break;
            case "Check-in Instructions":
                this.props.navigation.getParent().setOptions({headerShown: false});
                this.props.navigation.navigate("CheckIn Instructions", {
                    rentalData: publicRentalData
                });
                break;    
            case "Reservations":
                this.props.navigation.getParent().setOptions({headerShown: false});
                this.props.navigation.navigate("Reservations", {
                    rentalData: publicRentalData
                });
                break;    
            case "GuestView":
                this.props.navigation.getParent().setOptions({headerShown: false});
                this.props.navigation.navigate("Public Property", {
                    rentalData: publicRentalData
                });
                break;    
            case "Subscription":
                this.props.navigation.getParent().setOptions({headerShown: false});
                this.props.navigation.navigate("Subscription", {
                    rentalData: publicRentalData
                });
                break;  
            case "Share":
                this.onSharePress(postID)
                break;     
            case "Delete":
                if (publicRentalData != null) {
                    if (!!this.props.onDeletePress) {
                        this.props.onDeletePress(publicRentalData)
                    }
                } else if (draftData != null) {
                    if (this.props.onDeleteDraft) {
                        this.props.onDeleteDraft(draftData)
                    }
                } else if (expiredRentalData != null) {
                    if (!!this.props.onDeletePress) {
                        this.props.onDeletePress(expiredRentalData)
                    }
                }
                break;
            case "Complete":
                this.props.navigation.getParent().setOptions({headerShown: false});
                if (publicRentalData != null) {
                    this.props.navigation.navigate('Post Property', {
                        publicRentalData: publicRentalData,
                        privateRentalData: privateRentalData,
                    })
                } else if (draftData != null) {
                    this.props.navigation.navigate('Post Property', {
                        draftData: draftData,
                    })
                } else if (expiredRentalData != null) {
                    this.props.navigation.navigate('Post Property', {
                        draftData: expiredRentalData,
                    })
                }
                break;       
            default:
              // code block
          }
    }

    buildButtons = (privateRentalData, publicRentalData) => {
        let listItems = [];
        let parent = [];
        listItems.push(
            {label: "Edit", value: `Edit:::${publicRentalData.key}`},
            {label: "Availability", value: `Availability:::${publicRentalData.key}`}
        )
        if (privateRentalData.subscriptionLevel != undefined && privateRentalData.subscriptionLevel != null) {
            if (privateRentalData.subscriptionLevel != "bronze") {
                listItems.push(    
                    {label: "Booking Options", value: `Booking Options:::${publicRentalData.key}`},
                    {label: "Pricing", value: `Pricing Breakdown:::${publicRentalData.key}`},
                    {label: "Tax Collection", value: `Tax:::${publicRentalData.key}`},
                    {label: "Check-in Instructions", value: `Check-in Instructions:::${publicRentalData.key}`},
                    {label: "Reservations", value: `Reservations:::${publicRentalData.key}`},
                );
            } else {
                listItems.push(    
                    {label: "Public Listing", value: `Booking Options:::${publicRentalData.key}`},
                );
                if (!!privateRentalData.hasReservations && privateRentalData.hasReservations) {
                    listItems.push(    
                        {label: "Reservations", value: `Reservations:::${publicRentalData.key}`},
                    );
                }
            }
        } else {
            listItems.push(    
                {label: "Public Listing", value: `Booking Options:::${publicRentalData.key}`},
            );
            if (!!privateRentalData.hasReservations && privateRentalData.hasReservations) {
                listItems.push(    
                    {label: "Reservations", value: `Reservations:::${publicRentalData.key}`},
                );
            }
        }
        listItems.push(
            {label: "Subscription", value: `Subscription:::${publicRentalData.key}`},
            {label: "Guest View", value: `GuestView:::${publicRentalData.key}`},
        );

        listItems.push(
            {label: "Share", value: `Share:::${publicRentalData.key}`},
        )
        listItems.push({   
            label: "Delete",
            value: `Delete:::${publicRentalData.key}`,
            filled: true,
        });
        parent.push(            
            <DropDown 
                listItems={listItems}
                callback={item => this.buttonSelected(item)}
            />
        )
        return parent;
    }

    buildDraftButtons = (data) => {
        let listItems = [];
        let parent = [];
        listItems.push(
            {label: "Complete", value: `Complete:::${data.key}`},
            {label: "Delete", value: `Delete:::${data.key}`, filled: true}
        )
        parent.push(            
            <DropDown 
                listItems={listItems}
                callback={item => this.buttonSelected(item)}
            />
        );
        return parent;
    }

    checkRenewal = (privateRentalData) => {
        let parent = [];
        const expirationDate = new Date(privateRentalData.expiration);
        if (!!privateRentalData.subscriptionLevel) {
            if (privateRentalData.subscriptionLevel != "silver") {
                parent.push(
                    <Text allowFontScaling={false} style={styles.renewalLabel} >Renewal Date: {expirationDate.toDateString()}</Text>
                )
            } 
        } else {
            parent.push(
                <Text allowFontScaling={false} style={styles.renewalLabel} >Renewal Date: {expirationDate.toDateString()}</Text>
            )
        }
        return parent;
    }

    createTable = () => {
        let parent = [];
        let maxWidth = 380;
        if (this.state.width < 1140 ) {
            maxWidth = 800;
        }
        if (this.state.expiredRentalsloadingComplete && this.state.draftRentalloadingComplete) {
            for (var key in this.state.rentalsData) {
                const privateRentalData = this.state.rentalsData[key];
                if (this.state.publicRentalsData) {
                    const publicRentalData = this.state.publicRentalsData[key];
                    if (publicRentalData) {
                        parent.push(
                            <Container maxWidth={maxWidth}>
                                <View style={styles.rentalWrapper}>
                                    <View key={key} style={styles.rentalWrapper}>
                                        <Text allowFontScaling={false} style={styles.headerLabeltext}>{publicRentalData.title}</Text>
                                        { this.checkRenewal(privateRentalData) }
                                    </View>
                                    {this.checkAnalytics(publicRentalData)}
                                    <View style={styles.previewImageWrapper}>
                                        <View style={styles.activityIndicatorWrapper}>
                                            <ActivityIndicator size="large" style={styles.activityIndicator}/>
                                        </View>
                                        <Image
                                            key={privateRentalData.key}
                                            style={styles.previewImage}
                                            source={{
                                                uri: publicRentalData.photoUrls[0],
                                            }}
                                            resizeMode={"contain"}
                                        />
                                    </View>       
                                    <View style={styles.buttonWrapper}>
                                        { this.buildButtons(privateRentalData, publicRentalData) }   
                                    </View>
                                </View>
                            </Container>
                        );
                    }
                }
                if (!!this.state.expiredRentalData) {
                    const expiredRentalDataObj = this.state.expiredRentalData[privateRentalData.key];
                    let draftData = null;
                    if (!! this.state.draftRentalData) {
                        draftData = this.state.draftRentalData[privateRentalData.key];
                    }
                    if (expiredRentalDataObj && (draftData == null || draftData == undefined)) {
                        if (expiredRentalDataObj.photoUrls[0]) {
                            parent.push(
                                <Container maxWidth={maxWidth}>
                                    <View key={key} style={styles.rentalWrapper}>
                                        <Text allowFontScaling={false} style={styles.headerLabeltext}>{expiredRentalDataObj.title}</Text>
                                        <Text allowFontScaling={false} style={styles.renewalLabel} >Renewal Date: Expired</Text>
                                        <View style={styles.previewImageWrapper}>
                                            <View style={styles.activityIndicatorWrapper}>
                                                <ActivityIndicator size="large" style={styles.activityIndicator}/>
                                            </View>
                                            <Image
                                                key={privateRentalData.key}
                                                style={styles.previewImage}
                                                source={{
                                                    uri: expiredRentalDataObj.photoUrls[0],
                                                }}
                                                resizeMode={"contain"}
                                            />
                                        </View>
                                        <View style={styles.buttonWrapper}>
                                            { this.buildDraftButtons(expiredRentalDataObj) }
                                        </View>
                                    </View>
                                </Container>
                            );
                        }
                    }
                }
            }
            for (var draftKey in this.state.draftRentalData) {
                let draftData = this.state.draftRentalData[draftKey];
                if (draftData.pending) {
                    if (draftData.photoUrls) {
                        if (draftData.photoUrls[0]) {
                            parent.push(
                                <Container maxWidth={maxWidth}>
                                    <View key={key} style={styles.rentalWrapper}>
                                        <Text llowFontScaling={false} style={{color: "blue", fontWeight: "600"}}>Pending</Text>
                                        <Text allowFontScaling={false} style={styles.headerLabeltext}>{draftData.title}</Text>
                                        <Text allowFontScaling={false} style={styles.renewalLabel} >Draft</Text>
                                        <View style={styles.previewImageWrapper}>
                                            <View style={styles.activityIndicatorWrapper}>
                                                <ActivityIndicator size="large" style={styles.activityIndicator}/>
                                            </View>
                                            <Image
                                                key={draftData.key}
                                                style={styles.previewImage}
                                                source={{
                                                    uri: draftData.photoUrls[0],
                                                }}
                                                resizeMode={"contain"}
                                            />
                                        </View>
                                    </View>
                                </Container>
                            );
                        }
                    } else {
                        parent.push(
                            <Container maxWidth={maxWidth}>
                                <View key={key} style={styles.rentalWrapper}>
                                <Text llowFontScaling={false} style={{color: "blue", fontWeight: "600"}}>Pending</Text>
                                    <Text allowFontScaling={false} style={styles.headerLabeltext}>{draftData.title}</Text>
                                    <Text allowFontScaling={false} style={styles.renewalLabel} >Draft</Text>
                                </View>
                            </Container>
                        );
                    }
                } else {
                    if (draftData.photoUrls) {
                        if (draftData.photoUrls[0]) {
                            parent.push(
                                <Container maxWidth={maxWidth}>
                                    <View key={key} style={styles.rentalWrapper}>
                                        <Text allowFontScaling={false} style={styles.headerLabeltext}>{draftData.title}</Text>
                                        <Text allowFontScaling={false} style={styles.renewalLabel} >Draft</Text>
                                        <View style={styles.previewImageWrapper}>
                                            <View style={styles.activityIndicatorWrapper}>
                                                <ActivityIndicator size="large" style={styles.activityIndicator}/>
                                            </View>
                                            <Image
                                                key={draftData.key}
                                                style={styles.previewImage}
                                                source={{
                                                    uri: draftData.photoUrls[0],
                                                }}
                                                resizeMode={"contain"}
                                            />
                                        </View>
                                        <View style={styles.buttonWrapper}>
                                            { this.buildDraftButtons(draftData) }
                                        </View>
                                    </View>
                                </Container>
                            );
                        }
                    } else {
                        parent.push(
                            <Container maxWidth={maxWidth}>
                                <View key={key} style={styles.rentalWrapper}>
                                    <Text allowFontScaling={false} style={styles.headerLabeltext}>{draftData.title}</Text>
                                    <Text allowFontScaling={false} style={styles.renewalLabel} >Draft</Text>
                                    { this.buildDraftButtons(draftData) }
                                </View>
                            </Container>
                        );
                    }
                }
            }
        }
        let final = [
            <View style={styles.flexContainer}>
                {parent}
            </View>
        ];
        return final;
    }

    onCopyPress = () => {
        this.setState({
            shareModalVisible: false,
            successModalVisible : true,
            successModalKey: Math.random().toString(36).substring(7),
        });
    }

    onModalClose = () => {
        this.setState({shareModalVisible : false, successModalVisible: false});
    }

  render() {
    return (     
        <View style={styles.rentalsWrapper} key={this.state.tableKey}>
            <Modal
                animationType="slide"
                transparent={true}
                visible={this.state.shareModalVisible}
                key={this.state.shareModalKey}
                onModalClose={this.onModalClose}
                onCopyPress={this.onCopyPress}
                website={this.state.selectedPropertyUrl}
                labelText=""
            />
            <Modal
                animationType="slide"
                transparent={true}
                visible={this.state.successModalVisible}
                key={this.state.successModalKey}
                onModalClose={this.onModalClose}
                labelText="You have successfully copied the URL for your listing."
                isSuccess={true}
            />
            <Modal
                animationType="slide"
                transparent={true}
                visible={this.state.errorModalVisible}
                key={this.state.errorModalKey}
                onModalClose={this.onModalClose}
                labelText={this.state.errorMessage}
                isError={true}
            />
            <Loader visible={this.state.loading}/>
            {this.createTable()} 
        </View>
    )
  }
}

const styles = StyleSheet.create({
    rentalsWrapper: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
    },
    rentalWrapper: {
        width: "100%",
        borderRadius:5,
        padding: 10,
        paddingLeft: 0,
        paddingBottom: 0,
        paddingTop: 0,
        justifyContent: "center",
        alignItems: "center",
    },
    button: {
        marginBottom: 10,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonBlue: {
        marginBottom: 10,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#3a93f1',
        borderColor: '#2790ff',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonRed: {
        marginBottom: 10,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#f13a3a',
        borderColor: '#ff2323',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    previewImageWrapper: {
        width: "100%",
        height: "80%",
        marginBottom: 20,
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "green",
    },
    previewImage: {
        width: "100%",
        height: 300,
        margin: "auto",
        backgroundColor: "#ebecf0",
    },
    headerLabeltext: {
        marginBottom: 5,
        // marginTop: 20,
        fontWeight: "600",
        textAlign: "center",
    },
    renewalLabel: {
        marginBottom: 5,
        textAlign: "center",
        fontSize: 12,
    },
    activityIndicatorWrapper: {
        position: "absolute",
        flex: 1,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
    },
    activityIndicator: {
        zIndex: 1,
    },
    analyticsText: {
        fontSize: 10,
        fontWeight: "600",
        marginBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 6,
        textAlign: "center",
        borderWidth: 1,
        borderColor: "#999999",
        borderRadius: 12,
        color: "#3d3d3d",
    },
    buttonWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        maxWidth: 1100,
    },
    margin5: {
        margin: 5,
        marginBottom: 0,
    },
    flexContainer: {
        padding: 10,
        paddingHorizontal: 20,
        paddingTop: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        flexWrap: "wrap",
    },
});
