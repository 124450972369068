import React, { useState, useEffect } from 'react';
import { StyleSheet, LayoutAnimation, View, TouchableHighlight, Image, Modal, Platform, Dimensions } from 'react-native';
import { Calendar } from 'react-native-calendars';

import ActionButton from './ActionButton';
import SearchInput from "./SearchInput";
import Stepper from "./Stepper";
import Checkbox from "./CheckBox";
import StyledTextInput from './StyledTextInput';
import { getShortDateString } from '../helpers/date-formatter';

const FilterBar = ( props ) => {
  const [filterData, setFilterData] = useState(props.filterData);
  const [searchKey, setSearchKey] = useState(Math.random().toString(36).substring(7));
  const [listingTypeVisible, setListingTypeVisible] = useState(false);
  const [listingTypeButtonKey, setListingTypeButtonKey] = useState(Math.random().toString(36).substring(7));
  const [bedBathVisible, setBedBathVisible] = useState(false);
  const [bedBathButtonKey, setBedBathButtonKey] = useState(Math.random().toString(36).substring(7));
  const [searchDatesVisible, setSearchDatesVisible] = useState(false);
  const [searchDatesKey, setSearchDatesKey] = useState(Math.random().toString(36).substring(7));
  const [markedDates, setMarkedDates] = useState(null);
  const [checkIn, setCheckIn] = useState(null); 
  const [checkOut, setCheckOut] = useState(null); 
  const [bedroomsKey, setBedroomKey] = useState(Math.random().toString(36).substring(7));
  const [bathroomsKey, setBathroomsKey] = useState(Math.random().toString(36).substring(7));
  const [otherVisible, setOtherVisible] = useState(false);
  const [peopleKey, setPeopleKey] = useState(Math.random().toString(36).substring(7));
  const [petFriendlyKey, setPetFriendlyKey] = useState(Math.random().toString(36).substring(7));
  const [tagsKey, setTagsKey] = useState(Math.random().toString(36).substring(7));
  const [strKey, setStrKey] = useState(Math.random().toString(36).substring(7));
  const [ltrKey, setLtrKey] = useState(Math.random().toString(36).substring(7));
  const [saleKey, setSaleKey] = useState(Math.random().toString(36).substring(7));

    useEffect(() => {
        if (!!props.data) {
            setFilterData(props.data);
            setSearchKey(Math.random().toString(36).substring(7));
        }
    },[]);

  const filterDataChange = (prop, data) => {
    let newFilterData = filterData || {};
    if (!!data) {
        newFilterData[prop] = data;
    } else {
        delete newFilterData[prop];
    }
    if (Object.keys(newFilterData).length == 0) {
        newFilterData = null
    } 
    setFilterData(newFilterData);
    if (!!props.onFilterChange) {
        props.onFilterChange(newFilterData);
    }
  }

  const datesSelected = (checkIn, checkOut) => {
    let newFilterData = filterData || {};
    if (!!checkIn) {
        newFilterData["checkIn"] = checkIn;
    } else {
        delete newFilterData["checkIn"]
    }
    if (!!checkOut) {
        newFilterData["checkOut"] = checkOut;
    } else {
        delete newFilterData["checkOut"];
    }
    setCheckIn(checkIn);
    setCheckOut(checkOut);
    if (Object.keys(newFilterData).length == 0) {
        newFilterData = null;
    }
    setFilterData(newFilterData);
    if (!!props.onFilterChange) {
        props.onFilterChange(newFilterData);
    }
  }

  const buildTypeLabel = () => {
    let label = "type";
    if (!!filterData) {
        if (!!filterData.listingType) {
             if (filterData.listingType == "ltr") {
                label = "long term rental";
             } else if (filterData.listingType == "str") {
                label = "short term rental";
            } else if (filterData.listingType == "forSale") {
                label = "for sale";
            }
        }
    }
    return label;
  }

  const buildBedBathLabel = () => {
    let label = "bed • bath";
    if (!!filterData) {
        if (!!filterData.bedrooms) {
            label = label.replace("bed", `${filterData.bedrooms} bed(s)`)
        }
        if (!!filterData.bathrooms) {
            label = label.replace("bath", `${filterData.bathrooms} baths(s)`)
        }
    }
    return label;
  }

  const buildDatesLabel = () => {
    let label = "dates";
    if (!!filterData) {
        if (!!filterData.checkIn) {
            label =  getShortDateString(filterData.checkIn);
        }
        if (!!filterData.checkOut) {
            let checkOutString = getShortDateString(filterData.checkOut);
            label += ` - ${checkOutString}`;
        }
    }
    return label;
  }

  const buildMinDate = () => {
    const todayDate = new Date();
    let month = todayDate.getUTCMonth()+1;
    let day = todayDate.getUTCDate();
    month >= 10 ? month = todayDate.getMonth()+1: month = `0${todayDate.getMonth()+1}`;
    day >= 10 ? day = todayDate.getUTCDate(): day = `0${todayDate.getUTCDate()}`;
    const todayDateString = todayDate.getUTCFullYear()+"-"+month +"-"+day
    return todayDateString;
  }

  const onDayPress = (day) => {
    if (markedDates == null) {
        let markedDatesArray = {};
        markedDatesArray[day.dateString] = {
            startingDay: true, endingDay: true, color: '#3a93f1', textColor: 'white'
        };
        //filterDataChange("checkIn", day.dateString);
        setMarkedDates(markedDatesArray);
        setSearchDatesKey(Math.random().toString(36).substring(7));
        setCheckIn(day.dateString);
    } else {
        let keyCount = Object.keys(markedDates).length;
        if (keyCount == 1) {
            let newMarkedDates = {};
            let startDate = new Date(checkIn);
            let endDate = new Date(day.dateString);
            let datesAray = [];
            if (startDate.getTime() > endDate.getTime()) {
                datesAray = getDaysArray(endDate, startDate);
            } else {
                datesAray = getDaysArray(startDate, endDate);
            }
            let datesCount = datesAray.length;
            let checkInDate = "";
            let checkOutDate = "";
            for (var key in datesAray) {
                let date = datesAray[key].toISOString().slice(0,10);
                if (key == 0) {
                    checkInDate = date;
                    newMarkedDates[date] = {startingDay: true, color: '#3a93f1', textColor: 'white'};
                } else if (datesCount == (parseInt(key) + 1)) {
                    checkOutDate = date;
                    newMarkedDates[date] = {endingDay: true, color: '#3a93f1', textColor: 'white'};
                } else {
                    newMarkedDates[date] = {color: '#3a93f1', textColor: 'white'};
                }
            }
            datesSelected(checkInDate, checkOutDate);
            setMarkedDates(newMarkedDates);
            
        } else {
            let markedDatesArray = {};
            markedDatesArray[day.dateString] = {startingDay: true, endingDay: true, color: '#3a93f1', textColor: 'white'};
            setMarkedDates(markedDatesArray);
            setSearchDatesKey(Math.random().toString(36).substring(7));
            setCheckIn(day.dateString);
        }
    }
  }

  const getDaysArray = (s,e) => {
    for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;
  };

  const buildSearchInput = () => {
    let parent = [];
    let searchWrapperStyles = {
        zIndex: 200, position: "absolute", maxWidth: 290,
    }
    let xIconWrapper = {position: "absolute", zIndex: 200, left: 260, marginTop: 10,}
    if (Platform.OS == "web") {
        searchWrapperStyles = { zIndex: 200 }
        xIconWrapper = {position: "absolute", zIndex: 200, left: 270, marginTop: 10,}
    }
    parent.push(
        <View style={searchWrapperStyles}>
            <TouchableHighlight
                onPress={()=>{
                    filterDataChange("location", null);
                    setSearchKey(Math.random().toString(36).substring(7));
                }}
                style={xIconWrapper}
            >
                <Image
                    source={require('../assets/xIcon.png')}
                    style={styles.removeIconImage}
                />
            </TouchableHighlight>
            <SearchInput
                onChange={(lat, lng, name) => {
                    if (!!name) {
                        filterDataChange("location", {lat, lng, name})
                    } else {
                        if (props.data != null) {
                            if (props.data.location.name) {
                                filterDataChange("location",
                                {
                                    lat: props.data.location.lat,
                                    lng: props.data.location.lng,
                                    name: props.data.location.name
                                })
                            } else {
                                filterDataChange("location", null)
                            }
                        } else {
                            filterDataChange("location", null)
                        }
                    }
                }}
                value={filterData != null && filterData.location != undefined? filterData.location.name: null}
                key={searchKey}
            />
        </View>
    )
    if (Platform.OS != "web") {
        const buttonSpacer = [<View style={{height: 80}}/>];
        parent.push(
            buttonSpacer
        );
    } 
    parent.push(
        <View style={{padding: 5,}}>
            <ActionButton
                filled={true}
                text={buildTypeLabel()}
                size={"medium"}
                key={bedBathButtonKey}
                handler={()=>{
                    setListingTypeVisible(true);
                }}
            />
        </View>,
        <View style={{padding: 5,}}>
            <ActionButton
                filled={true}
                text={buildBedBathLabel()}
                size={"medium"}
                key={bedBathButtonKey}
                handler={()=>{
                    setBedBathVisible(true);
                }}
            />
        </View>,
        <View style={{padding: 5}}>
            <ActionButton
                filled={true}
                text={buildDatesLabel()}
                key={searchDatesKey}
                size={"medium"}
                handler={()=>{
                    setSearchDatesVisible(true);
                }}
            />
        </View>,
        <View style={{padding: 5,}}>
            <ActionButton
                filled={true}
                text={"other"}
                size={"medium"}
                handler={()=>{
                    setOtherVisible(true);
                }}
            />
        </View>
    );
    return parent;
  }

  return (
    <View style={styles.wrapper}>
        <Modal
            animationType={"fade"}
            visible={bedBathVisible}
            transparent={true}
            onBackdropPress={() => this.modalClose()}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <View style={{padding: 10,}}>
                        <Stepper
                            count={!!filterData? filterData.bedrooms: null}
                            label="Number of bedrooms"
                            key={bedroomsKey}
                            delayedHandler={count => {
                                filterDataChange("bedrooms", count);
                                setBedBathButtonKey(Math.random().toString(36).substring(7));
                            }}
                        />
                    </View>
                    <View style={{padding: 10, marginBottom: 15}}>
                        <Stepper
                            count={!!filterData? filterData.bathrooms: null}
                            delayedHandler={count => {
                                filterDataChange("bathrooms", count);
                                setBedBathButtonKey(Math.random().toString(36).substring(7));
                            }}
                            label="Number of bathrooms"
                            key={bathroomsKey}
                        />
                    </View>
                    <ActionButton
                        text={"Clear filters"}
                        filled={true}
                        handler={()=>{
                            let newFilterData = filterData || {};
                            delete newFilterData["bedrooms"];
                            delete newFilterData["bathrooms"];
                            setFilterData(newFilterData);
                            if (!!props.onFilterChange) {
                                props.onFilterChange(newFilterData);
                            }
                            setBedBathButtonKey(Math.random().toString(36).substring(7));
                            setBedroomKey(Math.random().toString(36).substring(7));
                            setBathroomsKey(Math.random().toString(36).substring(7))
                        }}
                    />
                    <ActionButton
                        text={"Done"}
                        handler={()=>{
                            setBedBathVisible(false);
                        }}
                    />
                </View>
            </View>
        </Modal>
        <Modal
            animationType={"fade"}
            visible={searchDatesVisible}
            transparent={true}
            onBackdropPress={() => this.modalClose()}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <Calendar
                        horizontal={true}
                        style={[styles.calendar]}
                        markedDates={markedDates}
                        onDayPress={(day) => onDayPress(day)}
                        minDate={buildMinDate()}
                        enableSwipeMonths={true}
                        markingType={"period"}
                        renderArrow={(direction) => direction === 'left' ? 
                            <Image
                                source={require('../assets/backArrowIcon.png')}
                                style={styles.leftIcon}
                            ></Image> : 
                            <Image
                                source={require('../assets/forwardArrowIcon.png')}
                                style={styles.rightIcon}
                            ></Image>
                        }
                        onMonthChange={(month) => {
                            //this.setState({selectedMonth: month.dateString});
                        }}
                        // current={this.state.selectedMonth}
                    />
                    <ActionButton
                        text={"Clear dates"}
                        filled={true}
                        handler={()=>{
                            setMarkedDates(null);
                            setSearchDatesKey(Math.random().toString(36).substring(7));
                            datesSelected(null, null);
                        }}
                    />
                    <ActionButton
                        text={"Done"}
                        handler={()=>{
                            setSearchDatesVisible(false);
                        }}
                    />
                </View>
            </View>
        </Modal>
        <Modal
            animationType={"fade"}
            visible={otherVisible}
            transparent={true}
            onBackdropPress={() => this.modalClose()}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <View style={{padding: 10,}}>
                        <Stepper
                            count={!!filterData? filterData.people: null}
                            delayedHandler={count => {
                                filterDataChange("people", count);
                            }}
                            label="Number of people"
                            key={peopleKey}
                        />
                    </View>
                    <View style={{padding: 10, height: 60,}}>
                        <Checkbox 
                            label={"Pet Friendly"}
                            checked={!!filterData && filterData.petFriendly? true: false}
                            handler={(checked)=>{
                                filterDataChange("petFriendly", checked);
                            }}
                            key={petFriendlyKey}
                        />
                    </View>
                    <View style={{marginBottom: 15, width: 250}}>
                        <StyledTextInput
                            placeholder={"Search Tags"}
                            onBlur={(text)=>{
                                filterDataChange("keyword", text);
                            }}
                            defaultValue={!!filterData && !!filterData.keyword? filterData.keyword: null}
                            key={tagsKey}
                        />
                    </View>
                    <ActionButton
                        text={"Clear filters"}
                        filled={true}
                        handler={()=>{
                            let newFilterData = filterData || {};
                            delete newFilterData["people"];
                            delete newFilterData["petFriendly"];
                            delete newFilterData["keyword"];
                            setFilterData(newFilterData);
                            if (!!props.onFilterChange) {
                                props.onFilterChange(newFilterData);
                            }
                            setPeopleKey(Math.random().toString(36).substring(7));
                            setPetFriendlyKey(Math.random().toString(36).substring(7));
                            setTagsKey(Math.random().toString(36).substring(7))
                        }}
                    />
                    <ActionButton
                        text={"Done"}
                        handler={()=>{
                            setOtherVisible(false);
                        }}
                    />
                </View>
            </View>
        </Modal>
        <Modal
            animationType={"fade"}
            visible={listingTypeVisible}
            transparent={true}
            onBackdropPress={() => this.modalClose()}
        >
            <View style={styles.centeredView}>
                <View style={styles.modalView}>
                    <View style={{padding: 10, height: 120}}>
                        <Checkbox 
                            label={"Short Term Rental"}
                            checked={!!filterData && !!filterData.listingType && filterData.listingType == "str"? true: false}
                            handler={(checked)=>{
                                filterDataChange("listingType", checked? "str": null);
                                setStrKey(Math.random().toString(36).substring(7))
                                setLtrKey(Math.random().toString(36).substring(7))
                                setSaleKey(Math.random().toString(36).substring(7))
                            }}
                            key={strKey}
                        />
                        <Checkbox 
                            label={"Long Term Rental"}
                            checked={!!filterData && !!filterData.listingType && filterData.listingType == "ltr"? true: false}
                            handler={(checked)=>{
                                filterDataChange("listingType",  checked? "ltr": null);
                                setStrKey(Math.random().toString(36).substring(7))
                                setLtrKey(Math.random().toString(36).substring(7))
                                setSaleKey(Math.random().toString(36).substring(7))
                            }}
                            key={ltrKey}
                        />
                        <Checkbox 
                            label={"For Sale"}
                            checked={!!filterData && !!filterData.listingType && filterData.listingType == "forSale"? true: false}
                            handler={(checked)=>{
                                filterDataChange("listingType",  checked? "forSale": null);
                                setStrKey(Math.random().toString(36).substring(7))
                                setLtrKey(Math.random().toString(36).substring(7))
                                setSaleKey(Math.random().toString(36).substring(7))
                            }}
                            key={saleKey}
                        />
                    </View>
                    <ActionButton
                        text={"Clear filters"}
                        filled={true}
                        handler={()=>{
                            let newFilterData = filterData || {};
                            delete newFilterData["listingType"];
                            setFilterData(newFilterData);
                            if (!!props.onFilterChange) {
                                props.onFilterChange(newFilterData);
                            }
                            setStrKey(Math.random().toString(36).substring(7))
                            setLtrKey(Math.random().toString(36).substring(7))
                            setSaleKey(Math.random().toString(36).substring(7))
                        }}
                    />
                    <ActionButton
                        text={"Done"}
                        handler={()=>{
                            setListingTypeVisible(false)
                        }}
                    />
                </View>
            </View>
        </Modal>
        {buildSearchInput()}
    </View>
  );
}

export default FilterBar;

const styles = StyleSheet.create({
  removeIconImage: {
    height: 30,
    width:30,
    zIndex: 12,
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "center",
    alignItems: "baseline"
  },
  centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        minWidth: 250,
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        justifyContent: "center",
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    calendar: {
        height: 400,
        marginTop: 10,
        maxWidth: 600,
        width: "100%",
    },
    leftIcon: {
        width:40,
        height:40,
    },
    rightIcon: {
        width:40,
        height:40,
    },   
});