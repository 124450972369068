import React, { Component } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { getDatabase, ref, onValue, set } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import 'react-native-gesture-handler';
import { createStackNavigator } from '@react-navigation/stack';

import PostProperties from './PostProperty'
import Calendar from "./CalendarView"
import Subscription from './Subscription';
import Pricing from './Pricing';
import SpecialOptions from './BookingOptions';
import CheckInInstructions from './CheckInInstructions';
import Reservations from './Reservations';
import Success from './Success';
import CheckOut from './CheckOut';
import TaxRate from './TaxRate';
import PublicProfile from './PublicProfile';
import MessageView from './Message';
import CancelReservation from './CancelReservation';

import { removeDeletedFiles } from '../helpers/image-uploader';

import Modal from "../compenents/Modal";
import MyRentalsTable from "../compenents/MyRentalsTable";
import Footer from "../compenents/Footer";
import ActionButton from '../compenents/ActionButton';

const Stack = createStackNavigator();

export default class MyPropertiesViewWrapper extends Component {
    render() {
        return (
            <Stack.Navigator>
                <Stack.Screen name="My Properties" component={MyPropertiesView} options={{headerShown: false}}/>
                <Stack.Screen name="Post Property" component={PostProperties}/>
                <Stack.Screen name="Calendar View" component={Calendar}/>
                <Stack.Screen name="Subscription" component={Subscription}/>
                <Stack.Screen name="Pricing" component={Pricing}/>
                <Stack.Screen name="Tax Collection" component={TaxRate}/>
                <Stack.Screen name="Special Options" component={SpecialOptions}/>
                <Stack.Screen name="CheckIn Instructions" component={CheckInInstructions}/>
                <Stack.Screen name="Reservations" component={Reservations}/>
                <Stack.Screen name="Check Out" component={CheckOut}/>
                <Stack.Screen name="Success" component={Success}/>
                <Stack.Screen name="Public Profile" component={PublicProfile}/>
                <Stack.Screen name="Message" component={MessageView}/>
                <Stack.Screen name="Cancel Reservation" component={CancelReservation}/>
            </Stack.Navigator>
        )
    }
}

class MyPropertiesView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            modalVisible: false,
            modalKey:  Math.random().toString(36).substring(7),
            successModalVisible: false,
            successModalKey:  Math.random().toString(36).substring(7),
            errorModalVisible: false,
            errorModalKey: Math.random().toString(36).substring(7),
            requestingDeletePostData: null,
            requestingDeleteDraftData: null,
            deleteDraftModelVisible: false,
            deleteDraftsModalKey: Math.random().toString(36).substring(7),
            rentalTableKey: Math.random().toString(36).substring(7),
        };
        this.requestDeletePost = this.requestDeletePost.bind(this);
    } 

    componentDidMount() {
        this.props.navigation.setOptions({
            drawerType: "permanent"
        })
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
			if (user) {
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
		});
    }

    showDeletePrompt = (postData) => {
        this.setState({ 
            requestingDeletePostData: postData,
            modalVisible: true,
            modalKey:  Math.random().toString(36).substring(7),
        });
    }

    showDeleteDraftPrompt = (postData) => {
        this.setState({ 
            requestingDeleteDraftData: postData,
            deleteDraftModelVisible: true,
            deleteDraftsModalKey:  Math.random().toString(36).substring(7),
        });
    }

    requestDeletePost = async () => {
        let url = "https://us-central1-doc2doc-d54a2.cloudfunctions.net/deletePost?";
        url += "posterUID="+this.state.user.uid;
        url += "&postID="+this.state.requestingDeletePostData.key;
        let response = await fetch(url);
        let data = await response.json();
        if (!!data.response) {
            if (data.response == "success") {
                removeDeletedFiles(
                    this.state.requestingDeletePostData.photoUrls,
                    this.state.requestingDeletePostData.key, false,
                    this.state.user.uid, true);

                this.setState({
                    successModalVisible: true, 
                    successModalKey: Math.random().toString(36).substring(7),
                    rentalTableKey: Math.random().toString(36).substring(7),
                });
            } else {
                // alert("There was a failire checking out. please reach out to doc2docrentals@gmail.com #88512")
            }
        }
    }

    requestDeleteDraft = async () => {
        if (this.state.requestingDeleteDraftData) {
            const db = getDatabase();
            const draftsRef = ref(db, `drafts/${this.state.user.uid}/${this.state.requestingDeleteDraftData.key}`);
            await set(draftsRef, null);

            //delete images
            removeDeletedFiles(
                this.state.requestingDeleteDraftData.photoUrls,
                this.state.requestingDeleteDraftData.key,
                true, this.state.user.uid, true);

            this.setState({
                successModalVisible: true, 
                successModalKey: Math.random().toString(36).substring(7),
                rentalTableKey: Math.random().toString(36).substring(7),
            });
        } else {
            this.setState({
                errorModalVisible: true, 
                errorModalKey: Math.random().toString(36).substring(7),
            });
        }
    }

    onModalClose = () => {
        this.setState({modalVisible : false, deleteDraftModelVisible: false});
    }

    render() {
        return (
            <ScrollView contentContainerStyle={styles.container}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.errorModalVisible}
                    key={this.state.errorModalKey}
                    onModalClose={this.onModalClose}
                    isError={true}
                    labelText="There was an issue deleting your post"
                />
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.successModalVisible}
                    key={this.state.successModalKey}
                    onModalClose={this.onModalClose}
                    isSuccess={true}
                    labelText="Your post has been deleted"
                />
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.modalVisible}
                    key={this.state.modalKey}
                    onModalClose={this.onModalClose}
                    onConfirmClick={this.requestDeletePost}
                    labelText="Are you sure you want to delete this post? This will automatically unsubscribe you from automatic payments with PayPal and delete the post."
                />
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.deleteDraftModelVisible}
                    key={this.state.deleteDraftsModalKey}
                    onModalClose={this.onModalClose}
                    onConfirmClick={this.requestDeleteDraft}
                    labelText="Are you sure you want to delete this draft?"
                />
                <ActionButton
                    text={"+ Post New Property"}
                    handler={()=>{
                        this.props.navigation.getParent().setOptions({headerShown: false});
                        this.props.navigation.navigate('Post Property', {
                            publicRentalData: null,
                            privateRentalData: null,
                            draftData: null,
                        });
                    }}
                />
                {!!this.state.user ? 
                    <MyRentalsTable 
                        key={this.state.rentalTableKey}
                        user={this.state.user} 
                        navigation={this.props.navigation}
                        onDeletePress={this.showDeletePrompt}
                        onDeleteDraft={this.showDeleteDraftPrompt}   
                    />
                    : 
                    null
                }
                <Footer hideText={true}/>
            </ScrollView>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 10,
        paddingBottom: 20,
    },
    button: {
        marginTop: 20,
        marginBottom: 30,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 250
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    buttonSignup: {
        fontSize: 12
    },
    headerLabeltext: {
        fontSize: 24,
        marginTop: 50,
        fontWeight: "700",
    },
    mb50: {
        marginBottom: 50,
    },
    mb20: {
        marginBottom: 20,
    },
    mb10: {
        marginBottom: 10,
    },
    mt80: {
        marginTop: 80,
    }
})