import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, Platform } from 'react-native';
import * as WebBrowser from 'expo-web-browser';
import ActionButton from '../compenents/ActionButton';
import CheckBox from '../compenents/CheckBox';
import { getDatabase, ref, get, set, push } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { requestUpdate } from '../helpers/post-interactons';

import Container from "../compenents/Container";
import TopAlert from '../compenents/TopAlert';
import Modal from '../compenents/Modal';
import Stepper from '../compenents/Stepper';

export default class TaxRate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            successIsShowing: false,
            yesChecked: false,
            noChecked: false,
            salesTax: 0,
            occupancyTax: 0, 
            yesKey: Math.random().toString(36).substring(7),
            noKey: Math.random().toString(36).substring(7),
            salesTaxKey: Math.random().toString(36).substring(7),
            occupancyTaxKey: Math.random().toString(36).substring(7),
        }
    }

    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
			if (user) {
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
        });
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.populateView();
        });
        this.populateView();
    }

    populateView = () => {
        let salesTax = 0;
        let occupancyTax = 0;
        if (!!this.props.route.params.newRentalData) {
            if (!!this.props.route.params.newRentalData.salesTax) {
                salesTax = this.props.route.params.newRentalData.salesTax;
            }
            if (!!this.props.route.params.newRentalData.occupancyTax) {
                occupancyTax = this.props.route.params.newRentalData.occupancyTax;
            }
        } else if (!!this.props.route.params.rentalData) {
            if (!!this.props.route.params.rentalData.salesTax) {
                salesTax = this.props.route.params.rentalData.salesTax;
            }
            if (!!this.props.route.params.rentalData.occupancyTax) {
                occupancyTax = this.props.route.params.rentalData.occupancyTax;
            }
        }
        let yesChecked = false;
        let noChecked = false;
        if (salesTax != 0 || occupancyTax != 0) {
            yesChecked = true
        } else if (!!this.props.route.params.rentalData) {
            noChecked = true;
        }
        this.setState({
            yesChecked: yesChecked,
            noChecked: noChecked,
            salesTax: salesTax,
            occupancyTax: occupancyTax, 
            yesKey: Math.random().toString(36).substring(7),
            noKey: Math.random().toString(36).substring(7),
            salesTaxKey: Math.random().toString(36).substring(7),
            occupancyTaxKey: Math.random().toString(36).substring(7),
        })
    }

    yesHanlder = (checked) => {
        this.setState({
            yesChecked: checked,
            noChecked: !checked,
            yesKey: Math.random().toString(36).substring(7),
            noKey: Math.random().toString(36).substring(7),
        });
    }

    noHanlder = (checked) => {
        this.setState({
            yesChecked: !checked,
            noChecked: checked,
            yesKey: Math.random().toString(36).substring(7),
            noKey: Math.random().toString(36).substring(7),
        });
    }

    salesTaxHandler = (taxRate) => {
        let final = parseFloat(`${taxRate}`.replace(/[^\d.-]/g, '')) 
        this.setState({
            salesTax: `${final.toFixed(1)}%`
        })
    }

    occupancyTaxHandler = (taxRate) => {
        let final = parseFloat(`${taxRate}`.replace(/[^\d.-]/g, '')) 
        this.setState({
            occupancyTax: `${final.toFixed(1)}%`
        })
    }

    buildTotalLabel = () => {
        let parent = [];
        let totalTax = parseFloat(this.state.salesTax ) + parseFloat(this.state.occupancyTax);
        if (this.state.yesChecked) {
            parent.push(
                <Text 
                    allowFontScaling={false}
                    style={[styles.headerLabeltext, {marginBottom: 10}]}
                >Total Tax Collected: {totalTax.toFixed(1)}%
                </Text>
            );
        }
        return parent;
    }

    onModalClose = () => {
        this.setState({
            modalVisible: false,
        })
    }

    saveDraft = (checkoutData) => {
        let save = false;
        if (this.props.route.params.subscriptionChange == null) {
            save = true;
        } else if (!this.props.route.params.subscriptionChange) {
            save = true;
        }
        if (save) {
            const db = getDatabase();
            const draftsRef = ref(db, `drafts/${this.state.user.uid}/${checkoutData.key}`);
            set(draftsRef, checkoutData);
        }
    }

    continueButtonPress = () => {
        let subscriptionChange = true;
        if (this.props.route.params.subscriptionChange == null) {
            subscriptionChange = false;
        } else if (!this.props.route.params.subscriptionChange) {
            subscriptionChange = false;
        }
        if (this.state.yesChecked) {
            let newRentalData = this.props.route.params.newRentalData;
            newRentalData.salesTax = parseFloat(`${this.state.salesTax}`.replace(/[^\d.-]/g, ''));
            newRentalData.occupancyTax = parseFloat(`${this.state.occupancyTax}`.replace(/[^\d.-]/g, ''));
            this.props.navigation.navigate("CheckIn Instructions", {
                newRentalData: newRentalData,
                subscriptionChange: subscriptionChange,
            });
            this.saveDraft(newRentalData);
        } else if (this.state.noChecked) {
            this.props.navigation.navigate("CheckIn Instructions", {
                newRentalData: this.props.route.params.newRentalData,
                subscriptionChange: subscriptionChange,
            });
            this.saveDraft(this.props.route.params.newRentalData);
        } else {
            this.setState({
                modalVisible: true,
                modalKey: Math.random().toString(36).substring(7),
            });
        }
    }

    saveButtonPress = async () => {
        let checkoutData = this.props.route.params.rentalData
        checkoutData.salesTax = parseFloat(`${this.state.salesTax}`.replace(/[^\d.-]/g, ''));
        checkoutData.occupancyTax = parseFloat(`${this.state.occupancyTax}`.replace(/[^\d.-]/g, ''));
        let success = await requestUpdate(this.state.user.uid, checkoutData);
        if (success) {
            this.showSuccess();
        } else {
            this.setState({
                errorMessage: "There was an issue updating your lising.",
                errorModalKey: Math.random().toString(36).substring(7),
                errorModalVisible: true,
            })
        }    
    }

    showSuccess = () => {
        this.setState({
            successIsShowing: true,
        });
        setTimeout(() => {
            this.setState({
                successIsShowing: false,
            });
        }, 1500);
    }

    render() {
        return (
            <View style={{height: "100%"}}>
                <TopAlert text={"Tax Collection updated"} isShowing={this.state.successIsShowing}/>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.modalVisible}
                    key={this.state.modalKey}
                    onModalClose={this.onModalClose}
                    labelText={"Please fill in all the required fields"}
                    isError={true}
                />
                <ScrollView contentContainerStyle={styles.container}>
                    <Container title={"Tax Collection Amount"}>
                        <Text style={{marginTop: 10}}>Doc to Doc Rentals offers the ability to collect taxes for your rental.</Text>
                        <Text style={{marginTop: 10}}>The collected taxes can be paid out separately.</Text>
                        <Text style={{marginTop: 10}}>
                            Don't know what tax rate to collect? You can look up your sales and occupancy tax rate by state 
                            <Text 
                                style={styles.link} 
                                onPress={()=>{
                                    let url = "https://www.ncsl.org/research/fiscal-policy/state-lodging-taxes.aspx";
                                    if (Platform.OS !== 'web') {
                                        try {
                                            const results = WebBrowser.openBrowserAsync(url).catch(error => {
                                                console.log(error)
                                                this.setState({
                                                    errorModalVisible : true,
                                                    errorModalText: "There was an issue opening the following URL: "+ url,
                                                    errorModalKey: Math.random().toString(36).substring(7),
                                                });
                                            });
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    } else {
                                        window.open(url, "_blank")
                                    }
                                }}
                            > here.</Text>
                        </Text>
                        <Text style={{marginTop: 10}}>Would you like us to collect taxes for you?</Text>
                        <View style={{height: 10}}/>
                        <CheckBox
                            label={"Yes"}
                            checked={this.state.yesChecked}
                            key={this.state.yesKey}
                            handler={this.yesHanlder}
                        />
                        <View style={{height: 5}}/>
                        <CheckBox
                            label={"No, I will withhold my own taxes"}
                            checked={this.state.noChecked}
                            key={this.state.noKey}
                            handler={this.noHanlder}
                        />
                        <View style={{height: 10}}/>
                        {
                            this.state.yesChecked ?
                            <View style={{marginBottom: 10}}>
                                <Stepper
                                    count={this.state.salesTax}
                                    key={this.state.salesTaxKey}
                                    handler={this.salesTaxHandler}
                                    label="Sales Tax Rate"
                                    percentage={true}
                                    max={10}
                                />
                                <Stepper
                                    count={this.state.occupancyTax}
                                    key={this.state.occupancyTaxKey}
                                    handler={this.occupancyTaxHandler}
                                    label="Occupancy Tax Rate"
                                    percentage={true}
                                    max={16}
                                />
                            </View>:null
                        }
                        { this.buildTotalLabel() }
                        {
                            !!this.props.route.params.newRentalData ?
                            <ActionButton
                                text={"Continue"}
                                handler={()=>{
                                    this.continueButtonPress();
                                }}
                            />:
                            <ActionButton
                            text={"Save"}
                            handler={()=>{
                                this.saveButtonPress();
                            }}
                        />
                        }
                    </Container>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        marginTop: 10,
        fontWeight: "600",
        textAlign: "center",
    },
    link: {
        textDecorationStyle: "solid",
        textDecorationColor: "black",
        textDecorationLine: "underline",
    },
})