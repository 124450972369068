import React, { Component } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';

import Logo from "../compenents/Logo";
import Container from "../compenents/Container";
import TermsOfService from '../compenents/TermsOfService';
import UserAgreementComponent from '../compenents/UserAgreementComponent';
import Footer from "../compenents/Footer";

export default class Legal extends Component {

    constructor(props) {
        super(props);
    } 

    render() {
        return (
            <ScrollView>
               <View style={styles.container}>
                    <Logo/>
                    <Container>
                        <UserAgreementComponent/>
                    </Container>
                    <Container>
                        <TermsOfService/>
                    </Container>
                </View>
                <Footer hideText={true}/>
            </ScrollView>
        );
    }
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    listingFooter: {
        height: 1,
        width: "80%",
        backgroundColor: "grey",
        marginTop: 30,
        marginBottom: 20
    },
});