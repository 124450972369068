import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import AutoComplete from "react-google-autocomplete";

const GooglePlacesInputWeb = (props) => {
    const [value, setValue] = useState(null);
  
    useEffect(() => {
      if (!!value) {
        if (!!value.geometry) {
          let lat = value.geometry.location.lat();
          let lng = value.geometry.location.lng();
          if (props.onChange && !!lat && !!lng) {
            props.onChange(lat, lng, value.formatted_address)
          }
        }
      }
    }, [value]);
  
    if (props.local == "address") {
      return (
        <AutoComplete
          defaultValue={props.value}
          apiKey={"AIzaSyCgAEOikmxZ9Zb9JB20pSHGZLKACyt9YZk"}
          style={
            { width: "292px",
              height: "45px",
              backgroundColor: "#f5f5f5",
              borderColor: '#d3d3d3',
              borderWidth: 0,
              borderBottomWidth: 1,
              fontSize: 14,
              zIndex: 100
            }
          }
          options={{
            types: [],
          }}
          onPlaceSelected={(place) => {
            setValue(place);
          }}
        />
      );
    } else {
      return (
        <AutoComplete
          apiKey={"AIzaSyCgAEOikmxZ9Zb9JB20pSHGZLKACyt9YZk"}
          defaultValue={props.value}
          style={
            { width: "292px",
              height: "45px",
              backgroundColor: "#f5f5f5",
              borderColor: '#d3d3d3',
              borderWidth: 0,
              borderBottomWidth: 1,
              fontSize: 14,
              zIndex: 100,
              paddingLeft: 10,
            }
          }
          options={{
            types: ["(cities)"],
          }}
          onPlaceSelected={(place) => {
            setValue(place);
          }}
        />
      );
    }
}

const styles = StyleSheet.create({
  inputBox: {
    width: '100%',
    borderRightWidth: 0,
    borderBottomWidth: 1,
    height: 50,
    padding: 10,
    fontSize: 14,
    borderColor: '#d3d3d3',
    backgroundColor: "#f5f5f5",
    textAlign: "center",
    maxWidth: 800,
  },
});

export default GooglePlacesInputWeb;