import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View } from 'react-native';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get } from "firebase/database";
import 'react-native-gesture-handler';
import { createStackNavigator } from '@react-navigation/stack';

import PublicProfile from './PublicProfile';
import MessageView from './Message';

import Feed from "../compenents/Feed";
import TopAlert from '../compenents/TopAlert';
import Loader from "../compenents/Loader";


const Stack = createStackNavigator();

export default class RequestsWrapper extends Component {
    render() {
        return (
            <Stack.Navigator>
                <Stack.Screen name="Requests View" component={Requests} options={{headerShown: false}}/>
                <Stack.Screen name="Profile View" component={PublicProfile}/>
                <Stack.Screen name="Message" component={MessageView}/>
            </Stack.Navigator>
        )
    }
}

class Requests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            requestsData: null,
            successIsShowing: false,
            loaderIsShowing: false,
            loaderKey: Math.random().toString(36).substring(7),
            blockList: null,
            feedKey: Math.random().toString(36).substring(7),
        }
    }

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.getUser();
            this.props.navigation.getParent().setOptions({headerShown: true});
        });
        this.getUser();
    }

    getUser = async () => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                this.setState({
                    user: user,
                });
                let success = await this.getRequests(user);
                let done = await this.getBlockList()
            } else {
                this.setState({
                    user: null,
                })
            }
        });
    }

    getRequests = async (user) => {
        const db = getDatabase();
        const requestsRef = ref(db, `requests/${user.uid}`);
        let snapshot = await get(requestsRef);
        let requestsData = snapshot.val();
        if (!!requestsData) {
            this.setState({
                requestsData: requestsData,
                feedKey: Math.random().toString(36).substring(7),
            });
            return true;
        }
        return false;
    }


    getBlockList = async () => {
        const db = getDatabase();
        const reservationsRef = ref(db, `blockList/${this.state.user.uid}`);
        let snapshot = await get(reservationsRef);
        let blockList = snapshot.val();
        this.setState({
            blockList: blockList,
        });
        return true;
    }

    showLoading = (showing) => {
        this.setState({ 
            loaderIsShowing: showing,
            loaderKey: Math.random().toString(36).substring(7),
        });
    }

    showSuccess = () => {
        this.setState({
            successIsShowing: true,
        });
        setTimeout(() => {
            this.setState({
                successIsShowing: false,
            });
        }, 1500);
    }

    buildView = () => {
        let parent = [];
        if (!!this.state.requestsData) {
            parent.push(
                <Feed 
                    key={this.state.feedKey}
                    requestsData={this.state.requestsData} 
                    blockList={this.state.blockList}
                    navigation={this.props.navigation}
                    showMoreIsShowing={false}
                    colums={2}
                    onSuccess={()=>{
                        this.showSuccess();
                        this.setState({
                            requestsData: null,
                        });
                        this.getRequests(this.state.user);
                    }}
                    onLoading={(isLoading)=>{
                        this.showLoading(isLoading);
                    }}
                    user={this.state.user}
                />
            )
        } else {
            parent.push(
                <Text style={{marginTop: 10, fontWeight: "600"}}>You have no requests yet</Text>
            );
        }
        return parent;
    }

    render() {
        return (
            <View style={{height: "100%"}}>
                <TopAlert text={"Success"} isShowing={this.state.successIsShowing}/>
                <Loader 
                    visible={this.state.loaderIsShowing}
                    key={this.state.loaderKey}  
                />
                <ScrollView contentContainerStyle={styles.container}>
                    {this.buildView()}
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        marginTop: 10,
        fontWeight: "600",
        textAlign: "center",
    },
})