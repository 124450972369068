import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View } from 'react-native';
import { getDatabase, ref, child, set, push } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { requestUpdate } from '../helpers/post-interactons';

import Container from "../compenents/Container";
import Modal from "../compenents/Modal";
import Checkbox from "../compenents/CheckBox";
import Stepper from "../compenents/Stepper";
import TopAlert from '../compenents/TopAlert';
import ActionButton from '../compenents/ActionButton';

export default class BookingOptions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            autoBook: null,
            public: null,
            requireNPI: null,
            requestNPI: null,
            minStay: null,
            minDays: null,
            errorModalVisible: false,
            successIsShowing: false,
            autoBookKey: Math.random().toString(36).substring(7),
            noAutoBookkey: Math.random().toString(36).substring(7),
            errorModalKey: Math.random().toString(36).substring(7),
            docsOnlyCheckBoxKey: Math.random().toString(36).substring(7),
            publicCheckBoxKey: Math.random().toString(36).substring(7),
            requireNPIKey: Math.random().toString(36).substring(7),
            requestNPIKey: Math.random().toString(36).substring(7),
            noNPIKey: Math.random().toString(36).substring(7),
            minDaysKey: Math.random().toString(36).substring(7),
        };
    }

    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
			if (user) {
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
        });
        this.configurePage();
    }

    configurePage = () => {
        if (!!this.props.route.params) {
            let autoBook = null;
            let requireNPI = null;
            let requestNPI = null;
            let minStay = null;
            let minDays = null;
            let publicRental = null;
            let isBronze = false;
            if (this.props.route.params.rentalData) {
                if (this.props.route.params.rentalData.autoBook != null) {
                    autoBook = this.props.route.params.rentalData.autoBook;
                }
                if (this.props.route.params.rentalData.requireNPI != null) {
                    requireNPI = this.props.route.params.rentalData.requireNPI;
                }
                if (this.props.route.params.rentalData.requestNPI != null) {
                    requestNPI = this.props.route.params.rentalData.requestNPI;
                }
                if (this.props.route.params.rentalData.minStay != null) {
                    minStay = this.props.route.params.rentalData.minStay;
                }
                if (this.props.route.params.rentalData.minDays != null) {
                    minDays = this.props.route.params.rentalData.minDays
                }
                if (this.props.route.params.rentalData.public != null) {
                    publicRental = this.props.route.params.rentalData.public
                }
                if (!!this.props.route.params.rentalData.subscriptionLevel) {
                    if (this.props.route.params.rentalData.subscriptionLevel == null ||
                        this.props.route.params.rentalData.subscriptionLevel == undefined) {
                        isBronze = true;
                    } else if (this.props.route.params.rentalData.subscriptionLevel == "bronze") {
                        isBronze = true;
                    } 
                } else {
                    isBronze = true;
                }
            } else if (this.props.route.params.newRentalData) {
                if (this.props.route.params.newRentalData.autoBook != null) {
                    autoBook = this.props.route.params.newRentalData.autoBook;
                }
                if (this.props.route.params.newRentalData.requireNPI != null) {
                    requireNPI = this.props.route.params.newRentalData.requireNPI;
                }
                if (this.props.route.params.newRentalData.requestNPI != null) {
                    requestNPI = this.props.route.params.newRentalData.requestNPI;
                }
                if (this.props.route.params.newRentalData.minStay != null) {
                    minStay = this.props.route.params.newRentalData.minStay;
                }
                if (this.props.route.params.newRentalData.minDays != null) {
                    minDays = this.props.route.params.newRentalData.minDays
                }
                if (this.props.route.params.newRentalData.public != null) {
                    publicRental = this.props.route.params.newRentalData.public
                }
                if (this.props.route.params.newRentalData.subscriptionLevel == "bronze") {
                    isBronze = true;
                }
            }
            this.setState({
                autoBook: autoBook,
                requireNPI: requireNPI,
                requestNPI: requestNPI,
                minStay: minStay,
                minDays: minDays,
                public: publicRental,
                autoBookKey: Math.random().toString(36).substring(7),
                noAutoBookkey: Math.random().toString(36).substring(7),
                docsOnlyCheckBoxKey: Math.random().toString(36).substring(7),
                publicCheckBoxKey: Math.random().toString(36).substring(7),
                requireNPIKey: Math.random().toString(36).substring(7),
                requestNPIKey: Math.random().toString(36).substring(7),
                noNPIKey: Math.random().toString(36).substring(7),
                minDaysKey: Math.random().toString(36).substring(7),
                minStayYesKey: Math.random().toString(36).substring(7),
                minStayNoKey: Math.random().toString(36).substring(7),
                isBronze: isBronze,
            });
        }
    }

    // getBookingOptions = () => {
    //     const db = getDatabase();
    //     let draftsRef = ref(db, `drafts/${this.state.user.uid}}/${purchaseData.key}`);
    //     get(rentalsRef).then((snapshot) => {
            
    //     });
    // }

    autoBookHandler = (checked) => {
        this.setState({ 
            autoBook: checked,
            autoBookKey: Math.random().toString(36).substring(7),
            noAutoBookkey: Math.random().toString(36).substring(7),
        })
    }

    autoBookNoHandler = (checked) => {
        this.setState({ 
            autoBook: !checked,
            autoBookKey: Math.random().toString(36).substring(7),
            noAutoBookkey: Math.random().toString(36).substring(7),
        })
    }

    docsOnlyHandler = (checked) => {
        this.setState({ 
            public: !checked,
            docsOnlyCheckBoxKey: Math.random().toString(36).substring(7),
            publicCheckBoxKey: Math.random().toString(36).substring(7),
        })
    }

    publicHandler = (checked) => {
        this.setState({ 
            public: checked,
            docsOnlyCheckBoxKey: Math.random().toString(36).substring(7),
            publicCheckBoxKey: Math.random().toString(36).substring(7),
        })
    }

    requireNPIHandler = (checked) => {
        this.setState({ 
            requireNPI: checked,
            requestNPI: false,
            requireNPIKey: Math.random().toString(36).substring(7),
            requestNPIKey: Math.random().toString(36).substring(7),
            noNPIKey: Math.random().toString(36).substring(7),
        })
    }

    requestNPIHandler = (checked) => {
        this.setState({ 
            requireNPI: false,
            requestNPI: checked,
            requireNPIKey: Math.random().toString(36).substring(7),
            requestNPIKey: Math.random().toString(36).substring(7),
            noNPIKey: Math.random().toString(36).substring(7),
        });
    }

    noNPIHandler = (checked) => {
        this.setState({ 
            requireNPI: false,
            requestNPI: false,
            requireNPIKey: Math.random().toString(36).substring(7),
            requestNPIKey: Math.random().toString(36).substring(7),
            noNPIKey: Math.random().toString(36).substring(7),
        });
    }

    minStayYesHandler = (checked) => {
        this.setState({ 
            minStay: checked,
            minStayYesKey: Math.random().toString(36).substring(7),
            minStayNoKey: Math.random().toString(36).substring(7),
        });
    }

    minStayNoHandler = (checked) => {
        this.setState({ 
            minStay: !checked,
            minStayYesKey: Math.random().toString(36).substring(7),
            minStayNoKey: Math.random().toString(36).substring(7),
        });
    }

    saveDraft = (checkoutData) => {
        let save = false;
        if (this.props.route.params.subscriptionChange == null) {
            save = true;
        } else if (!this.props.route.params.subscriptionChange) {
            save = true;
        }
        if (save) {
            const db = getDatabase();
            const draftsRef = ref(db, `drafts/${this.state.user.uid}/${checkoutData.key}`);
            set(draftsRef, checkoutData);
        }
    }

    saveButtonPress = () => {
        this.actionButtonPress();
    }

    bronzeSaveButtonPress = async () => {
        let updateData = {...this.props.route.params.rentalData};
        updateData["public"] = this.state.public;
        let success = await requestUpdate(this.state.user.uid, updateData);
        if (success) {
            this.showSuccess();
        } else {
            this.setState({
                errorMessage: "There was an issue updating your lising.",
                errorModalKey: Math.random().toString(36).substring(7),
                errorModalVisible: true,
            })
        }     
    }

    nextButtonPress = () => {
        this.actionButtonPress();
    }

    bronzeCheckOut = async () => {
        if (this.state.public != null) {
            if (this.props.route.params.newRentalData != null) {
                let checkoutData = this.props.route.params.newRentalData;
                checkoutData.public = this.state.public;
                this.props.navigation.navigate("Check Out", {
                    newRentalData: checkoutData,
                    checkOutType: "bronze",
                });
                this.saveDraft(checkoutData);
            } else if (this.props.route.params.rentalData) {
                let updateData = {...this.props.route.params.rentalData};
                updateData.public = this.state.public;
                let success = await requestUpdate(this.state.user.uid, updateData);
                if (success) {
                    this.showSuccess();
                } else {
                    this.setState({
                        errorMessage: "There was an issue updating your lising.",
                        errorModalKey: Math.random().toString(36).substring(7),
                        errorModalVisible: true,
                    })
                }      
            }
        }
    }

    actionButtonPress = async () => {
        if (this.state.autoBook != null &&
            this.state.public != null &&
            this.state.requireNPI != null &&
            this.state.requestNPI != null &&
            this.state.minStay != null) {
            if (this.state.public && this.state.requireNPI) {
                this.setState({
                    errorMessage: "You cannot require an NPI from all renters if its available to the public.",
                    errorModalKey: Math.random().toString(36).substring(7),
                    errorModalVisible: true,
                })
            } else {
                if (this.props.route.params.newRentalData != null) {
                    let checkoutData = this.props.route.params.newRentalData;
                    checkoutData.autoBook = this.state.autoBook;
                    checkoutData.public = this.state.public;
                    checkoutData.requireNPI = this.state.requireNPI;
                    checkoutData.requestNPI = this.state.requestNPI;
                    checkoutData.minStay = this.state.minStay;
                    if (this.state.minDays != null) {
                        checkoutData.minDays = this.state.minDays;
                    }

                    let subscriptionChange = true;
                    if (this.props.route.params.subscriptionChange == null) {
                        subscriptionChange = false;
                    } else if (!this.props.route.params.subscriptionChange) {
                        subscriptionChange = false;
                    }

                    this.props.navigation.navigate("Pricing", {
                        newRentalData: checkoutData,
                        subscriptionChange: subscriptionChange,
                    });
                    this.saveDraft(checkoutData);
                } else if (this.props.route.params.rentalData) {
                    let updateData = {...this.props.route.params.rentalData};
                    updateData.autoBook = this.state.autoBook;
                    updateData.public = this.state.public;
                    updateData.requireNPI = this.state.requireNPI;
                    updateData.requestNPI = this.state.requestNPI;
                    updateData.minStay = this.state.minStay;
                    if (this.state.minDays != null) {
                        updateData.minDays = this.state.minDays;
                    }
                    let success = await requestUpdate(this.state.user.uid, updateData);
                    if (success) {
                        this.showSuccess();
                    } else {
                        this.setState({
                            errorMessage: "There was an issue updating your lising.",
                            errorModalKey: Math.random().toString(36).substring(7),
                            errorModalVisible: true,
                        })
                    }      
                }
            }
        } else {
            this.setState({
                errorMessage: "Please select all required fields.",
                errorModalKey: Math.random().toString(36).substring(7),
                errorModalVisible: true,
            })
        }
    }

    showSuccess = () => {
        this.setState({
            successIsShowing: true,
        });
        setTimeout(() => {
            this.setState({
                successIsShowing: false,
            });
        }, 1500);
    }

    createPublicFeilds = () => {
        let parent = [];
        if (this.state.public != null) {
            parent.push(
                <Checkbox 
                    label={"Doctors only"}
                    checked={!this.state.public }
                    key={this.state.docsOnlyCheckBoxKey}
                    handler={this.docsOnlyHandler}
                />,
                <Checkbox 
                    label={"Public"}
                    checked={this.state.public}
                    key={this.state.publicCheckBoxKey}
                    handler={this.publicHandler}
                />
            );
        } else {
            parent.push(
                <Checkbox 
                    label={"Doctors only"}
                    checked={false }
                    key={this.state.docsOnlyCheckBoxKey}
                    handler={this.docsOnlyHandler}
                />,
                <Checkbox 
                    label={"Public"}
                    checked={false}
                    key={this.state.publicCheckBoxKey}
                    handler={this.publicHandler}
                />
            );
        }
        return parent;
    }

    createNPIOptions = () => {
        let parent = [];
        if (this.state.requestNPI == null && this.state.requestNPI == null) {
            parent.push(
                <Checkbox 
                    label={"Require"}
                    checked={false}
                    key={this.state.requireNPIKey}
                    handler={this.requireNPIHandler}
                />,
                <Checkbox 
                    label={"Request"}
                    checked={false}
                    key={this.state.requestNPIKey}
                    handler={this.requestNPIHandler}
                />,
                <Checkbox 
                    label={"No NPI"}
                    checked={false}
                    key={this.state.noNPIKey}
                    handler={this.noNPIHandler}
                />
            )
        } else {
            parent.push(
                <Checkbox 
                    label={"Require"}
                    checked={this.state.requireNPI}
                    key={this.state.requireNPIKey}
                    handler={this.requireNPIHandler}
                />,
                <Checkbox 
                    label={"Request"}
                    checked={this.state.requestNPI}
                    key={this.state.requestNPIKey}
                    handler={this.requestNPIHandler}
                />,
                <Checkbox 
                    label={"No NPI"}
                    checked={(!this.state.requireNPI && !this.state.requestNPI) }
                    key={this.state.noNPIKey}
                    handler={this.noNPIHandler}
                />
            )
        }
        return parent;
    }

    createMinStayOptions = () => {
        let parent = [];
        if (this.state.minStay != null) {
            parent.push(
                <View style={styles.flexWrapper}>
                    <Checkbox 
                        label={"Yes"}
                        checked={this.state.minStay}
                        key={this.state.minStayYesKey}
                        handler={this.minStayYesHandler}
                    />
                    <Checkbox 
                        label={"No"}
                        checked={!this.state.minStay}
                        key={this.state.minStayNoKey}
                        handler={this.minStayNoHandler}
                    />
                </View>
            );
            if (this.state.minStay) {
                parent.push(
                    <View style={styles.flexWrapper}>
                        <Stepper
                            count={this.state.minDays}
                            key={this.state.minDaysKey}
                            navigation={this.props.navigation}
                            handler={minDays => {
                                this.setState({
                                    minDays: minDays
                                })
                            }}
                        />
                        <Text>nights minimum</Text>
                    </View>
                )
            }
        } else {
            parent.push(
                <View style={styles.flexWrapper}>
                    <Checkbox 
                        label={"Yes"}
                        checked={false}
                        key={this.state.minStayYesKey}
                        handler={this.minStayYesHandler}
                    />
                    <Checkbox 
                        label={"No"}
                        checked={false}
                        key={this.state.minStayNoKey}
                        handler={this.minStayNoHandler}
                    />
                </View>
            );
        }
        return parent;
    }

    buildActionButton = () => {
        let parent = [];
        if (this.props.route.params.rentalData) {
            if (this.state.isBronze) {
                parent.push(
                    <ActionButton
                        text={"Save"}
                        handler={()=>{
                            this.bronzeSaveButtonPress()
                        }}
                    />
                );
            } else {
                parent.push(
                    <ActionButton
                        text={"Save"}
                        handler={()=>{
                            this.saveButtonPress()
                        }}
                    />
                );
            }
        } else if (this.state.isBronze) {
            parent.push(
                <ActionButton
                    text={"Next"}
                    handler={()=>{
                        this.bronzeCheckOut()
                    }}
                />
            );
        } else {
            parent.push(
                <ActionButton
                    text={"Next"}
                    handler={()=>{
                        this.nextButtonPress()
                    }}
                />
            );
        }
        return parent;
    }

    createAutoBookOption = () => {
        let parent = [];
        if (this.state.autoBook == null) {
            parent.push(
                <Checkbox 
                    label={"Yes"}
                    checked={false}
                    key={this.state.autoBookKey}
                    handler={this.autoBookHandler}
                />,
                <Checkbox 
                    label={"No"}
                    checked={false}
                    key={this.state.noAutoBookkey}
                    handler={this.autoBookNoHandler}
                />  
            );
        } else {
            parent.push(
                <Checkbox 
                    label={"Yes"}
                    checked={this.state.autoBook}
                    key={this.state.autoBookKey}
                    handler={this.autoBookHandler}
                />,
                <Checkbox 
                    label={"No"}
                    checked={!this.state.autoBook}
                    key={this.state.noAutoBookkey}
                    handler={this.autoBookNoHandler}
                />  
            );
        }
        return parent;
    }

    render() {
        return (
            <View style={{height: "100%"}}>
                <TopAlert text={"Booking Options Updated"} isShowing={this.state.successIsShowing}/>
                <ScrollView contentContainerStyle={styles.container}>
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.errorModalVisible}
                        key={this.state.errorModalKey}
                        onModalClose={this.onModalClose}
                        labelText={this.state.errorMessage}
                        isError={true}
                    />
                    {
                        !this.state.isBronze?
                        <Container title={"Enable AutoBooking"}>
                            <Text style={styles.sublabel}>Would you like to allow renters to reserve automatically without needing approval?</Text>
                            <View style={styles.flexWrapper}>
                                {this.createAutoBookOption()}
                            </View>
                        </Container> :
                        null
                    }
                    {
                        !this.state.isBronze?
                        <Container title={"Publicly Rentable"}>
                            <Text style={styles.sublabel}>Would you like to rent to Docs only or allow the public to book?</Text>
                            <View style={styles.flexWrapper}>
                            {this.createPublicFeilds()}
                            </View>
                        </Container>:
                        <Container title={"Public Listing"}>
                            <Text style={styles.sublabel}>Would you like your listing to be visible to the public?</Text>
                            <View style={styles.flexWrapper}>
                            {this.createPublicFeilds()}
                            </View>
                        </Container>
                    }
                    {
                        !this.state.isBronze?
                        <Container title={"Require/ Request NPI"}>
                                <Text style={styles.sublabel}>Would you like to require or request NPI numbers from renters?</Text>
                                <View style={styles.flexWrapper}>
                                {this.createNPIOptions()}
                            </View>
                        </Container>:
                        null
                    }
                    {
                        !this.state.isBronze?
                        <Container title={"Minimum Stay"}>
                            <Text style={styles.sublabel}>Is there a minimum night stay?</Text>
                            {this.createMinStayOptions()}
                            <View>
                                {this.buildActionButton()}
                            </View>
                        </Container>:
                        <Container>
                            <View>{this.buildActionButton()}</View>
                        </Container>
                    }
                </ScrollView>
            </View>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerLabeltext: {
        fontSize: 24,
        marginBottom: 20,
        marginTop: 50,
        fontWeight: "700",
        textAlign: "center",
    },
    mt20: {
        marginTop: 20,
    },
    centeredText: {
        textAlign: "center",
    },
    payment: {
        width: 500,
        height: 600,
    },
    subscriptionWrapper: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    },
    flexWrapper: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    subscriptionTypeWrapper: {
        width: 180,
        borderWidth: 1,
        borderColor: "lightgrey",
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        margin: 5,
        padding: 10,
    },
    subscriptionTypeWrapperSelected: {
        width: 180,
        borderWidth: 5,
        borderColor: "blue",
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        margin: 5,
        padding: 10,
    },
    subscriptionTypeHeaderText: {
        fontSize: 12,
        fontWeight: "600",
        textAlign: "left",
        width: "100%",
        paddingLeft: 15,
    },
    subscriptionLevelIcon: {
        width: 60,
        height: 60,
    },
    dot: {
        fontSize: 5,
    },
    dotWrapper: {
        width: 20,
        paddingBottom: 5,
        position: "absolute",
        top: 3,
    },
    optionWrapper: {
        width: "100%",
    },  
    subHeaderLabel: {
        fontSize: 14,
        fontWeight: "600",
        textAlign: "center",
    },
    button: {
        marginTop: 10,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    label: {
        margin: 5,
        marginTop: 10,
        fontSize: 12,
        fontWeight: "600",
    },
    sublabel: {
        paddingBottom: 0,
    },
})