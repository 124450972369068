import React, { Component } from 'react';
import { StyleSheet, Text, View, Image, ScrollView, Platform } from 'react-native';
import { getAuth, onAuthStateChanged, } from "firebase/auth";

import ListingRetreiver from "../helpers/listing-retreiver";
import { userIsPhysician } from '../helpers/public-user-helper';
import FilterBar from '../compenents/FilterBar';

import Modal from "../compenents/Modal";
import Loader from "../compenents/Loader";
import Footer from '../compenents/Footer.native';
import Feed from "../compenents/Feed";

export default class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rentalData: null,
            errorModalVisible: false, 
            errorModalKey: Math.random().toString(36).substring(7),
            lastIndex: 0, 
            showMoreIsShowing: true,
            loaderIsShowing: false,
            loaderKey: Math.random().toString(36).substring(7),
            filterData: null,
            filterViewKey: Math.random().toString(36).substring(7),
            user: null,
            userIsDoc: false,
        };
    } 

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            //setUpView()
        });
        this.setUpView();
    }

    setUpView = () => {
        if (!!this.props.route.params) {
            if (!!this.props.route.params.filterData) {
                this.setState({
                    filterData: this.props.route.params.filterData,
                    filterViewKey: Math.random().toString(36).substring(7),
                }, ()=>{
                    this.getUser(); 
                }) 
            } else {
                this.getUser(); 
            }
        } else {
            this.getUser(); 
        }
    }

    getUser = async () => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                if (!user.isAnonymous) {
                    await this.userIsDoc(user);
                    this.setState({ user });
                } else {
                    this.setState({ 
                        user: null, 
                        userIsDoc: false, 
                    });
                }
            } else {
                this.setState({ 
                user: null, 
                userIsDoc: false, 
                });
            }
            this.confirgureView();
        });
      }
    
    userIsDoc = async (user) => {
        let userIsDoc = await userIsPhysician(user.uid);
        this.setState({
            userIsDoc: userIsDoc,
        });
    }

    confirgureView = () => {
        if (!!this.props.route.params) {
            if (this.props.route.params.filterData) {
                this.setState({
                    filterData: this.props.route.params.filterData,
                },() => {
                    this.props.navigation.addListener('willFocus', this.getSearchData())
                });
            } else {
                this.props.navigation.addListener('willFocus', this.getSearchData())
            }
        }
    }

    getSearchData = async () => {
        this.setState({
            loaderIsShowing: true,
            loaderKey: Math.random().toString(36).substring(7),
        });
        let lastPostKey = null;
        let lastSubscriptionLevel = null;

        if (!!this.state.rentalData) {
            lastPostKey = this.state.rentalData[Object.keys(this.state.rentalData)[Object.keys(this.state.rentalData).length - 1]].key;
            if (!!this.state.rentalData && !!lastPostKey) {
                lastSubscriptionLevel = this.state.rentalData[lastPostKey].subscriptionLevel;
            }
        }
        let listingRetreiver = new ListingRetreiver();
        let data = await listingRetreiver.getReccomendedListings(
            this.state.filterData, this.state.userIsDoc, lastPostKey, lastSubscriptionLevel
        );
        if (this.state.rentalData == null) {
            let newLastIndex = Object.keys(data).length;
            this.setState({
                rentalData : data,
                lastIndex: newLastIndex,
                loaderIsShowing: false,
                loaderKey: Math.random().toString(36).substring(7),
            });
        } else {
            let newData = {...this.state.rentalData, ...data};
            let newLastIndex = Object.keys(newData).length;
            this.setState({
                rentalData : newData,
                lastIndex: newLastIndex,
                loaderIsShowing: false,
                loaderKey: Math.random().toString(36).substring(7),
            });
        }
        this.toggleShowMoreButton();
    }

    toggleShowMoreButton = () => {
        if (this.state.filterData != null) {
            if (
                this.state.filterData.location != null ||
                this.state.filterData.keyword != null
            ) {
                this.setState({
                    showMoreIsShowing: false,
                });
            } else {
                this.setState({
                    showMoreIsShowing: true,
                });
            }
        } else {
            this.setState({
                showMoreIsShowing: true,
            });
        }
    }

    onModalClose = () => {
        this.setState({errorModalVisible: false})
    }

    onFilterComplete = (filterData) => {
        //clear all data and search again
        this.setState({
            filterData: filterData,
            rentalData: null,
            lastIndex: 0,
        }, () => this.getSearchData());
        if (!!this.props.route.params.homeCallback) {
            this.props.route.params.homeCallback(filterData);
        }
    }

    handleFilterPress = () => {
        let params = {
            screen: 'Filters',
            params: {
                filterData: this.state.filterData,
                searchCallBack: this.onFilterComplete
            }
        }
        this.props.navigation.navigate("Filters", params);
    }

    createFilterView = () => {
        let parent = [];
        parent.push(
            <View style={{
                width: "100%",
                borderBottomColor: "lightgrey",
                borderBottomWidth: 1,
            }}
            >
                <FilterBar
                    onFilterChange={(filterData)=>{
                        this.onFilterComplete(filterData)
                    }}
                    data={this.state.filterData}
                    key={this.state.filterViewKey}
                />
            </View>
        )
        return parent;
    }

    render() {
        return (
            <View style={{width: "100%", height: "100%", backgroundColor: "light grey"}}>
                <ScrollView contentContainerStyle={[styles.mainContainer]}>
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.errorModalVisible}
                        key={this.state.errorModalKey}
                        onModalClose={this.onModalClose}
                        labelText={"Please enter a location to search"}
                        isError={true}
                    />
                    <Loader 
                        visible={this.state.loaderIsShowing}
                        key={this.state.loaderKey}  
                    />
                    {this.createFilterView()}
                    <Feed 
                        listingData={this.state.rentalData} 
                        navigation={this.props.navigation}
                        showMoreIsShowing={this.state.showMoreIsShowing}
                        showMoreCallback={() => this.confirgureView()}
                        colums={2}
                    />
                    <Footer hideText={true}/>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    viewWrapper: {
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        borderBottomWidth: 20,
        borderBottomColor: "#9a9a9a",
    },
    thumbnail: {
        width: "100%",
        height: 450,
        maxWidth: 900,
        backgroundColor: "#f5f5f5",
        zIndex:15,
        position: "absolute",
        top: 0,
    },
    searchWrapper: {
        width: "100%",
        height: 40,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    searchButton: {
        width:40,
        height:40,
        justifyContent: 'center',
        marginLeft:10,
    },
    filterButton: {
        width:60,
        height:60,
        justifyContent: 'center',
        position: "absolute",
        right: "8%",
        top: 33
,    },
    filterImageWraper: {
        height:58,
        width: 58,
        padding: 10,
    },
    searchIcon: {
        width:35,
        height:35,
    },
    filterIcon: {
        width:38,
        height:38,
    },
    searchInput: {
        height: 40,
        borderColor: 'gray',
        borderWidth: 0.5,
        zIndex: 10,
        backgroundColor: "white",
        borderRadius: 10,
        width: "60%",
        maxWidth: 1200,
        textAlign: "center"
    },
    inputBox: {
        width: '85%',
        margin: 10,
        padding: 15,
        fontSize: 16,
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        textAlign: 'center',
        backgroundColor: "#f5f5f5",
        maxWidth: 600,
        height: 50,
    },
    button: {
        marginTop: 20,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonBlue: {
        marginBottom: 20,
        paddingVertical: 5,
        marginTop: 40,
        alignItems: 'center',
        backgroundColor: '#3a93f1',
        borderColor: '#2790ff',
        borderWidth: 1,
        borderRadius: 5,
        width: 200,
        margin: "auto",
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    buttonSignup: {
        fontSize: 12
    },
    headerLabeltext: {
        fontSize: 24,
        marginTop: 50,
        fontWeight: "700",
        textAlign: "center",
    },
    noListingsLabel: {
        fontSize: 24,
        marginBottom: 250,
        marginTop: 100,
        fontWeight: "700",
        textAlign: "center",
    },
    propertyTitleText: {
        fontSize: 24,
        marginBottom: 0,
        fontWeight: "700",
        textAlign: "center",
        marginTop: 20,
        backgroundColor: "white",
        zIndex: 15,
    },
    amentitesContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        margin:10,
        backgroundColor: "white"
      },
      amentitiesIcon: {
        height: 35,
        width:35,
        margin: 5,
      },
    bedBathWrapper: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'stretch',
    },
    mt20: {
        marginTop: 20,
    },
    searchTableWrapper: {
        width: "100%",
    },
    mainContainer: {
        minHeight: "100%",
    },
    loadingImage: {
        zIndex: 15,
        position: "absolute",
        height: 150,
        marginTop: "40%",
    },
    previewImageWrapper: {
        width:"100%",
        marginBottom: 20,
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    activityIndicatorWrapper: {
        position: "absolute",
        flex: 1,
        width: "100%",
        maxWidth: 1200,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
    },
    activityIndicator: {
        zIndex: 1,
    },
    locationLabel: {
        color: "grey",
        padding: 10
    }, 
    adWrapper: {
        borderBottomWidth: 20,
        borderBottomColor: "#9a9a9a",
    },
    adWidget: {
        height: 500,
        margin: 10,
    },
    filterView: {
        position: "absolute",
        bottom: -15,
        width: "100%",
        height: 75,
        backgroundColor: "white",
        zIndex: 20,
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        borderTopColor: "grey",
        borderTopWidth: 0.5,
        paddingBottom: 0,
        marginBottom: 0,
        
      },
      filterLabel: {
        fontSize: 16,
        fontWeight: "600",
        textAlign: "center",
      }
})