import React from 'react';

import { Platform, Image, StyleSheet, View, TouchableHighlight } from 'react-native';
import { DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';

export default function CustomDrawerContent(props) {

    const { state, ...rest } = props;
    const newState = { ...state };
    newState.routes = newState.routes.filter(
      (item) => 
        item.name !== 'Check Out' &&
        item.name != "Post Property" &&
        item.name != "Subscription" &&
        item.name != "Pricing" &&
        item.name != "Special Options" &&
        item.name != "CheckIn Instructions" &&
        item.name != "Public Property" &&
        item.name != "Calendar View" &&
        item.name != "Create Reservation" &&
        item.name != "Write Review" &&
        item.name != "Read Reviews" &&
        item.name != "Report" &&
        item.name != "Success"
    );

    const navigateToIosApp = () => {
        window.open("https://apps.apple.com/us/app/doc-to-doc-rentals/id1546290314", "_blank");
    }

    const navigateToAndroidApp = () => {
        window.open("https://play.google.com/store/apps/details?id=com.doc2docrental.doc2docrental&fbclid=IwAR03iWvylXgP0L8cE5MtbH3NNPuzHBLNtZkayaHh9KAwxIMjlgLGvvIrU-s", "_blank");
    }

    if (Platform.OS == "web") {
        return (
            <DrawerContentScrollView {...props}>
                {/* <View style={{height: 60}}/> */}
                <Image 
                    source={require('../assets/horizontal-logo.png')}
                    style={styles.logo}
                    resizeMode="contain"
                />
                <DrawerItemList state={newState} {...rest} />
                <View style={styles.appStoreWrapper}>
                  <TouchableHighlight onPress={navigateToIosApp}>
                      <Image 
                          source={require('../assets/ios-app-icon.png')}
                          style={styles.iconWrapper}
                      />
                  </TouchableHighlight>
                  <TouchableHighlight onPress={navigateToAndroidApp}>
                      <Image 
                        source={require('../assets/google-play-app-icon.png')}
                        style={styles.iconWrapper}
                      />
                  </TouchableHighlight>
                </View>
            </DrawerContentScrollView>
          );
    } else {
        return (
            <DrawerContentScrollView {...props}>
              <View style={{height: 60}}/>
              <DrawerItemList state={newState} {...rest} />
            </DrawerContentScrollView>
          );
    }

  }
  
  const styles = StyleSheet.create({
    appStoreWrapper: {
      width: "100%",
      height: 140,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    iconWrapper: {
      height: 56,
      width: 200,
    },
    logo: {
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 10,
      width: 230,
      height: 40,
    }
  });