import React, { Component } from 'react';
import { StyleSheet, Text, Image, TouchableHighlight, View, ActivityIndicator } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Ripple from 'react-native-material-ripple';

export default class ActionButton extends Component {

    buildIcon = () => {
        let parent = [];
        let indicatorColor = "white";
        if (this.props.filled) {
            indicatorColor = "#5d51ef";
        }
        if (this.props.icon != null) {
            if (this.props.loading) {
                parent.push(
                    <ActivityIndicator size="small" color={indicatorColor}/>
                );
            } else {
                parent.push(
                    <Image 
                        style={styles.icon}
                        source={this.props.icon}
                    />
                );
            }
        } else {
            if (this.props.loading) {
                parent.push(
                    <ActivityIndicator size="small" color={indicatorColor}/>
                );
            } 
        }
        return parent;
    }

    buttonPress = () => {
        if (!!this.props.handler) {
            this.props.handler();
        }
    }

    buildView = () => {
        let parent = [];
        let gradientStyles = [styles.gradient];
        let colors = ['#ab5cf7', '#5d51ef', '#53a4e8'];
        let disabled = false;
        if (this.props.loading) {
            colors = [
                'rgba(171, 92, 247, 0.8)',
                'rgba(93, 81, 239, 0.8)',
                'rgba(83, 164, 232, 0.8)',
            ];
            disabled = true;
        }
        if (this.props.size != null) {
            switch(this.props.size) {
                case "small":
                   gradientStyles.push(styles.small);
                  break;
                case "medium":
                    gradientStyles.push(styles.medium);
                  break;
                case "large":
                    gradientStyles.push(styles.large);
                  break;
                default:
                    gradientStyles.push(styles.large);
              }
        } else {
            gradientStyles.push(styles.large);
        }
        if (this.props.filled != null) {
            if (this.props.filled) {
                let filledButtonStyle = [styles.filledButtonWrapper];
                parent.push(
                    <Ripple 
                        onPress={this.buttonPress}
                        style={styles.buttonNoShadow}
                        disabled={disabled}>
                        <LinearGradient
                            colors={colors}
                            locations={[0.0, 0.7, 1.0]}
                            start={{ x: 0.1, y: 0.2 }}
                            end={{ x: 0.9, y: 1.0 }}
                            style={gradientStyles}>
                            <View style={filledButtonStyle}>
                                <View>
                                    <Text allowFontScaling={false} style={styles.filledButtonText}>
                                        {!!this.props.text? this.props.text: ""}
                                    </Text>
                                    {this.buildIcon()}
                                </View>
                            </View>
                        </LinearGradient>
                    </Ripple>
                );
            } else {
                parent.push(
                    <Ripple 
                        onPress={this.buttonPress}
                        style={styles.button}
                        disabled={disabled}>
                        <LinearGradient
                            colors={colors}
                            locations={[0.0, 0.7, 1.0]}
                            start={{ x: 0.1, y: 0.2 }}
                            end={{ x: 0.9, y: 1.0 }}
                            style={gradientStyles}>
                            <View style={{display: "flex", alignItems: "center", justifyContent: "center",flexDirection: "row"}}>
                                <Text allowFontScaling={false} style={styles.buttonText}>{this.props.text}</Text>
                                <View style={{width: 10}}/>
                                {this.buildIcon()}
                            </View>
                        </LinearGradient>
                    </Ripple>
                );
            }
        } else if (this.props.isText != null && this.props.isText) {
            parent.push(
                 <TouchableHighlight 
                    onPress={this.buttonPress}
                >
                    <Text allowFontScaling={false} style={styles.filledButtonTextBlack}>{this.props.text}</Text>
                </TouchableHighlight>
            );
        } else {
            parent.push(
                <Ripple 
                    onPress={this.buttonPress}
                    style ={styles.button}
                    disabled={disabled}>
                    <LinearGradient
                        colors={colors}
                        locations={[0.0, 0.7, 1.0]}
                        start={{ x: 0.1, y: 0.2 }}
                        end={{ x: 0.9, y: 1.0 }}
                        style={gradientStyles}>
                        <View style={{display: "flex", alignItems: "center", justifyContent: "center",flexDirection: "row"}}>
                            <Text allowFontScaling={false} style={styles.buttonText}>{this.props.text}</Text>
                            <View style={{width: 10}}/>
                            {this.buildIcon()}
                        </View>
                    </LinearGradient>
                </Ripple>
            );
        }
        return parent;
    }

    render() {
        return (        
            <View>
                { this.buildView() }
            </View>
        )
    }
}

const styles = StyleSheet.create({
  button: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
    shadowOffset: {
        width: 0,
        height: 2,
      },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 3,
    borderRadius: 10,
    margin: 3,
  },
  buttonNoShadow: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
    borderRadius: 10,
    margin: 3,
  },
  gradient: {
    borderRadius: 10,
    // width: 230,
    textAlign: "center",
    height: 40,
    paddingTop: 8,
    width: "100%",
    minWidth: 100,
    paddingHorizontal: 2,
  },
  small: {
    minWidth: 50,
  },
  medium: {
    minWidth: 150,
  },
  large: {
    minWidth: 230,
  },
  filledButtonWrapper: {
    borderRadius: 8,
    // width: 226,
    textAlign: "center",
    height: 36,
    backgroundColor: "white",
    marginTop: -6,
    marginLeft: 0,
    paddingHorizontal: 10,
  },
  filledButtonMediumWrapper: {
    borderRadius: 8,
    width: 146,
    textAlign: "center",
    height: 36,
    backgroundColor: "white",
    marginTop: -6,
    marginLeft: 2
  },
  buttonText: {
      fontSize: 16,
      fontWeight: "700",
    color: "white",
    textAlign: "center",
  },
  filledButtonText: {
    color: "#5d51ef",
    fontSize: 16,
    fontWeight: "700",
    textAlign: "center",
    marginTop: 8,
  },
  icon: {
      width: 20,
      height: 20,
  },
  filledButtonTextBlack: {
    fontSize: 14,
    fontWeight: "600",
    textAlign: "center",
    marginTop: 8,
    color: "black",
    height: 30,
  },
});
