import React, { Component } from 'react';
import { View, StyleSheet, Image } from 'react-native';
import {Calendar, CalendarList, Agenda} from 'react-native-calendars';
 
export default class CalendarComponent extends Component {

  constructor(props) {
    super(props);
    const todayDate = new Date();
    let month = todayDate.getUTCMonth()+1;
    let day = todayDate.getUTCDate();
    month >= 10 ? month = todayDate.getMonth()+1: month = `0${todayDate.getMonth()+1}`;
    day >= 10 ? day = todayDate.getUTCDate(): day = `0${todayDate.getUTCDate()}`;
    const todayDateString = todayDate.getUTCFullYear()+"-"+month +"-"+day
    this.state = {
      blackOutDates: null,
      calendarKey: Math.random().toString(36).substring(7),
      selectedMonth: todayDateString,
      firstCalendarKey: Math.random().toString(36).substring(7),
      secondCalendarKey: Math.random().toString(36).substring(7),
    };
  } 

  componentDidMount() {
    this.loadView();
  }

  loadView = () => {
    if (this.props.blackOut) {
      this.setState({
        blackOutDates: this.props.blackOut,
        calendarKey: Math.random().toString(36).substring(7),
      })
    } 
  }

  onDayPress = (day) => {
    if (this.state.blackOutDates === null) {
      const blackOutDates = {};
      blackOutDates[day.dateString] = { "disabled": true };
      this.setState({ "blackOutDates": blackOutDates });
      if (this.props.callback) {
        this.props.callback(blackOutDates);
      }
    } else {
      //check if day is already on list and remove if yes
      let newBlackout = this.state.blackOutDates;
      if (newBlackout[day.dateString] !== undefined) {
        delete newBlackout[day.dateString];
      } else {
        newBlackout[day.dateString] = { "disabled": true };
      }

      this.setState({ "blackOutDates": newBlackout, calendarKey: Math.random().toString(36).substring(7)});
      if (this.props.callback) {
        this.props.callback(newBlackout);
      }
    }
  }

  render() {
    const todayDate = new Date();
    let month = todayDate.getUTCMonth()+1;
    let day = todayDate.getUTCDate();
    month >= 10 ? month = todayDate.getMonth()+1: month = `0${todayDate.getMonth()+1}`;
    day >= 10 ? day = todayDate.getUTCDate(): day = `0${todayDate.getUTCDate()}`;
    const todayDateString = todayDate.getUTCFullYear()+"-"+month +"-"+day
    if (this.props.editable) {
      return(
        <View style={styles.container}>
          <Calendar
            horizontal={true}
            style={styles.calendar}
            markedDates={this.state.blackOutDates}
            key={this.state.calendarKey}
            onDayPress={this.onDayPress}
            minDate={todayDateString}
            enableSwipeMonths={true}
            theme={{
              backgroundColor: 'white',
            }}
            renderArrow={(direction) => direction === 'left' ? 
            <Image
                source={require('../assets/backArrowIcon.png')}
                style={styles.leftIcon}
            ></Image> : 
            <Image
                source={require('../assets/forwardArrowIcon.png')}
                style={styles.rightIcon}
            ></Image>}
            onMonthChange={(month) => {
              this.setState({selectedMonth: month.dateString});
            }}
            current={this.state.selectedMonth}
          />
        </View>
      )
    } else {
      let startDate = new Date();
      if (!!this.state.selectedMonth) {
        startDate = new Date(`${this.state.selectedMonth}`);
      }
      //add day to fix bug
      startDate.setDate(startDate.getDate()+1);
      let startMonth = startDate.getMonth()+1;
      startMonth >= 10 ? startMonth = startDate.getMonth()+1: startMonth = `0${startDate.getMonth()+1}`;
      let startString = `${startDate.getFullYear()}-${startMonth}-01`;
      let endDate = new Date(startDate.setMonth(startDate.getMonth()+2));
      let endMonth = endDate.getMonth()
      endMonth >= 10 ? endMonth = endDate.getMonth(): endMonth = `0${endDate.getMonth()}`;
      let endDateString = `${endDate.getFullYear()}-${endMonth}-01`;
      return(
        <View style={styles.flexBoxWrapper}>
           <View style={{width: "50%", minWidth: 300, padding: 10, paddingTop: 0, flex: 1}}>
            <Calendar
              key={this.state.firstCalendarKey}
              current={startString}
              horizontal={true}
              style={styles.smallCalendar}
              markedDates={this.props.blackOut}
              minDate={todayDateString}
              theme={{
                calendarBackground: 'white',
              }}
              renderArrow={(direction) => direction === 'left' ? 
              <Image
                  source={require('../assets/backArrowIcon.png')}
                  style={styles.leftIcon}
              ></Image> : 
              null}
              onMonthChange={(month) => {
                this.setState({
                  selectedMonth: month.dateString,
                  firstCalendarKey: Math.random().toString(36).substring(7),
                  secondCalendarKey: Math.random().toString(36).substring(7),
                });
              }}
            />
           </View>
           <View style={{width: "50%", minWidth: 300, padding: 10, paddingTop: 0, flex: 1}}>
            <Calendar
              // initialDate={endDateString}
              key={this.state.secondCalendarKey}
              current={endDateString}
              horizontal={true}
              style={styles.smallCalendar}
              markedDates={this.props.blackOut}
              minDate={todayDateString}
              theme={{
                calendarBackground: 'white',
              }}
              renderArrow={(direction) => direction === 'left' ? 
              null: 
              <Image
                  source={require('../assets/forwardArrowIcon.png')}
                  style={styles.rightIcon}
              ></Image>}
              onMonthChange={(month) => {
                this.setState({
                  selectedMonth: month.dateString,
                  firstCalendarKey: Math.random().toString(36).substring(7),
                  secondCalendarKey: Math.random().toString(36).substring(7),
                });
              }}
            />
           </View>
        </View>
      )
    }
  }
};
 
const styles = StyleSheet.create({
  container: { 
    height: 400,
    width:"100%",
    backgroundColor: "white",
  },
  flexBoxWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
},
  calendar: {
    height: 400,
    backgroundColor: "white",
  },
  leftIcon: {
    width:40,
    height:20,
  },
  rightIcon: {
    width:40,
    height:20,
  }
});