import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, TouchableHighlight, Image, Platform, Dimensions } from 'react-native';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get, set } from "firebase/database";
import { createStackNavigator } from '@react-navigation/stack';

import MessageView from './Message';
import PublicProfile from './PublicProfile';

import {filterOutput} from "../helpers/output-formatter";

import Container from "../compenents/Container";
import ActionButton from "../compenents/ActionButton";

const Stack = createStackNavigator();

export default function MyMessagesWrapper() {
    return (
      <Stack.Navigator>
        <Stack.Screen
          name="My Messages"
          component={MyMessages}
          options={{ headerShown: false }}
        />
        <Stack.Screen 
          name="Message" 
          component={MessageView}
        />
        <Stack.Screen 
          name="Public Profile" 
          component={PublicProfile}
        />
      </Stack.Navigator>
    );
}


class MyMessages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            myMessagesData: null,
            messagedUsersData: null,
            width:  Dimensions.get('window').width 
        }
    }

    componentDidMount() {
        this.getUser(); 
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.props.navigation.getParent().setOptions({headerShown: true});
            this.getMyMessages(); 
        });
        if (Platform.OS == "web") {
            window.addEventListener('resize', this.updateDimensions);
        }
    }

    getUser = () => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user }, ()=>{
                    this.getMyMessages(); 
                });
            }
        });
    }

    updateDimensions = () => {
        if (!!this.props.navigation) {
            if (this.props.navigation.isFocused()) {
              this.setState({ width:  Dimensions.get('window').width });
            }
        }
    }

    getMyMessages = async () => {
        if (!!this.state.user) {
            const db = getDatabase();
            const myMessagesRef = ref(db, `myMessages/${this.state.user.uid}`);
            let snapshot = await get(myMessagesRef);
            let myMessagesData = snapshot.val();
            let messagedUsersData = null;
            if (myMessagesData != null) {
                for (let index in myMessagesData) {
                    let profile = await this.getProfileDataForID(index)
                    if (!!profile) {
                        if (!!messagedUsersData) {
                            messagedUsersData[index] = profile;
                        } else {
                            messagedUsersData = {};
                            messagedUsersData[index] = profile;
                        }
                    }
                }
            }
            this.setState({
                myMessagesData: myMessagesData,
                messagedUsersData: messagedUsersData,
            })
        }
    }

    getProfileDataForID = async id => {
        if (this.state.messagedUsersData != null) {
            if (this.state.messagedUsersData[id] != null) {
                return this.state.messagedUsersData[id];
            }
        }
        const db = getDatabase();
        const profileRef = ref(db, `profiles/${id}`);
        let snapshot = await get(profileRef);
        let profileData = snapshot.val();
        if (!!profileData) {
            return profileData;
        } 
        return null;
    }

    buildProfileImage = (id) => {
        let parent = [];
        let photoUrl = "https://doc2doc-d54a2.web.app/media/userAvatar.png";
        if (!!this.state.messagedUsersData) {
            if (!!this.state.messagedUsersData[id]) {
                if (!!this.state.messagedUsersData[id].photoUrl) {
                    photoUrl = this.state.messagedUsersData[id].photoUrl;
                } 
            }
        }
        parent.push(
            <TouchableHighlight
                onPress={()=>{
                    this.props.navigation.getParent().setOptions({headerShown: false});
                    this.props.navigation.navigate("Public Profile", {
                        profileUID: id,
                    });
                }}
            >
                <Image
                    style={styles.userImage}
                    source={{
                        uri: photoUrl,
                    }}
                />
            </TouchableHighlight>
        )
        return parent;
    }

    buildName = (id) => {
        let parent = [];
        if (!!this.state.messagedUsersData) {
            if (!!this.state.messagedUsersData[id]) {
                if (!!this.state.messagedUsersData[id].firstName) {
                    parent.push(
                        <Text style={{fontWeight: "600"}}>
                            {this.state.messagedUsersData[id].firstName + " " + this.state.messagedUsersData[id].lastName}
                        </Text>  
                    );
                } else {
                    parent.push(
                        <Text style={{fontWeight: "600"}}>
                            No Name
                        </Text>  
                    );
                }
            } else {
                parent.push(
                    <Text style={{fontWeight: "600"}}>
                        No Name
                    </Text>  
                );
            }
        }
        return parent;
    }

    buildMessage = messageData => {
        let parent = [];
        if (messageData.read) {
            parent.push(
                <Text
                    style={{ paddingTop: 5}} 
                    numberOfLines={1}
                >{filterOutput(messageData.lastMessage)}</Text>
            )
        } else {
            parent.push(
                <Text 
                    style={{fontWeight: "600", paddingTop: 5}}
                    numberOfLines={1}
                >{filterOutput(messageData.lastMessage)}</Text>
            );
        }
        return parent;
    }

    buildDate = messageData => {
        let parent = [];
        let date = new Date(messageData.createdAt);
        parent.push(
            <Text style={{fontSize: 10, color: "grey"}}>
                {this.getDateString(date)}
            </Text>
        )
        return parent;
    }

    getDateString = (m) => {
        return ("0" + (m.getUTCMonth()+1)).slice(-2) + "/" +
        ("0" + m.getUTCDate()).slice(-2) + "/" +
        m.getUTCFullYear() + "  "+
        m.toLocaleTimeString()
    }

    buildActionButton = (id) => {
        let parent = [];   
        if (this.state.width > 600) {
            parent.push(
                <View style={{position: "absolute", right: 0, top: 5}}>
                    <ActionButton
                        text="Message"
                        handler={()=>{
                            this.props.navigation.getParent().setOptions({headerShown: false});
                            this.props.navigation.navigate("Message", {
                                recipientUID: id,
                                user: this.state.user,
                            })
                        }}
                        size={"medium"}
                    />
                </View>
            )
        }
        return parent;
    }

    buildMessageView = (index, messageData) => {
        let parent = [];
        if (this.state.width > 600 && this.state.width < 873) {
            parent.push(
                <TouchableHighlight style={{width: this.state.width - 260, maxWidth: 540 }}
                    onPress={()=>{
                        this.props.navigation.getParent().setOptions({headerShown: false});
                        this.props.navigation.navigate("Message", {
                            recipientUID: index,
                            user: this.state.user,
                        })
                    }}
                >
                    <View style={{marginLeft: 10,}}>
                        {this.buildName(index)}
                        {this.buildDate(messageData)}
                        {this.buildMessage(messageData)}
                    </View>
                </TouchableHighlight>
            );
        } else if (this.state.width > 600 && this.state.width >= 873) {
            parent.push(
                <TouchableHighlight style={{width: this.state.width - 580, maxWidth: 540 }}
                    onPress={()=>{
                        this.props.navigation.getParent().setOptions({headerShown: false});
                        this.props.navigation.navigate("Message", {
                            recipientUID: index,
                            user: this.state.user,
                        })
                    }}
                >
                    <View style={{marginLeft: 10,}}>
                        {this.buildName(index)}
                        {this.buildDate(messageData)}
                        {this.buildMessage(messageData)}
                    </View>
                </TouchableHighlight>
            );
        } else {
            parent.push(
                <TouchableHighlight style={{width: this.state.width - 100  }}
                    onPress={()=>{
                        this.props.navigation.getParent().setOptions({headerShown: false});
                        this.props.navigation.navigate("Message", {
                            recipientUID: index,
                            user: this.state.user,
                        })
                    }}
                >
                    <View style={{marginLeft: 10,}}>
                        {this.buildName(index)}
                        {this.buildDate(messageData)}
                        {this.buildMessage(messageData)}
                    </View>
                </TouchableHighlight>
            );
        }
        return parent;
    }

    buildView = () => {
        let parent = [];
        if (!!this.state.myMessagesData) {
            for (let index in this.state.myMessagesData) {
                let messageData = this.state.myMessagesData[index];
                parent.push(
                    <Container>
                        <View style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", width: "100%"}}>
                            {this.buildProfileImage(index)}
                            {this.buildMessageView(index, messageData)}
                            {this.buildActionButton(index)}
                        </View>
                    </Container>

                )
            }
        }
        return parent;
    }

    render() {
        return (
            <View style={{height: "100%"}}>
                <ScrollView contentContainerStyle={styles.container}>
                    {this.buildView()}
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        marginTop: 10,
        fontWeight: "600",
        textAlign: "center",
    },
    userImage: {
        width: 60, 
        height: 60,
        borderRadius: 30,
        paddingHorizontal: 10,
    },  
})