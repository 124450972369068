
import React, { Component } from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';

export default class GoldInfo extends Component {
    render() {
        return (
            <View style={styles.subscriptionTypeWrapper}>
                <Text style={styles.subHeaderLabel}>Gold</Text>
                <Image 
                    source={require('../assets/goldIcon.png')}
                    style={styles.subscriptionLevelIcon}
                />
                <View style={styles.optionWrapper}>
                    <View style={styles.dotWrapper}><Text allowFontScaling={false} style={styles.dot}>{'\u2B24'}</Text></View>
                    <Text style={styles.subscriptionTypeHeaderText}>
                        $25 per month
                    </Text>
                </View>
                <View style={styles.optionWrapper}>
                    <View style={styles.dotWrapper}><Text allowFontScaling={false} style={styles.dot}>{'\u2B24'}</Text></View>
                    <Text style={styles.subscriptionTypeHeaderText}>
                        Ability to book through app with no fee
                    </Text>
                </View>
                <View style={styles.optionWrapper}>
                    <View style={styles.dotWrapper}><Text allowFontScaling={false} style={styles.dot}>{'\u2B24'}</Text></View>
                    <Text style={styles.subscriptionTypeHeaderText}>
                        Link to your website and email
                    </Text>
                </View>
            </View>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',

    },
    mt20: {
        marginTop: 20,
    },
    centeredText: {
        textAlign: "center",
    },
    payment: {
        width: 500,
        height: 600,
    },
    subscriptionWrapper: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "100%",
        padding: 10,
    },
    subscriptionTypeWrapper: {
        width: 200,
        borderWidth: 1,
        borderColor: "lightgrey",
        borderRadius: 6,
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
        marginHorizontal: 5,
    },
    subscriptionTypeWrapperSelected: {
        borderWidth: 5,
        borderColor: "#5d51ef",
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
        marginHorizontal: 5,
    },
    subscriptionTypeHeaderText: {
        fontSize: 12,
        fontWeight: "600",
        textAlign: "left",
        width: "100%",
        paddingLeft: 15,
    },
    subscriptionLevelIcon: {
        width: 60,
        height: 60,
    },
    dot: {
        fontSize: 5,
    },
    dotWrapper: {
        width: 20,
        paddingBottom: 5,
        position: "absolute",
        top: 3,
    },
    optionWrapper: {
        width: "100%",
    },  
    subHeaderLabel: {
        fontSize: 18,
        fontWeight: "600",
        textAlign: "center",
    },
    button: {
        marginTop: 20,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    label: {
        margin: 5,
        marginTop: 10,
        fontWeight: "600",
    },
})
