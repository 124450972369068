const email_regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

export const removeEmail = text => {
    return text.replace(email_regex,'⚫⚫⚫⚫⚫@⚫⚫⚫.⚫⚫⚫');
}

export const removePhone = text => {
    var correction = [
        /\d{3}[-.\s]?\d{3}[-.\s]?\d{4}/g,
        /\(\d{3}\)[-.\s]?\d{3}[-.\s]?\d{4}/g,
        /^\D*(\d\D*){10}$/g,
    ];
    correction.map(regEx => {
        text = text.replace(regEx, "⚫⚫⚫-⚫⚫⚫-⚫⚫⚫⚫");
    })
    return text;
}

export const filterOutput = text => {
    let noEmail = removeEmail(text);
    return removePhone(noEmail);
}

export const formatTime = date => {
    let hours = date.getHours();
    let minutes = `${date.getMinutes()}`;
    let suffix = hours >= 12? 'pm' : 'am';
    hours = hours > 12? hours -12 : hours;
    hours = hours == '00'? 12 : hours;
    minutes = minutes.length === 2? minutes: `0${minutes}`;
    return `${hours}:${minutes}${suffix}`;
}