import { useEffect, useState } from 'react';
import { 
    Platform, 
    StyleSheet, 
    View, 
    Text,
    useColorScheme, 
    TouchableOpacity,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { getFunctions, httpsCallable } from "firebase/functions";
import { requestTrackingPermissionsAsync } from 'expo-tracking-transparency';
import { 
    getAuth, signInWithCredential,
    linkWithCredential, onAuthStateChanged, 
    signInWithEmailAndPassword, fetchSignInMethodsForEmail, 
    sendPasswordResetEmail, createUserWithEmailAndPassword
} from 'firebase/auth';

import Modal from "../compenents/Modal";
import Container from "../compenents/Container";
import Logo from "../compenents/Logo";
import CheckBox from '../compenents/CheckBox';
import Footer from "../compenents/Footer";
import ActionButton from '../compenents/ActionButton';
import FbSignUpButton from "../compenents/FbSignUpButton";
import StyledTextInput from '../compenents/StyledTextInput';
import ExpandableView from "../compenents/ExpandableView";
import AppleSignUpButton from '../compenents/AppleSignUpButton';
import TopAlert from "../compenents/TopAlert";

const validEmailRegex = RegExp(
	/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export default function SignUpLogIn(props) {

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [yesChecked, setYesChecked] = useState(false);
    const [noChecked, setNoChecked] = useState(true);
    const [fullName, setFullname] = useState(null);
    const [npi, setNPI] = useState(null);
    const [medSchool, setMedSchool] = useState(null);
    const [currentPractice, setCurrentPractice] = useState(null);
    const [physicianStatusError, setPhysicianStatusError] = useState();
    const [errorModalVisible, setErrorModalVisible] = useState();
    const [errorModalText, setErrorModalText] = useState();
    const [resetModalVisible, setResetModalVisible] = useState(false);
    const [topAlerShowing, setTopAlerShowing] = useState(false);

    const [authObject, setAuthObject] = useState();
    const [showSignUpQuestions, setShowSignUpQuestions] = useState();
    const [showAdditionalPassword, setShowAdditionalPassword] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [emailError, setEmailError] = useState();
    const [passwordError, setPasswordError] = useState();
    const [fullNameError, setFullNameError] = useState(false);
    const [npiError, setNpiError] = useState(false);
    const [medSchoolError, setMedSchoolError] = useState(false);
    const [currentPracticeError, setCurrentPracticeError] = useState(false);
    const [additionalPasswordError, setAdditionalPasswordError] = useState();
    const [additionalPassword, setAdditionalPassword] = useState();

    useEffect(()=>{
        requestTracking();
    }, [props]);

    const requestTracking = async () => {
        if (Platform.OS == "ios") {
            const { status } = await requestTrackingPermissionsAsync();
        }
    }

    const validPassword = (password) => {
        if (password.length < 8) {
            return false
        }
        if (password.search(/[a-z]/i) < 0) {
            return false;
        }
        if (password.search(/[0-9]/) < 0) {
            return false
        }
        return true;
    }

    const validateEmailAndPassword = () => {
        let allInputValid = true;
        if (email === null || email === "" || email === " "){
            allInputValid = false;
            setEmailError("Please enter an email");
        } else {
            if (validEmailRegex.test(email)) {
                setEmailError(null);
            } else {
                allInputValid = false;
                setEmailError("Please enter a valid email");
            }
        }
        if (password === undefined || password === "" || password === " ") {
            allInputValid = false;
            setPasswordError( "Please enter a password")
        } else {
            if (validPassword(password)) {
                setPasswordError(null);
            } else {
                allInputValid = false;
                setPasswordError("Passwords must contain at least 8 letters, one number, and one upper case letter, and no special characters");
            }
        }

        if (allInputValid) {
            checkExistingAccounts(authObject, "password", email)
        } else {

        }
    }

    const createNewUser = async () => {
        setLoading(true);
        const auth = getAuth();
        try {
            let userCredential = await createUserWithEmailAndPassword(auth, email, password);
            requestNewSignUpEmail(email, false, userCredential.user.uid);
            if (yesChecked) {
                requestPhysicianPriviledges(userCredential.user);
            } 
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setErrorModalText(error.message);
            setErrorModalVisible(true);
        }
    }

    const requestNewSignUpEmail = async (email, userIsPhysician, uid) => {    
        fetch(`https://us-central1-doc2doc-d54a2.cloudfunctions.net/sendWelcomeEmail?dest=${email}&userIsPhysician=${userIsPhysician}&userUID=${uid}`)
        .then(response => response.json())
        .then(data => console.log(data));
    }

    const buildAdditionalPasswordInput = () => {
        let parent = [
            <Container title="Merge Accounts" maxWidth={450}>
                <View style={{
                    display: "flex", 
                    flexDirection: "row", 
                    justifyContent: "center", 
                    flexWrap: "wrap"
                }}>
                     <Text style={{ maxWidth: 450, marginTop: 5, marginBottom: 10,}}>
                        You have an existing account under the email attached your attempted sign in method.
                    </Text> 
                    <Text>
                        Enter the password to attach your new sign in method with your existing account or select 
                        "Don't Merge Accounts" to sign in without connecting accounts.
                    </Text>

                    <StyledTextInput
                        secure={true}
                        onChange={(existingAccountPassword)=>{
                            setAdditionalPassword(existingAccountPassword);
                        }}
                        placeholder={"Password for existing account"}
                    />
                    {
                        !!additionalPasswordError?
                        <Text style={styles.errorStyle}>
                            This password is inccorect
                        </Text>:
                        null
                    }
                </View>
                <View>
                    <ActionButton 
                        text={"Merge Accounts"}
                        handler={()=>{  
                            validateAdditionalPassword();
                        }}
                    />
                    <ActionButton 
                        text={"Don't Merge Accounts"}
                        handler={()=>{
                            createNewUser();
                        }}
                        filled={true}
                    />
                    <ActionButton 
                        text={"Cancel"}
                        handler={()=>{
                            setShowAdditionalPassword(false);
                        }}
                        filled={true}
                    />
                </View>
            </Container>
        ];
        return parent;
    }

    const validateSignUpQuestions = () => {
        let allValid = true;
        if (yesChecked) {
            if (fullName == null || fullName == "") {
                allValid = false;
                setFullNameError(true);
            } else {
                setFullNameError(null);
            }
            if (npi == null || npi == "") {
                allValid = false;
                setNpiError(true);
            } else {
                setNpiError(null);
            }
            if (medSchool == null || medSchool == "") {
                allValid = false;
                setMedSchoolError(true);
            } else {
                setMedSchoolError(null);
            }
            if (currentPractice == null || currentPractice == "") {
                allValid = false;
                setCurrentPracticeError(true);
            } else {
                setCurrentPracticeError(null);
            }
        } else if (!noChecked && !yesChecked) {
            allValid = false;
            setPhysicianStatusError(true);
        } else {
            setPhysicianStatusError(false);
        }
        if (allValid) {
            if (authObject) {
                signUserInWithCredential(authObject);

            } else {
                createNewUser();
            }
        } else {
            //show error?
        }
    }

    const requestPhysicianPriviledges = async (user) => {
        const functions = getFunctions();
        const requestPhysicianStatus = httpsCallable(functions, 'requestPhysicianStatus');
        let requestData = {
            user: user.uid,
            fullName: fullName,
            npi: npi,
            medSchool: medSchool,
            currentPractice: currentPractice,
        }
        await requestPhysicianStatus(requestData);
    }

    const buildQuestions = () => {
        let parent = [
            <Container title="More sign up questions">
                <View style={{
                    display: "flex", 
                    flexDirection: "row", 
                    justifyContent: "center", 
                    flexWrap: "wrap"
                }}>
                    <View style={{margin: 5}}>
                        <CheckBox 
                            label={"Yes, I am a physician"}
                            checked={yesChecked}
                            key={yesChecked}
                            handler={checked => {
                                setYesChecked(checked);
                                setNoChecked(!checked);
                            }}
                        />
                    </View>
                    <View style={{margin: 5}}>
                        <CheckBox 
                            label={"No, I am not a physician"}
                            checked={noChecked}
                            key={noChecked}
                            handler={checked => {
                                setYesChecked(!checked);
                                setNoChecked(checked);
                            }}
                        />
                    </View>
                </View>
                <View>
                    {
                        physicianStatusError != null ?
                        <Text style={styles.errorStyle}>
                            Please select if you are a physician
                        </Text>:
                        <></>
                    }
                </View>
                <ExpandableView showTrigger={false} isOpen={yesChecked}>
                    <View style={{width: "100%", display: "flex", alignItems: "center",}}>
                        <Text style={{ maxWidth: 450, marginTop: 5, marginBottom: 10,}}>
                            Log in immediatley and create a request to be granted physician priviledges.
                        </Text>
                        <StyledTextInput
                            onChange={(fullName)=>{
                                setFullname(fullName);
                            }}
                            placeholder={"Full Name"}
                            defaultValue={fullName}
                        />
                        {
                            !!fullNameError?
                            <Text style={styles.errorStyle}>
                                Please enter your full name
                            </Text>:
                            null
                        }
                        <StyledTextInput
                            onChange={(npi)=>{
                                setNPI(npi);
                            }}
                            placeholder={"NPI"}
                        />
                        {
                            !!npiError?
                            <Text style={styles.errorStyle}>
                                Please enter your NPI number
                            </Text>:
                            null
                        }
                        <StyledTextInput
                            onChange={(medSchool)=>{
                                setMedSchool(medSchool);
                            }}
                            placeholder={"Name of Medical School"}
                        />
                        {
                            !!medSchoolError?
                            <Text style={styles.errorStyle}>
                                Please enter the name of your medical school
                            </Text>:
                            null
                        }
                        <StyledTextInput
                            onChange={(currentPractice)=>{
                                setCurrentPractice(currentPractice);
                            }}
                            placeholder={"Name of Current Practice/ Facility"}
                        />
                        {
                            !!currentPracticeError?
                            <Text style={styles.errorStyle}>
                                Please enter the name of your current practice/ facility
                            </Text>:
                            null
                        }
                    </View>
                </ExpandableView>
                <ActionButton 
                    text={"Continue"}
                    handler={()=>{
                       validateSignUpQuestions();
                    }}
                />
                <ActionButton 
                    text={"Cancel"}
                    handler={()=>{
                        setShowSignUpQuestions(false);
                    }}
                    filled={true}
                />
            </Container>
        ];
        return parent;
    }

    const checkExistingAccounts = async (authObject, signInType, responseEmail) => {
        if (responseEmail) {
            const auth = getAuth();
            let signInMethods = await fetchSignInMethodsForEmail(auth, responseEmail);
            if (signInMethods.length > 0) {
                let hasThisAuth = signInMethods.filter(item=>{
                    return item == signInType;
                })
                let hasEmail = signInMethods.filter(item=>{
                    return item == "password";
                })
                if (hasThisAuth.length == 0) {
                    if (hasEmail.length) {
                        setShowAdditionalPassword(true);
                    } else {
                        //ideally we could merge accounts here but theres no way
                        if (props.route.name == "Sign Up") {
                            setShowSignUpQuestions(true);
                        } else {
                            signInWithEmail();
                        }
                    }
                } else {
                    if (authObject) {
                        signUserInWithCredential(authObject);
                    } else {
                        signInWithEmail();
                    }
                }
            } else {
                if (props.route.name == "Sign Up") {
                    setShowSignUpQuestions(true);
                } else {
                    setErrorModalText("You do not have an account. Please navigate to sign up page");
                    setErrorModalVisible(true);
                }
            }
        } else {
            if (props.route.name == "Sign Up") {
                setShowSignUpQuestions(true);
            } else {
                if (authObject) {
                    signUserInWithCredential(authObject);
                } else {
                    setShowSignUpQuestions(true);
                }
            }
        }
    }

    const validateAdditionalPassword = () => {
        let allValid = true;
        if (additionalPassword == null || additionalPassword == "") {
            if (validEmailRegex.test(additionalPassword)) {
                setAdditionalPasswordError(null);
            } else {
                setAdditionalPasswordError("Please enter a valid password");
                allValid = false;
            }
        }
        if (allValid) {
            mergeUserAccounts();
        } else {
            //show error?
        }
    }

    const mergeUserAccounts = async () => {
        const auth = getAuth();
        try {
            userCredentials = await signInWithEmailAndPassword(auth, email, additionalPassword);
            let userCred = await linkWithCredential(userCredentials.user, authObject);
            setUser(userCred.user);
        } catch (error) {
            setErrorModalText(error.message);
            setErrorModalVisible(true);
        }
    }

    const signUserInWithCredential = async (credential) => {
        try {
            const auth = getAuth();
            let usercred = await signInWithCredential(auth, credential);
            const user = usercred.user;
            setUser(user);
            if (props.route.name == "Sign Up") {
                requestNewSignUpEmail(user.email, false, user.uid);
                requestPhysicianPriviledges(user);
            }
            setLoading(false);
        } catch (error) {
            // if (error.message.indexOf("account-exists-with-different-credential") > -1) {
            //     //wtf do we do here??
            // }
            setErrorModalText(error.message);
            setErrorModalVisible(true);
        }
    }

    const signInWithEmail = () => {
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then(() => props.navigation.navigate('Home'))
            .catch(error => {
                setErrorModalText(error.message);
                setErrorModalVisible(true);
            });
    }

    const buildAppleButton = () => {
        if (Platform.OS === "ios") {
            return [
                <>
                    <Text style={{fontSize: 18, fontWeight: "600"}}>Or</Text>
                    <Container title={`${props.route.name} using Apple`} maxWidth={"80%"}>
                        <AppleSignUpButton 
                            type="signup"
                            onComplete={(authObject, email, name) => {
                                setAuthObject(authObject);
                                setEmail(email);
                                checkExistingAccounts(authObject, "apple.com", email)
                                setFullname(name);
                            }}
                        />
                    </Container>
                </>
            ]
        }
    }

    const onConfirmClick = () => {
        var emailAddress = email;
        if (emailAddress != null) {
            const auth = getAuth();
            sendPasswordResetEmail(auth, emailAddress).then(function() {
                setTopAlerShowing(true);
            }).catch(function(error) {
                setErrorModalText(error.message);
                setErrorModalVisible(true);
            });
        } else {
            setErrorModalText("Please enter a valid email address");
            setErrorModalVisible("Please enter a valid email address");
            setResetModalVisible(false);
        }
    }
    

    const promptForPasswordReset = () => {
        setResetModalVisible(true);
    }

    const buildActionButtons = () => {
        let parent = [];
        if (props.route.name == "Sign Up") {
            parent.push(
                <ActionButton 
                    text={"Already have an account?"}
                    handler={()=>{
                        props.navigation.navigate("Log In");
                    }}
                    filled={true}
                />
            );
        } else {
            parent.push(
                <ActionButton 
                    text={"Dont have an account?"}
                    handler={()=>{
                        props.navigation.navigate("Sign Up");
                    }}
                    filled={true}
                />,
                <ActionButton 
                    text={"Forgot Password?"}
                    handler={()=>{
                        promptForPasswordReset();
                    }}
                    filled={true}
                />
            );
        }
        return parent;
    }

    const buildFaceBookButton = () => {
        return [
            <Text style={{fontSize: 18, fontWeight: "600"}}>Or</Text>,
            <Container title={`${props.route.name} using Facebook`}>
                <FbSignUpButton type="signup"/>
                {/* <Text style={{
                    color: "grey", fontSize: 12, maxWidth: 450, marginTop: 5, textAlign: "center"
                }}>
                    Detect if you are a member of the Doc to Doc Rentals Facebook group and sign up as 
                    a physician immediately without waiting for admin approval
                </Text> */}
            </Container>
        ]
    }

    const constructView = () => {
        let parent = [];
        let isOpen = !showSignUpQuestions && !showAdditionalPassword;
        let emailLabel = "Enter your email and new password";
        let passwordPlaceholder = "New Password";
        if (props.route.name != "Sign Up") {
            emailLabel = "Enter your email and password";
            passwordPlaceholder = "Password";
        }
        parent.push(
            <ExpandableView showTrigger={false} isOpen={isOpen}>
                <View style={{alignItems: "center", width: "100%", display: "flex", justifyContent: "center"}}>
                    <Container title={`${props.route.name} using Email`}>
                        <Text>{emailLabel}</Text>              
                        <StyledTextInput
                            secure={false}
                            onChange={(email)=>{
                                setEmail(email);
                            }}
                            placeholder={"Email"}
                        />
                        {!!emailError ? 
                            <Text allowFontScaling={false} style={styles.errorStyle}>
                                Please enter an email
                            </Text> : null
                        }
                        <StyledTextInput
                            secure={true}
                            onChange={(password)=>{
                                setPassword(password);
                            }}
                            placeholder={passwordPlaceholder}
                        />
                        {!!passwordError ? 
                            <Text allowFontScaling={false} style={styles.errorStyle}>
                                {passwordError}
                            </Text> : null
                        }
                        <ActionButton 
                            text={`${props.route.name}`}
                            loading={loading}   
                            handler={()=>{
                                validateEmailAndPassword();
                            }}
                        />
                        {buildActionButtons()}
                    </Container>
                    {buildFaceBookButton()}
                    {buildAppleButton()}
                </View>
            </ExpandableView>,
            <ExpandableView showTrigger={false} isOpen={showAdditionalPassword}>
                 <View style={{alignItems: "center", width: "100%"}}>
                    {buildAdditionalPasswordInput()}
                 </View>
            </ExpandableView>,
            <View style={{alignItems: "center", width: "100%"}}>
                <ExpandableView showTrigger={false} isOpen={showSignUpQuestions}>
                    <View style={{alignItems: "center", width: "100%"}}>
                        {buildQuestions()}
                    </View>
                </ExpandableView>
            </View>,
        )
        return [
            <Modal
                animationType="slide"
                transparent={true}
                visible={errorModalVisible}
                onModalClose={()=>{
                    setTimeout(()=>{
                        setErrorModalVisible(false);
                    }, 1000);
                }}
                key={errorModalVisible}
                labelText={errorModalText}
                isError={true}
            />,
            <Modal
                animationType="slide"
                transparent={true}
                visible={resetModalVisible}
                key={resetModalVisible}
                onModalClose={()=>{
                    setTimeout(()=>{
                        setResetModalVisible(false);
                    }, 1000);
                }}
                onConfirmClick={() => onConfirmClick()}
                labelText="Would you like to reset your password?"
            />,
            <Logo/>,
            parent,
        ];
    }

    return (
        <View>
             <TopAlert text={"Success!"} isShowing={topAlerShowing}/>
             <View style={[styles.container, {height: "100%"}]}>
                <KeyboardAwareScrollView horizontal={false} style={{width: "100%"}}>
                    <View style={styles.container}>
                        {constructView()}
                    </View>
                </KeyboardAwareScrollView>
            </View>
        </View>
    )
}

 
const styles = StyleSheet.create({
    container: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%",
    },
    errorStyle: {
        color: "red",
        fontWeight:"bold",
    },
});