import { getDatabase, ref, get } from "firebase/database";

export const getUserImage = async (uid) => {
    const db = getDatabase();
    const profileRef = ref(db, `profiles/${uid}/photoUrl`);
    let snapshot = await get(profileRef);
    let imageUrl = snapshot.val();
    if (!!imageUrl) {
        return imageUrl;
    } else {
        return null;
    }
}