import React, { Component } from 'react';
import { StyleSheet, View, ScrollView, Image, Text } from 'react-native';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, get, set } from "firebase/database";

import Container from "../compenents/Container";
import ActionButton from "../compenents/ActionButton";
import TopAlert from '../compenents/TopAlert';

export default class BlockedUsers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            blockList: null,
            successIsShowing: false,
            topAlertMessage: "",
        };
    } 

    componentDidMount() {
        this.getUser();
    }

    getUser = () => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user }, async ()=>{
                    let blockList = await this.getMyBlockList();
                    if (!!blockList) {
                        this.getProfileData(blockList);
                    }
                });
            }
        });
    }

    getMyBlockList = async () => {
        const db = getDatabase();
        const reservationsRef = ref(db, `blockList/${this.state.user.uid}`);
        let snapshot = await get(reservationsRef);
        let blockList = snapshot.val();
        return blockList;
    }

    getProfileData = async (blockList) => {
        const db = getDatabase();
        let profiles = null;
        for (let index in blockList) {
            const profileRef = ref(db, `profiles/${index}`);
            let snapshot = await get(profileRef);
            let profile = snapshot.val();
            if (profiles == null) {
                profiles = {};
            }
            profiles[index] = profile;
        }
        this.setState({
            blockList: blockList,
            profiles: profiles,
        })
    }

    showSuccess = message => {
        this.setState({
            successIsShowing: true,
            topAlertMessage: message,
        });
        setTimeout(() => {
            this.setState({
                successIsShowing: false,
            });
        }, 1500);
    }

    unblockUser = async (blockedUserId) => {
        const db = getDatabase();
        const blockListRef = ref(db, `blockList/${this.state.user.uid}/${blockedUserId}`);
        let snapshot = await set(blockListRef, null);
        this.showSuccess("You have successfully unblocked this user");
        let blockList = await this.getMyBlockList();
        if (!!blockList) {
            this.getProfileData(blockList);
        } else {
            this.setState({
                blockList: null,
                profiles: null,
            })
        }
    }

    buildView = () => {
        let parent =[];
        if (this.state.blockList != null) {
            for (let index in this.state.blockList) {
                if (!!this.state.profiles[index]) {
                    let profile = this.state.profiles[index];
                    let photoUrl = "https://doc2doc-d54a2.web.app/media/userAvatar.png";
                    if (profile.photoUrl != null) {
                        photoUrl = profile.photoUrl;
                    }
                    let userName = `${profile.firstName} ${profile.lastName}`;
                    parent.push(
                        <Container>
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "100%"}}
                            >
                                <View style={{
                                    display: "flex", 
                                    flexDirection: "row", 
                                    justifyContent: "flex-start"}}
                                >
                                    <Image
                                        style={styles.userImage}
                                        source={{
                                            uri: photoUrl,
                                        }}
                                    />
                                    <Text style={{
                                        marginTop: 10, 
                                        fontWeight: "600",
                                        marginLeft: 10,}}
                                    >{userName}</Text>
                                </View>
                                <ActionButton
                                    text="Unblock"
                                    handler={()=>{
                                        this.unblockUser(index);
                                    }}
                                    size="medium"
                                />
                            </View>
                        </Container>
                    )
                }
            }
        }
        return parent;
    }

    render() {
        return (
            <View style={{height: "100%"}}>
                <TopAlert text={this.state.topAlertMessage} isShowing={this.state.successIsShowing}/>
                <ScrollView>
                    <View style={styles.container}>
                        {this.buildView()}
                    </View>
                </ScrollView>
            </View>
        );
    }
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    userImage: {
        width: 60, 
        height: 60,
        borderRadius: 30,
        paddingHorizontal: 10,
    },  
});