import { getDatabase, ref, child, push, get } from "firebase/database";
import { getStorage, getDownloadURL, ref as sRef, uploadString, uploadBytes, uploadBytesResumable, deleteObject } from "firebase/storage";
import { Platform } from "react-native";

export const uploadImages = async (images, id, draft, userID, progress) => {
    let resizedImages = await resizeAndUploadImages(images, progress);
    //await removeDeletedFiles(images, id, draft, userID);
    return resizedImages;
}

//this deletes photos from expired listings when reposting them
export const removeDeletedFiles = async (images, id, draft, userID, isDelete) => {
    let promises = [];
    const db = getDatabase();
    const storage = getStorage();
    let rentalData = null;
    if (isDelete) {
        images.forEach(url => {
            if (url.indexOf("firebasestorage") > -1) {
                const httpsReference = sRef(storage, url);
                promises.push(deleteObject(httpsReference));
            }
        })
    } else if (draft) {
        const imageRef = ref(db, `drafts`);
        const rentalRef = child(imageRef, `${userID}/${id}`)
        let snapshot = await get(rentalRef);
        rentalData = snapshot.val();
    } else {
        const imageRef = ref(db, `publicRentals`);
        const rentalRef = child(imageRef, id)
        let snapshot = await get(rentalRef);
        rentalData = snapshot.val();
    }
    if (!!rentalData) {
        for (let index in rentalData.photoUrls) {
            let url = rentalData.photoUrls[index];
            let success = images.filter(element => {
                return element == url;
            });
            if (success.length == 0) {
                const httpsReference = sRef(storage, url);
                promises.push(deleteObject(httpsReference));
            }
        }
    } 
    return await Promise.all(promises);
}

const resizeAndUploadImages = async (images, progress) => {
    let promises = [];
    const db = getDatabase();
    const storage = getStorage();
    let newImageData = [];
    let finalUrls = [];
    let completed = 0;
    for (let index in images) {
        let image = images[index];
        if (index < 20) {
            if (image.indexOf("firebasestorage") == -1) {
                if (Platform.OS == "web") {
                    newImageData.push(image);
                    promises.push(
                        new Promise((resolve, reject) => {
                            const newImageRef = push(child(ref(db), 'posts'));
                            const storageRef = sRef(storage, `publicRentalImages/${newImageRef.key}`);
                            uploadString(storageRef, image, 'data_url').then((snapshot) => {
                                getDownloadURL(snapshot.ref).then(newUrl => {
                                    resolve({newUrl: newUrl, oldUrl: image});
                                    completed += 1;
                                    progress(completed/images.length);
                                }).catch(e => {
                                    console.log(e);
                                    reject();
                                });
                            }).catch(e => {
                                console.log(e);
                                reject();
                            });
                        })
                     );
                } else {
                    newImageData.push(image);
                    promises.push(
                        new Promise((resolve, reject) => {
                            fetch(image)
                            .then((response)=> response.blob())
                            .then((imageBlob)=> {
                                if (!!imageBlob) {
                                    const newImageRef = push(child(ref(db), 'posts'));
                                    const storageRef = sRef(storage, `publicRentalImages/${newImageRef.key}`);
                                    const uploadTask = uploadBytesResumable(storageRef, imageBlob);
                                    uploadTask.on('state_changed', 
                                    (snapshot) => {}, 
                                    (error) => {
                                        console.log(error);
                                        reject();
                                    }, 
                                    () => {
                                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                                            resolve({newUrl: downloadURL, oldUrl: image});
                                            completed += 1;
                                            progress(completed/images.length);
                                        });
                                    });
                                } else {
                                    reject();
                                }
                            }).catch(e => {
                                console.log(e);
                                reject();
                            });
                        })
                    );
                    //check if promises has 4
                    if (promises.length == 4) {
                        try {
                            let updatedUrls = await Promise.all(promises);
                            let moreUrls = newImageData.map(image => {
                                if (image.indexOf("firebasestorage") == -1) {
                                    let newUrlData = updatedUrls.filter(data => {
                                        return image == data.oldUrl;
                                    })
                                    if (newUrlData.length > 0) {
                                        if (!!newUrlData[0]) {
                                            return newUrlData[0].newUrl;
                                        } 
                                    }
                                } else {
                                    return image;
                                }
                            });
                            finalUrls = finalUrls.concat(moreUrls);
                            promises = [];
                        } catch (error) {
                            console.log(error);
                        }
                    } else if (newImageData.length == images.length) {
                        try {
                            let updatedUrls = await Promise.all(promises);
                            let moreUrls = newImageData.map(image => {
                                if (image.indexOf("firebasestorage") == -1) {
                                    let newUrlData = updatedUrls.filter(data => {
                                        return image == data.oldUrl;
                                    })
                                    if (newUrlData.length > 0) {
                                        if (!!newUrlData[0]) {
                                            return newUrlData[0].newUrl;
                                        } 
                                    }
                                } else {
                                    return image;
                                }
                            });
                            finalUrls = finalUrls.concat(moreUrls);
                            promises = [];
                        } catch (error) {
                            console.log(error);
                        }
                    }
                }
            } else {
                if (Platform.OS == "web") {
                    newImageData.push(image);
                    completed += 1;
                    progress(completed/images.length);
                } else {
                    finalUrls.push(image)
                    completed += 1;
                    progress(completed/images.length);
                }
            }
        }
    }
    if (Platform.OS == "web") {
        try {
            let updatedUrls = await Promise.all(promises);
            finalUrls = newImageData.map(image => {
                if (image.indexOf("firebasestorage") == -1) {
                    let newUrlData = updatedUrls.filter(data => {
                        return image == data.oldUrl;
                    })
                    if (newUrlData.length > 0) {
                        if (!!newUrlData[0]) {
                            return newUrlData[0].newUrl;
                        } 
                    }
                } else {
                    return image;
                }
            });
            return finalUrls;
        } catch (error) {
            console.log(error);
            return null;
        }
    } else {
        finalUrls = finalUrls.filter(url => {
            return !!url;
        }); 
        return finalUrls;
    }
};