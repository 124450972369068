// import { Platform } from 'react-native';

export const getDateString = (dateString) => {
    let date = new Date(dateString);
    let monthNum = parseInt(dateString.split("-")[1]);
    let month = getMonth(monthNum);
    let dateOutput = `${month}`;
    // if (Platform.OS == "android") {
    //     let outputArray = dateOutput.split(" ")
    //     let trimmedDateOutput = outputArray[1]; 
    //     dateOutput = trimmedDateOutput; 
    // } 
    dateOutput += " " + date.getUTCDate() + ", ";
    dateOutput += date.getUTCFullYear();
    return dateOutput;
}

export const getShortDateString = (dateString) => {
    let date = new Date(dateString);
    let monthNum = parseInt(dateString.split("-")[1]);
    let dateOutput = `${monthNum}/`;
    dateOutput += date.getUTCDate()+"/";
    dateOutput += date.getUTCFullYear().toString().slice(-2);
    return dateOutput;
}

export const getDataDateString = (date) => {
    let dateString = `${date.getUTCFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`
    return dateString;
}

export const getMonth = (monthNumber) => {
    switch (monthNumber) {
        case 12: return "Dec";
        case 1: return "Jan";
        case 2: return "Feb";
        case 3: return "Mar";
        case 4: return "April";
        case 5: return "May";
        case 6: return "June";
        case 7: return "July";
        case 8: return "Aug";
        case 9: return "Sept";
        case 10: return "Oct";
        case 11: return "Nov";
    }
}