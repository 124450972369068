import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, Alert } from 'react-native';
import { getDatabase, ref, get, update, set } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import ActionButton from '../compenents/ActionButton';
import { userIsPhysician } from '../helpers/public-user-helper';
import { getRentalPrice, getGuestFee, getTaxAmount, getPriceBeforeFee, getNightlyFee } from "../helpers/price-calculator";
import Container from "../compenents/Container";
import Modal from '../compenents/Modal'
import { getDateString, getDataDateString } from '../helpers/date-formatter';
import Loader from '../compenents/Loader';

export default class ReviewReservation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            userIsDoc: false,
            guestFee: null,
            totalPrice: null,
            modalVisible: false,
            modalKey: Math.random().toString(36).substring(7),
            variablePhysicianPriceData: null,
            variablePublicPriceData: null,
        }
    }

    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
			if (user) {
                this.setState({ user }, () => {
                    this.checkIfUserIsDoc(user.uid);
                });
            } else {
                this.setState({ user: null });
            }
        });
    }

    onModalClose = () => {
        this.setState({modalVisible: false});
    }

    checkIfUserIsDoc = async (uid) => {
        let userIsDoc = await userIsPhysician(uid);
        this.setState({
          userIsDoc: userIsDoc,
        }, async ()=>{
            let variablePhysicianPriceData = await this.getPhysicianPriceData();
            let variablePublicPriceData = await this.getPublicPriceData();
            this.setState({
                variablePhysicianPriceData: variablePhysicianPriceData,
                variablePublicPriceData: variablePublicPriceData,
              }, ()=>{
                this.setPrices();
              });
        });
    }

    getPhysicianPriceData = async () => {
        const db = getDatabase();
        if (!!this.props.route.params.rentalData.variablePhysicianPrice) {
            if (this.props.route.params.rentalData.variablePhysicianPrice) {
                const variablePriceRef = ref(db, `variablePhysicianPrice/${this.props.route.params.rentalData.key}`);
                let snapshot = await get(variablePriceRef)
                const variablePhysicianPriceData = snapshot.val();
                if (variablePhysicianPriceData !== null) {
                    return variablePhysicianPriceData
                } else {
                    return null
                }
            }
        }
    }

    getPublicPriceData = async () => {
        const db = getDatabase();
        if (!!this.props.route.params.rentalData.variablePublicPrice) {
            if (this.props.route.params.rentalData.variablePublicPrice) {
                const variablePriceRef = ref(db, `variablePublicPrice/${this.props.route.params.rentalData.key}`);
                let snapshot = await get(variablePriceRef)
                const variablePublicPriceData = snapshot.val();
                if (variablePublicPriceData !== null) {
                    return variablePublicPriceData
                } else {
                    return null;
                }
            }
        }
    }

    setPrices = () => {
        let checkInDate = new Date(this.props.route.params.reservationData.checkIn);
        let checkOutDate = new Date(this.props.route.params.reservationData.checkOut);
        let datesAray = this.getDaysArray(checkInDate, checkOutDate);
        let datesCount = datesAray.length - 1;
        let bringingPet = this.props.route.params.reservationData.bringingPet;
        let rentalData = this.props.route.params.rentalData;
        let nightlyFee = getNightlyFee(this.state.userIsDoc,
            rentalData.publicPrice,
            rentalData.physicianPrice,
            datesCount,
            this.state.variablePhysicianPriceData,
            this.state.variablePublicPriceData,
            datesAray)
        let petFee = 0;
        if (this.props.route.params.rentalData.petFee) {
            if (this.props.route.params.reservationData.bringingPet) {
                petFee = this.props.route.params.rentalData.petFee;
            }
        }
        let cleaningFee = 0;
        if (this.props.route.params.rentalData.cleaningFee) {
            cleaningFee = this.props.route.params.rentalData.cleaningFee;
        }

        let guestFee = getGuestFee(this.state.userIsDoc,
            rentalData.publicPrice,
            rentalData.physicianPrice,
            rentalData.petFee,
            rentalData.cleaningFee,
            datesCount,
            bringingPet,
            this.state.variablePhysicianPriceData,
            this.state.variablePublicPriceData,
            datesAray);
        let totalPrice = getRentalPrice(this.state.userIsDoc,
            rentalData.publicPrice,
            rentalData.physicianPrice,
            rentalData.petFee,
            rentalData.cleaningFee,
            datesCount,
            bringingPet,
            rentalData.salesTax,
            rentalData.occupancyTax,
            this.state.variablePhysicianPriceData,
            this.state.variablePublicPriceData,
            datesAray);
        let totalBeforeFees = getPriceBeforeFee(this.state.userIsDoc,
            rentalData.publicPrice,
            rentalData.physicianPrice,
            rentalData.petFee,
            rentalData.cleaningFee,
            datesCount,
            bringingPet,
            this.state.variablePhysicianPriceData,
            this.state.variablePublicPriceData,
            datesAray);
        let salesTax = (totalBeforeFees + guestFee) * (rentalData.salesTax * 0.01);
        let occupancyTax = (totalBeforeFees + guestFee) * (rentalData.occupancyTax * 0.01);
        if (isNaN(salesTax)) {
            salesTax = 0;
        }
        if (isNaN(occupancyTax)) {
            occupancyTax = 0;
        }
        this.setState({
            nightlyFee: nightlyFee,
            petFee: petFee,
            cleaningFee: cleaningFee,
            guestFee: guestFee,
            totalPrice: totalPrice,
            salesTax: salesTax,
            occupancyTax: occupancyTax,
        })
    }

    buildView = () => {
        let parent = [];
        if (!!this.props.route.params) {
            if (!!this.props.route.params.reservationData) {
                parent.push(
                    <View style={{display: "flex", flexDirection: "row", width: 280}}>
                        <Text style={{width: 110}}>Name:</Text>
                        <View style={styles.divider}></View>
                        <Text>{this.props.route.params.reservationData.firstName} {this.props.route.params.reservationData.lastName}</Text>
                    </View>
                );
                if (!!this.props.route.params.reservationData.npiNumber) {
                    parent.push(
                        <View style={{display: "flex", flexDirection: "row", width: 280}}>
                            <Text style={{width: 110}}>NPI: </Text>
                            <View style={styles.divider}></View>
                            <Text>{this.props.route.params.reservationData.npiNumber}</Text>
                        </View>
                    );
                }
                let checkInDate = new Date(this.props.route.params.reservationData.checkIn);
                let checkOutDate = new Date(this.props.route.params.reservationData.checkOut);
                let datesAray = this.getDaysArray(checkInDate, checkOutDate);
                let datesCount = datesAray.length - 1;
                let checkInString = getDateString(this.props.route.params.reservationData.checkIn);
                let checkOutString = getDateString(this.props.route.params.reservationData.checkOut);
                parent.push(
                    <View style={{display: "flex", flexDirection: "row", width: 280}}>
                        <Text style={{width: 110}}>Check In:</Text>
                        <View style={styles.divider}></View>
                        <Text>{checkInString}</Text>
                    </View>
                );
                parent.push(
                    <View style={{display: "flex", flexDirection: "row",  width: 280}}>
                        <Text style={{width: 110}}>Check Out:</Text>
                        <View style={styles.divider}></View>
                        <Text>{checkOutString}</Text>
                    </View>
                );
                parent.push(
                    <View style={{display: "flex", flexDirection: "row", width: 280}}>
                        <Text style={{width: 110}}>Night(s):</Text>
                        <View style={styles.divider}></View>
                        <Text>x {datesCount}</Text>
                    </View>
                );
                if (this.props.route.params.reservationData.bringingPet) {
                    parent.push(
                        <View style={{display: "flex", flexDirection: "row", width: 280}}>
                            <Text style={{width: 110}}>Pet:</Text>
                            <View style={styles.divider}></View>
                            <Text>Yes</Text>
                        </View>
                    );
                }
                parent.push(
                    <View style={{display: "flex", flexDirection: "row",  width: 280}}>
                        <Text style={{width: 110}}>People:</Text>
                        <View style={styles.divider}></View>
                        <Text>x {this.props.route.params.reservationData.numberOfPeople}</Text>
                    </View>
                );
            }
        }
        return parent;
    }

    buildPriceBreakDown = () => {
        let parent = [];
        let checkInDate = new Date(this.props.route.params.reservationData.checkIn);
        let checkOutDate = new Date(this.props.route.params.reservationData.checkOut);
        let datesAray = this.getDaysArray(checkInDate, checkOutDate);
        let datesCount = datesAray.length - 1;
        let rentalData = this.props.route.params.rentalData;
        if (this.props.route.params.rentalData.petFee) {
            if (this.props.route.params.reservationData.bringingPet) {
                parent.push(
                    <View style={{display: "flex", flexDirection: "row", width: 230}}>
                        <Text style={{width: 150}}>Pet Fee:</Text>
                        <View style={styles.divider}></View>
                        <Text style={{width: 70}}>${rentalData.petFee.toFixed(2)}</Text>
                    </View>
                )
            }
        }
        if (this.props.route.params.rentalData.cleaningFee) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 230}}>
                    <Text style={{width: 150}}>Cleaning Fee:</Text>
                    <View style={styles.divider}></View>
                    <Text style={{width: 70}}>${rentalData.cleaningFee.toFixed(2)}</Text>
                </View>
            )
        }
        if (rentalData.publicPrice != null) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 230}}>
                    <Text style={{width: 150}}>Nightly Fee x{datesCount} :</Text>
                    <View style={styles.divider}></View>
                    <Text style={{width: 70}}>${this.state.nightlyFee?.toFixed(2)}</Text>
                </View>
            )
        }
        if (rentalData.physicianPrice != null) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 230}}>
                    <Text style={{width: 150}}>Nightly Fee x{datesCount}:</Text>
                    <View style={styles.divider}></View>
                    <Text style={{width: 70}}>${this.state.nightlyFee?.toFixed(2)}</Text>
                </View>
            )
        }
        parent.push(
            <View style={{display: "flex", flexDirection: "row", width: 230}}>
                <Text style={{width: 150}}>Guest Fee:</Text>
                <View style={styles.divider}></View>
                <Text style={{width: 70}}>${parseFloat(this.state.guestFee).toFixed(2)}</Text>
            </View>
        )
        if (this.props.route.params.rentalData.salesTax) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 230}}>
                    <Text style={{width: 150}}>Sales Tax ({this.props.route.params.rentalData.salesTax}%):</Text>
                    <View style={styles.divider}></View>
                    <Text style={{width: 70}}>${parseFloat(this.state.salesTax).toFixed(2)}</Text>
                </View>
            )
        }
        if (this.props.route.params.rentalData.occupancyTax) {
            parent.push(
                <View style={{display: "flex", flexDirection: "row", width: 230}}>
                    <Text style={{width: 150}}>Occupancy Tax ({this.props.route.params.rentalData.occupancyTax}%):</Text>
                    <View style={styles.divider}></View>
                    <Text style={{width: 70}}>${parseFloat(this.state.occupancyTax).toFixed(2)}</Text>
                </View>
            )
        }
        parent.push(
            <View style={{display: "flex", flexDirection: "row", marginTop: 10,}}>
                <Text style={{fontWeight: "600", fontSize: 18}}>Total: ${parseFloat(this.state.totalPrice).toFixed(2)}</Text>
            </View>
        )
        return parent;
    }

    getDaysArray = (s,e) => {
        for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;
    };

    requestBooking = async () => {
        this.setState({ 
            loaderIsShowing: true,
            loaderKey: Math.random().toString(36).substring(7),
        });
        const total = this.state.totalPrice.toString();
        const nightlyFee =  this.state.nightlyFee.toString();
        const guestFee =  this.state.guestFee.toString();
        const bringingPet = this.props.route.params.reservationData.bringingPet || false;
        let petFee = 0;
        if (!!this.props.route.params.rentalData.petFee) {
            if (bringingPet) {
                petFee = this.props.route.params.rentalData.petFee.toString();
            }
        }
        let cleaningFee = 0;
        if (!!this.props.route.params.rentalData.cleaningFee) {
            cleaningFee = this.props.route.params.rentalData.cleaningFee.toString();
        }
        let salesTax = 0;
        if (!!this.props.route.params.rentalData.cleaningFee) {
            salesTax = this.state.salesTax.toString();
        }
        let occupancyTax = 0;
        if (!!this.props.route.params.occupancyTax) {
            occupancyTax = this.props.route.params.occupancyTax.toString();
        }
        const postID = this.props.route.params.rentalData.key;
        const checkIn = this.props.route.params.reservationData.checkIn;
        const checkOut = this.props.route.params.reservationData.checkOut;
        const message = this.props.route.params.reservationData.message;
        const people = this.props.route.params.reservationData.numberOfPeople;
        const requesterUID = this.state.user.uid; 
        const renterFirstName =this.props.route.params.reservationData.firstName; 
        const renterLastName =this.props.route.params.reservationData.lastName; 
        const customerID = this.props.route.params.customerID;
        const paymentSource = this.props.route.params.paymentSource;
        const npi = this.props.route.params.reservationData.npiNumber || false;
        const renterEmail = this.state.user.email;
        let url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/requestBooking?`;
        url += `total=${total}&nightlyFee=${nightlyFee}&guestFee=${guestFee}&petFee=${petFee}&`
        url += `cleaningFee=${cleaningFee}&salesTax=${salesTax}&occupancyTax=${occupancyTax}&`
        url += `postID=${postID}&checkIn=${checkIn}&checkOut=${checkOut}&bringingPet=${bringingPet}&`
        url += `message=${message}&people=${people}&requesterUID=${requesterUID}&`
        url += `renterFirstName=${renterFirstName}&renterLastName=${renterLastName}&`
        url += `customerID=${customerID}&paymentSource=${paymentSource}&renterEmail=${renterEmail}&npi=${npi}`;
        try {
            const response = await fetch(url);
            const bookingResponse = await response.json();
            if (bookingResponse.response == "success") {
                this.setState({ 
                    loaderIsShowing: false,
                    loaderKey: Math.random().toString(36).substring(7),
                });
                this.props.navigation.navigate("Success", {
                    reservationRequestData: postID,
                })
            } else {
                console.log("something failed")
                this.setState({
                    modalVisible: true,
                    modalKey: Math.random().toString(36).substring(7),
                    loaderIsShowing: false,
                    loaderKey: Math.random().toString(36).substring(7),
                })
            }   
        } catch (error) {
            console.log(error)
            this.setState({
                modalVisible: true,
                modalKey: Math.random().toString(36).substring(7),
                loaderIsShowing: false,
                loaderKey: Math.random().toString(36).substring(7),
            })
        }
    }

    sendBooking = async () => {
        this.setState({ 
            loaderIsShowing: true,
            loaderKey: Math.random().toString(36).substring(7),
        });
        const total = this.state.totalPrice.toString();
        const nightlyFee = this.state.nightlyFee.toString();
        const guestFee =  this.state.guestFee.toString();
        const bringingPet = this.props.route.params.reservationData.bringingPet || false;
        let petFee = 0;
        if (!!this.props.route.params.rentalData.petFee) {
            if (bringingPet) {
                petFee = this.props.route.params.rentalData.petFee.toString();
            }
        }
        let cleaningFee = 0;
        if (!!this.props.route.params.rentalData.cleaningFee) {
            cleaningFee = this.props.route.params.rentalData.cleaningFee.toString();
        }
        let salesTax = 0;
        if (!!this.props.route.params.rentalData.cleaningFee) {
            salesTax = this.state.salesTax.toString();
        }
        let occupancyTax = 0;
        if (!!this.props.route.params.occupancyTax) {
            occupancyTax = this.props.route.params.occupancyTax.toString();
        }
        const postID = this.props.route.params.rentalData.key;
        const checkIn = this.props.route.params.reservationData.checkIn;
        const checkOut = this.props.route.params.reservationData.checkOut;
        const message = this.props.route.params.reservationData.message;
        const people = this.props.route.params.reservationData.numberOfPeople;
        const requesterUID = this.state.user.uid; 
        const renterFirstName =this.props.route.params.reservationData.firstName; 
        const renterLastName =this.props.route.params.reservationData.lastName; 
        const customerID = this.props.route.params.customerID;
        const paymentSource = this.props.route.params.paymentSource;
        const npi = this.props.route.params.reservationData.npiNumber || "no npi";
        const renterEmail = this.state.user.email;
        let url = `https://us-central1-doc2doc-d54a2.cloudfunctions.net/bookRental?`;
        url += `total=${total}&nightlyFee=${nightlyFee}&guestFee=${guestFee}&petFee=${petFee}&`
        url += `cleaningFee=${cleaningFee}&salesTax=${salesTax}&occupancyTax=${occupancyTax}&`
        url += `postID=${postID}&checkIn=${checkIn}&checkOut=${checkOut}&bringingPet=${bringingPet}&`
        url += `message=${message}&people=${people}&requesterUID=${requesterUID}&`
        url += `renterFirstName=${renterFirstName}&renterLastName=${renterLastName}&`
        url += `customerID=${customerID}&paymentSource=${paymentSource}&renterEmail=${renterEmail}&npi=${npi}`;
        try {
            const response = await fetch(url);
            const bookingResponse = await response.json();
            if (bookingResponse.response == "success") {
                this.setState({ 
                    loaderIsShowing: false,
                    loaderKey: Math.random().toString(36).substring(7),
                });
                this.props.navigation.navigate("Success", {
                    reservationData: postID,
                })
            } else {
                console.log("something failed")
                this.setState({
                    modalVisible: true,
                    modalKey: Math.random().toString(36).substring(7),
                    loaderIsShowing: false,
                    loaderKey: Math.random().toString(36).substring(7),
                })
            }   
        } catch (error) {
            console.log(error)
            this.setState({
                modalVisible: true,
                modalKey: Math.random().toString(36).substring(7),
                loaderIsShowing: false,
                loaderKey: Math.random().toString(36).substring(7),
            })
        }
    }

    checkOut = () => {
        if (this.props.route.params.rentalData.autoBook) {
            this.sendBooking();
        } else {
            this.requestBooking();
        }
    }
    
    render() {
        return (
            <View style={{height: "100%"}}>
                <Modal
                    animationType="slide"
                    transparent={true}
                    visible={this.state.modalVisible}
                    key={this.state.modalKey}
                    onModalClose={this.onModalClose}
                    isError={true}
                    labelText="Payment method failed. Please update your form of payment in the settings tab of the menu."
                />
                <Loader 
                    visible={this.state.loaderIsShowing}
                    key={this.state.loaderKey}  
                />
                <ScrollView contentContainerStyle={styles.container}>
                    <Container>
                        <Text allowFontScaling={false} style={styles.headerLabeltext}>Details</Text>
                        {this.buildView()}
                    </Container>
                    <Container>
                        <Text allowFontScaling={false} style={styles.headerLabeltext}>Price Breakdown</Text>
                        {this.buildPriceBreakDown()}
                    </Container>
                    <Container>
                        {
                            this.props.route.params.rentalData.autoBook ?
                            <ActionButton
                                text={"Book Now"}
                                handler={()=>{
                                    this.checkOut();
                                }}
                            />:
                            <ActionButton
                                text={"Send Request"}
                                handler={()=>{
                                    this.checkOut();
                                }}
                            />
                        }
                    </Container>
                </ScrollView>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    headerLabeltext: {
        fontWeight: "600",
        textAlign: "center",
        marginBottom: 10,
    },
    divider: {
        backgroundColor: "#d3d3d3",
        width: 1,
        height: 20,
        marginHorizontal: 10,
      },
})