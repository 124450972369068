import React, { Component } from 'react';
import { StyleSheet, Text, Image, TouchableHighlight, TouchableWithoutFeedback, View, PanResponder, ActivityIndicator, Dimensions } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
// import Ripple from 'react-native-material-ripple';
import Ripples from 'react-ripples'

export default class ActionButton extends Component {

    buildIcon = () => {
        let parent = [];
        let indicatorColor = "white";
        if (this.props.filled) {
            indicatorColor = "#5d51ef";
        }
        if (this.props.icon != null) {
            if (this.props.loading) {
                parent.push(
                    <ActivityIndicator size="small" color={indicatorColor}/>
                );
            } else {
                parent.push(
                    <Image 
                        style={styles.icon}
                        source={this.props.icon}
                    />
                );
            }
        } else {
            if (this.props.loading) {
                parent.push(
                    <ActivityIndicator size="small" color={indicatorColor}/>
                );
            } 
        }

        return parent;
    }

    buttonPress = () => {
        if (!!this.props.handler) {
            this.props.handler();
        }
    }

    buildView = () => {
        let parent = [];
        let gradientStyles = [styles.gradient];
        let colors = ['#ab5cf7', '#5d51ef', '#53a4e8'];
        let disabled = false;
        if (this.props.loading) {
            colors = [
                'rgba(171, 92, 247, 0.8)',
                'rgba(93, 81, 239, 0.8)',
                'rgba(83, 164, 232, 0.8)',
            ];
            disabled = true;
        }
        if (this.props.size != null) {
            switch(this.props.size) {
                case "small":
                   gradientStyles.push(styles.small);
                  break;
                case "medium":
                    gradientStyles.push(styles.medium);
                  break;
                case "large":
                    gradientStyles.push(styles.large);
                  break;
                case "extralarge":
                    gradientStyles.push(styles.extralarge);
                    break;
                default:
                    gradientStyles.push(styles.large);
              }
        } else {
            gradientStyles.push(styles.large);
        }
        if (this.props.filled != null) {
            if (this.props.filled) {
                let filledButtonStyle = styles.filledButtonWrapper;
                if (this.props.size != null) {
                    if (this.props.size == "medium") {
                        filledButtonStyle = styles.filledButtonMediumWrapper;
                    }
                }
                parent.push(
                    <TouchableWithoutFeedback 
                        onPress={this.buttonPress}
                        style={styles.buttonNoShadow}
                        disabled={disabled}
                    >
                        <Ripples>
                            <LinearGradient
                                colors={colors}
                                locations={[0.0, 0.7, 1.0]}
                                start={{ x: 0.1, y: 0.2 }}
                                end={{ x: 0.9, y: 1.0 }}
                                style={gradientStyles}>
                                <View style={filledButtonStyle}>
                                    <View style={styles.contentWrapper}>
                                        <Text 
                                            allowFontScaling={false} 
                                            style={styles.filledButtonText}
                                            numberOfLines={1}
                                        >{this.props.text}</Text>
                                        {this.buildIcon()}
                                    </View>
                                </View>
                            </LinearGradient>
                        </Ripples>
                    </TouchableWithoutFeedback> 
                );
            } else {
                parent.push(
                    <TouchableWithoutFeedback 
                        onPress={this.buttonPress}
                        style={styles.button}
                        disabled={disabled}
                    >
                        <Ripples>
                            <LinearGradient
                                colors={colors}
                                locations={[0.0, 0.7, 1.0]}
                                start={{ x: 0.1, y: 0.2 }}
                                end={{ x: 0.9, y: 1.0 }}
                                style={gradientStyles}>
                                <View style={styles.contentWrapper}>
                                    <Text allowFontScaling={false} style={styles.buttonText}>{this.props.text}</Text>
                                    {this.buildIcon()}
                                </View>
                            </LinearGradient>
                        </Ripples>
                    </TouchableWithoutFeedback>
                );
            }
        } else if (this.props.isText != null && this.props.isText) {
            parent.push(
                 <TouchableWithoutFeedback 
                    onPress={this.buttonPress}
                    style ={styles.button}
                >
                    <Text allowFontScaling={false} style={styles.filledButtonTextBlack}>{this.props.text}</Text>
                </TouchableWithoutFeedback>
            );
        } else {
            parent.push(
                <TouchableWithoutFeedback 
                    onPress={this.buttonPress}
                    style ={styles.button}
                    disabled={disabled}
                >
                    <Ripples>
                            <LinearGradient
                                colors={colors}
                                locations={[0.0, 0.7, 1.0]}
                                start={{ x: 0.1, y: 0.2 }}
                                end={{ x: 0.9, y: 1.0 }}
                                style={gradientStyles}>
                                <View style={styles.contentWrapper}>
                                    <Text allowFontScaling={false} style={styles.buttonText}>{this.props.text}</Text>
                                    {this.buildIcon()}
                                </View>
                            </LinearGradient>
                        
                    </Ripples>
                </TouchableWithoutFeedback>
            );
        }
        return parent;
    }

    render() {
        return (        
            <View style={{padding: 2}}>
                { this.buildView() }
            </View>
        )
    }
}

const styles = StyleSheet.create({
  button: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
    shadowOffset: {
        width: 0,
        height: 2,
      },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 3,
    borderRadius: 10,
    margin: 3,
    
  },
  buttonNoShadow: {
    textAlign: "center",
    color: "white",
    fontWeight: "700",
    borderRadius: 10,
    margin: 3,
  },
  gradient: {
    borderRadius: 10,
    // width: 230,
    textAlign: "center",
    height: 40,
    paddingTop: 8,
    width: "100%",
  },
  small: {
    width: 50,
  },
  medium: {
    width: 150,
  },
  large: {
    width: 230,
  },
  extralarge: {
    width: "100%",
    paddingHorizontal: 10,
  },
  filledButtonWrapper: {
    borderRadius: 8,
    width: 226,
    textAlign: "center",
    height: 36,
    backgroundColor: "white",
    marginTop: -6,
    marginLeft: 2
  },
  filledButtonMediumWrapper: {
    borderRadius: 8,
    width: 146,
    textAlign: "center",
    height: 36,
    backgroundColor: "white",
    marginTop: -6,
    marginLeft: 2
  },
  buttonText: {
    fontSize: 16,
    fontWeight: "700",
    color: "white",
    textAlign: "center",
  },
  filledButtonText: {
    color: "#5d51ef",
    fontSize: 16,
    fontWeight: "700",
    textAlign: "center",
    marginTop: 8,
  },
  filledButtonTextBlack: {
    fontSize: 14,
    fontWeight: "600",
    textAlign: "center",
    marginTop: 8,
  },
  icon: {
      width: 20,
      height: 20,
      marginTop: 2,
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  }
});
