import { getDataDateString } from "./date-formatter";

const guestFee = 0.1;

export const getRentalPrice = (userIsDoc, nightlyFee, docNightlyFee, petFee, cleaningFee, nights, bringingPet, salesTax, occupancyTax, variablePhysicianPriceData, variablePublicPriceData, dateArray) => {
    let totalBeforeFees = getPriceBeforeFee(userIsDoc, nightlyFee, docNightlyFee, petFee, cleaningFee, nights, bringingPet, variablePhysicianPriceData, variablePublicPriceData, dateArray);
    let fees = getGuestFee(userIsDoc, nightlyFee, docNightlyFee, petFee, cleaningFee, nights, bringingPet);
    let price = totalBeforeFees + fees;
    let taxes = getTaxAmount(salesTax, occupancyTax, price);
    let finalPrice = price;
    if (!isNaN(taxes)) {
        finalPrice = price + taxes;
    }
    return finalPrice;
}

export const getTaxAmount = (salesTax, occupancyTax, total) => {
    let tax = (total * (salesTax * 0.01)) + (total * (occupancyTax * 0.01));
    return tax;
}

export const getGuestFee = (userIsDoc, nightlyFee, docNightlyFee, petFee, cleaningFee, nights, bringingPet, variablePhysicianPriceData, variablePublicPriceData, dateArray) => {
    let totalBeforeFees = getPriceBeforeFee(userIsDoc, nightlyFee, docNightlyFee, petFee, cleaningFee, nights, bringingPet, variablePhysicianPriceData, variablePublicPriceData, dateArray);
    let feeAmount =  totalBeforeFees * guestFee;
    return feeAmount;
}

export const getPriceBeforeFee = (userIsDoc, nightlyFee, docNightlyFee, petFee, cleaningFee, nights, bringingPet, variablePhysicianPriceData, variablePublicPriceData, dateArray) => {
    let finalPrice = getNightlyFee(userIsDoc, nightlyFee, docNightlyFee, nights, variablePhysicianPriceData, variablePublicPriceData, dateArray);
    if (userIsDoc) {
        if (cleaningFee != null) {
            finalPrice = finalPrice + cleaningFee 
        }
        if (bringingPet != null && bringingPet) {
            finalPrice = finalPrice + petFee 
        }
    } else {
        if (cleaningFee != null) {
            finalPrice = finalPrice + cleaningFee 
        }
        if (bringingPet != null && bringingPet) {
            finalPrice = finalPrice + petFee 
        }
    }
    return finalPrice;
}

export const getNightlyFee = (userIsDoc, nightlyFee, docNightlyFee, nights, variablePhysicianPriceData, variablePublicPriceData, dateArray) => {
    let finalPrice = 0;
    if (userIsDoc) {
        if (!!variablePhysicianPriceData) {
            for (let index in dateArray) {
                if (index < dateArray.length - 1) {
                    let date = dateArray[index];
                    let dateString = getDataDateString(date);
                    if (!!variablePhysicianPriceData[dateString]) {
                        finalPrice += variablePhysicianPriceData[dateString];
                    } else {
                        finalPrice += docNightlyFee
                    }
                }
            }
            console.log(finalPrice)
        } else {
            finalPrice = docNightlyFee * nights;
        }
    } else {
        if (!!variablePublicPriceData) {
            for (let index in dateArray) {
                if (index < dateArray.length - 1) {
                    let date = dateArray[index];
                    let dateString = getDataDateString(date);
                    if (!!variablePublicPriceData[dateString]) {
                        finalPrice += variablePublicPriceData[dateString];
                    } else {
                        finalPrice += nightlyFee
                    }
                }
            }
        } else {
            finalPrice = nightlyFee * nights;
        }
    }
    return finalPrice;
}

export const getHostRevenueData = (payout) => {
    let totalCollected = parseFloat(payout.totalCollected);
    let stripeFee = (totalCollected * .03) + .3;
    let taxCollected = parseFloat(payout.taxCollected);
    let guestFee = totalCollected * .1;
    let hostFee = 0;
    if (payout.isGold != undefined) {
        if (!payout.isGold) {
            hostFee = (totalCollected - guestFee) * .03;
        } 
    }
    let platformPayout = hostFee + guestFee - stripeFee;
    let hostPayout = totalCollected - (hostFee + guestFee);
    return {
        totalCollected,
        stripeFee,
        taxCollected,
        guestFee,
        hostFee,
        platformPayout,
        hostPayout,
    }

}