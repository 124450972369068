
import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

export default function Container ({ children, maxWidth, title }) {
  return (
    <View style={[styles.wrapper, {maxWidth: maxWidth || 800}]}>
      {
        title? 
        <Text style={styles.headerLabel}>{title || ""}</Text>:
        null
      }
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: "white",
    width: "100%",
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 6,
    margin: 5,
    padding: 10,
    paddingHorizontal: 20,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 3,
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  headerLabel: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 10,
  },
});