import React, { Component } from 'react';
import { StyleSheet, Text, ScrollView, View, TextInput, Alert } from 'react-native';
import { getDatabase, ref, get, set } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { requestUpdate } from '../helpers/post-interactons';

import Container from "../compenents/Container";
import Modal from "../compenents/Modal";
import SearchInput from "../compenents/SearchInput";
import TimePicker from '../compenents/TimePicker';
import TopAlert from '../compenents/TopAlert';
import ActionButton from '../compenents/ActionButton';
import Loader from '../compenents/Loader';

export default class CheckInInstructions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lat: null,
            lng: null,
            locationName: null,
            checkInTime: "12:00",
            checkOutTime: "12:00",
            checkInInstructions: null,
            errorModalVisible: false,
            successIsShowing: false,
            errorModalKey: Math.random().toString(36).substring(7),
            searchKey:  Math.random().toString(36).substring(7),
            checkInKey: Math.random().toString(36).substring(7),
            checkOutKey: Math.random().toString(36).substring(7),
            instructionsKey: Math.random().toString(36).substring(7),
            loaderIsShowing: false,
            loaderKey: Math.random().toString(36).substring(7),
        };
    }


    componentDidMount() {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
			if (user) {
                this.setState({ user });
            } else {
                this.setState({ user: null });
            }
        });
        this.configurePage();
    }

    configurePage = () => {
        if (!!this.props.route.params) {
            if (this.props.route.params.newRentalData) {
                let lat = null;
                let lng = null;
                let locationName = null;
                let checkInTime = "12:00";
                let checkOutTime = "12:00";
                let checkInInstructions = null;
                if (this.props.route.params.newRentalData.lat != null) {
                    lat = this.props.route.params.newRentalData.lat;
                }
                if (this.props.route.params.newRentalData.lng != null) {
                    lng = this.props.route.params.newRentalData.lng;
                }
                if (this.props.route.params.newRentalData.locationName != null) {
                    locationName = this.props.route.params.newRentalData.locationName;
                }
                if (this.props.route.params.newRentalData.checkInTime != null) {
                    checkInTime = this.props.route.params.newRentalData.checkInTime
                }
                if (this.props.route.params.newRentalData.checkOutTime != null) {
                    checkOutTime = this.props.route.params.newRentalData.checkOutTime
                }
                if (this.props.route.params.newRentalData.checkInInstructions != null) {
                    checkInInstructions = this.props.route.params.newRentalData.checkInInstructions
                }
                this.setState({
                    lat: lat,
                    lng: lng,
                    locationName: locationName,
                    checkInTime: checkInTime,
                    checkOutTime: checkOutTime,
                    checkInInstructions: checkInInstructions,
                    checkInKey: Math.random().toString(36).substring(7),
                    checkOutKey: Math.random().toString(36).substring(7),
                    searchKey: Math.random().toString(36).substring(7),
                });
            } else if (this.props.route.params.rentalData) {
                this.getCheckInData(this.props.route.params.rentalData);
                let checkInTime = null;
                let checkOutTime = null;
                if (this.props.route.params.rentalData.checkInTime != null) {
                    checkInTime = this.props.route.params.rentalData.checkInTime;
                }
                if (this.props.route.params.rentalData.checkOutTime != null) {
                    checkOutTime = this.props.route.params.rentalData.checkOutTime
                }
                this.setState({
                    checkInTime: checkInTime,
                    checkOutTime: checkOutTime,
                    checkInKey: Math.random().toString(36).substring(7),
                    checkOutKey: Math.random().toString(36).substring(7),
                });
            }
        }
    }

    getCheckInData = (rentalData) => {
        const db = getDatabase();
        let checkInInstructionsRef = ref(db, `checkInInstructions/${rentalData.key}`);
        get(checkInInstructionsRef).then((snapshot) => {
            let checkInData = snapshot.val();
            if (!!checkInData) {
                this.setState({
                    lat: checkInData.lat,
                    lng: checkInData.lng,
                    locationName: checkInData.address,
                    checkInInstructions: checkInData.checkInInstructions,
                    searchKey: Math.random().toString(36).substring(7),
                    checkInKey: Math.random().toString(36).substring(7),
                    checkOutKey: Math.random().toString(36).substring(7),
                    instructionsKey:  Math.random().toString(36).substring(7),
                });
            }
        });
    }

    onSearchSelect = (lat, lng, name) => {
        this.setState({
            lat: lat,
            lng: lng,
            locationName: name,
        });
    }

    saveDraft = async (checkoutData, checkInData) => {
        let draftData = {
            ...checkoutData,
             ...checkInData,
            lat: this.state.lat,
            lng: this.state.lng,
            locationName: this.state.locationName,
            checkInTime: this.state.checkInTime,
            checkOutTime: this.state.checkOutTime,
            checkInInstructions: this.state.checkInInstructions,
        }
        const db = getDatabase();
        const draftsRef = ref(db, `drafts/${this.state.user.uid}/${draftData.key}`);
        let success = await set(draftsRef, draftData);
        return success;
    }

    requestSilverListing = async (checkoutData, checkInData) => {
        this.setState({ 
            loaderIsShowing: true,
            loaderKey: Math.random().toString(36).substring(7),
        });
        checkoutData["pending"] = true;
        let success = await this.saveDraft(checkoutData, checkInData);
        const response = await fetch(`https://us-central1-doc2doc-d54a2.cloudfunctions.net/createNewSilverListing?posterUID=${this.state.user.uid}&postID=${checkoutData.key}`);
        const responseData  = await response.json();
        this.setState({ 
            loaderIsShowing: false,
            loaderKey: Math.random().toString(36).substring(7),
        });
        if (!!responseData) {
            if (responseData.response == "success") {
                return true
            } else {
                return false;
            }
        }
    }

    saveButtonPress = async () => {
        if (this.state.checkInInstructions != null &&
            this.state.checkInInstructions != "" &&
            this.state.lat != null &&
            this.state.lng != null &&
            this.state.locationName != null &&
            this.state.locationName != "" &&
            this.state.checkInTime != null &&
            this.state.checkOutTime != null ) {
            if (this.props.route.params.rentalData != null) {
                let checkoutData = this.props.route.params.rentalData;
                checkoutData["checkInInstructions"] = this.state.checkInInstructions,
                checkoutData["lat"] = this.state.lat;
                checkoutData["lng"] = this.state.lng;
                checkoutData["locationName"] = this.state.locationName;
                checkoutData["checkInTime"] = this.state.checkInTime;
                checkoutData["checkOutTime"] = this.state.checkOutTime;
                let success = await requestUpdate(this.state.user.uid, checkoutData);
                if (success) {
                    this.showSuccess();
                } else {
                    this.setState({
                        errorMessage: "There was an issue updating your lising.",
                        errorModalKey: Math.random().toString(36).substring(7),
                        errorModalVisible: true,
                    })
                }     
            } else {
                this.setState({
                    errorMessage: "There was an issue checking out. Please try again",
                    errorModalKey: Math.random().toString(36).substring(7),
                    errorModalVisible: true,
                });
            }
        } else {
            this.setState({
                errorMessage: "Please select all required fields.",
                errorModalKey: Math.random().toString(36).substring(7),
                errorModalVisible: true,
            });
        }
    }

    nextButtonPress = async () => {
        if (this.state.checkInInstructions != null &&
            this.state.checkInInstructions != "" &&
            this.state.lat != null &&
            this.state.lng != null &&
            this.state.locationName != null &&
            this.state.locationName != "" &&
            this.state.checkInTime != null &&
            this.state.checkOutTime != null ) {
            if (this.props.route.params.newRentalData != null) {
                let checkoutData = this.props.route.params.newRentalData;
                let checkInData = {
                    checkInInstructions: this.state.checkInInstructions,
                    lat: this.state.lat,
                    lng: this.state.lng,
                    locationName: this.state.locationName,
                    checkInTime: this.state.checkInTime,
                    checkOutTime: this.state.checkOutTime,
                }
                if (checkoutData.subscriptionLevel == "silver") {
                    //post it
                    let success = await this.requestSilverListing(checkoutData, checkInData);
                    if (success) {
                        //navigate to success
                        this.props.navigation.navigate("Success", {
                            rentalData: checkoutData,
                        });
                    } else {
                        Alert.alert("Error", "There was an error while posting your listing.")
                    }
                } else {
                    if (this.props.route.params.subscriptionChange == undefined) {
                        //save draft
                        this.saveDraft(checkoutData, checkInData);
                        this.props.navigation.navigate("Check Out", {
                            newRentalData: checkoutData,
                            checkInData: checkInData,
                            checkOutType: checkoutData.subscriptionLevel,
                        });
                    } else if (!this.props.route.params.subscriptionChange) {
                        //save draft
                        this.saveDraft(checkoutData, checkInData);
                        this.props.navigation.navigate("Check Out", {
                            newRentalData: checkoutData,
                            checkInData: checkInData,
                            checkOutType: checkoutData.subscriptionLevel,
                        });
                    } else {
                        this.props.navigation.navigate("Check Out", {
                            newRentalData: checkoutData,
                            checkInData: checkInData,
                            checkOutType: checkoutData.subscriptionLevel,
                            subscriptionChange: true,
                        });
                    }

                }
            } else {
                this.setState({
                    errorMessage: "There was an issue checking out. Please try again",
                    errorModalKey: Math.random().toString(36).substring(7),
                    errorModalVisible: true,
                });
            }
        } else {
            this.setState({
                errorMessage: "Please select all required fields.",
                errorModalKey: Math.random().toString(36).substring(7),
                errorModalVisible: true,
            });
        }
    }

    showSuccess = () => {
        this.setState({
            successIsShowing: true,
        });
        setTimeout(() => {
            this.setState({
                successIsShowing: false,
            });
        }, 1500);
    }

    buildActionButton = () => {
        let parent = [];
        if (this.props.route.params.newRentalData != null) {
            parent.push(
                <ActionButton
                    text={"Next"}
                    handler={()=>{
                        this.nextButtonPress();
                    }}
                />
            );
        } else if (this.props.route.params.rentalData != null) {
            parent.push(
                <ActionButton
                    text={"Save"}
                    handler={()=>{
                        this.saveButtonPress();
                    }}
                />
            );
        }
        return parent;
    }

    render() {
        let headWrapperStyles = [styles.headWrapper];
        return (
            <View style={{height: "100%"}}>
                <TopAlert text={"Check in Instructions Updated"} isShowing={this.state.successIsShowing}/>
                <Loader 
                    visible={this.state.loaderIsShowing}
                    key={this.state.loaderKey}  
                />
                <View style={headWrapperStyles}>
                    <View 
                        style={{
                            width: "100%",
                            position: "absolute", 
                            display: "flex", 
                            alignItems: "center", 
                            justifyContent: "center",
                            zIndex: 0,
                        }}
                    >
                        <Container title={"Exact Address"}>
                            <Text style={styles.sublabel}>This will be sent to renters via email 24 before their check in date and will not be publicly visible</Text>

                                <SearchInput
                                    local={"address"}
                                    onChange={(lat, lng, name) => {
                                        if (!!name) {
                                        this.onSearchSelect(lat, lng, name)
                                        } else {
                                            this.onSearchSelect(null, null, null)
                                        }
                                    }}
                                    key={this.state.searchKey}
                                    value={this.state.locationName}
                                    isFocused={focused => {
                                        this.setState({
                                            searchIsFocused: focused
                                        })
                                    }}
                                />
                        
                        </Container>
                    </View>
                </View>
                <ScrollView contentContainerStyle={styles.container}>
                    <Modal
                        animationType="slide"
                        transparent={true}
                        visible={this.state.errorModalVisible}
                        key={this.state.errorModalKey}
                        onModalClose={this.onModalClose}
                        labelText={this.state.errorMessage}
                        isError={true}
                    />
                    <Container title={"Check in time"}>
                        <Text style={styles.sublabel}>Check in time will be publicly visible</Text>
                        <TimePicker 
                            key={this.state.checkInKey}
                            onUpdate={data => {
                                this.setState({
                                    checkInTime: data,
                                    checkInKey: Math.random().toString(36).substring(7),
                                })
                            }}
                            time={this.state.checkInTime}
                        />
                    </Container>
                    <Container title={"Check out time"}>
                        <Text style={styles.sublabel}>Check out time will be publicly visible</Text>
                        <TimePicker 
                            key={this.state.checkOutKey}
                            onUpdate={data => {
                                this.setState({
                                    checkOutTime: data,
                                    checkOutKey: Math.random().toString(36).substring(7),
                                })
                            }}
                            time={this.state.checkOutTime}
                        />
                    </Container>
                    <Container title={"Check-in Instructions"}>
                        <Text style={styles.sublabel}>These instructions will be sent to renters 48 hours before their check in date and will not be publicly visible</Text>
                        <TextInput
                            style={[styles.inputBox, styles.multilineBox]}
                            onChangeText={checkInInstructions => {
                                this.setState({ checkInInstructions: checkInInstructions});
                            }}
                            placeholder='Check-in Instructions'
                            placeholderTextColor='black'
                            multiline = {true}
                            numberOfLines={4}
                            key={this.state.instructionsKey}
                            value={this.state.checkInInstructions}
                        />
                        <View>
                            { this.buildActionButton() }
                        </View>
                    </Container>
                </ScrollView>
            </View>
        )
    }
}


const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30,
    },
    headerLabeltext: {
        fontSize: 24,
        marginBottom: 20,
        marginTop: 50,
        fontWeight: "700",
        textAlign: "center",
    },
    flexWrapper: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
    }, 
    subHeaderLabel: {
        fontSize: 14,
        fontWeight: "600",
        textAlign: "center",
    },
    button: {
        marginTop: 20,
        marginBottom: 20,
        paddingVertical: 5,
        alignItems: 'center',
        backgroundColor: '#FFA611',
        borderColor: '#FFA611',
        borderWidth: 1,
        borderRadius: 5,
        width: 200
    },
    buttonText: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black'
    },
    label: {
        margin: 5,
        marginTop: 10,
        fontSize: 12,
        fontWeight: "600",
    },
    sublabel: {
        paddingBottom: 10,
    },
    inputBox: {
        width: '85%',
        maxWidth: 1000,
        marginBottom: 10,
        padding: 15,
        fontSize: 14,
        borderColor: '#d3d3d3',
        borderBottomWidth: 1,
        backgroundColor: "#f5f5f5",
    },
    headWrapper:{
        width: "100%",
        zIndex: 20, 
        display: "flex", 
        height: 110,
    },
    multilineBox: {
        minHeight: 80,  
    },
})